import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Button, CustomInput, Input, Row } from 'reactstrap';
import { CheckCircle, XCircle } from 'react-feather';

import { toDateInputValue } from 'utils/date-time';
import { displayPrice } from 'utils/format';

import type { TCustomerWithStats } from 'store/customers';
import CollapsibleCard from '../common/CollapsibleCard';
import './customers-list.scss';

const spacer = <div style={{ width: 10 }}></div>;

const blurredCellStyle = { filter: 'blur(1.8px)' };
const normalCellStyle = {};

interface IProps {
  allCustomers: TCustomerWithStats[];
  blurPersonalData: boolean;
  customerEmailsList: string;
  headerAddon: React.ReactNode;
  onCustomersSelect: (customers: Set<number>) => void;
  selectedCustomers: Set<number>;
  updateCustomerEmailsList: () => void;
}

export default class CustomersListComponent extends React.PureComponent<
  IProps,
  {}
> {
  static propTypes = {
    allCustomers: PropTypes.array.isRequired,
    blurPersonalData: PropTypes.bool.isRequired,
    customerEmailsList: PropTypes.string.isRequired,
    headerAddon: PropTypes.element,
    onCustomersSelect: PropTypes.func.isRequired,
    selectedCustomers: PropTypes.instanceOf(Set).isRequired,
    updateCustomerEmailsList: PropTypes.func.isRequired,
  };

  selectAllCustomers = () =>
    this.props.onCustomersSelect(
      new Set(this.props.allCustomers.map((c) => c.id))
    );

  selectAllCustomersWithSms = () =>
    this.props.onCustomersSelect(
      new Set(this.getSmsAllowingCustomers().map((c) => c.id))
    );

  selectAllCustomersWithEmail = () =>
    this.props.onCustomersSelect(
      new Set(this.getEmailAllowingCustomers().map((c) => c.id))
    );

  clearSelected = () => this.props.onCustomersSelect(new Set([]));

  toggleCustomer = (e) => {
    const { checked, value } = e.target;
    const newSet = new Set([...this.props.selectedCustomers]);
    if (checked) {
      newSet.add(parseInt(value, 10));
    } else {
      newSet.delete(parseInt(value, 10));
    }
    this.props.onCustomersSelect(newSet);
  };

  getSmsAllowingCustomers = () =>
    this.props.allCustomers.filter((c) => c.sms_marketing);

  getEmailAllowingCustomers = () =>
    this.props.allCustomers.filter((c) => c.email_marketing);

  render() {
    const { allCustomers, blurPersonalData, headerAddon, selectedCustomers } =
      this.props;
    if (!allCustomers || !allCustomers.length) {
      return null;
    }
    const personalDataCellStyle = blurPersonalData
      ? blurredCellStyle
      : normalCellStyle;
    return (
      <>
        <Row className="mx-0 mb-4 align-items-center">
          <Button
            type="button"
            className="mr-2"
            onClick={this.selectAllCustomers}
          >
            {i18next.t('customers-list.select-all')}
          </Button>
          <Button
            type="button"
            className="mr-2"
            onClick={this.selectAllCustomersWithSms}
          >
            {i18next.t('customers-list.select-all-with-sms')}
          </Button>
          <Button
            type="button"
            className="mr-2"
            onClick={this.selectAllCustomersWithEmail}
          >
            {i18next.t('customers-list.select-all-with-email')}
          </Button>
          <Button type="button" onClick={this.clearSelected}>
            {i18next.t('Clear selection')}
          </Button>
        </Row>
        <Row className="mx-0 mb-4 align-items-center">
          <div className="d-flex flex-column">
            <strong>
              {i18next.t('customers-list.total')}: {allCustomers.length}
            </strong>
            <strong>
              {i18next.t('customers-list.selected')}: {selectedCustomers.size}
            </strong>
          </div>
          {headerAddon}
        </Row>
        {selectedCustomers.size > 0 && (
          <Row className="mx-0">
            <CollapsibleCard title="Lista mailingowa" className="col-12">
              <Button
                type="button"
                className="mb-2"
                onClick={this.props.updateCustomerEmailsList}
              >
                {!this.props.customerEmailsList
                  ? 'Pobierz listę'
                  : 'Zaktualizuj listę'}
              </Button>
              <Input
                type="textarea"
                style={{ minHeight: 300 }}
                disabled
                value={this.props.customerEmailsList || ''}
              />
            </CollapsibleCard>
          </Row>
        )}
        <table>
          <tr className="strong bb-1">
            <td>{i18next.t('customers-list.customer')}</td>
            <td className="mw-300">
              {i18next.t('customers-list.customer-data')}
            </td>
            <td className="px-2">
              {i18next.t('customers-list.orders-amount')}
            </td>
            <td className="px-2">{i18next.t('customers-list.orders-sum')}</td>
            <td className="px-2">{i18next.t('customers-list.orders-avg')}</td>
            <td>{i18next.t('customers-list.first-order-date')}</td>
            <td>{i18next.t('customers-list.last-order-date')}</td>
            <td className="text-center min-w-200">
              <div>{i18next.t('customers-list.agreements')}</div>
              <div>SMS | Email</div>
            </td>
          </tr>
          {allCustomers.map((user) => (
            <tr key={user.id} className="bb-1 align-items-center">
              <td className="mw-200">
                <CustomInput
                  type="checkbox"
                  id={user.id}
                  value={user.id}
                  label={`${user.name} ${user.surname}`}
                  checked={selectedCustomers.has(user.id)}
                  onChange={this.toggleCustomer}
                  style={personalDataCellStyle}
                />
              </td>
              <td className="mw-300">
                <div style={personalDataCellStyle}>{user.email}</div>
                <div style={personalDataCellStyle}>{user.phone}</div>
              </td>
              <td className="orders-count-cell">{user.orders_count}</td>
              <td className="orders-count-cell">
                {displayPrice(user.orders_sum_price)}
              </td>
              <td className="orders-count-cell">
                {displayPrice(user.orders_avg_price)}
              </td>
              <td className="order-date-cell">
                {user.orders_min_created_at
                  ? toDateInputValue(new Date(user.orders_min_created_at))
                  : '-'}
              </td>
              <td className="order-date-cell">
                {user.orders_max_created_at
                  ? toDateInputValue(new Date(user.orders_max_created_at))
                  : '-'}
              </td>
              <td className="d-flex flex-row justify-content-center">
                {user.sms_marketing ? (
                  <CheckCircle color="green" />
                ) : (
                  <XCircle color="red" />
                )}
                {spacer}
                {user.email_marketing ? (
                  <CheckCircle color="green" />
                ) : (
                  <XCircle color="red" />
                )}
              </td>
            </tr>
          ))}
        </table>
      </>
    );
  }
}
