import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import i18next from 'i18next';

import IconWithTooltip from '../common/IconWithTooltip';
import OtoSpinner from '../common/OtoSpinner';
import OtoToggle from '../common/OtoToggle';
import CollapsibleCard from '../common/CollapsibleCard';
import { IPlace, NormalizedRestaurant, PlaceDTO } from 'types';
import Shapes from 'shapes/main';

type PlaceDTOWithRestaurant = PlaceDTO & { restaurant_id: number };

const getDefaultState = (
  restaurant: NormalizedRestaurant,
  places: IPlace[]
): PlaceDTOWithRestaurant => ({
  name: '',
  priority: Math.max(...places.map((p) => p.priority), 0) + 1,
  is_outside: false,
  is_active: true,
  restaurant_id: restaurant.id,
});

interface IProps {
  onAddPlace: (values: PlaceDTOWithRestaurant) => Promise<any>;
  places: IPlace[];
  restaurant: NormalizedRestaurant;
}

const AddPlaceComponent: React.FC<IProps> = (props) => {
  const { places, restaurant } = props;

  const [values, setValues] = useState<PlaceDTOWithRestaurant>(
    getDefaultState(restaurant, places)
  );
  const [loading, setLoading] = useState(false);

  const updateField = (name: string, val: any) =>
    setValues({ ...values, [name]: val });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateField(e.target.name, e.target.value);

  useEffect(() => {
    setValues({
      ...values,
      priority: getDefaultState(restaurant, places).priority,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurant, places]);

  const onSave = (values: PlaceDTOWithRestaurant) => {
    setLoading(true);
    props
      .onAddPlace(values)
      .then(() => {
        setLoading(false);
        setValues(getDefaultState(restaurant, places));
      })
      .catch((e) => setLoading(false));
  };
  return (
    <CollapsibleCard title={i18next.t('place-settings.add-place.header')}>
      <Row className="flex-wrap">
        <FormGroup className="col col-auto min-w-300">
          <Label>{i18next.t('place-settings.column-header.name.label')}</Label>
          <Input
            name="name"
            placeholder={i18next.t('place-settings.column-header.name.placeholder')}
            value={values.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className="col col-auto">
          <Label>
            {i18next.t('place-settings.column-header.priority.label')}{' '}
            <IconWithTooltip
              id="place-priority"
              text={i18next.t('place-settings.column-header.priority.tooltip')}
            />
          </Label>
          <Input
            type="number"
            step="1"
            className="mw-125"
            name="priority"
            value={values.priority}
            onChange={handleChange}
          />
        </FormGroup>
        <Col>
          <FormGroup>
            <Label>{i18next.t('place-settings.column-header.is-outside')}</Label>
            <OtoToggle
              checked={values.is_outside}
              className="w-100"
              name="is_outside"
              label={values.is_outside ? i18next.t('Yes') : i18next.t('No')}
              onChange={(e) => updateField('is_outside', e.target.checked)}
              value="true"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>{i18next.t('place-settings.column-header.is-active.label')}</Label>
            <OtoToggle
              checked={values.is_active}
              className="w-100"
              name="is_active"
              label={values.is_active ? i18next.t('Yes') : i18next.t('No')}
              value="true"
              onChange={(e) => updateField('is_active', e.target.checked)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <FormGroup className="col-12">
          {loading ? (
            <OtoSpinner />
          ) : (
            <Button type="button" onClick={() => onSave(values)}>
              {i18next.t('Add')}
            </Button>
          )}
        </FormGroup>
      </Row>
    </CollapsibleCard>
  );
};

AddPlaceComponent.propTypes = {
  onAddPlace: PropTypes.func.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
};

export default AddPlaceComponent;
