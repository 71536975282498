import React from 'react';
import { Messaging, getToken } from 'firebase/messaging';

import { NormalizedRestaurant } from 'types/restaurants';
import { firebaseInstance } from 'utils/firebase-messaging';
import { getDeviceDataStr } from 'utils/device';
import { throttle } from 'utils/general';
import { ILogOptions, LOG_BAG } from 'utils/log';
import { _1hour } from 'config';
import FcmMessageListener from './FcmMessageListener';
import { ConnectedProps, connect } from 'react-redux';
import { selectMessagingToken, setMessagingToken } from 'store/app';
import { IAppState } from 'store/main';

const logFcmError: (message: string, options?: ILogOptions) => void = throttle(
  (message: string, options?: ILogOptions) => {
    console.warn('FCM error skipped: ', message);
    return;
    // leaving for debugging purposes
    // eslint-disable-next-line no-unreachable
    LOG_BAG.logActivity(message, options);
  },
  _1hour
);

interface IFcmTokenProviderProps {
  messagingError?: any;
  onMessage: (message: object) => void;
  setError: (error: any) => void;
  restaurant: NormalizedRestaurant;
}

class FcmTokenProvider extends React.Component<
  IFcmTokenProviderProps & PropsFromRedux,
  {}
> {

  static displayName = 'FcmTokenProvider';

  componentDidMount() {
    this.ensureFcmTokenUpToDate(this.props.restaurant);
  }

  componentDidUpdate(prevProps: IFcmTokenProviderProps) {
    if (prevProps.restaurant !== this.props.restaurant) {
      this.ensureFcmTokenUpToDate(this.props.restaurant);
    }
  }

  ensureFcmTokenUpToDate = (restaurant: NormalizedRestaurant) => {
    if (!restaurant) {
      console.warn('FCM token provider stops because of no restaurant');
    }
    if (firebaseInstance.messagingError) {
      if (!this.props.messagingError) {
        this.props.setError(firebaseInstance.messagingError);
      }
      return;
    }

    if ('serviceWorker' in navigator) {
      console.log(
        'FCM Token Provider: service worker is in navigator, loading registration'
      );
      navigator.serviceWorker.getRegistration().then(async (reg) => {
        console.log(
          'FCM Token Provider: service worker is in navigator, reg loaded, passing it to getToken()'
        );
        getToken(firebaseInstance.messaging as Messaging, {
          serviceWorkerRegistration: reg,
        })
          .then((token: string) => this.props.setMessagingToken(token))
          .catch((e) => {
            logFcmError(`FCM error: ${getDeviceDataStr()}`, e);
          });
      });
    } else {
      console.log(
        'FCM Token Provider: NO service worker is in navigator, calling getToken() without registration'
      );
      getToken(firebaseInstance.messaging as Messaging)
        .then((token: string) => this.props.setMessagingToken(token))
        .catch((e) => {
          logFcmError(`FCM error: ${getDeviceDataStr()}`, e);
        });
    }
  };

  render() {
    const { token } = this.props;
    return (
      token && (
        <FcmMessageListener
          onMessage={this.props.onMessage}
          restaurant={this.props.restaurant}
          token={token}
        />
      )
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  token: selectMessagingToken(state),
});

const mapDispatchToProps = {
  setMessagingToken,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FcmTokenProvider);
