import React from 'react';
import { Form, FormikProps, Field, withFormik, FormikBag } from 'formik';
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardHeader,
  Row,
} from 'reactstrap';

import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import { ROLES } from 'enums';
import UserRoleSelect from './UserRoleSelect';

export type AddUserFormValues = {
  name: string;
  email: string;
  password: string;
  role_id: number;
  restaurant_id: number;
  create_restaurant_config: boolean;
};

interface AddUserFormOwnProps {
  isDriver?: boolean;
  restaurantId: number;
  onAddUser: (user: AddUserFormValues) => void;
}

type AddUserFormProps = AddUserFormOwnProps & FormikProps<AddUserFormValues>;

const AddUserFormWithoutFormik: React.FC<AddUserFormProps> = (props) => {
  const { isDriver, isSubmitting, handleChange, values } = props;
  return (
    <Card>
      <CardHeader>
        {isDriver ? 'Dodaj kierowcę' : 'Dodaj użytkownika'}
      </CardHeader>
      <CardBody>
        <Form>
          <Row className="mx-0 d-flex flex-wrap">
            <FormGroup className="col min-w-200">
              <Label>Imię oraz Nazwisko</Label>
              <Field as={Input} name="name" placeholder="np. Adam Nowak" />
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Email</Label>
              <Field as={Input} name="email" />
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Nr. telefonu</Label>
              <Field as={Input} name="phone" />
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Hasło</Label>
              <Field as={Input} name="password" />
            </FormGroup>
            {!isDriver && (
              <UserRoleSelect
                name={'role_id'}
                onChange={handleChange}
                value={values.role_id}
              />
            )}
            {!isDriver && (
              <FormGroup className="col min-w-200">
                <Label className="ml-3">
                  <Input
                    type="checkbox"
                    name={'create_restaurant_config'}
                    onChange={handleChange}
                    checked={values.create_restaurant_config}
                  />
                  Stwórz konfigurację restauracji
                  <br />
                  (zalecane, jeżeli to pierwszy użytkownik dla danego lokalu)
                </Label>
              </FormGroup>
            )}
          </Row>
          <FormGroup className="col-12">
            <SubmitButtonWithLoader
              label={isDriver ? 'Dodaj kierowcę' : 'Dodaj użytkownika'}
              loading={isSubmitting}
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

const AddUserForm = withFormik<AddUserFormOwnProps, AddUserFormValues>({
  mapPropsToValues: (props: AddUserFormOwnProps) => ({
    name: '',
    email: '',
    password: '',
    role_id: props.isDriver ? ROLES.RESTAURANT_DRIVER : ROLES.RESTAURANT_ADMIN,
    restaurant_id: props.restaurantId,
    create_restaurant_config: false,
  }),
  handleSubmit: (
    values: AddUserFormValues,
    { props, setSubmitting }: FormikBag<AddUserFormOwnProps, AddUserFormValues>
  ) => {
    return props.onAddUser(values);
  },
})(AddUserFormWithoutFormik);

export default AddUserForm;
