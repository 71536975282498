import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import IconWithTooltip from '../common/IconWithTooltip';
import OtoSpinner from '../common/OtoSpinner';
import { currency } from 'globals/currency';
import { fixDecimal } from 'utils/general';
import { calculateSmsParts } from 'utils/marketing';
import type {
  TCommonPropsForSpecificModals,
  TSendSmsModalProps,
} from 'app/containers/WithSetModal';

type SendSmsModalProps = TSendSmsModalProps & TCommonPropsForSpecificModals<string>;

const SendSmsModal: React.FC<SendSmsModalProps> = (props) => {
  const [smsMessage, setSmsMessage] = useState(props.smsText || '');
  const [loading, setLoading] = useState(false);
  const { customers: selectedCustomers, closeModal, onSave } = props;
  const smsCostPerPart = 0.1;
  const smsParts = calculateSmsParts(smsMessage);
  const sendMessage = () => {
    setLoading(true);
    onSave(smsMessage).finally(() => setLoading(false));
  };
  return (
    <Modal isOpen>
      <ModalHeader toggle={closeModal}>
        Wysyłasz wiadomość SMS do {selectedCustomers.size} klientów
      </ModalHeader>
      <ModalBody>
        <label htmlFor="sms-message">Treść wiadomości SMS</label>
        <Input
          type="textarea"
          placeholder="Np: Nowa karta menu - drugie danie z kategorii ABC -50%!"
          name="sms-message"
          value={smsMessage}
          onChange={(e) => setSmsMessage(e.target.value)}
        />
        <hr />
        <div className="mt-3">
          <div>
            Koszt wiadomości SMS: {smsCostPerPart.toFixed(2)} {currency} per
            część{' '}
            <IconWithTooltip
              id="sms-cost"
              text="Długości części SMS (bez polskich znaków, to 160 znaków 1 część, 146 znaki 2 część, 153 znaki 3 i kolejne części"
            />
          </div>
          <div>
            Liczba części SMS: {smsParts} ({smsMessage.length} znaków)
          </div>
          <div>Liczba wiadomości SMS: {selectedCustomers.size}</div>
          <hr />
          <div>
            Całkowity koszt kampanii: {smsCostPerPart} * {smsParts} *{' '}
            {selectedCustomers.size} ={' '}
            {fixDecimal(smsParts * selectedCustomers.size * smsCostPerPart)}{' '}
            {currency}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <OtoSpinner />
        ) : (
          <>
            <Button type="button" color="secondary" onClick={closeModal}>
              Zamknij
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={sendMessage}
              disabled={!smsMessage}
            >
              Wyślij
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

SendSmsModal.propTypes = {
  customers: PropTypes.instanceOf(Set).isRequired,
  smsText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SendSmsModal;
