import React, { useEffect, useState } from 'react';
import { FormikProvider, FieldArray, useFormik } from 'formik';
import i18 from 'i18next';
import { Tag, Trash } from 'react-feather';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { AdditionalFieldTypes } from 'enums';
import { setFocus } from 'utils/ui';
import IconWithTooltip from '../common/IconWithTooltip';
import OtoChip from '../common/OtoChip';
import InputWithAddon from '../common/InputWithAddon';
import OtoButtons from '../common/OtoButtons';
import CollapsibleCard from '../common/CollapsibleCard';
import ProductAdditionalFieldEdit from './ProductAdditionalFieldEdit';
import ProductAdditionalFieldAdd from './ProductAdditionalFieldAdd';
import type {
  MenuItem,
  MenuItemLabel,
  MenuItemPriceOption,
  NormalizedRestaurant,
} from 'types';
import MenuItemAvailability from '../menu/MenuItemAvailability';

import './product-edit-modal.scss';
import { useUpdateEffect } from 'react-use';

const possibleLabels = [
  { name: 'new', value: 'new' },
  { name: 'recommended', value: 'recommended' },
  { name: 'vege', value: 'vege' },
  { name: 'vegan', value: 'vegan' },
  { name: 'hot', value: 'hot' },
  { name: 'very-hot', value: 'very-hot' },
  { name: 'max-hot', value: 'max-hot' },
  { name: 'gluten-free', value: 'gluten-free' },
  { name: 'lactose-free', value: 'lactose-free' },
  { name: 'sugar-free', value: 'sugar-free' },
];

type ProductEditModalProps = {
  currency: string;
  onClose: () => void;
  onImageRemove: () => void;
  onSave: (product: MenuItem) => void;
  product: MenuItem;
  renderUploadImage: () => React.ReactNode;
  restaurant: NormalizedRestaurant;
  showMenuItemAvailability: boolean;
};

export type TProductEditFormValues = MenuItem;

export const ProductEditModal: React.FC<ProductEditModalProps> = ({
  currency,
  onClose,
  onImageRemove,
  onSave,
  product,
  renderUploadImage,
  restaurant,
  showMenuItemAvailability,
}) => {
  const formik = useFormik({
    initialValues: {
      ...product,
    },
    onSubmit: (values) => {
      const normalizedUpdatedProduct = setSingleSpacesInChoiceFields(values);
      onSave(normalizedUpdatedProduct);
    },
  });

  const { handleChange, setFieldValue, setValues, values } = formik;

  useEffect(() => {
    setFocus('input[name=name]');
  }, []);

  useUpdateEffect(() => {
    setFieldValue('image', product.image);
  }, [product?.image]);

  const updateLabels = (newLabels: MenuItemLabel[]) =>
    setFieldValue('labels', newLabels);

  const [withDiscount, setWithDiscount] = useState(
    product.priceOptions
      ? !!product.priceOptions[0].oldPrice
      : !!product.oldPrice
  );

  const setDiscount = () => {
    // @ts-ignore we allow price as empty string here because we want to have empty input
    const newValues: TProductEditFormValues = values.priceOptions
      ? {
          ...values,
          priceOptions: values.priceOptions.map((opt) => ({
            ...opt,
            oldPrice: opt.price,
            price: '',
          })),
        }
      : {
          ...values,
          oldPrice: values.price,
          price: '',
        };
    setValues(newValues);
    setFocus('input[name=price]');
    setWithDiscount(true);
  };

  const removeDiscount = () => {
    const { oldPrice, ...otherValues } = values;
    const newValues: TProductEditFormValues = values.priceOptions
      ? {
          ...values,
          priceOptions: values.priceOptions.map((opt) => {
            const { oldPrice, ...otherValues } = opt;
            return {
              ...otherValues,
              price: oldPrice as number,
            };
          }),
        }
      : {
          ...otherValues,
          price: oldPrice as number,
        };
    setValues(newValues);
    setWithDiscount(false);
  };

  const addPriceOption = () => {
    const getNewItem = (
      price?: number,
      oldPrice?: number | null
    ): MenuItemPriceOption => {
      return {
        name: '',
        // @ts-ignore allow empty price here for empty input
        price: price || '',
        ...(withDiscount ? { oldPrice } : {}),
      };
    };
    if (values.priceOptions) {
      setValues({
        ...values,
        priceOptions: [...values.priceOptions, getNewItem()],
      });
      return;
    }
    const { price, oldPrice, ...valuesWithoutPrices } = values;
    // @ts-ignore allow empty price here for empty input
    setValues({
      ...valuesWithoutPrices,
      priceOptions: [getNewItem(price, oldPrice), getNewItem()],
    });
  };

  const removePriceOption = (index: number) => {
    const priceOptions = values.priceOptions as MenuItemPriceOption[];
    if (priceOptions.length > 2) {
      setValues({
        ...values,
        priceOptions: [
          ...priceOptions.slice(0, index),
          ...priceOptions.slice(index + 1),
        ],
      });
    } else {
      setValues({
        ...values,
        priceOptions: null,
        price: priceOptions[0].price,
        ...(withDiscount ? { oldPrice: priceOptions[0].oldPrice } : {}),
      });
    }
  };

  const renderSinglePrice = (
    priceOption: MenuItemPriceOption,
    index: number | null = null,
    multiple = false
  ) => {
    const namePrefix = multiple ? `priceOptions[${index}].` : '';
    return (
      <Row
        className="product-options-row"
        key={multiple ? index : 'single-price'}
      >
        {multiple ? (
          <Input
            value={priceOption.name || ''}
            name={`${namePrefix}name`}
            placeholder={i18.t('product-edit-modal.price-option-name')}
            className="mw-250 h-100"
            onChange={handleChange}
          />
        ) : null}
        {!!withDiscount && (
          <InputWithAddon
            addonText={currency}
            type="number"
            step="0.01"
            className="mw-200 h-100"
            wrapperClassName="mb-1"
            value={priceOption.oldPrice || ''}
            id={`${namePrefix}oldPrice`}
            name={`${namePrefix}oldPrice`}
            placeholder={i18.t('product-edit-modal.old-price')}
            onChange={handleChange}
          />
        )}
        <InputWithAddon
          addonText={currency}
          type="number"
          step="0.01"
          className="mw-200"
          value={priceOption.price || ''}
          id={`${namePrefix}price`}
          name={`${namePrefix}price`}
          placeholder={
            withDiscount
              ? `${i18.t('product-edit-modal.price-after-discount')}`
              : `${i18.t('product-edit-modal.price')}`
          }
          onChange={handleChange}
        />
        {multiple ? (
          <Button
            color="danger"
            className="p-1"
            onClick={() => removePriceOption(index as number)}
          >
            <Trash />
          </Button>
        ) : null}
      </Row>
    );
  };

  const renderLabels = () => {
    const selectedLabels = values.labels || [];
    return (
      <>
        <Label>{i18.t('product-edit-modal.tags')}</Label>
        {possibleLabels.map((lbl) => {
          const isSelected = selectedLabels
            .map((l) => l.name)
            .includes(lbl.name);
          const handleClick = () =>
            isSelected
              ? updateLabels(selectedLabels.filter((l) => l.name !== lbl.name))
              : updateLabels([...selectedLabels, lbl]);
          return (
            <OtoChip
              key={lbl.name}
              isSelected={isSelected}
              label={i18.t(`possible-labels.${lbl.name}`)}
              onClick={handleClick}
            />
          );
        })}
      </>
    );
  };

  const renderPrices = () => {
    return (
      <>
        {values.priceOptions
          ? values.priceOptions.map((opt, index) =>
              renderSinglePrice(opt, index, true)
            )
          : renderSinglePrice(values)}
        <div className="d-flex align-items-center mb-3">
          <OtoButtons.AddButton onClick={addPriceOption} className="pl-0">
            {i18.t('product-edit-modal.add-price-option')}
          </OtoButtons.AddButton>
          <IconWithTooltip
            id="add-price-option"
            text={i18.t('product-edit-modal.add-price-option-description')}
            style={{ marginLeft: 0 }}
          />
        </div>
      </>
    );
  };

  return (
    <Modal isOpen toggle={onClose} className="product-edit-modal">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader toggle={onClose} className="px-0 pt-0">
            {i18.t('product-edit-modal.editing-product')} {values.name}
          </ModalHeader>
          <ModalBody className="px-0">
            <Input
              value={values.name}
              name="name"
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.name')}
            />
            <hr />
            <Input
              value={values.desc || ''}
              type="textarea"
              rows={4}
              name="desc"
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.description')}
            />
            {renderPrices()}
            <hr />
            {values.image && (
              <Row className="align-vertical mb-2">
                <Col xs="auto">
                  <img
                    src={values.image}
                    alt={`Zdjęcie produktu ${values.name}`}
                    style={{ maxHeight: 200 }}
                  />
                </Col>
                <Col xs="auto">
                  <OtoButtons.DeleteButton onClick={onImageRemove}>
                    {i18.t('Remove')}
                  </OtoButtons.DeleteButton>
                </Col>
              </Row>
            )}
            <CollapsibleCard buttonClassName={'p-1'} title={'Dodaj zdjęcie'}>
              {renderUploadImage()}
            </CollapsibleCard>
            <hr />
            <CollapsibleCard
              buttonClassName={'p-1'}
              title={`Dodatki (obecnie: ${
                (values.additionalFields || []).length > 0
                  ? `${values.additionalFields?.length}`
                  : 'brak'
              })`}
            >
              <FieldArray
                name="additionalFields"
                render={(arrayHelpers) => (
                  <>
                    {(values.additionalFields || []).map((field, index) => (
                      <ProductAdditionalFieldEdit
                        key={index}
                        currency={currency}
                        field={field}
                        index={index}
                        onChange={handleChange}
                        onSetSelect={(setId: string, setName: string) =>
                          arrayHelpers.replace(index, {
                            ...field,
                            value: setId,
                            name: setName,
                          })
                        }
                        onRemove={arrayHelpers.remove}
                        availableSets={restaurant.menu.addonFieldsSets || null}
                      />
                    ))}
                    <ProductAdditionalFieldAdd
                      allowSets={true}
                      onAdd={(additionalFieldType) =>
                        arrayHelpers.push(
                          getDefaultAddonFieldByType(additionalFieldType)
                        )
                      }
                    />
                  </>
                )}
              />
            </CollapsibleCard>
            <hr />
            <CollapsibleCard buttonClassName={'p-1'} title={'Etykiety'}>
              {renderLabels()}
            </CollapsibleCard>
            <hr />
            {showMenuItemAvailability && (
              <MenuItemAvailability product={values} setValues={setValues} />
            )}
          </ModalBody>
          <ModalFooter>
            {withDiscount ? (
              <Button
                color="link"
                className="pl-0 text-danger"
                onClick={removeDiscount}
              >
                <Tag size="16" />
                {i18.t('product-edit-modal.remove-discount')}
              </Button>
            ) : (
              <Button color="link" className="pl-0" onClick={setDiscount}>
                <Tag size="16" />
                {i18.t('product-edit-modal.set-discount')}
              </Button>
            )}
            <Button type="submit" color="primary">
              {i18.t('product-edit-modal.save-changes')}
            </Button>
            <Button type="button" color="secondary" onClick={onClose}>
              {i18.t('Close')}
            </Button>
          </ModalFooter>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export default ProductEditModal;

function setSingleSpacesInChoiceFields(product: MenuItem) {
  return {
    ...product,
    additionalFields: (product.additionalFields || []).map((field) => {
      if (
        field.type === AdditionalFieldTypes.SINGLE_CHOICE ||
        field.type === AdditionalFieldTypes.MULTIPLE_CHOICE
      ) {
        return {
          ...field,
          value: (field.value || '')
            .replaceAll(',', ', ')
            .replaceAll(',  ', ', '),
        };
      }
      return field;
    }),
  };
}

function getDefaultAddonFieldByType(type: AdditionalFieldTypes) {
  if (type === AdditionalFieldTypes.MULTIPLE_CHOICE) {
    return {
      type,
      max: 2,
      value: '',
    };
  }
  return { type };
}
