import React from 'react';

import { IPayout } from 'types';
import { currency } from 'globals/currency';
import Shapes from 'shapes/main';
import { formatDate } from 'utils/date-time';

interface IProps {
  payout: IPayout;
}

export const getPayoutPaidAtLabel = (payout: IPayout) => {
  if (payout.paid_at) {
    return formatDate(new Date(payout.paid_at));
  }
  if (new Date(payout.created_at) >= new Date('2023-01-01')) {
    if (payout.sum_to_payout === 0) {
      return 'nd (pusty okres)';
    }
    return payout.sum_to_payout > 0
      ? 'oczekuje na rozliczenie'
      : 'oczekuje na potrącenie';
  }
  return 'brak informacji';
};

const PayoutItem: React.FC<IProps> = ({ payout }) => {
  return (
    <>
      <div>Obrót zamówień:</div>
      <div>
        Wszystkich: {payout.orders_sum_all} {currency}
      </div>
      <div>
        Płaconych online: {payout.orders_sum_online} {currency}
      </div>
      <div>
        Płaconych przy odbiorze: {payout.orders_sum_offline} {currency}
      </div>
      <hr />
      <div>
        Prowizja netto: {payout.commission_net} {currency}
      </div>
      <div>
        Prowizja brutto: {payout.commission_brut} {currency}
      </div>
      <div>
        Koszt kodów rabatowych: {payout.promo_codes_cost} {currency}
      </div>
      <hr />
      <div>
        Kwota do wypłaty: {payout.sum_to_payout} {currency}
      </div>
      <div>Data wysłania przelewu: {getPayoutPaidAtLabel(payout)}</div>
    </>
  );
};

PayoutItem.propTypes = {
  payout: Shapes.payoutShape.isRequired,
};

export default PayoutItem;
