import React from 'react';
import { Form, FormGroup, Label, Input, Row } from 'reactstrap';
import i18next from 'i18next';

import {
  AddReservationFormValues,
  NormalizedRestaurant,
  ReservationConfig,
} from 'types';
import TimePicker from 'app/components/common/TimePicker';
import CustomerSuggestByPhone from 'app/components/reservations/CustomerSuggestByPhone';
import { checkBoxStyle } from '../styles';
import {
  canSelectDuration,
  getDurationOptions,
  hasAssignee,
  hasFlexibleTables,
  hasKids,
} from 'utils/reservation';
import { isIOS, isSafari } from 'utils/device';

interface IProps {
  contentAttachments?: React.ReactNode;
  contentFooter?: React.ReactNode;
  contentRenderFlexibleTables: (disabled?: boolean) => React.ReactNode;
  isAdmin: boolean;
  config: ReservationConfig;
  handleCustomerSelected: (customer: {
    customer_name: string;
    phone_number: string;
  }) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  restaurant: NormalizedRestaurant;
  values: AddReservationFormValues;
  updateVisitTime: (time: string) => void;
}

const AddReservationFormFull: React.FC<IProps> = (props) => {
  const {
    contentAttachments,
    contentFooter,
    contentRenderFlexibleTables,
    isAdmin,
    config,
    handleCustomerSelected,
    handleChange,
    handleSubmit,
    restaurant,
    values,
    updateVisitTime,
  } = props;

  const { assignee_list } = config;

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="customer_name">{i18next.t('reservation.guest_name')}</Label>
        <Input
          type="text"
          name="customer_name"
          id="customer_name"
          placeholder={i18next.t('reservation.guest_name')}
          className="mw-300"
          onChange={handleChange}
          value={values.customer_name}
        />
      </FormGroup>
      <Row>
        <FormGroup className="col-12 col-md-6">
          <Label for="phone_number">
            {i18next.t('reservation.phone_number')}
          </Label>
          <CustomerSuggestByPhone
            handleChange={handleChange}
            restaurant={restaurant}
            onCustomerSelected={handleCustomerSelected}
            values={values}
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-6">
          <Label for="guests_number">
            {i18next.t('reservation.guests_number')}
          </Label>
          <Input
            type="number"
            name="guests_number"
            id="guests_number"
            min="1"
            onChange={handleChange}
            value={values.guests_number}
            className="mw-125"
          />
        </FormGroup>
      </Row>
      {hasKids(config) ? (
        <FormGroup>
          <Label for="kids_number">
            {i18next.t('reservation.includes_children')}
          </Label>
          <Input
            type="number"
            name="kids_number"
            id="kids_number"
            onChange={handleChange}
            value={values.kids_number}
          />
        </FormGroup>
      ) : null}
      <Row>
        <FormGroup className="col-12 col-md-6">
          <Label for="visitDate">{i18next.t('reservation.visit_date')}</Label>
          <Input
            type="date"
            name="visitDate"
            id="visitDate"
            placeholder="Data rezerwacji"
            onChange={handleChange}
            value={values.visitDate}
            className="mw-200"
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-6">
          <Label for="visitTime">{i18next.t('reservation.visit_time')}</Label>
          {isIOS() || isSafari() ? (
            <Input
              type="time"
              name="visitTime"
              id="visitTime"
              onChange={handleChange}
              value={values.visitTime}
              className="mw-125"
            />
          ) : (
            <TimePicker
              className="d-flex flex-row align-items-center"
              defaultHour={values.visitTime.slice(0, 2)}
              defaultMin={values.visitTime.slice(3)}
              onChange={updateVisitTime}
            />
          )}
        </FormGroup>
      </Row>
      {hasAssignee(config) && (
        <FormGroup>
          <Label for="assignee">{i18next.t('reservation.assignee')}</Label>
          <Input
            type="select"
            contentEditable
            suppressContentEditableWarning
            name="assignee"
            id="assignee"
            className="mw-250"
            onChange={handleChange}
            value={values.assignee}
          >
            <option key="" value="">
              {i18next.t('Unset')}
            </option>
            {assignee_list!.map((opt: string) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
      {canSelectDuration(config) && (
        <FormGroup>
          <Label for="duration">{i18next.t('reservation.duration')}</Label>
          <Input
            type="select"
            className="mw-200"
            contentEditable
            suppressContentEditableWarning
            name="duration"
            id="duration"
            onChange={handleChange}
            value={values.duration}
          >
            {getDurationOptions(config).map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
      {hasFlexibleTables(config) && contentRenderFlexibleTables(false)}
      <FormGroup className="mb-2" check>
        <Label for="is_entire_place" check>
          <Input
            type="checkbox"
            name="is_entire_place"
            id="is_entire_place"
            style={checkBoxStyle}
            onChange={handleChange}
            checked={values.is_entire_place}
          />{' '}
          {i18next.t('add-reservation-page.reservation-exclusive')}
        </Label>
      </FormGroup>
      {isAdmin && (
        <FormGroup className="mw-300">
          <Label for="source">{i18next.t('reservation.source')}</Label>
          <Input
            type="select"
            contentEditable
            suppressContentEditableWarning
            name="source"
            id="source"
            onChange={handleChange}
            value={values.source}
          >
            <option value="dashboard">dashboard (usual)</option>
            <option value="integration">integration (no SMS)</option>
          </Input>
        </FormGroup>
      )}
      {contentAttachments}
      <FormGroup>
        <Label for="notes_from_restaurant">{i18next.t('reservation.notes_from_restaurant')}</Label>
        <Input
          type="textarea"
          name="notes_from_restaurant"
          id="notes_from_restaurant"
          onChange={handleChange}
          value={values.notes_from_restaurant || ''}
        />
      </FormGroup>
      {contentFooter}
    </Form>
  );
};

export default AddReservationFormFull;
