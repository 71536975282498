import React from 'react';
import { Row } from 'reactstrap';

import OrdersLineChart from 'app/components/statistics/OrdersLineChart';
import OrdersMostPopular from 'app/components/statistics/OrdersMostPopular';
import OrdersStatisticsSummary from 'app/components/statistics/OrdersStatisticsSummary';
import { toDateInputValue } from 'utils/date-time';
import OrdersHistory from 'app/components/statistics/OrdersHistory';
import WithDateRangeDataFetched from '../../containers/WithDateRangeDataFetched';
import StatisticsColumn from 'app/components/statistics/StatisticsColumn';
import TenantSelector from 'app/components/admin-statistics/TenantSelector';
import { hasProduct } from 'utils/restaurant';
import { OTO_PRODUCTS } from 'enums';

export default class OrderStatsPage extends React.PureComponent {
  state = {
    tenant: '',
  };

  handleTenantChange = (newTenant) =>
    this.setState({
      tenant: newTenant,
    });

  getUrl = (startDate, endDate) => {
    const { restaurant } = this.props;

    const start = toDateInputValue(startDate);
    const end = toDateInputValue(endDate);

    return [
      `/restaurants/${restaurant.id}/orders-stats/${start}/${end}?tenant=${this.state.tenant}`,
      `/restaurants/${restaurant.id}/declined-orders-stats?from=${start}&to=${end}&tenant=${this.state.tenant}`,
    ];
  };

  render() {
    const { tenant } = this.state;
    const hasValidTenantSelected =
      this.state.tenant && this.state.tenant !== 'all';

    return (
      <>
        <TenantSelector
          className="mw-300 mb-2"
          label="Zakres zamówień"
          value={tenant}
          onChange={this.handleTenantChange}
          showLesznoJe={hasProduct(
            this.props.restaurant,
            OTO_PRODUCTS.lesznoje_orders
          )}
        />
        {hasValidTenantSelected ? (
          <WithDateRangeDataFetched allowRefetch getUrl={this.getUrl}>
            {(fetchedData, { ranges }) => (
              <>
                {hasValidTenantSelected && (
                  <div>Statystyki dla {this.state.tenant}</div>
                )}
                {this.renderOrdersStats(fetchedData[0], ranges)}
                {this.renderDeclinedOrdersStats(fetchedData[1], ranges)}
                {this.renderOrdersHistory(fetchedData[0], ranges)}
              </>
            )}
          </WithDateRangeDataFetched>
        ) : (
          <div className="text-danger">Wybierz zakres zamówień!</div>
        )}
      </>
    );
  }

  renderOrdersStats(statisticsData, ranges) {
    const nestedOrders = Object.entries(statisticsData.statsByDay || {}).map(
      ([date, statItem]) => statItem.orders
    );
    const flattennedStats = nestedOrders.flat();
    return (
      <>
        <h2 className="col-12 p-0 mb-2">
          Statystyki zamówień {this.getRangesText(ranges)}
        </h2>
        <OrdersStatisticsSummary
          ordersStats={flattennedStats}
          restaurant={this.props.restaurant}
          statisticsData={statisticsData}
        />
        {!!statisticsData.statsByDay && !!ranges[0].startDate && (
          <OrdersLineChart
            className="mb-2"
            ordersData={statisticsData.statsByDay}
            startDate={ranges[0].startDate}
            endDate={ranges[0].endDate}
          />
        )}
        {statisticsData.total_orders_count && statisticsData.statsByDay && (
          <OrdersMostPopular ordersStats={flattennedStats} />
        )}
      </>
    );
  }

  renderDeclinedOrdersStats(declinedStatsData, ranges) {
    const [firstStatsRow] = declinedStatsData;
    return (
      <>
        <h2 className="col-12 p-0 mb-2">
          Odrzucone zamówienia {this.getRangesText(ranges)}
        </h2>
        <Row className="m-b-md align-items-start">
          <StatisticsColumn
            showAsCard
            value={firstStatsRow.orders_count}
            title="Liczba odrzuconych zamówień"
          />
          <StatisticsColumn
            showAsCard
            value={firstStatsRow.orders_price}
            title="Obrót odrzuconych zamówień"
          />
        </Row>
      </>
    );
  }

  renderOrdersHistory(statisticsData, ranges) {
    return (
      <>
        <h2 className="h3 mt-4">
          Historia zamówień {this.getRangesText(ranges)}
        </h2>
        <OrdersHistory data={statisticsData} />
      </>
    );
  }

  getRangesText = (ranges) =>
    `za okres ${toDateInputValue(ranges[0].startDate)} - ${toDateInputValue(
      ranges[0].endDate
    )}`;
}
