import React from 'react';
import PropTypes from 'prop-types';
import {
  withFormik,
  Form,
  FormikBag,
  FormikProps,
  FieldArray,
  FormikErrors,
  FieldArrayRenderProps,
} from 'formik';
import { toast } from 'react-toastify';
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Alert,
} from 'reactstrap';

import { logActivity, stringifyEntity, logError } from 'utils/log';
import { put } from 'services/api';
import { getRestaurant, setRestaurant } from 'services/persistent';
import { CustomRule } from 'types';
import Shapes from 'shapes/main';

const newRule = {
  key: 'visitDate',
  values: '',
  disableBook: true,
  disableOrder: true,
  text: '',
};

const getRuleLabel = (rule: TCustomRulesWithValuesAsString) => {
  if (rule.disableBook && rule.disableOrder) {
    return 'blokada zamówień i rezerwacji';
  }
  if (rule.disableOrder) {
    return 'blokada zamówień';
  }
  if (rule.disableBook) {
    return 'blokada rezerwacji';
  }
  return 'tylko komunikat';
};

type TOwnProps = {
  restaurantId: number;
  rules: CustomRule[] | null;
};

type TCustomRulesWithValuesAsString = Omit<CustomRule, 'values'> & {
  values: string;
};

interface ClosedDaysFormValues {
  rules: TCustomRulesWithValuesAsString[];
}

type TProps = FormikProps<ClosedDaysFormValues> & TOwnProps;

class ClosedDaysForm extends React.PureComponent<TProps, {}> {
  static propTypes = {
    rules: PropTypes.arrayOf(Shapes.customRuleShape.isRequired),
  };

  render() {
    return (
      <Form>
        <FieldArray name="rules" render={this.renderRulesArray} />
      </Form>
    );
  }

  renderRulesArray = (arrayHelpers: FieldArrayRenderProps) => {
    const { values, handleChange, isSubmitting } = this.props;
    const rules = values.rules || [];
    return (
      <>
        {rules.map((rule, index) => (
          <Card key={index} outline color={rule.text ? undefined : 'danger'}>
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5 className="bb-1">
                Dzień wyjątkowy #{index + 1} ({getRuleLabel(rule)})
              </h5>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => arrayHelpers.remove(index)}
              >
                Usuń
              </button>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label for={`rules.${index}.values`}>
                  Daty (w formacie 2020-01-10, oddzielone przecinkami)
                </Label>
                <Input
                  type="text"
                  name={`rules.${index}.values`}
                  onChange={handleChange}
                  value={rule.values}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  className="d-block w-full"
                  for={`rules.${index}.disableBook`}
                >
                  Rodzaj dnia wyjątkowego
                </Label>
                <Label className="ml-3">
                  <Input
                    type="checkbox"
                    name={`rules.${index}.disableBook`}
                    onChange={handleChange}
                    checked={rule.disableBook}
                  />
                  Zablokuj możliwość rezerwacji
                </Label>
                <Label className="ml-3">
                  <Input
                    type="checkbox"
                    name={`rules.${index}.disableOrder`}
                    onChange={handleChange}
                    checked={rule.disableOrder || false}
                  />
                  Zablokuj możliwość zamówień
                </Label>
              </FormGroup>
              <FormGroup>
                <Label for={`rules.${index}.text`}>Komunikat (wymagany)</Label>
                <Input
                  type="textarea"
                  name={`rules.${index}.text`}
                  onChange={handleChange}
                  value={rule.text || ''}
                  placeholder="np. Drodzy goście, w ten poniedziałek restauracja jest zamknięta. Zapraszamy od wtorku :)"
                />
              </FormGroup>
            </CardBody>
          </Card>
        ))}
        {rules.filter((rule) => !rule.text).length > 0 && (
          <Alert color="warning">
            Jeden lub więcej dni wyjątkowych nie ma ustawionego komunikatu.
            Zostanie wyświetlony komunikat o tym, że restauracja jest zamknięta.
          </Alert>
        )}
        {isSubmitting ? (
          <Spinner color="primary" />
        ) : (
          <>
            <button type="submit" className="btn btn-primary">
              Zapisz
            </button>
            <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={() => arrayHelpers.push(newRule)}
            >
              Dodaj nowy
            </button>
          </>
        )}
      </>
    );
  };
}

const convertRuleToRuleWithValuesAsString = (
  rule: CustomRule
): TCustomRulesWithValuesAsString => ({
  ...rule,
  values: rule.values.join(', '),
});

const mapPropsToValues = (props: TOwnProps): ClosedDaysFormValues => {
  const values: ClosedDaysFormValues = {
    rules: (props.rules || []).map(convertRuleToRuleWithValuesAsString),
  };
  return values;
};

const validate = (): FormikErrors<ClosedDaysFormValues> => ({});

const handleSubmit = (
  values: ClosedDaysFormValues,
  { setSubmitting, props }: FormikBag<TOwnProps, ClosedDaysFormValues>
) => {
  const { restaurantId } = props;
  const newRules = values.rules.map((rule) => ({
    ...rule,
    values: rule.values.trim().split(','),
  }));
  const url = `/restaurants/${restaurantId}`;
  put(url, {
    custom_rules: newRules,
  })
    .then((restaurant) => {
      setRestaurant(restaurant);
      toast.success('Dni wyjątkowe zostały sukcesywnie zaktualizowane');
      logActivity(
        `Dni wyjątkowe zostały zaktualizowane.\n ${stringifyEntity(newRules)}`
      );
      setSubmitting(false);
    })
    .catch((e) => {
      toast.error('Problem zapisu dni wyjątkowych');
      logError('ClosedDaysForm update fail', e);
      setSubmitting(false);
    });
};

export { ClosedDaysForm };
export default withFormik<TOwnProps, ClosedDaysFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  displayName: 'ClosedDaysForm',
})(ClosedDaysForm);
