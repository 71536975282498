import { combineReducers } from 'redux';

import adminReducer, { IAdminReducerState } from './admin';
import appReducer, { IAppReducerState } from './app';
import customersReducer, { CustomersReducerState } from './customers';
import deliveriesReducer, { IDeliveryReducerState } from './deliveries';
import ordersReducer, { IOrdersReducerState } from './orders';
import reservationsReducer, { IReservationsReducerState } from './reservations';
import restaurantReducer, { IRestaurantReducerState } from './restaurant';
import promoCodesReducer, { IPromoCodesReducerState } from './promoCodes';
import tenantsReducer, { ITenantsReducerState } from './tenants';
import {
  addReservationReducer,
  IReservationCalendarReducerState,
} from './reservation-calendar';

export interface IAppState {
  admin: IAdminReducerState;
  app: IAppReducerState;
  customers: CustomersReducerState;
  deliveries: IDeliveryReducerState;
  orders: IOrdersReducerState;
  reservations: IReservationsReducerState;
  reservationsCalendar: IReservationCalendarReducerState;
  restaurant: IRestaurantReducerState;
  promoCodes: IPromoCodesReducerState;
  tenants: ITenantsReducerState;
}

const mainReducer = combineReducers({
  admin: adminReducer,
  app: appReducer,
  customers: customersReducer,
  deliveries: deliveriesReducer,
  orders: ordersReducer,
  reservations: reservationsReducer,
  reservationsCalendar: addReservationReducer,
  restaurant: restaurantReducer,
  promoCodes: promoCodesReducer,
  tenants: tenantsReducer,
});

export default mainReducer;
