import React from 'react';
import PropTypes from 'prop-types';
import { connect, ConnectedProps } from 'react-redux';
import i18 from 'i18next';
import { FormGroup, Label, Input, Col, Row } from 'reactstrap';
import { Form, FormikProps, withFormik } from 'formik';
import { Clock } from 'react-feather';
import { toast } from 'react-toastify';

import { logImportantActivity, LOG_BAG } from 'utils/log';
import { updateRestaurantOpeningHours } from 'store/restaurant';
import { days } from 'utils/date-time';
import CollapsibleCard from '../common/CollapsibleCard';
import { col10Style } from '../../styles';
import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import { selectRestaurant } from 'store/restaurant';
import InputWithAddon from '../common/InputWithAddon';
import { IAppState } from 'store/main';
import {
  NormalizedRestaurant,
  RestaurantOpeningHour,
} from 'types';
import Shapes from 'shapes/main';

export enum OpeningHoursType {
  DELIVERY = 'delivery',
  RESTAURANT = 'restaurant',
}

const mapDefaultHours = (
  hours: RestaurantOpeningHour[],
  type: OpeningHoursType
) => {
  let defaultValues: RestaurantOpeningHour[] = [];

  if (!hours || !hours.find) {
    hours = [];
    logImportantActivity(
      `Hours are not defined: ${JSON.stringify(hours)}, type is: ${type}`
    );
  }

  for (let dayIndex = 0; dayIndex <= 6; dayIndex++) {
    // @ts-ignore this worked before TS refactor
    let currentHours: RestaurantOpeningHour =
      hours.find((e) => e.open_day === dayIndex) ?? {};

    // @ts-ignore this worked before TS refactor
    defaultValues.push({
      open_day: dayIndex,
      is_closed: currentHours.is_closed ?? true,
      open_at: currentHours.open_at ?? '12:00',
      close_at: currentHours.close_at ?? '21:00',
    });
  }

  return defaultValues;
};

interface IOwnProps {
  type: OpeningHoursType;
}

interface OpeningHoursFormValues {
  hours: any[];
}

type TPropsWithoutFormik = IOwnProps & PropsFromRedux;

type TProps = TPropsWithoutFormik & FormikProps<OpeningHoursFormValues>;

const COMPONENT_NAME = 'OpeningHoursForm';

class OpeningHoursForm extends React.PureComponent<TProps, {}> {
  static propTypes = {
    restaurant: Shapes.restaurantShape.isRequired,
    type: PropTypes.oneOf(Object.values(OpeningHoursType)).isRequired,
  };

  static getDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  render() {
    const { values, isSubmitting, type } = this.props;

    return (
      <Row>
        <Col md={col10Style}>
          <CollapsibleCard
            title={
              <div>
                <Clock className="mr-2" />
                {i18.t(`settings.${type}-opening-hours.title`)}
              </div>
            }
            bodyClassName="pt-0"
          >
            <Form>
              {values.hours.map((day, index) =>
                this.renderHourItem(day, index)
              )}
              <SubmitButtonWithLoader className="mt-3" loading={isSubmitting} />
            </Form>
          </CollapsibleCard>
        </Col>
      </Row>
    );
  }

  renderHourItem(day, index) {
    const { handleChange } = this.props;
    return (
      <FormGroup
        key={index}
        row
        className="align-items-center border-top my-0 py-2"
      >
        <Label xs="auto" sm={4} xl={3}>
          <strong className="d-block font-weight-bold text-black">
            {i18.t(days[index])}
          </strong>
        </Label>
        <Col xs="auto" sm={8} xl={3}>
          <FormGroup check>
            <Label check>
              <Input
                name={`hours[${index}].is_closed`}
                type="checkbox"
                checked={day.is_closed}
                onChange={handleChange}
              />
              {i18.t(`hours.closed`)}
            </Label>
          </FormGroup>
        </Col>
        {!day.is_closed && (
          <>
            <Input
              type="hidden"
              name={`hours[${index}].open_day`}
              value={index}
            />
            <Col xs={12} sm={6} xl={3} className="mb-2">
              {this.renderTimeInput(
                `hours[${index}].open_at`,
                i18.t('hours.from'),
                day.open_at
              )}
            </Col>
            <Col xs={12} sm={6} xl={3} className="mb-2">
              {this.renderTimeInput(
                `hours[${index}].close_at`,
                i18.t('hours.to'),
                day.close_at
              )}
            </Col>
          </>
        )}
      </FormGroup>
    );
  }

  renderTimeInput(name, label, value) {
    const { handleChange } = this.props;
    return (
      <InputWithAddon
        addonText={label}
        type="time"
        name={name}
        onChange={handleChange}
        value={value}
      />
    );
  }
}

const OpeningHoursFormWithFormik = withFormik<
  TPropsWithoutFormik,
  OpeningHoursFormValues
>({
  mapPropsToValues: (props: TPropsWithoutFormik) => ({
    hours: mapDefaultHours(
      props.restaurant[
        `${props.type}_opening_hours`
      ] as RestaurantOpeningHour[],
      props.type
    ),
  }),
  handleSubmit: (values: OpeningHoursFormValues, { setSubmitting, props }) => {
    const onSuccess = () =>
      toast.success(
        i18.t(`settings.${props.type}-opening-hours.success-toast`)
      );

    const payload = {
      ...values,
      type: props.type,
    };

    return props
      .updateRestaurantOpeningHours(payload, props.restaurant.id, onSuccess)
      .finally(() => setSubmitting(false));
  },
  displayName: COMPONENT_NAME,
})(OpeningHoursForm);

const mapStateToProps = (state: IAppState) => ({
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
});

const connector = connect(mapStateToProps, { updateRestaurantOpeningHours });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OpeningHoursFormWithFormik);
