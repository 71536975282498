import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import SimpleTableWithSorting, {
  generateColumn,
  getRTOriginal,
  ReactTableCellProps,
} from '../common/SimpleTableWithSorting';
import { IRobot } from 'types/robot';
import Shapes from 'shapes/main';

interface IProps {
  className?: string;
  header: React.ReactNode;
  robots: IRobot[];
  showUserColumn?: boolean;
}

const robotsColumns = [
  generateColumn('id'),
  generateColumn('name'),
  generateColumn('ip', {
    customHeader: 'IP',
    customCellFormatter: (props: ReactTableCellProps<IRobot>) => {
      const robot = getRTOriginal<IRobot>(props);
      return <a href={robot.ip}>{robot.ip}</a>;
    },
  }),
  generateColumn('status'),
];

const robotsColumnsWithUser = [
  ...robotsColumns,
  generateColumn('user', {
    customHeader: 'Operator',
    customCellFormatter: (props: ReactTableCellProps<IRobot>) => {
      const robot = getRTOriginal<IRobot>(props);
      return (
        <div>
          {robot.user
            ? `${robot.user.name} (${robot.user.id})`
            : 'No assigned user'}
        </div>
      );
    },
  }),
];

const RobotsList = (props: IProps) => {
  const { className, header, robots, showUserColumn } = props;
  return (
    <>
      <h1>{header}</h1>
      <SimpleTableWithSorting
        className={className}
        columns={showUserColumn ? robotsColumnsWithUser : robotsColumns}
        data={robots}
      />
      {robots && !robots.length && (
        <Row>
          <Col xs="12">Nie masz przypisanych do siebie robotów.</Col>
        </Row>
      )}
    </>
  );
};

RobotsList.propTypes = {
  className: PropTypes.string,
  header: PropTypes.node.isRequired,
  robots: PropTypes.arrayOf(Shapes.robotShape).isRequired,
  showUserColumn: PropTypes.bool,
};

RobotsList.defaultProps = {
  className: '',
  showUserColumn: false,
};

RobotsList.displayName = 'RobotsList';

export default RobotsList;
