import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

type OtoAutocompleteCommonProps = {
  emptyRecordMsg?: string;
  disabled?: boolean;
  isSingle?: boolean;
  placeholder?: string;
  style?: {
    multiselectContainer?: React.CSSProperties;
    searchBox?: React.CSSProperties;
    inputField?: React.CSSProperties;
    chips?: React.CSSProperties;
    optionContainer?: React.CSSProperties;
    option?: React.CSSProperties;
    groupHeading?: React.CSSProperties;
  };
};

type TOption = Record<string, unknown>;

type OtoAutocompleteObjectProps = {
  isObject: true;
  fieldToDisplay: string;
  options: TOption[];
  onChange: (item: TOption) => void;
  value?: TOption | null;
};

type OtoAutocompleteStringProps = {
  isObject: false;
  fieldToDisplay?: never;
  options: string[];
  onChange: (value: string) => void;
  value?: string | null;
};

export type OtoAutocompleteProps = OtoAutocompleteCommonProps &
  (OtoAutocompleteObjectProps | OtoAutocompleteStringProps);

const OtoAutocomplete: React.FC<OtoAutocompleteProps> = ({
  disabled,
  isSingle = false,
  isObject,
  fieldToDisplay,
  options,
  onChange,
  value,
  ...rest
}) => {
  return (
    <Multiselect
      options={options}
      selectionLimit={isSingle ? 1 : undefined}
      selectedValues={value ? [value] : []}
      onSelect={(selectedValues, selectedValue) =>
        isSingle ? onChange(selectedValue) : onChange(selectedValues)
      }
      onRemove={(selectedValues, selectedValue) =>
        isSingle ? onChange(selectedValue) : onChange(selectedValues)
      }
      displayValue={fieldToDisplay ? fieldToDisplay : undefined}
      {...rest}
    />
  );
};

export default OtoAutocomplete;
