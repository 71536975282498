import React from 'react';
import i18next from 'i18next';

import { PromoCodeIssuers, PromoCodeTypes } from 'enums';
import { currency } from 'globals/currency';
import { IOrder, IPromoCode } from 'types';

type PromoCodeTextProps = {
  order: IOrder;
  showHint?: boolean;
};

const PromoCodeText: React.FC<PromoCodeTextProps> = (props) => {
  const { order, showHint = false } = props;
  const code = order.promo_code;
  if (!code) {
    return null;
  }
  return (
    <span className="text-danger">
      {i18next.t('order.promo-code-used')}: {getPromoCodeLabel(code)}
      {!!code.issuer && (
        <span className="small">
          {' '}
          {i18next.t(`order.promo-code-issuer.${code.issuer}`)}
        </span>
      )}
      {showHint &&
        !!code.issuer &&
        code.issuer !== PromoCodeIssuers.restaurant && (
          <div>
            Nabij na POS zamówienie w całości, wartość kodu rabatowego zostanie
            uwzględniona przy rozliczeniu. W wypadku płatności przy odbiorze -
            uwzględnij wartość kodu przy pobieraniu zapłaty.
          </div>
        )}
    </span>
  );
};

export function getPromoCodeLabel(promoCode: IPromoCode) {
  const { code, discount_type: type } = promoCode;
  if (code === '5-10-15') {
    return 'Rabat od 5 do 15 zł';
  }
  if (type === PromoCodeTypes.freeDelivery) {
    return i18next.t('order.promo-code-type.freeDelivery');
  }
  if (type === PromoCodeTypes.value) {
    return i18next.t('order.promo-code-type.value', {
      discount: promoCode.discount,
      currency,
    });
  }
  if (type === PromoCodeTypes.percent) {
    return i18next.t('order.promo-code-type.percent', {
      discount: promoCode.discount,
    });
  }
  return null;
}
export default PromoCodeText;
