import React from 'react';
import { PageLoaderProvider } from '../../PageLoader/PageLoaderContext';
import { PageAlertProvider } from '../../PageAlert/PageAlertContext';
import { ModalContextProvider } from '../../../containers/ModalContext';


export default function ContextProviders({ children }) {
  return (
    <PageLoaderProvider>
      <PageAlertProvider>
        <ModalContextProvider>
          {children}
        </ModalContextProvider>
      </PageAlertProvider>
    </PageLoaderProvider>
  );
}
