import React from 'react';
import { Col } from 'reactstrap';

import { NormalizedRestaurant } from 'types';
import ClosedDaysForm from 'app/components/ClosedDaysForm';
import ErrorBoundary from '../containers/ErrorBoundary';
import { col10Style, col8Style } from '../styles';
import type { PageComponent } from '../types-global';
import { useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';
import UpdatesProvider from 'app/containers/UpdatesProvider';
import { UpdateTarget } from 'enums';

const SpecialDaysSettings: PageComponent<{}> = () => {
  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant;
  const { custom_rules: rules, name } = restaurant;
  return (
    <>
      <UpdatesProvider
        restaurant={restaurant}
        target={UpdateTarget.CustomRules}
      >
        <Col lg={col10Style} xl={col8Style}>
          <h3 className="h4 mt-2">Dni wyjątkowe w {name}</h3>
          <ErrorBoundary componentName="ClosedDaysForm">
            <ClosedDaysForm
              restaurantId={restaurant.id}
              rules={
                rules && rules.filter
                  ? rules.filter((rule) => rule.key === 'visitDate')
                  : null
              }
            />
          </ErrorBoundary>
        </Col>
      </UpdatesProvider>
    </>
  );
};

SpecialDaysSettings.navName = 'Dni wyjątkowe';
SpecialDaysSettings.url = '/settings/special-days';

SpecialDaysSettings.displayName = 'SpecialDaysSettings';

export default SpecialDaysSettings;
