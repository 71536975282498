import type {
  IRestaurantPlaceArea,
  TRestaurantPlacesAreaConfig,
} from 'types';

import image2PierMainHall from './2pier-widok-wnetrze.png';
import image2PierTerraceHall from './2pier-widok-taras.jpg';

const mainHallAreaFor2PiEr: IRestaurantPlaceArea = {
  tablesConfig: {
    628: {
      position: {
        y: '66.25%',
        x: '10.75%',
      },
    },
    629: {
      position: {
        y: '50.5%',
        x: '10.95%',
      },
    },
    630: {
      position: {
        x: '10.95%',
        y: '35%',
      },
    },
    631: {
      position: {
        x: '10.95%',
        y: '19%',
      },
    },
    632: {
      position: {
        x: '22%',
        y: '11%',
      },
      size: {
        height: '17.5%',
        width: '11%',
      },
    },
    633: {
      position: {
        x: '34.5%',
        y: '11%',
      },
    },
    634: {
      position: {
        x: '48%',
        y: '11%',
      },
    },
    635: {
      position: {
        x: '62%',
        y: '11%',
      },
    },
    636: {
      position: {
        x: '75%',
        y: '27%',
      },
    },
    637: {
      position: {
        x: '67.5%',
        y: '85%',
      },
      size: {
        width: '12%',
      },
    },
    638: {
      position: {
        x: '75%',
        y: '50%',
      },
      size: {
        height: '22.5%',
      },
    },
    639: {
      position: {
        x: '51.5%',
        y: '73%',
      },
      size: {
        height: '22.5%',
      },
    },
    640: {
      position: {
        x: '35.5%',
        y: '73%',
      },
      size: {
        height: '22.5%',
      },
    },
    641: {
      position: {
        x: '35.5%',
        y: '32.5%',
      },
      size: {
        height: '22.5%',
      },
    },
    642: {
      position: {
        x: '51.5%',
        y: '32.5%',
      },
      size: {
        height: '22.5%',
      },
    },
  },
  image: {
    src: image2PierMainHall,
    width: 775,
    height: 544,
  },
  tableSize: {
    width: '9%',
    height: '14%',
  },
  styles: {
    minHeight: 400,
    width: '100%',
  },
};

const terraceAreaFor2PiEr: IRestaurantPlaceArea = {
  image: {
    src: image2PierTerraceHall,
    width: 669,
    height: 489,
  },
  tablesConfig: {
    643: {
      position: {
        x: '58.21%',
        y: '60.8%',
      },
    },
    644: {
      position: {
        x: '60.09%',
        y: '34.83%',
      },
    },
    645: {
      position: {
        x: '46.42%',
        y: '36.5%',
      },
    },
    646: {
      position: {
        x: '33.77%',
        y: '42.54%',
      },
    },
    647: {
      position: {
        x: '37.55%',
        y: '62.21%',
      },
    },
    648: {
      position: {
        x: '23.02%',
        y: '90.62%',
      },
    },
    649: {
      position: {
        x: '37.55%',
        y: '90.75%',
      },
    },
    651: {
      position: {
        x: '1.79%',
        y: '79.69%',
      },
    },
    656: {
      position: {
        x: '3%',
        y: '15%',
      },
      size: {
        height: '21%',
      },
    },
    657: {
      position: {
        x: '12.75%',
        y: '15%',
      },
      size: {
        height: '21%',
      },
    },
    658: {
      position: {
        x: '22%',
        y: '15%',
      },
      size: {
        height: '21%',
      },
    },
    659: {
      position: {
        x: '31.75%',
        y: '15%',
      },
      size: {
        height: '21%',
      },
    },
    660: {
      position: {
        x: '3.4%',
        y: '42.54%',
      },
    },
    661: {
      position: {
        x: '13.02%',
        y: '42.29%',
      },
    },
    662: {
      position: {
        x: '22.83%',
        y: '42.93%',
      },
    },
    665: {
      position: {
        x: '43.5%',
        y: '14.75%',
      },
      size: {
        width: '7.5%',
      },
    },
    666: {
      position: {
        x: '51.5%',
        y: '14.75%',
      },
      size: {
        width: '7.5%',
      },
    },
    667: {
      position: {
        x: '59%',
        y: '14.75%',
      },
      size: {
        width: '7.5%',
      },
    },
    668: {
      position: {
        x: '67%',
        y: '14.75%',
      },
      size: {
        width: '7.5%',
      },
    },
    669: {
      position: {
        x: '74.75%',
        y: '14.75%',
      },
      size: {
        width: '7.5%',
      },
    },
    670: {
      position: {
        x: '82.75%',
        y: '14.75%',
      },
      size: {
        width: '7.5%',
      },
    },
    671: {
      position: {
        x: '85.25%',
        y: '29%',
      },
    },
    672: {
      position: {
        x: '85.25%',
        y: '43%',
      },
    },
    673: {
      position: {
        x: '85.25%',
        y: '57%',
      },
    },
    674: {
      position: {
        x: '85.25%',
        y: '71%',
      },
    },
    675: {
      position: {
        x: '81%',
        y: '84.5%',
      },
      size: {
        height: '13%',
        width: '15.5%',
      },
    },
  },
  tableSize: {
    width: '9%',
    height: '14%',
  },
};

export const _2pierAreasConfig: TRestaurantPlacesAreaConfig = {
  100: mainHallAreaFor2PiEr,
  101: terraceAreaFor2PiEr,
};
