import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'reactstrap';
import { addDays } from 'date-fns';

import Shapes from 'shapes/main';
import { OTO_PRODUCTS } from 'enums';
import OtoToggle from '../common/OtoToggle';
import { MenuValidators } from '../menu/MenuErrors';
import { YesNoIcons } from '../icons/IconSets';
import { OriginalRestaurant } from 'types/restaurants';
import { isEmptyAndNotZero } from 'utils/validators';
import { areOrdersEnabled } from 'utils/orders-config';
import { hasAnyOrders, hasProduct } from 'utils/restaurant';

const RestaurantValidator = {
  isDebug: (r: OriginalRestaurant) => r.status === 'debug',
  hasNoOrders: (r: OriginalRestaurant) => !hasProduct(r, OTO_PRODUCTS.orders),
  hasDisabledOrders: (r: OriginalRestaurant) =>
    !areOrdersEnabled(r?.config?.orders),
  hasDisabledOrdersOverXDays: (daysNum: number) => {
    return (r: OriginalRestaurant) => {
      if (
        areOrdersEnabled(r?.config?.orders) ||
        !r?.config?.orders?.allowOrdersChangedAt
      ) {
        return false;
      }
      return (
        new Date(r?.config?.orders?.allowOrdersChangedAt) <
        addDays(new Date(), -daysNum)
      );
    };
  },
  hasNoOrdersConfig: (r: OriginalRestaurant) => !r.config || !r.config.orders,
  hasOrdersButNoCoordinates: (r: OriginalRestaurant) => {
    if (!r.config || !r.config.orders) {
      return false;
    }
    return (
      !r.config.orders.delivery ||
      !r.lat ||
      !r.lng
    );
  },
  hasEmptyCuisineLabel: (r: OriginalRestaurant) =>
    !r.cuisine_label || !r.cuisine_label.length,
  hasEmptyImages: (r: OriginalRestaurant) => !r.images || !r.images.length,
};

interface IProps {
  restaurants: OriginalRestaurant[];
}

const AdminRestaurantValidator: React.FC<IProps> = (props) => {
  const { restaurants } = props;
  const [showStats, setShowStats] = useState(false);
  const [showDebugRestaurants, setShowDebugRestaurants] = useState(false);

  useEffect(() => {
    setShowStats(false);
  }, [restaurants]);

  if (!showStats) {
    return (
      <Card body>
        <Button
          color="primary"
          className="mw-300"
          onClick={() => setShowStats(true)}
        >
          Pokaż problemy w wybranych restauracjach ({restaurants.length} lokali)
        </Button>
      </Card>
    );
  }

  const debugRestaurants = restaurants.filter(RestaurantValidator.isDebug);

  const notDebugRestaurants = restaurants.filter(
    (r) => !RestaurantValidator.isDebug(r)
  );
  const restaurantsToFilter = showDebugRestaurants
    ? restaurants
    : notDebugRestaurants;

  const haveDisabledOrders = restaurantsToFilter.filter(
    RestaurantValidator.hasDisabledOrders
  );

  const haveDisabledOrdersForOver2Days = restaurantsToFilter.filter(
    RestaurantValidator.hasDisabledOrdersOverXDays(2)
  );

  const haveDisabledOrdersForOver5Days = restaurantsToFilter.filter(
    RestaurantValidator.hasDisabledOrdersOverXDays(5)
  );

  const haveNoOrders = restaurantsToFilter.filter(
    RestaurantValidator.hasNoOrders
  );
  const haveNoOrdersConfig = restaurantsToFilter.filter(
    RestaurantValidator.hasNoOrdersConfig
  );
  const hasOrdersButNoCoordinates = restaurantsToFilter.filter(
    RestaurantValidator.hasOrdersButNoCoordinates
  );
  const haveEmptyCuisineLabel = restaurantsToFilter.filter(
    RestaurantValidator.hasEmptyCuisineLabel
  );
  const haveEmptyImages = restaurantsToFilter.filter(
    RestaurantValidator.hasEmptyImages
  );

  const hasNoMenu = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      !r.menu || !r.menu.categories || !r.menu.categories.length
  );
  const hasDuplicatedCategories = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.hasDuplicateCategoryNames(r.menu?.categories || [])
  );
  const hasNoNameProducts = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithNoName(r.menu?.categories || []).length > 0
  );
  const hasZeroPriceProducts = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithEmptyPrices(r.menu?.categories || [])
        .length > 0
  );
  const hasProductsWithUnknownDisabledStatus = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getUnknownDisabledProducts(r).length > 0
  );
  const hasDuplicatedAddonNamesProducts = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithDuplicatedAddonNames(
        r.menu?.categories || []
      ).length > 0
  );
  const hasInvalidChoices = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithInvalidChoices(r.menu?.categories || [])
        .length > 0
  );
  const hasAddonsWithoutNames = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithAdditionalFieldsWithoutNames(
        r.menu?.categories || []
      ).length > 0
  );
  const hasCheckboxAddonsWithNoPrice = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithInvalidAddons(r.menu?.categories || [])
        .length > 0
  );

  const hasSetsWithInvalidAddons = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      Object.keys(
        MenuValidators.getSetsWithAddonsWithPriceError(
          r.menu?.addonFieldsSets || []
        )
      ).length > 0
  );

  const hasProductsWithMoreThan5AddonsNotInSet = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithInlineAddonsAmount(
        r.menu?.categories || [],
        5
      ).length > 0
  );

  const hasProductsWithMoreThan10AddonsNotInSet = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      MenuValidators.getProductsWithInlineAddonsAmount(
        r.menu?.categories || [],
        10
      ).length > 0
  );

  const hasNoOldDeliveryHours = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      !r.config?.orders?.deliveryHours ||
      r.config?.orders?.deliveryHours.length === 0
  );
  const hasNoNewDeliveryHours = restaurantsToFilter.filter(
    (r: OriginalRestaurant) =>
      !r.delivery_opening_hours || r.delivery_opening_hours.length === 0
  );
  const hasDifferentDeliveryHours = restaurantsToFilter.filter(
    (r: OriginalRestaurant) => {
      const { delivery_opening_hours } = r;
      const { deliveryHours } = r.config?.orders || {};
      if (!deliveryHours || !delivery_opening_hours) {
        return false;
      }
      if (deliveryHours.length !== delivery_opening_hours.length) {
        return true;
      }
      const formatHour = (hour) => (hour < 10 ? `0${hour}` : hour);
      const formatMin = (min) => (min < 10 ? `0${min}` : min);
      const differentHoursAmount = deliveryHours.filter((hourPair, index) => {
        if (hourPair.isClosed && delivery_opening_hours[index].is_closed) {
          return false;
        }
        return (
          `${formatHour(hourPair.open.hour)}:${formatMin(
            hourPair.open.min
          )}` !== delivery_opening_hours[index].open_at ||
          `${formatHour(hourPair.close.hour)}:${formatMin(
            hourPair.close.min
          )}` !== delivery_opening_hours[index].close_at
        );
      }).length;
      return differentHoursAmount > 0;
    }
  );

  const hasNoAreas = restaurantsToFilter.filter((r: OriginalRestaurant) => {
    const { delivery } = r.config?.orders || {};
    if (!delivery) {
      return false;
    }
    return !delivery.area || !delivery.areaOptions;
  });
  const hasDynamicDeliveryPrice = restaurantsToFilter.filter(
    (r: OriginalRestaurant) => {
      const { delivery } = r.config?.orders || {};
      if (!delivery) {
        return false;
      }
      return delivery.dynamicDeliveryPricing;
    }
  );
  const hasDifferentAreaFeaturesVsOptions = restaurantsToFilter.filter(
    (r: OriginalRestaurant) => {
      const { delivery } = r.config?.orders || {};
      const features = delivery?.area?.features || [];
      const options = delivery?.areaOptions || [];
      return options.length !== features.length;
    }
  );
  const _hasEmptyFieldInAreaOptions = (field: string) =>
    restaurantsToFilter.filter((r: OriginalRestaurant) => {
      const { delivery } = r.config?.orders || {};
      const options = delivery?.areaOptions || [];
      return options.filter((areaOption) =>
        isEmptyAndNotZero(areaOption[field])
      ).length;
    });
  const hasAreaOptionsWithNoPrice = _hasEmptyFieldInAreaOptions('price');
  const hasAreaOptionsWithNoMinCart =
    _hasEmptyFieldInAreaOptions('minCartAmount');
  const hasAreaOptionsWithNoFreeDelivery =
    _hasEmptyFieldInAreaOptions('freeDeliveryFrom');

  const renderRow = (label, restaurants) => (
    <div>
      <span className="mr-2">
        {restaurants.length > 0 ? YesNoIcons.NO : YesNoIcons.YES}
      </span>
      {label} - {restaurants.length} sztuk
      {restaurants.length > 0 &&
        `: ${restaurants
          .map((r: OriginalRestaurant) => `${r.name} (${r.id})`)
          .join(', ')}`}
    </div>
  );

  return (
    <Card body>
      <h2>Błędy w restauracjach</h2>
      <OtoToggle
        id="showDebug"
        name="showDebug"
        label="Pokazuj błędy w nowych (debug) restauracjach"
        checked={showDebugRestaurants}
        onChange={(e) => setShowDebugRestaurants(e.target.checked)}
      />
      <hr />
      <h3>Ogólne / powodujące problemy techniczne</h3>
      {renderRow('Nie włączone (debug)', debugRestaurants)}
      {renderRow('Zamówienia wstrzymane', haveDisabledOrders)}
      {renderRow(
        'Zamówienia wstrzymane od 2+ dni',
        haveDisabledOrdersForOver2Days
      )}
      {renderRow(
        'Zamówienia wstrzymane od 5+ dni',
        haveDisabledOrdersForOver5Days
      )}
      {renderRow('Nie włączone (debug)', debugRestaurants)}
      {renderRow("Nie mają 'orders' w products", haveNoOrders)}
      {renderRow('Nie mają orders config', haveNoOrdersConfig)}
      {renderRow(
        'Mają orders, ale nie mają współrzędnych',
        hasOrdersButNoCoordinates
      )}
      <hr />
      <h3>Związane z rozliczeniami</h3>
      {renderRow(
        'Mają orders, ale nie mają lubje, lesznoje, miedzioweje, iframe, website',
        restaurantsToFilter.filter(
          (r) => hasProduct(r, OTO_PRODUCTS.orders) && !hasAnyOrders(r)
        )
      )}
      <hr />
      <h3>
        Związane z wyświetlaniem na liście (źle się je wyszukuje, lub gorzej
        wyglądają)
      </h3>
      {renderRow(
        'Nie mają, lub mają puste cuisine label',
        haveEmptyCuisineLabel
      )}
      {renderRow('Nie mają ustawionych zdjęć', haveEmptyImages)}
      <hr />
      <h3>Związane z konfiguracją godzin</h3>
      {renderRow(
        'Mają brak ustawionych godzin dostaw (stare, deliveryHours)',
        hasNoOldDeliveryHours
      )}
      {renderRow(
        'Mają brak ustawionych godzin dostaw (nowe, restaurant_delivery_hours)',
        hasNoNewDeliveryHours
      )}
      {renderRow(
        'Godziny dostaw (stare a nowe) się różnią',
        hasDifferentDeliveryHours
      )}
      <hr />
      <h3>Związane z konfiguracją stref</h3>
      {renderRow('Nie mają ustawionych stref', hasNoAreas)}
      {renderRow(
        'Mają dynamicDeliveryPrice = true (stare pole do wyłąćzenia - zamienić na strefy)',
        hasDynamicDeliveryPrice
      )}
      {renderRow(
        'Mają różną liczbę narysowanych stref a konfiguracji stref',
        hasDifferentAreaFeaturesVsOptions
      )}
      {renderRow('Mają strefy z pustymi cenami', hasAreaOptionsWithNoPrice)}
      {renderRow(
        'Mają strefy z pustymi polami min. koszyk',
        hasAreaOptionsWithNoMinCart
      )}
      {renderRow(
        'Mają strefy z pustymi polami darmowa dostawa od',
        hasAreaOptionsWithNoFreeDelivery
      )}
      <hr />
      <h3>Związane z menu</h3>
      {renderRow('Brak menu', hasNoMenu)}
      {renderRow('Mają zduplikowane kategorie', hasDuplicatedCategories)}
      {renderRow('Mają produkty bez nazw', hasNoNameProducts)}
      {renderRow('Mają produkty bez cen', hasZeroPriceProducts)}
      {renderRow(
        'Mają produkty, które nie są ani włączone ani wyłączone',
        hasProductsWithUnknownDisabledStatus
      )}
      {renderRow(
        'Mają zduplikowane nazwy produktów do wyboru',
        hasDuplicatedAddonNamesProducts
      )}
      {renderRow('Mają źle wprowadzone pola do wyboru', hasInvalidChoices)}
      {renderRow('Mają dodatki bez nazwy w produktach', hasAddonsWithoutNames)}
      {renderRow(
        'Mają źle wprowadzone pola płatne dodatki w produktach',
        hasCheckboxAddonsWithNoPrice
      )}
      {renderRow(
        'Mają źle wprowadzone pola płatne dodatki w zestawach dodatków',
        hasSetsWithInvalidAddons
      )}
      {renderRow(
        'Mają produkty z 5+ dodatkami nie w zestawach',
        hasProductsWithMoreThan5AddonsNotInSet
      )}
      {renderRow(
        'Mają produkty z 10+ dodatkami nie w zestawach',
        hasProductsWithMoreThan10AddonsNotInSet
      )}
    </Card>
  );
};

AdminRestaurantValidator.propTypes = {
  restaurants: PropTypes.arrayOf(Shapes.originalRestaurantShape.isRequired)
    .isRequired,
};

export default React.memo(AdminRestaurantValidator);
