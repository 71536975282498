import React from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, Label } from 'reactstrap';
import { addDays, addMonths, addYears, startOfDay } from 'date-fns';
import i18next from 'i18next';

import { toDateInputValue } from 'utils/date-time';

type TOption = {
  name: string;
  value: string;
};

export const daysLimitFilterOptions: [TOption, TOption, ...TOption[]] = [
  {
    name: 'reservations.filter-last-two-weeks',
    value: toDateInputValue(addDays(startOfDay(new Date()), -14)),
  },
  {
    name: 'reservations.filter-last-month',
    value: toDateInputValue(addMonths(startOfDay(new Date()), -1)),
  },
  {
    name: 'reservations.filter-last-three-month',
    value: toDateInputValue(addMonths(startOfDay(new Date()), -3)),
  },
  {
    name: 'reservations.filter-last-year',
    value: toDateInputValue(addYears(startOfDay(new Date()), -1)),
  },
  {
    name: 'reservations.filter-all',
    value: toDateInputValue(addDays(startOfDay(new Date()), -730)),
  },
];

const renderDaysLimitOption = (option) => {
  return (
    <option key={option.value} value={option.value}>
      {i18next.t(option.name)}
    </option>
  );
};

interface ReservationsDaysLimitFilterProps {
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const ReservationsDaysLimitFilter: React.FC<
  ReservationsDaysLimitFilterProps
> = ({ disabled = false, onChange, value }) => {
  return (
    <FormGroup className="col-12 col-lg-4 p-0 mt-3 no-print">
      <Label className="font-weight-bold">
        {i18next.t('reservations.filter-days-limit')}
      </Label>
      <Input
        disabled={disabled}
        type="select"
        className="mw-200"
        value={value}
        onChange={onChange}
      >
        {daysLimitFilterOptions.map(renderDaysLimitOption)}
      </Input>
    </FormGroup>
  );
};

ReservationsDaysLimitFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ReservationsDaysLimitFilter;
