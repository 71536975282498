import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import i18 from 'i18next';
import { Form, withFormik } from 'formik';
import type { FormikBag, FormikProps } from 'formik';

import CollapsibleCard from '../common/CollapsibleCard';
import { IconWithTooltip, SubmitButtonWithLoader } from '../common';
import { SafeNewTabLink } from '../welcome/helpers';
import coordsImg from '../../../assets/images/google-coords-tutorial.png';
import {
  NormalizedRestaurant,
  RestaurantFieldsAllowedToChange,
} from 'types';

type TOwnProps = {
  canEditLogo: boolean;
  resourcesPath: string;
  onSubmit: (payload: Partial<RestaurantFieldsAllowedToChange>) => Promise<any>;
  restaurant: NormalizedRestaurant;
};

type GeneralSettingsFormValues = {
  lat: NormalizedRestaurant['lat'];
  lng: NormalizedRestaurant['lng'];
  logo: string;
};

type TProps = FormikProps<GeneralSettingsFormValues> & TOwnProps;

const GeneralSettingsForm: React.FC<TProps> = ({
  canEditLogo,
  handleChange,
  isSubmitting,
  resourcesPath,
  values,
}) => {
  return (
    <Form>
      {
        <FormGroup>
          <Label>
            Logo{' '}
            <IconWithTooltip
              id="logo"
              text={i18.t('order-settings-form.logo-tooltip')}
            />
          </Label>
          <Input
            name="logo"
            className="mw-200"
            disabled={!canEditLogo}
            value={values.logo || ''}
            onChange={handleChange}
          />
          {values.logo && (
            <img
              className="mt-2 mw-150"
              src={`${resourcesPath}partner-logos/${values.logo}`}
              alt="Logo restauracji"
            />
          )}
        </FormGroup>
      }

      <div className="row align-items-end">
        <FormGroup className="col-12 col-md-6">
          <Label for="lat">
            {i18.t('delivery-settings-form.latitude-coordinate')}
          </Label>
          <Input
            type="number"
            name="lat"
            id="lat"
            placeholder={i18.t('delivery-settings-form.eg-51')}
            step="0.0000001"
            onChange={handleChange}
            value={values.lat}
            className="coords"
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-6">
          <Label for="lat">
            {i18.t('delivery-settings-form.longitude-coordinate')}
          </Label>
          <Input
            type="number"
            name="lng"
            id="long"
            placeholder={i18.t('delivery-settings-form.eg-22')}
            step="0.0000001"
            onChange={handleChange}
            value={values.lng}
            className="coords"
          />
        </FormGroup>
      </div>
      <CollapsibleCard
        title={i18.t('delivery-settings-form.where-to-get-coords-from')}
      >
        <div className="mb-2">
          Intrukcja PL:{' '}
          <SafeNewTabLink url="https://support.google.com/maps/answer/18539?co=GENIE.Platform%3DDesktop&hl=pl" />
        </div>
        <div className="mb-2">
          Tutorial EN:{' '}
          <SafeNewTabLink url="https://support.google.com/maps/answer/18539?co=GENIE.Platform%3DDesktop&hl=en" />
        </div>
        <img
          src={coordsImg}
          alt="Skąd wziąć koordynaty"
          className="w-100 mw-400"
        />
      </CollapsibleCard>
      <SubmitButtonWithLoader className="mt-3" loading={isSubmitting} />
    </Form>
  );
};

const mapPropsToValues = (props: TOwnProps): GeneralSettingsFormValues => ({
  lat: props.restaurant.lat,
  lng: props.restaurant.lng,
  logo: props.restaurant.logo || '',
});

const handleSubmit = (
  values: GeneralSettingsFormValues,
  { props }: FormikBag<TOwnProps, GeneralSettingsFormValues>
) => props.onSubmit(values);

export default withFormik<TOwnProps, GeneralSettingsFormValues>({
  mapPropsToValues,
  handleSubmit,
  displayName: 'GeneralSettingsForm',
})(GeneralSettingsForm);
