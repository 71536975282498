import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';

import OtoTabs from 'app/components/common/OtoTabs';
import LubjeOrdersStatisticsPage from './tenant/OrdersStatisticsPage';
import ConfirmationTimesStatisticsPage from './tenant/ConfirmationTimesStatisticsPage';
import PrepareTimeStatsPage from './tenant/PrepareTimeStatsPage';
import PromoCodeStatsPage from './tenant/PromoCodeStatsPage';
import TenantDashboard from 'app/components/admin/TenantDashboard';
import { useAppSelector } from 'hooks/useRedux';
import { selectAdminTenant } from 'store/admin';
import { AdminTenantSelector } from 'app/components/admin-statistics';
import { PageComponent } from '../../types-global';
import AdminCustomerStats from 'app/components/admin-statistics/AdminCustomerStats';

const tabs = {
  orders: 'Obroty zamówień',
  confirmationTimes: 'Czasy na potwierdzenie',
  prepareTimes: 'Czasy realizacji',
  promoCodes: 'Kody rabatowe',
  customers: 'Użytkownicy',
};

export const DashboardPageForTenantAdmin: PageComponent<{}> = () => {
  const [activeTab, setActiveTab] = useState('');

  const activeTenant = useAppSelector(selectAdminTenant);

  return (
    <>
      <AdminTenantSelector />
      <OtoTabs tabs={tabs} activeTab={activeTab} changeTab={setActiveTab} />
      <TabContent
        activeTab={activeTab}
        className={activeTab === tabs.orders ? 'bg-transparent p-0 mt-3' : ''}
      >
        {!activeTab && <>Wybierz zakładkę, aby pobrać statystyki</>}
        <TabPane tabId={tabs.orders}>
          {activeTab === tabs.orders && (
            <LubjeOrdersStatisticsPage tenant={activeTenant} />
          )}
        </TabPane>
        <TabPane tabId={tabs.confirmationTimes}>
          {activeTab === tabs.confirmationTimes && (
            <ConfirmationTimesStatisticsPage tenant={activeTenant} />
          )}
        </TabPane>
        <TabPane tabId={tabs.prepareTimes}>
          {activeTab === tabs.prepareTimes && (
            <PrepareTimeStatsPage tenant={activeTenant} />
          )}
        </TabPane>
        <TabPane tabId={tabs.promoCodes}>
          {activeTab === tabs.promoCodes && (
            <PromoCodeStatsPage tenant={activeTenant} />
          )}
        </TabPane>
        <TabPane tabId={tabs.customers}>
          {activeTab === tabs.customers && (
            <AdminCustomerStats tenant={activeTenant} />
          )}
        </TabPane>
      </TabContent>
      <hr className="my-4" />
      <TenantDashboard tenant={activeTenant} />
    </>
  );
};

DashboardPageForTenantAdmin.url = '/admin/tenant-stats';
DashboardPageForTenantAdmin.navName = 'admin.stats-new';

export default DashboardPageForTenantAdmin;
