import React from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { selectLanguage, setLang } from 'store/app';
import { setLsLang } from 'services/persistent';
import LanguagePicker from '../components/LanguagePicker';

interface IProps {
  className?: string;
}

const ViewLanguagePicker = (props: IProps) => {
  const lang = useAppSelector(selectLanguage)
  const dispatch = useAppDispatch()

  const changeLang = (lng: string) => {
    setLsLang(lng);
    i18next
      .changeLanguage(lng)
      .then(() => {
        dispatch(setLang(lng));
        window.location.reload();
      })
      .catch(() => toast.error('Wystąpił problem zmiany języka'));
  };
  return (
    <LanguagePicker
      className={props.className || ''}
      lang={lang}
      onChange={changeLang}
    />
  )
};

export default ViewLanguagePicker;
