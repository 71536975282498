import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import type { FormGroupProps, InputProps } from 'reactstrap';
import { InputType } from 'reactstrap/es/Input';

import IconWithTooltip from './IconWithTooltip';

export type OtoFormGroupProps = FormGroupProps & {
  className?: string;
  inputProps?: InputProps;
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  tooltipText?: string;
  type?: string;
  value: string | number | undefined | null;
};

export const OtoFormGroup: React.FC<OtoFormGroupProps> = ({
  className,
  inputProps,
  label,
  name,
  onChange,
  placeholder,
  tooltipText,
  type,
  value,
}) => (
  <FormGroup className={className}>
    <Label for={name}>
      {label} {tooltipText && <IconWithTooltip id={name} text={tooltipText} />}
    </Label>
    <Input
      type={type as InputType}
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value !== null ? value : undefined}
      {...inputProps}
    />
  </FormGroup>
);

OtoFormGroup.propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  tooltipText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OtoFormGroup.defaultProps = {
  className: '',
  inputClass: '',
  inputProps: {},
  type: 'text',
  value: '',
};

export default OtoFormGroup;
