import React from 'react';
import i18next from 'i18next';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';

import { currency } from 'globals/currency';
import { countArrayItemsByField, sumArrayItemsByField } from 'utils/array';
import { fixDecimal } from 'utils/general';
import { getDeliveryMethodLabel, getPaymentMethodLabel } from 'utils/orders';
import PieChartWithAmount from './PieChartWithAmount';
import StatisticsColumn from './StatisticsColumn';
import type { IOrder } from 'types';
import { EDeliveryType } from 'enums';

type OrdersStatisticsSummaryProps = {
  ordersStats: IOrder[];
  statisticsData: {
    total_orders_count: number;
    total_orders_sum: number;
    total_payout_amount: number;
  };
};

const OrdersStatisticsSummary: React.FC<OrdersStatisticsSummaryProps> = (
  props
) => {
  const { ordersStats, statisticsData } = props;

  const ordersByDeliveryCount = countArrayItemsByField(
    ordersStats,
    'delivery_type'
  );
  const ordersByPaymentAmount = countArrayItemsByField(
    ordersStats,
    'payment_type'
  );
  const ordersByPaymentSum = sumArrayItemsByField(
    ordersStats,
    'payment_type',
    'price'
  );
  const ordersBySource = countArrayItemsByField(ordersStats, 'source');

  const ordersByDeliveryArray = Object.entries(ordersByDeliveryCount).map(
    ([delivery_type, ordersAmount]) => ({
      ordersAmount,
      delivery_type: getDeliveryMethodLabel(delivery_type as EDeliveryType),
    })
  );
  const ordersByPaymentArray = Object.keys(ordersByPaymentAmount).map(
    (paymentType) => ({
      paymentTypeLabel: i18next.t(
        `payment-types.${getPaymentMethodLabel(paymentType, false)}`
      ),
      ordersAmount: ordersByPaymentAmount[paymentType],
      ordersSum: fixDecimal(ordersByPaymentSum[paymentType]),
    })
  );
  const ordersBySourceArray = Object.entries(ordersBySource).map(
    ([source, ordersAmount]) => ({
      ordersAmount,
      source: i18next.t(`order.sources.${source}`),
    })
  );

  return (
    <>
      <div className="mb-3">
        <div>
          <strong className="text-danger">Uwaga:</strong> Liczby, przedstawione
          poniżej niosą charakter poglądowy i mogą nie być dokładne w 100%.
        </div>
        <div>
          Niosą one bardziej charakter poglądowy i umożliwiają podgląd historii
          zamówień.
        </div>
        <div>
          Dokładne rozliczenia są generowane w pliku Excel, który jest wysyłany
          drogą mailową przy pierwszym rozliczeniu i później jest aktualizowany
          na bieżąco raz w tygodniu.
        </div>
      </div>
      <Card>
        <CardHeader className="d-flex flex-row align-items-center">
          <span className="mr-2">Podsumowanie okresu</span>
        </CardHeader>
        <CardBody>
          <Row className="m-b-md align-items-start">
            <StatisticsColumn
              title="Liczba zamówień"
              value={statisticsData.total_orders_count}
            />
            <StatisticsColumn
              title="Obrót zamówień"
              value={`${fixDecimal(
                statisticsData.total_orders_sum
              )} ${currency}`}
            />
            <StatisticsColumn
              title="Kwota do wypłaty"
              value={`${fixDecimal(
                statisticsData.total_payout_amount
              )} ${currency}`}
            >
              <div>Dotyczy zamówień, opłaconych online</div>
            </StatisticsColumn>
          </Row>
          <Row className="m-b-md align-items-start bt-1">
            <StatisticsColumn
              className="mt-3"
              title="Liczba zamówień zamówień wg źródła"
            >
              <PieChartWithAmount
                data={ordersBySourceArray}
                dataField="ordersAmount"
                labelField="source"
                showSelect={false}
                showText
              />
            </StatisticsColumn>
            <StatisticsColumn
              className="mt-3"
              title="Liczba zamówień wg sposoby dostawy"
            >
              <PieChartWithAmount
                data={ordersByDeliveryArray}
                dataField="ordersAmount"
                labelField="delivery_type"
                showSelect={false}
                showText
              />
            </StatisticsColumn>
            <StatisticsColumn
              className="mt-3"
              title="Liczba zamówień wg sposobu płatności"
            >
              <PieChartWithAmount
                data={ordersByPaymentArray}
                dataField="ordersAmount"
                labelField="paymentTypeLabel"
                textFormatter={(item) =>
                  `${item.paymentTypeLabel}: ${item.ordersAmount} szt. (${item.ordersSum} ${currency})`
                }
                showSelect={false}
                showText
              />
            </StatisticsColumn>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default React.memo(OrdersStatisticsSummary);
