import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  selectCalendarPreferences,
  updateCalendarPreferences,
} from 'store/reservation-calendar';
import {
  loadRestaurantPlaces,
  selectPlaces,
  selectRestaurant,
  selectRestaurantTables,
} from 'store/restaurant';
import { ISavedReservation } from 'types';
import ViewReservationsPlace from 'app/views/ViewReservationsPlace';
import AddReservationForm from './AddReservationFormikWrapper';
import ReservationsViewOptions from 'app/components/reservations/ReservationsViewOptions';
import ReservationsFetcher from 'app/containers/ReservationsFetcher';
import { daysLimitFilterOptions } from 'app/components/reservations/ReservationsDaysLimitFilter';
import { FREQUENCY } from 'config';

const PlaceMapViewPage = () => {
  const restaurant = useAppSelector(selectRestaurant);
  const restaurantTables = useAppSelector(selectRestaurantTables);
  const restaurantPlaces = useAppSelector(selectPlaces);
  const calendarPreferences = useAppSelector(selectCalendarPreferences);

  const dispatch = useAppDispatch();

  const handleReservationClick = useCallback(
    (reservation: ISavedReservation) => {
      toast.info(
        'Obecnie strona Mapa Stolików nie pozwala na edycję rezerwacji.'
      );
    },
    []
  );
  useEffect(() => {
    restaurant && dispatch(loadRestaurantPlaces(restaurant));
  }, [dispatch, restaurant]);

  if (!restaurant || !restaurant.config) {
    return null;
  }

  return (
    <>
      <ReservationsFetcher
        config={restaurant.config}
        daysLimitFilter={daysLimitFilterOptions[1].value}
        frequency={FREQUENCY.REFRESH}
        includeTablesAndTheirPlaces={true}
        restaurant={restaurant}
      />
      <ReservationsViewOptions
        openByDefault
        places={restaurantPlaces}
        preferences={calendarPreferences}
        showCalendarViewOptions={false}
        showMapViewOptions={true}
        showPlaceSelect={true}
        showHours={false}
        updatePreferences={(preferences) =>
          dispatch(updateCalendarPreferences(preferences))
        }
      />
      {calendarPreferences.activePlace && (
        <AddReservationForm
          allTables={restaurantTables}
          config={restaurant.config}
          hasCalendar={false}
          restaurant={restaurant}
          onReservationCreate={() =>
            toast.success('Rezerwacja została utworzona.')
          }
          type="findPlace"
        />
      )}
      <ViewReservationsPlace
        onReservationClick={handleReservationClick}
        showProposedReservations={false}
      />
    </>
  );
};

export default PlaceMapViewPage;
