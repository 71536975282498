import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NormalizedRestaurant } from 'types/restaurants';
import Shapes from 'shapes/main';
import { isElectron } from 'utils/general';
import FcmMessageListener from './FcmMessageListener';
import ElectronTwoWayCommunication from '../electron-debug-helpers/ElectronTwoWayCommunication';
import ElectronService from 'services/electron';
import DebugModeOnly from 'app/components/debug/DebugModeOnly';

interface IElectronFcmTokenProviderProps {
  messagingError?: any;
  onMessage: (message: object) => void;
  setError: (error: any) => void;
  restaurant: NormalizedRestaurant;
}

const ElectronFcmTokenProvider: React.FC<IElectronFcmTokenProviderProps> = (
  props
) => {
  const [pushes, setPushes] = useState<any[]>([]);
  const [token, setToken] = useState<string | null>(null);

  const handleTokenReceive = (newToken: string) => {
    console.log('[app] ElectronFcmTokenProvider received token', newToken);
    setToken(newToken);
  };

  const handlePushMessage = (message: object) => {
    console.log('[app]ElectronFcmTokenProvider received messsage', message);
    props.onMessage(message);
    setPushes([...pushes, message]);
  };

  useEffect(() => {
    if (isElectron()) {
      console.log('[app] - isElectron, asking for token');
      ElectronService.enableElectronNotifications(
        handleTokenReceive,
        handlePushMessage
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <DebugModeOnly>
        <div>Electron token: {token || 'NO TOKEN RECEIVED'}</div>
        <div>Pushes received: {pushes.length}</div>
        <ElectronTwoWayCommunication />
        <ul>
          {pushes.map((item, index) => (
            <li key={index}>{JSON.stringify(item)}</li>
          ))}
        </ul>
      </DebugModeOnly>
      {token && (
        <FcmMessageListener
          token={token}
          onMessage={props.onMessage}
          restaurant={props.restaurant}
        />
      )}
    </>
  );
};

ElectronFcmTokenProvider.propTypes = {
  messagingError: PropTypes.any,
  onMessage: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
};

export default ElectronFcmTokenProvider;
