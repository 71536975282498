import pd from 'platform-detect';

export function isWindowsOrMacOS() {
  return pd.windows || pd.macos;
}

export function getOSasString() {
  const popularOS = ['android', 'windows', 'ios', 'linux', 'macos'];
  for (let str of popularOS) {
    if (pd[str]) {
      return str;
    }
  }
  return `other OS, not any from: ${popularOS.join(', ')}`;
}

export function getBrowserAsString() {
  const popularBrowsers = ['chrome', 'safari', 'firefox'];
  for (let str of popularBrowsers) {
    if (pd[str]) {
      return str;
    }
  }
  return `other browser, not any from: ${popularBrowsers.join(', ')}`;
}

export function getDeviceDataStr() {
  return `
  OS: ${getOSasString()}
  Browser: ${getBrowserAsString()}
  Window width (inner, outer): ${window.innerWidth}, ${window.outerWidth}`;
}

export const isIOS = (): boolean => pd.ios;

// export const isSafari = (): boolean => pd.safari;

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
