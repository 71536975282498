import { resetFindPlace } from 'store/reservation-calendar';
import { ADD_RESERVATION } from 'store/reservations';

export const actionsInterceptorMiddleware = (store) => (next) => (action) => {
  resetFindPlaceStateOnReservationAdd(store, action);
  return next(action);
};

function resetFindPlaceStateOnReservationAdd(store, action) {
  if (action.type === ADD_RESERVATION) {
    store.dispatch(resetFindPlace())
  }
}
