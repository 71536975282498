import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup } from 'reactstrap';
import i18next from 'i18next';

import type { IPlace } from 'types';
import OtoToggle from '../common/OtoToggle';
import OtoSpinner from '../common/OtoSpinner';
import PlaceEditModal from './PlaceEditModal';
import OtoButtons from '../common/OtoButtons';
import IconWithTooltip from '../common/IconWithTooltip';

interface IProps {
  onPlaceUpdate: (place: IPlace) => Promise<void | IPlace>;
  places: IPlace[];
  placesLoading: number[];
}

const PlacesListComponent: React.FC<IProps> = (props) => {
  const { onPlaceUpdate, places, placesLoading } = props;

  const [selectedPlace, setSelectedPlace] = useState<IPlace | null>(null);

  const handlePlaceSave = (updatedPlace: IPlace) =>
    onPlaceUpdate(updatedPlace).then(() => setSelectedPlace(null));

  return (
    <>
      <hr />
      <Form className="table-settings__form">
        <FormGroup className="table-settings__row table-settings__row--heading">
          <div className="table-name-cell table-settings__row-cell">
            {i18next.t('place-settings.column-header.name.label')}
          </div>
          <div className="table-settings__row-cell">
            <span className="mr-2">
              {i18next.t('place-settings.column-header.priority.label')}
            </span>
            <IconWithTooltip
              id="places-list-place-priority"
              text={i18next.t('place-settings.column-header.priority.tooltip')}
            />
          </div>
          <div className="table-settings__row-cell">
            {i18next.t('place-settings.column-header.is-outside')}
          </div>
          <div className="table-settings__row-cell">
            {i18next.t('place-settings.column-header.is-active.label')}
          </div>
        </FormGroup>
        {places &&
          places.map((place) => {
            return (
              <FormGroup
                key={place.name}
                className="table-settings__row align-items-center"
              >
                <div className="table-name-cell table-settings__row-cell d-flex flex-wrap justify-content-center">
                  <div className="nowrap">{place.name}</div>
                  <OtoButtons.EditButton
                    className={'p-0'}
                    onClick={() => setSelectedPlace(place)}
                    size={16}
                  >
                    {i18next.t('Edit')}
                  </OtoButtons.EditButton>
                </div>
                <div className="table-settings__row-cell">{place.priority}</div>
                <div className="table-settings__row-cell">
                  {place.is_outside ? i18next.t('Yes') : i18next.t('No')}
                </div>
                <div className="table-settings__row-cell">
                  {placesLoading.includes(place.id) ? (
                    <OtoSpinner />
                  ) : (
                    <OtoToggle
                      id={`place-${place.id}.is_active`}
                      name={`place-${place.id}.is_active`}
                      label={
                        place.is_active ? i18next.t('Yes') : i18next.t('No')
                      }
                      checked={place.is_active}
                      onChange={(e) =>
                        onPlaceUpdate({
                          ...place,
                          is_active: e.target.checked,
                        })
                      }
                    />
                  )}
                </div>
              </FormGroup>
            );
          })}
      </Form>
      <PlaceEditModal
        onSave={handlePlaceSave}
        selectedPlace={selectedPlace}
        setSelectedPlace={setSelectedPlace}
        loading={
          selectedPlace ? placesLoading.includes(selectedPlace.id) : false
        }
      />
    </>
  );
};

PlacesListComponent.propTypes = {
  onPlaceUpdate: PropTypes.func.isRequired,
  places: PropTypes.array.isRequired,
};

export default PlacesListComponent;
