import React from 'react';
import PropTypes from 'prop-types';
import i18 from 'i18next';
import { Trash } from 'react-feather';
import { Col, Input, Label, Row } from 'reactstrap';

import { AdditionalFieldTypes } from 'enums';
import Shapes from 'shapes/main';
import type {
  MenuAddonFieldsSet,
  MenuItemAdditionalField,
} from 'types';
import InputWithAddon from '../common/InputWithAddon';
import InputWithOptions from '../common/InputWithOptions';
import OtoToggle from '../common/OtoToggle';

const additionalFieldsDict: Record<AdditionalFieldTypes, string> = {
  [AdditionalFieldTypes.MULTIPLE_CHOICE]:
    'product-edit-modal.addon-type-edit.multiple-choice',
  [AdditionalFieldTypes.SINGLE_CHOICE]:
    'product-edit-modal.addon-type-edit.single-choice',
  [AdditionalFieldTypes.CHECKBOX]:
    'product-edit-modal.addon-type-edit.enlarge-upgrade',
  [AdditionalFieldTypes.SET]: 'product-edit-modal.addon-type-edit.set',
};

interface ProductAdditionalFieldEditProps {
  availableSets: MenuAddonFieldsSet[] | null;
  currency: string;
  index: number;
  field: MenuItemAdditionalField;
  onChange: React.ChangeEventHandler<any>;
  onRemove: (index: number) => void;
  onSetSelect?: (setId: string, setName: string) => void;
}

const classNames = {
  fieldWrapper: 'mb-2 pb-3 bb-1 bc-grey',
  fieldNameOrDescInput: 'min-w-250 mw-300 mb-2',
};

export const ProductAdditionalFieldEdit: React.FC<ProductAdditionalFieldEditProps> = (props) => {
  const {
    availableSets,
    currency,
    field,
    index,
    onRemove,
    onChange: handleChange,
    onSetSelect,
  } = props;

  const fieldTitle = (
    <strong className="d-inline-block my-2">
      {i18.t('product-edit-modal.additional-field')} {index + 1} -{' '}
      {i18.t(additionalFieldsDict[field.type])}
      <Trash className="text-danger ml-2" onClick={() => onRemove(index)} />
    </strong>
  );

  const toggle = (
    <OtoToggle
      checked={!field.isDisabled}
      className="mt-2"
      id={`additionalFields[${index}].isDisabled`}
      name={`additionalFields[${index}].isDisabled`}
      label="Dostępny?"
      labelClassName=""
      labelWrapperClassName="d-inline-flex flex-column-reverse justify-content-start "
      onChange={(e) =>
        handleChange({
          ...e,
          target: {
            name: e.target.name,
            value: !field.isDisabled,
            checked: !field.isDisabled,
          },
        })
      }
    />
  );

  if (field.type === AdditionalFieldTypes.MULTIPLE_CHOICE) {
    return (
      <>
        {fieldTitle}
        <Row className={classNames.fieldWrapper}>
          <Col xs="auto">
            <Label>
              {i18.t('product-edit-modal.lowercase-additional-name-p')}
            </Label>
            <Input
              value={field.name || ''}
              name={`additionalFields[${index}].name`}
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.eg-sauces')}
              className={classNames.fieldNameOrDescInput}
            />
          </Col>
          <Col className="pl-sm-0" xs="auto">
            <Label>{i18.t('product-edit-modal.max-quantity-to-choose')}</Label>
            <InputWithOptions
              value={field.max || ''}
              id={`additionalFields[${index}].max`}
              name={`additionalFields[${index}].max`}
              onChange={handleChange as any}
              placeholder={i18.t('product-edit-modal.eg-2')}
              className="mw-125"
              options={[1, 2, 3]}
            />
          </Col>
          <Col xs="auto">
            <Label>{i18.t('product-edit-modal.options-separated')}</Label>
            <Input
              type="textarea"
              value={field.value || ''}
              name={`additionalFields[${index}].value`}
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.eg-ketchup')}
              className="mw-400"
            />
          </Col>
          <Col xs="auto">{toggle}</Col>
        </Row>
      </>
    );
  }
  if (field.type === AdditionalFieldTypes.SINGLE_CHOICE) {
    return (
      <>
        {fieldTitle}
        <Row className={classNames.fieldWrapper}>
          <Col xs="auto">
            <Label>
              {i18.t('product-edit-modal.lowercase-additional-name-s')}
            </Label>
            <Input
              className={classNames.fieldNameOrDescInput}
              name={`additionalFields[${index}].name`}
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.eg-roll')}
              value={field.name || ''}
            />
          </Col>
          <Col xs="auto">
            <Label>{i18.t('product-edit-modal.options-separated')}</Label>
            <Input
              className={classNames.fieldNameOrDescInput}
              type="textarea"
              value={field.value || ''}
              name={`additionalFields[${index}].value`}
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.eg-roll-types')}
            />
          </Col>
          <Col xs="auto">{toggle}</Col>
        </Row>
      </>
    );
  }
  if (field.type === AdditionalFieldTypes.CHECKBOX) {
    return (
      <>
        {fieldTitle}
        <Row className={classNames.fieldWrapper}>
          <Col xs="auto">
            <Label>{i18.t('product-edit-modal.addition-name')}</Label>
            <Input
              value={field.name || ''}
              name={`additionalFields[${index}].name`}
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.eg-add-chop')}
              className={classNames.fieldNameOrDescInput}
            />
          </Col>
          <Col className="pl-sm-0 mw-175" xs="auto">
            <Label>{i18.t('product-edit-modal.additional-price')}</Label>
            <InputWithAddon
              addonText={currency}
              className="mw-70"
              value={field.addonPrice || ''}
              type="number"
              step="0.01"
              min="0"
              id={`additionalFields[${index}].addonPrice`}
              name={`additionalFields[${index}].addonPrice`}
              onChange={handleChange}
              placeholder={i18.t('product-edit-modal.eg-2')}
            />
          </Col>
          <Col xs="auto">{toggle}</Col>
        </Row>
      </>
    );
  }

  if (field.type === AdditionalFieldTypes.SET) {
    if (!availableSets?.length || !onSetSelect) {
      return (
        <div className="text-danger">
          {i18.t('product-edit-modal.cannot-select-set')}
        </div>
      );
    }
    return (
      <>
        {fieldTitle}
        <Row className="mb-3 align-items-end">
          <Col>
            <Input
              className="mw-250"
              name="selectedSetId"
              type="select"
              value={field.value || ''}
              onChange={(e) =>
                onSetSelect(
                  e.target.value,
                  availableSets?.find((set) => set.id === e.target.value)
                    ?.name || ''
                )
              }
            >
              <option key="" value="" disabled></option>
              {(availableSets || []).map((set) => (
                <option key={set.id} value={set.id}>
                  {set.name}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div className="text-danger">
      {i18.t('product-edit-modal.unknown-type-of-addon')}
    </div>
  );
};

ProductAdditionalFieldEdit.propTypes = {
  field: Shapes.menuItemAdditionalFieldShape.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetSelect: PropTypes.func,
};

ProductAdditionalFieldEdit.displayName = 'ProductAdditionalFieldEdit';

export default ProductAdditionalFieldEdit;
