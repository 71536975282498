import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

import { PayoutWithRestaurantAndCompany } from 'types';
import APIService from 'services/api';
import OtoSpinner from '../../common/OtoSpinner';

interface IProps {
  payout: PayoutWithRestaurantAndCompany;
}

const MarkPayoutAsPaid: React.FC<IProps> = (props) => {
  const { payout } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [isPaid, setIsPaid] = React.useState<boolean>(!!payout.paid_at);

  const handleClick = () => {
    setLoading(true);
    APIService.put(`/payouts/${payout.id}`, {})
      .then(() => setIsPaid(true))
      .catch((e) =>
        toast.error(e?.message || 'Wystąpił błąd aktualizacji wypłaty')
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div>{payout.id}</div>
      {loading && <OtoSpinner className="mr-2" />}
      {isPaid ? (
        <span>{payout.sum_to_payout > 0 ? 'wypłacone' : 'potrącone'}</span>
      ) : (
        <Button type="button" disabled={loading} onClick={handleClick}>
          Oznacz jako {payout.sum_to_payout > 0 ? 'wypłacone' : 'potrącone'}
        </Button>
      )}
    </>
  );
};

export default MarkPayoutAsPaid;
