import type { TRestaurantPlacesAreaConfig } from 'types';

export const bonaRestaurantAreasConfig: TRestaurantPlacesAreaConfig = {
  132: {
    image: {
      src: 'https://cdn.otostolik.pl/img/restaurant-places/497-bona-restaurant/patio-sala-glowna.jpg',
      width: 3297,
      height: 2229,
    },
    tablesConfig: {},
  },
  136: {
    image: {
      src: 'https://cdn.otostolik.pl/img/restaurant-places/497-bona-restaurant/sala3-przy-wejsciu.jpg',
      width: 3247,
      height: 2290,
    },
    tablesConfig: {},
  },
  137: {
    image: {
      src: 'https://cdn.otostolik.pl/img/restaurant-places/497-bona-restaurant/sala2.jpg',
      width: 4032,
      height: 3024,
    },
    tablesConfig: {},
  },
};
