import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes, { Validator } from 'prop-types';
import { format } from 'date-fns';
import {
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
  ToolbarInput,
} from '@fullcalendar/core';
import plLocale from '@fullcalendar/core/locales/pl';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimegridPlugin from '@fullcalendar/resource-timegrid';
import type {
  ColCellContentArg,
  ResourceSourceInput,
} from '@fullcalendar/resource';

import {
  isDeclined,
  isCountStatus,
  isNote,
  addPeopleAmountToTableTitle,
} from 'utils/reservation';
import Shapes from 'shapes/main';
import Restaurant from 'utils/restaurant';
import {
  IPlace,
  ISavedReservation,
  ITableWithPlaceName,
  NormalizedRestaurant,
  CreateReservationDTO,
  ReservationConfig,
  ReservationProposalParams,
  TTableSuggestion,
  TTableSuggestionWithPeopleAmount,
  AddReservationFormValues,
} from 'types';

import { toDateInputValue, toDateTimeInputValue } from 'utils/date-time';
import {
  generateNoteEvents,
  generateReservationEvents,
  generateReservationSuggestionEvents,
  IReservationCalendarEvent,
} from './ReservationCalendarHelpers';
import { ReservationCalendarPreferences } from 'store/reservation-calendar';
import withSetModal, { TSetModalFunction } from '../../../containers/WithSetModal';
import { ModalTypes } from 'enums';

import './calendar.scss';

const plugins = [
  dayGridPlugin,
  interactionPlugin,
  scrollGridPlugin,
  timeGridPlugin,
  resourceTimelinePlugin,
  resourceTimegridPlugin,
];

const getCalendarHeader = (hasFlexibleTables = false) => ({
  left: 'title',
  center: hasFlexibleTables
    ? 'customDayGridDay,customResourceTimelineDay,customResourceTimeGridDay,customDayGridWeek,customDayGridMonth'
    : 'customDayGridDay,customDayGridWeek,customDayGridMonth',
  right: 'today prev,next',
});

const timeFormat = {
  hour: '2-digit' as const,
  minute: '2-digit' as const,
  meridiem: false,
};
const resourceColumns = [
  {
    labelText: 'Nazwa',
    field: 'nameWithPeopleAmount',
  },
];

type TExtendedProps = IReservationCalendarEvent['extendedProps'];

export interface ReservationsCalendarProps {
  config: ReservationConfig;
  onCalendarDateClick: (params: {
    date: string;
    table?: { id: string };
  }) => void;
  onOpenModalClick: (reservation: ISavedReservation) => void;
  onProposedTablesSelect: (
    param: TTableSuggestionWithPeopleAmount | ReservationProposalParams
  ) => void;
  reservationFormValues: AddReservationFormValues | null;
  reservations: ISavedReservation[];
  reservationProposal: null | ReservationProposalParams;
  restaurant: NormalizedRestaurant;
  places?: IPlace[] | null;
  preferences: ReservationCalendarPreferences;
  setModal: TSetModalFunction;
  tables?: ITableWithPlaceName[] | null;
  tableSuggestions: TTableSuggestion[];
  updateReservation: (
    reservation: ISavedReservation,
    values: Partial<CreateReservationDTO>
  ) => Promise<ISavedReservation | null>;
  updateCalendarPreferences: (
    payload: Partial<ReservationCalendarPreferences>
  ) => void;
}

export type TReservationCalendarView =
  | 'dayGridMonth'
  | 'dayGridWeek'
  | 'dayGridDay'
  | 'resourceTimelineDay'
  | 'resourceTimeGridDay';

type TCalendarButton = {
  text: string;
  click: () => void;
};

type TCalendarButtons = {
  customDayGridDay: TCalendarButton;
  customDayGridWeek: TCalendarButton;
  customDayGridMonth: TCalendarButton;
  customResourceTimeGridDay: TCalendarButton;
  customResourceTimelineDay: TCalendarButton;
};

export const ReservationsCalendar: React.FC<ReservationsCalendarProps> = (
  props
) => {
  const calendarRef = useRef<FullCalendar>();

  const {
    config,
    onCalendarDateClick,
    onOpenModalClick,
    onProposedTablesSelect,
    reservations,
    reservationProposal,
    restaurant,
    preferences,
    places,
    reservationFormValues,
    setModal,
    tables,
    tableSuggestions,
    updateCalendarPreferences,
    updateReservation,
  } = props;

  const flexibleTables = config && config.flexible_tables;

  const calendarHeader: ToolbarInput = getCalendarHeader(flexibleTables);

  const defaultView: TReservationCalendarView = flexibleTables
    ? 'dayGridDay'
    : 'dayGridWeek';

  const [view, setView] = useState<TReservationCalendarView>(defaultView);

  const generateEvents = useCallback(
    (eventsView = view): EventInput[] => {
      const noteEvents: IReservationCalendarEvent[] = generateNoteEvents(
        reservations,
        {
          addNonVaccinated: Restaurant.wantsToCountNonVaccinated(restaurant),
        }
      );

      const reservationEvents: IReservationCalendarEvent[] =
        generateReservationEvents({
          config,
          flexibleTables: flexibleTables,
          defaultDuration: config.reservation_duration as number,
          reservations,
          preferences: preferences,
          view: eventsView,
        });

      const suggestionEvents = preferences.showReservationSuggestionsOnCalendar
        ? generateReservationSuggestionEvents({
            preferences,
            tables: tables || [],
            reservationFormValues,
            tableSuggestions,
          })
        : [];

      return [
        ...noteEvents,
        ...reservationEvents,
        ...(suggestionEvents ? suggestionEvents : []),
      ];
    },
    [
      config,
      flexibleTables,
      preferences,
      reservations,
      restaurant,
      reservationFormValues,
      tableSuggestions,
      tables,
      view,
    ]
  );

  const regenerateEvents = useCallback(
    () => setEvents(generateEvents(view)),
    [generateEvents, view]
  );

  const getCustomCalendarButtonProps = useCallback(
    (view: TReservationCalendarView, label: string): TCalendarButton => ({
      text: label,
      click: () => {
        setView(view);
        regenerateEvents();
        if (calendarRef.current) {
          calendarRef.current.getApi().changeView(view);
        } else {
          console.error(
            'calendarRef.current is null in getCustomCalendarButtonProps'
          );
        }
      },
    }),
    [regenerateEvents]
  );

  const [events, setEvents] = useState<EventInput>([]);

  const calendarCustomButtons = useMemo<TCalendarButtons>(
    () => ({
      customDayGridDay: getCustomCalendarButtonProps(
        'dayGridDay',
        'Dzień (lista)'
      ),
      customDayGridWeek: getCustomCalendarButtonProps('dayGridWeek', 'Tydzień'),
      customDayGridMonth: getCustomCalendarButtonProps(
        'dayGridMonth',
        'Miesiąc'
      ),
      customResourceTimelineDay: getCustomCalendarButtonProps(
        'resourceTimelineDay',
        'Dzień (stoliki)'
      ),
      customResourceTimeGridDay: getCustomCalendarButtonProps(
        'resourceTimeGridDay',
        'Dzień (1 sala)'
      ),
    }),
    [getCustomCalendarButtonProps]
  );

  useEffect(regenerateEvents, [regenerateEvents]);

  useEffect(() => {
    const haveNewTableSuggestionsAppeared = tableSuggestions.length > 0;

    const hasSingleProposalAppeared = !!reservationProposal;

    const haveMultipleProposalsAppeared =
      haveNewTableSuggestionsAppeared && !!reservationFormValues;

    const updateActivePlaceInCalendar = () => {
      const selectedDate =
        reservationProposal?.visitDate ||
        (reservationFormValues?.visitDate &&
          new Date(reservationFormValues.visitDate));
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        selectedDate && calendarApi.gotoDate(toDateInputValue(selectedDate));
      } else {
        console.error(
          'calendarRef.current is null in updateActivePlaceInCalendar'
        );
      }
    };

    if (hasSingleProposalAppeared) {
      const selectedTable = reservationProposal?.selectedTables[0];
      if (!!selectedTable) {
        const place = (places || []).find(
          (place) => place.id === selectedTable.place_id
        );
        // TODO re-enable this after showing restaurants how to use find place
        // updateCalendarPreferences({ activePlace: place });
        updateCalendarPreferences({ activePlace: null });
        updateActivePlaceInCalendar();
      }
    }

    if (haveMultipleProposalsAppeared) {
      const suggestion = tableSuggestions[0];
      const place = (places || []).find(
        (place) => place.id === suggestion.place_id
      );
      // TODO re-enable this after showing restaurants how to use find place
      // updateCalendarPreferences({ activePlace: place });
      updateCalendarPreferences({ activePlace: null });
      updateActivePlaceInCalendar();
    }
  }, [
    calendarRef,
    places,
    reservationProposal,
    reservationFormValues,
    tableSuggestions,
    updateCalendarPreferences,
  ]);

  const getTableResources = (): ResourceSourceInput => {
    const { activePlace, hideDisabledPlaces } = preferences;

    const tableToResourceInput = (table: ITableWithPlaceName) => ({
      id: table.id.toString(),
      title: table.name,
      placeName: table.placeName,
      nameWithPeopleAmount: addPeopleAmountToTableTitle(table, {
        addPlace: false,
        allTables: tables || [],
      }).nameWithPeopleAmount,
    });

    let shownTables = tables || [];
    if (activePlace) {
      shownTables = shownTables.filter(
        (table) => table.place_id === activePlace.id
      );
    }
    if (hideDisabledPlaces) {
      const disabledPlaceIds = (places || [])
        .filter((place) => !place.is_active)
        .map((place) => place.id);
      shownTables = shownTables.filter(
        (table) => !disabledPlaceIds.includes(table.place_id)
      );
    }

    return shownTables.map(tableToResourceInput);
  };

  const formatPlaceName = (
    arg: ColCellContentArg,
    placesByNames: Record<string, IPlace>
  ): string => {
    const placeName = arg.groupValue;
    const place = placesByNames[placeName];
    if (!place || place.is_active) {
      return placeName;
    }
    return `(wył.) ${placeName}`;
  };

  const handleDateClick = (eventObject: DateClickArg) => {
    const { date, dateStr, jsEvent, resource: table } = eventObject;

    const isDayNumberClicked =
      jsEvent.target &&
      (jsEvent.target as HTMLElement).classList &&
      (jsEvent.target as HTMLElement).classList.contains(
        'fc-daygrid-day-number'
      );

    if (isDayNumberClicked) {
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(defaultView);
        calendarApi.gotoDate(date);
      } else {
        console.error('calendarRef.current is null in handleDateClick');
      }
      return;
    }

    onCalendarDateClick({
      date: dateStr,
      table,
    });
  };

  const handleEventClick = (arg: EventClickArg) => {
    const extendedProps = arg.event.extendedProps as TExtendedProps;

    if (isCountStatus(extendedProps)) {
      return;
    }

    if (extendedProps.type === 'reservation-proposal') {
      onProposedTablesSelect(extendedProps.tableSuggestion);
      document.querySelector('.find-place-card')?.scrollIntoView();
    }

    if (extendedProps.type === 'reservation') {
      onOpenModalClick(extendedProps.reservation);
    }
  };

  const handleEventDrop = (dropInfo: EventDropArg) => {
    const extendedProps = dropInfo.event
      .extendedProps as IReservationCalendarEvent['extendedProps'];
    if (extendedProps.type !== 'reservation') {
      return;
    }

    const reservation = extendedProps.reservation;

    if (dropInfo.delta.days) {
      const dateFrom = toDateTimeInputValue(dropInfo.oldEvent.start as Date);
      const dateTo = toDateTimeInputValue(dropInfo.event.start as Date);
      setModal(
        {
          title: `Aktualizujesz rezerwację #${reservation.id}`,
          text: (
            <>
              Czy chcesz zmienić datę rezerwacji z <strong>{dateFrom}</strong>{' '}
              na <strong>{dateTo}</strong>
            </>
          ),
          confirm: () => {
            updateReservation(reservation, {
              reserved_on: format(
                dropInfo.event.start as Date,
                'y-MM-dd HH:mm:ss'
              ),
            }).catch(() => dropInfo.revert());
          },
          cancel: () => {
            dropInfo.revert();
          },
          confirmColor: 'success',
          cancelText: 'Anuluj',
        },
        ModalTypes.CONFIRM
      );
      return;
    }
    if (dropInfo.delta.milliseconds) {
      const timeFrom = toDateTimeInputValue(dropInfo.oldEvent.start as Date);
      const timeTo = toDateTimeInputValue(dropInfo.event.start as Date);
      setModal(
        {
          title: `Aktualizujesz rezerwację #${reservation.id}`,
          text: (
            <>
              Czy chcesz zmienić godzinę rezerwacji z{' '}
              <strong>{timeFrom}</strong> na <strong>{timeTo}</strong>
            </>
          ),
          confirm: () => {
            updateReservation(reservation, {
              reserved_on: format(
                dropInfo.event.start as Date,
                'y-MM-dd HH:mm:ss'
              ),
            }).catch(() => dropInfo.revert());
          },
          cancel: () => {
            dropInfo.revert();
          },
          confirmColor: 'success',
          cancelText: 'Anuluj',
        },
        ModalTypes.CONFIRM
      );
      return;
    }
    if (dropInfo.oldResource !== dropInfo.newResource) {
      const tableFrom = dropInfo.oldResource ? dropInfo.oldResource.title : '';
      const tableTo = dropInfo.newResource ? dropInfo.newResource.title : '';
      setModal(
        {
          title: `Aktualizujesz rezerwację #${reservation.id}`,
          text: (
            <>
              Czy chcesz zmienić stolik z <strong>{tableFrom}</strong> na{' '}
              <strong>{tableTo}</strong>
            </>
          ),
          confirm: () => {
            updateReservation(reservation, {
              tables: [parseInt(dropInfo.newResource!.id, 10)],
            }).catch(() => dropInfo.revert());
          },
          cancel: () => {
            dropInfo.revert();
          },
          confirmColor: 'success',
          cancelText: 'Anuluj',
        },
        ModalTypes.CONFIRM
      );
      return;
    }
    console.warn('invalid dropInfo', dropInfo);
    dropInfo.revert();
  };

  const getEventClassNames = (arg: EventContentArg): string => {
    const extendedProps = arg.event.extendedProps as TExtendedProps;

    if (isCountStatus(extendedProps)) {
      return 'fc-event-count-status';
    }
    if (extendedProps.type === 'reservation-proposal') {
      return 'fc-event-reservation-proposal';
    }
    if (extendedProps.type === 'reservation') {
      const { reservation } = extendedProps;
      if (isNote(reservation)) {
        return 'fc-event-note';
      }
      if (isDeclined(reservation)) {
        return preferences.showDeclinedReservations
          ? 'fc-event-declined'
          : 'd-none';
      }
      if (reservation.extras && reservation.extras.present) {
        return 'fc-event-success';
      }
    }
    return '';
  };

  const renderEventContent = (arg: EventContentArg) => {
    const { event } = arg;
    const extendedProps = event.extendedProps as TExtendedProps;

    if (!extendedProps || extendedProps.type !== 'reservation') {
      return (
        <div className="fc-event-main-inner">
          <div className="fc-event-title">{event.title}</div>
        </div>
      );
    }
    const reservation = extendedProps.reservation;
    return (
      <div
        className="fc-event-main-inner align-vertical"
        // data-notes={reservation.notes}
      >
        {reservation?.notes_from_guest && (
          <span className="event-label event-label--notes-from-customer">!</span>
        )}
        {reservation?.notes_from_restaurant && (
          <span className="event-label event-label--notes-from-restaurant">!</span>
        )}
        {reservation?.attachments && (
          <span className="event-label event-label--file">Plik</span>
        )}
        {['landing', 'iframe'].includes(reservation.source) && (
          <span className="event-label event-label--automatic"></span>
        )}
        <div className="fc-event-title">{event.title}</div>
      </div>
    );
  };

  const placesByNames: Record<string, IPlace> = (places || []).reduce(
    (acc, place) => ({ ...acc, [place.name]: place }),
    {}
  );

  return (
    <>
      {events?.length > 0 ? (
        <FullCalendar
          // @ts-ignore
          ref={calendarRef}
          customButtons={calendarCustomButtons}
          dateClick={handleDateClick}
          eventTimeFormat={timeFormat}
          events={events}
          // TODO make sure about this
          editable
          eventContent={renderEventContent}
          eventClassNames={getEventClassNames}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          headerToolbar={calendarHeader}
          initialView={defaultView}
          locales={[plLocale]}
          locale="pl"
          nowIndicator={true}
          plugins={plugins}
          resourceGroupField={'placeName'}
          resourceAreaColumns={resourceColumns}
          resources={getTableResources()}
          resourceAreaWidth={150}
          resourceAreaHeaderContent={'Stoliki'}
          resourceGroupLabelContent={(arg) =>
            formatPlaceName(arg, placesByNames)
          }
          slotMinTime={preferences.timeFrom}
          slotMinWidth={50}
          dayMinWidth={view === 'resourceTimeGridDay' ? 100 : undefined}
          slotMaxTime={preferences.timeTo}
        />
      ) : (
        <div>Brak dostępnych rezerwacji</div>
      )}
    </>
  );
};

ReservationsCalendar.propTypes = {
  config: Shapes.reservationConfigShape.isRequired,
  onOpenModalClick: PropTypes.func.isRequired,
  reservations: PropTypes.arrayOf(Shapes.savedReservationShape.isRequired as Validator<ISavedReservation>)
    .isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
  places: PropTypes.arrayOf(Shapes.restaurantPlaceShape.isRequired),
  tables: PropTypes.arrayOf(
    Shapes.restaurantTableWithPlaceNameShape.isRequired
  ),
};

ReservationsCalendar.defaultProps = {
  tables: [],
};

export default withSetModal<ReservationsCalendarProps>(ReservationsCalendar);
