import React, { useState } from 'react';
import { AlertCircle } from 'react-feather';
import { Button, Col, Row, } from 'reactstrap';

import { sortArrayByField } from 'utils/array';
import PieChartWithAmount from './PieChartWithAmount';
import ProductStatisticsTable from './ProductStatisticsTable';
import { IOrder } from 'types';

type ProductStats = Record<string, {
  name: string;
  amount: number;
  price: number;
}>

type OrdersMostPopularProps = {
  ordersStats: IOrder[];
}

const OrdersMostPopular: React.FC<OrdersMostPopularProps> = props => {
  const { ordersStats } = props;
  const [showProductsTable, setShowProductsTable] = useState(false);
  const mostPopularProducts = Object.values(getProductStats(ordersStats));
  const mostPopularProductsByAmount = sortArrayByField(mostPopularProducts, 'amount');
  const mostPopularProductsByPrice = sortArrayByField(mostPopularProducts, 'price');

  const showAllBtn = showProductsTable
    ? null
    : <Button color="info" className="mt-3" onClick={() => setShowProductsTable(true)} type="button">
      <AlertCircle />
      <span className="ml-2">Pokaż wszystkie</span>
    </Button>;

  return <>
    <Row className="mb-3">
      <hr className="my-2 w-100" />
      <h2 className="title h3 col-12 mb-2">Najbardziej popularne dania</h2>
      <Col lg="6" className="centered-column">
        <PieChartWithAmount
          data={mostPopularProductsByAmount}
          dataField="amount"
          labelField="name"
          title="Najczęściej zamawiane"
        />
        {showAllBtn}
      </Col>
      <Col lg="6" className="centered-column">
        <PieChartWithAmount
          data={mostPopularProductsByPrice}
          dataField="price"
          labelField="name"
          title="Największy obrót"
        />
        {showAllBtn}
      </Col>
    </Row>
    {showProductsTable && <ProductStatisticsTable products={mostPopularProducts} />}
  </>
};

function getProductStats(orders: IOrder[]) {
  return orders.reduce((dict: ProductStats, order) => {
    order.products.forEach(product => {
      const { name, amount, price } = product;
      if (!dict[name]) {
        dict[name] = {
          name,
          amount: 0,
          price: 0
        };
      }
      dict[name].amount += amount;
      dict[name].price += price;
    });
    return dict;
  }, {})
}

export default React.memo(OrdersMostPopular);
