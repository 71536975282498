import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18next from 'i18next';

import { FREQUENCY } from 'config';
import { isOrdersConfigFinished, isPending } from 'utils/orders';
import { selectRestaurant } from 'store/restaurant';
import {
  loadOrders,
  selectAllOrders,
  selectOrdersLastUpdateTime,
  selectOrdersLoading,
} from 'store/orders';
import { areOrdersEnabled, isDeliveryClosed } from 'utils/orders-config';
import { safeDingOrNotify } from 'utils/sounds';
import { selectHasMultipleRestaurants, selectUserRestaurants } from 'store/app';
import { displayTime } from 'utils/date-time';
import OtoButtons from 'app/components/common/OtoButtons';
import { OrdersConfig } from 'types/orders-config';
import { IOrder } from 'types/order';
import { IAppState } from 'store/main';

interface IOwnProps {
  frequency: string | number;
  ordersConfig: OrdersConfig;
  onFetchError?: (error: any, url?: string) => void;
  onFetchSuccess?: (payload: any) => void;
  showLastFetchTime: boolean;
}

interface IState {
  initialOrdersLoaded: boolean;
}

type IProps = IOwnProps & PropsFromRedux;

class OrdersFetcher extends React.Component<IProps, IState> {
  ordersFetchInterval: number | null = null;
  dingInterval: NodeJS.Timeout | null = null;
  state = { initialOrdersLoaded: false };
  skippedTimes = 0;
  maxSkippedTimes = 5;

  static defaultProps = {
    frequency: FREQUENCY.REFRESH,
    showLastFetchTime: false,
  };

  static displayName = 'OrdersFetcher';

  componentDidMount() {
    this.fetchOrders();
    this.ordersFetchInterval = setInterval(
      this.fetchOrders,
      this.props.frequency as number
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orders !== this.props.orders) {
      this.checkPendingOrders(this.props.orders);
    }
  }

  componentWillUnmount() {
    this.ordersFetchInterval && clearInterval(this.ordersFetchInterval);
    this.dingInterval && clearInterval(this.dingInterval);
  }

  fetchOrders = (force = false) => {
    const {
      onFetchError,
      onFetchSuccess,
      ordersConfig,
      ordersLoading,
      restaurant,
    } = this.props;
    if (ordersLoading) {
      return;
    }
    const areOrdersDisabledOrNotConfigured =
      !isOrdersConfigFinished(ordersConfig, restaurant) ||
      !areOrdersEnabled(ordersConfig) ||
      isDeliveryClosed(ordersConfig);
    if (
      areOrdersDisabledOrNotConfigured &&
      this.state.initialOrdersLoaded &&
      this.skippedTimes < this.maxSkippedTimes &&
      !force
    ) {
      this.skippedTimes++;
      return;
    }
    if (this.skippedTimes === this.maxSkippedTimes) {
      this.skippedTimes = 0;
    }
    this.setState({ initialOrdersLoaded: true });
    const ids = this.props.hasMultipleRestaurants
      ? (this.props.userRestaurants || []).map((r) => r.id).join(',')
      : restaurant!.id;
    const url = `/restaurants/${ids}/orders`;
    this.props.loadOrders(url, {
      onSuccess: onFetchSuccess,
      onError: onFetchError,
    });
  };

  refetchOrders = () => this.fetchOrders(true);

  checkPendingOrders = (orders: IOrder[]) => {
    const pendingOrders = orders.filter(isPending);
    if (!this.dingInterval && pendingOrders.length > 0) {
      safeDingOrNotify(pendingOrders);
      this.dingInterval = setInterval(
        () => safeDingOrNotify(pendingOrders),
        FREQUENCY.DING
      );
    } else if (this.dingInterval && !pendingOrders.length) {
      clearInterval(this.dingInterval);
      this.dingInterval = null;
    }
  };

  render() {
    if (this.props.showLastFetchTime) {
      return (
        <div className="align-vertical">
          {i18next.t('orders-fetcher.last-fetch-date')}:{' '}
          <span className="ml-1">
            {this.props.ordersLastUpdateTime
              ? displayTime(this.props.ordersLastUpdateTime)
              : i18next.t('orders-fetcher.not-fetched')}
          </span>
          <OtoButtons.ReloadButton
            className="ml-2 mb-2"
            disabled={this.props.ordersLoading}
            onClick={this.refetchOrders}
          >
            {i18next.t('orders-fetcher.refetch')}
          </OtoButtons.ReloadButton>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: IAppState) => ({
  orders: selectAllOrders(state),
  ordersLoading: selectOrdersLoading(state),
  ordersLastUpdateTime: selectOrdersLastUpdateTime(state),
  restaurant: selectRestaurant(state),
  userRestaurants: selectUserRestaurants(state),
  hasMultipleRestaurants: selectHasMultipleRestaurants(state),
});
const mapDispatchToProps = {
  loadOrders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrdersFetcher);
