import PropTypes from 'prop-types';

const userShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string,
  phone: PropTypes.string.isRequired,
  is_working: PropTypes.bool.isRequired,
})

export default userShape