import React from 'react';
import { TrendingUp } from 'react-feather';

import CollapsibleCard from '../common/CollapsibleCard';
import { SafeNewTabLink, TutorialLinkGroup } from './helpers';

import facebookLinkImage from '../../../assets/images/getting-started/order-food-button.png';
import googleOrderLinkImage from '../../../assets/images/getting-started/google-maps-order-link.png';
import BannerImage from '../../../assets/images/otostolik-square-banner.png';
import type { NormalizedRestaurant } from 'types';

type HowToGetMoreOrdersProps = {
  restaurant: NormalizedRestaurant;
};

const HowToGetMoreOrders: React.FC<HowToGetMoreOrdersProps> = ({ restaurant }) => {
  const { slug } = restaurant;

  const fbTutorialsLink = `https://drive.google.com/file/d/1jXWcabaskEqFUuoptMXIgc14Ks9vUZyJ/view`;
  const googleMapsTutorialLink = 'https://drive.google.com/open?id=1gxMcoBz1RhV0mC0Cmooe0K4RgaPLUxNH';

  const fbShopNowButtonLink
    = `https://otostolik.pl/zamawiaj/${slug}?utm_source=facebook&utm_medium=shop_now_button`;
  const googleMapsShopNowLink = `https://otostolik.pl/zamawiaj/${slug}?utm_source=google_maps`;

  return <>
    <CollapsibleCard title={<div><TrendingUp /> <span>Krok 3 - Jak mieć więcej zamówień z OtoStolik? (ustawienie przycisków, grafika i treść dla posta)</span></div>}>
      <h4>3.1 - Ustaw przycisk "Zamawiaj jedzenie" na Facebook</h4>
      <TutorialLinkGroup
        img={facebookLinkImage}
        label={`Przycisk "Zamawiaj jedzenie" na Facebook`}
        len="30 sec"
        tutorialLink={fbTutorialsLink}
        valueLink={fbShopNowButtonLink}
      />
      <h4>3.2 - Ustaw link do zamówień do OtoStolik lub własnej www na Google Maps </h4>
      <TutorialLinkGroup
        img={googleOrderLinkImage}
        label="Link do Google Maps"
        len="1 min"
        tutorialLink={googleMapsTutorialLink}
        valueLink={googleMapsShopNowLink}
      />
      <h4 className="mt-5">3.3 - Poinformuj gości o możliwości zamówienia z OtoStolik - napisz post na social media</h4>
      <div>
        <p>Nie mamy konkretnych wytycznych, ale miło będzie, jeżeli wspomnisz, że jesteśmy regionalną firmą :)</p>
        <p>Możesz użyć naszej przykładowej <SafeNewTabLink url={BannerImage}>grafiki:</SafeNewTabLink></p>
        <img src={BannerImage} alt="OtoStolik - przykładowa grafika do posta" className="mw-200" />
        <div className="mt-3">Jeżeli wolisz zrobić własną grafikę - jeszcze lepiej, nie ograniczaj się!</div>
        <p>Jeżeli jest taka potrzeba - możesz użyć nasze logo:</p>
        <div>
          <SafeNewTabLink url="https://cdn.otostolik.pl/img/logo-svg/with-pl-light.svg">Pomarańczowe SVG</SafeNewTabLink>
          |
          <SafeNewTabLink url="https://cdn.otostolik.pl/img/logo-svg/with-pl-white.svg">Białe SVG</SafeNewTabLink>
          |
          <SafeNewTabLink url="https://cdn.otostolik.pl/img/logo-png/with-pl-light.png">Pomarańczowe PNG</SafeNewTabLink>
          |
          <SafeNewTabLink url="https://cdn.otostolik.pl/img/logo-png/with-pl-white.png">Białe PNG</SafeNewTabLink>
        </div>
      </div>
    </CollapsibleCard>
  </>;
}

export default HowToGetMoreOrders;
