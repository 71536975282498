import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import DateTimeHelper from 'utils/date-time';
import OtoButtons from '../common/OtoButtons';

interface IProps {
  isEmpty?: boolean;
  onEdit: MouseEventHandler;
  ranges: {
    startDate: Date;
    endDate: Date;
  }[];
}

const StatisticsRangeHeader: React.FC<IProps> = (props) => {
  const { isEmpty, ranges, onEdit } = props;
  return (
    <Row className="mx-0 align-vertical">
      {isEmpty ? (
        'Wybierz okres, aby pobrać dane'
      ) : (
        <>
          Okres: {DateTimeHelper.toDateInputValue(ranges[0].startDate)} -{' '}
          {DateTimeHelper.toDateInputValue(ranges[0].endDate)}
          {props.children}
          <OtoButtons.EditButton className="pt-0 ml-2" onClick={onEdit} />
        </>
      )}
    </Row>
  );
};

StatisticsRangeHeader.propTypes = {
  isEmpty: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  ranges: PropTypes.array.isRequired,
};

export default StatisticsRangeHeader;
