import React, { useEffect } from 'react';

import TenantSelector from './TenantSelector';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { Tenants } from 'store/tenants';
import { selectAdminTenant, setAdminTenant } from 'store/admin';
import {
  selectIsLesznojeAdmin,
  selectIsLubjeAdmin,
} from 'store/app';

export const AdminTenantSelector: React.FC<{}> = () => {
  const activeTenant = useAppSelector(selectAdminTenant);
  const dispatch = useAppDispatch();

  const setActiveTenant = (tenant: string) =>
    dispatch(setAdminTenant(tenant as Tenants));

  const isLubjeAdmin = useAppSelector(selectIsLubjeAdmin);
  const isLesznoAdmin = useAppSelector(selectIsLesznojeAdmin);

  const getDefaultTenant = () => {
    if (isLubjeAdmin) {
      return Tenants.LUBJE;
    }
    if (isLesznoAdmin) {
      return Tenants.LesznoJe;
    }

    return Tenants.OtoStolik;
  };

  const defaultTenant = getDefaultTenant();

  useEffect(() => {
    if (activeTenant !== defaultTenant) {
      setActiveTenant(defaultTenant);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(isLubjeAdmin || isLesznoAdmin) {
    return null;
  }
  
  return (
    <TenantSelector
      value={activeTenant}
      onChange={setActiveTenant}
      showLesznoJe={true}
      showOstrowJe={true}
    />
  );
};

export default AdminTenantSelector;
