import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {
  Button,
  Row,
  Label,
  Col,
  FormGroup,
  Input,
  Spinner,
  FormText,
  FormFeedback,
} from 'reactstrap';
import { Form, FormikProps, withFormik } from 'formik';
import { toast } from 'react-toastify';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { MapPin, User } from 'react-feather';

import APIService from 'services/api';
import { logImportantActivity, userFriednlyLog } from 'utils/log';
import InputWithAddon from '../common/InputWithAddon';
import OtoInputs from '../common/OtoInputs';
import { toDateInputValue } from 'utils/date-time';
import CONTACT_DATA from 'globals/contacts';
import OtoSpinner from '../common/OtoSpinner';

type CompanyFormValues = {
  nip: string;
  name: string;
  address: string;
  bank_account: string;
  representer: {
    name: string;
    phone: string;
  };
  signed_at: string;
};

type TOwnProps = {
  onSubmit: (
    createdCompany: CompanyFormValues,
    representer: CompanyFormValues['representer']
  ) => void;
  handleGoBack?: (values: CompanyFormValues) => void;
};
type TProps = FormikProps<CompanyFormValues> & TOwnProps;

const CompanyForm: React.FC<TProps> = (props) => {
  const {
    values,
    isSubmitting,
    setErrors,
    setValues,
    handleChange,
    handleGoBack,
    errors,
  } = props;
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);

  const getCompanyDataByNip = () => {
    setIsCompanyLoading(true);
    const url = '/integrations/rejestr-io';
    APIService.post(url, { nip: values.nip })
      .then((res) => {
        // TODO somewhere check by id if company present or just rejestr.io data.
        if (res.success) {
          setValues({
            ...values,
            ...res.data,
          });
        } else {
          logImportantActivity(
            `Company data submitted, but NIP not found at rejestr.io!\values: ${userFriednlyLog(
              values
            )}\nresponse: ${userFriednlyLog(res)}`
          );
          toast.error(
            'Nie udało się pobrać danych firmy z rejestr.io po numerze NIP. Wpisz dane ręcznie.'
          );
        }
      })
      .catch((errorRespose) => {
        toast.warn(
          'Nie znaleźliśmy danych Twojej firmy automatycznie, prosimy o wypełnienie pół ręcznie',
          { autoClose: false }
        );
        !!errorRespose.errors && setErrors(errorRespose.errors);
      })
      .finally(() => setIsCompanyLoading(false));
  };

  return (
    <Form>
      <legend>{i18next.t('sign-up.company-legend')}</legend>
      <Row form>
        <Col md={4}>
          {/* <FormGroup>
            <Label for="companyNip">
              {i18next.t('sign-up.restaurant-nip-label')}
            </Label>
            <InputWithAddon
              addonText={`${company.nip.toString().length}/10`}
              type="number"
              name="company[nip]"
              id="companyNip"
              placeholder={i18next.t('sign-up.restaurant-nip-ph')}
              value={company.nip}
              onChange={handleChange}
              required
              disabled={isSubmitting}
              invalid={errors && errors.nip ? true : false}
            />
            {errors && errors.nip && <FormFeedback>{errors.nip}</FormFeedback>}
            <FormText>{i18next.t('sign-up.restaurant-nip-info')}</FormText>
          </FormGroup> */}
          <FormGroup>
            <Label for="nip">{i18next.t('sign-up.restaurant-nip-label')}</Label>
            <NumericFormat
              format="###-###-##-##"
              mask="X"
              customInput={InputWithAddon}
              addonText={`${values.nip.toString().length}/10`}
              onValueChange={(valuesObject) =>
                props.setFieldValue('nip', valuesObject.value)
              }
              isNumericString
              type="tel"
              className="mw-250"
              name="nip"
              id="nip"
              placeholder={i18next.t('sign-up.restaurant-nip-ph')}
              value={values.nip}
              // onChange={handleChange}
              disabled={isSubmitting || isCompanyLoading}
              required
            />
            {errors && errors.nip && <FormFeedback>{errors.nip}</FormFeedback>}
            <FormText>{i18next.t('sign-up.restaurant-nip-info')}</FormText>
          </FormGroup>
        </Col>
        <Col md={6} className="mt-md-2">
          {isCompanyLoading ? (
            <OtoSpinner className="mt-md-4" />
          ) : (
            <Button
              color="primary"
              className="mt-md-4"
              onClick={getCompanyDataByNip}
            >
              Pobierz dane firmy po NIP
            </Button>
          )}
        </Col>
      </Row>
      <Row form>
        <Col>
          <FormGroup>
            <Label for="companyName">
              {i18next.t('sign-up.company-name-label')}
            </Label>
            <Input
              type="text"
              name="name"
              id="companyName"
              placeholder={i18next.t('sign-up.company-name-ph')}
              value={values.name}
              onChange={handleChange}
              disabled={isSubmitting || isCompanyLoading}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="companyAddress">
          {i18next.t('sign-up.company-address-label')}
        </Label>
        <InputWithAddon
          addonText={<MapPin />}
          addonType="prepend"
          type="text"
          name="address"
          id="companyAddress"
          placeholder={i18next.t('sign-up.company-address-ph')}
          value={values.address}
          onChange={handleChange}
          disabled={isSubmitting || isCompanyLoading}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="companyBankAccount">
          {i18next.t('sign-up.company-bank-account-label')}
        </Label>
        <PatternFormat
          format="## #### #### #### #### #### ####"
          mask="_"
          customInput={InputWithAddon}
          onValueChange={(valuesObject) =>
            props.setFieldValue('bank_account', valuesObject.value)
          }
          isNumericString
          type="tel"
          className="mw-300"
          name="bank_account"
          id="companyBankAccount"
          placeholder={i18next.t('sign-up.company-bank-account-ph')}
          addonText={`${(values.bank_account || '').length} / 26`}
          value={values.bank_account}
          disabled={isSubmitting || isCompanyLoading}
          invalid={errors && !!errors['bank_account']}
          required
        />
        {errors && errors['bank_account'] ? (
          <FormFeedback>{errors['bank_account']}</FormFeedback>
        ) : null}
        <FormText>{i18next.t('sign-up.company-bank-account-info')}</FormText>
      </FormGroup>
      <Row form>
        <Col md={7}>
          <FormGroup>
            <Label for="companyRepresenter">
              {i18next.t('sign-up.company-representer-label')}
            </Label>
            <InputWithAddon
              addonText={<User />}
              addonType="prepend"
              type="text"
              name="representer[name]"
              id="companyRepresenter"
              placeholder={i18next.t('sign-up.company-representer-ph')}
              value={values.representer.name}
              onChange={handleChange}
              disabled={isSubmitting || isCompanyLoading}
              valid={!!values.representer.name}
              required
            />
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="companyRepresenterPhone">
              {i18next.t('sign-up.company-representer-phone-label')}
            </Label>
            <OtoInputs.Phone
              className="mw-200"
              setFieldValue={props.setFieldValue}
              name="representer[phone]"
              id="companyRepresenterPhone"
              placeholder={i18next.t('sign-up.company-representer-phone-ph')}
              value={values.representer.phone}
              disabled={isSubmitting || isCompanyLoading}
              required
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="companySignedAt">
          {i18next.t('sign-up.company-signed-at-label')}
        </Label>
        <Input
          className="mw-250"
          name="signed_at"
          id="companySignedAt"
          value={values.signed_at}
          disabled
        />
      </FormGroup>
      <Row form>
        <Col md={9}>
          <Button
            type="submit"
            color="primary"
            block
            className="d-flex justify-content-center align-items-center"
            disabled={isSubmitting || isCompanyLoading}
          >
            <span>{i18next.t('sign-up.company-submit-btn')}</span>
            {isSubmitting && (
              <Spinner size="sm" color="light" className="ml-2" />
            )}
          </Button>
        </Col>
        <Col md={3}>
          <Button
            type="button"
            color="secondary"
            className="mt-2 mt-md-0"
            block
            disabled={isSubmitting || isCompanyLoading}
            onClick={handleGoBack ? () => handleGoBack(values) : undefined}
          >
            {i18next.t('sign-up.company-goback-btn')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

CompanyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withFormik<TOwnProps, CompanyFormValues>({
  mapPropsToValues: (): CompanyFormValues => ({
    nip: '',
    name: '',
    address: '',
    bank_account: '',
    representer: {
      name: '',
      phone: '+48',
    },
    signed_at: toDateInputValue(new Date(), '.'),
  }),
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    setSubmitting(true);
    const url = '/company';
    APIService.post(url, values)
      .then((createdCompany) =>
        props.onSubmit(createdCompany, values.representer)
      )
      .catch((errorRespose) => {
        toast.error(
          `Wystąpił błąd zapisu firmy. Popraw błędy i spróbuj ponownie, lub skontaktuj się z nami mailowo: ${CONTACT_DATA.HELP_EMAIL}.`,
          { autoClose: false }
        );
        logImportantActivity(
          `Wystąpił błąd dodawania nowej firmy (rejestracja restauracji - krok 1)! 
          Error: ${JSON.stringify(errorRespose)}
          Dane firmy: ${userFriednlyLog(values)}`
        );
        setErrors(errorRespose.errors);
      })
      .finally(() => setSubmitting(false));
  },
})(CompanyForm);
