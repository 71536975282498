import React, { ComponentType } from 'react';
import { useLocation, useNavigate, NavigateFunction, Location } from 'react-router-dom';

// Define the additional props that withRouter will provide
export interface WithRouterProps {
  navigate: NavigateFunction;
  location: Location;
}

// Define a type for the component that can accept the extra props
// P extends WithRouterProps means any props P must include the props defined in WithRouterProps
export const withRouter = <P extends WithRouterProps>(Component: ComponentType<P>) => {
  // Wrapper component
  const Wrapper: React.FC<Omit<P, keyof WithRouterProps>> = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    // Cast the props to ensure type correctness
    const componentProps = { navigate, location, ...props } as P;

    return <Component {...componentProps} />;
  };
  
  return Wrapper;
};
