export enum ROLES {
  ADMIN = 1,
  RESTAURANT_ADMIN = 2,
  SALES_REP = 3,
  DELIVERY_COORDINATOR = 4,
  RESTAURANT_STAFF = 5,
  RESTAURANT_DRIVER = 6,
  LUBJE_STAKEHOLDER = 7,
  LESZNOJE_ADMIN = 8,
  EXCHANGE_DRIVER = 9,
}

export const roleNamesById: Record<ROLES, string> = {
  [ROLES.ADMIN]: 'Admin / Team Member',
  [ROLES.RESTAURANT_ADMIN]: 'Restaurant admin',
  [ROLES.SALES_REP]: 'Sales Rep',
  [ROLES.DELIVERY_COORDINATOR]: 'Delivery coordinator',
  [ROLES.RESTAURANT_STAFF]: 'Restaurant personnel',
  [ROLES.RESTAURANT_DRIVER]: 'Restaurant driver',
  [ROLES.LUBJE_STAKEHOLDER]: 'LUB*JE Stakeholder',
  [ROLES.LESZNOJE_ADMIN]: 'LEszno Je! Admin',
  [ROLES.EXCHANGE_DRIVER]: 'Exchange driver',
};

const allAdminRoles = [
  ROLES.ADMIN,
  ROLES.LUBJE_STAKEHOLDER,
  ROLES.LESZNOJE_ADMIN,
  ROLES.SALES_REP,
]

export const ROLE_GROUPS = {
  allAdminRoles,
  allAdminRolesAndDeliveryCoordinator: [...allAdminRoles, ROLES.DELIVERY_COORDINATOR],
  allDriverRoles: [ROLES.RESTAURANT_DRIVER, ROLES.EXCHANGE_DRIVER],
};

