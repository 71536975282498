import React, { useCallback, useEffect, useState } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { addMinutes } from 'date-fns/esm';
import { Button, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import {
  AddReservationFormValues,
  IPlace,
  IRestaurantPlaceArea,
  ISavedReservation,
  ITableWithPlace,
  NormalizedRestaurant,
  ReservationConfig,
  TTableSize,
  TTableSuggestionWithPeopleAmount,
  TTableWithPeopleAmount,
} from 'types';
import {
  getDefaultDuration,
  generateReservedOn,
  isDeclined,
} from 'utils/reservation';
import areasByRestaurant from './areas';
import { CollapsibleCard, OtoButtons, OtoFormGroup } from '../../common';
import Shapes from 'shapes/main';
import type { ReservationCalendarPreferences } from 'store/reservation-calendar';

import './ReservationsPlace.scss';

interface IProps {
  activePlace: IPlace | null;
  bookingConfig: ReservationConfig;
  calendarPreferences: ReservationCalendarPreferences;
  canEdit: boolean;
  onReservationClick: (reservation: ISavedReservation) => void;
  onTableSelect?: (table: ITableWithPlace) => void;
  onPlaceUpdate: (place: IPlace) => void;
  restaurant: NormalizedRestaurant;
  restaurantPlaces: IPlace[];
  reservationsByTables: Record<number, ISavedReservation[]>;
  reservationFormValues: AddReservationFormValues | null;
  selectedTables: TTableWithPeopleAmount[];
  tables: ITableWithPlace[];
  tableSuggestions: TTableSuggestionWithPeopleAmount[];
}

export const ReservationsPlace: React.FC<IProps> = (props) => {
  const {
    activePlace,
    bookingConfig,
    calendarPreferences,
    canEdit,
    restaurant,
    reservationsByTables,
    onPlaceUpdate,
    onTableSelect,
    onReservationClick,
    reservationFormValues,
    restaurantPlaces,
    selectedTables,
    tables,
    tableSuggestions,
  } = props;
  const { showPeopleAmountOnMap, showReservationIfPresentOnMap } =
    calendarPreferences;

  const getDefaultArea = useCallback(() => {
    if (!activePlace || !restaurant) {
      return null;
    }

    return (
      activePlace.area ||
      restaurantPlaces.find((place) => place.id === activePlace.id)?.area ||
      areasByRestaurant[restaurant.id]?.[activePlace.id] ||
      null
    );
  }, [activePlace, restaurant, restaurantPlaces]);
  const [editing, setEditing] = useState(false);
  const [editableTable, setEditableTable] = useState<ITableWithPlace | null>(
    null
  );
  const [area, setArea] = useState<IRestaurantPlaceArea | null>(
    getDefaultArea()
  );

  useEffect(() => {
    setArea(getDefaultArea());
  }, [activePlace, getDefaultArea]);

  const handleSave = useCallback(() => {
    area &&
      activePlace &&
      onPlaceUpdate({
        ...activePlace,
        area,
      });
    setEditing(false);
  }, [activePlace, area, onPlaceUpdate]);

  if (!activePlace) {
    return (
      <div className="restaurant-map">Wybierz salę, aby wyświetlić mapę</div>
    );
  }

  if (!area || !area?.tablesConfig) {
    return (
      <div className="my-2">
        Mapa sali {activePlace.name} w restauracji {restaurant.name} nie została
        zdefiniowana
      </div>
    );
  }

  if (!reservationFormValues?.visitDate) {
    return (
      <div className="restaurant-map">
        Wybierz datę w modułu znajdź miejsce aby wyświetlić mapę stolików
      </div>
    );
  }

  const defaultDuration = getDefaultDuration(bookingConfig);
  const desiredStart = new Date(
    generateReservedOn({
      visitDate: reservationFormValues.visitDate,
      visitTime: reservationFormValues.visitTime,
    })
  );
  const desiredEnd = addMinutes(desiredStart, reservationFormValues.duration);

  // TODO write tests for this function
  const isTableBusy = (table: ITableWithPlace): boolean => {
    const blockingReservations = (reservationsByTables[table.id] || []).filter(
      (reservation) => {
        if (isDeclined(reservation)) {
          return false;
        }

        const reservationStart = new Date(reservation.reserved_on);
        const reservationEnd = addMinutes(
          reservationStart,
          reservation.duration || defaultDuration
        );

        const startsAfter = desiredStart >= reservationEnd;
        const endsBefore = desiredEnd <= reservationStart;
        const isReservationInSelectedHours = !startsAfter && !endsBefore;

        return isReservationInSelectedHours || reservation.is_entire_place;
      }
    );
    return !!blockingReservations?.length;
  };

  const { tableSize } = area;

  const placeRatio = area.image ? area.image.height / area.image.width : 9 / 16;

  const suggestedTableIds = tableSuggestions.map((table) => table.id);
  const selectedTableIds = (selectedTables || []).map((table) => table.id);

  const handleAreaClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!editing) {
      return;
    }
    if (!editableTable) {
      toast.error('Najpierw wybierz stolik do edycji');
      return;
    }
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const percentX = parseFloat(
      ((x * 100) / e.currentTarget.clientWidth).toFixed(2)
    );
    const percentY = parseFloat(
      ((y * 100) / e.currentTarget.clientHeight).toFixed(2)
    );

    area.tablesConfig[editableTable?.id] = {
      ...area.tablesConfig[editableTable?.id],
      position: {
        x: `${percentX}%`,
        y: `${percentY}%`,
      },
    };
    setEditableTable(null);
  };

  const handleTableClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    table: ITableWithPlace,
    { isBusy, isSelected }: { isBusy: boolean; isSelected: boolean }
  ) => {
    e.stopPropagation();
    if (editing) {
      setEditableTable(table);
      return;
    }
    if (!onTableSelect) {
      toast.error('W tym widoku nie można wybrać stolika');
      return;
    }
    if (!editing) {
      if (isBusy && !isSelected) {
        window.confirm(
          `Stolik ${table.name} jest przypisany do innych rezerwacji o podobnych godzinach. Czy chcesz wybrać go mimo to?`
        ) && onTableSelect(table);
      } else {
        onTableSelect(table);
      }
      return;
    }
  };

  const tablesWithoutPosition = tables
    .filter((table) => table.place_id === activePlace.id)
    .filter((table) => !area.tablesConfig[table.id]?.position);

  const updateTableSize = (
    editableTable: ITableWithPlace,
    size: Partial<TTableSize>
  ) => {
    setArea({
      ...area,
      tablesConfig: {
        ...area.tablesConfig,
        [editableTable.id]: {
          ...area.tablesConfig[editableTable.id],
          size: {
            ...area.tablesConfig[editableTable.id]?.size,
            ...size,
          },
        },
      },
    });
  };

  const updateTablePosition = (
    editableTable: ITableWithPlace,
    position: Partial<{ x: string; y: string }>
  ) => {
    setArea({
      ...area,
      tablesConfig: {
        ...area.tablesConfig,
        [editableTable.id]: {
          ...area.tablesConfig[editableTable.id],
          position: {
            ...area.tablesConfig[editableTable.id]?.position,
            ...position,
          },
        },
      },
    });
  };

  const shouldTableHaveClick = editing || onTableSelect;

  return (
    <div>
      {tablesWithoutPosition.length > 0 && (
        <>
          <p>Następujące stoliki nie mają ustawionego miejsca na mapce:</p>
          <ul className="p-0 d-flex flex-wrap">
            {tablesWithoutPosition.map((table) => (
              <li
                key={table.id}
                className="d-flex align-vertical br-1 pr-2 mr-2"
              >
                {table.name} ({table.min_people}-{table.max_people} os.)
                {canEdit && editing && (
                  <OtoButtons.AddButton
                    onClick={(e) =>
                      handleTableClick(e, table, {
                        isBusy: false,
                        isSelected: false,
                      })
                    }
                  >
                    Ustaw
                  </OtoButtons.AddButton>
                )}
              </li>
            ))}
          </ul>
          {canEdit && !editing && (
            <OtoButtons.EditButton
              color="primary"
              onClick={() => {
                setEditing(true);
              }}
            >
              {'Edytuj'}
            </OtoButtons.EditButton>
          )}
        </>
      )}
      <section
        className="restaurant-map__place"
        style={{
          ...area.styles,
          backgroundImage: `url(${area.image?.src})`,
          paddingBottom: `${placeRatio * 100}%`,
        }}
        onClick={handleAreaClick}
      >
        {tables
          .filter((table) => table.place.id === activePlace.id)
          .map((table, index) => ({
            ...table,
            size: {
              width:
                area.tablesConfig[table.id]?.size?.width || tableSize?.width,
              height:
                area.tablesConfig[table.id]?.size?.height || tableSize?.height,
            },
          }))
          .map((table) => {
            const areaPosition = area.tablesConfig[table.id]?.position;
            let top: string | number = 0;
            if (!!areaPosition) {
              if (typeof areaPosition?.y === 'number') {
                top =
                  areaPosition.y < 0
                    ? `calc(100% - ${areaPosition.y * -1}px)`
                    : areaPosition.y;
              } else {
                top = areaPosition.y;
              }
            }

            const left = areaPosition?.x || 0;

            const isInactive = !areaPosition;
            const isProposed = suggestedTableIds.includes(table.id);
            const isSelected = selectedTableIds.includes(table.id);
            const isBusy = isTableBusy(table);

            return (
              <article
                key={table.id}
                id={`table-${table.id}`}
                className={[
                  'restaurant-map__place-table',
                  isBusy
                    ? 'restaurant-map__place-table--busy'
                    : 'restaurant-map__place-table--free',
                  shouldTableHaveClick
                    ? ''
                    : 'restaurant-map__place-table--read-only',
                  isInactive ? 'restaurant-map__place-table--inactive' : '',
                  isProposed ? 'restaurant-map__place-table--proposed' : '',
                  isSelected ? 'restaurant-map__place-table--selected' : '',
                ].join(' ')}
                style={{
                  top,
                  left,
                  width: table.size.width,
                  height: table.size.height,
                }}
                onClick={
                  shouldTableHaveClick
                    ? (e) => handleTableClick(e, table, { isBusy, isSelected })
                    : undefined
                }
              >
                <div>{table.name}</div>
                {showPeopleAmountOnMap && (
                  <div>
                    ({table.min_people} - {table.max_people}) os.
                  </div>
                )}
                {showReservationIfPresentOnMap &&
                  reservationsByTables[table.id]?.map((reservation) => {
                    const time = new Date(reservation.reserved_on);
                    const from = format(time, 'HH:mm', { locale: pl });
                    const to = format(
                      addMinutes(
                        time,
                        reservation.duration ||
                          restaurant.config.reservation_duration ||
                          60
                      ),
                      'HH:mm',
                      { locale: pl }
                    );
                    return (
                      <div
                        key={reservation.id}
                        style={{
                          border: '1px solid black',
                          padding: 5,
                          marginBottom: 10,
                        }}
                        onClick={() => onReservationClick(reservation)}
                      >
                        {from}-{to} {reservation.guests_number} os.
                      </div>
                    );
                  })}
              </article>
            );
          })}
      </section>
      {canEdit && (
        <>
          {editing && (
            <>
              <CollapsibleCard
                buttonClassName={'p-1'}
                title={'Domyślne rozmiary stolików'}
              >
                <div className="d-flex gap-10">
                  <FormGroup className="mw-200">
                    <label htmlFor="default-table-width">
                      Domyślna szerowkość stolika
                    </label>
                    <Input
                      type="text"
                      id="default-table-width"
                      value={area.tableSize?.width || ''}
                      onChange={(e) =>
                        setArea({
                          ...area,
                          tableSize: {
                            ...area.tableSize,
                            width: e.target.value,
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="mw-200">
                    <label htmlFor="default-table-height">
                      Domyślna wysokość stolika
                    </label>
                    <Input
                      type="text"
                      id="default-table-height"
                      value={area.tableSize?.height || ''}
                      onChange={(e) =>
                        setArea({
                          ...area,
                          tableSize: {
                            ...area.tableSize,
                            height: e.target.value,
                          },
                        })
                      }
                    />
                  </FormGroup>
                </div>
              </CollapsibleCard>
              <CollapsibleCard buttonClassName={'p-2'} title={'Obrazek mapy'}>
                <div className="d-flex gap-10 row mx-0">
                  <OtoFormGroup
                    className="col p-0"
                    label={'Link do obrazka'}
                    name={'imageSource'}
                    value={area.image?.src || ''}
                    onChange={(e) =>
                      setArea({
                        ...area,
                        image: {
                          height: area.image?.height || 0,
                          width: area.image?.width || 0,
                          src: e.target.value,
                        },
                      })
                    }
                  />
                  <OtoFormGroup
                    className="min-w-150"
                    label={'Szerokość obrazka'}
                    name={'imageWidth'}
                    value={area.image?.width ?? ''}
                    onChange={(e) =>
                      setArea({
                        ...area,
                        image: {
                          height: area.image?.height || 0,
                          width: e.target.value ? parseInt(e.target.value) : 0,
                          src: area.image?.src || '',
                        },
                      })
                    }
                  />
                  <OtoFormGroup
                    className="min-w-150"
                    label={'Wysokość obrazka'}
                    name={'imageHeight'}
                    type={'number'}
                    step={1}
                    min={0}
                    value={area.image?.height ?? ''}
                    onChange={(e) =>
                      setArea({
                        ...area,
                        image: {
                          height: e.target.value ? parseInt(e.target.value) : 0,
                          width: area.image?.width || 0,
                          src: area.image?.src || '',
                        },
                      })
                    }
                  />
                </div>
              </CollapsibleCard>
            </>
          )}
          {editing && editableTable && (
            <CollapsibleCard
              buttonClassName={'p-2'}
              title={<>Edytuj wybrany stolik: {editableTable.name}</>}
              openByDefault={true}
            >
              <h4>Rozmiary stolika {editableTable.name}</h4>
              <div className="d-flex gap-10">
                <FormGroup className="mw-200">
                  <label htmlFor="table-width">Szerowkość stolika</label>
                  <Input
                    type="text"
                    id="table-width"
                    value={
                      area.tablesConfig[editableTable.id]?.size?.width || ''
                    }
                    onChange={(e) =>
                      updateTableSize(editableTable, {
                        width: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup className="mw-200">
                  <label htmlFor="table-width">Wysokość stolika</label>
                  <Input
                    type="text"
                    id="table-height"
                    value={
                      area.tablesConfig[editableTable.id]?.size?.height || ''
                    }
                    onChange={(e) =>
                      updateTableSize(editableTable, {
                        height: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <OtoButtons.ReloadButton
                  onClick={() =>
                    updateTableSize(editableTable, {
                      height: undefined,
                      width: undefined,
                    })
                  }
                >
                  Resetuj rozmiary
                </OtoButtons.ReloadButton>
              </div>
              <h4>Odstępy stolika {editableTable.name}</h4>
              <div className="d-flex gap-10">
                <FormGroup className="mw-200">
                  <label htmlFor="table-width">Odstęp lewo (x)</label>
                  <Input
                    type="text"
                    id="table-height"
                    value={
                      area.tablesConfig[editableTable.id]?.position?.x || ''
                    }
                    onChange={(e) =>
                      updateTablePosition(editableTable, {
                        x: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup className="mw-200">
                  <label htmlFor="table-width">Odstęp góra (y)</label>
                  <Input
                    type="text"
                    id="table-height"
                    value={
                      area.tablesConfig[editableTable.id]?.position?.y || ''
                    }
                    onChange={(e) =>
                      updateTablePosition(editableTable, {
                        y: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </div>
            </CollapsibleCard>
          )}
          <div
            style={{
              display: 'flex',
              gap: 10,
            }}
          >
            <OtoButtons.EditButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setEditing(!editing);
              }}
              outline={true}
            >
              {editing ? 'Zakończ edycję' : 'Edytuj'}
            </OtoButtons.EditButton>
            <Button
              type="button"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                console.log('area is', area);
              }}
              outline={true}
            >
              Pokaż na konsoli
            </Button>
            <OtoButtons.SaveButton
              type="button"
              onClick={handleSave}
              outline={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

ReservationsPlace.propTypes = {
  activePlace: Shapes.restaurantPlaceShape,
  bookingConfig: Shapes.reservationConfigShape.isRequired,
  onTableSelect: PropTypes.func.isRequired,
  reservationsByTables: PropTypes.objectOf(
    PropTypes.arrayOf(
      Shapes.savedReservationShape.isRequired as Validator<ISavedReservation>
    ).isRequired
  ).isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
  onReservationClick: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(Shapes.restaurantTableWithPlaceShape.isRequired)
    .isRequired,
};

export default ReservationsPlace;
