import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import FcmTokenProvider from './FcmTokenProvider';
import ElectronFcmTokenProvider from './ElectronFcmTokenProvider';
import { isElectron } from 'utils/general';
import { ding } from 'utils/sounds';
import {
  isConfigUpdateMessage,
  isDriverArrivedMessage,
  isMenuUpdateMessage,
  isNewDeliveryMessage,
  isNewOrderMessage,
  isDriverAssignedMessage,
  isDriverUnassignedMessage,
} from 'utils/firebase-messaging';
import type { NormalizedRestaurant } from 'types';
import { loadOrders } from 'store/orders';
import { loadRestaurant } from 'store/restaurant';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { selectHasMultipleRestaurants, selectUserRestaurants } from 'store/app';

let firebaseMessageListeners: ((message: any) => void)[] = [];

type FcmMessageHandlerProps = {
  messagingError: any;
  restaurant: NormalizedRestaurant | null;
  setFcmError: React.Dispatch<any>;
};

const FcmMessageHandler: React.FC<FcmMessageHandlerProps> = ({
  messagingError,
  restaurant,
  setFcmError,
}) => {
  const dispatch = useAppDispatch();
  const userRestaurants = useAppSelector(selectUserRestaurants);
  const hasMultipleRestaurants = useAppSelector(selectHasMultipleRestaurants);

  useEffect(() => {
    firebaseMessageListeners = [
      handleConfigUpdateMsg,
      handleDriverArrivedMsg,
      handleMenuUpdateMsg,
      handleNewDeliveryMsg,
      handleNewOrderMsg,
      handleDriverAssignedMsg,
      handleDriverUnassignedMsg,
    ];
  }, []);
  const handleFcmMessage = (message) => {
    firebaseMessageListeners.forEach((listener) => listener(message));
    const validators = [
      isConfigUpdateMessage,
      isDriverArrivedMessage,
      isMenuUpdateMessage,
      isNewDeliveryMessage,
      isNewOrderMessage,
      isDriverAssignedMessage,
      isDriverUnassignedMessage,
    ];
    const isUnknownMessage = validators.every(
      (validator) => !validator(message)
    );
    if (isUnknownMessage) {
      ding();
      const content =
        'notification' in message
          ? `(testowe powiadomienie) ${message.notification.title}: ${message.notification.body}`
          : `Otrzymałeś testowe powiadomienie: ${JSON.stringify(message)}`;
      toast.info(content);
    }
  };

  const handleMenuUpdateMsg = (message) => {
    if (isMenuUpdateMessage(message)) {
      toast.info('Inny użytkownik zaktualizował menu, pobieram zmiany');
      dispatch(
        loadRestaurant(restaurant!.id, {
          allowCache: false,
          setLoading: true,
        })
      );
    }
  };

  const handleConfigUpdateMsg = (message) => {
    if (isConfigUpdateMessage(message)) {
      toast.info('Inny użytkownik zaktualizował ustawienia, pobieram zmiany');
      dispatch(
        loadRestaurant(restaurant!.id, {
          allowCache: false,
          setLoading: true,
        })
      );
    }
  };

  const handleDriverArrivedMsg = (message) => {
    if (isDriverArrivedMessage(message)) {
      toast.info('Dostawca przyjechał po odbiór zamówienia');
      ding();
    }
  };

  const handleDriverAssignedMsg = (message) => {
    if (isDriverAssignedMessage(message)) {
      toast.info('Restauracja przydzieliła Ci nową dostawę');
      ding();
    }
  };

  const handleDriverUnassignedMsg = (message) => {
    if (isDriverUnassignedMessage(message)) {
      toast.info(
        'Jedna z Twoich dostaw została przydzielona na nowo - sprawdź aktualną listę dostaw'
      );
      ding();
    }
  };

  const handleNewDeliveryMsg = (message) => {
    if (isNewDeliveryMessage(message)) {
      toast.info('Otrzymałeś nową dostawę');
      ding();
    }
  };

  const handleNewOrderMsg = (message) => {
    if (isNewOrderMessage(message)) {
      const ids =
        hasMultipleRestaurants && userRestaurants
          ? userRestaurants.map((r) => r.id).join(',')
          : restaurant!.id;
      const url = `/restaurants/${ids}/orders`;
      dispatch(loadOrders(url));
    }
  };

  return (
    <>
      {isElectron() ? (
        <ElectronFcmTokenProvider
          messagingError={messagingError}
          onMessage={handleFcmMessage}
          setError={setFcmError}
          restaurant={restaurant as NormalizedRestaurant}
        />
      ) : (
        <FcmTokenProvider
          messagingError={messagingError}
          onMessage={handleFcmMessage}
          setError={setFcmError}
          restaurant={restaurant as NormalizedRestaurant}
        />
      )}
    </>
  );
};

export default FcmMessageHandler;
