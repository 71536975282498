import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, ButtonGroup, Card, CardBody } from 'reactstrap';
import { Edit, Trash } from 'react-feather';
import i18next from 'i18next';

import { toDateInputValue } from 'utils/date-time';
import { displayTime } from 'utils/date-time';
import IconWithTooltip from '../common/IconWithTooltip';
import { usageTypeOptions } from './UsageTypeSelect';
import { IPromoCode, UsageType } from 'types/promo-code';
import { promoCodelabelsByType } from './getPromoCodeLabel';
import Shapes from 'shapes/main';

const AddFirstItem = (props) => {
  const { btnText = '', className = '' } = props;
  return (
    <Card className={className}>
      <CardBody className="d-flex flex-column align-items-center justify-content-center">
        {props.children}
        <button
          className="mt-3 brand-btn"
          onClick={props.onClick}
          type="button"
        >
          {btnText}
        </button>
      </CardBody>
    </Card>
  );
};

type PromoCodesListProps = {
  onCreate: () => void;
  onEdit: (promoCode: IPromoCode) => void;
  onDelete: (promoCode: IPromoCode) => void;
  promoCodes: null | IPromoCode[];
};

export const PromoCodesList: React.FC<PromoCodesListProps> = ({
  onCreate,
  onEdit,
  onDelete,
  promoCodes,
}) => {
  if (!promoCodes || !promoCodes.length) {
    return (
      <AddFirstItem
        className="mt-1"
        btnText="Dodaj swój pierwszy kod"
        onClick={onCreate}
      >
        Lista kodów promocyjnych jest pusta.
      </AddFirstItem>
    );
  }
  return (
    <Card className="p-0">
      <CardBody className="p-1">
        <Table className="m-0 reduced-padding-table" responsive striped>
          <thead>
            <tr>
              <th>Kod</th>
              <th>Opis</th>
              <th className="min-w-150">Rodzaj promocji</th>
              <th className="min-w-150">
                <span className="mr-2 text-center">
                  {i18next.t('promocode.usage-type-label')}
                </span>
                <IconWithTooltip
                  id="promo-code-type"
                  text={usageTypeOptions
                    .map((e) => i18next.t(e.name))
                    .join(', ')}
                />
              </th>
              <th className="min-w-200">Okres ważności</th>
              <th>
                <span className="mr-2">Ile razy użyty</span>
              </th>
              <th>
                <span className="mr-2">Min. koszyk</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {promoCodes.map((promoCode) => {
              const activatedAt = new Date(promoCode.activated_at);
              const deactivatedAt = new Date(promoCode.deactivated_at);
              const usageTypeText = i18next.t(
                usageTypeOptions.find(
                  (opt) => opt.value === promoCode.usage_type
                )?.name as string
              );
              return (
                <tr key={`${promoCode.id}`}>
                  <td>
                    <div>{promoCode.code}</div>
                    <ButtonGroup size="sm" className="mt-2">
                      <Button color="success" onClick={() => onEdit(promoCode)}>
                        <Edit size="14" />
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => onDelete(promoCode)}
                      >
                        <Trash size="14" />
                      </Button>
                    </ButtonGroup>
                  </td>
                  <td>{promoCode.description}</td>
                  <td>
                    <div>{promoCodelabelsByType[promoCode.discount_type]}</div>
                    {!isFreeDeliveryCode(promoCode) && (
                      <div className="mt-1">Wartość: {promoCode.discount}</div>
                    )}
                  </td>
                  <td className="text-center">{usageTypeText}</td>
                  <td className="min-w-200">
                    <div>
                      OD: {toDateInputValue(activatedAt)}{' '}
                      {displayTime(activatedAt)}
                    </div>
                    {!!deactivatedAt ? (
                      <div className="mt-1">
                        DO: {toDateInputValue(deactivatedAt)}{' '}
                        {displayTime(deactivatedAt)}
                      </div>
                    ) : (
                      <div>bez terminu ważności</div>
                    )}
                  </td>
                  <td className="text-center">
                    {promoCode.orders_count}
                    {promoCode.usage_type === UsageType.Single &&
                    promoCode.redeemed
                      ? ' (max.)'
                      : ''}
                  </td>
                  <td>{promoCode.min_cart_amount || 'Brak'}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

PromoCodesList.propTypes = {
  promoCodes: PropTypes.arrayOf(Shapes.promoCodeShape.isRequired),
  onCreate: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PromoCodesList;

function isFreeDeliveryCode(promoCode: IPromoCode) {
  return promoCode.discount_type === 'freeDelivery';
}
