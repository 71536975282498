import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import SimpleTableWithSorting, {
  ReactTableCellProps,
  generateColumn,
  getRTOriginal,
} from '../common/SimpleTableWithSorting';
import { NormalizedRestaurant } from 'types/restaurants';
import { User } from 'types/user';
import Shapes from 'shapes/main';
import OtoButtons from '../common/OtoButtons';
import APIService from 'services/api';
import { LOG_BAG } from 'utils/log';
import { YesNoIcons } from '../icons/IconSets';

interface IProps {
  canEditDrivers: boolean;
  drivers: User[];
  onDelete: (driver: User) => void;
  restaurant: NormalizedRestaurant;
}

const DriversList = (props: IProps) => {
  const { drivers, canEditDrivers, onDelete, restaurant } = props;

  const deleteDriver = useCallback(
    (driver: User) => {
      return APIService.del(
        `/restaurants/${restaurant.id}/drivers/${driver.id}`
      )
        .then(() => {
          toast.success(`Kierowca ${driver.name} został usunięty`);
          onDelete(driver);
          return driver;
        })
        .catch((error: Error) => {
          toast.error('Wystąpił problem podczas usuwania kierowcy');
          LOG_BAG.logError('Driver delete error', error);
        });
    },
    [onDelete, restaurant]
  );

  const columns = useMemo(
    () => [
      generateColumn('name'),
      generateColumn('email'),
      ...(canEditDrivers
        ? [
            generateColumn('is_working', {
              customCellFormatter: (props: ReactTableCellProps<User>) =>
                getRTOriginal<User>(props)?.is_working
                  ? YesNoIcons.YES
                  : YesNoIcons.NO,
            }),
          ]
        : []),
      generateColumn('id', {
        customHeader: 'Akcje',
        customCellFormatter: (props: ReactTableCellProps<User>) => (
          <>
            <OtoButtons.DeleteButton
              onClick={() => deleteDriver(getRTOriginal(props))}
            />
          </>
        ),
      }),
    ],
    [canEditDrivers, deleteDriver]
  );

  return (
    <>
      <SimpleTableWithSorting columns={columns} data={drivers} />
    </>
  );
};

DriversList.propTypes = {
  drivers: PropTypes.arrayOf(Shapes.userShape).isRequired,
  onDelete: PropTypes.func.isRequired,
  canEditDrivers: PropTypes.bool.isRequired,
};

DriversList.displayName = 'DriversList';

export default DriversList;
