import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { IRobot } from 'types/robot';
import Shapes from 'shapes/main';
import { G_MAPS_API_KEY } from 'config';
import DeliveriesMapMarker from '../deliveries/DeliveriesMapMarker';

interface IProps {
  className?: string;
  robots: IRobot[];
}

const defineds = {
  chisza: {
    label: 'Chisza Lublin',
    lat: 51.2481818,
    lng: 22.5646204,
  },
  dodoPizza: {
    label: 'Dodo Pizza Jana Pawła',
    lat: 52.2338152,
    lng: 20.9988077,
  },
};

const mapContainerStyle = { marginTop: '10px', height: 400, width: '100%' };

const restaurantMarkers = Object.values(defineds).map((item) => (
  <DeliveriesMapMarker
    content={item.label}
    color="blue"
    lat={item.lat}
    lng={item.lng}
  />
));

const RobotsMap = (props: IProps) => {
  const { className, robots } = props;

  console.warn('fix / unify google map keys!');

  const [mapCenter, setMapCenter] = useState({
    lat: defineds.dodoPizza.lat,
    lng: defineds.dodoPizza.lng,
  });

  useEffect(() => {
    if (robots?.[0] && robots[0].location) {
      setMapCenter({
        lat: robots[0].location.lat,
        lng: robots[0].location.lng,
      });
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={className} style={mapContainerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: G_MAPS_API_KEY }}
        defaultCenter={mapCenter}
        defaultZoom={16}
      >
        {robots
          .filter((robot) => robot.user && robot.location)
          .map((robot) => (
            <DeliveriesMapMarker
              content={
                <div>
                  <div>Robot: {robot.name}</div>
                  <div>Op: {robot.user?.name}</div>
                </div>
              }
              color="red"
              lat={robot.location?.lat}
              lng={robot.location?.lng}
            />
          ))}
        {restaurantMarkers}
      </GoogleMapReact>
    </div>
  );
};

RobotsMap.propTypes = {
  className: PropTypes.string,
  robots: PropTypes.arrayOf(Shapes.robotShape).isRequired,
};

RobotsMap.defaultProps = {
  className: '',
};

RobotsMap.displayName = 'RobotsMap';

export default RobotsMap;
