import { ROLES, ROLE_GROUPS } from 'enums';
import { IRoute } from './types';
import DashboardPageForRootAdmin from 'app/pages/admin/DashboardPageForRootAdmin';
import AdminStatisticsPage from 'app/pages/admin/AdminStatisticsPage';
import AdminRestaurantsPage from 'app/pages/admin/AdminRestaurantsPage';
import AdminUsersPage from 'app/pages/admin/AdminUsersPage';
import AdminIntegrationsPage from 'app/pages/admin/AdminIntegrationsPage';
import FleetManagementPage from 'app/pages/admin/FleetManagementPage';
import OrdersDeclinationPage from 'app/pages/admin/OrdersDeclinationPage';
import AdminPayoutsPage from 'app/pages/admin/AdminPayoutsPage';
import RestaurantCompaniesPage from 'app/pages/admin/RestaurantCompaniesPage';
import DashboardPageForTenantAdmin from 'app/pages/admin/DashboardPageForTenantAdmin';
import AdminPaymentsPage from 'app/pages/admin/AdminPaymentsPage';
import AdminDeliveriesListPage from 'app/pages/admin/AdminDeliveriesPage';

export const rootAdminDashboardRoute: IRoute = {
  name: DashboardPageForRootAdmin.navName,
  path: DashboardPageForRootAdmin.url,
  component: DashboardPageForRootAdmin,
  availableForRoles: [ROLES.ADMIN],
};

export const adminStatisticsRoute: IRoute = {
  name: AdminStatisticsPage.navName,
  path: AdminStatisticsPage.url,
  component: AdminStatisticsPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const tenantAdminDashboardRoute: IRoute = {
  name: DashboardPageForTenantAdmin.navName,
  path: DashboardPageForTenantAdmin.url,
  component: DashboardPageForTenantAdmin,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminRestaurantListRoute: IRoute = {
  name: (AdminRestaurantsPage as any).navName,
  path: (AdminRestaurantsPage as any).url,
  component: AdminRestaurantsPage as unknown as React.ComponentType,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminUsersRoute: IRoute = {
  name: AdminUsersPage.navName as string,
  path: AdminUsersPage.url as string,
  component: AdminUsersPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminRestaurantCompaniesRoute: IRoute = {
  name: RestaurantCompaniesPage.navName,
  path: RestaurantCompaniesPage.url,
  component: RestaurantCompaniesPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminOrderDeclinationRoute: IRoute = {
  name: OrdersDeclinationPage.navName as string,
  path: OrdersDeclinationPage.url as string,
  component: OrdersDeclinationPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminDeliveriesRoute: IRoute = {
  name: AdminDeliveriesListPage.navName,
  path: AdminDeliveriesListPage.url,
  component: AdminDeliveriesListPage,
  availableForRoles: ROLE_GROUPS.allAdminRolesAndDeliveryCoordinator,
};

export const adminPaymentsRoute: IRoute = {
  name: AdminPaymentsPage.navName,
  path: AdminPaymentsPage.url,
  component: AdminPaymentsPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminPayoutsRoute: IRoute = {
  name: AdminPayoutsPage.navName,
  path: AdminPayoutsPage.url,
  component: AdminPayoutsPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};

export const adminFleetManagerRoute: IRoute = {
  name: FleetManagementPage.navName,
  path: FleetManagementPage.url,
  component: FleetManagementPage,
  availableForRoles: [ROLES.ADMIN],
};

export const adminIntegrationsRoute: IRoute = {
  name: AdminIntegrationsPage.navName as string,
  path: AdminIntegrationsPage.url as string,
  component: AdminIntegrationsPage,
  availableForRoles: ROLE_GROUPS.allAdminRoles,
};
