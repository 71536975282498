import React from 'react';
import { Bar } from 'react-chartjs-2';

const colors = ['#f4623a', '#1497FF', 'yellow', '#6ED3FF', 'green', 'magenta'];

export const OtoStackedBarChart: React.FC<any> = (props) => {
  const { labels, datasets } = props;
  const data = {
    labels,
    datasets: datasets.map((originalItem, index) => ({
      ...originalItem,
      pointStyle: 'rectRounded',
      backgroundColor: colors[index] || 'grey',
    })),
  };
  return (
    <div className="App">
      <Bar
        data={{
          labels: data.labels,
          datasets: data.datasets,
        }}
        height={400}
        options={{
          layout: {
            padding: {
              top: 30,
              right: 40,
              bottom: 40,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'right',
              align: 'start',
              labels: {
                usePointStyle: true,
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              // precision: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default OtoStackedBarChart;
