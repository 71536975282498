import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { LOG_BAG } from 'utils/log';
import { logout } from 'store/app';
import ErrorScreen from './ErrorScreen';

type ErrorBoundaryProps = {
  componentName: string;
  errorComponent?: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

const ErrorBoundaryLocationProvider: React.FC<ErrorBoundaryProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ErrorBoundary location={location} navigate={navigate} {...props}>
      {props.children}
    </ErrorBoundary>
  );
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps & {
    location: {
      pathname: string;
    };
    navigate: (path: string) => void;
  },
  ErrorBoundaryState
> {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    LOG_BAG.logComponentDidCatch(this.props.componentName, error, errorInfo);
  }

  handleLogout = () => {
    const { location, navigate } = this.props;
    logout({
      location,
      navigate,
    });
  };

  render() {
    const { errorComponent, children } = this.props;
    if (this.state.hasError) {
      return errorComponent || <ErrorScreen onLogout={this.handleLogout} />;
    }

    return children;
  }
}

export default ErrorBoundaryLocationProvider;
