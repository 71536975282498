import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';

import ContactCard from 'app/components/contact/ContactCard';
import APIService from 'services/api';
import UrlFetcher from './UrlFetcher';
import { appName } from 'globals/version';
import { isElectron } from 'utils/general';
import OtoButtons from 'app/components/common/OtoButtons';

interface IAppStatusData {
  forceUpdate: boolean;
}

const UpdateAppBanner = () => {
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    const url = `/app-status/${appName}`;
    APIService.get(url).then((response: IAppStatusData) => {
      setShouldUpdate(response.forceUpdate);
    });
  }, []);

  if (!shouldUpdate) {
    return null;
  }
  return (
    <div>
      <Alert color="secondary">
        <p>
          Twoja wersja aplikacji jest przedawniona, niektóre funkcjonalności
          mogą nie działać poprawnie.
        </p>
        {isElectron() ? (
          <p className="mb-0">
            Skontaktuj się z nami w celu zainstalowania nowej wersji aplikacji.
          </p>
        ) : (
          <>
            <OtoButtons.ReloadButton
              className="p-0 mb-2"
              onClick={() => window.location.reload()}
            >
              Odśwież stronę aby zaktualizować
            </OtoButtons.ReloadButton>
            <p className="mb-0">
              Jeżeli ten komunikat nie zniknie - skontaktuj się z nami w celu
              aktualizacji.
            </p>
          </>
        )}
      </Alert>
      <UrlFetcher<{
        email: string;
        phone: string;
      }>
        url="/support/contact-data"
        onError="show-component"
      >
        {([contactData, urlFetcherProps]) => (
          <ContactCard contactData={contactData} />
        )}
      </UrlFetcher>
    </div>
  );
};

export default UpdateAppBanner;
