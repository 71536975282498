import { isDev } from 'config';
import { wasAnyClickMade } from 'app/containers/CaptureClickToPlaySound';
import { IOrder } from 'types';
import { logError } from 'utils/log';
import { getOSasString, isWindowsOrMacOS } from './device';
import { getAddress } from './orders';
import Order from './orders';

const dingSound = require('../assets/ding-louder-v1.mp3');

export function ding() {
  if (!wasAnyClickMade) {
    console.log('no click was made, so no sound will be played');
    return;
  }
  try {
    const audio = new Audio(dingSound);
    audio.play();
  } catch (e: any) {
    logError('Was unable to ding() sound', e);
  }
}

const notifyAboutPendingOrders = (orders: IOrder[]) => {
  if (!orders?.[0]) {
    return
  }

  try {
    const restaurantName = orders?.[0]?.restaurant?.name;
    const title =
      orders?.length > 1
        ? `${restaurantName ? `${restaurantName} - ` : ''}${
            orders.length
          } nowe zamówienia`
        : `${restaurantName ? `${restaurantName} - ` : ''}Nowe zamówienie`;

    const order = orders[0];

    const deliveryMethodPart = Order.isSelfCollect(order)
      ? Order.isTakeout(order)
        ? 'odbiorem własnym'
        : 'odbiorem drive-thru'
      : `dostawą pod adres ${getAddress(order)}`;

    const hasCustomPrepareTime = Order.hasCustomRealizationTime(order);

    const body = `Pojawiło się nowe zamówienie #${
      order.id
    } z ${deliveryMethodPart} ${
      restaurantName ? `w restauracji ${restaurantName}` : ''
    }.
      ${
        hasCustomPrepareTime
          ? ` Prośba o realizację na godzinę: ${order.customer.deliveryAt}`
          : ''
      }`;

    if (isWindowsOrMacOS()) {
      notify(title, body);
    }

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (!registration) {
          console.warn('no registration, so no notification will be shown');
          return;
        }

        const showNotification = () =>
          registration.showNotification(title, {
            body,
          });

        if (Notification.permission === 'granted') {
          showNotification();
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              showNotification();
            }
          });
        }
      });
    }
  } catch (e: any) {
    logError(`make some sound error, current OS: ${getOSasString()}`, e);
  }
};

export function safeDingOrNotify(orders: IOrder[]) {
  ding();
  notifyAboutPendingOrders(orders);
}

export function notify(title: string, body: string) {
  if (isDev) {
    return;
  }
  if (!('Notification' in window)) {
    alert('Obecna przeglądarka nie pozwala na wyświetlanie komunikatów push');
  } else if (Notification.permission === 'granted') {
    return new Notification(title, { body });
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        return new Notification(title, { body });
      }
    });
  }
}
