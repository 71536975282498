import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { Navbar, NavbarToggler, Collapse, Nav } from 'reactstrap';

import ToggleSidebarButton from './ToggleSidebarButton';

type THeaderRoute = {
  name: string;
  path: string;
};

type HeaderProps = {
  isActiveRoute: (route: THeaderRoute) => boolean;
  isSidebarCollapsed: boolean;
  onPageNameChange: (pageName: string) => void;
  onToggleSidebarClick: () => void;
  pageLoader: React.ReactNode;
  routes: THeaderRoute[];
};

const Header: React.FC<HeaderProps> = ({
  isActiveRoute,
  isSidebarCollapsed,
  children,
  onPageNameChange,
  onToggleSidebarClick,
  pageLoader,
  routes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getPageTitle = () => {
    let name;
    routes.forEach((route) => {
      if (isActiveRoute(route)) {
        name = route.name;
      }
      return null;
    });
    return i18next.t(`nav.${name}`);
  };

  const title = getPageTitle();

  useEffect(() => {
    onPageNameChange(title);
  }, [onPageNameChange, title]);

  return (
    <header className="app-header">
      <SkipToContentLink focusId="primary-content" />
      <div className="top-nav">
        <Navbar color="faded" light expand="md">
          <ToggleSidebarButton
            isSidebarCollapsed={isSidebarCollapsed}
            onToggleSidebarClick={onToggleSidebarClick}
          />
          <div className="page-heading">{title}</div>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto align-items-center" navbar>
              {children}
            </Nav>
          </Collapse>
          {pageLoader}
        </Navbar>
      </div>
    </header>
  );
};

export default Header;

const SkipToContentLink = ({ focusId }: { focusId: string }) => {
  return (
    <a href={`#${focusId}`} tabIndex={1} className="skip-to-content">
      Skip to Content
    </a>
  );
};
