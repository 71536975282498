import React, { useCallback, useEffect, useState } from 'react';
import memoizeOne from 'memoize-one';
import { Input, FormGroup, Label, Row, Col } from 'reactstrap';

import WithDateRangeDataFetched from '../../containers/WithDateRangeDataFetched';
import RestaurantOrderValuesTable from './RestaurantOrderValuesTable';
import StatisticsColumn from '../statistics/StatisticsColumn';
import { fixDecimal } from 'utils/general';
import { toDateInputValue } from 'utils/date-time';
import { currency } from 'globals/currency';
import { Tenants } from 'store/tenants';
import { useAppSelector } from 'hooks/useRedux';
import { selectAdminTenant } from 'store/admin';
import AdminTenantSelector from './AdminTenantSelector';

type DefaultRange = 'today' | 'week' | 'month' | 'lastWeek' | 'lastMonth';

export interface IRestaurantOrdersStats {
  name: string;
  orders_count: number;
  orders_price: number;
  orders_avg: number;
  orders_commission_sum: number;
}

interface IRestaurantOrdersStatsWithCommission extends IRestaurantOrdersStats {
  restaurant_commission: number;
}

const MAX_COMMISSION = 1200;
const COMMISSION_PERCENT = 8;

const addCommission = (
  restaurantItem: IRestaurantOrdersStats
): IRestaurantOrdersStatsWithCommission => ({
  ...restaurantItem,
  restaurant_commission: Math.min(
    (restaurantItem.orders_price * COMMISSION_PERCENT) / 100,
    MAX_COMMISSION
  ),
});
const getPerRestaurantStatsWithCommission = memoizeOne(
  (data: IRestaurantOrdersStats[]) => data.map(addCommission)
);

interface IProps {
  defaultRange?: DefaultRange;
  tenant?: Tenants;
}

const OrdersPerRestaurantStats: React.FC<IProps> = (props) => {
  const [source, setSource] = useState('');

  const adminSelectedTenant = useAppSelector(selectAdminTenant);

  const [showSelects, setShowSelects] = useState(true);

  useEffect(() => {
    if (props.tenant) {
      setShowSelects(false);
    }
  }, [props.tenant]);

  const getUrl = useCallback(
    (start, end) => {
      const from = toDateInputValue(start);
      const to = toDateInputValue(end);
      const tenantPart = !!adminSelectedTenant
        ? `&tenant=${adminSelectedTenant}`
        : '';
      const sourcePart = !!source ? `&source=${source}` : '';
      return `/statistics/orders-by-restaurants?from=${from}&to=${to}${tenantPart}${sourcePart}`;
    },
    [source, adminSelectedTenant]
  );

  const renderPerRestaurantStatistics = (data: IRestaurantOrdersStats[]) => {
    const perRestaurantsData = getPerRestaurantStatsWithCommission(data);
    const totalCommission: number = perRestaurantsData.reduce(
      (acc: number, item: IRestaurantOrdersStatsWithCommission) =>
        acc + item.restaurant_commission,
      0
    );
    const totalOrdersAmount = perRestaurantsData.reduce(
      (acc: number, item: IRestaurantOrdersStats) => acc + item.orders_count,
      0
    );
    const totalGross = perRestaurantsData.reduce(
      (acc: number, item: IRestaurantOrdersStats) => acc + item.orders_price,
      0
    );
    return (
      <>
        <Row>
          <StatisticsColumn
            title="Szacowana prowizja"
            value={`${fixDecimal(totalCommission)} ${currency}`}
            showAsCard
          >
            <div className="small">
              Przy założeniu prowizji {COMMISSION_PERCENT}% oraz maks. stawki{' '}
              {MAX_COMMISSION} {currency}
            </div>
            <div className="small">
              Nie uwzględnia restauracji, które mają indywidualne ustalenia oraz
              prowizji za płatności online
            </div>
          </StatisticsColumn>
          <StatisticsColumn
            title="Liczba zamówień"
            value={totalOrdersAmount}
            showAsCard
          />
          <StatisticsColumn
            title="Obrót zamówień"
            value={`${fixDecimal(totalGross)} ${currency}`}
            showAsCard
          />
        </Row>
        <Row>
          <Col xs={12}>
            <h2>Liczba zamówień per restauracja</h2>
            <RestaurantOrderValuesTable data={perRestaurantsData} />
          </Col>
        </Row>
      </>
    );
  };

  const rangeProps = !!props.defaultRange
    ? { defaultRange: props.defaultRange, fetchOnMount: true }
    : {};

  return (
    <>
      {showSelects && (
        <div>
          <SourceSelect value={source} onChange={setSource} />
          <AdminTenantSelector />
        </div>
      )}
      <WithDateRangeDataFetched allowRefetch getUrl={getUrl} {...rangeProps}>
        {(data: IRestaurantOrdersStats[]) =>
          renderPerRestaurantStatistics(data)
        }
      </WithDateRangeDataFetched>
    </>
  );
};

function SourceSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (onChange: string) => void;
}) {
  return (
    <FormGroup className="col-12 col-lg-4 p-0">
      <Label>Source (API to be implemented)</Label>
      <Input
        type="select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">all</option>
        <option value="iframe">iframe (restaurant website)</option>
        <option value="mobile_app">mobile_app (restaurant mobile app)</option>
      </Input>
    </FormGroup>
  );
}

export default OrdersPerRestaurantStats;
