import React from 'react';
import PropTypes from 'prop-types';

import APIService from 'services/api';
import { logError } from 'utils/log';
import { CenterCardSpinner, OtoSpinner } from 'app/components/common';
import OtoError from '../components/common/OtoError';

type OnErrorBehaviour = 'show-error' | 'show-component';

interface IProps<T> {
  showDataWhileLoading?: boolean;
  url: string;
  children: ([data, otherProps]: [
    T,
    {
      setData: (newData: T) => void;
      reloadData: () => void;
    }
  ]) => React.ReactNode;
  onError?: OnErrorBehaviour;
}

interface IState<T> {
  data: T | null;
  error: any;
  initialDataLoaded: boolean;
  loading: boolean;
}

export default class UrlFetcher<T> extends React.Component<
  IProps<T>,
  IState<T>
> {
  static displayName = 'UrlFetcher';

  static propTypes = {
    showDataWhileLoading: PropTypes.bool,
    url: PropTypes.string.isRequired,
    onError: PropTypes.oneOf(['show-error', 'show-component']),
  };

  static defaultProps = {
    showDataWhileLoading: false,
    onError: 'show-error',
  };

  _isMounted = false;
  abortController?: AbortController;

  state: IState<T> = {
    error: null,
    data: null,
    initialDataLoaded: false,
    loading: true,
  };

  componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.getData();
    }
  }

  getData = () => {
    this._isMounted && this.setState({ loading: true });
    this.abortController?.abort();
    this.abortController = new AbortController();
    return APIService.get(this.props.url, {
      signal: this.abortController.signal,
    })
      .then(this.setData)
      .catch((e) => {
        logError(`UrlFetcher error for GET url ${this.props.url}`, e);
        this._isMounted && this.setState({ loading: false, error: e });
      });
  };

  setData = (newData: T) =>
    this._isMounted &&
    this.setState({
      data: newData,
      error: null,
      initialDataLoaded: true,
      loading: false,
    });

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController?.abort();
  }

  render() {
    const { onError, showDataWhileLoading } = this.props;
    const { error, initialDataLoaded, loading } = this.state;

    const canSkipLoader = showDataWhileLoading && initialDataLoaded;

    if (loading && !canSkipLoader) {
      return <CenterCardSpinner fullHeight={false} />;
    }
    if (error && onError === 'show-error') {
      return <OtoError type={'fetch-data'} onRefetchClick={this.getData} />;
    }
    const otherProps = {
      setData: this.setData,
      reloadData: this.getData,
    };
    return (
      <>
        {canSkipLoader && loading && <OtoSpinner />}
        {this.props.children([this.state.data as T, otherProps])}
      </>
    );
  }
}
