import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';

type StatisticsColumnProps = {
  className?: string;
  children?: React.ReactNode;
  showAsCard?: boolean;
  title: string;
  value?: string | number;
};

const StatisticsColumn: React.FC<StatisticsColumnProps> = (props) => (
  <Col xs={12} sm={4} className={props.className}>
    {props.showAsCard ? (
      <Card>
        <CardHeader>{props.title}</CardHeader>
        <CardBody>
          <div className="h2">{props.value}</div>
          {props.children}
        </CardBody>
      </Card>
    ) : (
      <>
        <h5>{props.title}</h5>
        <div className="h2">{props.value}</div>
        {props.children}
      </>
    )}
  </Col>
);

StatisticsColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  showAsCard: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

export default StatisticsColumn;
