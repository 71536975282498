import React from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';

import OtoSpinner from 'app/components/common/OtoSpinner';
import APIService from 'services/api';
import { logError } from 'utils/log';
import ViewOrderModal from 'app/views/ViewOrderModal';
import { fixDecimal } from 'utils/general';
import StatisticsColumn from 'app/components/statistics/StatisticsColumn';
import Order from 'utils/orders';
import { getArrayAvg } from 'utils/array';
import { DEFAULT_PROMO_CODE } from './PromoCodesPage';
import { ModalTypes } from 'config';
import { getPromoCodeLabel } from 'app/components/orders/PromoCodeText';
import { sendSms } from 'store/sms';
import withSetModal, { TSetModalFunction } from '../containers/WithSetModal';
import OrderFeedbackList from 'app/components/order-feedback/OrderFeedbackList';
import { OrderSource } from 'app/components/orders/order-parts/OrderSource';
import type { IOrderFeedback, IPromoCode, NormalizedRestaurant } from 'types';
import type { IAppState } from 'store/main';
import { selectRestaurant } from 'store/restaurant';

const isValidFeedback = (feedback) => {
  if (feedback.food_rating === 0) {
    return false;
  }
  return feedback.delivery_rating !== 0 || Order.isSelfCollect(feedback.order);
};

const getFeedbackAverage = memoizeOne((feedbacks = []) => {
  const validFeedbacks = feedbacks.filter(isValidFeedback);
  return {
    foodRating: fixDecimal(
      getArrayAvg(validFeedbacks.map((f) => f.food_rating))
    ),
    deliveryRating: fixDecimal(
      getArrayAvg(validFeedbacks.map((f) => f.delivery_rating))
    ),
  };
});

type OrderFeedbackPageProps = PropsFromRedux & {
  setModal: TSetModalFunction;
};

type OrderFeedbackPageState = {
  feedbacks?: IOrderFeedback[];
  ordersWithFeedbacks: any[];
  loading?: boolean;
  error?: boolean;
  selectedOrder: any;
};

class OrderFeedbackPage extends React.Component<
  OrderFeedbackPageProps,
  OrderFeedbackPageState
> {
  state: OrderFeedbackPageState = {
    ordersWithFeedbacks: [],
    selectedOrder: null,
  };

  componentDidMount() {
    this.loadFeedbacks();
  }

  loadFeedbacks = () => {
    // TODO fix potential memory leak on unmonted component
    const url = `/restaurants/${this.props.restaurant.id}/orders/feedbacks?include=order|order.payment&sort=-id`;
    this.setState({ loading: true });
    APIService.get(url)
      .then((feedbacks: IOrderFeedback[]) =>
        this.setState({
          loading: false,
          feedbacks,
        })
      )
      .catch((e) => {
        this.setState({ loading: false });
        toast.error('Wystąpił błąd pobierania opinii');
        logError('Order feedback GET error', e);
      });
  };

  showOrderDetails = (selectedOrder) => this.setState({ selectedOrder });
  hideOrderDetailsModal = () => this.setState({ selectedOrder: null });

  renderOrderSource = (order) => <OrderSource order={order} />;

  render() {
    const { error, loading } = this.state;
    return (
      <Card>
        <CardHeader>Opinie klientów na temat zamówień</CardHeader>
        <CardBody>
          {this.renderFeedbackSummary()}
          {error && (
            <div>
              <Alert color="danger">Wystąpił problem pobierania opinii</Alert>
              <Button color="primary" onClick={this.loadFeedbacks}>
                Pobierz ponownie
              </Button>
            </div>
          )}
          {loading && <OtoSpinner center className="mb-2" />}
          {this.state.feedbacks && (
            <OrderFeedbackList
              feedbacks={this.state.feedbacks}
              showOrderDetails={this.showOrderDetails}
              giveNextOrderPromocode={this.giveNextOrderPromocode}
              restaurant={this.props.restaurant}
            />
          )}
          {this.state.selectedOrder && (
            <ViewOrderModal
              order={this.state.selectedOrder}
              onClose={this.hideOrderDetailsModal}
              renderOrderSource={this.renderOrderSource}
              restaurant={this.props.restaurant}
              updatingOrders={this.props.updatingOrders}
              setModal={this.props.setModal}
            />
          )}
        </CardBody>
      </Card>
    );
  }

  renderFeedbackSummary() {
    const { feedbacks } = this.state;
    if (!feedbacks || !feedbacks.length) {
      return null;
    }
    const { foodRating, deliveryRating } = getFeedbackAverage(feedbacks);
    return (
      <Row className="m-b-md align-items-end">
        <StatisticsColumn title="Liczba ocen" value={feedbacks.length} />
        <StatisticsColumn
          title="Średnia ocena za jedzenie"
          value={foodRating}
        />
        <StatisticsColumn
          title="Średnia ocena za dostawę"
          value={deliveryRating}
        />
      </Row>
    );
  }

  giveNextOrderPromocode = (feedback: IOrderFeedback) => {
    this.props.setModal(
      {
        confirm: (promoCode: IPromoCode) => this.sendSmsWithPromoCode(feedback, promoCode),
        modalSpecificProps: {
          promoCode: {
            ...DEFAULT_PROMO_CODE,
            code: `RABAT-${feedback.order_id}`,
            description: `Kod rabatowy za negatywną opinię za zamówienie #${feedback.order_id}`,
          } as IPromoCode, // @FIXME create IPromoCodeDTO or some other type for unsaved code
        },
      },
      ModalTypes.PROMO_CODE_MODAL
    );
  };

  sendSmsWithPromoCode = (feedback: IOrderFeedback, promoCode: IPromoCode) => {
    const smsText = `Przykro nam, że coś poszło nie tak z Twoim ostatnim zamówieniem. Z kodem rabatowym ${
      promoCode.code
    } otrzymasz ${getPromoCodeLabel(promoCode)}.`;
    const customers = [feedback.order!.customer.phone];
    return this.props.setModal(
      {
        confirm: (message, closeModal) =>
          this.props.sendSms({
            message,
            restaurant: this.props.restaurant,
            customers: customers,
            onSuccess: closeModal,
            onError: () => {
              toast.error('Wystąpił błąd wysyłania SMS');
            },
          }),
        modalSpecificProps: {
          customers: new Set(customers),
          smsText,
        },
      },
      ModalTypes.SEND_SMS_MODAL
    );
  };
}

const mapStateToProps = (state: IAppState) => ({
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
  updatingOrders: state.orders.updatingOrders,
});

const connector = connect(mapStateToProps, { sendSms });

const ConnectedOrderFeedbackPage = compose(
  connector,
  withSetModal
)(OrderFeedbackPage);

type PropsFromRedux = ConnectedProps<typeof connector>;

// @FIXME TS refactor - types for class components to be fixed later
// @ts-expect-error
ConnectedOrderFeedbackPage.url = '/order-feedback';
// @ts-expect-error
ConnectedOrderFeedbackPage.navName = 'Opinie';

export default ConnectedOrderFeedbackPage;
