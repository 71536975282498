import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import { ITable, TTableSuggestionWithPeopleAmount, TTableWithPeopleAmount } from 'types';
import { usePrevious } from 'hooks/usePrevious';
import { ModalTypes } from 'config';
import { addPeopleAmountToTableTitle } from 'utils/reservation';
import Shapes from 'shapes/main';

interface IProps {
  allTables?: ITable[] | null;
  isEntirePlace: boolean;
  setModal: TSetModalFunction;
  onTablesSelected: (tables: (TTableWithPeopleAmount | TTableSuggestionWithPeopleAmount)[] | null) => void;
  selectedTables: ITable[];
}

const EntirePlaceReservationHelper: React.FC<IProps> = (props) => {
  const { allTables, setModal, onTablesSelected, isEntirePlace, selectedTables } =
    props;

  const previousTables = usePrevious(selectedTables);
  const previousIsEntirePlace = usePrevious(isEntirePlace);

  const hasBecomeEntirePlaceAndHasTables =
    !previousIsEntirePlace && isEntirePlace && selectedTables.length > 0;

  const wasEntirePlaceAndTableAppeared =
    previousIsEntirePlace &&
    isEntirePlace &&
    previousTables?.length === 0 &&
    selectedTables.length > 0;

  useEffect(() => {
    if (hasBecomeEntirePlaceAndHasTables || wasEntirePlaceAndTableAppeared) {
      const placeName = selectedTables[0].place?.name;
      const placeNamePart = placeName ? ` ${placeName}` : '';
      const allTablesFromSamePlace = allTables?.filter(
        (table) => table.place_id === selectedTables[0].place_id
      );
      setModal(
        {
          title: 'Rezerwacja sali na wyłączność',
          text: (
            <>
              <p>
                Cała sala zostanie zablokowana nawet jeżeli zaznaczysz tylko
                jeden stolik w rezerwacji na wyłączność, jednak zalecamy
                zaznaczenie wszystkich stolików w danej sali w celu lepszej
                widoczności rezerwacji na wyłączność w kalendarzu.
              </p>
              <p>
                Czy chcesz zaznaczyć wszystkie stoliki ({allTablesFromSamePlace?.length} szt.) w sali{placeNamePart}?
              </p>
            </>
          ),
          confirm: () => {
            const mappedTables = allTablesFromSamePlace?.map((table) =>
            addPeopleAmountToTableTitle(table, {
              addPlace: false,
              allTables: allTables || [],
            })
          )
            onTablesSelected(mappedTables || null);
          },
          confirmColor: 'success',
          confirmText: 'Tak, zaznacz wszystkie',
          cancelText: 'Nie, pozostaw tylko zaznaczone',
        },
        ModalTypes.CONFIRM
      );
    }
  }, [
    allTables,
    hasBecomeEntirePlaceAndHasTables,
    wasEntirePlaceAndTableAppeared,
    onTablesSelected,
    setModal,
    selectedTables,
    isEntirePlace,
  ]);
  return <div></div>;
};

EntirePlaceReservationHelper.propTypes = {
  allTables: PropTypes.arrayOf(Shapes.restaurantTableShape.isRequired)
    .isRequired,
  isEntirePlace: PropTypes.bool.isRequired,
  onTablesSelected: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  selectedTables: PropTypes.arrayOf(Shapes.restaurantTableShape.isRequired)
    .isRequired,
};

export default withSetModal(EntirePlaceReservationHelper);
