import memoizeOne from 'memoize-one';

import { possibleReservationDurations } from 'config';
import type {
  ISavedReservation,
  ReservationConfig,
  ITable,
  TTableSuggestion,
  TTableSuggestionWithPeopleAmount,
  TTableWithPeopleAmount,
  TNormalizedReservation,
} from 'types';
import { ReservationSource, ReservationStatus } from 'types';
import { isToday, parseReservedOn } from './date-time';
import { toDateInputValue } from './date-time';

export const DECLINE_REASON_BY_CLIENT = 2;
export const DECLINE_REASON_NO_SHOW = 3;

export const COUNT_STATUS = 'count-status';

export const isDeclined = (res: ISavedReservation) =>
  res.status === ReservationStatus.declined;
export const isDeclinedByClient = (res: ISavedReservation) =>
  isDeclined(res) && res.declined_reason_id === DECLINE_REASON_BY_CLIENT;
export const isConfirmed = (res: ISavedReservation) =>
  res.status === ReservationStatus.confirmed;

export const isReservedAutomatically = (res: ISavedReservation) => {
  if (res.source) {
    return ![ReservationSource.dashboard, ReservationSource.integration].includes(res.source);
  }
  return true;
};

export const isCountStatus = (res: any) => res.type === COUNT_STATUS;
export const isNote = (res: ISavedReservation) => res.guests_number === 0;
export const isReservedForToday = (res: ISavedReservation) =>
  isToday(new Date(res.reserved_on));

export function countReservationPlacesByDay(
  reservations: ISavedReservation[],
  options: { addNonVaccinated?: boolean } = { addNonVaccinated: false }
) {
  if (!reservations || !reservations.reduce) {
    return [];
  }
  const { addNonVaccinated = false } = options;
  return reservations.reduce((acc, res) => {
    if (!isConfirmed(res)) {
      return acc;
    }
    const dateKey = res.reserved_on.split(' ')[0];
    if (!dateKey) {
      return acc;
    }
    if (acc[dateKey]) {
      acc[dateKey].guestsCount += parseInt(res.guests_number.toString(), 10);
      if (addNonVaccinated) {
        acc[dateKey].nonVaccinatedCount += parseInt(
          res.extras?.non_vaccinated_amount?.toString() || '0',
          10
        );
      }
    } else {
      acc[dateKey] = {
        guestsCount: parseInt(res.guests_number.toString(), 10),
      };
      if (addNonVaccinated) {
        acc[dateKey].nonVaccinatedCount = parseInt(
          res.extras?.non_vaccinated_amount?.toString() || '0',
          10
        );
      }
    }
    return acc;
  }, {});
}

export function getNewlyCreatedReservations(oldReservations, newReservations) {
  if (oldReservations.length) {
    const oldIds = oldReservations
      .filter(isConfirmed)
      .filter(isReservedForToday)
      .map((res) => res.id);
    return newReservations
      .filter(isConfirmed)
      .filter(isReservedForToday)
      .filter(isReservedAutomatically)
      .filter((res) => !oldIds.includes(res.id));
  }
  return [];
}

export function getRecentlyDeclinedReservations(
  oldReservations: ISavedReservation[],
  newReservations: ISavedReservation[]
) {
  if (oldReservations.length) {
    const oldDeclinedIds = oldReservations
      .filter(isDeclinedByClient)
      .filter(isReservedForToday)
      .map((res) => res.id);
    const newDeclined = newReservations
      .filter(isDeclinedByClient)
      .filter(isReservedForToday);
    return newDeclined.filter((res) => !oldDeclinedIds.includes(res.id));
  }
  return [];
}

export function addHours(str: string, hrs: number) {
  let hourPart = Math.min(parseInt(str.slice(11, 13), 10) + hrs, 24);
  if (hourPart === 24) {
    return `${str.slice(0, 11)}23${str.slice(13, 14)}59${str.slice(16)}`;
  }
  const hasHalfHours = !!(hrs % 1);
  const hours = str.slice(14, 16);
  const minutePart = hasHalfHours
    ? parseInt(hours, 10) + (hrs % 1) * 60
    : hours;
  if (hasHalfHours) {
    hourPart = parseInt(hourPart.toString(), 10);
  }
  return `${str.slice(0, 11)}${hourPart}${str.slice(
    13,
    14
  )}${minutePart}${str.slice(16)}`;
}

export function generateReservedOn({ visitDate, visitTime }) {
  const date =
    visitDate instanceof Date ? toDateInputValue(visitDate) : visitDate;
  return date + ' ' + visitTime + ':00';
}

export function convertSavedReservationToNormalized<
  T extends {
    reserved_on: string;
    duration?: number | null;
  }
>(reservation: T, config: ReservationConfig): TNormalizedReservation<T> {
  const { duration, reserved_on, ...otherProps } = reservation;
  const { visitDate, visitTime } = parseReservedOn(reserved_on);
  return {
    ...otherProps,
    duration: duration || getDefaultDuration(config),
    visitDate,
    visitTime,
  };
}

export function getDefaultDuration(config: ReservationConfig): number {
  const desiredDefault = config.reservation_duration
    ? config.reservation_duration * 60
    : null;
  const defaultDuration: number =
    desiredDefault &&
    config.reservation_duration_options?.includes(desiredDefault)
      ? desiredDefault
      : config.reservation_duration_options?.[0] || 120;
  return defaultDuration;
}

export function getKidsNumber(reservation: ISavedReservation) {
  return (reservation.extras && reservation.extras.kids_number) || 0;
}

type TTableTitleOptions = {
  addPlace: boolean;
  allTables: ITable[];
};

export function addPeopleAmountToTableTitle(
  table: ITable | TTableSuggestion,
  options: TTableTitleOptions
): TTableWithPeopleAmount | TTableSuggestionWithPeopleAmount {
  return {
    ...table,
    nameWithPeopleAmount: formatTableTitle(table, options),
  };
}

export const getTablesDict = memoizeOne(
  (tables: ITable[]): Record<number, ITable> => {
    return tables.reduce((acc, table) => {
      return {
        ...acc,
        [table.id]: table,
      };
    }, {});
  }
);

export function formatTableTitle(
  table: ITable | TTableSuggestion,
  options: TTableTitleOptions
) {
  const { addPlace, allTables } = options;
  const tablesDict = getTablesDict(allTables);

  const placeSuffix = addPlace && table.place ? ` (${table.place.name})` : '';
  let possibleConnections = '';
  if (
    tablesDict &&
    'connect' in table &&
    table.connect &&
    table.linked_to.length > 0
  ) {
    const connectedTablesNames = table.linked_to
      .map((id) => tablesDict[id]?.name)
      .join(', ');
    const connectedTablesMinSize = table.linked_to.reduce(
      (acc, id) => acc + (tablesDict[id]?.min_people || 0),
      0
    );
    const connectedTablesMaxSize = table.linked_to.reduce(
      (acc, id) => acc + (tablesDict[id]?.max_people || 0),
      0
    );

    const totalMinSize = table.min_people + connectedTablesMinSize;
    const totalMaxSize = table.max_people + connectedTablesMaxSize;

    possibleConnections = ` (w połączeniu z ${connectedTablesNames} = ${totalMinSize}-${totalMaxSize} os.)`;
  }

  return `${table.name} (${table.min_people}-${table.max_people} os.)${placeSuffix}${possibleConnections}`;
}

export function isReservationsConfigFinished(config: ReservationConfig) {
  if (!config || !config.reservation_duration) {
    return false;
  }
  return true;
}

export function hasFlexibleTables(config: ReservationConfig) {
  return config.flexible_tables;
}

export function hasKids(config?: ReservationConfig | null): boolean {
  return config?.has_kids || false;
}

export function hasAssignee(config: ReservationConfig) {
  return config.has_assignee && config.assignee_list;
}

export function canSelectDuration(config: ReservationConfig) {
  return (
    !!config.reservation_duration_options &&
    config.reservation_duration_options.length > 1
  );
}

export function getDurationOptions(config: ReservationConfig) {
  const availableDurations = config.reservation_duration_options || [];
  return possibleReservationDurations.filter((anOpt) =>
    availableDurations.includes(anOpt.value)
  );
}
