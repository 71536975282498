import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { LANGS } from 'config';

const flagIconStyle = {
  border: '1px solid black',
  marginRight: '1rem',
  width: 24,
};

interface LanguagePickerProps {
  className?: string;
  lang: string;
  onChange: (lng: string) => void;
}

export const LanguagePicker = ({ className, lang, onChange }: LanguagePickerProps) => {
  return (
    <UncontrolledDropdown className={className || ''}>
      <DropdownToggle caret>
        <img
          src={LANGS.icons[lang]}
          alt={`${lang} flag icon`}
          style={flagIconStyle}
        />
        <span>{lang}</span>
      </DropdownToggle>
      <DropdownMenu>
        {LANGS.supported.map((lng) => (
          <DropdownItem key={lng} onClick={() => onChange(lng)}>
            {lng.toUpperCase()}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default LanguagePicker;
