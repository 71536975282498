import React, { useMemo } from 'react';

import DateTimeHelper from 'utils/date-time';
import { fixDecimal } from 'utils/general';
import StatisticsRangeHeader from 'app/components/admin-statistics/StatisticsRangeHeader';
import SimpleTableWithSorting, {
  generateColumn,
} from 'app/components/common/SimpleTableWithSorting';
import WithDateRangeDataFetched from '../../../containers/WithDateRangeDataFetched';
import { Tenants } from 'store/tenants';

interface IConfirmationTimeStats {
  name: string;
  orders_amount: number;
  avg_average: number;
}

interface IProps {
  tenant: Tenants;
}

const ConfirmationTimesStatisticsPage: React.FC<IProps> = (props: IProps) => {
  const { tenant } = props;

  const columns = useMemo(() => {
    const allColumns = [
      generateColumn('name', { customHeader: 'Nazwa restauracji' }),
      generateColumn('orders_amount', { customHeader: 'Liczba zamówień' }),
      generateColumn('avg_average', {
        customHeader: 'Średni czas na potwierdzenie',
        customCellFormatter: (props) => (
          <div>{fixDecimal(props.value)} min.</div>
        ),
      }),
    ];
    return allColumns;
  }, []);

  const formatUrl = (start: Date, end: Date, tenant: string) =>
    `/statistics/confirmation-times?from=${DateTimeHelper.toDateInputValue(
      start
    )}&to=${DateTimeHelper.toDateInputValue(end)}&tenant=${tenant}`;

  return (
    <div>
      <WithDateRangeDataFetched<IConfirmationTimeStats[]>
        hideCalendarAfterFetch
        fetchOnMount={false}
        defaultRange="lastWeek"
        getUrl={(dateFrom: Date, dateTo: Date) =>
          formatUrl(dateFrom, dateTo, tenant)
        }
      >
        {(stats, { editRanges, ranges }) => (
          <>
            <StatisticsRangeHeader
              isEmpty={!stats}
              onEdit={editRanges}
              ranges={ranges}
            />
            {!!stats && (
              <SimpleTableWithSorting data={stats} columns={columns} />
            )}
          </>
        )}
      </WithDateRangeDataFetched>
    </div>
  );
};

export default ConfirmationTimesStatisticsPage;
