import { Tenants } from 'enums';
import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

interface TenantSelectorProps {
  className?: string;
  label?: string;
  value: string;
  showLesznoJe: boolean;
  showOstrowJe: boolean;
  onChange: (newValue: string) => void;
}

const TenantSelector: React.FC<TenantSelectorProps> = (props) => {
  const {
    className = 'col-12 col-lg-4 p-0 mw-250',
    label = 'Firma (Tenant)',
    onChange,
    showLesznoJe,
    showOstrowJe,
    value,
  } = props;
  return (
    <FormGroup className={className}>
      <Label>{label}</Label>
      <Input
        type="select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">wszystkie</option>
        {showLesznoJe && <option value={Tenants.LesznoJe}>Leszno Je!</option>}
        {showOstrowJe && <option value={Tenants.OstrowJe}>Ostrów Je!</option>}
        <option value={Tenants.LUBJE}>LUB*JE</option>
        <option value={Tenants.OtoStolik}>
          OtoStolik (strona lub aplikacja restauracji)
        </option>
      </Input>
    </FormGroup>
  );
};

export default TenantSelector;
