import React from 'react';
import { format } from 'date-fns';
import { Form } from 'reactstrap';

import {
  CollapsibleCard,
  IconWithTooltip,
  OtoFormGroup,
  SubmitButtonWithLoader,
} from 'app/components/common';
import NavBadge from 'app/theme-components/SidebarNav/components/NavBadge';
import type { TCustomerNote } from 'types';

export type GuestCardProps = {
  className?: string;
  customerNotes: TCustomerNote[];
  onNoteAdd?: (note: string) => Promise<boolean>;
  isLoadingCustomerNotes?: boolean;
  isNoteBeingCreated?: boolean;
};

export const GuestCard: React.FC<GuestCardProps> = ({
  className,
  customerNotes,
  onNoteAdd,
  isLoadingCustomerNotes,
  isNoteBeingCreated,
}) => {
  const regularGrey = 'rgb(205, 205, 205)';
  const darkGrey = 'rgb(110, 110, 110)';

  const [noteValue, setNoteValue] = React.useState<string>('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!onNoteAdd) {
      return;
    }
    const isSuccess = await onNoteAdd(noteValue);
    if (isSuccess) {
      setNoteValue('');
    }
  };

  return (
    <CollapsibleCard
      className={className}
      buttonClassName={'p-2'}
      openByDefault={true}
      title={
        <>
          <span>Karta Gościa</span>
          <NavBadge color={'primary'} className={'mx-2'} text={'Nowość'} />
          <IconWithTooltip
            id={'guest-card-explanation'}
            text={
              'Tutaj możesz zapisać informacje na temat preferencji Gościa, takich jak ulubione miejsce lub dania, alergeny i t.d.'
            }
          />
        </>
      }
    >
      {!isLoadingCustomerNotes &&
        customerNotes?.map((note, index) => (
          <div key={note.id}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: darkGrey,
                fontSize: '.9em',
              }}
            >
              <span>Notatka #{customerNotes.length - index}</span>
              <span>
                {format(new Date(note.created_at), 'HH:mm dd.MM.yyyy')}
              </span>
            </div>
            <p style={{ margin: 0, padding: 0 }}>{note.note}</p>
            <hr style={{ borderColor: regularGrey, margin: '5px 0' }} />
          </div>
        ))}
      {!customerNotes?.length && !isLoadingCustomerNotes && <p>Brak notatek</p>}
      {onNoteAdd && (
        <CollapsibleCard
          className={'mt-4'}
          buttonClassName={'p-1'}
          openByDefault={false}
          title={'Dodaj notatkę'}
        >
          <Form onSubmit={handleSubmit}>
            <OtoFormGroup
              label={'Tekst notatki'}
              name={'customer-note-text'}
              onChange={(e) => setNoteValue(e.target.value)}
              value={noteValue}
              type={'textarea'}
            />
            <SubmitButtonWithLoader
              color={'primary'}
              disabled={!noteValue}
              label={'Zapisz notatkę'}
              loading={isNoteBeingCreated || false}
            />
          </Form>
        </CollapsibleCard>
      )}
    </CollapsibleCard>
  );
};

export default GuestCard;
