import React from 'react';
import PropTypes from 'prop-types';

interface GroupedFlowProps {
  emptyText: string;
  fieldKey: string;
  data: any[];
  flow: any; // TODO use generic, e.g. Record<string, { label: string }>; and fix propTypes
  renderItem: Function;
  rowClassName?: string;
  sortItemsInGroup?: Function;
}

export const GroupedFlow: React.FC<GroupedFlowProps> = (props) => {
  const {
    emptyText,
    fieldKey,
    data,
    flow,
    renderItem,
    rowClassName,
    sortItemsInGroup,
  } = props;
  const groupedData = data.reduce((acc, item) => {
    acc[item[fieldKey]] = [...(acc[item[fieldKey]] || []), item];
    return acc;
  }, {});

  const groups = Object.entries(flow).map(([groupedByKey, meta]: any) => {
    const amount =
      (groupedData[groupedByKey] && groupedData[groupedByKey].length) || 0;

    const sectionHeader = (
      <div className="h4 text-primary text-left w-100">
        {meta.label} ({amount})
      </div>
    );

    const items = sortItemsInGroup
      ? sortItemsInGroup(groupedData[groupedByKey] || [], groupedByKey)
      : groupedData[groupedByKey] || [];

    return (
      <div
        className={`row mx-0 w-100 mb-3 text-left ${rowClassName}`}
        key={meta.label}
      >
        {sectionHeader}
        {amount > 0 ? items.map(renderItem) : <div>{emptyText}</div>}
      </div>
    );
  });
  
  return <>
    {groups}
  </>
};

GroupedFlow.propTypes = {
  emptyText: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  flow: PropTypes.object.isRequired,
  renderItem: PropTypes.func.isRequired,
  rowClassName: PropTypes.string,
  sortItemsInGroup: PropTypes.func,
};

GroupedFlow.defaultProps = {
  rowClassName: '',
};

GroupedFlow.displayName = 'GroupedFlow';

export default GroupedFlow;
