import React from 'react';
import { NormalizedRestaurant } from 'types/restaurants';
import { User } from 'types/user';
import UrlFetcher from '../containers/UrlFetcher';
import CollapsibleCard from 'app/components/common/CollapsibleCard';
import ViewAddDriver from 'app/components/users/ViewAddDriver';
import DriversList from 'app/components/users/DriversList';
import { getArrayWithoutObject } from 'utils/array';
import { selectIsRootAdmin } from 'store/app';
import { useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';

const RestaurantDriversPage: React.FC<{}> = () => {
  const isRootAdmin = useAppSelector(selectIsRootAdmin)
  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant

  return (
    <div>
      <UrlFetcher<User[]> url={`/restaurants/${restaurant.id}/drivers`}>
        {([drivers, urlFetcherProps]) => (
          <>
            <CollapsibleCard title="Dodaj kierowcę">
              <ViewAddDriver
                restaurant={restaurant}
                onSuccess={(newDriver: User) =>
                  urlFetcherProps.setData([...drivers, newDriver])
                }
              />
            </CollapsibleCard>
            <DriversList
              canEditDrivers={isRootAdmin}
              drivers={drivers}
              onDelete={(deletedDriver: User) =>
                urlFetcherProps.setData(
                  getArrayWithoutObject(drivers, deletedDriver)
                )
              }
              restaurant={restaurant}
            />
          </>
        )}
      </UrlFetcher>
    </div>
  );
};

export default Object.assign(RestaurantDriversPage, {
  url: '/drivers',
  navName: 'Kierowcy',
});
