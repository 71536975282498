import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { hasCustomRealizationTime } from 'utils/orders';

export const OrderRealizationTime = ({ className = '', order }) => {
  return hasCustomRealizationTime(order) ? (
    <span className={`text-danger ${className}`}>
      {i18next.t('order.realization-time')}: {order.customer.deliveryAt}
    </span>
  ) : null;
};

OrderRealizationTime.propTypes = {
  className: PropTypes.string,
  order: Shapes.orderShape.isRequired,
};

export default OrderRealizationTime;
