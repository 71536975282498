import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Archive, Clock } from 'react-feather';

import Shapes from 'shapes/main';
import { displayTime } from 'utils/date-time';
import { IDelivery } from 'types/delivery';
import Delivery from 'utils/deliveries';
import { YesNoIcons } from '../../icons/IconSets';

import './DeliveryStatus.scss';

interface IDeliveryStatusProps {
  className?: string;
  children?: React.ReactNode;
  delivery: IDelivery;
  showId: boolean;
}

const DeliveryStatus = (props: IDeliveryStatusProps) => {
  const { className = '', delivery, showId } = props;
  return (
    <div className={`d-flex flex-row align-vertical ${className}`}>
      <div className="center-children mr-2 delivery-status">
        {Delivery.isNew(delivery) && <Clock />}
        {(Delivery.isAssigned(delivery) || Delivery.isArriving(delivery)) && (
          <>{Delivery.getDriverTimeToArrive(delivery)} min.</>
        )}
        {Delivery.isArrived(delivery) && <Archive />}
        {Delivery.isOnTheWay(delivery) && (
          <img src="/car.svg" alt="car icon" className="w-40" />
        )}
        {Delivery.isDelivered(delivery) && YesNoIcons.YES}
        {Delivery.isDeclined(delivery) && <span className="fs-20">A</span>}
      </div>
      <div className="d-flex flex-column align-items-start ml-3">
        {showId && (
          <div>
            {i18next.t('delivery.title')} #{delivery.id} (
            {displayTime(delivery.created_at)})
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};

DeliveryStatus.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  delivery: Shapes.deliveryShape.isRequired,
  showId: PropTypes.bool.isRequired,
};

DeliveryStatus.displayName = 'DeliveryStatus';

export default DeliveryStatus;
