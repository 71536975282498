import React from 'react';
import { Spinner, SpinnerProps } from 'reactstrap';

type OtoSpinnerProps = Omit<SpinnerProps, 'size'> & {
  center?: boolean;
  size?: 'sm';
};

export const OtoSpinner: React.FC<OtoSpinnerProps> = ({
  center = false,
  color = 'primary',
  ...rest
}) => {
  const spinner = <Spinner color={color} {...rest} />;
  if (center) {
    return <div className="w-100">{spinner}</div>;
  }
  return spinner;
};

export default OtoSpinner;
