import React, { useEffect } from 'react';
import { Col, Form, FormGroup, Label, Input, Row } from 'reactstrap';
import i18next from 'i18next';

import {
  AddReservationFormValues,
  ITableWithPlace,
  NormalizedRestaurant,
  ReservationConfig,
  TTableWithPeopleAmount,
} from 'types';
import { hasAssignee } from 'utils/reservation';
import CustomerSuggestByPhone from 'app/components/reservations/CustomerSuggestByPhone';
import OtoButtons from 'app/components/common/OtoButtons';
import SelectedTables from 'app/components/reservations/SelectedTables';

interface IProps {
  contentAttachments?: React.ReactNode;
  contentFooter?: React.ReactNode;
  config: ReservationConfig;
  goBack: () => void;
  handleCustomerSelected: (customerData: {
    customer_name: string;
    phone_number: string;
  }) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  restaurant: NormalizedRestaurant;
  selectedTables: TTableWithPeopleAmount[];
  values: AddReservationFormValues;
}

const formId = 'add-reservation-form-step-2';

const AddReservationFormStep2: React.FC<IProps> = (props) => {
  const {
    config,
    contentFooter,
    goBack,
    handleCustomerSelected,
    handleChange,
    handleSubmit,
    restaurant,
    selectedTables,
    values,
  } = props;

  const { assignee_list } = config;

  useEffect(() => {
    document.querySelector(`#${formId}`)?.scrollIntoView();
  }, []);

  return (
    <Form className="bt-grey-1 mt-3 pt-3" onSubmit={handleSubmit} id={formId}>
      <h3>Krok 2: Wpisz dane gości oraz pozostałe szczegóły rezerwacji</h3>
      <div>
        <OtoButtons.GoBackButton
          className="align-vertical px-0"
          onClick={goBack}
        >
          Wróc do wyboru stolika
        </OtoButtons.GoBackButton>
      </div>
      <Col className="mb-2">
        <SelectedTables tables={selectedTables as ITableWithPlace[]} />
      </Col>
      <Row className="mx-0">
        <FormGroup className="col-auto min-w-300">
          <Label for="customer_name">
            {i18next.t('reservation.guest_name')}
          </Label>
          <Input
            type="text"
            name="customer_name"
            id="customer_name"
            placeholder={i18next.t('reservation.guest_name')}
            className="mw-300"
            onChange={handleChange}
            value={values.customer_name}
          />
        </FormGroup>
        <FormGroup className="col-auto min-w-300">
          <Label for="phone_number">
            {i18next.t('reservation.phone_number')}
          </Label>
          <CustomerSuggestByPhone
            handleChange={handleChange}
            restaurant={restaurant}
            onCustomerSelected={handleCustomerSelected}
            values={values}
          />
        </FormGroup>
      </Row>
      <Row className="mx-0">
        {hasAssignee(config) && (
          <FormGroup className="col-auto">
            <Label for="assignee">{i18next.t('reservation.assignee')}</Label>
            <Input
              type="select"
              contentEditable
              suppressContentEditableWarning
              name="assignee"
              id="assignee"
              className="mw-250"
              onChange={handleChange}
              value={values.assignee}
            >
              <option key="" value="">
                {i18next.t('Unset')}
              </option>
              {assignee_list!.map((opt: string) => (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
        {/* {isAdmin && (
        <FormGroup className="mw-300">
          <Label for="source">{i18next.t('reservation.source')}</Label>
          <Input
            type="select"
            contentEditable
            suppressContentEditableWarning
            name="source"
            id="source"
            onChange={handleChange}
            value={values.source}
          >
            <option value="dashboard">dashboard (usual)</option>
            <option value="integration">integration (no SMS)</option>
          </Input>
        </FormGroup>
      )} */}
        {/* {contentAttachments} */}
        <FormGroup className="col-auto min-w-400">
          <Label for="notes_from_restaurant">{i18next.t('reservation.notes_from_restaurant')}</Label>
          <Input
            type="textarea"
            name="notes_from_restaurant"
            id="notes_from_restaurant"
            onChange={handleChange}
            value={values.notes_from_restaurant || ''}
          />
        </FormGroup>
        {contentFooter}
      </Row>
    </Form>
  );
};

export default AddReservationFormStep2;
