import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import { pl } from 'react-date-range/dist/locale';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { defaultStaticRanges } from './defaultRangesPl';

import './oto-date-range-picker.scss';

interface IProps {
  initialRanges?: any[];
  ranges?: any[];
  onChange: Function;
}
interface IState {
  uncontrolled: boolean;
  ranges?: any[];
}
export class OtoDateRangePicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = props.initialRanges
      ? {
          ranges: props.initialRanges,
          uncontrolled: true,
        }
      // @TODO FIXME or remove uncontrolled version crashes in storybook and is not used in app
      : { uncontrolled: false };
  }

  static propTypes = {
    initialRanges: PropTypes.array,
    ranges: PropTypes.array,
    onChange: PropTypes.func.isRequired,
  };

  getRanges = () => {
    return this.state.uncontrolled ? this.state.ranges : this.props.ranges;
  };

  handleChange = (newRange) => {
    if (this.state.uncontrolled && this.state.ranges) {
      this.setState({ ranges: [newRange[this.state.ranges[0].key]] });
    }
    this.props.onChange(newRange);
  };

  render() {
    return (
      <DateRangePicker
        className="mb-2 oto-date-range-picker"
        onChange={this.handleChange}
        inputRanges={[]}
        staticRanges={defaultStaticRanges}
        direction="vertical"
        locale={pl}
        moveRangeOnFirstSelection={false}
        ranges={this.getRanges()}
        showSelectionPreview={true}
        weekStartsOn={1}
      />
    );
  }
}

export default OtoDateRangePicker;
