import React, { useMemo } from 'react';
import { format } from 'date-fns';
import i18next from 'i18next';

import type {
  ISavedReservation,
  ReservationConfig,
  TNormalizedReservation,
} from 'types';
import {
  ReactTableCellProps,
  SimpleTableWithSorting,
  generateColumn,
  getRTOriginal,
} from 'app/components/common';
import { convertSavedReservationToNormalized } from 'utils/reservation';
import { YesNoIcons } from 'app/components/icons/IconSets';

export type ReservationsTableProps = {
  bookingConfig: ReservationConfig;
  reservations: ISavedReservation[];
  showMarketingAgreements?: boolean;
  showNotes?: boolean;
};

export const ReservationsTable: React.FC<ReservationsTableProps> = ({
  bookingConfig,
  reservations,
  showMarketingAgreements = false,
  showNotes = false,
}) => {
  const normalizedReservations = useMemo(() => {
    return reservations.map((reservation) => ({
      ...convertSavedReservationToNormalized(reservation, bookingConfig),
      status: i18next.t(`reservation.statuses.${reservation.status}`),
    }));
  }, [bookingConfig, reservations]);

  const columns = useMemo(() => {
    return [
      generateColumn('id'),
      generateColumn('created_at', {
        customHeader: 'Czas utworzenia',
        customCellFormatter: (
          props: ReactTableCellProps<TNormalizedReservation<ISavedReservation>>
        ) => {
          const reservation =
            getRTOriginal<TNormalizedReservation<ISavedReservation>>(props);
          return format(new Date(reservation.created_at), 'HH:mm dd.MM.yyyy');
        },
      }),
      generateColumn('visitDate', {
        customHeader: 'Data rezerwacji',
        customCellFormatter: (
          props: ReactTableCellProps<TNormalizedReservation<ISavedReservation>>
        ) => {
          const reservation =
            getRTOriginal<TNormalizedReservation<ISavedReservation>>(props);
          return format(new Date(reservation.visitDate), 'dd.MM.yyyy');
        },
      }),
      generateColumn('visitTime', {
        customHeader: 'Godzina rezerwacji',
      }),
      generateColumn('customer_name', {
        customHeader: i18next.t('reservation.guest_name'),
      }),
      generateColumn('phone_number', {
        customHeader: i18next.t('reservation.phone_number'),
      }),
      generateColumn('guests_number', {
        customHeader: i18next.t('reservation.guests_number'),
      }),
      generateColumn('status', {
        filterType: 'select',
      }),
      ...(showNotes
        ? [
            generateColumn('notes_from_guest', {
              customHeader: i18next.t('reservation.notes_from_guest'),
            }),
            generateColumn('notes_from_restaurant', {
              customHeader: i18next.t('reservation.notes_from_restaurant'),
            }),
          ]
        : []),
      ...(showMarketingAgreements
        ? [
            generateColumn('sms_marketing', {
              customHeader: i18next.t('reservation.agreements.sms'),
              customCellFormatter: (
                props: ReactTableCellProps<ISavedReservation>
              ) => {
                const reservation = getRTOriginal<ISavedReservation>(props);
                return reservation.sms_marketing
                  ? YesNoIcons.YES
                  : YesNoIcons.NO;
              },
            }),
            generateColumn('email_marketing', {
              customHeader: i18next.t('reservation.agreements.email'),
              customCellFormatter: (
                props: ReactTableCellProps<ISavedReservation>
              ) => {
                const reservation = getRTOriginal<ISavedReservation>(props);
                return reservation.email_marketing
                  ? YesNoIcons.YES
                  : YesNoIcons.NO;
              },
            }),
          ]
        : []),
    ];
  }, [showMarketingAgreements, showNotes]);

  return (
    <>
      <SimpleTableWithSorting
        columns={columns}
        data={normalizedReservations}
        hasFilters
        pageSize={10}
      />
    </>
  );
};

export default ReservationsTable;
