import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import i18next from 'i18next';

import Rating from '../common/Rating';
import { displayTime, newSafeDate, toDateInputValue } from 'utils/date-time';
import DeclaredOrderDeliveryTime from './DeclaredOrderDeliveryTime';
import OrderWasOnTimeInfo from './OrderWasOnTimeInfo';
import { IOrder, IOrderFeedback, NormalizedRestaurant } from 'types';
import Order from 'utils/orders';
import { LOG_BAG } from 'utils/log';
import Shapes from 'shapes/main';

interface IProps {
  feedbacks: IOrderFeedback[];
  giveNextOrderPromocode: (feedback: IOrderFeedback) => void;
  showOrderDetails: (order: IOrder) => void;
  restaurant: NormalizedRestaurant;
}

const isBadFeedback = (feedback: IOrderFeedback) =>
  feedback.food_rating < 4 ||
  (!Order.isSelfCollect(feedback.order as IOrder) &&
    feedback.delivery_rating < 4);

const OrderFeedbackList: React.FC<IProps> = (props) => {
  const { feedbacks, giveNextOrderPromocode, showOrderDetails } = props;

  const feedbacksWithOrder = (feedbacks || []).filter(
    (feedback) => feedback.order
  );
  const feedbacksWithoutOrder = (feedbacks || []).filter(
    (feedback) => !feedback.order
  );

  useEffect(() => {
    feedbacksWithoutOrder.length > 0 &&
      LOG_BAG.logError(
        `There are ${feedbacksWithoutOrder.length} feedbacks without order for restaurant`
      );
  }, [feedbacksWithoutOrder.length]);

  if (!feedbacks || !feedbacks.length) {
    return null;
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>{i18next.t('order.title')}</th>
          <th>Ocena jedzenia</th>
          <th>Ocena dostawy</th>
          <th>Komentarz</th>
          <th>Godzina przyjęcia</th>
          <th>Kiedy miało być?</th>
          <th>Kiedy było wg. urzytkownika</th>
          <th>Czy przyjechało na czas?</th>
        </tr>
      </thead>
      <tbody>
        {feedbacksWithOrder.map((feedback) => {
          if (!feedback.order) {
            return null;
          }
          return (
            <tr key={feedback.id}>
              <th scope="row">
                <div>
                  #{feedback.order_id}
                  <div>
                    {toDateInputValue(
                      newSafeDate((feedback.order as IOrder).created_at)
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => showOrderDetails(feedback.order as IOrder)}
                >
                  Pokaż szczegóły
                </button>
              </th>
              <td>
                <Rating value={feedback.food_rating} />
              </td>
              <td>
                {Order.isSelfCollect(feedback.order as IOrder) ? (
                  Order.getDeliveryMethodLabel(feedback.order.delivery_type)
                ) : (
                  <Rating value={feedback.delivery_rating} />
                )}
              </td>
              <td>
                {feedback.comment}
                {isBadFeedback(feedback) && (
                  <Button
                    size="sm"
                    type="button"
                    color="success"
                    onClick={() => giveNextOrderPromocode(feedback)}
                  >
                    Daj rabat na następne zamówienie
                  </Button>
                )}
              </td>
              <td>
                {displayTime(
                  new Date((feedback.order as IOrder).confirmed_at as string)
                )}
              </td>
              <td>
                <DeclaredOrderDeliveryTime feedback={feedback} />
              </td>
              <td>
                {feedback.delivered_at_by_customer
                  ? displayTime(new Date(feedback.delivered_at_by_customer))
                  : null}
              </td>
              <td>
                <OrderWasOnTimeInfo feedback={feedback} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

OrderFeedbackList.propTypes = {
  giveNextOrderPromocode: PropTypes.func.isRequired,
  showOrderDetails: PropTypes.func.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
};

export default OrderFeedbackList;
