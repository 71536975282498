import React from 'react';
import PropTypes from 'prop-types';

import type {
  MenuItem,
  TMenuItemAvailability,
  TMenuItemDailyAvailability,
} from 'types';
import { CollapsibleCard, OtoButtons, OtoToggle, TimePicker } from '../common';
import Shapes from 'shapes/main';

interface IProps {
  product: MenuItem;
  setValues: (product: MenuItem) => void;
}

const labelsByDay = {
  mon: 'Poniedziałek',
  tue: 'Wtorek',
  wed: 'Środa',
  thu: 'Czwartek',
  fri: 'Piątek',
  sat: 'Sobota',
  sun: 'Niedziela',
};

const MenuItemAvailability: React.FC<IProps> = (props) => {
  const { product, setValues } = props;

  const { availability } = product;

  const setBasicAvailability = () => {
    const defaultAvailability: TMenuItemDailyAvailability = {
      hourFrom: 12,
      hourTo: 21,
      minuteTo: 0,
      minuteFrom: 0,
      isAvailable: true,
      isFullDay: true,
    };
    setValues({
      ...product,
      availability: {
        mon: defaultAvailability,
        tue: defaultAvailability,
        wed: defaultAvailability,
        thu: defaultAvailability,
        fri: defaultAvailability,
        sat: defaultAvailability,
        sun: defaultAvailability,
      },
    });
  };

  const clearAvailability = () => {
    setValues({
      ...product,
      availability: undefined,
    });
  };

  const updateAvailability = (newAvailability: TMenuItemAvailability) => {
    const newProduct: MenuItem = {
      ...product,
      availability: newAvailability,
    };
    setValues(newProduct);
  };

  const updateDayAvailability = (
    day: string,
    newAvailability: TMenuItemDailyAvailability
  ) => {
    updateAvailability({
      ...(product.availability as TMenuItemAvailability),
      [day]: newAvailability,
    });
  };

  return (
    <>
      <CollapsibleCard
        buttonClassName={'p-1'}
        title={
          <div className="d-flex align-vertical justify-content-start flex-wrap">
            <div className="mr-2">Dostępność:</div>
            <div className="small">{getAvailabilityLabel(product)}</div>
          </div>
        }
      >
        {!availability && (
          <>
            <div>Dostępny cały czas</div>
            <OtoButtons.AddButton
              onClick={setBasicAvailability}
              className="pl-0"
            >
              Ustaw dostępność (np. konkretne dni lub godziny)
            </OtoButtons.AddButton>
          </>
        )}
        {!!availability && (
          <>
            <div>Dostępny cały czas</div>
            <OtoButtons.AddButton onClick={clearAvailability} className="pl-0">
              Usuń szczegółową dostępność (produkt będzie dostępny cały czas)
            </OtoButtons.AddButton>
            {Object.entries(availability).map(([day, dayAvailability]) => (
              <div key={day} className="bt-1 py-2 bc-grey">
                <div>{labelsByDay[day]}</div>
                <OtoToggle
                  checked={dayAvailability.isAvailable}
                  label="Dostępny w danym dniu"
                  onChange={(e) =>
                    updateDayAvailability(day, {
                      ...dayAvailability,
                      isAvailable: e.target.checked,
                    })
                  }
                />
                {dayAvailability.isAvailable && (
                  <>
                    <OtoToggle
                      checked={dayAvailability.isFullDay}
                      label="Dostępny cały dzień"
                      onChange={(e) =>
                        updateDayAvailability(day, {
                          ...dayAvailability,
                          isFullDay: e.target.checked,
                        })
                      }
                    />
                  </>
                )}
                {!dayAvailability.isFullDay && (
                  <div
                    className="d-flex align-vertical"
                    style={{
                      gap: 10,
                    }}
                  >
                    <span>OD</span>
                    <TimePicker
                      className="d-flex align-vertical"
                      defaultHour={dayAvailability.hourFrom?.toString()}
                      defaultMin={dayAvailability.minuteFrom?.toString()}
                      onChange={(time: string) => {
                        const [hour, minute] = time.split(':') as [string, string];
                        updateDayAvailability(day, {
                          ...dayAvailability,
                          hourFrom: parseInt(hour, 0),
                          minuteFrom: parseInt(minute, 0),
                        });
                      }}
                    />
                    <span>DO</span>
                    <TimePicker
                      className="d-flex align-vertical"
                      defaultHour={dayAvailability.hourTo?.toString()}
                      defaultMin={dayAvailability.minuteTo?.toString()}
                      onChange={(time: string) => {
                        const [hour, minute] = time.split(':') as [string, string];
                        updateDayAvailability(day, {
                          ...dayAvailability,
                          hourTo: parseInt(hour, 0),
                          minuteTo: parseInt(minute, 0),
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </CollapsibleCard>
    </>
  );
};

export const getAvailabilityLabel = (menuItem: MenuItem) => {
  if (!menuItem.availability) {
    return 'Dostępny cały czas';
  }
  const { availability } = menuItem;
  const days = Object.keys(availability);

  const availableFullTimeDays = days.filter(
    (day) => availability[day].isAvailable && availability[day].isFullDay
  );
  if (availableFullTimeDays.length === 7) {
    return 'Dostępny cały czas';
  }

  const availableDays = days.filter((day) => availability[day].isAvailable);
  const availableDaysLabels = availableDays.map((day) => {
    const daily = availability[day] as TMenuItemDailyAvailability;
    if (daily.isFullDay) {
      return labelsByDay[day];
    }
    const { hourFrom, minuteFrom, hourTo, minuteTo } = daily;
    return `${labelsByDay[day]} (${hourFrom}:${minuteFrom
      ?.toString()
      .padEnd(2, '0')} - ${hourTo}:${minuteTo?.toString().padEnd(2, '0')})`;
  });
  return `Dostępny w dniach: ${availableDaysLabels.join(', ')}`;
};

export function isAvailableNow(menuItem: MenuItem): boolean {
  if (!menuItem.availability) {
    return true;
  }

  const now = new Date();
  const currentDayKey = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][
    now.getDay()
  ];
  const currentDayAvailability = menuItem.availability[currentDayKey as string];

  if (!currentDayAvailability.isAvailable) {
    return false;
  }

  if (currentDayAvailability.isFullDay) {
    return true;
  }

  const startTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    currentDayAvailability.hourFrom!,
    currentDayAvailability.minuteFrom!
  );
  const endTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    currentDayAvailability.hourTo!,
    currentDayAvailability.minuteTo!
  );

  return now > startTime && now < endTime;
}

MenuItemAvailability.propTypes = {
  product: Shapes.menuItemShape.isRequired,
  setValues: PropTypes.func.isRequired,
};

export default MenuItemAvailability;
