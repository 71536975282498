import React from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';

import { currency } from 'globals/currency';
import { fixDecimal } from 'utils/general';

type ProductStatisticsTableProps = {
  products: {
    name: string;
    amount: number;
    price: number;
  }[];
};

const ProductStatisticsTable: React.FC<ProductStatisticsTableProps> = ({
  products,
}) => {
  const data = React.useMemo(() => products, [products]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa produktu',
        accessor: 'name',
      },
      {
        Header: 'Zamówiono razy',
        accessor: 'amount',
      },
      {
        Header: 'Wygenerowano obrót',
        accessor: 'price',
        Cell: (props) => (
          <div>
            {fixDecimal(props.value)} {currency}
          </div>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

ProductStatisticsTable.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductStatisticsTable;
