import { EPaymentType } from 'enums/payments';

export const paymentOptions = [
  { name: EPaymentType.TRANSFER, isOnline: true },
  { name: EPaymentType.BLIK, isOnline: true },
  { name: EPaymentType.CARD_ONLINE, isOnline: true },
  { name: EPaymentType.GOOGLE_PAY, isOnline: true },
  { name: EPaymentType.APPLE_PAY, isOnline: true },
  { name: EPaymentType.CARD_ON_PLACE },
  { name: EPaymentType.CASH },
];
export const activePaymentOptionKeys = [
  EPaymentType.TRANSFER,
  EPaymentType.BLIK,
  EPaymentType.CARD_ONLINE,
  EPaymentType.APPLE_PAY,
  EPaymentType.GOOGLE_PAY,
  EPaymentType.CARD_ON_PLACE,
  EPaymentType.CASH,
];
