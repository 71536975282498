import React from 'react';
import PropTypes from 'prop-types';
import { Star } from 'react-feather';

export const Rating = ({ value, max = 5 }) => {
  return (
    <>
      {
      new Array(max)
        .fill(0)
        .map((_, index) => <Star key={index} fill={index < value ? 'var(--primary)' : 'transparent'} color={index < value ? 'var(--primary)' : 'grey'} />)
      }
  </>
  )
};

Rating.propTypes = {
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
}

export default Rating;
