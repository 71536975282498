import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { InputGroup, InputGroupAddon, Spinner } from 'reactstrap';
import Autosuggest from 'react-autosuggest';

import APIService from 'services/api';
import { LOG_BAG } from 'utils/log';
import { NormalizedRestaurant } from 'types/restaurants';
import Shapes from 'shapes/main';
import { ISavedReservation } from 'types/reservation';
import useDebounce from 'hooks/useDebounce';

import './CustomerSuggestByPhone.scss'

interface IProps {
  handleChange: React.ChangeEventHandler;
  onCustomerSelected: (customerData: {
    customer_name: string;
    phone_number: string;
  }) => void;
  restaurant: NormalizedRestaurant;
  values: Record<string, any>;
}

interface ISuggestion {
  first_reservation: ISavedReservation | null;
  last_reservation: ISavedReservation | null;
  reservations_count: number;
}

const CustomerSuggestByPhone: React.FC<IProps> = (props) => {
  const { handleChange, restaurant, onCustomerSelected, values } = props;

  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const [suggestionInputValue, setSuggestionInputValue] = useState('');
  const debouncedSearchTerm = useDebounce(suggestionInputValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 4) {
      const url = `/restaurants/${restaurant.id}/customers/${debouncedSearchTerm}`;
      setIsSearching(true);
      APIService.get(url)
        .then((suggestion: ISuggestion) => {
          if (suggestion.reservations_count > 0) {
            setSuggestions([suggestion]);
          } else {
            onSuggestionsClearRequested();
          }
          setIsSearching(false);
        })
        .catch((e) => {
          toast.error('Wystąpił błąd podczas wyszukiwania klienta');
          LOG_BAG.logError(
            `${CustomerSuggestByPhone.displayName} onSuggestionsFetchRequested error`,
            e
          );
          setIsSearching(false);
        });
    } else {
      onSuggestionsClearRequested();
    }
  }, [debouncedSearchTerm, restaurant.id]);

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSuggestionInputValue(value);
  };

  const onSuggestionSelected = (
    e: any,
    { suggestion }: { suggestion: ISuggestion }
  ) => {
    if (suggestion.last_reservation) {
      onCustomerSelected({
        customer_name: suggestion.last_reservation.customer_name,
        phone_number: suggestion.last_reservation.phone_number,
      });
    }
  };

  return (
    <InputGroup>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(suggestion: ISuggestion) =>
          suggestion?.last_reservation?.phone_number
        }
        renderSuggestion={renderSuggestion}
        inputProps={{
          id: 'phone_number',
          placeholder: 'np. 500123456',
          value: values.phone_number,
          onChange: handleChange,
          name: 'phone_number',
        }}
      />
      {isSearching ? (
        <InputGroupAddon className="seaching-spiner" addonType="append">
          <Spinner size="sm" color="primary" />
        </InputGroupAddon>
      ) : null}
    </InputGroup>
  );
};

function renderSuggestion(suggestion: ISuggestion) {
  const { last_reservation, first_reservation } = suggestion;
  const getOnlyDateFromDateStr = (dateStr: string): string =>
    dateStr.slice(0, 11);

  return (
    <div className="autosuggest__item">
      <span className="autosuggest__item-field">
        {last_reservation?.phone_number}
      </span>
      <span className="autosuggest__item-field">
        {last_reservation?.customer_name}
      </span>
      <span className="autosuggest__item-field">
        Pierwsza rezerwacja:{' '}
        {getOnlyDateFromDateStr(first_reservation!.created_at)}
      </span>
      <span className="autosuggest__item-field">
        Ostatnia rezerwacja:{' '}
        {getOnlyDateFromDateStr(last_reservation!.created_at)}
      </span>
      <span className="autosuggest__item-field">
        Liczba rezerwacji: {suggestion.reservations_count}
      </span>
    </div>
  );
}

CustomerSuggestByPhone.propTypes = {
  handleChange: PropTypes.func.isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
  values: PropTypes.object.isRequired,
};

CustomerSuggestByPhone.displayName = 'CustomerSuggestByPhone';

export default CustomerSuggestByPhone;
