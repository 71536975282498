import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CardBody, CardFooter, FormGroup, Label } from 'reactstrap';

import OtoSpinner from '../common/OtoSpinner';

type PageProperties = {
  primaryColor: string;
}

type PagePropertiesEditProps = {
  loading: boolean;
  onSave: (values: PageProperties) => void;
  value: PageProperties;
}

const PagePropertiesEdit: React.FC<PagePropertiesEditProps> = props => {
  const { loading, onSave, value } = props
  const [values, setValues] = useState(value || {});
  return <>
    <CardBody>
      <FormGroup>
        <Label>Kolor główny</Label>
        <input type="color" value={values.primaryColor} onChange={e => setValues({ ...values, primaryColor: e.target.value })} />
      </FormGroup>
    </CardBody>
    <CardFooter>
      {
        loading
          ? <OtoSpinner />
          : <Button type="button" onClick={() => onSave(values)}>Zapisz</Button>
      }
    </CardFooter>
  </>;
}

PagePropertiesEdit.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default PagePropertiesEdit;
