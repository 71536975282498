import DeliveriesPage from 'app/pages/DeliveriesPage';
import DriverDeliveriesPage from 'app/pages/DriverDeliveriesPage';
import RestaurantDriversPage from 'app/pages/RestaurantDriversPage';
import type { IRoute } from './types';
import { OTO_PRODUCTS, OTO_PRODUCTS_GROUPS, ROLES } from 'enums';

const deliveriesRouteWithoutRestrictions: IRoute = {
  // @fixme class component types for pages
  // @ts-expect-error
  name: DeliveriesPage.navName,
  // @ts-expect-error
  path: DeliveriesPage.url,
  component: DeliveriesPage,
};

export const deliveryCoordinatorDeliveriesListRoute: IRoute = deliveriesRouteWithoutRestrictions;

export const driverDeliveriesListRoute: IRoute = {
  // @fixme class component types for pages
  // @ts-expect-error
  name: DriverDeliveriesPage.navName,
  // @ts-expect-error
  path: DriverDeliveriesPage.url,
  component: DriverDeliveriesPage,
};

export const restaurantDeliveriesListRoute: IRoute = {
  ...deliveriesRouteWithoutRestrictions,
  availableWithProducts: OTO_PRODUCTS_GROUPS.anyDeliveriesProduct,
};

export const restaurantDriversRoute: IRoute = {
  name: RestaurantDriversPage.navName,
  path: RestaurantDriversPage.url,
  component: RestaurantDriversPage,
  availableForRoles: [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN],
  availableWithProducts: [OTO_PRODUCTS.restaurant_deliveries],
};
