import React, { useMemo } from 'react';

import DateTimeHelper from 'utils/date-time';
import { fixDecimal } from 'utils/general';
import StatisticsRangeHeader from 'app/components/admin-statistics/StatisticsRangeHeader';
import SimpleTableWithSorting, {
  generateColumn,
} from 'app/components/common/SimpleTableWithSorting';
import WithDateRangeDataFetched from '../../../containers/WithDateRangeDataFetched';
import { Tenants } from 'store/tenants';

interface IPrepareStatsItem {
  restaurant_id: number;
  restaurant_name: string;
  orders_amount: number;
  avg_prepare_time: number;
}

interface IProps {
  tenant: Tenants;
}

const PrepareTimeStatsPage = (props: IProps) => {
  const columns = useMemo(() => {
    const allColumns = [
      generateColumn('restaurant_name', { customHeader: 'Nazwa restauracji' }),
      generateColumn('restaurant_id', { customHeader: 'ID restauracji' }),
      generateColumn('orders_amount', { customHeader: 'Liczba zamówień' }),
      generateColumn('avg_prepare_time', {
        customHeader: 'Średni czas realizacji',
        customCellFormatter: (props) => (
          <div>{fixDecimal(props.value)} min.</div>
        ),
      }),
    ];
    return allColumns;
  }, []);

  const formatUrl = (start: Date, end: Date) =>
    `/statistics/order-preparation-times?from=${DateTimeHelper.toDateInputValue(
      start
    )}&to=${DateTimeHelper.toDateInputValue(end)}&tenant=${props.tenant}`;

  return (
    <div>
      <WithDateRangeDataFetched<IPrepareStatsItem[]>
        hideCalendarAfterFetch
        fetchOnMount={false}
        defaultRange="lastWeek"
        getUrl={formatUrl}
      >
        {(stats, { editRanges, ranges }) => (
          <>
            <StatisticsRangeHeader
              isEmpty={!stats}
              onEdit={editRanges}
              ranges={ranges}
            />
            {!!stats && (
              <SimpleTableWithSorting data={stats} columns={columns} />
            )}
          </>
        )}
      </WithDateRangeDataFetched>
    </div>
  );
};

export default PrepareTimeStatsPage;
