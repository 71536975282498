import React from 'react';
import { Alert } from 'reactstrap';

import { YesNoIcons } from '../icons/IconSets';
import Shapes from 'shapes/main';
import { NormalizedRestaurant, OrdersConfig } from 'types';

interface IProps {
  ordersConfig: OrdersConfig;
  restaurant: NormalizedRestaurant;
}

const OrdersConfigNotFinishedAlert: React.FC<IProps> = ({
  ordersConfig,
  restaurant,
}) => {
  return (
    <>
      <Alert color="info">
        <div>
          Brak możliwości przyjmowania zamówień online - ustawienia zamówień nie
          są skonfigurowane.
        </div>
        <div>
          Skonfiguruj ustawienia zamówień <a href="/settings/orders">tutaj</a>,
          lub skontaktuj się z nami <a href="/help">(dane kontaktowe)</a>
        </div>
      </Alert>
      <div className="mt-2">Wymagane pola:</div>
      <ul className="list-unstyled">
        {[
          {
            label: 'Ustawienia dostawy',
            present: !!ordersConfig?.delivery,
          },
          {
            label: 'Godziny przyjmowania zamówień',
            present: !!restaurant.delivery_opening_hours,
          },
          {
            label: 'Szacowany czas zamówienia z dostawą',
            present: !!ordersConfig?.deliveryTime,
          },
          {
            label: 'Strefy dostaw',
            present:
              !!ordersConfig?.delivery?.area &&
              !!ordersConfig?.delivery?.areaOptions,
          },
        ].map((field) => (
          <li key={field.label} className="mt-1">
            {field.present ? YesNoIcons.YES : YesNoIcons.NO} {field.label}
          </li>
        ))}
      </ul>
    </>
  );
};

OrdersConfigNotFinishedAlert.propTypes = {
  ordersConfig: Shapes.ordersConfigShape.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
};

export default OrdersConfigNotFinishedAlert;
