import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import Backend from 'i18next-http-backend';

import { getLsLang } from 'services/persistent';
import { LANGS } from 'config';
import { isElectron } from 'utils/general';

interface IProps {
  onLoaded: () => void;
}

const TranslationsProvider: React.FC<IProps> = (props) => {
  const { onLoaded } = props;

  const loadTranslations = async () => {
    const regularBackendOptions = {
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
        requestOptions: {
          cache: 'no-cache',
        },
      },
    };

    let electronOptions: object | null = null;

    if (isElectron()) {
      const translationsPl = await import('./translationsPl');
      const translationsEn = await import('./translationsEn');

      electronOptions = {
        resources: {
          en: {
            common: translationsEn.default,
          },
          pl: {
            common: translationsPl.default,
          },
        },
      };
    }

    const finalOptions: any = {
      lng: getLsLang() || LANGS.default,
      lngs: LANGS.supported,
      fallbackLng: LANGS.default,
      ns: ['common'],
      defaultNS: 'common',
      debug: true,
      ...(electronOptions || regularBackendOptions),
    };

    i18next
      .use(Backend)
      .init(finalOptions)
      .then(() => {
        onLoaded();
      });
  };

  useEffect(() => {
    loadTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoaded]);
  return null;
};

TranslationsProvider.propTypes = {
  onLoaded: PropTypes.func.isRequired,
};

export default TranslationsProvider;
