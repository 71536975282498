import React from 'react';
import PropTypes from 'prop-types';

import SimpleTableWithSorting, {
  generateColumn,
} from '../common/SimpleTableWithSorting';

const promoCodesColumns = [
  generateColumn('promo_code', { customHeader: 'Nazwa kodu' }),
  generateColumn('orders_count', { customHeader: 'Liczba zamówień' }),
  generateColumn('issuer', { customHeader: 'Na czyj koszt wydany' }),
  generateColumn('refund_amount', { customHeader: 'Wartość' }),
];

interface IProps {
  data: {
    promo_code: string;
    orders_count: number;
    issuer: string;
    refund_amount: number;
  }[];
}

const AdminPromoCodesStatistics = ({ data }: IProps) => {
  return (
    !!data && <SimpleTableWithSorting columns={promoCodesColumns} data={data} />
  );
};

AdminPromoCodesStatistics.propTypes = {
  data: PropTypes.array,
};

export default AdminPromoCodesStatistics;
