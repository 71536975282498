import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import { getLsFbToken, getVisitorId, setLsFbToken } from 'services/auth';
import { NormalizedRestaurant } from 'types/restaurants';
import { firebaseInstance } from 'utils/firebase-messaging';
import Shapes from 'shapes/main';
import APIService from 'services/api';
import { LOG_BAG } from 'utils/log';

interface IProps {
  onMessage: (message: object) => void;
  restaurant: NormalizedRestaurant;
  token: string;
}

const FcmMessageListener: React.FC<IProps> = (props) => {
  const { onMessage, restaurant, token: newToken } = props;

  const setFcmToken = async () => {
    const visitorId = await getVisitorId();
    APIService.put(`/users/me/devices`, {
      device_id: visitorId,
      device_os: 'web',
      push_notifications_token: newToken,
    })
      .then(() => {
        const { token: currentFcmToken } = getLsFbToken() || {};
        if (currentFcmToken !== newToken) {
          setLsFbToken(newToken);
        }
        firebaseInstance.addOnMessageListener?.(onMessage);
      })
      .catch((e) => {
        toast.error('Problem ustawienia notyfikacji');
        LOG_BAG.logError('Cannot set firebase token', e);
      });
  };

  useEffect(() => {
    setFcmToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToken, restaurant]);

  return null;
};

FcmMessageListener.displayName = 'FcmMessageListener';

FcmMessageListener.propTypes = {
  onMessage: PropTypes.func.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
  token: PropTypes.string.isRequired,
};

export default FcmMessageListener;
