import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { VIEWS, ORDER_STATUS } from 'config';

import { displayTime, newSafeDate } from 'utils/date-time';
import { truncate, whatHasChanged } from 'utils/general';
import Order from 'utils/orders';

import Shapes from 'shapes/main';

import OrderCutlery from './order-parts/OrderCutlery';
import OrderDelivery from './order-parts/OrderDelivery';
import OrderPaymentType from './order-parts/OrderPaymentType';
import OrderRealizationTime from './order-parts/OrderRealizationTime';
import OrderStatus from './order-parts/OrderStatus';
import OrderProducts from './order-parts/OrderProducts';
import OrderTotal from './order-parts/OrderTotal';
import { OtoSpinner } from '../common';
import PromoCodeText from './PromoCodeText';

import type { IOrder } from 'types/order';
import type { NormalizedRestaurant } from 'types/restaurants';

import './OrderCard.scss'
export interface OrderCardProps {
  order: IOrder;
  isLoading: boolean;
  onOrderClick: (order: IOrder) => void;
  restaurantName?: string;
  onUpdateOrderStatus: (order: IOrder, status: string) => void;
  renderCourrierDetails: (order: IOrder) => React.ReactNode;
  restaurant: NormalizedRestaurant;
  view: string;
}

export class OrderCard extends React.Component<OrderCardProps, {}> {
  static propTypes = {
    order: Shapes.orderShape,
    isLoading: PropTypes.bool.isRequired,
    onOrderClick: PropTypes.func.isRequired,
    onUpdateOrderStatus: PropTypes.func.isRequired,
    restaurant: Shapes.restaurantShape,
    restaurantName: PropTypes.string,
    view: PropTypes.oneOf([VIEWS.GRID.text, VIEWS.LIST.text]),
  };

  static displayName = 'OrderCard';

  shouldComponentUpdate(nextProps: OrderCardProps) {
    if (whatHasChanged(this.props, nextProps, ['order']).length) {
      return true;
    }
    const { order } = this.props;
    const nextOrder = nextProps.order;
    if (
      order.id !== nextOrder.id ||
      order.status !== nextOrder.status ||
      order.customer.courrierCalled !== nextOrder.customer.courrierCalled
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { order, onOrderClick, restaurant, restaurantName, view } =
      this.props;
    const isGrid = view === VIEWS.GRID.text;
    const isList = view === VIEWS.LIST.text;
    const rootClass = isGrid ? 'order-sticky-note' : 'order-list-item';
    const orderTitle = (
      <div className="order__title">
        <span>
          {i18next.t('order.title')} #{order.id}{' '}
        </span>
        {isGrid && <span>{displayTime(newSafeDate(order.created_at))}</span>}
        {restaurantName ? ` (${restaurantName})` : ''}
      </div>
    );
    return (
      <div
        className={`order ${rootClass} ${rootClass}--${order.status}`}
        onClick={() => onOrderClick(order)}
      >
        {isList && (
          <div className="order__time">
            <div className="order__time-label">godzina złożenia:</div>
            <div className="order__time-value">
              {displayTime(newSafeDate(order.created_at))}
            </div>
            {!order.customer.deliveryAt && Order.getPrepareTime(order) && (
              <>
                <div className="order__time-label mt-3">
                  {i18next.t('order.prepare-time.label')}:
                </div>
                <div className="order__time-value">
                  {Order.getPrepareTime(order)} min.
                </div>
              </>
            )}
            <OrderRealizationTime className="text-center mt-3" order={order} />
          </div>
        )}
        <div className="order__col order__col--details">
          {isGrid && orderTitle}
          <div className="order__body">
            {isGrid && <OrderRealizationTime order={order} />}
            <PromoCodeText order={order} />
            {isGrid && <OrderProducts order={order} />}
            {isGrid && <OrderTotal order={order} />}
          </div>
          {isList && <OrderTotal order={order} />}
          <OrderDelivery order={order} />
          <OrderCutlery order={order} restaurant={restaurant} />
          {isList && <OrderPaymentType order={order} />}
          {order.customer.notes && (
            <span className="text-danger mt-2">
              {truncate(order.customer.notes, 65)}
            </span>
          )}
        </div>
        <div className="order__col">
          {isList && orderTitle}
          {this.props.isLoading ? (
            <OtoSpinner className="mt-2" />
          ) : (
            this.renderQuickButtons()
          )}
        </div>
      </div>
    );
  }

  renderQuickButtons = () => {
    const { order, renderCourrierDetails } = this.props;
    if (Order.isPending(order)) {
      return (
        <>
          <OrderStatus order={order} />
          <OrderPaymentType order={order} />
          {this.renderQuickButton(
            order,
            i18next.t('order.mark-as-confirmed'),
            ORDER_STATUS.CONFIRMED
          )}
        </>
      );
    }
    if (Order.isConfirmed(order)) {
      const text = Order.isSelfCollect(order)
        ? i18next.t('order.mark-as-ready-to-collect')
        : i18next.t('order.mark-as-sent');
      const markAsSentButton = this.renderQuickButton(
        order,
        text,
        ORDER_STATUS.SENT
      );
      return (
        <>
          <OrderStatus order={order} />
          {renderCourrierDetails(order)}
          {markAsSentButton}
        </>
      );
    }
    if (Order.isSent(order)) {
      const text = Order.isSelfCollect(order)
        ? i18next.t('order.mark-as-taken')
        : i18next.t('order.mark-as-delivered');
      return (
        <>
          <OrderStatus order={order} />
          {this.renderQuickButton(order, text, ORDER_STATUS.DELIVERED)}
        </>
      );
    }
    return <OrderStatus order={order} />;
  };

  renderQuickButton = (order, text, status) => (
    <button
      type="button"
      className="btn order__btn"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onUpdateOrderStatus(order, status);
      }}
    >
      {text}
    </button>
  );
}

export default OrderCard;
