import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import Shapes from 'shapes/main';
import {
  MenuAddonFieldsSet,
  MenuItem,
  MenuItemAdditionalField,
} from 'types/restaurant-menu';
import ProductAdditionalFieldAdd from '../orders/ProductAdditionalFieldAdd';
import ProductAdditionalFieldEdit from '../orders/ProductAdditionalFieldEdit';
import CardWithInput from './CardWithInput';
import { AdditionalFieldTypes, ModalTypes } from 'enums';
import CollapsibleCard from '../common/CollapsibleCard';
import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import OtoButtons from '../common/OtoButtons';
import { currency } from 'globals/currency';

interface IProps {
  getMenuItemsWhichUseAddonSet: (set: MenuAddonFieldsSet) => MenuItem[];
  onSetsSave: (newSets: MenuAddonFieldsSet[]) => void;
  setModal: TSetModalFunction;
  sets: MenuAddonFieldsSet[];
}

const generateUUID = () => {
  let d = new Date().getTime();
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

// TODO better rewrite this using formik instead
const MenuAddonFieldSets: React.FC<IProps> = ({
  getMenuItemsWhichUseAddonSet,
  onSetsSave,
  setModal,
  sets,
}) => {
  const [localSets, setLocalSets] = useState<MenuAddonFieldsSet[]>([]);

  useEffect(() => {
    setLocalSets(sets);
  }, [sets]);

  const onSetAdd = useCallback(
    (newSetName: string) => {
      const newSets = [
        ...localSets,
        {
          id: generateUUID(),
          name: newSetName,
          items: [],
        },
      ];
      setLocalSets(newSets);
      onSetsSave(newSets);
    },
    [localSets, setLocalSets, onSetsSave]
  );

  const getLocalSetByIndex = useCallback(
    (setIndex: number): MenuAddonFieldsSet => {
      const foundSet = localSets[setIndex];
      if (!foundSet) {
        throw new Error(`No set found for index ${setIndex}`);
      }
      return foundSet;
    },
    [localSets]
  );

  const changeSetName = (set: MenuAddonFieldsSet, setIndex: number) => {
    setModal(
      {
        title: `${i18next.t('change-addon-set-name-modal.enter-new')} ${
          set.name
        }`,
        confirm: (newSetName: string) => {
          const originalSet = getLocalSetByIndex(setIndex);
          const changedSet: MenuAddonFieldsSet = {
            ...originalSet,
            name: newSetName,
          };

          const newSets = [
            ...localSets.slice(0, setIndex),
            changedSet,
            ...localSets.slice(setIndex + 1),
          ];
          setLocalSets(newSets);
          onSetsSave(newSets);
        },
        confirmColor: 'success',
        confirmText: `${i18next.t('Save')}`,
        cancelText: `${i18next.t('Cancel')}`,
      },
      ModalTypes.INPUT
    );
  };

  const removeSet = (set: MenuAddonFieldsSet, setIndex: number) => {
    const itemsWhichUseSet = getMenuItemsWhichUseAddonSet(set);
    if (itemsWhichUseSet.length > 0) {
      setModal(
        {
          title: `Brak możliwości usunięcia zestawu dodatków ${set.name}`,
          text: (
            <>
              <p>
                Ten zestaw dodatków jest używany w następujących produktach (
                {itemsWhichUseSet.length} szt.):{' '}
                {itemsWhichUseSet.map((p) => p.name).join(', ')}
              </p>
              <p>Najpierw usuń zestaw dodatków z wymienionych produktów.</p>
            </>
          ),
          confirm: () => {
            toast.error(
              'Błąd usuwania zestawu dodatków - zestaw jest używany w produktach'
            );
          },
          confirmColor: 'danger',
          confirmText: `${i18next.t('Remove')}`,
          cancelText: `${i18next.t('Cancel')}`,
        },
        ModalTypes.CONFIRM
      );
      return;
    }
    setModal(
      {
        title: `Czy na pewno chcesz usunąć zestaw dodatków "${set.name}"?`,
        confirm: () => {
          const newSets = [
            ...localSets.slice(0, setIndex),
            ...localSets.slice(setIndex + 1),
          ];
          setLocalSets(newSets);
          onSetsSave(newSets);
        },
        confirmColor: 'danger',
        confirmText: `${i18next.t('Remove')}`,
        cancelText: `${i18next.t('Cancel')}`,
      },
      ModalTypes.CONFIRM
    );
  };

  const updateSetItems = useCallback(
    (setItems: MenuItemAdditionalField[], setIndex: number) => {
      const originalSet = getLocalSetByIndex(setIndex);
      const changedSet: MenuAddonFieldsSet = {
        ...originalSet,
        items: setItems,
      };

      setLocalSets([
        ...localSets.slice(0, setIndex),
        changedSet,
        ...localSets.slice(setIndex + 1),
      ]);
    },
    [localSets, setLocalSets]
  );

  const onFieldAddToSet = useCallback(
    (newFieldType: AdditionalFieldTypes, setIndex: number) => {
      const originalSet = getLocalSetByIndex(setIndex);
      updateSetItems(
        [
          ...originalSet.items,
          {
            name: '',
            type: newFieldType,
          },
        ] as MenuItemAdditionalField[], // @FIXME TODO
        setIndex
      );
    },
    [localSets, updateSetItems]
  );

  const onFieldUpdate = useCallback(
    (
      data: Partial<MenuItemAdditionalField>,
      fieldIndex: number,
      setIndex: number
    ) => {
      const originalSet = getLocalSetByIndex(setIndex);
      updateSetItems(
        [
          ...originalSet.items.slice(0, fieldIndex),
          {
            ...originalSet.items[fieldIndex],
            ...data,
          },
          ...originalSet.items.slice(fieldIndex + 1),
        ] as MenuItemAdditionalField[], // @FIXME TODO
        setIndex
      );
    },
    [localSets, updateSetItems]
  );

  const onFieldRemove = useCallback(
    (fieldIndex: number, setIndex: number) => {
      const originalSet = getLocalSetByIndex(setIndex);
      updateSetItems(
        [
          ...originalSet.items.slice(0, fieldIndex),
          ...originalSet.items.slice(fieldIndex + 1),
        ],
        setIndex
      );
    },
    [localSets, updateSetItems]
  );

  return (
    <>
      <h2>Zestawy dodatków</h2>
      {localSets.map((set, setIndex) => (
        <CollapsibleCard
          key={setIndex}
          className="mw-800"
          buttonClassName="p-1"
          title={
            <div className="align-vertical">
              <h4 className="my-2">
                Zestaw dodatków {setIndex + 1}: {set.name}
              </h4>
              <Button
                type="button"
                color="link"
                className="py-0"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  changeSetName(set, setIndex);
                }}
              >
                ({i18next.t('menu-edit.rename')})
              </Button>
              <OtoButtons.DeleteButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeSet(set, setIndex);
                }}
                className="ml-2"
              />
            </div>
          }
        >
          {set.items.map((setAdditionalField, setFieldIndex) => (
            <ProductAdditionalFieldEdit
              key={setFieldIndex}
              currency={currency}
              index={setFieldIndex}
              field={setAdditionalField}
              availableSets={null}
              onChange={(e) =>
                onFieldUpdate(
                  {
                    [e.target.name.split('.')[1]]: e.target.value,
                  } as Partial<MenuItemAdditionalField>,
                  setFieldIndex,
                  setIndex
                )
              }
              onRemove={(index) => onFieldRemove(index, setIndex)}
            />
          ))}
          <ProductAdditionalFieldAdd
            allowSets={false}
            onAdd={(newFieldType: AdditionalFieldTypes) =>
              onFieldAddToSet(newFieldType, setIndex)
            }
          />
          <Button type="button" onClick={() => onSetsSave(localSets)}>
            {i18next.t('Save')}
          </Button>
        </CollapsibleCard>
      ))}
      <CardWithInput
        buttonClassName={`align-vertical pl-0`}
        className="mx-0"
        placeholder={'Dodaj nowy zestaw dodatków'}
        prefix={'Dodaj zestaw '}
        onAdd={onSetAdd}
      />
    </>
  );
};

MenuAddonFieldSets.propTypes = {
  getMenuItemsWhichUseAddonSet: PropTypes.func.isRequired,
  onSetsSave: PropTypes.func.isRequired,
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]).isRequired,
      name: PropTypes.string,
      items: PropTypes.arrayOf(Shapes.menuItemAdditionalFieldShape.isRequired)
        .isRequired,
    }).isRequired
  ).isRequired,
};

export default withSetModal(MenuAddonFieldSets);
