import PropTypes from 'prop-types';

import { discountCategoryShape, discountProductShape } from './discounts';

export * from './discounts';

const deliveryAreaOptionShape = PropTypes.shape({
  freeDeliveryFrom: PropTypes.number.isRequired,
  minCartAmount: PropTypes.number.isRequired,
  name: PropTypes.string,
  price: PropTypes.number.isRequired,
});

const deliveryConfigShape = PropTypes.exact({
  area: PropTypes.any,
  areaOptions: PropTypes.arrayOf(deliveryAreaOptionShape.isRequired).isRequired,
  minCartAmount: PropTypes.number,
  price: PropTypes.number,
  freeDeliveryFrom: PropTypes.number,
  maxKmDistance: PropTypes.number,
  dynamicDeliveryPricing: PropTypes.bool,
  baseDeliveryKm: PropTypes.number,
  extraKmCost: PropTypes.number,
});

const oldFormatDeliveryHourShape = PropTypes.exact({
  open: PropTypes.exact({
    min: PropTypes.number.isRequired,
    hour: PropTypes.number.isRequired,
  }).isRequired,
  close: PropTypes.exact({
    min: PropTypes.number.isRequired,
    hour: PropTypes.number.isRequired,
  }).isRequired,
  isClosed: PropTypes.bool.isRequired,
});

const ordersConfigShape = PropTypes.exact({
  allowOrders: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  allowOrdersChangedAt: PropTypes.string,
  allowOrdersDisabledUntil: PropTypes.string,
  allowedDeliveryOptionKeys: PropTypes.array,
  allowedPaymentOptionKeys: PropTypes.array,
  allowedDeliveryPaymentOptionKeys: PropTypes.array,
  allowedTakeoutPaymentOptionKeys: PropTypes.array,
  alertText: PropTypes.string,
  askForCutlery: PropTypes.bool,
  delivery: deliveryConfigShape.isRequired,
  deliveryTime: PropTypes.number,
  deliveryHours: PropTypes.arrayOf(oldFormatDeliveryHourShape.isRequired)
    .isRequired,
  dynamicDeliveryText: PropTypes.string,

  discountPercent: PropTypes.number,
  discountPercentIfTakeout: PropTypes.number,
  discountText: PropTypes.string,
  // todo refactor - it's usually rules or categories/products
  discountRules: PropTypes.arrayOf(
    PropTypes.oneOfType([
      discountCategoryShape.isRequired,
      discountProductShape.isRequired,
    ]).isRequired
  ).isRequired,
  discountCategories: PropTypes.array,
  discountProducts: PropTypes.array,

  roundDiscount: PropTypes.bool,
  takeoutTime: PropTypes.number,
});

export default ordersConfigShape;
