import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

export function isToday(date: Date) {
  return isDaySame(date, new Date());
}

export function isDaySame(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function parseReservedOn(reserved_on: string): {
  visitDate: string;
  visitTime: string;
} {
  const visitDate = toDateInputValue(new Date(reserved_on.slice(0, 10)));
  const visitTime = reserved_on.slice(11, 16);
  return { visitDate, visitTime };
}

export function displayTime(input: string | Date) {
  const date = typeof input === 'string' ? new Date(input) : input;
  return format(date, 'HH:mm', { locale: pl });
}

export function getWeekDayIndex(date: Date) {
  const weekDayIndex = date.getDay();
  return weekDayIndex === 0 ? 6 : weekDayIndex - 1;
}

export const days = [
  'days.monday',
  'days.tuesday',
  'days.wednesday',
  'days.thursday',
  'days.friday',
  'days.saturday',
  'days.sunday',
];

export function newSafeDate(str: string): Date {
  return new Date(str.replace(/-/g, '/'));
}

export function toDateInputValue(local: Date, separator = '-') {
  const day = ('0' + local.getDate()).slice(-2);
  const month = ('0' + (local.getMonth() + 1)).slice(-2);
  const year = local.getFullYear();
  return `${year}${separator}${month}${separator}${day}`;
}

export function toDateTimeInputValue(local: Date, separator = '-') {
  const hours = local.getHours();
  const minutes = local.getMinutes();
  return `${toDateInputValue(local, separator)} ${hours}:${
    minutes < 10 ? '0' + minutes : minutes
  }`;
}

type TDateFormats = 'dd.MM' | 'dd.MM.yy' | 'dd.MM.yyyy' | 'EEEE';

export function formatDate(
  local: Date,
  dateFormat: TDateFormats = 'dd.MM.yyyy'
) {
  return format(local, dateFormat, { locale: pl });
}

const DateTimeHelper = {
  toDateInputValue,
  toDateTimeInputValue,
  isToday,
  isDaySame,
  parseReservedOn,
  displayTime,
  getWeekDayIndex,
  days,
  newSafeDate,
};

export default DateTimeHelper;
