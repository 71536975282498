import React, { Component } from 'react';

export type PageAlertContextType =
  | {
      alert: null;
      closeAlert?: () => void;
    }
  | {
      alert: {
        message: string;
        type: string;
      };
      closeAlert: () => void;
      setAlert: (message: string, type: string) => void;
    };

const PageAlertContext = React.createContext<PageAlertContextType>({
  alert: null,
});

type PageAlertProviderState = {
  alert: PageAlertContextType['alert'];
};

export class PageAlertProvider extends Component<{}, PageAlertProviderState> {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
    };
  }

  setAlert = (message: string, type: string) => {
    this.setState({
      alert: {
        message,
        type,
      },
    });
  };

  closeAlert = () => {
    this.setState({ alert: null });
  };

  render() {
    return (
      <PageAlertContext.Provider
        value={{
          alert: this.state.alert,
          closeAlert: this.closeAlert,
          setAlert: this.setAlert,
        }}
      >
        {this.props.children}
      </PageAlertContext.Provider>
    );
  }
}

export default PageAlertContext;
