import React from 'react';
import { Modal } from 'reactstrap';
import { Tablet } from 'react-feather';

import CollapsibleCard from '../common/CollapsibleCard';

import enterPageImg from '../../../assets/images/getting-started/1-notification-permission/1-enter-page.png';
import loginImg from '../../../assets/images/getting-started/1-notification-permission/2-login.png';
import acceptNotificationsImg from '../../../assets/images/getting-started/1-notification-permission/3-accept-notification.png';
import savePassClickOkImg from '../../../assets/images/getting-started/1-notification-permission/4-save-pass-click-ok.png';

import openSettingsImg from '../../../assets/images/getting-started/2-ensure-notification-priority/5-open-settings.png';
import findNotificationsImg from '../../../assets/images/getting-started/2-ensure-notification-priority/6-find-notifications.png';
import findAdminOtoStolik from '../../../assets/images/getting-started/2-ensure-notification-priority/7-find-admin-otostolik.png';
import ensureImportantImg from '../../../assets/images/getting-started/2-ensure-notification-priority/8-ensure-important.png';

import addToHomeScreenImg from '../../../assets/images/getting-started/3-add-to-home-screen/9-add-to-home-screen.png';
import presentIconOnHomeScreenImg from '../../../assets/images/getting-started/3-add-to-home-screen/13-present.png';

import './GetDeviceReady.scss';

const fullScreenModalStyle = {
  height: 'calc(100% - 80px)',
  margin: 40,
  maxWidth: 'none',
  width: 'calc(100% - 80px)',
};

const part1Items = [
  {
    src: enterPageImg,
    altText: 'Wejdź na https://admin.otostolik.pl',
    caption: (
      <div>
        1.1 Wejdź na{' '}
        <a href="https://admin.otostolik.pl">https://admin.otostolik.pl</a>
      </div>
    ),
    header: '1.1',
    key: '1',
  },
  {
    src: loginImg,
    altText: 'Zaloguj się',
    caption: <div>1.2 Zaloguj się</div>,
    header: '1.2',
    key: '2',
  },
  {
    src: acceptNotificationsImg,
    caption: '1.3 Wyraź zgodę na wysyłanie powiadomień',
    key: '3',
  },
  {
    src: savePassClickOkImg,
    caption:
      '1.4 Zapisz hasło i kliknij OK w okienku, aby umożliwić działanie dźwięku',
    key: '4',
  },
];

const part2Items = [
  {
    src: openSettingsImg,
    caption: '2.1 Otwórz Ustawienia',
    key: '1',
  },
  {
    src: findNotificationsImg,
    caption: '2.2 Znajdź powiadomienia',
    key: '2',
  },
  {
    src: findAdminOtoStolik,
    caption: '2.3 Znajdź stronę admin.otostolik.pl',
    key: '3',
  },
  {
    src: ensureImportantImg,
    caption: (
      <>
        2.4 Upewnij się, że pole <strong>Ważność</strong> jest wysoka lub
        najwyższa i że notyfikacje będą sygnalizowane dźwiękiem.
      </>
    ),
    key: '4',
  },
];

const part3Items = [
  {
    src: addToHomeScreenImg,
    caption: (
      <>
        3.1 - W ustawieniach w prawym górnym rogu wybierz{' '}
        <strong>Dodaj do ekranu głównego</strong>. Po zatwierdzeniu, powinna
        pojawić się ikona aplikacji na pulpicie.
      </>
    ),
    key: '1',
  },
  {
    src: presentIconOnHomeScreenImg,
    caption: (
      <>
        3.2 - Wynik. <div className="text-danger">Uwaga:</div> skrót zwykle
        pojawia się na nowym oknie pulpitu (trzeba przesunąć wprawo)
      </>
    ),
    key: '2',
  },
];

type GetDeviceReadyState = {
  openImage: string | null;
}

class GetDeviceReady extends React.PureComponent<
  {},
  GetDeviceReadyState
> {
  state: GetDeviceReadyState = { openImage: null };

  openImageModal = (e) => this.setState({ openImage: e.target.src });
  hideModal = () => this.setState({ openImage: null });

  render() {
    return (
      <>
        <CollapsibleCard
          title={
            <>
              <Tablet />{' '}
              <span>
                Krok 2 - jak przygotować sprzęt do pierwszego zamówienia?
              </span>
            </>
          }
        >
          <h3 className="mb-3 font-weight-bold">
            Część 1: Zezwolenie na notyfikacje/powiadomienia:
          </h3>
          <div className="image-tutorial-container">
            {part1Items.map(this.renderItem)}
            <h3 className="bt-1 pt-2 mb-3 font-weight-bold">
              Część 2: Sprawdź, czy notyfikacja ma Wysoką ważność:
            </h3>
            {part2Items.map(this.renderItem)}
            <h3 className="bt-1 pt-2 mb-3 font-weight-bold">
              Część 3: Dodaj aplikację do ekranu głównego
            </h3>
            {part3Items.map(this.renderItem)}
          </div>
        </CollapsibleCard>
        <Modal
          isOpen={!!this.state.openImage}
          toggle={this.hideModal}
          style={fullScreenModalStyle}
        >
          {this.state.openImage && (
            <img
              src={this.state.openImage}
              alt="full screen"
              onClick={this.hideModal}
            />
          )}
        </Modal>
      </>
    );
  }

  renderItem = (item) => (
    <figure key={item.key}>
      <img src={item.src} alt={item.alt} onClick={this.openImageModal} />
      <figcaption>{item.caption}</figcaption>
    </figure>
  );
}

export default GetDeviceReady;
