import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

import { SIDEBAR_ORDER_CUISINE_LIST } from './cuisines';
import CommonSection from '../common/CommonSection';

export type CuisineListSelectProps = {
  handleChange: (newCuisineLabels: string[]) => void;
  values: string[];
};

const CuisineListSelect: React.FC<CuisineListSelectProps> = ({
  handleChange,
  values,
}) => {
  return (
    <CommonSection header="Typy kuchni (max. 5)">
      <Multiselect
        isObject={false}
        options={SIDEBAR_ORDER_CUISINE_LIST}
        onSelect={handleChange}
        onRemove={handleChange}
        selectedValues={values}
        selectionLimit={5}
        showCheckbox
        placeholder="Wybierz typy kuchni"
      />
      <p className="text-danger mt-3 mb-0">
        Uwaga - w obecnej chwili sekcja typ kuchni służy jedynie do podglądu
        możliwych opcji. Dla aktualizacji typów kuchni skontaktuj się z nami.
      </p>
    </CommonSection>
  );
};

export default CuisineListSelect;
