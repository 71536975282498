import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import i18next from 'i18next';

import Order, { isNotConfirmedYet } from 'utils/orders';
import PromoCodeText from './PromoCodeText';
import { currency } from 'globals/currency';
import { ORDER_STATUS, personalFields } from 'config';
import { displayTime, isToday, newSafeDate } from 'utils/date-time';
import OtoSpinner from '../common/OtoSpinner';
import { hasPrinter, isBombardino } from 'utils/restaurant';
import { toDateInputValue } from 'utils/date-time';
import Shapes from 'shapes/main';
import OrderCutlery from './order-parts/OrderCutlery';
import OrderDelivery from './order-parts/OrderDelivery';
import OrderPaymentType from './order-parts/OrderPaymentType';
import OrderRealizationTime from './order-parts/OrderRealizationTime';
import OrderProducts from './order-parts/OrderProducts';
import OrderTotal from './order-parts/OrderTotal';
import type { IOrder, NormalizedRestaurant } from 'types';

import './OrderModal.scss';
import OrderConfirmedAtTime from './order-parts/OrderConfirmedAtTime';

export type OrderModalProps = {
  className?: string | null;
  order: IOrder;
  isLoading: boolean;
  onAcceptOrderClick: () => void;
  onDeclineOrderClick: () => void;
  onChangeRestaurantClick: () => void;
  onClose: (order: IOrder) => void;
  onPrintOrderClick: () => void;
  onUpdateOrderStatus: (status: string, extra?: any) => void;
  renderOrderSource: (order: IOrder) => React.ReactNode;
  restaurant: NormalizedRestaurant;
};

export class OrderModal extends React.PureComponent<OrderModalProps, {}> {
  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    order: Shapes.orderShape.isRequired,
    onAcceptOrderClick: PropTypes.func.isRequired,
    onDeclineOrderClick: PropTypes.func.isRequired,
    onChangeRestaurantClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    restaurant: Shapes.restaurantShape.isRequired,
    onUpdateOrderStatus: PropTypes.func.isRequired,
  };

  sendToDelivery = () => this.props.onUpdateOrderStatus(ORDER_STATUS.SENT);
  markAsDelivered = () =>
    this.props.onUpdateOrderStatus(ORDER_STATUS.DELIVERED);

  render() {
    const { className = '', order, renderOrderSource, restaurant } = this.props;
    const safeCreatedAt = newSafeDate(order.created_at);
    return (
      <Modal
        className={`${className} new-order-modal`}
        isOpen={true}
        toggle={() => this.props.onClose(order)}
        key="modal"
      >
        {this.renderHeader()}
        <ModalBody className="row">
          <Col xs="12" md="6">
            {!isToday(safeCreatedAt) && (
              <div>
                <strong>{i18next.t('order-modal.created-date')}: </strong>
                <span>{toDateInputValue(safeCreatedAt)}</span>
              </div>
            )}
            <strong>
              {i18next.t('order-modal.delivery-n-payment-details')}:
            </strong>
            <OrderDelivery order={order} />
            {!Order.isSelfCollect(order) && (
              <div>
                {i18next.t('order-modal.delivery-cost')}: {order.delivery_cost}{' '}
                {currency}
              </div>
            )}
            <OrderPaymentType order={order} />
            <OrderCutlery order={order} restaurant={restaurant} />
            {renderOrderSource(order)}
            <hr />
            <strong>{i18next.t('order-modal.ordered-meals')}:</strong>
            <OrderProducts order={order} />
            <OrderTotal order={order} />
            {order.customer.notes && (
              <>
                <strong className="text-danger">
                  {i18next.t('order-modal.notes')}:{' '}
                </strong>
                {order.customer.notes}
              </>
            )}
          </Col>
          <Col xs="12" md="6">
            <hr className="sm-minus" />
            <div>
              <strong>{i18next.t('order-modal.created-time')}: </strong>
              <span>{displayTime(safeCreatedAt)}</span>
            </div>
            <OrderConfirmedAtTime order={order} />
            <hr />
            <strong>{i18next.t('order-modal.customer-details')}:</strong>
            {personalFields.map((field) => (
              <div key={field.name}>
                {field.label}: {order.customer[field.name]}
              </div>
            ))}
            <div>
              <strong>{i18next.t('order.prepare-time.label')}: </strong>
              <span>
                {!!order.prepare_time
                  ? i18next.t('order.prepare-time.value-valid', {
                      mins: order.prepare_time,
                    })
                  : i18next.t('order.prepare-time.value-missing')}
              </span>
            </div>
            {!!order.food_prepare_time && (
              <div>
                {i18next.t('order.food-prepare-time', {
                  mins: order.food_prepare_time,
                })}
              </div>
            )}
            <OrderRealizationTime order={order} />
            <PromoCodeText order={order} showHint />
          </Col>
        </ModalBody>
        <ModalFooter>{this.renderFooterButtons()}</ModalFooter>
      </Modal>
    );
  }

  renderHeader() {
    const { isLoading, order } = this.props;
    if (isLoading) {
      return (
        <ModalHeader>
          <OtoSpinner center />
        </ModalHeader>
      );
    }

    return isNotConfirmedYet(order) ? (
      <ModalHeader toggle={this.props.onAcceptOrderClick} className="pt-1">
        {i18next.t('order.title-new')} #{order.id}
      </ModalHeader>
    ) : (
      <ModalHeader toggle={() => this.props.onClose(order)} className="pt-1">
        {i18next.t('order.title')} #{order.id}
      </ModalHeader>
    );
  }

  renderFooterButtons = () => {
    if (this.props.isLoading) {
      return <OtoSpinner center />;
    }
    const { order, restaurant } = this.props;
    if (isNotConfirmedYet(order)) {
      return (
        <>
          <Button color="danger" onClick={this.props.onDeclineOrderClick}>
            {i18next.t('order.mark-as-declined')}
          </Button>{' '}
          <Button color="success" onClick={this.props.onAcceptOrderClick}>
            {i18next.t('order.mark-as-confirmed')}
          </Button>
          {isBombardino(this.props.restaurant) && (
            <Button color="info" onClick={this.props.onChangeRestaurantClick}>
              {i18next.t('order.change-restaurant')}
            </Button>
          )}
        </>
      );
    }
    if (Order.isDelivered(order) || Order.isDeclined(order)) {
      return null;
    }
    if (Order.isSent(order)) {
      return (
        <Button color="success" onClick={this.markAsDelivered}>
          {i18next.t(
            Order.isSelfCollect(order)
              ? 'order.mark-as-taken'
              : 'order.mark-as-delivered'
          )}
        </Button>
      );
    }

    const markAsSentButton = (
      <Button color="success" onClick={this.sendToDelivery}>
        {Order.isSelfCollect(order)
          ? i18next.t('order.mark-as-ready-to-collect')
          : i18next.t('order.mark-as-sent')}
      </Button>
    );
    if (hasPrinter(restaurant)) {
      return (
        <>
          <Button color="warning" onClick={this.props.onPrintOrderClick}>
            {i18next.t('order-modal.print-order')}
          </Button>
          {markAsSentButton}
        </>
      );
    }

    return markAsSentButton;
  };
}

export default OrderModal;
