import { IRestaurantPlaceArea, TRestaurantPlacesAreaConfig } from 'types';

const areaForOtoLokal: IRestaurantPlaceArea = {
  tablesConfig: {
    1: {
      position: {
        x: 100,
        y: 100,
      },
    },
    2: {
      position: {
        x: 300,
        y: 100,
      },
    },
    3: {
      position: {
        x: 500,
        y: 100,
      },
    },
    4: {
      position: {
        x: 500,
        y: 100,
      },
    },
  },
  tableSize: {
    width: '8.5%',
    height: '13%',
  },
  styles: {
    height: '100%',
    minHeight: 700,
    width: '100%',
  },
};

export const otolokalPartnerAreasConfig: TRestaurantPlacesAreaConfig = {
  1: areaForOtoLokal,
};
