import React from 'react';
import { toast } from 'react-toastify';

import { NormalizedRestaurant } from 'types/restaurants';
import APIService from 'services/api';
import { LOG_BAG } from 'utils/log';
import { User } from 'types';
import AddUserForm from './AddUserForm';


interface ViewAddDriverProps {
  restaurant: NormalizedRestaurant;
  onSuccess: (driver: User) => void;
}

const ViewAddDriver = (props: ViewAddDriverProps) => {
  const { restaurant } = props;
  const handleAddDriver = (values) => {
    return APIService.post(`/restaurants/${restaurant.id}/drivers`, values)
      .then((newDriver: User) => {
        toast.success('Kierowca został dodany');
        props.onSuccess(newDriver);
      })
      .catch((error: Error) => {
        toast.error('Wystąpił błąd pocdzas dodawania kierowcy');
        LOG_BAG.logError('Driver add error', error);
      });
  };
  return <AddUserForm isDriver onAddUser={handleAddDriver} restaurantId={restaurant.id} />;
};

export default ViewAddDriver;
