import { ROLES } from 'enums';
import { OTO_PRODUCTS } from 'enums';
import OrderFeedbackPage from '../app/pages/OrderFeedbackPage';
import OrdersListPage from '../app/pages/OrdersListPage';
import ReferralsPage from '../app/pages/ReferralsPage';
import WelcomePage from '../app/pages/Welcome';
import RestaurantPayoutsPage from '../app/pages/RestaurantPayoutsPage';
import type { INav, INavItem, INavItemWithChildren } from './types';
import settingsNavItem, { allOrdersProducts } from './settings';
import restaurantStatisticsNavItem from './restaurant-statistics';
import { getAdminNavPart } from './admin';
import marketingNavItem from './marketing';
import { reservationNavItems } from './reservation';
import {
  deliveriesCoordinatorDeliveriesListNavItem,
  driverDeliveriesListNavItem,
  restaurantDeliveriesListNavItem,
  restaurantDriversListNavItem,
} from './deliveries';

export type { INav, INavItem, INavItemWithChildren };

const ordersListPage: INavItem = {
  name: OrdersListPage.navName,
  icon: 'Clipboard',
  url: OrdersListPage.url,
  availableWithProducts: allOrdersProducts,
};

const referralsNavItem: INavItem = {
  name: ReferralsPage.navName,
  icon: 'DollarSign',
  url: ReferralsPage.url,
};

const helpNavItem: INavItem = {
  name: 'Pomoc',
  icon: 'HelpCircle',
  url: '/help',
};

const defaultNav: INav = {
  top: [
    ordersListPage,
    restaurantDeliveriesListNavItem,
    restaurantDriversListNavItem,
    getAdminNavPart(),
    ...reservationNavItems,
    settingsNavItem,
    {
      name: RestaurantPayoutsPage.navName,
      icon: 'DollarSign',
      url: RestaurantPayoutsPage.url,
      availableForRoles: [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN],
      availableWithProducts: [OTO_PRODUCTS.orders],
    },
    marketingNavItem,
    referralsNavItem,
    {
      // @fixme class component page type
      // @ts-expect-error
      name: OrderFeedbackPage.navName,
      icon: 'MessageSquare',
      // @ts-expect-error
      url: OrderFeedbackPage.url,
      availableWithProducts: allOrdersProducts,
    },
    restaurantStatisticsNavItem,
    {
      name: WelcomePage.navName,
      icon: 'Book',
      url: WelcomePage.url,
    },
    helpNavItem,
  ],
  bottom: [],
};

export default defaultNav;

export const coordinatorNav = {
  top: [
    deliveriesCoordinatorDeliveriesListNavItem,
    getAdminNavPart(),
    referralsNavItem,
    helpNavItem,
  ],
  bottom: [],
};

export const restaurantDriverNav = {
  top: [driverDeliveriesListNavItem, referralsNavItem, helpNavItem],
  bottom: [],
};

export const exchangeDriverNav = restaurantDriverNav;
