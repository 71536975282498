import UrlFetcher from 'app/containers/UrlFetcher';
import React, { useCallback, useState } from 'react';
import type {
  AnyRestaruant,
  TDeliveryPricing,
  TDeliveryPricingDTO,
} from 'types';
import DeliveriesPricingsList from './DeliveriesPricingsList';
import APIService from 'services/api';
import { toast } from 'react-toastify';
import { getArrayWithoutObject } from 'utils/array';
import { CommonSection } from 'app/components/common';
import AddDeliveryPricingForm from './AddDeliveryPricingForm';
import Restaurant from 'utils/restaurant';

export type ViewRestaurantDeliveriesPricingsProps = {
  restaurant: AnyRestaruant;
};

const ViewRestaurantDeliveriesPricings: React.FC<
  ViewRestaurantDeliveriesPricingsProps
> = ({ restaurant }) => {
  const [loadingDeliveryPricings, setLoadingDeliveryPricings] = useState<
    number[]
  >([]);

  const isLoading = useCallback(
    (deliveryPricing: TDeliveryPricing) =>
      loadingDeliveryPricings.includes(deliveryPricing.id),
    [loadingDeliveryPricings]
  );

  if (!Restaurant.hasAnyDeliveries(restaurant)) {
    return (
      <CommonSection header={'Cennik dostaw'}>
        Nie dotyczy - restauracja nie korzysta z usługi lub integracji dostaw
      </CommonSection>
    );
  }

  return (
    <>
      <UrlFetcher<TDeliveryPricing[]>
        url={`/restaurants/${restaurant.id}/deliveries-pricings`}
      >
        {([deliveriesPricings, { setData }]) => {
          const handleAddDeliveryPricing = async (
            payload: TDeliveryPricingDTO
          ) => {
            return APIService.post(
              `/restaurants/${restaurant.id}/deliveries-pricings`,
              payload
            )
              .then((deliveryPricing) => {
                setData([...deliveriesPricings, deliveryPricing]);
                toast.success('Dodano cenę dostawy');
                return deliveryPricing;
              })
              .catch(() => {
                toast.error('Wystąpił problem z dodaniem ceny dostawy');
                return null;
              });
          };

          const handleRemoveDeliveryPricing = async (
            deliveryPricing: TDeliveryPricing
          ) => {
            setLoadingDeliveryPricings((prev) => [...prev, deliveryPricing.id]);
            return APIService.del(
              `/restaurants/${restaurant.id}/deliveries-pricings/${deliveryPricing.id}`
            )
              .then(() => {
                setLoadingDeliveryPricings((prev) =>
                  prev.filter((id) => id !== deliveryPricing.id)
                );
                setData(
                  getArrayWithoutObject(deliveriesPricings, deliveryPricing)
                );
                toast.success('Usunięto cenę dostawy');
              })
              .catch(() => {
                setLoadingDeliveryPricings((prev) =>
                  prev.filter((id) => id !== deliveryPricing.id)
                );
                toast.error('Wystąpił problem z usunięciem ceny dostawy');
              });
          };

          return (
            <CommonSection header={'Cennik dostaw'}>
              {!!deliveriesPricings?.length ? (
                <DeliveriesPricingsList
                  deliveriesPricings={deliveriesPricings}
                  isLoading={isLoading}
                  onRemove={handleRemoveDeliveryPricing}
                />
              ) : (
                <div className={'h4 my-2 text-danger'}>
                  Brak ustawionych cen dostaw
                </div>
              )}
              <AddDeliveryPricingForm
                onAddDeliveryPricing={handleAddDeliveryPricing}
              />
            </CommonSection>
          );
        }}
      </UrlFetcher>
    </>
  );
};

export default ViewRestaurantDeliveriesPricings;
