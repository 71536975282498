import PropTypes from 'prop-types';

import deliveryShape from './delivery';
import orderShape from './order';
import ordersConfigShape from './orders-config';
import { draftReservationShape, savedReservationShape } from './reservation';
import reservationConfigShape from './reservation-config';
import restaurantShape, {
  customRuleShape,
  originalRestaurantShape,
} from './restaurant';
import {
  menuItemShape,
  menuItemAdditionalFieldShape,
  categoryShape,
} from './restaurant-menu';
import restaurantPlaceShape from './restaurant-place';
import restaurantTableShape, {
  restaurantTableWithPlaceShape,
  restaurantTableWithPlaceNameShape,
} from './restaurant-table';
import payoutShape from './payout';
import promoCodeShape from './promo-code';
import robotShape from './robot';
import userShape from './user';

const modalBagShape = PropTypes.exact({
  setModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modal: PropTypes.object,
});

const urlFetcherPropsShape = PropTypes.exact({
  reloadData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
});

const Shapes = {
  customRuleShape,

  deliveryShape,
  draftReservationShape,

  menuCategoryShape: categoryShape,
  menuItemShape,
  menuItemAdditionalFieldShape,
  modalBagShape,

  orderShape,
  ordersConfigShape,
  originalRestaurantShape,
  payoutShape,

  promoCodeShape,
  reservationConfigShape,
  restaurantShape,
  restaurantPlaceShape,
  restaurantTableShape,
  restaurantTableWithPlaceShape,
  restaurantTableWithPlaceNameShape,

  robotShape,
  savedReservationShape,
  urlFetcherPropsShape,
  userShape,
};

export default Shapes;
