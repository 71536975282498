import React from 'react';
import { Button } from 'reactstrap';

import { ModalTypes, ORDER_STATUS } from 'config';
import { IOrder } from 'types/order';
import Order from 'utils/orders';
import type { TSetModalFunction } from '../../../containers/WithSetModal';

const DeclineOrderCell = (props: {
  onOrderDecline: Function;
  setModal: TSetModalFunction;
  order: IOrder;
}) => {
  const { setModal, onOrderDecline, order } = props;

  if (order.status === ORDER_STATUS.DECLINED) {
    return <div>#{order.id}</div>;
  }
  return (
    <>
      <div>#{order.id}</div>
      <Button
        type="button"
        onClick={() => {
          setModal(
            {
              title: 'Wybierz powód braku możliwości realizacji zamówienia',
              options: Order.CONFIRMED_ORDER_DECLINE_REASONS,
              other: 'inny',
              otherPlaceholder: 'podaj powód odrzucenia zamówienia',
              confirm: (reason: string, customReason?: string) => {
                return onOrderDecline(
                  order,
                  ORDER_STATUS.DECLINED,
                  order.restaurant,
                  customReason || reason
                );
              },
              confirmColor: 'danger',
              confirmText: 'Odrzuć zamówienie',
              cancelText: 'Zamknij',
            },
            ModalTypes.SELECT
          );
        }}
      >
        Anuluj
      </Button>
    </>
  );
};

DeclineOrderCell.propTypes = {};

export default DeclineOrderCell;
