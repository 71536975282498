import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18 from 'i18next';

import ModalContext from '../../containers/ModalContext';
import { days, formatDate, getWeekDayIndex } from 'utils/date-time';
import { logActivity } from 'utils/log';
import { areOrdersEnabled } from 'utils/orders-config';
import Shapes from 'shapes/main';
import { OtoSpinner, OtoToggle } from '../common';
import { OldFormatDeliveryHour, OrdersConfig } from 'types';
import { IPageModalProps } from '../../containers/WithSetModal';
import { ModalTypes, REASONS_TO_CLOSE } from 'config';
import { disableUntilOptions } from './DisableOrdersModal';

export const formatDeliveryHours = (hours: OldFormatDeliveryHour) => {
  const { open, close, isClosed } = hours;
  if (isClosed) {
    return 'zamknięte';
  }
  return `${open.hour}:${open.min || '00'} - ${close.hour}:${
    close.min || '00'
  }`;
};

const formatReasonToClose = (key) => {
  const foundReason = REASONS_TO_CLOSE.find((item) => item.value === key);
  return foundReason ? i18.t(foundReason.label) : key;
};

interface IProps {
  ordersConfig: OrdersConfig;
  onUpdate: (
    payload: Partial<OrdersConfig>,
    successMessage: string
  ) => Promise<any>;
}

const DisableOrdersToggle: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const ordersConfig = props.ordersConfig || {};
  const allowOrders = areOrdersEnabled(ordersConfig);

  const updateDisableOrders = (payload: Partial<OrdersConfig>) => {
    const successMessage = `${i18.t(
      'disable-orders-toggle.orders-have-been'
    )} ${
      allowOrders
        ? `${i18.t('disable-orders-toggle.disabled')}`
        : `${i18.t('disable-orders-toggle.enabled')}`
    }`;
    setLoading(true);
    return props
      .onUpdate(payload, successMessage)
      .finally(() => setLoading(false));
  };

  const disableOrders = ({
    disableUntil,
    reasonToDisable,
  }: {
    reasonToDisable: string;
    disableUntil?: string;
  }) => {
    const disableUntilLabel = disableUntilOptions.find(opt => opt.value === disableUntil)?.label || disableUntil;
    logActivity(
      `Zamówienia będą ${
        allowOrders ? `wyłączone do ${disableUntilLabel} z powodu: ${reasonToDisable}` : 'włączone'
      }`,
      { instant: true }
    );

    return updateDisableOrders({
      allowOrders: reasonToDisable,
      allowOrdersDisabledUntil: disableUntil,
    });
  };

  const enableOrders = () => {
    logActivity(`Zamówienia będą włączone`, { instant: true });
    return updateDisableOrders({
      allowOrders: true,
      allowOrdersDisabledUntil: null,
    });
  };

  const weekdayIndex = getWeekDayIndex(new Date());
  const todayDeliveryHours = ordersConfig.deliveryHours[weekdayIndex];

  const modalProps: IPageModalProps = allowOrders
    ? {
        modalSpecificProps: {
          onValuesSelected: disableOrders,
          todayDeliveryHours,
        },
      }
    : {
        title: `${i18.t('disable-orders-toggle.enabled-confirmation')}`,
        text: `${i18.t('disable-orders-toggle.enabled-confirmation-text')}`,
        confirm: enableOrders,
        confirmColor: 'success',
      };
  const modalType = allowOrders
    ? ModalTypes.DISABLE_ORDERS
    : ModalTypes.CONFIRM;
  
  const secondaryText = allowOrders
    ? `${i18.t('disable-orders-toggle.concerns-hours')} (${i18
        .t(days[weekdayIndex])
        .toLowerCase()}: ${formatDeliveryHours(todayDeliveryHours)})`
    : `${i18.t('disable-orders-toggle.reason')} ${formatReasonToClose(
        ordersConfig.allowOrders
      )}`;
  const declaredTime = (
    <div className="small">
      {i18.t('disable-orders-toggle.declared-time')}:{' '}
      {ordersConfig.deliveryTime} min.{' '}
      <a href="/settings/orders">({i18.t('Change')})</a>
    </div>
  );
  return (
    <div className="nav navbar pl-0">
      <ModalContext.Consumer>
        {(modalBag) => (
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <div>
                  {i18.t('disable-orders-toggle.orders')}{' '}
                  {allowOrders
                    ? `${i18.t('disable-orders-toggle.enabled')}`
                    : `${i18.t('disable-orders-toggle.disabled')}`}
                </div>
                <div className="small">{secondaryText}</div>
                {ordersConfig.allowOrdersDisabledUntil && (
                  <div className="small">
                    Wyłączone do:{' '}
                    {formatDate(
                      new Date(ordersConfig.allowOrdersDisabledUntil)
                    )}
                  </div>
                )}
              </div>
              {loading ? (
                <OtoSpinner className="ml-2" />
              ) : (
                <OtoToggle
                  checked={allowOrders}
                  className="ml-2"
                  name="allowOrders"
                  value="true"
                  onChange={() => modalBag.setModal(modalProps, modalType)}
                />
              )}
            </div>
            {declaredTime}
          </div>
        )}
      </ModalContext.Consumer>
      {props.children}
    </div>
  );
};

DisableOrdersToggle.propTypes = {
  ordersConfig: Shapes.ordersConfigShape.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default DisableOrdersToggle;
