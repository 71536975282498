import PropTypes, { Requireable } from 'prop-types';
import { AdditionalFieldTypes } from 'enums';
import { MenuItemAdditionalField } from 'types';

const menuItemLabelShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

// SingleChoiceAdditionalField PropTypes
const singleChoiceAdditionalFieldShape = PropTypes.shape({
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([AdditionalFieldTypes.SINGLE_CHOICE]).isRequired,
  value: PropTypes.string.isRequired,
});

// MultipleChoiceAdditionalField PropTypes
const multipleChoiceAdditionalFieldShape = PropTypes.shape({
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([AdditionalFieldTypes.MULTIPLE_CHOICE]).isRequired,
  value: PropTypes.string.isRequired,
});

// AddonAdditionalField PropTypes
const addonAdditionalFieldShape = PropTypes.shape({
  addonPrice: PropTypes.number.isRequired,
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([AdditionalFieldTypes.CHECKBOX]).isRequired,
});

// SetAdditionalField PropTypes
const setAdditionalFieldShape = PropTypes.shape({
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([AdditionalFieldTypes.SET]).isRequired,
  value: PropTypes.string.isRequired,
});

// Combining them together for MenuItemAdditionalField
export const menuItemAdditionalFieldShape = PropTypes.oneOfType([
  singleChoiceAdditionalFieldShape,
  multipleChoiceAdditionalFieldShape,
  addonAdditionalFieldShape,
  setAdditionalFieldShape,
]) as Requireable<MenuItemAdditionalField>;


export const priceOptionShape = PropTypes.exact({
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  oldPrice: PropTypes.number,
});

export const menuItemDailyAvailabilityShape = PropTypes.shape({
  hourFrom: PropTypes.number,
  hourTo: PropTypes.number,
  minuteFrom: PropTypes.number,
  minuteTo: PropTypes.number,
  isAvailable: PropTypes.bool.isRequired,
  isFullDay: PropTypes.bool.isRequired,
}).isRequired;

export const menuItemAvailabilityShape = PropTypes.shape({
  mon: menuItemDailyAvailabilityShape,
  tue: menuItemDailyAvailabilityShape,
  wed: menuItemDailyAvailabilityShape,
  thu: menuItemDailyAvailabilityShape,
  fri: menuItemDailyAvailabilityShape,
  sat: menuItemDailyAvailabilityShape,
  sun: menuItemDailyAvailabilityShape,
});

export const menuItemShape = PropTypes.shape({
  id: PropTypes.number,
  desc: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  price: PropTypes.number.isRequired,
  oldPrice: PropTypes.number,
  labels: PropTypes.arrayOf(menuItemLabelShape.isRequired),
  isDisabled: PropTypes.bool.isRequired,
  additionalFields: PropTypes.arrayOf(menuItemAdditionalFieldShape.isRequired),
  priceOptions: PropTypes.arrayOf(priceOptionShape.isRequired),
  availability: menuItemAvailabilityShape,
});

export const categoryShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    menuItemShape.isRequired as Requireable<MenuItemAdditionalField>
  ).isRequired,
  text: PropTypes.string,
});

const menuShape = PropTypes.shape({
  categories: PropTypes.arrayOf(categoryShape.isRequired).isRequired,
});

export default menuShape;
