import React from 'react';
import PropTypes from 'prop-types';

export type MarketingCampaign = {
  id: number;
  name: string;
  description: string;
  status: string;
  type: string;
  price: number;
  parameters: Record<string, unknown>;
  scheduled_at: string;
  template_id: number;
  promo_code_id?: number;
  restaurant_id: number;
}

const MarketingCampaingList: React.FC<{
  campaigns: MarketingCampaign[];
}> = props => {
  const { campaigns } = props
  return <>
    {
      campaigns.map(c => <div key={c.id} className="card">
        {
          Object.keys(c).map(key => <div className="row">{key}: {c[key]}</div>)
        }
      </div>)
    }
  </>;
};

MarketingCampaingList.propTypes = {
  campaigns: PropTypes.array.isRequired,
};

export default MarketingCampaingList;