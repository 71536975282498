import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Row,
} from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import i18next from 'i18next';

import OtoSpinner from '../common/OtoSpinner';
import { ITable } from 'types/restaurant-table';
import Shapes from 'shapes/main';
import { IconWithTooltip, OtoToggle } from '../common';

interface TableEditModalProps {
  selectedTable: ITable | null;
  setSelectedTable: (
    table:
      | (ITable & {
          max_people: number | '';
          min_people: number | '';
        })
      | null
  ) => void;
  tables: ITable[];
  onSave: (selectedTable: ITable) => void;
  loading: boolean;
}

export const TableEditModal: React.FC<TableEditModalProps> = (props) => {
  const { selectedTable, setSelectedTable, tables, onSave, loading } = props;
  const clearSelectedTable = () => setSelectedTable(null);
  const updateLinkedTo = (newLinkedTo: ITable[]) =>
    setSelectedTable({
      ...(selectedTable as ITable),
      linked_to: newLinkedTo.map((table) => table.id),
    });
  return (
    <Modal isOpen={!!selectedTable} toggle={clearSelectedTable}>
      {selectedTable && (
        <>
          <ModalHeader toggle={clearSelectedTable}>
            Edytujesz stolik {selectedTable.name}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>{i18next.t('table-settings.add-table.name')}</Label>
              <Input
                placeholder="np. 1, S1 lub A"
                value={selectedTable.name}
                onChange={(e) =>
                  setSelectedTable({ ...selectedTable, name: e.target.value })
                }
              />
            </FormGroup>
            <Row className={'align-items-start'}>
              <FormGroup className="col-12 col-md-6">
                <Label>
                  {i18next.t('table-settings.add-table.min-people')}
                </Label>
                <Input
                  className="mw-150"
                  placeholder="np. 2"
                  type="number"
                  step="1"
                  value={selectedTable.min_people}
                  onChange={(e) =>
                    setSelectedTable({
                      ...selectedTable,
                      // @ts-ignore allow empty string in edit mode
                      min_people: e.target.value
                        ? parseInt(e.target.value, 10)
                        : '',
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label>
                  {i18next.t('table-settings.add-table.max-people')}
                </Label>
                <Input
                  className="mw-150"
                  placeholder="np. 4"
                  type="number"
                  step="1"
                  value={selectedTable.max_people}
                  onChange={(e) =>
                    setSelectedTable({
                      ...selectedTable,
                      // @ts-ignore allow empty string in edit mode
                      max_people: e.target.value
                        ? parseInt(e.target.value, 10)
                        : '',
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label>
                  <span>{i18next.t('table-settings.add-table.priority')}</span>
                  <IconWithTooltip
                    id="add-table-priority-tooltip"
                    className="ml-2"
                    text={i18next.t(
                      'table-settings.add-table.priority-tooltip'
                    )}
                  />
                </Label>
                <Input
                  className="mw-150"
                  placeholder={'np. 1'}
                  type="number"
                  step={'1'}
                  min={'1'}
                  value={selectedTable.priority}
                  onChange={(e) =>
                    setSelectedTable({
                      ...selectedTable,
                      // @ts-ignore allow empty string in edit mode
                      priority: e.target.value
                        ? parseInt(e.target.value, 10)
                        : '',
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label>
                  {i18next.t('table-settings.column-header.is-bookable-online')}
                </Label>
                <OtoToggle
                  checked={!!selectedTable.is_bookable}
                  onChange={() =>
                    setSelectedTable({
                      ...selectedTable,
                      is_bookable: !selectedTable.is_bookable,
                    })
                  }
                />
              </FormGroup>
            </Row>
            <FormGroup>
              <Label>
                {i18next.t(
                  'table-settings.column-header.available-connections'
                )}
              </Label>
              <Multiselect
                options={tables.filter(
                  (table) => table.id !== selectedTable.id
                )}
                selectedValues={tables.filter((table) =>
                  (selectedTable.linked_to || []).includes(table.id)
                )}
                onSelect={updateLinkedTo}
                onRemove={updateLinkedTo}
                displayValue="name"
                placeholder="Wybierz stoliki"
              />
            </FormGroup>
          </ModalBody>
        </>
      )}
      <ModalFooter>
        {loading ? (
          <OtoSpinner />
        ) : (
          <Button
            type="button"
            color="primary"
            onClick={() => onSave(selectedTable as ITable)}
          >
            Zapisz
          </Button>
        )}
        <Button type="button" onClick={clearSelectedTable}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  );
};

TableEditModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedTable: Shapes.restaurantTableShape.isRequired,
  setSelectedTable: PropTypes.func.isRequired,
  tables: PropTypes.array.isRequired,
};

export default TableEditModal;
