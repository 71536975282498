import React, { useCallback } from 'react';
import i18next from 'i18next';

import OrderCard, { OrderCardProps } from './OrderCard';
import { ORDER_STATUS, VIEWS } from 'config';
import GroupedFlow from '../common/GroupedFlow';
import { IOrder } from 'types/order';
import { IDelivery, IDeliveryCreateData } from 'types/delivery';
import {
  NormalizedRestaurant,
  OriginalRestaurant,
} from 'types/restaurants';
import ViewOrderCourrierDetails from '../../views/ViewOrderCourrierDetails';

interface IProps {
  orders: IOrder[];
  ordersLoading: number[];
  addDelivery: (
    data: IDeliveryCreateData,
    restaurant: NormalizedRestaurant
  ) => Promise<IDelivery>;
  handleOrderClick: (order: IOrder) => void;
  updateOrderCustomer: (order: IOrder, payload: IOrder['customer']) => void;
  updateOrderStatus: (order: IOrder, status: string) => void;
  restaurant: NormalizedRestaurant;
  view: any;
  userRestaurants?: OriginalRestaurant[];
}

const OrdersList: React.FC<IProps> = (props) => {
  const {
    addDelivery,
    orders,
    ordersLoading,
    view,
    restaurant,
    updateOrderCustomer,
    userRestaurants,
  } = props;

  const renderOrderCourrierDetails = useCallback(
    (order: IOrder) => {
      return (
        <ViewOrderCourrierDetails
          onDeliveryAdd={addDelivery}
          onUpdateOrderCustomer={updateOrderCustomer}
          order={order}
          restaurant={restaurant}
        />
      );
    },
    [addDelivery, restaurant, updateOrderCustomer]
  );

  const getOrderCardProps = (
    order: IOrder
  ): OrderCardProps & { key: number | string } => ({
    key: order.id,
    order: order,
    isLoading: ordersLoading.includes(order.id),
    onOrderClick: props.handleOrderClick,
    onUpdateOrderStatus: props.updateOrderStatus,
    renderCourrierDetails: renderOrderCourrierDetails,
    restaurant: restaurant,
    restaurantName: (
      (userRestaurants || []).find((r) => r.id === order.restaurant_id) || {}
    ).name,
    view: view.text,
  });
  if (!orders.length) {
    return (
      <p className="col-12 mt-2">
        {i18next.t('orders-list.no-orders-label')}
      </p>
    );
  }
  if (view.text === VIEWS.GRID.text) {
    return (
      <>
        {orders.map((order) => (
          <OrderCard {...getOrderCardProps(order)} />
        ))}
      </>
    );
  }

  const orderGroupsFlow = {
    [ORDER_STATUS.CONFIRMED]: {
      label: i18next.t('orders-list.flow.confirmed'),
    },
    [ORDER_STATUS.SENT]: {
      label: i18next.t('orders-list.flow.sent'),
    },
    [ORDER_STATUS.DELIVERED]: {
      label: i18next.t('orders-list.flow.delivered'),
    },
    [ORDER_STATUS.DECLINED]: {
      label: i18next.t('orders-list.flow.declined'),
    },
  };

  return (
    <>
      <GroupedFlow
        fieldKey="status"
        data={orders}
        emptyText={i18next.t('orders-list.flow.no-orders-label')}
        flow={orderGroupsFlow}
        renderItem={(order) => <OrderCard {...getOrderCardProps(order)} />}
      />
    </>
  );
};

export default OrdersList;
