import React, { MouseEventHandler } from 'react';
import i18next from 'i18next';
import { Button } from 'reactstrap';
import {
  ArrowLeftCircle,
  Edit,
  DownloadCloud,
  LogOut,
  PlusCircle,
  RefreshCcw,
  Save,
  Trash,
} from 'react-feather';
import OtoSpinner from './OtoSpinner';

interface IOtoButtonProps {
  className?: string;
  color?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  outline?: boolean;
  size?: number;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
}

const GenericButton = (
  props: Omit<IOtoButtonProps, 'size'> & {
    icon?: React.ReactNode;
  }
) => {
  const {
    color = 'link',
    className,
    children,
    disabled,
    icon,
    loading,
    type,
    ...restProps
  } = props;
  return (
    <Button
      color={color}
      className={`d-flex align-items-center ${className || ''}`}
      type={type || 'button'}
      disabled={loading || disabled}
      {...restProps}
    >
      {loading ? <OtoSpinner className={'mr-2'} size={'sm'} /> : icon}
      {children}
    </Button>
  );
};

const LogoutButton = (props: IOtoButtonProps) => {
  const { color = 'link', size = 20, ...restProps } = props;
  return (
    <GenericButton
      color={color}
      icon={<LogOut className="mr-1" size={size} />}
      {...restProps}
    >
      {props.children || i18next.t('header__logout')}
    </GenericButton>
  );
};

const AddButton = (props: IOtoButtonProps) => {
  const { size = 20, ...restProps } = props;
  return (
    <GenericButton
      icon={<PlusCircle className="mr-1" size={size} />}
      {...restProps}
    />
  );
};

const EditButton = (props: IOtoButtonProps) => {
  const { size = 20, ...restProps } = props;
  return (
    <GenericButton
      {...restProps}
      icon={<Edit className="mr-1" size={size} />}
    />
  );
};

export const ReloadButton = (props: IOtoButtonProps) => {
  const { color = 'link', size = 20, ...restProps } = props;
  return (
    <GenericButton
      color={color}
      icon={<RefreshCcw className="mr-1" size={size} />}
      {...restProps}
    >
      {props.children}
    </GenericButton>
  );
};

const SaveButton = (props: IOtoButtonProps) => {
  const { color = 'success', size = 20, ...restProps } = props;
  return (
    <GenericButton
      color={color}
      icon={<Save className="mr-1" size={size} />}
      {...restProps}
    >
      {props.children || i18next.t('Save')}
    </GenericButton>
  );
};

const DeleteButton = (props: IOtoButtonProps) => {
  const { color = 'danger', size = 20, ...restProps } = props;
  return (
    <GenericButton
      color={color}
      icon={<Trash className="mr-1" size={size} />}
      {...restProps}
    />
  );
};

const DownloadButton = (props: IOtoButtonProps) => {
  const { color = 'info', outline = true, size = 20, ...restProps } = props;
  return (
    <GenericButton
      color={color}
      icon={<DownloadCloud className="mr-1" size={size} />}
      outline={outline}
      {...restProps}
    />
  );
};

const GoBackButton = (props: IOtoButtonProps) => {
  const { size = 20, ...restProps } = props;
  return (
    <GenericButton
      {...restProps}
      icon={<ArrowLeftCircle className="mr-1" size={size} />}
    />
  );
};

export const OtoButtons = {
  AddButton,
  EditButton,
  DeleteButton,
  DownloadButton,
  GenericButton,
  GoBackButton,
  LogoutButton,
  ReloadButton,
  SaveButton,
};

export default OtoButtons;
