import i18next from 'i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, FormGroup, Input } from 'reactstrap';
import { ISavedFile } from 'types/attachment';
import { LOG_BAG } from 'utils/log';
import APIService from 'services/api';
import OtoSpinner from './OtoSpinner';

interface IFileUploadProps {
  onSuccess: (file: ISavedFile) => void;
}

export const FileUpload: React.FC<IFileUploadProps> = (props) => {
  const { onSuccess } = props;

  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    event.target?.files?.[0] && setSelectedFile(event.target.files[0]);
  };

  const send = () => {
    if (!selectedFile) {
      toast.error('Nie wybrano pliku');
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);

    APIService.post('/files/upload', formData)
      .then((createdFile: ISavedFile) => {
        onSuccess(createdFile);
        toast.success('Zapisano plik');
        setSelectedFile(null);
      })
      .catch((error) => {
        LOG_BAG.logError(`File upload error`, error);
        toast.error('Wystąpił problem podczas zapisu pliku');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <FormGroup className="align-vertical">
        {selectedFile ? (
          <>
            <div className="mb-2 mr-2">Wybrano plik: {selectedFile.name}</div>
            <Button
              type="button"
              color="primary"
              onClick={() => setSelectedFile(null)}
            >
              {i18next.t('Change')}
            </Button>
          </>
        ) : (
          <Input type="file" name="file" onChange={changeHandler} />
        )}
      </FormGroup>
      {isLoading ? (
        <OtoSpinner />
      ) : (
        <Button
          type="button"
          color="primary"
          disabled={!selectedFile}
          onClick={send}
        >
          {i18next.t('Submit')}
        </Button>
      )}
    </>
  );
};

export default FileUpload;
