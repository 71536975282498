import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';
import type { InputProps } from 'reactstrap';
import i18next from 'i18next';
import { Tag } from 'react-feather';

import CollapsibleCard from '../common/CollapsibleCard';
import OtoFormGroup from '../common/OtoFormGroup';
import OtoSpinner from '../common/OtoSpinner';
import {
  Category,
  DiscountCategory,
  DiscountProduct,
  Menu,
  MenuItem,
  OrdersConfig,
} from 'types';

import './DiscountsForm.scss';

const percentNumberInputProps: InputProps = {
  min: 0,
  max: 100,
  type: 'number',
};

export type DiscountFormValues = Omit<
  OrdersConfig,
  'discountCategories' | 'discountProducts'
> & {
  discountCategories: DiscountCategory[];
  discountProducts: DiscountProduct[];
};

interface IProps {
  addCategoryToDiscount: (categoryName: string, percent: number) => void;
  addProductToDiscount: (productName: string, percent: number) => void;
  discountCategories: DiscountCategory[];
  discountProducts: DiscountProduct[];
  isAdmin: boolean;
  isSubmitting: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  menu: Menu;
  removeCategoryFromDiscount: (index: number) => void;
  removeProductFromDiscount: (index: number) => void;
  values: DiscountFormValues;
}

interface IState {
  discountCategoryPercent: number;
  discountProductPercent: number;
  selectedCategory: Category;
  selectedProduct: MenuItem;
}

export default class DiscountsForm extends React.Component<IProps, IState> {
  state = {
    discountCategoryPercent: 0,
    discountProductPercent: 0,
  } as IState;

  static propTypes = {
    addCategoryToDiscount: PropTypes.func.isRequired,
    addProductToDiscount: PropTypes.func.isRequired,
    discountCategories: PropTypes.array.isRequired,
    discountProducts: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    menu: PropTypes.object.isRequired,
    removeCategoryFromDiscount: PropTypes.func.isRequired,
    removeProductFromDiscount: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
  };

  addCategoryDiscount = () =>
    this.props.addCategoryToDiscount(
      this.state.selectedCategory.name,
      this.state.discountCategoryPercent
    );
  addProductDiscount = () =>
    this.props.addProductToDiscount(
      this.state.selectedProduct.name,
      this.state.discountProductPercent
    );

  seDiscountCategoryPercent = (e) =>
    this.setState({ discountCategoryPercent: parseInt(e.target.value, 10) });
  seDiscountProductPercent = (e) =>
    this.setState({ discountProductPercent: parseInt(e.target.value, 10) });

  render() {
    const { isAdmin, isSubmitting, handleChange, menu, values } = this.props;
    const { removeCategoryFromDiscount, removeProductFromDiscount } =
      this.props;
    const categories = (menu && menu.categories) || [];
    const allProducts = categories.map((c) => c.items).flat();
    return (
      <Row className="mb-2">
        <Col>
          <CollapsibleCard
            title={
              <div>
                <Tag /> {i18next.t('discounts.title')}
              </div>
            }
            bodyClassName="pt-0"
            openByDefault
          >
            <hr className="mt-0" />
            <CardTitle tag="h4">{i18next.t('discounts.whole-menu')}</CardTitle>
            <Row>
              <Col>
                <OtoFormGroup
                  label={i18next.t('discounts.percent-value-title')}
                  name="discountPercent"
                  onChange={handleChange}
                  tooltipText={i18next.t('discounts.0-stands-for-no-discount')}
                  value={values.discountPercent}
                  inputProps={{
                    ...percentNumberInputProps,
                    className: 'mw-175',
                  }}
                />
                <OtoFormGroup
                  label={i18next.t('discounts.percent-value-title-if-takeout')}
                  name="discountPercentIfTakeout"
                  onChange={handleChange}
                  tooltipText={i18next.t('discounts.0-stands-for-no-discount')}
                  value={values.discountPercentIfTakeout}
                  inputProps={{
                    ...percentNumberInputProps,
                    className: 'mw-175',
                  }}
                />
                {!!values.discountPercent &&
                  !!values.discountPercentIfTakeout && (
                    <div className="text-danger mb-3">
                      {i18next.t('discounts.alert-discounts-work-together')}
                    </div>
                  )}
                <FormGroup check>
                  <Label>
                    <Input
                      type="checkbox"
                      name="roundDiscount"
                      checked={values.roundDiscount || false}
                      onChange={handleChange}
                    />
                    {i18next.t('discounts.ask-if-round-prices')}
                  </Label>
                </FormGroup>
                {isAdmin && (
                  <FormGroup>
                    <Label>Tekst promocji</Label>
                    <Input
                      type="textarea"
                      name="discountText"
                      value={values.discountText || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
            <hr className="mt-3" />
            <CardTitle tag="h4">
              <div>{i18next.t('discounts.second-from-category')}</div>
              <small>{i18next.t('discounts.second-from-category__hint')}</small>
            </CardTitle>
            <Row>
              <Col className="br-1">
                <OtoFormGroup
                  className="mt-150"
                  name="discount-category-percent"
                  label={i18next.t('discounts.percent-value-title')}
                  value={this.state.discountCategoryPercent}
                  onChange={this.seDiscountCategoryPercent}
                  inputProps={percentNumberInputProps}
                />
                <Label>{i18next.t('discounts.select-category')}</Label>
                <select
                  className="form-control mb-2"
                  onChange={(e) =>
                    this.setState({
                      selectedCategory: categories[e.target.value],
                    })
                  }
                >
                  <option value=""></option>
                  {categories.map((c, index) => (
                    <option key={c.name} value={index}>
                      {c.name}
                    </option>
                  ))}
                </select>
                {this.state.selectedCategory ? (
                  <Button
                    type="button"
                    color="primary"
                    onClick={this.addCategoryDiscount}
                  >
                    {i18next.t('discounts.add-discount')}
                  </Button>
                ) : (
                  <Button type="button" disabled>
                    {i18next.t('discounts.add-discount')}
                  </Button>
                )}
              </Col>
              <Col>
                <div className="bb-1">Rabaty na drugi produkt w kategorii</div>
                {this.props.discountCategories &&
                this.props.discountCategories.length
                  ? this.props.discountCategories.map((c, index) => (
                      <div className="mt-2" key={c.categoryName}>
                        -{c.percent}% na drugi produkt w kategorii{' '}
                        {c.categoryName}
                        <div
                          className="tag"
                          onClick={() => removeCategoryFromDiscount(index)}
                        >
                          X
                        </div>
                      </div>
                    ))
                  : 'Brak'}
              </Col>
            </Row>
            <hr className="mt-3" />
            <CardTitle tag="h4">
              <div>{i18next.t('discounts.second-same-product')}</div>
              <small>{i18next.t('discounts.second-same-product__hint')}</small>
            </CardTitle>
            <Row>
              <Col className="br-1">
                <OtoFormGroup
                  className="mw-150"
                  name="discount-product-percent"
                  label={i18next.t('discounts.percent-value-title')}
                  value={this.state.discountProductPercent}
                  onChange={this.seDiscountProductPercent}
                  inputProps={percentNumberInputProps}
                />
                <Label>{i18next.t('discounts.select-product')}</Label>
                <select
                  className="form-control mb-2"
                  onChange={(e) =>
                    this.setState({
                      selectedProduct: allProducts[e.target.value],
                    })
                  }
                >
                  <option value=""></option>
                  {allProducts.map((product, index) => (
                    <option key={product.name} value={index}>
                      {product.name}
                    </option>
                  ))}
                </select>
                {this.state.selectedProduct ? (
                  <Button
                    type="button"
                    color="primary"
                    onClick={this.addProductDiscount}
                  >
                    {i18next.t('discounts.add-discount')}
                  </Button>
                ) : (
                  <Button type="button" disabled>
                    {i18next.t('discounts.add-discount')}
                  </Button>
                )}
              </Col>
              <Col>
                <div className="bb-1">
                  Rabaty na drugą sztukę takiego samego produktu
                </div>
                {this.props.discountProducts &&
                this.props.discountProducts.length
                  ? this.props.discountProducts.map((item, index) => (
                      <div key={item.productName}>
                        -{item.percent}% na drugi {item.productName}
                        <div
                          className="tag"
                          onClick={() => removeProductFromDiscount(index)}
                        >
                          X
                        </div>
                      </div>
                    ))
                  : 'Brak'}
              </Col>
            </Row>
            {isSubmitting ? (
              <OtoSpinner className="mt-2" />
            ) : (
              <Button className="mt-3" type="submit">
                {i18next.t('Save')}
              </Button>
            )}
          </CollapsibleCard>
        </Col>
      </Row>
    );
  }
}
