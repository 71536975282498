import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import ReservationAlerts from 'app/components/reservations/ReservationAlerts';
import {
  getRecentlyDeclinedReservations,
  getNewlyCreatedReservations,
} from 'utils/reservation';
import { selectRestaurant } from 'store/restaurant';
import { IAppState } from 'store/main';
import type { ISavedReservation, ReservationConfig } from 'types';

type ReservationAlertsContainerProps = PropsFromRedux;
type ReservationAlertsContainerState = {
  alertReservations: ISavedReservation[];
};
class ReservationAlertsContainer extends React.Component<
  ReservationAlertsContainerProps,
  ReservationAlertsContainerState
> {
  _isMounted = false;
  state: ReservationAlertsContainerState = { alertReservations: [] };

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(prevProps: ReservationAlertsContainerProps) {
    if (this.props.reservations !== prevProps.reservations) {
      this.checkNewReservation(this.props.reservations, prevProps.reservations);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkNewReservation = (oldReservations: ISavedReservation[], newReservations: ISavedReservation[]) => {
    const todayRecentlyDeclinedReservations = getRecentlyDeclinedReservations(
      oldReservations,
      newReservations
    );
    const todayNewReservations = getNewlyCreatedReservations(
      oldReservations,
      newReservations
    );
    const alertReservations = [
      ...this.state.alertReservations,
      ...todayNewReservations,
      ...todayRecentlyDeclinedReservations,
    ];
    this._isMounted && this.setState({ alertReservations });
    if (
      alertReservations.length &&
      !this.props.restaurantConfig.mute_notification_sound
    ) {
      window.scrollTo(0, 0);
    }
  };

  removeAlert = (index) => {
    const { alertReservations } = this.state;
    const newAlertReservations = [
      ...alertReservations.slice(0, index),
      ...alertReservations.slice(index + 1),
    ];
    this.setState({ alertReservations: newAlertReservations });
  };

  render() {
    return (
      <ReservationAlerts
        reservations={this.state.alertReservations}
        removeAlert={this.removeAlert}
      />
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  reservations: state.reservations.reservations,
  restaurantConfig: selectRestaurant(state)?.config as ReservationConfig,
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReservationAlertsContainer);
