import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { checkToken, checkAppVersion } from 'services/auth';
import {
  addTable,
  addPlace,
  selectPlaces,
  selectPlacesLoading,
  loadRestaurantPlaces,
  updateReservationsConfig,
  updateRestaurantTable,
  updateRestaurantPlace,
  selectRestaurant,
  selectBookingConfig,
  selectRestaurantTables,
} from 'store/restaurant';
import { whatHasChanged } from 'utils/general';
import { logActivity, LOG_BAG, formatChangedKeys } from 'utils/log';
import { checkBoxStyle, col8Style, col10Style } from '../styles';
import UpdatesProvider from '../containers/UpdatesProvider';
import AddTableComponent from 'app/components/tables/AddTableComponent';
import TablesSettings from 'app/components/tables/TablesSettings';
import PlacesListComponent from 'app/components/places/PlacesListComponent';
import AddPlaceComponent from 'app/components/places/AddPlaceComponent';
import IconWithTooltip from 'app/components/common/IconWithTooltip';
import InputWithAddon from 'app/components/common/InputWithAddon';
import SubmitButtonWithLoader from 'app/components/common/SubmitButtonWithLoader';
import PreviewLink, { RestaurantPreviewType } from 'app/views/ViewPreviewLink';
import {
  IReservationDurationOption,
  ModalTypes,
  possibleReservationDurations,
} from 'config';
import {
  IPlace,
  ITable,
  ITableWithPlace,
  NormalizedRestaurant,
  PlaceDTO,
  ReservationConfig,
  TableDTO,
} from 'types';
import { UpdateTarget } from 'enums';
import withSetModal, { TSetModalFunction } from '../containers/WithSetModal';
import {
  CollapsibleCard,
  OtoButtons,
  OtoChip,
  OtoSpinner,
} from 'app/components/common';

import 'app/components/settings-form.scss';
import ReservationSettingsDuration from 'app/components/settings/ReservationSettingsDuration';
import { selectIsRootAdmin } from 'store/app';
import ReservationSettingsMaxPeople from 'app/components/settings/ReservationSettingsMaxPeople';

type ReservationSettingsFormValues = {
  assigneeList: string[] | null;
  allowReservationsRightBeforeOpening: boolean;
  autoReservationMaxGuests: number;
  autoReservationMaxGuestsLabel: string;
  flexibleTables: boolean;
  hasAreaPreferencePossibility: boolean;
  reservationDuration: number;
  reservationDurationOptionsForRestaurant: IReservationDurationOption[];
  reservationDurationOptionsForGuests: IReservationDurationOption[];
  daysInAdvance: number;
  hoursInAdvance: number;
  muteNotificationSound: boolean;
  ask_about_non_vaccinated_guest_amount: boolean;
  reservationsWarning: string;
  smsNotificationsForNewOrDeclinedBookings: boolean;
  smsNotificationsPhoneNumbers: string;
  // deprecated - simple booking (no tables manager) only
  availableSeats: number;
  availableTables: number;
};

type IOwnProps = {};

type PropsFromSetModal = {
  setModal: TSetModalFunction;
};

type TPropsWithoutFormik = IOwnProps & PropsFromRedux & PropsFromSetModal;

type TProps = FormikProps<ReservationSettingsFormValues> & TPropsWithoutFormik;

interface IState {
  configHashCheckLoading: boolean;
  configHashCheckFinished: boolean;
  tablesDict: Record<number, string>;
  tablesLoaded: boolean;
}

class ReservationSettingsPage extends React.PureComponent<TProps, IState> {
  _isMounted = false;

  state: IState = {
    configHashCheckLoading: true,
    configHashCheckFinished: false,
    tablesDict: {},
    tablesLoaded: false,
  };

  componentDidMount() {
    checkAppVersion();
    checkToken();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  init = ({ wasUpToDate }: { wasUpToDate: boolean }) => {
    if (!wasUpToDate) {
      window.location.reload();
    }
    this.mapConfigToFields();
    this._isMounted &&
      this.setState({
        configHashCheckLoading: false,
        configHashCheckFinished: true,
      });
  };

  mapConfigToFields = () => {
    const { config } = this.props;
    const { flexible_tables } = config;
    if (flexible_tables !== false && flexible_tables !== true) {
      LOG_BAG.logImportantActivity(
        'flexible_tables is not boolean in ReservationSettingsPage'
      );
    }
    if (flexible_tables) {
      this.props.loadRestaurantPlaces(this.props.restaurant);
      this.getTablesConfig();
    }
  };

  getTablesConfig = () => {
    const { tablesConfig } = this.props;
    if (!tablesConfig) {
      return;
    }
    const tablesDict = {};
    tablesConfig.forEach(
      (table: ITable) => (tablesDict[table.id] = table.name)
    );
    this._isMounted &&
      this.setState({
        tablesDict,
        tablesLoaded: true,
      });
  };

  addPlace = (placeData: PlaceDTO) =>
    this.props.addPlace(this.props.restaurant, placeData);

  addTable = (tableData: TableDTO) =>
    this.props.addTable(this.props.restaurant, tableData);

  handleTableUpdate = (tableData: ITable) =>
    this.props.updateRestaurantTable(this.props.restaurant, tableData);

  handlePlaceUpdate = (placeData: IPlace) =>
    this.props.updateRestaurantPlace(this.props.restaurant, placeData);

  updateReservationDurationForRestaurant = (
    selectedValues: IReservationDurationOption[]
  ) =>
    this.props.setFieldValue(
      'reservationDurationOptionsForRestaurant',
      selectedValues
    );

  updateReservationDurationForGuests = (
    selectedValues: IReservationDurationOption[]
  ) =>
    this.props.setFieldValue(
      'reservationDurationOptionsForGuests',
      selectedValues
    );

  render() {
    if (this.state.configHashCheckLoading) {
      return (
        <>
          <OtoSpinner center />
          {!this.state.configHashCheckFinished && (
            <UpdatesProvider
              restaurant={this.props.restaurant}
              target={UpdateTarget.ReservationConfig}
              onInit={this.init}
            />
          )}
        </>
      );
    }
    const { handleChange, handleSubmit, places, restaurant, values } = this.props;
    return (
      <Row>
        <Col md={col8Style}>
          <PreviewLink
            color="link"
            className="px-0"
            restaurant={restaurant}
            type={RestaurantPreviewType.Resevations}
          />
          <Card>
            <CardBody>
              {
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label className="mb-0">{i18next.t('reservation-settings.header')}</Label>
                  </FormGroup>
                  <ReservationSettingsDuration
                    handleChange={handleChange}
                    values={values}
                    updateReservationDurationForRestaurant={
                      this.updateReservationDurationForRestaurant
                    }
                    updateReservationDurationForGuests={
                      this.updateReservationDurationForGuests
                    }
                  />
                  <hr />
                  {values.flexibleTables ? null : (
                    <>
                      <FormGroup>
                        <Label for="availableSeats">
                          {i18next.t('reservation-settings.available-seats')}
                        </Label>
                        <Input
                          type="number"
                          name="availableSeats"
                          id="availableSeats"
                          placeholder="10"
                          onChange={handleChange}
                          value={values.availableSeats}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="availableTables">
                          {i18next.t('reservation-settings.available-tables')}
                        </Label>
                        <Input
                          type="number"
                          name="availableTables"
                          id="availableTables"
                          placeholder="10"
                          onChange={handleChange}
                          value={values.availableTables}
                        />
                      </FormGroup>
                    </>
                  )}
                  <FormGroup>
                    <Label for="daysInAdvance">
                      {i18next.t('reservation-settings.days-in-advance.label')}{' '}
                      <IconWithTooltip
                        id="res-min-advance"
                        text={i18next.t('reservation-settings.days-in-advance.tooltip')}
                      />
                    </Label>
                    <InputWithAddon
                      addonText={i18next.t('input-addons.days', { count: values.daysInAdvance })}
                      type="number"
                      step="1"
                      min="0"
                      className="mw-70"
                      name="daysInAdvance"
                      id="daysInAdvance"
                      onChange={handleChange}
                      value={values.daysInAdvance}
                    />
                  </FormGroup>
                  {values.daysInAdvance === 0 ? (
                    <FormGroup>
                      <Label for="hoursInAdvance">
                        {i18next.t('reservation-settings.hours-in-advance.label')}
                      </Label>
                      <InputWithAddon
                        addonText={
                          values.hoursInAdvance > 4 ? 'godzin' : 'godziny'
                        }
                        type="number"
                        step="1"
                        min="0"
                        className="mw-70"
                        name="hoursInAdvance"
                        id="hoursInAdvance"
                        onChange={handleChange}
                        value={values.hoursInAdvance}
                      />
                    </FormGroup>
                  ) : null}
                  <ReservationSettingsMaxPeople
                    disabled={!this.props.isAdmin}
                    handleChange={handleChange}
                    restaurant={restaurant}
                    values={values}
                  />
                  {this.renderAssigneeList()}
                  <FormGroup>
                    <Label className="w-100 text-left">
                      <span>{i18next.t('reservation-settings.reservations-warning.label')}</span>
                      <Input
                        type="textarea"
                        placeholder={i18next.t('reservation-settings.reservations-warning.placeholder')}
                        rows="3"
                        name="reservationsWarning"
                        onChange={handleChange}
                        value={values.reservationsWarning}
                      />
                    </Label>
                  </FormGroup>
                  <ReservationSettingsCheckboxGroup
                    isChecked={values.flexibleTables}
                    fieldName="flexibleTables"
                    label={i18next.t('reservation-settings.flexible-tables')}
                    onChange={handleChange}
                    style={checkBoxStyle}
                  />
                  <ReservationSettingsCheckboxGroup
                    isChecked={values.allowReservationsRightBeforeOpening}
                    fieldName="allowReservationsRightBeforeOpening"
                    label={i18next.t('reservation-settings.allow-reservations-right-before-opening')}
                    onChange={handleChange}
                    style={checkBoxStyle}
                  />
                  <ReservationSettingsCheckboxGroup
                    isChecked={!!values.ask_about_non_vaccinated_guest_amount}
                    fieldName="ask_about_non_vaccinated_guest_amount"
                    label={i18next.t('reservation-settings.ask-about-non-vaccinated-guest-amount')}
                    onChange={handleChange}
                    style={checkBoxStyle}
                  />
                  {
                    places?.length > 1 && (
                      <ReservationSettingsCheckboxGroup
                      isChecked={values.hasAreaPreferencePossibility}
                      fieldName="hasAreaPreferencePossibility"
                      label={i18next.t('reservation-settings.has-area-preference-possibility')}
                      onChange={handleChange}
                      style={checkBoxStyle}
                    />
                    )
                  }
                  <ReservationSettingsCheckboxGroup
                    isChecked={!!values.muteNotificationSound}
                    fieldName="muteNotificationSound"
                    label={i18next.t('reservation-settings.mute-notification-sound')}
                    onChange={handleChange}
                    style={checkBoxStyle}
                  />
                  <ReservationSettingsCheckboxGroup
                    fieldName="smsNotificationsForNewOrDeclinedBookings"
                    label={i18next.t('reservation-settings.sms-notifications-for-new-or-declined-bookings')}
                    onChange={handleChange}
                    style={checkBoxStyle}
                    isChecked={
                      !!values.smsNotificationsForNewOrDeclinedBookings
                    }
                  />
                  {values.smsNotificationsForNewOrDeclinedBookings && (
                    <FormGroup>
                      <Label className="w-100 text-left mt-2">
                        <span>
                          {i18next.t('reservation-settings.sms-notifications-phone-numbers.label')}
                        </span>
                        <Input
                          type="textarea"
                          placeholder={i18next.t('reservation-settings.sms-notifications-phone-numbers.placeholder')}
                          rows="3"
                          name="smsNotificationsPhoneNumbers"
                          onChange={handleChange}
                          value={values.smsNotificationsPhoneNumbers}
                        />
                      </Label>
                    </FormGroup>
                  )}
                  {(values.reservationDurationOptionsForRestaurant || [])
                    .length === 0 && (
                    <div className="mt-3 text-danger font-weight-bold">
                      {i18next.t('reservation-settings.errors.no-reservation-duration-options-for-restaurant')}
                    </div>
                  )}
                  {(values.reservationDurationOptionsForGuests || []).length ===
                    0 && (
                    <div className="mt-3 text-danger font-weight-bold">
                      {i18next.t('reservation-settings.errors.no-reservation-duration-options-for-guests')}
                    </div>
                  )}
                  <SubmitButtonWithLoader
                    className="mt-3"
                    loading={this.props.isSubmitting}
                  />
                </Form>
              }
            </CardBody>
          </Card>
        </Col>
        {this.props.values.flexibleTables && this.renderTableSettings()}
      </Row>
    );
  }

  renderAssigneeList() {
    const { setModal, values } = this.props;
    const handleAddAssignee = () => {
      setModal(
        {
          title: 'Podaj imię / nazwisko osoby przyjmującej',
          confirm: (newAssignee: string) => {
            this.props.setFieldValue('assigneeList', [
              ...(values.assigneeList || []),
              newAssignee,
            ]);
          },
          confirmColor: 'info',
          confirmText: 'Dodaj',
        },
        ModalTypes.INPUT
      );
    };
    return (
      <CollapsibleCard
        bodyClassName={'align-vertical flex-wrap'}
        title={
          <div>
            {i18next.t('reservation-settings.assignee-list.label')}
            <IconWithTooltip
              id="assignee-list-tooltip"
              className="ml-2"
              text={i18next.t('reservation-settings.assignee-list.tooltip')}
            />
          </div>
        }
      >
        {!!values.assigneeList?.length ? (
          values.assigneeList.map((assignee) => (
            <OtoChip
              key={assignee}
              label={assignee}
              isSelected
              onClick={() =>
                this.props.setFieldValue(
                  'assigneeList',
                  values.assigneeList!.filter((a) => a !== assignee)
                )
              }
            />
          ))
        ) : (
          <span>{i18next.t('reservation-settings.assignee-list.no-select-options')}</span>
        )}
        <OtoButtons.AddButton onClick={handleAddAssignee}>
          {i18next.t('reservation-settings.assignee-list.add-new')}
        </OtoButtons.AddButton>
      </CollapsibleCard>
    );
  }

  renderTableSettings() {
    const { places, placesLoading, restaurant } = this.props;
    const tables = this.props.tablesConfig;
    const tablesByIds = this.state.tablesDict;
    return (
      <Col key="flex-tables" lg={col10Style}>
        <Card>
          <CardHeader>{i18next.t('table-settings.header')}</CardHeader>
          <CardBody className="table-settings pt-0">
            {tables ? (
              <TablesSettings
                onTableUpdate={this.handleTableUpdate}
                tables={tables}
                tablesByIds={tablesByIds}
              />
            ) : (
              <div className="font-weight-bold">{i18next.t('reservation-settings.table-settings.no-data-message')}</div>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardHeader>{i18next.t('place-settings.header')}</CardHeader>
          <CardBody className="table-settings pt-0">
            {places ? (
              <PlacesListComponent
                onPlaceUpdate={this.handlePlaceUpdate}
                places={places}
                placesLoading={placesLoading}
              />
            ) : (
              <OtoSpinner />
            )}
          </CardBody>
        </Card>
        {places && (
          <AddPlaceComponent
            onAddPlace={this.addPlace}
            restaurant={restaurant}
            places={places}
          />
        )}
        {places && (
          <AddTableComponent
            onAddTable={this.addTable}
            restaurant={restaurant}
            places={places}
          />
        )}
      </Col>
    );
  }
}

const mapPropsToValues = (props: TPropsWithoutFormik): ReservationSettingsFormValues => {
  const { config } = props;
  const {
    assignee_list,
    available_seats,
    available_tables,
    auto_confirm_before,
    flexible_tables,
  } = config;
  if (flexible_tables !== false && flexible_tables !== true) {
    LOG_BAG.logImportantActivity(
      'flexible_tables is not boolean in ReservationSettingsPage'
    );
  }
  const daysInAdvance = parseInt((auto_confirm_before / 24).toString(), 10);
  return {
    assigneeList: assignee_list || null,
    allowReservationsRightBeforeOpening:
      config.allow_reservations_right_before_opening || false,
    autoReservationMaxGuests: config.auto_reservation_max_guests || 8,
    autoReservationMaxGuestsLabel:
      config.auto_reservation_max_guests_label || '',
    daysInAdvance: daysInAdvance,
    flexibleTables: flexible_tables,
    hoursInAdvance: auto_confirm_before - daysInAdvance * 24,
    reservationDuration: config.reservation_duration || 2,
    hasAreaPreferencePossibility:
      config.has_area_preference_possibility || false,
    muteNotificationSound: config.mute_notification_sound || false,
    ask_about_non_vaccinated_guest_amount:
      config.ask_about_non_vaccinated_guest_amount || false,
    reservationsWarning: config.reservations_warning || '',
    smsNotificationsForNewOrDeclinedBookings:
      config.sms_notifications_for_new_or_declined_bookings || false,
    smsNotificationsPhoneNumbers: config.sms_notifications_phone_numbers || '',
    reservationDurationOptionsForGuests:
      possibleReservationDurations.filter((opt) =>
        (config.reservation_duration_options_for_guests || []).includes(
          opt.value
        )
      ) || [],
    reservationDurationOptionsForRestaurant:
      possibleReservationDurations.filter((opt) =>
        (config.reservation_duration_options || []).includes(opt.value)
      ) || [],
    // deprecated - simple booking (no tables manager) only
    availableTables: available_tables || 10,
    availableSeats: available_seats || 40,
  };
};

const handleSubmit = (
  values: ReservationSettingsFormValues,
  { setSubmitting, props }: FormikBag<TPropsWithoutFormik, ReservationSettingsFormValues>
) => {
  const formattedValues: ReservationConfig = {
    ...props.config,
    assignee_list: values.assigneeList,
    allow_reservations_right_before_opening:
      values.allowReservationsRightBeforeOpening || false,
    auto_confirm_before: values.daysInAdvance
      ? values.daysInAdvance * 24
      : values.hoursInAdvance,
    auto_reservation_max_guests: values.autoReservationMaxGuests,
    auto_reservation_max_guests_label: values.autoReservationMaxGuestsLabel,

    reservation_duration: values.reservationDuration,
    reservation_duration_options_for_guests:
      values.reservationDurationOptionsForGuests.map((option) => option.value),
    reservation_duration_options:
      values.reservationDurationOptionsForRestaurant.map(
        (option) => option.value
      ),

    flexible_tables: values.flexibleTables,
    has_assignee: (values.assigneeList || []).length > 0,
    has_area_preference_possibility: values.hasAreaPreferencePossibility,

    mute_notification_sound: values.muteNotificationSound,
    ask_about_non_vaccinated_guest_amount:
      values.ask_about_non_vaccinated_guest_amount,
    reservations_warning: values.reservationsWarning,
    sms_notifications_for_new_or_declined_bookings:
      values.smsNotificationsForNewOrDeclinedBookings,
    sms_notifications_phone_numbers: values.smsNotificationsPhoneNumbers,
    // deprecated - simple booking (no tables manager) only
    available_seats: values.availableSeats,
    available_tables: values.availableTables,
  };

  const payload = {
    config: formattedValues,
  };
  const keysThatChanged = whatHasChanged<ReservationConfig>(
    props.config,
    formattedValues
  );

  const onSuccess = () => {
    logActivity(
      `Ustawienia zostały zaktualizowane. ${formatChangedKeys(
        keysThatChanged,
        props.config,
        formattedValues
      )}`
    );
    toast.success(i18next.t('reservation-settings.toasts.update-success'));
    if (
      keysThatChanged.includes('flexible_tables') &&
      formattedValues.flexible_tables
    ) {
      window.location.reload();
    }
  };

  if (keysThatChanged.length === 0) {
    toast.error(
      i18next.t('reservation-settings.toasts.error.nothing-was-changed')
    );
    setSubmitting(false);
    return;
  }

  return props
    .updateReservationsConfig(payload, props.restaurant, onSuccess)
    .finally(() => setSubmitting(false));
};

const ReservationSettingsPageWithFormik = withFormik<TProps, ReservationSettingsFormValues>({
  mapPropsToValues,
  handleSubmit,
  displayName: 'ReservationSettingsPage',
})(ReservationSettingsPage);

const mapStateToProps = (state) => ({
  isAdmin: selectIsRootAdmin(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
  config: selectBookingConfig(state),
  tablesConfig: selectRestaurantTables(state) as ITableWithPlace[],
  places: selectPlaces(state),
  placesLoading: selectPlacesLoading(state),
});

const mapDispatchToProps = {
  addTable,
  addPlace,
  loadRestaurantPlaces,
  updateReservationsConfig,
  updateRestaurantTable,
  updateRestaurantPlace,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withSetModal<TProps>(
  connector(ReservationSettingsPageWithFormik)
);

function ReservationSettingsCheckboxGroup({
  className = 'mt-2',
  fieldName,
  isChecked,
  label,
  onChange,
  style = {},
}) {
  return (
    <FormGroup check className={className}>
      <Label check>
        <Input
          type="checkbox"
          checked={isChecked}
          name={fieldName}
          style={{
            ...checkBoxStyle,
            ...style,
          }}
          onChange={onChange}
        />{' '}
        {label}
      </Label>
    </FormGroup>
  );
}
