import React, { ReactEventHandler } from 'react';
import PropTypes from 'prop-types';
import { Input, FormGroup, Label } from 'reactstrap';
import i18next from 'i18next';

import { UsageType } from 'types/promo-code';

export const usageTypeOptions = [
  {
    name: 'promocode.usage-type.single',
    value: UsageType.Single,
  },
  {
    name: 'promocode.usage-type.single-use-per-customer',
    value: UsageType.SinglePerCustomer,
  },
  {
    name: 'promocode.usage-type.for-new-customers',
    value: UsageType.ForNewCustomer,
  },
  {
    name: 'promocode.usage-type.multiple',
    value: UsageType.Multiple,
  },
];

const usageTypeOption = (option) => {
  return (
    <option key={option.value} value={option.value}>
      {i18next.t(option.name)}
    </option>
  );
};

const UsageTypeSelect = (props: {
  value: string;
  onChange: ReactEventHandler;
}) => {
  const { value, onChange } = props;

  return (
    <FormGroup>
      <Label>{i18next.t('promocode.usage-type-label')}</Label>
      <Input
        type="select"
        name="usage_type"
        value={value}
        onChange={onChange}
        className="mw-300"
      >
        {usageTypeOptions.map(usageTypeOption)}
      </Input>
    </FormGroup>
  );
};

UsageTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UsageTypeSelect;
