import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18 from 'i18next';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
} from 'reactstrap';
import { toast } from 'react-toastify';

import CollapsibleCard from '../common/CollapsibleCard';
import InputWithAddon from '../common/InputWithAddon';
import LocationSearchInput from '../orders/LocationSearchInput';
import Restaurant from 'utils/restaurant';
import { logError } from 'utils/log';
import { currency } from 'globals/currency';
import {
  ModalTypes,
  isStageHost,
  isLocalHost,
} from 'config';
import { OTO_PRODUCTS, EPaymentType } from 'enums';
import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import { driverTypeByProduct } from 'utils/deliveries';
import { IDelivery, IDeliveryCreateData } from 'types/delivery';
import { NormalizedRestaurant } from 'types/restaurants';
import Shapes from 'shapes/main';
import { DriverType } from 'enums/delivery';

interface IAddress {
  city: string;
  street: string;
  homeNumber: string;
  flatNumber?: string | null;
  lng: number | string;
  lat: number | string;
}
const DEFAULT_ADDRESS: IAddress = {
  city: '',
  street: '',
  homeNumber: '',
  flatNumber: '',
  lng: '',
  lat: '',
};

const testAddresses: IAddress[] = [
  {
    street: 'Cicha',
    homeNumber: '3',
    lng: 22.5552004,
    lat: 51.249833,
    city: 'Lublin',
  },
  {
    street: 'Bolesława Śmiałego',
    homeNumber: '8',
    lng: 22.5369216,
    lat: 51.2395188,
    city: 'Lublin',
  },
  {
    street: 'Ludowa',
    homeNumber: '15',
    lng: 22.5847371,
    lat: 51.2636578,
    city: 'Lublin',
  },
  {
    street: 'Chodźki',
    homeNumber: '27',
    lng: 22.5625304,
    lat: 51.2650119,
    city: 'Lublin',
  },
  {
    street: 'Szmaragdowa',
    homeNumber: '46',
    lng: 22.5131651,
    lat: 51.2271064,
    city: 'Lublin',
  },
];

interface ICallForCourrierProps {
  addDelivery: (
    data: IDeliveryCreateData,
    restaurant: NormalizedRestaurant
  ) => Promise<IDelivery>;
  isAdmin: boolean;
  restaurant: NormalizedRestaurant;
  setModal: TSetModalFunction;
}

const CallForCourrierForm: React.FC<ICallForCourrierProps> = (props) => {
  const { addDelivery, isAdmin, restaurant } = props;

  const availableDriverTypes = useMemo(
    () =>
      Object.entries(driverTypeByProduct)
        .filter(([product, driverType]) =>
          Restaurant.hasProduct(restaurant, product)
        )
        .map(([product, driverType]) => driverType),
    [restaurant]
  );

  const [address, setAddress] = useState(DEFAULT_ADDRESS);
  const [showValidation, setShowValidation] = useState(false);
  const [phone, setPhone] = useState('');
  const [payment, setPayment] = useState(EPaymentType.CARD_ON_PLACE);
  const [price, setPrice] = useState('');
  const [driverType, setDriverType] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setDriverType(availableDriverTypes[0]);
  }, [availableDriverTypes]);

  if (!Restaurant.canOrderDelivery(restaurant)) {
    return null;
  }

  const resetToDefault = () => {
    setAddress(DEFAULT_ADDRESS);
    setPhone('');
    setPrice('');
    setNotes('');
  };

  const errorClassName = 'text-danger mb-2';

  const isEmptyPriceError = price === '' && payment !== EPaymentType.PAID;
  const isDriverTypeError =
    driverType === DriverType.Robot && payment !== EPaymentType.PAID;

  const handleCallCourrierClick = () => {
    if (
      !address.street ||
      !address.homeNumber ||
      !phone ||
      isEmptyPriceError ||
      isDriverTypeError
    ) {
      setShowValidation(true);
      return null;
    } else if (showValidation) {
      setShowValidation(false);
    }

    const flatPart = address.flatNumber ? `/${address.flatNumber}` : '';
    const addressStr = `${address.city}, ${address.street} ${address.homeNumber}${flatPart}`;

    props.setModal(
      {
        ...getCommonCallForCourrierProps({
          isFoodeli: restaurant.products.includes(
            OTO_PRODUCTS.foodeli_deliveries
          ),
          isRobot: driverType === DriverType.Robot,
        }),
        // @ts-ignore fix types, this should be working
        confirm: (informBeforeTime: string, deliveryAtTime: string) => {
          const payload: IDeliveryCreateData = {
            type: 'external',
            driver_type: driverType,
            ...(deliveryAtTime
              ? { delivery_at: deliveryAtTime }
              : { inform_before: parseInt(informBeforeTime) }),
            address: addressStr,
            price,
            payment_status: payment,
            lng: address.lng,
            lat: address.lat,
            phone,
            notes,
          };

          addDelivery(payload, restaurant)
            .then((newDelivery: IDelivery) => {
              toast.success('Kurier wezwany');
              resetToDefault();
            })
            .catch((e) => {
              logError('call for courrier error', e);
              toast.error('Wystąpił błąd podczas wzywania kuriera');
            });
        },
      },
      ModalTypes.SELECT
    );
  };

  return (
    <CollapsibleCard
      title={i18.t('delivery.call-driver.call-external-delivery')}
    >
      <CardBody className="row">
        {(isAdmin || isStageHost() || isLocalHost()) && (
          <Col xs="12" className="row mx-0 align-items-start">
            <p className="text-left w-100">
              Jako admin, lub na testowym środowisku możesz uży testowego
              adresu, aby nie zużywać Google Maps quota.
            </p>
            {testAddresses.map((addr) => (
              <Card
                key={addr.street}
                tag="button"
                className="mw-250 p-2 text-center mr-2"
                onClick={() => setAddress({ ...addr, flatNumber: '' })}
              >
                {addr.street} {addr.homeNumber}, {addr.city}
              </Card>
            ))}
          </Col>
        )}
        <Col xs="12" md="4">
          <FormGroup>
            <Label className="row">{i18.t('delivery.address')}</Label>
            <LocationSearchInput
              address={address}
              onAddressUpdate={setAddress}
              restaurant={restaurant}
            />
          </FormGroup>
          <FormGroup>
            <Label className="row">{i18.t('delivery.customer-phone')}</Label>
            <Row>
              <Input
                type="tel"
                placeholder="np. 791222123"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Row>
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup>
            <Label>
              <Label>{i18.t('delivery.payment-type')}</Label>
            </Label>
            <Input
              type="select"
              value={payment}
              onChange={(e) => setPayment(e.target.value as EPaymentType)}
            >
              <option value={EPaymentType.CASH}>Gotówką przy odbiorze</option>
              <option value={EPaymentType.CARD_ON_PLACE}>
                Kartą przy odbiorze
              </option>
              <option value={EPaymentType.PAID}>Opłacone</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>{i18.t('delivery.price')}</Label>
            <InputWithAddon
              addonText={currency}
              id="price"
              name="price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>{i18.t('delivery.driverType')}</Label>
            <Input
              addonText={currency}
              id="driverType"
              name="driverType"
              type="select"
              value={driverType}
              onChange={(e) => setDriverType(e.target.value)}
            >
              {availableDriverTypes.map((driverType) => (
                <option value={driverType} key={driverType}>
                  {i18.t(`delivery.driver-types.${driverType}`)}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup>
            <Label>{i18.t('delivery.notes')}</Label>
            <Input
              type="textarea"
              value={notes}
              placeholder="np. piętro, kod do bramki, lub życzenia klienta"
              onChange={(e) => setNotes(e.target.value)}
            />
          </FormGroup>
          {showValidation && (
            <>
              {(!address.lat || !address.lng) && (
                <div className={errorClassName}>
                  Współrzędne adresu nie zostały pobrane. Kliknij w pozycję z
                  listy rozwijanej, wpisując ulicę, aby pobrać współrzędne tego
                  adresu.
                </div>
              )}
              {!address.street && (
                <div className={errorClassName}>Pole ulica jest wymagane</div>
              )}
              {!address.homeNumber && (
                <div className={errorClassName}>
                  Pole numer budynku jest wymagane
                </div>
              )}
              {!phone && (
                <div className={errorClassName}>Pole telefon jest wymagane</div>
              )}
              {isEmptyPriceError && (
                <div className={errorClassName}>
                  Pole kwota do zapłaty jest wymagane
                </div>
              )}
              {isDriverTypeError && (
                <div className={errorClassName}>
                  Dostawa robotem możliwa tylko w wypadku opłaconego zamówienia.
                </div>
              )}
            </>
          )}
          <Button
            type="button"
            className="mt-2"
            onClick={handleCallCourrierClick}
          >
            Wezwij
          </Button>
        </Col>
      </CardBody>
    </CollapsibleCard>
  );
};

const robotCourrierTimes = [
  '0 minut (na teraz)',
  '10 minut',
  '15 minut',
  '20 minut',
  '30 minut',
];

const humanCourrierTimes = [
  '15 minut',
  '20 minut',
  '25 minut',
  '30 minut',
  '35 minut',
  '45 minut',
  '60 minut',
  '90 minut',
  '120 minut i więcej',
];

export function getCommonCallForCourrierProps({
  isFoodeli,
  isRobot,
}: {
  isFoodeli: boolean;
  isRobot: boolean;
}) {
  return {
    title: i18.t('delivery.call-driver.set-prepare-time-title'),
    text: i18.t(
      isFoodeli
        ? 'delivery.call-driver.set-prepare-time-desc--foodeli'
        : 'delivery.call-driver.set-prepare-time-desc'
    ),
    options: isRobot ? robotCourrierTimes : humanCourrierTimes,
    other: i18.t('delivery.call-driver.fixed-time-hint'),
    otherPlaceholder: 'np. 16:30',
    confirmText: `Wezwij ${isRobot ? 'robota' : 'kuriera'}`,
    confirmColor: 'success' as const,
  };
}

CallForCourrierForm.propTypes = {
  addDelivery: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default withSetModal(CallForCourrierForm);
