import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { toast, ToastId } from 'react-toastify';
import i18next from 'i18next';

import ModalContext from '../containers/ModalContext';
import MenuEditComponent from 'app/components/menu/MenuEditComponent';
import { FREQUENCY, ModalTypes } from 'config';
import { AdditionalFieldTypes } from 'enums';
import OrdersFetcher from '../containers/OrdersFetcher';
import PendingOrdersContainer from '../containers/PendingOrdersContainer';
import withSetModal, { WithSetModalProps } from '../containers/WithSetModal';
import {
  loadRestaurantMenu,
  selectRestaurant,
  updateRestaurantMenu,
} from 'store/restaurant';
import { checkIfMenuUpToDate, updateMenuHash } from 'store/hash-updates';
import { LOG_BAG } from 'utils/log';
import { selectCanEditRestaurantMenu, selectIsAnyAdmin } from 'store/app';
import { debounce } from 'utils/general';
import CenterCardSpinner from 'app/components/common/CenterCardSpinner';
import { IAppState } from 'store/main';
import {
  Category,
  Menu,
  MenuAddonFieldsSet,
  MenuItem,
  NormalizedRestaurant,
} from 'types';
import { RestaurantPreviewType } from 'app/views/ViewPreviewLink';
import ProductEditModal from 'app/components/orders/ProductEditModal';
import ProductEditModalUploadImage from 'app/components/orders/ProductEditModalUploadImage';
import { currency } from 'globals/currency';
import APIService from 'services/api';

const NEW_PRODUCT: MenuItem = {
  name: '',
  desc: '',
  // @ts-ignore allow this to be empty here
  price: '',
  isDisabled: false,
};

const UNTOUCHED_STATE: Partial<TMenuEditPageState> = {
  wasEdited: false,
  editCatIndex: null,
  editProdIndex: null,
  editableProduct: null,
};

const COMPONENT_NAME = 'MenuEditPage';

type TMenuEditPageProps = PropsFromRedux & WithSetModalProps;

type TMenuEditPageState = {
  initialMenuReady: boolean;
  menuLoading: boolean;
  imageLoading: boolean;
  menu: Menu;
  menuKey: RestaurantPreviewType;

  editCatIndex: number | null;
  editProdIndex: number | null;
  editableProduct: MenuItem | null;

  wasEdited: boolean;
};

class MenuEditPage extends React.PureComponent<
  TMenuEditPageProps,
  TMenuEditPageState
> {
  static displayName = COMPONENT_NAME;
  static getDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  _isMounted = false;
  toastId: ToastId | undefined;

  constructor(props: TMenuEditPageProps) {
    super(props);
    this.state = {
      initialMenuReady: false,
      imageLoading: false,
      menuLoading: false,
      menu: props.restaurant?.menu,
      menuKey: RestaurantPreviewType.Menu,
      ...UNTOUCHED_STATE,
    } as TMenuEditPageState;
  }

  componentDidMount() {
    this._isMounted = true;
    const { restaurant } = this.props;
    this.props.checkIfMenuUpToDate(restaurant).then(({ isUpToDate }) => {
      if (!isUpToDate) {
        toast.info('Znaleziono nowszą wersję menu, pobieram zmiany');
        return this.props.loadRestaurantMenu(restaurant).then((menu) => {
          this.setState({
            menu,
            initialMenuReady: true,
          });
          this.props.updateMenuHash(restaurant);
        });
      } else {
        this.setState({ initialMenuReady: true });
      }
    });
  }

  componentDidCatch(error, info) {
    LOG_BAG.logComponentDidCatch('MenuEditPage', error, info);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRestaurantMenu = () => {
    const menu = this.props.restaurant[this.state.menuKey];
    this._isMounted &&
      this.setState({
        menuLoading: false,
        menu,
      });
  };

  updateMenu = (newMenu: Menu) => this.setState({ menu: newMenu });

  getMenuItemsWhichUseAddonSet = (set: MenuAddonFieldsSet): MenuItem[] => {
    return this.state.menu.categories
      .map((category) => category.items)
      .flat()
      .filter((item) =>
        item.additionalFields?.find(
          (field) =>
            field.type === AdditionalFieldTypes.SET && field.value === set.id
        )
      );
  };

  saveAddonSets = (addonSets: MenuAddonFieldsSet[]) => {
    const { menu } = this.state;
    this._isMounted &&
      this.setState({
        menu: {
          ...menu,
          addonFieldsSets: addonSets,
        },
      });
    this.debouncedAutoSaveMenu();
  };

  changeEditableMenu = (e) => {
    this.setState({ menuKey: e.target.value }, this.getRestaurantMenu);
  };

  addNewCategory = (newCategoryName: string) => {
    const menu = this.state.menu || { categories: [] };
    const categories = menu.categories || [];
    this._isMounted &&
      this.setState({
        menu: {
          ...menu,
          categories: [
            ...categories,
            {
              name: newCategoryName,
              items: [],
            },
          ],
        },
        wasEdited: true,
      });
  };

  changeCategoryName = (cat: Category) => {
    const catIndex = this.state.menu.categories.findIndex((c) => c === cat);
    this.props.setModal(
      {
        title: `${i18next.t('change-category-name-modal.enter-new')} ${
          cat.name
        }`,
        confirm: (newCatName) => {
          this.updateCategoryField(catIndex, 'name', newCatName);
        },
        confirmColor: 'success',
        confirmText: `${i18next.t('Save')}`,
        cancelText: `${i18next.t('Cancel')}`,
      },
      ModalTypes.INPUT
    );
  };

  changeCategoryText = (cat: Category) => {
    const catIndex = this.state.menu.categories.findIndex((c) => c === cat);
    this.props.setModal(
      {
        title: `${i18next.t('change-category-text-modal.enter-text')} ${
          cat.name
        }`,
        text: `${i18next.t('change-category-text-modal.current-text')} ${
          cat.text || `${i18next.t('menu-edit.none')}`
        }`,
        confirm: (newCatText) => {
          this.updateCategoryField(catIndex, 'text', newCatText);
        },
        confirmColor: 'success',
        confirmText: `${i18next.t('Save')}`,
        cancelText: `${i18next.t('Cancel')}`,
      },
      ModalTypes.INPUT
    );
  };

  setEditing = (catIndex: number, prodIndex: number) => {
    const product = this.getCategoryByIndex(catIndex).items[prodIndex];
    if (!product) {
      throw new Error(
        `No product found for index ${prodIndex} in category ${catIndex}`
      );
    }
    this.setState({
      ...this.state,
      editCatIndex: catIndex,
      editProdIndex: prodIndex,
      editableProduct: product,
      wasEdited: true,
    });
  };

  deleteProduct = (catIndex: number, prodIndex: number) => {
    const changedCategory = this.getCategoryByIndex(catIndex);
    const productToDelete = changedCategory.items[prodIndex];
    if (!productToDelete) {
      throw new Error(
        `No product found for index ${prodIndex} in category ${catIndex}`
      );
    }
    this.props.setModal(
      {
        title: `${i18next.t('delete-product-modal.confirm-deletion')} ${
          productToDelete.name
        }?`,
        text: `${i18next.t(
          'delete-product-modal.temporarily-unavailable-message'
        )}`,
        confirm: () => {
          this.deleteProductConfirm(catIndex, prodIndex);
        },
        confirmColor: 'danger',
        confirmText: `${i18next.t('delete-product-modal.confirm-text')}`,
        cancelText: `${i18next.t('delete-product-modal.cancel-text')}`,
      },
      ModalTypes.CONFIRM
    );
  };

  getCategoryByIndex = (catIndex: number) => {
    const category = this.state.menu.categories[catIndex];
    if (!category) {
      throw new Error(`No category found for index ${catIndex}`);
    }
    return category;
  };

  deleteProductConfirm = (catIndex, prodIndex) => {
    const changedCategory = this.getCategoryByIndex(catIndex);
    const updatedCategory = {
      ...changedCategory,
      items: [
        ...changedCategory.items.slice(0, prodIndex),
        ...changedCategory.items.slice(prodIndex + 1),
      ],
    };
    this.updateCategory(catIndex, updatedCategory);
    this.debouncedAutoSaveMenu();
  };

  addNewProduct = (catIndex: number) => {
    const changedCategory = this.getCategoryByIndex(catIndex);
    const updatedCategory = {
      ...changedCategory,
      items: [...changedCategory.items, NEW_PRODUCT],
    };
    const extraState: Partial<TMenuEditPageState> = {
      editCatIndex: catIndex,
      editProdIndex: changedCategory.items.length,
      editableProduct: NEW_PRODUCT,
    };
    this.updateCategory(catIndex, updatedCategory, extraState);
  };

  updateCategory = (catIndex, updatedCategory, extraState = {}) => {
    const menu = {
      ...this.state.menu,
      categories: [
        ...this.state.menu.categories.slice(0, catIndex),
        updatedCategory,
        ...this.state.menu.categories.slice(catIndex + 1),
      ],
    };
    this._isMounted &&
      this.setState({
        menu,
        wasEdited: true,
        ...extraState,
      });
    this.debouncedAutoSaveMenu();
  };

  updateCategoryField = (
    catIndex: number,
    key: 'name' | 'text',
    value: string
  ) => {
    const changedCategory = this.getCategoryByIndex(catIndex);
    this.updateCategory(catIndex, {
      ...changedCategory,
      [key]: value,
    });
  };

  changeCategoryOrder = (catIndex, catIndexChange) => {
    const categories = [...this.state.menu.categories];
    const temp = { ...categories[catIndex] } as Category;
    categories[catIndex] = categories[catIndex + catIndexChange] as Category;
    categories[catIndex + catIndexChange] = temp;
    const menu = {
      ...this.state.menu,
      categories,
    };
    this._isMounted &&
      this.setState({
        menu,
        wasEdited: true,
      });
    this.debouncedAutoSaveMenu();
  };

  changeProductOrder = (catIndex, prodIndex, prodIndexChange) => {
    const products = [...this.getCategoryByIndex(catIndex).items];
    const temp = { ...products[prodIndex] } as MenuItem;
    products[prodIndex] = products[prodIndex + prodIndexChange] as MenuItem;
    products[prodIndex + prodIndexChange] = temp;
    const changedCategory = {
      ...this.getCategoryByIndex(catIndex),
      items: products,
    };
    this.updateCategory(catIndex, changedCategory);
    this.debouncedAutoSaveMenu();
  };

  updateItemInCategory = (
    catIndex: number,
    prodIndex: number,
    newProduct: MenuItem,
    extraState?: Record<string, any>
  ) => {
    const changedCategory = this.getCategoryByIndex(catIndex);
    const updatedCategory = {
      ...changedCategory,
      items: [
        ...changedCategory.items.slice(0, prodIndex),
        newProduct,
        ...changedCategory.items.slice(prodIndex + 1),
      ],
    };
    this.updateCategory(catIndex, updatedCategory, extraState);
  };

  toggleProduct = (e, catIndex, prodIndex) => {
    const { checked } = e.target;
    const changedCategory = this.getCategoryByIndex(catIndex);
    const newProduct: MenuItem = {
      ...(changedCategory.items[prodIndex] as MenuItem),
    };
    newProduct.isDisabled = !checked;
    this.updateItemInCategory(catIndex, prodIndex, newProduct);
    this.debouncedAutoSaveMenu();
  };

  onProductSave = (updatedProduct: MenuItem) => {
    const { editCatIndex, editProdIndex } = this.state;
    this.updateItemInCategory(
      editCatIndex as number,
      editProdIndex as number,
      updatedProduct,
      UNTOUCHED_STATE
    );
    this.setState({
      editCatIndex: null,
      editProdIndex: null,
    });
  };

  cancelChanges = () => {
    this._isMounted &&
      this.setState({
        ...this.state,
        ...UNTOUCHED_STATE,
      });
  };

  removeCategory = (cat: Category) => {
    const catIndex = this.state.menu.categories.findIndex((c) => c === cat);
    this.props.setModal(
      {
        title: `${i18next.t('remove-category-modal.confirm-deletion')} ${
          cat.name
        }`,
        text: `${i18next.t('remove-category-modal.confirm-text')}`,
        confirm: () => this.removeCategoryConfirmed(catIndex),
        confirmColor: 'danger',
        confirmText: `${i18next.t('Remove')}`,
        cancelText: `${i18next.t('Cancel')}`,
      },
      ModalTypes.CONFIRM
    );
  };

  removeCategoryConfirmed = (catIndex: number) => {
    const { categories } = this.state.menu;
    const menu = {
      ...this.state.menu,
      categories: [
        ...categories.slice(0, catIndex),
        ...categories.slice(catIndex + 1),
      ],
    };
    this._isMounted &&
      this.setState({
        menu,
        wasEdited: true,
      });
    this.debouncedAutoSaveMenu();
  };

  saveMenu = () => {
    const { restaurant, updateMenuHash, updateRestaurantMenu } = this.props;
    const { menu, menuKey } = this.state;
    const { id } = restaurant;
    const payload: Partial<NormalizedRestaurant> = { [menuKey]: menu };
    const onSuccess = (restaurant: NormalizedRestaurant) => {
      updateMenuHash(restaurant);
      this._isMounted &&
        this.setState({
          menu: restaurant[menuKey],
          wasEdited: false,
        });
    };

    this.toastId = toast.warn(i18next.t('menu-edit.loading'));
    // @ts-ignore allow menuKey to be passed as a string
    updateRestaurantMenu(id, payload, { menuKey, onSuccess }).finally(() =>
      toast.dismiss(this.toastId)
    );
  };

  productsWithErrorImages: MenuItem[] = [];

  debouncedImageErrorLog = debounce(() => {
    const { restaurant } = this.props;
    const errorProducts: string = this.productsWithErrorImages
      .map((p) => p.name)
      .join(', ');
    LOG_BAG.logError(
      `Restaurant ${restaurant.name} (${restaurant.id}) has products with image error: ${errorProducts}`
    );
  }, 1000);

  handleImageError = (product: MenuItem, e: any) => {
    this.productsWithErrorImages.push(product);
    this.debouncedImageErrorLog();
  };

  debouncedAutoSaveMenu = debounce(this.saveMenu, 1000);

  render() {
    if (!this.state.initialMenuReady) {
      return <CenterCardSpinner text="Pobieram menu" />;
    }
    const { canEditMenu, isAnyAdmin, restaurant } = this.props;
    return (
      <>
        <OrdersFetcher
          ordersConfig={restaurant.orders}
          frequency={FREQUENCY.REFRESH_SECONDARY}
        />
        <PendingOrdersContainer />
        <ModalContext.Consumer>
          {(modalBag) => (
            <MenuEditComponent
              allowPlainText={isAnyAdmin}
              getMenuItemsWhichUseAddonSet={this.getMenuItemsWhichUseAddonSet}
              handleMenuKeyChange={this.changeEditableMenu}
              onImageError={this.handleImageError}
              restaurant={restaurant}
              onCategoryAdd={canEditMenu ? this.addNewCategory : null}
              onCategoryDelete={canEditMenu ? this.removeCategory : null}
              onCategoryOrderChange={
                canEditMenu ? this.changeCategoryOrder : null
              }
              onCategoryNameChange={
                canEditMenu ? this.changeCategoryName : null
              }
              onCategoryTextChange={
                canEditMenu ? this.changeCategoryText : null
              }
              onProductAdd={canEditMenu ? this.addNewProduct : null}
              onProductEdit={canEditMenu ? this.setEditing : null}
              onProductDelete={canEditMenu ? this.deleteProduct : null}
              onProductDisabledToggle={this.toggleProduct}
              onProductOrderChange={
                canEditMenu ? this.changeProductOrder : null
              }
              showAddonSets
              saveAddonSets={this.saveAddonSets}
              updateMenu={this.updateMenu}
              {...this.state}
            />
          )}
        </ModalContext.Consumer>
        {this.renderEditProductModal()}
      </>
    );
  }

  renderEditProductModal = () => {
    const { isAnyAdmin, restaurant } = this.props;
    const { editableProduct } = this.state;
    if (!editableProduct) {
      return null;
    }
    return (
      <ProductEditModal
        currency={currency}
        onClose={this.cancelChanges}
        onImageRemove={this.handleRemoveImage}
        onSave={this.onProductSave}
        product={editableProduct}
        renderUploadImage={this.renderUploadImage}
        restaurant={restaurant}
        showMenuItemAvailability={isAnyAdmin}
      />
    );
  };

  handleRemoveImage = () =>
    this.state.editableProduct &&
    this.setState({
      editableProduct: {
        ...this.state.editableProduct,
        image: undefined,
      },
    });

  renderUploadImage = () => {
    const { editableProduct } = this.state;
    if (!editableProduct) {
      return null;
    }
    return (
      <ProductEditModalUploadImage
        isLoading={this.state.imageLoading}
        onImageUpload={this.uploadImage}
        product={editableProduct}
      />
    );
  };

  uploadImage = (formData: FormData) => {
    const url = `/restaurants/${this.props.restaurant.id}/uploadimage`;
    this.setState({
      imageLoading: true,
    });
    APIService.post(url, formData)
      .then((imgData) => {
        this.setState({
          imageLoading: false,
          editableProduct: this.state.editableProduct
            ? {
                ...this.state.editableProduct,
                image: imgData.url,
              }
            : null,
        });
      })
      .catch(() => {
        this.setState({
          imageLoading: false,
        });
        toast.error('Wystąpił błąd podczas zapisu zdjęcia');
      });
  };
}

const mapStateToProps = (state: IAppState) => ({
  canEditMenu: selectCanEditRestaurantMenu(state),
  isAnyAdmin: selectIsAnyAdmin(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
});

const mapDispatchToProps = {
  checkIfMenuUpToDate,
  updateMenuHash,
  loadRestaurantMenu,
  updateRestaurantMenu,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withSetModal(connector(MenuEditPage));
