import type { TRestaurantPlacesAreaConfig } from 'types';

export const otwartaAreasConfig: TRestaurantPlacesAreaConfig = {
  121: {
    image: {
      src: 'https://cdn.otostolik.pl/img/restaurant-places/468-otwarta/otwarta-sala-v2.png',
      height: 640,
      width: 472,
    },
    tablesConfig: {},
  },
};
