import { API_URL } from 'config';
import { getLsFbToken } from './auth';
import { logActivity, logError } from 'utils/log';
import { appName, releaseVersion } from 'globals/version';
import { getApiToken } from 'store/app';

export const get = (url: string | string[], params?: RequestInit) => {
  const headers = getHeaders();
  if (url && url.length && typeof url !== 'string') {
    return Promise.all(
      url.map((singleUrl) =>
        fetch(`${API_URL}${singleUrl}`, {
          headers,
          mode: 'cors',
        }).then(handleResponseStatus)
      )
    );
  }
  return fetch(`${API_URL}${url}`, {
    headers,
    mode: 'cors',
    ...params,
  }).then(handleResponseStatus);
};

type TJsonPayload = Record<string, any>;

export const post = (
  url: string,
  payload: TJsonPayload | FormData,
  globalConfig: { domain: string } = { domain: API_URL }
) => {
  const { domain } = globalConfig;

  const isFormData = payload instanceof FormData;
  const isLogin = url === '/auth/login';
  const headers = getHeaders({ isFormData, isLogin });

  return fetch(`${domain}${url}`, {
    method: 'POST',
    headers,
    body: payload instanceof FormData ? payload : JSON.stringify(payload),
  }).then((response) => handleResponseStatus(response, { payload, isLogin }));
};

export const put = (url: string, payload: TJsonPayload) => {
  const headers = getHeaders();
  return fetch(`${API_URL}${url}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload),
  }).then(handleResponseStatus);
};

export const del = (url: string, payload?: any) => {
  const headers = getHeaders();
  const body = payload ? { body: JSON.stringify(payload) } : {};

  return fetch(`${API_URL}${url}`, {
    method: 'DELETE',
    headers,
    ...body,
  }).then(handleResponseStatus);
};

const APIService = {
  get,
  post,
  put,
  del,
};

export default APIService;

function handleResponseStatus(
  response,
  options?: { payload?: any; isLogin?: boolean }
) {
  const { payload, isLogin } = options || {};
  if (response.status === 200) {
    return response.json ? response.json() : true;
  }
  if (response.status === 201) {
    return response.json();
  }
  if (response.status === 204) {
    return null;
  }
  if (response.status === 422) {
    return response.json().then((json) => Promise.reject(json));
  }
  if (response.status === 401) {
    if (!isLogin) {
      logActivity('401 fetch error', response);
      window.location.pathname = '/login';
    } else {
      logActivity(
        `Unsuccessfull login for email: ${payload?.email}, isEmptyPassword: ${
          (payload?.password || '').length === 0
        }`,
        response
      );
    }
    const text = isLogin
      ? 'Nieprawidłowy login lub hasło. Spróbuj ponownie, a jeżeli kopiujesz dane z innego źródła - upewnij się, że przypadkiem nie została dodana spacja lub Enter.'
      : 'Sesja wygasła, zaloguj się by móc kontynuować';
    return Promise.reject(text);
  }
  if (response.status === 403) {
    return Promise.reject('Brak dostępu');
  }
  logError('Other fetch error', response);
  return Promise.reject(response.statusText);
}

function getHeaders(
  options = { isFormData: false, isLogin: false }
): HeadersInit {
  const { isFormData, isLogin } = options;
  const { token: firebaseToken } = getLsFbToken() || {};
  const jsonHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const apiTokenHeader = isLogin
    ? {}
    : {
        // TODO this should be XSS-protected
        Authorization: `Bearer ${getApiToken()}`,
      };
  const firebaseTokenHeader = firebaseToken
    ? { 'X-FIREBASE-NOTIFICATION-TOKEN': firebaseToken }
    : {};
  return {
    ...(isFormData ? {} : jsonHeaders),
    ...apiTokenHeader,
    'x-app-name': appName,
    'x-app-version': releaseVersion,
    ...firebaseTokenHeader,
  } as HeadersInit;
}
