import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

import mainReducer from 'store/main';
import { actionsInterceptorMiddleware } from 'effects/action-interceptor';

export const store = createStore(
    mainReducer,
    composeWithDevTools(applyMiddleware(thunk, actionsInterceptorMiddleware))
  );
  
  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>;
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch;