import React from 'react';
import type { FormikProps } from 'formik';
import i18 from 'i18next';
import { Label } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';

import { deliveryOptions } from 'utils/deliveries';
import type { OrdersConfigFormValues } from 'app/pages/OrderSettingsPage';
import { EDeliveryType } from 'enums';

type DeliveryOptionsProps = {
  setFieldValue: FormikProps<OrdersConfigFormValues>['setFieldValue'];
  showDriveThrough: boolean;
  showRobot: boolean;
  values: OrdersConfigFormValues;
};

const DeliveryOptions: React.FC<DeliveryOptionsProps> = ({
  setFieldValue,
  showDriveThrough,
  showRobot,
  values,
}) => {
  const availableOptions = React.useMemo(() => {
    return deliveryOptions.filter((option) => {
      if (option.name === EDeliveryType.DRIVE_THROUGH) {
        return showDriveThrough;
      }
      if (option.name === EDeliveryType.ROBOT) {
        return showRobot;
      }
      return true;
    });
  }, [showDriveThrough, showRobot]);

  const onUpdate = (selectedOptions) =>
    setFieldValue(
      'allowedDeliveryOptionKeys',
      selectedOptions.map((option) => option.name)
    );
  return (
    <div className="mb-3">
      <Label>{i18.t('delivery-settings-form.available-delivery-types')}</Label>
      <Multiselect
        options={availableOptions}
        selectedValues={availableOptions.filter((opt) =>
          (values.allowedDeliveryOptionKeys || []).includes(opt.name)
        )}
        onSelect={onUpdate}
        onRemove={onUpdate}
        displayValue="label"
        placeholder="Wybierz dostępne"
      />
    </div>
  );
};

export default DeliveryOptions;
