import { roleNamesById } from 'enums';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export type UserRoleSelectProps = {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  wrapperClassName?: string;
};

const UserRoleSelect: React.FC<UserRoleSelectProps> = ({
  name,
  onChange,
  value,
  wrapperClassName = 'col min-w-200',
}) => {
  return (
    <FormGroup className={wrapperClassName}>
      <Label>Rola</Label>
      <Input name={name} value={value} onChange={onChange} type="select">
        {Object.entries(roleNamesById).map(([id, name]) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default UserRoleSelect;
