import React from 'react';
import i18next from 'i18next';

import type { IOrder, NormalizedRestaurant } from 'types';

const labels = {
  yes: 'Yes',
  no: 'No',
};

interface IProps {
  order: IOrder;
  restaurant: NormalizedRestaurant;
}

export const OrderCutlery: React.FC<IProps> = ({ order, restaurant }) => {
  const value = i18next.t(
    order.customer.cutleryPreferences
      ? labels[order.customer.cutleryPreferences]
      : 'order.no-preferences'
  );
  return !!restaurant.orders.askForCutlery ? (
    <div>
      {i18next.t('order.cutlery')}: {value}
    </div>
  ) : null;
};

export default OrderCutlery;
