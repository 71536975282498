import React, { useEffect } from 'react';
import { Button } from 'reactstrap';

const ElectronTwoWayCommunication = () => {
  useEffect(() => {
    window.electronApi?.twoWayCommunicationReceive((event, value) => {
      alert('rendered received: ' + value);
    });
  }, []);

  const pushToElectron = () => {
    window.electronApi?.twoWayCommunicationRequest('abc');
  };

  return (
    <div>
      <Button type="button" color="primary" onClick={pushToElectron}>
        Test 2-way with electron
      </Button>
    </div>
  );
};

export default ElectronTwoWayCommunication;
