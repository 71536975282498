import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const visitHours = [
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];
const visitMinutes = [
  '00',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

interface IProps {
  className?: string;
  defaultHour?: string;
  defaultMin?: string;
  disabled?: boolean;
  onChange: (time: string) => void;
}

export const TimePicker = (props: IProps) => {
  const {
    className,
    defaultMin,
    defaultHour,
    disabled = false,
    onChange,
  } = props;
  const [hour, setHour] = useState(defaultHour || '12');
  const [min, setMin] = useState(defaultMin || '00');

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHour(e.target.value);
  };
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMin(e.target.value);
  };

  useEffect(() => {
    onChange(`${hour}:${min}`);
  }, [hour, min]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderOption = (opt: string) => (
    <option key={opt} value={opt}>
      {opt}
    </option>
  );

  return (
    <div className={className}>
      <Input
        type="select"
        disabled={disabled}
        value={hour}
        onChange={handleHourChange}
        className="mw-70 mr-1"
      >
        {visitHours.map(renderOption)}
      </Input>
      <Input
        type="select"
        disabled={disabled}
        value={min}
        onChange={handleMinChange}
        className="mw-70"
      >
        {visitMinutes.map(renderOption)}
      </Input>
    </div>
  );
};

TimePicker.propTypes = {
  className: PropTypes.string,
  defaultHour: PropTypes.string,
  defaultMin: PropTypes.string,
};

export default TimePicker;
