import React from 'react';
import { CheckCircle, XCircle } from 'react-feather';

import { InlineIcon } from '@iconify/react';
import carSide from '@iconify/icons-fa-solid/car-side';
import shoppingBag from '@iconify/icons-fa-solid/shopping-bag';
import shieldAlt from '@iconify/icons-fa-solid/shield-alt';
import robot from '@iconify/icons-mdi/robot-love';

import moneyBill from '@iconify/icons-fa-solid/money-bill';
import creditCard from '@iconify/icons-fa-solid/credit-card';
import mobileWithSignal from '@iconify-icons/vs/mobile';

import { EDeliveryType, EPaymentType } from 'enums';

const DeliveryOptionIcons = {
  [EDeliveryType.CONTACTLESS]: <><InlineIcon icon={shieldAlt} /><InlineIcon icon={carSide} /></>,
  [EDeliveryType.USUAL]: <InlineIcon icon={carSide} />,
  [EDeliveryType.ROBOT]: <InlineIcon icon={robot} />,
  [EDeliveryType.TAKEOUT]: <InlineIcon icon={shoppingBag} />,
  [EDeliveryType.DRIVE_THROUGH]: <InlineIcon icon={carSide} />,
};

const onlinePaymentIcon = <InlineIcon icon={mobileWithSignal} />;

const PaymentOptionIcons = {
  [EPaymentType.CASH]: <InlineIcon icon={moneyBill} />,
  [EPaymentType.CARD_ON_PLACE]: <InlineIcon icon={creditCard} />,
  [EPaymentType.CARD_ONLINE]: onlinePaymentIcon,
  [EPaymentType.BLIK]: onlinePaymentIcon,
  [EPaymentType.TRANSFER]: onlinePaymentIcon,
  [EPaymentType.APPLE_PAY]: onlinePaymentIcon,
  [EPaymentType.GOOGLE_PAY]: onlinePaymentIcon,
}

const YesNoIcons = {
  YES: <CheckCircle className="text-success" />,
  NO: <XCircle className="text-danger" />,
}

export { DeliveryOptionIcons, PaymentOptionIcons, YesNoIcons };
