import { CollapsibleCard } from 'app/components/common';
import { YesNoIcons } from 'app/components/icons/IconSets';
import React from 'react';
import type { ISavedReservation } from 'types';

export type ReservationMarketingAgreementsProps = {
  isAddedByRestaurant: boolean;
  className?: string;
  reservation: {
    source: ISavedReservation['source'];
    sms_marketing?: ISavedReservation['sms_marketing'];
    email_marketing?: ISavedReservation['email_marketing'];
  };
};

export const ReservationMarketingAgreements: React.FC<
  ReservationMarketingAgreementsProps
> = ({ isAddedByRestaurant, className = '', reservation }) => {
  return (
    <CollapsibleCard
      className={className}
      buttonClassName={'p-2'}
      title={<span>Zgody marketingowe</span>}
    >
      {isAddedByRestaurant ? (
        <div>
          Brak informacji o zgodach marketingowych - rezerwacja została dodana
          przez Restaurację
        </div>
      ) : (
        <>
          <div className="align-vertical gap-10 mb-2">
            <span>SMS:</span>
            {reservation.sms_marketing ? YesNoIcons.YES : YesNoIcons.NO}
          </div>
          <div className="align-vertical gap-10">
            <span>Email:</span>
            {reservation.email_marketing ? YesNoIcons.YES : YesNoIcons.NO}
          </div>
        </>
      )}
    </CollapsibleCard>
  );
};

export default ReservationMarketingAgreements;
