import React from 'react';
import { Alert } from 'reactstrap';

import { isDeclinedByClient } from 'utils/reservation';
import {
  RES_CANCELLED_TEXT,
  RES_CANCELLED,
  NEW_RES,
  NEW_RES_TEXT,
} from './lang';
import { ISavedReservation } from 'types';

type ReservationAlertsProps = {
  reservations: ISavedReservation[];
  removeAlert: (index: number) => void;
};

const ReservationAlerts: React.FC<ReservationAlertsProps> = (props) => {
  const { reservations, removeAlert } = props;
  return (
    <>
      {reservations.map((res, index) => (
        <Alert
          isOpen
          key={`${res.id}${res.status}`}
          color={isDeclinedByClient(res) ? 'danger' : 'info'}
          toggle={() => removeAlert(index)}
        >
          {isDeclinedByClient(res)
            ? `${RES_CANCELLED}: ${RES_CANCELLED_TEXT(res)}`
            : `${NEW_RES}: ${NEW_RES_TEXT(res)}`}
        </Alert>
      ))}
    </>
  );
};

export default ReservationAlerts;
