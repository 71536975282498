const FIRST_PART = 160;
const SECOND_PART = 146
const OTHER_PARTS = 153;

export function calculateSmsParts(message: string): number {
  if (!message || !message.length) {
    return 0;
  }
  if (message.length <= FIRST_PART) {
    return 1;
  }
  if (message.length <= (FIRST_PART + SECOND_PART)) {
    return 2;
  }
  const restLength = (message.length - FIRST_PART - SECOND_PART);
  return 3 + parseInt(((restLength - 1) / OTHER_PARTS).toString(), 10);
}
