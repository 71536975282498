import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

import { IPlace } from 'types';

export type ReservationsPlaceSelectProps = {
  activePlace: IPlace | null;
  onActivePlaceChange: (place: IPlace | null) => void;
  places: IPlace[];
};

export const ReservationsPlaceSelect: React.FC<ReservationsPlaceSelectProps> = ({
  activePlace,
  onActivePlaceChange,
  places,
}) => {
  return (
    <FormGroup className="bb-1 bc-grey pb-3 mb-2">
      <Label className="font-weight-bold" htmlFor="activePlace">
        Aktywna sala
      </Label>
      <Input
        type="select"
        className="mw-150"
        id="activePlace"
        value={activePlace ? JSON.stringify(activePlace) : ''}
        onChange={(e) =>
          onActivePlaceChange(
            !!e.target.value ? (JSON.parse(e.target.value) as IPlace) : null
          )
        }
      >
        <option value="">wszystkie</option>
        {places.map((place) => (
          <option key={place.id} value={JSON.stringify(place)}>
            {place.name}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default ReservationsPlaceSelect;
