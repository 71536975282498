import React from 'react';
import {
  CardGroup,
  FormGroup,
  Row,
  Col,
  Label,
  TabContent,
  TabPane,
  Input,
} from 'reactstrap';
import { ShoppingCart, Truck, Users } from 'react-feather';
import { getWeek } from 'date-fns';

import WithDateRangeDataFetched from '../../containers/WithDateRangeDataFetched';
import UrlFetcher from '../../containers/UrlFetcher';
import CardWithIcon from 'app/components/widgets/CardWithIcon';
import OtoLineChart from 'app/components/common/OtoLineChart';
import OtoTabs from 'app/components/common/OtoTabs';
import AdminSalesStatistics from 'app/components/admin-statistics/AdminSalesStatistics';
import RestaurantsFeedbacks from 'app/components/admin-statistics/RestaurantsFeedbacks';
import AdminDeclinedOrdersStats from 'app/components/admin-statistics/AdminDeclinedOrdersStats';
import AdminStatisticsOverviewComparison from 'app/components/admin-statistics/AdminStatisticsOverviewComparison';
import AdminReservationStatistics from 'app/components/admin-statistics/AdminReservationStatistics';
import TwilioCallsStatistics from './TwilioCallsStatistics';
import OrdersPerRestaurantStats from 'app/components/admin-statistics/OrdersPerRestaurantStats';
import { toDateInputValue } from 'utils/date-time';

class AdminStatisticsPage extends React.Component {
  tabs = {
    sales: 'Sales overview',
    orders: 'Orders',
    ordersByRestaurants: 'OrdersByRestaurants',
    reservations: 'Reservations',
    feedbacks: 'Feedbacks',
    declinedOrders: 'Declined orders',
    twilioCalls: 'Twilio calls',
  };

  groupByOptions = [
    {
      value: '',
      label: 'no grouping',
    },
    {
      value: 'week',
      label: 'week',
    },
    {
      value: 'month',
      label: 'month (not implemented yet)',
    },
  ];

  state = {
    activeTab: this.tabs.sales,
    ordersGroupBy: '',
  };

  changeTab = (newTab) => this.setState({ activeTab: newTab });

  getGeneralStatsUrl = (startDate, endDate) => {
    const groupByPart = this.state.ordersGroupBy
      ? `&groupBy=${this.state.ordersGroupBy}`
      : '';
    return `/statistics?from=${toDateInputValue(
      startDate
    )}&to=${toDateInputValue(endDate)}${groupByPart}`;
  };

  render() {
    const { activeTab } = this.state;
    return (
      <>
        <FormGroup>
          <Label>Group orders</Label>
          <Input
            type="select"
            value={this.state.ordersGroupBy}
            onChange={(e) => this.setState({ ordersGroupBy: e.target.value })}
          >
            {this.groupByOptions.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </Input>
        </FormGroup>
        <OtoTabs
          activeTab={activeTab}
          changeTab={this.changeTab}
          tabs={this.tabs}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId={this.tabs.sales}>
            {activeTab === this.tabs.sales && this.renderSalesOverviewTab()}
          </TabPane>
          <TabPane tabId={this.tabs.orders}>
            {activeTab === this.tabs.orders && this.renderOrdersTab()}
          </TabPane>
          <TabPane tabId={this.tabs.ordersByRestaurants}>
            {activeTab === this.tabs.ordersByRestaurants && (
              <OrdersPerRestaurantStats />
            )}
          </TabPane>
          <TabPane tabId={this.tabs.reservations}>
            {activeTab === this.tabs.reservations && (
              <>
                <UrlFetcher url={`/statistics/reservations`}>
                  {([reservations, urlFetcherProps]) => (
                    <AdminReservationStatistics data={reservations} />
                  )}
                </UrlFetcher>
              </>
            )}
          </TabPane>
          <TabPane tabId={this.tabs.feedbacks}>
            {activeTab === this.tabs.feedbacks && (
              <>
                <UrlFetcher url={`/restaurants/feedbacks`}>
                  {([feedbacks, urlFetcherProps]) => (
                    <RestaurantsFeedbacks data={feedbacks} />
                  )}
                </UrlFetcher>
              </>
            )}
          </TabPane>
          <TabPane tabId={this.tabs.declinedOrders}>
            {activeTab === this.tabs.declinedOrders &&
              this.renderDeclinedOrdersTab()}
          </TabPane>
          <TabPane tabId={this.tabs.twilioCalls}>
            {activeTab === this.tabs.twilioCalls && <TwilioCallsStatistics />}
          </TabPane>
        </TabContent>
      </>
    );
  }

  getDeclinedOrdersUrl = (startDate, endDate) => {
    return `/statistics/declined-orders?from=${toDateInputValue(
      startDate
    )}&to=${toDateInputValue(endDate)}`;
  };

  renderDeclinedOrdersTab() {
    return (
      <WithDateRangeDataFetched allowRefetch getUrl={this.getDeclinedOrdersUrl}>
        {(data) => (
          <>
            TODO - add REASONS as well, not just amounts + add cancelled orders
            by users
            <AdminDeclinedOrdersStats data={data} />
          </>
        )}
      </WithDateRangeDataFetched>
    );
  }

  renderSalesOverviewTab() {
    return (
      <Row>
        <Col>
          <h2>General sales / business stats</h2>
          <UrlFetcher url={'/admin/statistics/sales'}>
            {([salesStats, urlFetcherProps]) => (
              <AdminSalesStatistics data={salesStats} />
            )}
          </UrlFetcher>
        </Col>
      </Row>
    );
  }

  defaultOrdersStatistics = {
    customers: {},
    orders: {},
    deliveries: {},
    reservations: {},
    request: {},
  };

  renderOrdersTab() {
    return (
      <WithDateRangeDataFetched allowRefetch getUrl={this.getGeneralStatsUrl}>
        {(statistics = this.defaultOrdersStatistics) => (
          <>
            {this.renderOrdersTabHeader(statistics.request)}
            <CardGroup className="mb-3">
              <CardWithIcon
                bgColor="white"
                inverse={false}
                icon={Users}
                title={statistics.customers.total_count || 0}
                subtitle="Total Customers"
              />
              <CardWithIcon
                bgColor="white"
                inverse={false}
                icon={ShoppingCart}
                title={statistics.orders.today_count || 0}
                subtitle="Today Orders"
              />
              <CardWithIcon
                bgColor="white"
                inverse={false}
                icon={Truck}
                title={statistics.deliveries.today_count || 0}
                subtitle="Today Deliveries"
              />
            </CardGroup>
            <AdminStatisticsOverviewComparison statistics={statistics} />
            {statistics.orders_by_weeks &&
              this.renderPerWeekStats(statistics.orders_by_weeks)}
          </>
        )}
      </WithDateRangeDataFetched>
    );
  }

  renderOrdersTabHeader = (request) => {
    const from = new Date(request.from);
    const to = new Date(request.to);
    const pastFrom = new Date(request.pastFrom);
    const pastTo = new Date(request.pastTo);
    return (
      <Col xs={12}>
        <h3>
          Statystyki za okres {toDateInputValue(from)} - {toDateInputValue(to)}
        </h3>
        <h3>
          W porównaniu do {toDateInputValue(pastFrom)} -{' '}
          {toDateInputValue(pastTo)}
        </h3>
      </Col>
    );
  };

  renderPerWeekStats(data) {
    const getDateRangeOfWeek = (weekNo, y) => {
      let d1 = new Date('' + y + '');
      let numOfdaysPastSinceLastMonday = d1.getDay() - 1;
      d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
      d1.setDate(d1.getDate() + 7 * (weekNo - getWeek(d1)));
      let d2 = new Date(d1.getTime());
      d2.setDate(d2.getDate() + 6);
      return { d1, d2 };
    };

    return (
      <>
        <OtoLineChart
          header="Liczba zamówień w poszczególnych tygodniach (todo labele tygodni + stats daty check)"
          labels={data.map((item) => {
            // todo year to be passed
            const { d1, d2 } = getDateRangeOfWeek(
              parseInt(item.weekFromMonday, 10),
              2021
            );
            return `${toDateInputValue(d1)} - ${toDateInputValue(d2)}`;
          })}
          data={data.map((item) => item.orders_amount)}
        />
        <Row>
          <Col>Liczba zamówień</Col>
          <Col>Suma zamówień</Col>
          <Col>Tydzień roku</Col>
        </Row>
        {data.map((item, index) => (
          <Row key={index}>
            <Col>{item.orders_amount} sztuk</Col>
            <Col>{item.orders_sum} PLN</Col>
            <Col>Tydzień: {item.weekFromMonday}</Col>
          </Row>
        ))}
      </>
    );
  }
}

AdminStatisticsPage.url = '/admin-statistics';
AdminStatisticsPage.navName = 'admin.stats-old';

export default AdminStatisticsPage;
