import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import { CollapsibleCard, IconWithTooltip, InputWithAddon } from '../common';
import {
  IReservationDurationOption,
  possibleReservationDurations,
} from 'config';
import i18next from 'i18next';

interface IProps {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: {
    reservationDuration: number;
    reservationDurationOptionsForRestaurant: IReservationDurationOption[];
    reservationDurationOptionsForGuests: IReservationDurationOption[];
  };
  updateReservationDurationForRestaurant: (
    selectedValues: IReservationDurationOption[]
  ) => void;
  updateReservationDurationForGuests: (
    selectedValues: IReservationDurationOption[]
  ) => void;
}

const ReservationSettingsDuration: React.FC<IProps> = (props) => {
  const {
    handleChange,
    updateReservationDurationForRestaurant,
    updateReservationDurationForGuests,
    values,
  } = props;

  return (
    <CollapsibleCard
      bodyClassName={'align-vertical flex-wrap flex-column align-items-start'}
      title={<div>{i18next.t('reservation-settings.duration.header')}</div>}
    >
      <FormGroup>
        <Label for="reservationDuration">
          <span className="mr-1">{i18next.t('reservation-settings.duration.default.label')}</span>
          <IconWithTooltip
            id="res-duration"
            text={i18next.t('reservation-settings.duration.for-restaurant.tooltip')}
          />
        </Label>
        <InputWithAddon
          addonText={i18next.t('input-addons.hours', { count: values.reservationDuration })}
          type="number"
          step="0.5"
          className="mw-70"
          name="reservationDuration"
          id="reservationDuration"
          placeholder="2"
          onChange={handleChange}
          value={values.reservationDuration}
        />
      </FormGroup>
      <FormGroup>
        <Label for="reservationDurationOptionsForRestaurant_input">
          {i18next.t('reservation-settings.duration.for-restaurant.label')}
          <IconWithTooltip
            id="reservation-duration-options-for-restaurant-tooltip"
            className="ml-2"
            text={i18next.t('reservation-settings.duration.for-restaurant.tooltip')}
          />
        </Label>
        <Multiselect
          isObject={true}
          options={possibleReservationDurations}
          onSelect={updateReservationDurationForRestaurant}
          onRemove={updateReservationDurationForRestaurant}
          selectedValues={values.reservationDurationOptionsForRestaurant}
          placeholder={i18next.t('reservation-settings.duration.for-restaurant.placeholder')}
          displayValue="label"
          style={{
            searchBox:
              (values.reservationDurationOptionsForRestaurant || []).length ===
              0
                ? {
                    border: '1px solid red',
                    marginBottom: '1rem',
                  }
                : { marginBottom: '1rem' },
            inputField: { width: '100%' },
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="reservationDurationOptionsForGuests_input">
          {i18next.t('reservation-settings.duration.for-guest.label')}
          <IconWithTooltip
            id="reservation-duration-options-for-guests-tooltip"
            className="ml-2"
            text={i18next.t('reservation-settings.duration.for-guest.tooltip')}
          />
        </Label>
        <Multiselect
          isObject={true}
          options={possibleReservationDurations}
          onSelect={updateReservationDurationForGuests}
          onRemove={updateReservationDurationForGuests}
          selectedValues={values.reservationDurationOptionsForGuests}
          placeholder={i18next.t('reservation-settings.duration.for-guest.placeholder')}
          displayValue="label"
          id="reservationDurationOptionsForGuests"
          style={{
            searchBox:
              (values.reservationDurationOptionsForGuests || []).length === 0
                ? { border: '1px solid red' }
                : {},
            inputField: { width: '100%' },
          }}
        />
      </FormGroup>
    </CollapsibleCard>
  );
};

ReservationSettingsDuration.propTypes = {
  handleChange: PropTypes.func.isRequired,
  updateReservationDurationForGuests: PropTypes.func.isRequired,
  updateReservationDurationForRestaurant: PropTypes.func.isRequired,
};

export default ReservationSettingsDuration;
