import React from 'react';

import { PageComponent } from '../../types-global';
import { useAppSelector } from 'hooks/useRedux';
import { selectAdminTenant } from 'store/admin';
import { AdminTenantSelector } from 'app/components/admin-statistics';
import { toDateInputValue } from 'utils/date-time';
import { IPayment, IPaymentWitOrderInfo } from 'types';
import WithDateRangeDataFetched from 'app/containers/WithDateRangeDataFetched';
import AdminPaymentsList from 'app/components/admin/payments/AdminPaymentsList';
import StatisticsRangeHeader from 'app/components/admin-statistics/StatisticsRangeHeader';
import { OtoTabs } from 'app/components/common';
import { TabContent, TabPane } from 'reactstrap';
import UrlFetcher from 'app/containers/UrlFetcher';
import { getArrayWithUpdatedObject } from 'utils/array';
import APIService from 'services/api';
import { toast } from 'react-toastify';
import { EPaymentStatus } from 'enums';

const tabs = {
  payments: 'Płatności/Przelewy',
  refunds: 'Zwroty',
};
const AdminPaymentsPage: PageComponent<{}> = () => {
  const activeTenant = useAppSelector(selectAdminTenant);
  const [activeTab, setActiveTab] = React.useState(tabs.payments);
  const [loadingPaymentIds, setLoadingPaymentIds] = React.useState<number[]>(
    []
  );

  const getAdminPaymentsUrl = (from: Date, to: Date) =>
    `/admin/payments?from=${toDateInputValue(from)}&to=${toDateInputValue(
      to
    )}&tenant=${activeTenant}`;
  const getAdminRefundsUrl = () => `/admin/refunds`;

  return (
    <>
      <AdminTenantSelector />
      <OtoTabs activeTab={activeTab} changeTab={setActiveTab} tabs={tabs} />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={tabs.payments}>
          {activeTab === tabs.payments && (
            <>
              <WithDateRangeDataFetched<IPaymentWitOrderInfo[]>
                fetchOnMount={false}
                defaultRange="lastMonth"
                getUrl={getAdminPaymentsUrl}
              >
                {(payments, { editRanges, ranges }) => (
                  <>
                    <StatisticsRangeHeader
                      onEdit={editRanges}
                      ranges={ranges}
                    />
                    {payments && (
                      <div>
                        <h3>Uwaga!</h3>
                        Wyśietlono płatności (razem {payments.length} szt.) dla
                        wybranego okresu, pasujące do następujących kryteriów:
                        <ul>
                          <li>przypisane do zamówienia dla danego tenant</li>
                          <li>wszystkie płatności bez zamówienia</li>
                          <li>wszystkie abandoned płatności</li>
                        </ul>
                      </div>
                    )}
                    <AdminPaymentsList payments={payments} />
                  </>
                )}
              </WithDateRangeDataFetched>
            </>
          )}
        </TabPane>
        <TabPane tabId={tabs.refunds}>
          {activeTab === tabs.refunds && (
            <>
              <UrlFetcher<IPaymentWitOrderInfo[]> url={getAdminRefundsUrl()}>
                {([paymentsWithRefunds, { setData }]) => (
                  <>
                    {paymentsWithRefunds && (
                      <div>
                        Zwroty (razem {paymentsWithRefunds.length} szt.)
                      </div>
                    )}
                    <AdminPaymentsList
                      isPaymentLoading={(payment) =>
                        loadingPaymentIds.includes(payment.id)
                      }
                      payments={paymentsWithRefunds}
                      onRefundMadeClick={async (selectedPayment: IPayment) => {
                        setLoadingPaymentIds((prev) => [
                          ...prev,
                          selectedPayment.id,
                        ]);
                        try {
                          const updatedPayment = await APIService.put(
                            `/admin/refunds/payment/${selectedPayment.id}`,
                            { status: EPaymentStatus.REFUNDED }
                          );
                          setData(
                            getArrayWithUpdatedObject(
                              paymentsWithRefunds,
                              updatedPayment
                            )
                          );
                          toast.success('Zwrot zrobiony');
                        } catch (e) {
                          toast.error('Nie udało się zrobić zwrotu');
                        } finally {
                          setLoadingPaymentIds((prev) =>
                            prev.filter((id) => id !== selectedPayment.id)
                          );
                        }
                      }}
                    />
                  </>
                )}
              </UrlFetcher>
            </>
          )}
        </TabPane>
      </TabContent>
    </>
  );
};
AdminPaymentsPage.navName = 'admin.payments';
AdminPaymentsPage.url = '/admin/payments';

export default AdminPaymentsPage;
