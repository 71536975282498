import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
  Row,
} from 'reactstrap';

import OtoSpinner from '../common/OtoSpinner';
import { IPlace } from 'types/restaurant-place';
import Shapes from 'shapes/main';
import OtoToggle from '../common/OtoToggle';

interface IProps {
  selectedPlace: IPlace | null;
  setSelectedPlace: (place: IPlace | null) => void;
  onSave: (selectedPlace: IPlace) => void;
  loading: boolean;
}

const PlaceEditModal: React.FC<IProps> = (props) => {
  const { selectedPlace, setSelectedPlace, onSave, loading } = props;
  const clearSelectedPlace = () => setSelectedPlace(null);

  return (
    <Modal isOpen={!!selectedPlace} toggle={clearSelectedPlace}>
      {selectedPlace && (
        <>
          <ModalHeader toggle={clearSelectedPlace}>
            Edytujesz salę {selectedPlace.name}
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mw-300">
              <Label>Nazwa sali</Label>
              <Input
                placeholder="np. Sala główna"
                value={selectedPlace.name}
                onChange={(e) =>
                  setSelectedPlace({ ...selectedPlace, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup className="mw-150">
              <Label>Priorytet</Label>
              <Input
                placeholder="np. 1"
                value={selectedPlace.priority}
                onChange={(e) =>
                  setSelectedPlace({
                    ...selectedPlace,
                    // @ts-ignore allow empty string in edit mode
                    priority: e.target.value
                      ? parseInt(e.target.value, 10)
                      : '',
                  })
                }
                step="1"
                min="1"
                type="number"
              />
            </FormGroup>
            <Row>
              <FormGroup className="col-12 col-md-6">
                <Label>Czy jest aktywna</Label>
                <OtoToggle
                  className="mr-2 d-flex flex-row"
                  checked={selectedPlace.is_active}
                  id="isPlaceActive"
                  name="isPlaceActive"
                  onChange={(e) =>
                    setSelectedPlace({
                      ...selectedPlace,
                      is_active: e.target.checked,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label>Czy jest na zewnątrz</Label>
                <OtoToggle
                  className="mr-2 d-flex flex-row"
                  checked={selectedPlace.is_outside}
                  id="isPlaceOutside"
                  name="isPlaceOutside"
                  onChange={(e) =>
                    setSelectedPlace({
                      ...selectedPlace,
                      is_outside: e.target.checked,
                    })
                  }
                />
              </FormGroup>
            </Row>
          </ModalBody>
        </>
      )}
      <ModalFooter>
        {loading ? (
          <OtoSpinner />
        ) : (
          <Button
            type="button"
            color="primary"
            onClick={() => onSave(selectedPlace as IPlace)}
          >
            Zapisz
          </Button>
        )}
        <Button type="button" onClick={clearSelectedPlace}>
          Anuluj
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PlaceEditModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedPlace: Shapes.restaurantPlaceShape.isRequired,
  setSelectedPlace: PropTypes.func.isRequired,
};

export default PlaceEditModal;
