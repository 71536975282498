import React from 'react';
import { Button } from 'reactstrap';

import OtoAlert from 'app/components/common/OtoAlert';

type OtoErrorFetchDataProps = {
  className?: string;
  type: 'fetch-data';
  onRefetchClick?: () => void;
};

type OtoErrorReservationAttachmentsProps = {
  className?: string;
  type: 'reservation-attachments';
};

export type OtoErrorProps =
  | OtoErrorFetchDataProps
  | OtoErrorReservationAttachmentsProps;

export const OtoError: React.FC<OtoErrorProps> = (props) => {
  if (props.type === 'fetch-data') {
    const { onRefetchClick } = props;
    return (
      <OtoAlert color="secondary" className={props.className}>
        <div className="mb-2">Wystąpił błąd pobierania danych.</div>
        <Button type="button" color="primary" onClick={onRefetchClick}>
          Pobierz ponownie
        </Button>
      </OtoAlert>
    );
  }

  if (props.type === 'reservation-attachments') {
    return (
      <OtoAlert color="danger" className={props.className}>
        Wystąpił błąd wyświetlania załączników.
      </OtoAlert>
    );
  }
  return null;
};

export default OtoError;
