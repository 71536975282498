import { addMinutes, differenceInMinutes } from 'date-fns';

import type { IDelivery } from 'types/delivery';
import {
  EDeliveryType,
  EDeliveryStatus,
  DriverType,
  OTO_PRODUCTS,
} from 'enums';

export const driverTypeByProduct: { [key: string]: DriverType } = {
  [OTO_PRODUCTS.restaurant_deliveries]: DriverType.Restaurant,
  [OTO_PRODUCTS.exchange_deliveries]: DriverType.EXCHANGE,
  [OTO_PRODUCTS.foodeli_deliveries]: DriverType.Foodeli,
  [OTO_PRODUCTS.robot_deliveries]: DriverType.Robot,
  [OTO_PRODUCTS.szamaexpress_deliveries]: DriverType.SzamaExpress,
};

export const btnLabelByProduct = {
  [OTO_PRODUCTS.restaurant_deliveries]: 'order.call-own-courrier',
  [OTO_PRODUCTS.exchange_deliveries]: 'order.call-exchange-courrier',
  [OTO_PRODUCTS.foodeli_deliveries]: 'order.call-foodeli-courrier',
  [OTO_PRODUCTS.robot_deliveries]: 'order.call-robot-courrier',
  [OTO_PRODUCTS.szamaexpress_deliveries]: 'order.call-szamaexpress-courrier',
};

// @TODO remove this, use labels from translations
export const deliveryOptions = [
  { label: 'Dostawa zwykła', name: EDeliveryType.USUAL },
  { label: 'Dostawa bezkontaktowa', name: EDeliveryType.CONTACTLESS },
  { label: 'Dostawa robotem', name: EDeliveryType.ROBOT },
  { label: 'Odbiór osobisty', name: EDeliveryType.TAKEOUT },
  { label: 'Drive Thru', name: EDeliveryType.DRIVE_THROUGH },
];

export function isDelivered(delivery) {
  return delivery.status === EDeliveryStatus.DELIVERED;
}

const getConfirmedByDriverTime = (delivery: IDelivery): Date | null => {
  const assignedAtLog = (delivery.status_logs || []).find(
    (logItem) => logItem.status === EDeliveryStatus.ARRIVING
  );

  if (!assignedAtLog || delivery.status !== EDeliveryStatus.ARRIVING) {
    return null;
  }

  return new Date(assignedAtLog.created_at);
};

const getDriverTimeToArrive = (delivery: IDelivery) => {
  const assignedAtLog = (delivery.status_logs || []).find(
    (logItem) => logItem.status === EDeliveryStatus.ASSIGNED
  );

  if (
    !assignedAtLog ||
    !delivery.arriving_in ||
    ![EDeliveryStatus.ASSIGNED, EDeliveryStatus.ARRIVING].includes(
      delivery.status
    )
  ) {
    return null;
  }

  return differenceInMinutes(
    addMinutes(new Date(assignedAtLog.created_at), delivery.arriving_in),
    new Date()
  );
};

const Delivery = {
  isNew: (d: IDelivery) => d.status === EDeliveryStatus.NEW,
  isAssigned: (d: IDelivery) => d.status === EDeliveryStatus.ASSIGNED,
  isArriving: (d: IDelivery) => d.status === EDeliveryStatus.ARRIVING,
  isArrived: (d: IDelivery) => d.status === EDeliveryStatus.ARRIVED,
  isOnTheWay: (d: IDelivery) => d.status === EDeliveryStatus.ON_THE_WAY,
  isDeclined: (d: IDelivery) => d.status === EDeliveryStatus.DECLINED,
  isDelivered,
  getConfirmedByDriverTime,
  getDriverTimeToArrive,
};

export default Delivery;
