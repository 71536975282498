import React from 'react';
import i18next from 'i18next';

import { toDateTimeInputValue } from 'utils/date-time';
import Shapes from 'shapes/main';
import type { IDelivery } from 'types';

type DeliveryHistoryProps = {
  delivery: IDelivery;
}

const DeliveryHistory: React.FC<DeliveryHistoryProps> = (props) => {
  if (!props.delivery.status_logs) {
    return null;
  }

  return (
    <ul className="unstyled list-unstyled">
      {props.delivery.status_logs.map((item) => {
        const createdAt = toDateTimeInputValue(new Date(item.created_at));
        const status = i18next.t(
          `delivery.restaurant-view.statuses.${item.status}`
        );
        return (
          <li key={item.status}>
            {status} {createdAt}
          </li>
        );
      })}
    </ul>
  );
};

DeliveryHistory.propTypes = {
  delivery: Shapes.deliveryShape.isRequired,
};

DeliveryHistory.displayName = 'DeliveryHistory';

export default DeliveryHistory;
