import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import i18next from 'i18next';

import NavSpacer from './components/NavSpacer';
import NavOverlay from './components/NavOverlay';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import type { INavItem } from 'nav';
import createLinkComposed, { LinkComponentType } from '../createLinkComposed';

const NavBrand = ({
  logo,
  LinkComponent,
}: {
  logo: React.ReactNode;
  LinkComponent: LinkComponentType;
}) => {
  const LinkComposed = createLinkComposed(LinkComponent);
  return (
    <div className="site-logo-bar">
      <LinkComposed to="/" className="navbar-brand">
        {logo}
      </LinkComposed>
    </div>
  );
};

type SidebarNavProps = {
  isSidebarCollapsed: boolean;
  hasAlert: boolean;
  nav: {
    top: INavItem[];
    bottom: INavItem[];
  };
  logo: React.ReactNode;
  LinkComponent: LinkComponentType;
  toggleSidebar: () => void;
};

export default class SidebarNav extends Component<SidebarNavProps, {}> {
  getSingleItemTranslated = (item) => ({
    ...item,
    name: i18next.t(`nav.${item.name}`),
  });
  getDropdownItemTranslated = (item) => ({
    ...item,
    name: i18next.t(`nav.${item.name}`),
    children: item.children.map(this.getSingleItemTranslated),
  });

  render() {
    const {
      isSidebarCollapsed,
      hasAlert,
      logo,
      nav,
      LinkComponent,
      toggleSidebar,
    } = this.props;

    const renderNavItems = (items: INavItem[]) =>
      items.map((item, index) => {
        if ('children' in item && item.children) {
          return (
            <NavDropdownItem
              key={index}
              item={this.getDropdownItemTranslated(item)}
              isSidebarCollapsed={this.props.isSidebarCollapsed}
              LinkComponent={LinkComponent}
            />
          );
        } else if ('divider' in item) {
          return <NavDivider key={index} />;
        }

        return (
          <NavSingleItem
            item={{
              ...this.getSingleItemTranslated(item),
              badge: item.isNew
                ? {
                    text: 'Nowość',
                    color: 'primary',
                  }
                : undefined,
            }}
            LinkComponent={LinkComponent}
            key={index}
          />
        );
      });

    const hasPageAlertClass = hasAlert ? 'has-alert' : '';
    return (
      <div>
        <div className={`app-sidebar ${hasPageAlertClass}`}>
          <NavBrand logo={logo} LinkComponent={LinkComponent} />
          <nav>
            <ul id="main-menu">
              {renderNavItems(nav.top)}
              <NavSpacer />
              {renderNavItems(nav.bottom)}
            </ul>
          </nav>
        </div>
        {isSidebarCollapsed && <NavOverlay onClick={toggleSidebar} />}
      </div>
    );
  }
}
