import { fixDecimal } from 'utils/general';
import { currency } from 'globals/currency';
import { SelectColumnFilter, TSimpleTableColumnFilterProps } from './SimpleTableFitlers';
import { format } from 'date-fns';

export interface IColumn {
  accessor: string;
  Header: string;
  Cell?: Function;
  disableFilters?: boolean;
  Filter?: React.ComponentType<TSimpleTableColumnFilterProps>;
}

export interface IColumnOptions {
  customHeader?: string;
  customCellFormatter?: Function;
  shouldFixDecimal?: boolean;
  filterType?: 'text' | 'select' | 'none';
}

export const generateColumn = (
  fieldName: string,
  options: IColumnOptions = {}
): IColumn => {
  const {
    customHeader,
    customCellFormatter,
    filterType,
    shouldFixDecimal = false,
  } = options;
  const alwaysProps: IColumn = {
    Header:
      customHeader || fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
    accessor: fieldName,
    disableFilters: filterType === 'none',
  };

  if (filterType === 'select') {
    alwaysProps.Filter = SelectColumnFilter;
  }

  if (customCellFormatter || shouldFixDecimal) {
    return {
      ...alwaysProps,
      Cell: !!customCellFormatter
        ? customCellFormatter
        : (props) => (
            <div>
              {fixDecimal(props.value)} {currency}
            </div>
          ),
    };
  }
  return alwaysProps;
};

export interface ReactTableCellProps<Type> {
  cell: {
    row: {
      original: Type;
    };
  };
  value: any;
}

export function getRTOriginal<Type>(props: ReactTableCellProps<any>): Type {
  return props.cell.row.original;
}

export function TableDateFormatHelper<T>(
  field: keyof T,
  desiredFormat: string = 'yyyy-MM-dd HH:mm'
) {
  return (props: ReactTableCellProps<T>) => {
    const data = getRTOriginal<T>(props);
    const dateValue = data[field] as unknown as Date; // Type assertion to Date
    return format(new Date(dateValue), desiredFormat);
  };
}
