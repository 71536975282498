import React from 'react';

import Restaurant from 'utils/restaurant';

import OrderCardCallCourrierBtn from '../components/orders/OrderCardCallCourrierBtn';
import OrderCourrierStatus from '../components/orders/OrderCourrierStatus';
import type {
  IDelivery,
  IDeliveryCreateData,
  IOrder,
  IOrderCustomer,
  NormalizedRestaurant,
} from 'types';
import { useAppSelector } from 'hooks/useRedux';
import { selectDeliveries } from 'store/deliveries';

type ViewOrderCourrierDetailsProps = {
  onDeliveryAdd: (
    data: IDeliveryCreateData,
    restaurant: NormalizedRestaurant
  ) => Promise<IDelivery>;
  onUpdateOrderCustomer: (order: IOrder, customer: IOrderCustomer) => void;
  order: IOrder;
  restaurant: NormalizedRestaurant;
};
const ViewOrderCourrierDetails: React.FC<ViewOrderCourrierDetailsProps> = ({
  onDeliveryAdd,
  onUpdateOrderCustomer,
  order,
  restaurant,
}) => {
  const deliveries = useAppSelector(selectDeliveries);

  const onDeliveryAddAndUpdateOrder = (
    deliveryData: IDeliveryCreateData,
    restaurant: NormalizedRestaurant
  ) => {
    return onDeliveryAdd(deliveryData, restaurant).then((createdDelivery) => {
      onUpdateOrderCustomer(order, {
        ...order.customer,
        courrierCalled: new Date().toString(),
      });
      return createdDelivery;
    });
  };

  return (
    <>
      {Restaurant.canOrderDelivery(restaurant) && (
        <>
          {order.customer.courrierCalled ? (
            <OrderCourrierStatus deliveries={deliveries} order={order} />
          ) : (
            <div className="mb-3 empty-m-0">
              <OrderCardCallCourrierBtn
                addDelivery={onDeliveryAddAndUpdateOrder}
                order={order}
                restaurant={restaurant}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ViewOrderCourrierDetails;
