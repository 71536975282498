import React from 'react';

export const SafeNewTabLink: React.FC<{
  url: string;
}> = props => (
  <a href={props.url} target="_blank" rel="noopener noreferrer">
    {props.children || props.url}
  </a>
);

export const TutorialLinkGroup: React.FC<{
  img?: string;
  label: string;
  len?: string;
  tutorialLink: string;
  valueLink: string;
}> = ({ img, label, len = '', tutorialLink, valueLink }) => <div className="mb-2 bt-1">
  <strong>{label}:</strong>
  {img && <img src={img} alt="" className="my-2" />}
  <div>
    <span>Filmik jak ustawić{!!len && ` (${len})`}: </span><SafeNewTabLink url={tutorialLink} />
  </div>
  <div>
    <span>Link do wklejenia: </span><SafeNewTabLink url={valueLink} />
  </div>
</div>;
