import React from 'react';
import PropTypes from 'prop-types';

interface IProps {
  onSuccess?: () => void;
  style?: React.CSSProperties;
  textToCopy: string;
}

export const ClickToCopy: React.FC<IProps> = props => {
  const { onSuccess, style, textToCopy } = props;

  const handleCopy = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      onSuccess && onSuccess()
    })
  }

  return (
    <div style={{
      cursor: 'pointer',
      ...style,
    }}
    onClick={() => handleCopy(textToCopy)}>
      {props.children}
    </div>
  );
};

ClickToCopy.propTypes = {
  onSuccess: PropTypes.func,
  textToCopy: PropTypes.string.isRequired,
};

export default ClickToCopy;
