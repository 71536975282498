import React, { useState } from 'react';
import { CardBody, Col } from 'reactstrap';

import Restaurant from 'utils/restaurant';
import CollapsibleCard from '../common/CollapsibleCard';
import LocationSearchInput from './LocationSearchInput';
import Shapes from 'shapes/main';
import { NormalizedRestaurant } from 'types';
import { OTO_PRODUCTS } from 'enums';

interface CheckDeliveryPriceProps {
  restaurant: NormalizedRestaurant;
}

const CheckDeliveryPrice: React.FC<CheckDeliveryPriceProps> = ({
  restaurant,
}) => {
  const [address, setAddress] = useState({
    street: '',
    homeNumber: '',
    flatNumber: '',
  });

  if (
    !Restaurant.hasProduct(restaurant, OTO_PRODUCTS.foodeli_deliveries) &&
    !Restaurant.hasProduct(restaurant, OTO_PRODUCTS.szamaexpress_deliveries)
  ) {
    return null;
  }

  return (
    <CollapsibleCard title="Sprawdź odległość i cenę dostawy">
      <CardBody className="row">
        <Col xs="12" md="5">
          <LocationSearchInput
            address={address}
            onAddressUpdate={setAddress}
            restaurant={restaurant}
          />
        </Col>
        <Col xs="12" md="7" className="p-0 pl-md-3">
          <p>
            Dla wezwania kuriera Foodeli na zamówienie spoza OtoStolik zaloguj
            się bezpośrednio do aplikacji Foodeli.
          </p>
          <p>
            Formularza obok możesz użyć dla obliczenia ceny dostawy dla klienta.
          </p>
        </Col>
      </CardBody>
    </CollapsibleCard>
  );
};

CheckDeliveryPrice.propTypes = {
  restaurant: Shapes.restaurantShape.isRequired,
};

export default CheckDeliveryPrice;
