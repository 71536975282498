import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Col, Row } from 'reactstrap';

import CollapsibleCard from 'app/components/common/CollapsibleCard';
import { getPaymentMethodLabel } from 'utils/orders';
import { currency } from 'globals/currency';
import { fixDecimal } from 'utils/general';
import OrderDelivery from '../orders/order-parts/OrderDelivery';
import OrderPaymentType from '../orders/order-parts/OrderPaymentType';
import OrderTotal from '../orders/order-parts/OrderTotal';
import PromoCodeText from '../orders/PromoCodeText';
import OrderSource from '../orders/order-parts/OrderSource';

class OrdersHistory extends React.Component<{ data: any }, {}> {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data: statisticsData } = this.props;
    return Object.entries(statisticsData.statsByDay || {}).map(
      ([date, item]) => (
        <React.Fragment key={date}>
          {this.renderOrdersCard(date, item)}
          {this.renderDeliveriesCard(date, item)}
        </React.Fragment>
      )
    );
  }

  renderOrdersCard(date, item) {
    if (!item.orders || !item.orders.length) {
      return null;
    }
    return (
      <CollapsibleCard
        title={`Zamówienia z dnia ${date} - ${
          item.orders_count
        }szt. | Łączny koszt: ${fixDecimal(
          item.orders_sum
        )} ${currency} | Do wypłaty: ${item.payout_amount} ${currency}`}
        className="mt-3"
      >
        {item.orders.map((order) => (
          <Row key={order.id} className="bt-grey-1">
            <Col>
              <div>
                {i18next.t('order.title')} #{order.id}: {order.created_at}
              </div>
              <OrderDelivery order={order} />
              <OrderPaymentType order={order} />
              <OrderSource order={order} />
            </Col>
            <Col>
              <PromoCodeText order={order} />
              {order.products.map(this.renderProductRow)}
              <OrderTotal order={order} />
            </Col>
          </Row>
        ))}
      </CollapsibleCard>
    );
  }

  renderProductRow = (product) => {
    const hasDiscount = !!product.discountAmount;
    const fullPrice = fixDecimal(product.amount * product.price);
    const discountPart = hasDiscount
      ? `(pełna cena ${fullPrice}, rabat ${product.discountAmount})`
      : null;
    const pricePart = hasDiscount
      ? fixDecimal(product.amount * product.price - product.discountAmount)
      : fullPrice;
    return (
      <div key={product.name}>
        {product.amount} x {product.name}: {pricePart} {currency} {discountPart}
      </div>
    );
  };

  renderDeliveriesCard(date, item) {
    if (!item.deliveries || !item.deliveries.length) {
      return null;
    }
    return (
      <CollapsibleCard
        title={`Dostawy z dnia ${date} - ${item.deliveries.length} szt.`}
      >
        {item.deliveries.map((delivery) => (
          <Row key={delivery.id}>
            <Col>
              {
                delivery.type === 'internal'
                  ? `Dostawa #${delivery.id} - dostawa zamówienia ${delivery.order_id}`
                  : `Dostawa #${delivery.id} - dostawa zewnętrzna ${
                      delivery.address
                    }.
                      Kwota: ${delivery.price} ${currency}.
                      Sposób zapłaty: ${i18next.t(
                        `payment-types.${getPaymentMethodLabel(
                          delivery.payment_status
                        )}`
                      )}.` /* TODO probably should use payment_type */
              }
            </Col>
          </Row>
        ))}
      </CollapsibleCard>
    );
  }
}

export default OrdersHistory;
