import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, FormGroup, Input, Label } from 'reactstrap';

import Logger from 'utils/log';
import InputWithOptions from 'app/components/common/InputWithOptions';
import OtoSpinner from 'app/components/common/OtoSpinner';
import { API_URL } from 'config';
import APIService from 'services/api';

const SendTestPushNotification = () => {
  const [title, setTitle] = useState('Testowa wiadomość push');
  const [body, setBody] = useState(
    'Otrzymano testowe zamówienie. Ta część wiadomości może być dłuższa.'
  );

  const [delay, setDelay] = useState(30);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    const url = '/users/me/notifications/';
    const payload = {
      title,
      body,
      data: {
        os: 'web',
        type: 'test',
      },
      delay,
    };
    const domain = API_URL.replace('api.', '')

    setLoading(true);
    APIService.post(url, payload, { domain })
      .then(() =>
        toast.success(
          `Wysłano powiadomienie testowe o treści: ${title} z opóźnieniem ${delay} sekund`
        )
      )
      .catch((e) => {
        toast.error('Wystąpił błąd podczas wysyłania powiadomienia testowego');
        Logger.fetchError({ e, url });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <FormGroup>
        <Label>Tytuł wiadomości</Label>
        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      </FormGroup>
      <FormGroup>
        <Label>Treść wiadomości</Label>
        <Input
          type="textarea"
          rows="3"
          value={body}
          onChange={(e) => setBody(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Opóźnienie w wysyłce (sekundy)</Label>
        <InputWithOptions
          options={[0, 30, 60, 120, 300]}
          value={delay}
          onChange={(e) => setDelay(parseInt(e.target.value, 10))}
        />
      </FormGroup>
      <Button
        type="button"
        className="align-vertical"
        onClick={handleSubmit}
        disabled={loading}
      >
        Wyślij
        {loading && <OtoSpinner className="ml-2" />}
      </Button>
    </>
  );
};

export default SendTestPushNotification;
