import React from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import type { InputProps } from 'reactstrap/lib';

type InputWithAddonProps = InputProps & {
  addonText?: React.ReactNode;
  addonType?: 'append' | 'prepend';
  wrapperClassName?: string;
};

export const InputWithAddon: React.FC<InputWithAddonProps> = (props) => {
  const {
    addonText,
    addonType = 'prepend',
    wrapperClassName,
    ...otherProps
  } = props;
  const addon = !!addonText && (
    <InputGroupAddon addonType={addonType}>
      <InputGroupText>{addonText}</InputGroupText>
    </InputGroupAddon>
  );
  return (
    <InputGroup className={wrapperClassName || ''}>
      {addonType === 'prepend' && addon}
      <Input {...otherProps} />
      {addonType === 'append' && addon}
    </InputGroup>
  );
};

InputWithAddon.defaultProps = {
  addonType: 'append',
};

InputWithAddon.propTypes = {
  addonText: PropTypes.node,
  addonType: PropTypes.oneOf(['append', 'prepend']),
  // @ts-ignore not sure how to type this properly, there's InputType, which cannot be used as a value
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wrapperClassName: PropTypes.string,
};

export default InputWithAddon;
