export interface IPromoCode {
  activated_at: string;
  available_days?: string[] | null;
  available_sources?: string[] | null;
  code: string;
  created_at: string;
  customer_id?: number | null;
  deactivated_at: string;
  deleted_at?: string | null;
  description?: string | null;
  discount: number | null;
  discount_type: 'value' | 'percent' | 'freeDelivery';
  id: number;
  issuer: null | 'otostolik' | 'lesznoje' | 'lubje' | 'ostrowje' | 'restaurant';
  min_cart_amount: number | null;
  orders_count: number;
  redeemed: boolean;
  refund_amount: number | null;
  refund_type?: string | null;
  restaurant_id: number;
  updated_at: string;
  usage_type: UsageType;
}

export enum UsageType {
  Single = 'single',
  SinglePerCustomer = 'single_per_customer',
  ForNewCustomer = 'for_new_customer',
  Multiple = 'multiple',
}
