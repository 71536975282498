import React, { useMemo, useState } from 'react';
import { Form, FormikProps, withFormik, FormikBag } from 'formik';
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  CardHeader,
  Row,
} from 'reactstrap';

import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import { ROLES } from 'enums';
import OtoAutocomplete from '../common/OtoAutocomplete';
import { citiesById } from 'config';
import UserRoleSelect from './UserRoleSelect';

export type AddUserRestaurantFormValues = {
  user_id: number | null;
  restaurant_id: number | null;
  role_id: number;
};

interface AddUserRestaurantFormOwnProps {
  users: {
    id: number;
    name: string;
    email: string;
  }[];
  restaurants: {
    id: number;
    name: string;
    city_id: number;
  }[];
  onAddUserRestaurant: (user: AddUserRestaurantFormValues) => void;
}

type AddUserRestaurantFormProps = AddUserRestaurantFormOwnProps &
  FormikProps<AddUserRestaurantFormValues>;

const AddUserRestaurantFormWithoutFormik: React.FC<
  AddUserRestaurantFormProps
> = (props) => {
  const {
    isSubmitting,
    handleChange,
    restaurants,
    setFieldValue,
    users,
    values,
  } = props;
  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const restaurantOptions = useMemo(
    () =>
      restaurants.map((restaurant) => ({
        id: restaurant.id,
        name: `${restaurant.name} (${restaurant.id}) ${
          citiesById[restaurant.city_id]?.name || 'Inne miasto'
        }`,
      })),
    [restaurants]
  );

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        name: `${user.name} ${user.email} (${user.id})`,
      })),
    [users]
  );

  return (
    <Card>
      <CardHeader>{'Nadaj użytkownikowi dostęp do restauracji'}</CardHeader>
      <CardBody>
        <Form>
          <Row className="mx-0 d-flex flex-wrap">
            <FormGroup className="col min-w-200">
              <Label>
                Wybrana restauracja: {values.restaurant_id || 'nie wybrano'}
              </Label>
              <OtoAutocomplete
                options={restaurantOptions}
                isSingle={true}
                isObject={true}
                value={selectedRestaurant}
                onChange={(value) => {
                  if (value) {
                    setSelectedRestaurant(
                      value as {
                        id: number;
                        name: string;
                      }
                    );
                    setFieldValue('restaurant_id', value.id);
                  } else {
                    setSelectedRestaurant(null);
                    setFieldValue('restaurant_id', null);
                  }
                }}
                fieldToDisplay={'name'}
                placeholder={'Wybierz restauracje'}
                style={{
                  searchBox: {
                    background: 'white',
                    marginBottom: '10px',
                  },
                }}
                emptyRecordMsg={'Nie wybrano restauracji'}
              />
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>
                Wybrany użytkownik: {values.user_id || 'nie wybrano'}
              </Label>
              <OtoAutocomplete
                options={userOptions}
                isSingle={true}
                isObject={true}
                value={selectedUser}
                onChange={(value) => {
                  if (value) {
                    setSelectedUser(
                      value as {
                        id: number;
                        name: string;
                      }
                    );
                    setFieldValue('user_id', value.id);
                  } else {
                    setSelectedUser(null);
                    setFieldValue('user_id', null);
                  }
                }}
                fieldToDisplay={'name'}
                placeholder={'Wybierz użytkownika'}
                style={{
                  searchBox: {
                    background: 'white',
                    marginBottom: '10px',
                  },
                }}
                emptyRecordMsg={'Nie wybrano użytkownika'}
              />
            </FormGroup>
            <UserRoleSelect
              name={'role_id'}
              onChange={handleChange}
              value={values.role_id}
            />
          </Row>
          <FormGroup className="col-12">
            <SubmitButtonWithLoader
              label="Przypisz użytkownika do restauracji"
              loading={isSubmitting}
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

const AddUserRestaurantForm = withFormik<
  AddUserRestaurantFormOwnProps,
  AddUserRestaurantFormValues
>({
  mapPropsToValues: (props: AddUserRestaurantFormOwnProps) => ({
    role_id: ROLES.RESTAURANT_ADMIN,
    restaurant_id: null,
    user_id: null,
  }),
  handleSubmit: (
    values: AddUserRestaurantFormValues,
    {
      props,
      setSubmitting,
    }: FormikBag<AddUserRestaurantFormOwnProps, AddUserRestaurantFormValues>
  ) => {
    return props.onAddUserRestaurant(values);
  },
})(AddUserRestaurantFormWithoutFormik);

export default AddUserRestaurantForm;
