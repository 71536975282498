import React from 'react';
import i18next from 'i18next';

import { Button } from 'reactstrap';
import OtoSpinner from './OtoSpinner';

export type SubmitButtonWithLoaderProps = {
  className?: string;
  color?: string;
  disabled?: boolean;
  label?: string;
  loading: boolean;
};

export const SubmitButtonWithLoader: React.FC<SubmitButtonWithLoaderProps> = ({
  loading,
  label,
  ...restProps
}) => {
  return loading ? (
    <OtoSpinner />
  ) : (
    <Button {...restProps} type="submit">
      {label || i18next.t('Save')}
    </Button>
  );
};

export default SubmitButtonWithLoader;
