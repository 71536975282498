import React from 'react';

import { useAppSelector } from 'hooks/useRedux';
import { selectReservations } from 'store/reservations';
import ReservationsTable from 'app/components/reservations/ReservationsTable';
import { selectRestaurant } from 'store/restaurant';
import { FREQUENCY } from 'config';
import ViewReservationsFetcherWithRangeSelect from 'app/views/ViewReservationsFetcherWithRangeSelect';
import Restaurant from 'utils/restaurant';
import ReservationsMarketingAgreementStatistics from 'app/components/reservations/ReservationsMarketingAgreementStatistics';

const FindReservationPage = () => {
  const restaurant = useAppSelector(selectRestaurant);
  const reservations = useAppSelector(selectReservations);

  if (!restaurant?.config) {
    return null;
  }

  return (
    <>
      <ViewReservationsFetcherWithRangeSelect
        config={restaurant.config}
        defaultRange={'1-year'}
        frequency={FREQUENCY.REFRESH}
        includeTablesAndTheirPlaces={false}
        restaurant={restaurant}
        showToastOnError={true}
      />
      {Restaurant.wantsToCollectMarketingAgreements(restaurant) && (
        <ReservationsMarketingAgreementStatistics reservations={reservations} />
      )}
      <ReservationsTable
        bookingConfig={restaurant.config}
        showMarketingAgreements={Restaurant.wantsToCollectMarketingAgreements(
          restaurant
        )}
        reservations={reservations}
      />
    </>
  );
};

export default FindReservationPage;
