import React from 'react';

import { useAppSelector } from 'hooks/useRedux';
import { selectIsDebug } from 'store/app';

const DebugModeOnly: React.FC<{ children?: React.ReactNode }> = (props) => {
  const isDebug = useAppSelector(selectIsDebug);
  if (!isDebug) {
    return null;
  }
  return <>{props.children}</>;
};

export default DebugModeOnly;
