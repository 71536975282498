import { fixDecimal } from './general';

export function sortArrayByField(arr: any[], fieldName: string): Array<object> {
  return arr.sort((a, b) => (a[fieldName] > b[fieldName] ? -1 : 1));
}

export function getArrayAvg(arr: Array<number>): number {
  return arr.reduce((acc, item) => acc + item, 0) / arr.length;
}

export function countArrayItemsByField(arr: any[], field: string): object {
  const reducer = (dict: any, item: any) => {
    if (!dict[item[field]]) {
      dict[item[field]] = 0;
    }
    dict[item[field]]++;
    return dict;
  };
  return arr.reduce(reducer, {});
}

export function sumArrayItemsByField(
  arr: Array<object>,
  fieldToGroupBy: string,
  fieldToSum: string
): object {
  const reducer = (dict: any, item: any) => {
    if (!dict[item[fieldToGroupBy]]) {
      dict[item[fieldToGroupBy]] = 0;
    }
    dict[item[fieldToGroupBy]] += item[fieldToSum];
    return dict;
  };
  return arr.reduce(reducer, {});
}

export interface GroupedItem {
  label: string;
  amount: number;
  sum: number;
}

const groupArrayByField = (
  array: Array<object>,
  fieldToGroupBy: string,
  fieldToSum: string
): Array<GroupedItem> => {
  const amountStats: any = countArrayItemsByField(array, fieldToGroupBy);
  const sumStats: any = sumArrayItemsByField(array, fieldToGroupBy, fieldToSum);

  return Object.keys(amountStats).map((label) => ({
    label,
    amount: amountStats[label],
    sum: fixDecimal(sumStats[label]),
  }));
};

const groupArrayByFields = (
  arr: Array<object>,
  fieldsToGroupBy: Array<string>,
  fieldToSum: string
): Array<any> => {
  return Array.from(
    arr
      .reduce((r: Map<string, any>, o: any) => {
        const key = fieldsToGroupBy.map((field) => o[field]).join('-');

        const item = r.get(key) || {
          ...o,
          amount: 0,
          [fieldToSum]: 0,
        };

        item.amount++;
        item[fieldToSum] += o[fieldToSum];

        return r.set(key, item);
      }, new Map())
      .values()
  );
};

export function getArrayWithUpdatedObject(array: any[], updatedElement: any) {
  const elementIndex = array.findIndex((el) => el.id === updatedElement.id);
  if (elementIndex === -1) {
    return [...array, updatedElement];
  }
  return [
    ...array.slice(0, elementIndex),
    updatedElement,
    ...array.slice(elementIndex + 1),
  ];
}

export function getArrayWithoutObject<T extends { id: number | string }>(
  array: T[],
  element: any
): T[] {
  const elementIndex = array.findIndex((el) => el.id === element.id);
  return [...array.slice(0, elementIndex), ...array.slice(elementIndex + 1)];
}

const ArrayHelpers = {
  countArrayItemsByField,
  getArrayAvg,
  getArrayWithUpdatedObject,
  getArrayWithoutObject,
  groupArrayByField,
  groupArrayByFields,
  sortArrayByField,
  sumArrayItemsByField,
};

export default ArrayHelpers;
