import type { TRestaurantPlacesAreaConfig } from 'types';

import warszawaWschodniaMainHall from './warszawa-wschodnia.png';

export const warszawaWschodniaAreasConfig: TRestaurantPlacesAreaConfig = {
  130: {
    image: {
      src: warszawaWschodniaMainHall,
      height: 1655,
      width: 2339,
    },
    tablesConfig: {
      1023: {
        position: {
          x: '1.9%',
          y: '90.2%',
        },
      },
      1024: {
        position: {
          x: '1.92%',
          y: '82.55%',
        },
      },
      1025: {
        position: {
          x: '1.99%',
          y: '68.99%',
        },
      },
      1029: {
        position: {
          x: '1.99%',
          y: '61.48%',
        },
      },
      1030: {
        position: {
          x: '1.99%',
          y: '44.76%',
        },
      },
      1031: {
        position: {
          x: '2.05%',
          y: '33.91%',
        },
      },
      1032: {
        position: {
          x: '1.92%',
          y: '23.33%',
        },
      },
      1033: {
        position: {
          x: '1.86%',
          y: '12.39%',
        },
      },
      1034: {
        position: {
          x: '14.95%',
          y: '12.57%',
        },
      },
      1035: {
        position: {
          x: '15.01%',
          y: '23.15%',
        },
      },
      1036: {
        position: {
          x: '15.07%',
          y: '34.36%',
        },
      },
      1037: {
        position: {
          x: '15.07%',
          y: '45.57%',
        },
      },
      1038: {
        position: {
          x: '27.07%',
          y: '62.84%',
        },
      },
      1039: {
        position: {
          x: '27.07%',
          y: '52.35%',
        },
      },
      1040: {
        position: {
          x: '27%',
          y: '42.5%',
        },
      },
      1041: {
        position: {
          x: '27%',
          y: '32.64%',
        },
      },
      1042: {
        position: {
          x: '27.13%',
          y: '22.69%',
        },
      },
      1043: {
        position: {
          x: '27%',
          y: '12.57%',
        },
      },
      1044: {
        position: {
          x: '38.04%',
          y: '70.34%',
        },
      },
      1045: {
        position: {
          x: '38.17%',
          y: '59.95%',
        },
      },
      1046: {
        position: {
          x: '38.17%',
          y: '50.18%',
        },
      },
      1047: {
        position: {
          x: '38.23%',
          y: '40.24%',
        },
      },
      1048: {
        position: {
          x: '38.1%',
          y: '30.2%',
        },
      },
      1049: {
        position: {
          x: '38.17%',
          y: '20.16%',
        },
      },
      1050: {
        position: {
          x: '38.17%',
          y: '10.31%',
        },
      },
      1051: {
        position: {
          x: '38.17%',
          y: '0.18%',
        },
      },
      1052: {
        position: {
          x: '50.16%',
          y: '67.54%',
        },
      },
      1053: {
        position: {
          x: '50.16%',
          y: '56.06%',
        },
      },
      1054: {
        position: {
          x: '50%',
          y: '44.5%',
        },
      },
      1055: {
        position: {
          x: '50%',
          y: '33%',
        },
      },
      1056: {
        position: {
          x: '50.16%',
          y: '20.75%',
        },
        size: {
          width: '10.5%',
          height: '7%',
        },
      },
      1057: {
        position: {
          x: '50.16%',
          y: '0.3%',
        },
        size: {
          width: '5%',
          height: '14%',
        },
      },
      1058: {
        position: {
          x: '66.45%',
          y: '59.67%',
        },
      },
      1059: {
        position: {
          x: '66.52%',
          y: '49.82%',
        },
      },
      1060: {
        position: {
          x: '66.58%',
          y: '39.24%',
        },
      },
      1061: {
        position: {
          x: '66.45%',
          y: '28.57%',
        },
      },
      1062: {
        position: {
          x: '65.68%',
          y: '13.74%',
        },
      },
      1063: {
        position: {
          x: '65.75%',
          y: '0.54%',
        },
      },
    },
  },
};
