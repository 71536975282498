import React, { useState, useCallback } from 'react';
import { FormGroup, Label } from 'reactstrap';

import UrlFetcher from '../../containers/UrlFetcher';
import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';

import InputWithOptions from 'app/components/common/InputWithOptions';
import OrdersTable from 'app/components/orders/OrdersTable';
import { updateOrderStatus } from 'store/orders';
import { IOrder, IOrderWithSource } from 'types/order';
import { getArrayWithUpdatedObject } from 'utils/array';
import { selectIsLesznojeAdmin } from 'store/app';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { AnyRestaruant } from 'types';

const selectOptions = [25, 50, 100, 200];

interface IOrdersDeclinationPageProps {
  orders: IOrderWithSource[];
  setData: Function;
  setModal: TSetModalFunction;
}

const OrdersDeclinationPage = (props: IOrdersDeclinationPageProps) => {
  const { orders, setData, setModal } = props;
  const dispatch = useAppDispatch();

  const onOrderDecline = useCallback(
    async (
      order: IOrder,
      status: IOrder['status'],
      restaurant: AnyRestaruant,
      extra
    ) => {
      //@TODO check if this works
      const updatedOrder = (await dispatch(
        updateOrderStatus(order, status, restaurant, extra)
      )) as unknown as IOrder;
      setData(getArrayWithUpdatedObject(orders, updatedOrder));
    },
    [orders, setData, dispatch]
  );

  const isLesznoAdmin = useAppSelector(selectIsLesznojeAdmin);

  const filteredOrders = isLesznoAdmin
    ? orders.filter((order) => order.source_rel.tenant === 'lesznoje')
    : orders;

  return (
    <OrdersTable
      orders={filteredOrders}
      onOrderDecline={onOrderDecline}
      setModal={setModal}
      showCustomer={true}
      showDeclinationIfPresent={true}
      showPaymentDetails={true}
      showRestaurant={true}
      showTenant={true}
    />
  );
};

const OrdersDeclinationPageWithFetchedData = (props: any) => {
  const [amountToFetch, setAmountToFetch] = useState<number | string>(50);
  return (
    <>
      <FormGroup>
        <Label for="rows">Ile zamówień pobrać</Label>
        <InputWithOptions
          options={selectOptions}
          className="mw-200"
          type="number"
          step="0.1"
          id="rows"
          name="rows"
          placeholder="Wpisz wartość"
          value={amountToFetch}
          onChange={(e) =>
            setAmountToFetch(
              e?.target?.value ? parseInt(e.target.value, 10) : ''
            )
          }
        />
      </FormGroup>
      <hr />
      <UrlFetcher url={`/admin/orders?amount=${amountToFetch}`}>
        {([orders, urlFetcherProps]) => (
          <OrdersDeclinationPage
            orders={orders}
            {...urlFetcherProps}
            {...props}
          />
        )}
      </UrlFetcher>
    </>
  );
};

const EnhancedOrdersDeclinationPage = withSetModal(
  OrdersDeclinationPageWithFetchedData
);

EnhancedOrdersDeclinationPage.navName = 'admin.orders-declination';
EnhancedOrdersDeclinationPage.url = '/admin/orders-declination';

export default EnhancedOrdersDeclinationPage;
