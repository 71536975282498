import type { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import { store } from 'store/index';
import type {
  AddReservationFormValues,
  IPlace,
  ITable,
  NormalizedRestaurant,
  ReservationProposalParams,
  TTableSuggestion,
  TTableSuggestionWithPeopleAmount,
  TTableWithPeopleAmount,
} from 'types';
import Logger from 'utils/log';
import {
  addPeopleAmountToTableTitle,
  generateReservedOn,
} from 'utils/reservation';
import APIService from 'services/api';
import { IAppState } from './main';
import { CHANGE_RESTAURANT, LOGOUT } from './app';
import { selectRestaurantTables } from './restaurant';

export interface IReservationCalendarReducerState {
  addReservationFormValues: AddReservationFormValues | null;
  calendarPreferences: ReservationCalendarPreferences;
  findPlaceStep: 'step1' | 'step2' | 'full';
  findPlaceSelectedTableIds: number[];
  reservationProposal: null | ReservationProposalParams;
  selectedTables: TTableWithPeopleAmount[];
  tableSuggestions: TTableSuggestionWithPeopleAmount[];
  tableSuggestionsLoading: boolean;
  tableSuggestionParams: null | Omit<
    ReservationProposalParams,
    'selectedTables'
  >;
}

const savedPreferencesString = localStorage.getItem(
  'reservation-calendar-preferences'
);

let initialCalendarPreferences: ReservationCalendarPreferences = {
  activePlace: null,
  hideDisabledPlaces: false,
  showDeclinedReservations: true,
  showPeopleAmountOnMap: false,
  showReservationNotes: false,
  showReservationIfPresentOnMap: false,
  showReservationSuggestionsOnCalendar: true,
  timeFrom: '10:00',
  timeTo: '22:00',
};
try {
  if (savedPreferencesString) {
    Object.entries(JSON.parse(savedPreferencesString)).forEach(
      ([key, value]) => {
        initialCalendarPreferences = {
          ...initialCalendarPreferences,
          [key]: value,
        };
      }
    );
    initialCalendarPreferences.activePlace = null;
  }
} catch (e) {}

export const INITIAL_STATE: IReservationCalendarReducerState = {
  addReservationFormValues: null,
  calendarPreferences: initialCalendarPreferences,
  findPlaceStep: 'step1',
  findPlaceSelectedTableIds: [],
  reservationProposal: null,
  selectedTables: [],
  tableSuggestions: [],
  tableSuggestionsLoading: false,
  tableSuggestionParams: null,
};

const SELECT_PROPOSED_TABLES = 'reservationCalendar/SELECT_PROPOSED_TABLES';
const FIND_PLACE_GO_BACK = 'reservationCalendar/FIND_PLACE_GO_BACK';
const FIND_PLACE_PROCEED = 'reservationCalendar/FIND_PLACE_PROCEED';

const SHOW_RESERVATION_PROPOSAL_FOR_MANY_TABLES =
  'reservationCalendar/SHOW_RESERVATION_PROPOSAL_FOR_MANY_TABLES';

const TOGGLE_TABLE_SELECTION = 'reservationCalendar/TOGGLE_TABLE_SELECTION';
const SET_RESERVATION_FORM_VALUES =
  'reservationCalendar/SET_RESERVATION_FORM_VALUES';
const SET_SELECTED_TABLES = 'reservationCalendar/SET_SELECTED_TABLES';
const SET_TABLE_SUGGESTIONS_LOADING =
  'reservationCalendar/SET_TABLE_SUGGESTIONS_LOADING';
const SET_TABLE_SUGGESTIONS_SUCCESS =
  'reservationCalendar/SET_TABLE_SUGGESTIONS_SUCCESS';
const SET_TABLE_SUGGESTIONS_ERROR =
  'reservationCalendar/SET_TABLE_SUGGESTIONS_ERROR';

const UPDATE_RESERVATION_PREFERENCES =
  'reservationCalendar/UPDATE_RESERVATION_PREFERENCES';

const FIND_PLACE_RESET = 'reservationCalendar/FIND_PLACE_RESET';

type IReservationCalendarAction = {
  allTables: ITable[];
  formValues?: AddReservationFormValues;
  reservationProposal: ReservationProposalParams;
  reservationProposalParams:
    | TTableSuggestionWithPeopleAmount
    | ReservationProposalParams;
  selectedTable: TTableWithPeopleAmount;
  selectedTables: TTableWithPeopleAmount[];
  tableSuggestions: TTableSuggestion[];
  tableSuggestionParams: Omit<ReservationProposalParams, 'selectedTables'>;
  type: string;
  preferences: Partial<ReservationCalendarPreferences>;
};

export function addReservationReducer(
  state = INITIAL_STATE,
  action: IReservationCalendarAction
): IReservationCalendarReducerState {
  switch (action.type) {
    case SELECT_PROPOSED_TABLES:
      // TODO dirty hack, need to refactor
      const params = action.reservationProposalParams;
      return {
        ...state,
        findPlaceSelectedTableIds:
          'selectedTables' in params
            ? params.selectedTables.map((table) => table.id)
            : [params.id, ...(params.connect ? params.linked_to : [])],
        // reservationProposal: null,
        // tableSuggestions: [],
        // tableSuggestionParams: null,
      };
    case FIND_PLACE_GO_BACK:
      return {
        ...state,
        findPlaceStep: 'step1',
      };
    case FIND_PLACE_PROCEED:
      return {
        ...state,
        findPlaceStep: 'step2',
      };
    case TOGGLE_TABLE_SELECTION:
      const isPresent: boolean = !!state.selectedTables.find(
        (table) => table.id === action.selectedTable.id
      );
      return {
        ...state,
        selectedTables: isPresent
          ? state.selectedTables.filter(
              (table) => table.id !== action.selectedTable.id
            )
          : [...state.selectedTables, action.selectedTable],
      };
    case SET_RESERVATION_FORM_VALUES:
      return {
        ...state,
        addReservationFormValues:
          action.formValues || state.addReservationFormValues,
      };
    case SET_SELECTED_TABLES:
      return {
        ...state,
        selectedTables: action.selectedTables,
      };
    case SET_TABLE_SUGGESTIONS_LOADING:
      return {
        ...state,
        tableSuggestionsLoading: true,
        tableSuggestions: [],
      };
    case SET_TABLE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        tableSuggestions: formatSuggestions(
          action.tableSuggestions,
          action.allTables
        ),
        tableSuggestionsLoading: false,
      };
    case SET_TABLE_SUGGESTIONS_ERROR:
      return {
        ...state,
        tableSuggestions: [],
        tableSuggestionsLoading: false,
      };
    case SHOW_RESERVATION_PROPOSAL_FOR_MANY_TABLES:
      return {
        ...state,
        tableSuggestions: formatSuggestions(
          action.tableSuggestions,
          action.allTables
        ),
        tableSuggestionParams: action.tableSuggestionParams,
      };
    case UPDATE_RESERVATION_PREFERENCES:
      return {
        ...state,
        calendarPreferences: {
          ...state.calendarPreferences,
          ...action.preferences,
        },
      };
    case CHANGE_RESTAURANT:
    case FIND_PLACE_RESET:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

function formatSuggestions(suggestions: ITable[], allTables: ITable[]) {
  return suggestions.map(
    (table) =>
      addPeopleAmountToTableTitle(table, {
        addPlace: true,
        allTables,
      }) as TTableSuggestionWithPeopleAmount
  );
}

export type ReservationCalendarPreferences = {
  activePlace: IPlace | null;
  hideDisabledPlaces: boolean;
  showDeclinedReservations: boolean;
  showPeopleAmountOnMap: boolean;
  showReservationNotes: boolean;
  showReservationIfPresentOnMap: boolean;
  showReservationSuggestionsOnCalendar: boolean;
  timeFrom: string;
  timeTo: string;
};

export type TLoadAvailableTablesParams = {
  duration: number;
  visitDate: string;
  visitTime: string;
  guests_number: number;
};

export type TablesAvailability = 'suitable' | 'free' | 'all';

export const selectProposedTables = (
  params: TTableSuggestionWithPeopleAmount | ReservationProposalParams
) => ({
  type: SELECT_PROPOSED_TABLES,
  reservationProposalParams: params,
});

export const findPlaceGoBack = () => ({
  type: FIND_PLACE_GO_BACK,
});

export const findPlaceProceed = () => ({
  type: FIND_PLACE_PROCEED,
});

export const showReservationProposalForMultipleTables = (
  tableSuggestions: TTableSuggestion[],
  tableSuggestionParams: Omit<ReservationProposalParams, 'selectedTables'>
) => ({
  type: SHOW_RESERVATION_PROPOSAL_FOR_MANY_TABLES,
  allTables: selectRestaurantTables(store.getState() as IAppState),
  tableSuggestions,
  tableSuggestionParams,
});

export const setReservationFormValues = (values: AddReservationFormValues) => ({
  type: SET_RESERVATION_FORM_VALUES,
  formValues: values,
});

export const setSelectedTables = (
  selectedTables: TTableWithPeopleAmount[]
) => ({
  type: SET_SELECTED_TABLES,
  selectedTables,
});

export const toggleTableSelection = (
  selectedTable: TTableWithPeopleAmount
) => ({
  type: TOGGLE_TABLE_SELECTION,
  selectedTable,
});

export const resetFindPlace = () => ({
  type: FIND_PLACE_RESET,
});

export const updateCalendarPreferences =
  (payload: Partial<ReservationCalendarPreferences>) => (dispatch) => {
    const currentPrefs = (store.getState() as IAppState).reservationsCalendar
      .calendarPreferences;
    const newPreferences: ReservationCalendarPreferences = {
      ...currentPrefs,
      ...payload,
    };
    localStorage.setItem(
      'reservation-calendar-preferences',
      JSON.stringify(newPreferences)
    );
    dispatch({
      type: UPDATE_RESERVATION_PREFERENCES,
      preferences: payload,
    });
  };

export const loadTableSuggestions =
  (
    params: TLoadAvailableTablesParams,
    tablesAvailability: TablesAvailability
  ) =>
  (dispatch: Dispatch): Promise<TTableSuggestion[]> => {
    if (tablesAvailability === 'all') {
      return Promise.resolve([]);
    }
    if (!params.visitDate || !params.visitTime || !params.guests_number) {
      return Promise.resolve([]);
    }
    const restaurant = (store.getState() as IAppState).restaurant
      .restaurant as NormalizedRestaurant;

    const parts = [
      `date=${generateReservedOn(params)}`,
      `guests=${params.guests_number}`,
      `duration=${params.duration}`,
      `type=${tablesAvailability}`,
      'with=place',
    ];
    const url = `/restaurants/${restaurant.id}/tables?${parts.join('&')}`;
    dispatch({
      type: SET_TABLE_SUGGESTIONS_LOADING,
    });
    return APIService.get(url)
      .then((tables: TTableSuggestion[]) => {
        dispatch({
          type: SET_TABLE_SUGGESTIONS_SUCCESS,
          allTables: selectRestaurantTables(store.getState() as IAppState),
          tableSuggestions: tables,
        });
        return tables;
      })
      .catch((e) => {
        dispatch({
          type: SET_TABLE_SUGGESTIONS_ERROR,
        });
        toast.error('Wystąpił błąd podczas pobierania wolnych stolików');
        Logger.fetchError({ e, url });
        return [];
      });
  };

export const selectCalendarPreferences = (state: IAppState) =>
  state.reservationsCalendar.calendarPreferences;
export const selectTableSuggestionsLoading = (state: IAppState) =>
  state.reservationsCalendar.tableSuggestionsLoading;
export const selectTableSuggestions = (state: IAppState) =>
  state.reservationsCalendar.tableSuggestions;
export const selectTableSuggestionParams = (state: IAppState) =>
  state.reservationsCalendar.tableSuggestionParams;
export const selectReservationProposal = (state: IAppState) =>
  state.reservationsCalendar.reservationProposal;
export const selectSelectedTables = (state: IAppState) =>
  state.reservationsCalendar.selectedTables;

export const selectReservationFormValues = (state: IAppState) =>
  state.reservationsCalendar.addReservationFormValues;
