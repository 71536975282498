import React from 'react';

import DateTimeHelper from 'utils/date-time';
import AdminPromoCodesStatistics from 'app/components/admin-statistics/AdminPromoCodesStatistics';
import StatisticsRangeHeader from 'app/components/admin-statistics/StatisticsRangeHeader';
import WithDateRangeDataFetched from '../../../containers/WithDateRangeDataFetched';
import { Tenants } from 'store/tenants';

interface IProps {
  tenant: Tenants;
}

const PromoCodeStatsPage: React.FC<IProps> = ({ tenant }) => {
  const formatUrl = (start: Date, end: Date) =>
    `/statistics/promo-codes?from=${DateTimeHelper.toDateInputValue(
      start
    )}&to=${DateTimeHelper.toDateInputValue(end)}&tenant=${tenant}`;

  return (
    <div>
      <WithDateRangeDataFetched
        allowRefetch
        hideCalendarAfterFetch
        fetchOnMount={false}
        defaultRange="lastMonth"
        getUrl={formatUrl}
      >
        {(stats, { editRanges, ranges }) => (
          <>
            <StatisticsRangeHeader
              isEmpty={!stats}
              onEdit={editRanges}
              ranges={ranges}
            />
            <AdminPromoCodesStatistics data={stats as any} />
          </>
        )}
      </WithDateRangeDataFetched>
    </div>
  );
};

export default PromoCodeStatsPage;
