import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { ChevronUp } from 'react-feather';

interface CollapsibleCardProps {
  bodyClassName?: string;
  buttonClassName?: string;
  children: React.ReactNode | ((open: boolean) => React.ReactNode);
  className?: string;
  openByDefault?: boolean;
  onOpen?: () => void;
  tag?: 'button' | 'div';
  title: React.ReactNode;
  titleWithButtons?: boolean;
}

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  bodyClassName = '',
  buttonClassName = 'p-3',
  className = '',
  children,
  tag: Tag = 'button',
  openByDefault = false,
  onOpen,
  title,
  titleWithButtons = false,
}) => {
  const [open, setOpen] = useState(openByDefault);
  const toggleOpen = () => {
    onOpen?.();
    setOpen(!open);
  };
  const chevron = (
    <ChevronUp className={`rotate ${open ? '' : 'rotate--180'}`} />
  );
  const header = titleWithButtons ? (
    <CardHeader className="row mx-0 p-0 justify-content-between">
      {title}
      <Tag
        {...(Tag === 'button' ? { type: 'button' } : {})}
        onClick={toggleOpen}
        className="btn strong p-2"
      >
        {chevron}
      </Tag>
    </CardHeader>
  ) : (
    <Tag
      {...(Tag === 'button' ? { type: 'button' } : {})}
      onClick={toggleOpen}
      className={`btn strong d-flex align-items-center ${buttonClassName}`}
    >
      {chevron}
      <span>{title}</span>
    </Tag>
  );
  return (
    <Card className={className}>
      {header}
      <Collapse isOpen={open}>
        <CardBody className={bodyClassName}>
          {/* @ts-expect-error */}
          {typeof children === 'function' ? children(open) : children}
        </CardBody>
      </Collapse>
    </Card>
  );
};

CollapsibleCard.propTypes = {
  bodyClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  openByDefault: PropTypes.bool,
  onOpen: PropTypes.func,
  tag: PropTypes.oneOf(['button', 'div']),
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  titleWithButtons: PropTypes.bool,
};

export default CollapsibleCard;
