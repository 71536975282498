import type { TRestaurantPlacesAreaConfig } from 'types';

import { bonaRestaurantAreasConfig } from './area-configs/bona-restaurant-497';
import { otolokalPartnerAreasConfig } from './area-configs/otolokalpartner-124';
import { warszawaWschodniaAreasConfig } from './area-configs/warszawa-wschodnia-495';
import { wloskaSztukaAreasConfig } from './area-configs/wloska-sztuka-484';
import { _2pierAreasConfig } from './area-configs/_2pier-restauracja-141';
import { otwartaAreasConfig } from './area-configs/otwarta-468';

export const areasByRestaurant: Record<number, TRestaurantPlacesAreaConfig> = {
  124: otolokalPartnerAreasConfig,
  141: _2pierAreasConfig,
  468: otwartaAreasConfig,
  484: wloskaSztukaAreasConfig,
  495: warszawaWschodniaAreasConfig,
  497: bonaRestaurantAreasConfig,
};

export default areasByRestaurant;
