import React from 'react';
import { Truck } from 'react-feather';
import type { FormikProps } from 'formik';
import { FormGroup, Input, Label, Row } from 'reactstrap';
import i18 from 'i18next';

import CommonSection from '../common/CommonSection';
import IconWithTooltip from '../common/IconWithTooltip';
import InputWithAddon from '../common/InputWithAddon';
import DeliveryOptions from './DeliveryOptions';
import { currency } from 'globals/currency';
import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';

import OtoToggle from '../common/OtoToggle';
import type { OrdersConfigFormValues } from 'app/pages/OrderSettingsPage';

const COMPONENT_NAME = 'DeliverySettingsForm';

type DeliverySettingsFormProps = {
  loading: boolean;
  isAdmin: boolean;
  handleChange: FormikProps<OrdersConfigFormValues>['handleChange'];
  setFieldValue: FormikProps<OrdersConfigFormValues>['setFieldValue'];
  showDriveThrough: boolean;
  showRobot: boolean;
  values: OrdersConfigFormValues;
};

const DeliverySettingsForm: React.FC<DeliverySettingsFormProps> = (props) => {
  const {
    loading,
    setFieldValue,
    showDriveThrough,
    showRobot,
    isAdmin,
    handleChange,
    values,
  } = props;
  const showDeprecated = isAdmin;
  return (
    <CommonSection
      header={
        <>
          {}
          <Truck /> {i18.t('delivery-settings-form.title')}
        </>
      }
    >
      {
        <>
          <hr />
          <DeliveryOptions
            setFieldValue={setFieldValue}
            showDriveThrough={showDriveThrough}
            showRobot={isAdmin}
            values={values}
          />
          {showDeprecated && (
            <Row className="b-danger">
              <div className="col-12 text-danger">Deprecated - nie używać</div>
              <FormGroup className="col-12 col-md-6 d-flex align-items-center">
                <OtoToggle
                  className="mr-2"
                  name="delivery.dynamicDeliveryPricing"
                  defaultChecked={
                    values.delivery.dynamicDeliveryPricing || false
                  }
                  value="yes"
                  onChange={(e) => {
                    setFieldValue(
                      'delivery.dynamicDeliveryPricing',
                      e.target.checked
                    );
                  }}
                />
                <Label className="mb-0">
                  {i18.t('delivery-settings-form.dynamic-price')}
                </Label>
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label for="price">
                  {i18.t('delivery-settings-form.delivery-price')}
                </Label>
                <InputWithAddon
                  addonText={currency}
                  type="number"
                  name="delivery.price"
                  id="price"
                  placeholder={i18.t('delivery-settings-form.eg-5')}
                  onChange={handleChange}
                  value={values.delivery.price || ''}
                  step="0.1"
                />
              </FormGroup>

              {values.delivery.dynamicDeliveryPricing && (
                <FormGroup className="col-12 col-md-6">
                  <Label for="baseDeliveryKm">
                    <span className="mr-2">
                      {i18.t('delivery-settings-form.basic-distance')}
                    </span>
                    <IconWithTooltip
                      id="baseDeliveryKm"
                      text={i18.t(
                        'delivery-settings-form.basic-distance-tooltip'
                      )}
                    />
                  </Label>
                  <InputWithAddon
                    addonText="km"
                    type="number"
                    step="0.1"
                    name="delivery.baseDeliveryKm"
                    id="baseDeliveryKm"
                    placeholder={i18.t('delivery-settings-form.eg-4')}
                    onChange={handleChange}
                    value={values.delivery.baseDeliveryKm || ''}
                  />
                </FormGroup>
              )}
              <FormGroup className="col-12 col-md-6">
                <Label for="maxKmDistance">
                  <span className="mr-2">
                    {i18.t('delivery-settings-form.max-distance')}
                  </span>
                  <IconWithTooltip
                    id="maxKmDistance"
                    text={i18.t('delivery-settings-form.max-distance-tooltip')}
                  />
                </Label>
                <InputWithAddon
                  addonText="km"
                  type="number"
                  name="delivery.maxKmDistance"
                  id="maxKmDistance"
                  placeholder={i18.t('delivery-settings-form.eg-8')}
                  step="0.1"
                  onChange={handleChange}
                  value={values.delivery.maxKmDistance || ''}
                />
              </FormGroup>
              {values.delivery.dynamicDeliveryPricing && (
                <FormGroup className="col-12 col-md-6">
                  <Label for="extraKmCost">
                    {i18.t('delivery-settings-form.additional-km-price')}
                  </Label>
                  <InputWithAddon
                    addonText={currency}
                    type="number"
                    step="0.1"
                    name="delivery.extraKmCost"
                    id="extraKmCost"
                    placeholder={i18.t('delivery-settings-form.eg-15')}
                    onChange={handleChange}
                    value={values.delivery.extraKmCost || ''}
                  />
                </FormGroup>
              )}
              <FormGroup className="col-12 col-md-6">
                <Label for="freeDeliveryFrom">
                  <span className="mr-2">
                    {i18.t('delivery-settings-form.free-delivery')}
                  </span>
                  <IconWithTooltip
                    id="free-delivery-from"
                    text={i18.t('delivery-settings-form.free-delivery-tooltip')}
                  />
                </Label>
                <InputWithAddon
                  addonText={currency}
                  type="number"
                  name="delivery.freeDeliveryFrom"
                  id="freeDeliveryFrom"
                  placeholder={i18.t('delivery-settings-form.eg-100')}
                  onChange={handleChange}
                  value={values.delivery.freeDeliveryFrom || ''}
                />
              </FormGroup>
            </Row>
          )}
          <hr />
          {isAdmin && (
            <FormGroup>
              <Label>
                {i18.t('delivery-settings-form.delivery-cost-rules')}{' '}
                <IconWithTooltip
                  id="dynamicDeliveryText"
                  text={i18.t(
                    'delivery-settings-form.delivery-cost-rules-desc'
                  )}
                />
              </Label>
              <Input
                name="dynamicDeliveryText"
                type="textarea"
                disabled={!isAdmin}
                value={values.dynamicDeliveryText || ''}
                onChange={handleChange}
              />
            </FormGroup>
          )}
          <SubmitButtonWithLoader className="mt-3" loading={loading} />
        </>
      }
    </CommonSection>
  );
};

DeliverySettingsForm.displayName = COMPONENT_NAME;

export default DeliverySettingsForm;
