import {
  OtoButtons,
  ReactTableCellProps,
  SimpleTableWithSorting,
  generateColumn,
  getRTOriginal,
} from 'app/components/common';
import React, { useMemo } from 'react';
import { TDeliveryPricing } from 'types';

export type DeliveriesPricingsListProps = {
  deliveriesPricings: TDeliveryPricing[];
  isLoading: (deliveryPricing: TDeliveryPricing) => boolean;
  onRemove?: (deliveryPricing: TDeliveryPricing) => void;
};

const DeliveriesPricingsList: React.FC<DeliveriesPricingsListProps> = ({
  deliveriesPricings,
  isLoading,
  onRemove,
}) => {
  const columns = useMemo(() => {
    return [
      generateColumn('distance_from', {
        customHeader: 'Odległość od (metry)',
      }),
      generateColumn('distance_to', {
        customHeader: 'Odległość od (metry)',
      }),
      generateColumn('price', {
        customHeader: 'Cena (netto dla restauracji)',
      }),
      ...(onRemove
        ? [
            generateColumn('id', {
              customHeader: 'Akcje',
              customCellFormatter: (
                props: ReactTableCellProps<TDeliveryPricing>
              ) => {
                const deliveryPricing = getRTOriginal<TDeliveryPricing>(props);
                return (
                  <OtoButtons.DeleteButton
                    loading={isLoading(deliveryPricing)}
                    onClick={() => onRemove(deliveryPricing)}
                  />
                );
              },
            }),
          ]
        : []),
    ];
  }, [isLoading, onRemove]);
  return <SimpleTableWithSorting columns={columns} data={deliveriesPricings} />;
};

export default DeliveriesPricingsList;
