import React from 'react';

import { fixDecimal } from 'utils/general';
import { currency } from 'globals/currency';
import type { IOrder } from 'types';

type OrderProductsProps = {
  order: IOrder;
};

export const OrderProducts: React.FC<OrderProductsProps> = ({ order }) => {
  return (
    <>
      {order.products.map((product, index) => {
        const { selectedAdditionalFields = [] } = product;
        return (
          <React.Fragment key={`${product.name}${index}`}>
            <div className="align-vertical flex-wrap mb-2">
              <div className="mr-2">
                {product.amount} x {product.name}
                {product.option ? ` (${product.option})` : ''}:{' '}
                {fixDecimal(product.amount * product.price)} {currency}
              </div>
              <small className="nowrap text-nowrap">
                ({product.category || ''})
              </small>
            </div>
            {selectedAdditionalFields.length > 0 && (
              <div className="mb-2">
                {selectedAdditionalFields.map((text) => (
                  <div key={text} className="text-muted">
                    {text}
                  </div>
                ))}
              </div>
            )}
            {product.notes && (
              <div className="text-danger mb-3">{product.notes}</div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

OrderProducts.displayName = 'OrderProducts';

export default OrderProducts;
