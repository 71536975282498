import PropTypes from 'prop-types';

const reservationConfigShape = PropTypes.shape({
  assignee_list: PropTypes.arrayOf(PropTypes.string.isRequired),
  allow_reservations_right_before_opening: PropTypes.bool,
  ask_about_non_vaccinated_guest_amount: PropTypes.bool,
  auto_confirm_before: PropTypes.number.isRequired,

  auto_reservation_max_guests: PropTypes.number,
  auto_reservation_max_guests_label: PropTypes.string,

  close_time: PropTypes.string, // format eg "24:00:00"
  open_time: PropTypes.string,

  flexible_tables: PropTypes.bool.isRequired,

  has_area_preference_possibility: PropTypes.bool,
  has_assignee: PropTypes.bool,

  reservation_duration: PropTypes.number.isRequired,
  reservation_duration_options: PropTypes.arrayOf(PropTypes.number.isRequired),
  reservation_duration_options_for_guests: PropTypes.arrayOf(
    PropTypes.number.isRequired
  ),

  mute_notification_sound: PropTypes.bool,
  reservations_warning: PropTypes.string,
  reservation_in_hours_before: PropTypes.number,

  sms_notifications_for_new_or_declined_bookings: PropTypes.bool,
  sms_notifications_phone_numbers: PropTypes.string,

  // TODO deprecated - to be removed
  available_seats: PropTypes.number,
  available_tables: PropTypes.number,
});

export default reservationConfigShape;
