import React from 'react';
import { addMinutes } from 'date-fns';

import { displayTime } from 'utils/date-time';
import Order from 'utils/orders';
import { IOrderFeedback } from 'types';

interface IProps {
  feedback: IOrderFeedback;
}

const DeclaredOrderDeliveryTime: React.FC<IProps> = (props) => {
  const { order } = props.feedback;
  if (!order) {
    return null;
  }
  return (
    <>
      {order.confirmed_at && Order.getPrepareTime(order)
        ? displayTime(
            addMinutes(
              new Date(order.confirmed_at),
              Order.getPrepareTime(order) as number
            )
          )
        : null}
    </>
  );
};

export default DeclaredOrderDeliveryTime;
