import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from 'reactstrap';

import ModalContext from './ModalContext';
import { ModalTypes } from 'config';
import PromoCodeEditModal from 'app/components/promocodes/PromoCodeEditModal';
import SendSmsModal from 'app/components/marketing/SendSmsModal';
import AcceptDeliveryModal from 'app/components/deliveries/AcceptDeliveryModal';
import OtoSelectOption from 'app/components/common/OtoSelectOption';
import AddCompanyContactModal from 'app/components/companies/AddCompanyContactModal';
import DisableOrdersModal from 'app/components/orders/DisableOrdersModal';

const OTHER = 'SOME_KEY_HARD_TO-TYPE';

const DEFAULT_ModalTypes = [
  ModalTypes.CONFIRM,
  ModalTypes.INPUT,
  ModalTypes.SELECT,
];

const CustomModalTypesByKey = {
  [ModalTypes.ACCEPT_DELIVERY]: AcceptDeliveryModal,
  [ModalTypes.ADD_COMPANY_CONTACT]: AddCompanyContactModal,
  [ModalTypes.SEND_SMS_MODAL]: SendSmsModal,
  [ModalTypes.PROMO_CODE_MODAL]: PromoCodeEditModal,
  [ModalTypes.DISABLE_ORDERS]: DisableOrdersModal,
};

type TPageModalState = {
  value: string;
  customValue: string;
};

class PageModal extends React.Component<{}, TPageModalState> {
  state = {
    value: '',
    customValue: '',
  };

  initialValueWasSet = false;

  static propTypes = {
    modal: PropTypes.exact({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      confirm: PropTypes.func.isRequired,
      confirmColor: PropTypes.string,
      confirmText: PropTypes.string,
      cancel: PropTypes.func.isRequired,
      cancelText: PropTypes.string,
      options: PropTypes.array,
      other: PropTypes.bool,
      otherPlaceholder: PropTypes.string,
      modalSpecificProps: PropTypes.object,
    }),
  };

  inputRef: React.Ref<HTMLInputElement | HTMLTextAreaElement> | undefined;

  setInputRefAndFocus = (node) => {
    this.inputRef = node;
    setTimeout(() => {
      node && node.focus && node.focus();
    }, 0);
  };

  render() {
    return (
      <ModalContext.Consumer>
        {(context) =>
          context.modal && (
            <Modal
              className="page-level-modal"
              isOpen={context.modal !== null}
              toggle={context.closeModal}
            >
              {this.renderContent(context)}
            </Modal>
          )
        }
      </ModalContext.Consumer>
    );
  }

  renderContent({ closeModal, modal }) {
    const {
      title,
      text,
      type,
      confirm,
      confirmColor = 'danger',
      confirmText = 'Tak',
      cancel,
      cancelText = 'Zamknij',
      options = [],
      other,
      otherPlaceholder,
    } = modal;
    if (!DEFAULT_ModalTypes.includes(type)) {
      return this.renderCustomModalContent({ closeModal, modal });
    }
    const { customValue, value } = this.state;
    if (!this.initialValueWasSet && !!modal.initialValue && value === '') {
      // dirty hook to allow passing a default value to input modal. refactor later.
      this.setState({
        value: modal.initialValue,
      });
      this.initialValueWasSet = true;
    }

    const onConfirm = () => {
      if (type === ModalTypes.INPUT || type === ModalTypes.SELECT) {
        confirm(value, customValue);
      } else {
        confirm();
      }
      this.setState({ value: '', customValue: '' });
      this.initialValueWasSet = false; // reset, so it's possible to pass initial value again
      closeModal();
    };

    const onCancel = () => {
      cancel && cancel();
      this.setState({ value: '', customValue: '' });
      this.initialValueWasSet = false; // reset, so it's possible to pass initial value again
      closeModal();
    };

    const hasEmptyValue = type !== ModalTypes.CONFIRM && !value;
    const hasEmptyCustomValueWhenSelected = value === OTHER && !customValue;
    const disabled = hasEmptyValue || hasEmptyCustomValueWhenSelected;
    return (
      <>
        <ModalHeader toggle={closeModal}>{title}</ModalHeader>
        <ModalBody>
          {text && <p>{text}</p>}
          {type === ModalTypes.INPUT && (
            <Input
              value={value || ''}
              innerRef={this.setInputRefAndFocus}
              onChange={(e) => this.setState({ value: e.target.value })}
            />
          )}
          {type === ModalTypes.SELECT && (
            <Input
              type="select"
              value={value || ''}
              onChange={(e) => this.setState({ value: e.target.value })}
            >
              <option key="empty-option" value=""></option>
              {options.map(this.renderOption)}
              {other ? (
                <option key="other" value={OTHER}>
                  {other}
                </option>
              ) : null}
            </Input>
          )}
          {value === OTHER ? (
            <Input
              type="text"
              className="mt-2"
              placeholder={otherPlaceholder}
              value={customValue || ''}
              onChange={(e) => this.setState({ customValue: e.target.value })}
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color={confirmColor} disabled={disabled} onClick={onConfirm}>
            {confirmText}
          </Button>{' '}
          <Button color="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        </ModalFooter>
      </>
    );
  }

  renderCustomModalContent({ closeModal, modal }) {
    const { type, confirm } = modal;
    const CustomModalType = CustomModalTypesByKey[type];
    if (!CustomModalType) {
      return null;
    }
    return (
      <CustomModalType
        {...(modal.modalSpecificProps || {})}
        onSave={(message) => confirm(message, closeModal)}
        closeModal={closeModal}
      />
    );
  }

  renderOption = (opt) => {
    return (
      <OtoSelectOption
        key={typeof opt !== 'object' ? opt : opt.value}
        opt={opt}
      />
    );
  };
}

export default PageModal;
