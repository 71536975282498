import React, { useEffect } from 'react';

export let wasAnyClickMade = false;

const addSelfDestructingEventListener = (element: HTMLElement, eventType, callback: Function) => {
  let handler = () => {
    callback();
    element.removeEventListener(eventType, handler);
  };
  element.addEventListener(eventType, handler);
};

const CaptureClickToPlaySound: React.FC<{}> = () => {
  useEffect(() => {
    addSelfDestructingEventListener(document.body, 'click', () => {
      wasAnyClickMade = true;
    });
  }, []);
  return null;
};

export default CaptureClickToPlaySound;
