import React, { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import { FormGroup, Label, Input } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import memoizeOne from 'memoize-one';

import OtoSpinner from '../common/OtoSpinner';
import type {
  ITable,
  NormalizedRestaurant,
  TTableSuggestion,
  TTableWithPeopleAmount,
} from 'types';
import { addPeopleAmountToTableTitle } from 'utils/reservation';
import TablesCapacity from './TablesCapacity';
import IconWithTooltip from '../common/IconWithTooltip';
import type { TablesAvailability } from 'store/reservation-calendar';

interface AddReservationTablesSelectProps {
  allTables: ITable[];
  classNameTablesField?: string;
  classNameTablesAvailabilityField?: string;
  disabled?: boolean;
  loadTableSuggestions: (
    params: {
      duration: number;
      guests_number: number;
      visitDate: string;
      visitTime: string;
    },
    availability: TablesAvailability
  ) => Promise<TTableSuggestion[]>;
  restaurant: NormalizedRestaurant;
  onTablesSelected: (tables: TTableWithPeopleAmount[]) => void;
  onSuggestionsLoaded?: (suggestions: TTableSuggestion[]) => void;
  selectedTables: TTableWithPeopleAmount[];
  suggestedTables: TTableWithPeopleAmount[];
  tableSuggestionsLoading: boolean;
  values: {
    duration: number;
    is_entire_place: boolean;
    visitDate: string;
    visitTime: string;
    guests_number: number;
  };
}

const getTablesWithPeopleAmount = memoizeOne(
  (tables: ITable[]): TTableWithPeopleAmount[] => {
    return tables.map((table) =>
      addPeopleAmountToTableTitle(table, {
        addPlace: true,
        allTables: tables,
      })
    );
  }
);

export const AddReservationTablesSelect: React.FC<
  AddReservationTablesSelectProps
> = (props) => {
  const {
    allTables,
    classNameTablesField = 'col-12 col-md-7',
    classNameTablesAvailabilityField = 'col-12 col-md-5',
    disabled,
    loadTableSuggestions,
    onTablesSelected,
    onSuggestionsLoaded,
    selectedTables,
    suggestedTables,
    tableSuggestionsLoading,
    values,
  } = props;

  const [tablesAvailability, setTablesAvailability] =
    useState<TablesAvailability>('suitable');

  const updateTables = (selectedList: TTableWithPeopleAmount[]) =>
    onTablesSelected(selectedList);

  const loadSuggestions = useCallback(async () => {
    const suggestions = await loadTableSuggestions(
      {
        duration: values.duration,
        guests_number: values.guests_number,
        visitDate: values.visitDate,
        visitTime: values.visitTime,
      },
      tablesAvailability
    );
    onSuggestionsLoaded?.(suggestions);
  }, [
    loadTableSuggestions,
    onSuggestionsLoaded,
    values.guests_number,
    values.visitDate,
    values.visitTime,
    values.duration,
    tablesAvailability,
  ]);

  useEffect(() => {
    loadSuggestions();
  }, [loadSuggestions]);

  const tablesWithPeopleAmount = getTablesWithPeopleAmount(allTables);

  if (tablesAvailability !== 'all' && tableSuggestionsLoading) {
    return <OtoSpinner className="m-1 mb-2" />;
  }

  const tableOptions =
    tablesAvailability === 'all' || values.is_entire_place
      ? tablesWithPeopleAmount
      : suggestedTables;

  const selectedIds = selectedTables.map((table) => table.id);

  if (!tableOptions) {
    return <OtoSpinner className="m-1 mb-2" />;
  }

  const safeSelectedTables = tablesWithPeopleAmount.filter((table) =>
    selectedIds.includes(table.id)
  );

  return (
    <>
      <FormGroup className={classNameTablesField}>
        <Label for="tables">{i18next.t('reservation.tables')}</Label>
        <Multiselect
          disable={disabled}
          options={tableOptions}
          selectedValues={safeSelectedTables}
          onSelect={updateTables}
          onRemove={updateTables}
          displayValue="nameWithPeopleAmount"
          placeholder={i18next.t('reservation.choose_table')}
          style={{
            searchBox: {
              background: 'white',
            },
          }}
          emptyRecordMsg={
            safeSelectedTables.length > 0
              ? 'Nie ma więcej stolików dla wybranych parametrów'
              : 'Brak stolików dla wybranych parametrów'
          }
        />
        <TablesCapacity tables={selectedTables} />
      </FormGroup>
      <FormGroup className={classNameTablesAvailabilityField}>
        <div className="mw-175">
          <Label className="align-vertical">
            Rodzaj stolików{' '}
            <IconWithTooltip
              style={{ marginLeft: 5 }}
              id="table-availability"
              text={
                <>
                  <div className="mb-2">
                    Pasujące stoliki - stoliki o odpowiednim rozmiarze, lub o
                    możliwości połączeń dla wybranej liczby osób.
                  </div>
                  <div>
                    Wolne stoliki - stoliki bez rezerwacji w wybranym terminie,
                    bez względu na ich wielkość
                  </div>
                </>
              }
            />
          </Label>
          <Input
            id="tables-availability"
            type="select"
            disabled={disabled}
            value={tablesAvailability}
            style={{ minHeight: 43 }}
            onChange={(e) =>
              setTablesAvailability(e.target.value as TablesAvailability)
            }
          >
            <option value="suitable">pasujące</option>
            <option value="free">wolne</option>
            <option value="all">wszystkie</option>
          </Input>
        </div>
      </FormGroup>
    </>
  );
};

AddReservationTablesSelect.displayName = 'AddReservationTablesSelect';

export default AddReservationTablesSelect;
