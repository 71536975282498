import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { addDays, startOfDay } from 'date-fns';

import OtoChip from '../common/OtoChip';
import { newSafeDate } from 'utils/date-time';

const _14daysAgo = addDays(startOfDay(new Date()), -14);
const _30daysAgo = addDays(startOfDay(new Date()), -30);

type TCustomerFilter = {
  name: string;
  nameProps?: Record<string, unknown>;
  handler: (customer) => boolean;
};

const availableFilters: TCustomerFilter[] = [
  {
    name: 'last-order-month-ago',
    handler: (customer) => {
      return (
        !!customer.orders_max_created_at &&
        newSafeDate(customer.orders_max_created_at) < _30daysAgo
      );
    },
  },
  {
    name: 'last-order-x-days-ago',
    nameProps: {
      days: 14,
    },
    handler: (customer) => {
      return (
        !!customer.orders_max_created_at &&
        newSafeDate(customer.orders_max_created_at) < _14daysAgo
      );
    },
  },
  {
    name: 'single-order',
    handler: (customer) => customer.orders_count === 1,
  },
  {
    name: 'avg-order-below',
    nameProps: {
      value: 50,
    },
    handler: (customer) => {
      return customer.orders_count > 0 && customer.orders_avg_price < 50;
    },
  },
];

const CustomersFilter = (props) => {
  const { activeFilters, setActiveFilters } = props;
  const toggleFilter = (filter) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters([...activeFilters.filter((item) => item !== filter)]);
    } else {
      setActiveFilters([...activeFilters, filter]);
    }
  };
  return (
    <div className="mb-3 bb-1">
      {availableFilters.map((filter) => (
        <OtoChip
          key={filter.name}
          label={i18next.t(`customers-filter.${filter.name}`, filter.nameProps)}
          isSelected={activeFilters.includes(filter)}
          onClick={() => toggleFilter(filter)}
        />
      ))}
    </div>
  );
};

CustomersFilter.propTypes = {
  activeFilters: PropTypes.array.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
};

export default CustomersFilter;
