import React, { useMemo } from 'react';
import { Button } from 'reactstrap';
import {
  ICompanyContact,
  IRestaurantCompanyWithRestaurantsAndContacts,
  OriginalRestaurant
} from 'types';
import { CenterCardSpinner, SimpleTableWithSorting, generateColumn, getRTOriginal, ReactTableCellProps } from 'app/components/common';
import { ModalTypes } from 'config';
import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import useData from 'hooks/useData';

const ShowAddCompanyContactModalBtn = withSetModal(AddCompanyContactModalBtn);

const columns = [
  generateColumn('id'),
  generateColumn('name', { customHeader: 'Nazwa firmy' }),
  generateColumn('nip'),
  generateColumn('address', { customHeader: 'Adres siedziby' }),
  generateColumn('ip', {
    customHeader: 'Przypisane restauracje',
    customCellFormatter: (props: ReactTableCellProps<IRestaurantCompanyWithRestaurantsAndContacts>) => {
      const restaurantCompany = getRTOriginal<IRestaurantCompanyWithRestaurantsAndContacts>(props);
      return (
        <div>
          {restaurantCompany.restaurants.length > 0 ? (
            restaurantCompany.restaurants
              .map((r) => `${r.name} (${r.id})`)
              .join(', ')
          ) : (
            <span className="text-danger">
              Firma nie ma przypisanych restauracji
            </span>
          )}
        </div>
      );
    },
  }),
  generateColumn('fake-property-2', {
    customHeader: 'Osoby kontaktowe',
    customCellFormatter: (props: ReactTableCellProps<IRestaurantCompanyWithRestaurantsAndContacts>) => {
      const restaurantCompany = getRTOriginal<IRestaurantCompanyWithRestaurantsAndContacts>(props);
      return (
        <div>
          {restaurantCompany.contacts.length > 0 ? (
            restaurantCompany.contacts.map((c) => (
              <div className="mb-2 bb-1" key={c.phone}>
                {c.first_name} {c.last_name} ({c.position}), {c.phone},{' '}
                {c.email}
              </div>
            ))
          ) : (
            <span className="text-danger">Firma nie ma kontaktów</span>
          )}
          <div>
            {/* @ts-ignore */}
            <ShowAddCompanyContactModalBtn company={restaurantCompany} />
          </div>
        </div>
      );
    },
  }),
];

const RestaurantCompaniesPage = () => {
  const { data: restaurantCompanies, isLoading } = useData<
    IRestaurantCompanyWithRestaurantsAndContacts[]
  >({ url: '/restaurant-companies' });

  const normalizedRestaurantCompanies = useMemo(() => {
    return (restaurantCompanies || []).map((item) => ({
      ...item,
      nip: item.nip.trim(),
    }));
  }, [restaurantCompanies]);

  return (
    <>
      {isLoading && <CenterCardSpinner text="Pobieram dane o firmach" />}
      {restaurantCompanies && (
        <SimpleTableWithSorting
          columns={columns}
          data={
            normalizedRestaurantCompanies as unknown as OriginalRestaurant[]
          }
          hasFilters
          pageSize={100}
        />
      )}
    </>
  );
};

interface IAddCompanyContactModalBtnProps {
  company: IRestaurantCompanyWithRestaurantsAndContacts;
  setModal: TSetModalFunction;
}

function AddCompanyContactModalBtn(props: IAddCompanyContactModalBtnProps) {
  const { company, setModal } = props;
  return (
    <Button
      type="button"
      onClick={() =>
        setModal(
          {
            title: `Dodawanie kontatu do firmy ${company.name}`,
            confirm: (newContact: ICompanyContact) => {
              console.debug('created contact', newContact);
            },
            modalSpecificProps: {
              companyId: company.id,
            },
            confirmColor: 'success',
          },
          ModalTypes.ADD_COMPANY_CONTACT
        )
      }
    >
      Dodaj kontakt
    </Button>
  );
}

RestaurantCompaniesPage.url = '/admin/restaurant-companies';
RestaurantCompaniesPage.navName = 'admin.companies';
RestaurantCompaniesPage.icon = 'Home';

export default RestaurantCompaniesPage;
