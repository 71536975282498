import React from 'react';
import { format } from 'date-fns';

import type { IDelivery, IOrder } from 'types';
import Delivery from 'utils/deliveries';

interface OrderCourrierStatusProps {
  deliveries: IDelivery[];
  order: IOrder;
}

const OrderCourrierStatus: React.FC<OrderCourrierStatusProps> = ({ deliveries, order }) => {
  const orderDelivery = deliveries.find(
    (delivery) => delivery.order_id === order.id
  );

  if (orderDelivery) {
    const assignedTime = Delivery.getConfirmedByDriverTime(orderDelivery);
    if (assignedTime) {
      return (
        <div className="my-2">
          Kurier przyjął dostawę o {format(assignedTime, 'hh:mm')}
        </div>
      );
    }
  }

  return <div className="my-2">Kurier wezwany</div>;
};

OrderCourrierStatus.displayName = 'OrderCourrierStatus';

export default OrderCourrierStatus;
