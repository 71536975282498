import React from 'react';
import { Smile } from 'react-feather';

import CollapsibleCard from 'app/components/common/CollapsibleCard';
import GetDeviceReady from 'app/components/welcome/GetDeviceReady';
import HowToGetMoreOrders from 'app/components/welcome/HowToGetMoreOrders';
import ReservationLinks from 'app/components/welcome/ReservationLinks';
import EnhancedOrdersList from './OrdersListPage';
import type { PageComponent } from 'app/types-global';
import { useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';

const WelcomePage: PageComponent<{}> = () => {
  const restaurant = useAppSelector(selectRestaurant)
  if (!restaurant) {
    return null;
  }

  return (
    <>
      <h1 className="h3">Cześć Restauratorze, witamy na pokładzie!</h1>
      <div className="h5 w-100 text-left" style={{ maxWidth: 550 }}>
        <p>
          Jeżeli trafiłeś tutaj, najprawdopodobniej zaczynasz wspaniałą
          przygodę, jaką się okaże współpraca z OtoStolik (mamy nadzieję{' '}
          <Smile />)
        </p>
      </div>
      <h4>Najważniejsze dla Ciebie zakładki systemu, to:</h4>
      <ul>
        <li>
          <a href={EnhancedOrdersList.url}>{EnhancedOrdersList.navName}</a>
        </li>
        <li>
          <a href="/settings/orders">
            Ustawienia zamówień - cena dostawy, minimum logistyczne, godziny
          </a>
        </li>
        <li>
          <a href="/menu">Edycja menu</a>
        </li>
      </ul>
      <CollapsibleCard
        title={
          <>
            <Smile /> Krok 1 - co zrobić przed pierwszym zamówieniem?
          </>
        }
      >
        <ul>
          <li>Sprawdź, czy menu jest uzupełnione poprawnie</li>
          <li>Sprawdź ustawienia zamówień - koszt dostawy, godziny</li>
        </ul>
      </CollapsibleCard>
      <GetDeviceReady />
      <HowToGetMoreOrders restaurant={restaurant} />
      <ReservationLinks restaurant={restaurant} />
    </>
  );
};

WelcomePage.navName = 'Pierwsze kroki';
WelcomePage.url = '/how-to-setup';

export default WelcomePage;
