import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, isSupported } from 'firebase/messaging';
import type { Messaging } from 'firebase/messaging';

import { isTestEnv } from 'config';
import { logError } from './log';

const firebaseInstance: {
  messaging: Messaging | null;
  messagingError: Error | null;
  messagingSetup: boolean;
  messagingListeners: ((payload: any) => void)[];
  addOnMessageListener?: (listener: (payload: any) => void) => void;
} = {
  messaging: null,
  messagingError: null,
  messagingSetup: false,
  messagingListeners: [],
};

initializeFirebaseAppAndMessaging();

async function initializeFirebaseAppAndMessaging() {
  try {
    const initializedFirebaseApp = initializeApp({
      apiKey: 'AIzaSyDnFEwW82Qrxjy5ZNkQhG582ZU9UG1E-MQ',
      authDomain: 'otostolik-restaurant-native.firebaseapp.com',
      databaseURL: 'https://otostolik-restaurant-native.firebaseio.com',
      projectId: 'otostolik-restaurant-native',
      storageBucket: 'otostolik-restaurant-native.appspot.com',
      messagingSenderId: '668862045659',
      appId: '1:668862045659:web:d242c2a3153ddf0cc09bfe',
      measurementId: 'G-MQG9EBQKY2',
    });
    firebaseInstance.messaging = null;
    if (!isTestEnv) {
      if (await isSupported()) {
        firebaseInstance.messaging = getMessaging(initializedFirebaseApp);
      }
    }

    firebaseInstance.addOnMessageListener = handleMessageListenerAdd;
  } catch (e) {
    firebaseInstance.messagingError = e as Error;
  }
}

function handleMessageListenerAdd(listener) {
  if (!firebaseInstance.messaging) {
    logError('Cannot setup onMessage - firebase messaging is not ready');
  }
  if (!firebaseInstance.messagingListeners.includes(listener)) {
    firebaseInstance.messagingListeners = [
      ...firebaseInstance.messagingListeners,
      listener,
    ];
  }
  if (firebaseInstance.messagingSetup) {
    return;
  }

  if (firebaseInstance.messaging) {
    onMessage(firebaseInstance.messaging, (payload) => {
      console.warn('firebase received message in foreground:', payload);
      firebaseInstance.messagingListeners.forEach((l) => l(payload));
    });
    console.log('firebase messaging setup onMessage() in foreground done');
  } else {
    console.error(
      'cannot setup onMessage() in foreground - firebase messaging is not ready'
    );
  }

  firebaseInstance.messagingSetup = true;
}

const entities = {
  restaurantConfig: 'restaurant_config',
  restaurantDelivery: 'restaurant_delivery',
  restaurantOrder: 'restaurant_order',
  restaurantMenu: 'restaurant_menu',
};

const actions = {
  driverArrived: 'arrived',
  created: 'created',
  updated: 'updated',
  driverAssigned: 'driver_assigned',
  driverUnassigned: 'driver_unassigned',
};

const isMenuUpdateMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantMenu &&
  message.data.event_type === actions.updated;

const isConfigUpdateMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantConfig &&
  message.data.event_type === actions.updated;

const isDriverArrivedMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantDelivery &&
  message.data.event_type === actions.driverArrived;

const isNewDeliveryMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantDelivery &&
  message.data.event_type === actions.created;

const isNewOrderMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantOrder &&
  message.data.event_type === actions.created;

const isDriverAssignedMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantDelivery &&
  message.data.event_type === actions.driverAssigned;

const isDriverUnassignedMessage = (message): boolean =>
  !!message.data &&
  message.data.entity === entities.restaurantDelivery &&
  message.data.event_type === actions.driverUnassigned;

export {
  firebaseInstance,
  isConfigUpdateMessage,
  isDriverArrivedMessage,
  isMenuUpdateMessage,
  isNewDeliveryMessage,
  isNewOrderMessage,
  isDriverAssignedMessage,
  isDriverUnassignedMessage,
};
