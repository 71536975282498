import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { selectActiveTenant, Tenants } from 'store/tenants';
import { useAppSelector } from 'hooks/useRedux';

const HelmetMetaProvider: React.FC<{ pageTitle: string }> = ({ pageTitle }) => {
  const tenant = useAppSelector(selectActiveTenant);
  const { iconPath, title } = getSEO(pageTitle, tenant);

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={iconPath} />
    </Helmet>
  );
};

interface ISEO {
  title: string;
  iconPath: string;
}

function getSEO(pageTitle: string, tenant: Tenants): ISEO {
  if (tenant === Tenants.OtoStolik) {
    return {
      title: `OtoStolik | ${pageTitle}`,
      iconPath: '/transparent.png',
    };
  }
  if (tenant === Tenants.LUBJE) {
    return {
      title: `LUB*JE | ${pageTitle}`,
      iconPath: '/lubje-192.png',
    };
  }
  return {
    title: pageTitle,
    iconPath: '/transparent.png',
  };
}

HelmetMetaProvider.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default HelmetMetaProvider;
