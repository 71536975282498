import React from 'react';
import { Card, Container, CardTitle, CardText } from 'reactstrap';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import ViewLanguagePicker from '../../views/ViewLanguagePicker';
import SmartLogo from '../SmartLogo';

const SuccessForm: React.FC<{
  onBoardData: any;
}> = (props) => {
  const { onBoardData } = props;
  return (
    <Container className="mt-4 mx-auto px-2">
      <div className="mx-auto col-xl-9">
        <div className="d-flex mb-3 justify-content-between mx-auto">
          <SmartLogo />
          <ViewLanguagePicker />
        </div>
        <Card body inverse color="success">
          <CardTitle tag="h2">{i18next.t('sign-up.success.title')}</CardTitle>
          <CardText>{i18next.t('sign-up.success.text')}</CardText>
        </Card>
        {onBoardData?.userPassword && (
          <Card body className="mt-2">
            <CardTitle tag="h2">
              {i18next.t('sign-up.success.your-login-data')}
            </CardTitle>
            <CardText>
              {i18next.t('Login')}: {onBoardData.user.email}
            </CardText>
            <CardText>
              {i18next.t('Password')}: {onBoardData.userPassword}
            </CardText>
          </Card>
        )}
        <div className="mt-2 mb-4 d-flex justify-content-center">
          <span className="mr-2">{i18next.t('sign-up.has-account-text')}</span>
          <a href="/login">{i18next.t('sign-up.login-btn')}</a>
        </div>
      </div>
    </Container>
  );
};

SuccessForm.propTypes = {
  onBoardData: PropTypes.shape({
    user: PropTypes.object,
    userPassword: PropTypes.string,
  }).isRequired,
};

export default SuccessForm;
