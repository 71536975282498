import React from 'react';
import PropTypes from 'prop-types';
import filterXss from 'xss';

import { MenuItem, MenuItemPriceOption } from 'types';
import i18next from 'i18next';
import { currency } from 'globals/currency';
import Shapes from 'shapes/main';

interface IProps {
  onImageError: (product: MenuItem, e: any) => void;
  product: MenuItem;
  showDetails: boolean;
}

const MenuProductCardDetails: React.FC<IProps> = ({
  onImageError,
  product,
  showDetails,
}) => {
  return (
    <>
      {showDetails && <p className="mb-1">{product.desc}</p>}
      {renderPrice(product)}
      {product.image && (
        <img
          src={filterXss(product.image)}
          alt={`Zdjęcie produktu ${product.name}`}
          onError={(e) => onImageError(product, e)}
        />
      )}
      {showDetails &&
        product.additionalFields &&
        product.additionalFields.length > 0 && (
          <div className="mt-1">
            <strong>
              {i18next.t('product-edit-modal.additional-fields')}{' '}
            </strong>
            <span>
              {product.additionalFields
                .map((fieldGroup) => fieldGroup.name)
                .join(', ')}
            </span>
          </div>
        )}
      {showDetails && product.labels && product.labels.length > 0 && (
        <div className="mt-1">
          <strong>{i18next.t('menu-edit.tags')}: </strong>
          <span>
            {product.labels
              .map((lbl) => i18next.t(`possible-labels.${lbl.name}`))
              .join(', ')}
          </span>
        </div>
      )}
    </>
  );
};

function renderPrice(product: MenuItem) {
  if (product.priceOptions) {
    return product.priceOptions.map((opt) => renderPriceOption(opt, opt.name));
  }
  return renderPriceOption(product);
}

function renderPriceOption(
  opt: MenuItemPriceOption,
  name = `${i18next.t('menu-edit.price')}`
) {
  return (
    <div key={name}>
      <strong>{name}: </strong>
      {opt.oldPrice ? (
        <span className="price price--old">{opt.oldPrice}&nbsp;</span>
      ) : null}
      <span className="price">
        {opt.price} {currency}
      </span>
    </div>
  );
}

MenuProductCardDetails.propTypes = {
  onImageError: PropTypes.func.isRequired,
  product: Shapes.menuItemShape.isRequired,
  showDetails: PropTypes.bool.isRequired,
};

export default MenuProductCardDetails;
