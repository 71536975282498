import React from 'react';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { selectResourcesPath, selectTenantConfigs, Tenants } from 'store/tenants';
import { useAppSelector } from 'hooks/useRedux';


export const OrderSource = (props) => {
  const { order } = props;
  const resourcesPathHost = useAppSelector(selectResourcesPath);
  const tenantConfigs = useAppSelector(selectTenantConfigs);

  if (!order.source_rel) {
    return null;
  }
  
  const isOtoStolikTenant = order.source_rel.tenant === Tenants.OtoStolik;

  const orderTenantConfig = tenantConfigs[order.source_rel.tenant];
  const sourceKey = isOtoStolikTenant ? order.source : orderTenantConfig.label;
  
  const absLogoPath = orderTenantConfig.logo?.includes('http')
    ? orderTenantConfig.logo
    : `${resourcesPathHost}${orderTenantConfig.logo}`;
  
  return (
    <div className="align-vertical">
      <strong className="d-block">{i18next.t('order.source')}:</strong>
      {isOtoStolikTenant && <div>{i18next.t(`order.sources.${sourceKey}`)}</div>}
      {!isOtoStolikTenant && (
        <img
          src={absLogoPath}
          alt={`Logo ${orderTenantConfig.label}`}
          style={{ height: 40, marginLeft: 8 }}
        />
      )}
    </div>
  );
};

OrderSource.propTypes = {
  order: Shapes.orderShape.isRequired,
};

export default OrderSource;
