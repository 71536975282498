import React from 'react';
import { FormGroup } from 'reactstrap';
import i18next from 'i18next';

import CollapsibleCard from '../common/CollapsibleCard';
import { ReservationConfig } from 'types/reservation-config';
import { hasFlexibleTables } from 'utils/reservation';

import part1 from '../../../assets/videos/tutorials/1-order-management-long.mp4';
import part2 from '../../../assets/videos/tutorials/2-menu-edit.mp4';
import part3 from '../../../assets/videos/tutorials/3-order-settings.mp4';
import part4 from '../../../assets/videos/tutorials/4-order-discounts.mp4';

import bookUsualPart1 from '../../../assets/videos/tutorials/reservations-usual-look/main-info.webm';
import bookUsualPart2 from '../../../assets/videos/tutorials/reservations-usual-look/automatic-reservations.webm';

import bookTableMangerPart1 from '../../../assets/videos/tutorials/reservations-manager-look/main-info.webm';
import bookTableMangerPart2 from '../../../assets/videos/tutorials/reservations-manager-look/automatic-reservations.webm';
import Shapes from 'shapes/main';

const videos = [
  {
    title: 'Część 1: Przyjmowanie zamówień (ok. 5 min)',
    driveURL:
      'https://drive.google.com/open?id=1g4qKhEiLzbL4sUqI1AP-hA2qJT-ywVkO',
    src: part1,
  },
  {
    title: 'Część 2: Edycja menu (ok. 2 min)',
    driveURL:
      'https://drive.google.com/open?id=1lheImaLMemdA2Uri_TojbmblFSURQdcU',
    src: part2,
  },
  {
    title: 'Część 3: Ustawienia zamówień (ok. 2 min)',
    driveURL:
      'https://drive.google.com/open?id=1bID3-8tOb0EZFKnpNkM85pRtHWO7zJBB',
    src: part3,
  },
  {
    title: 'Część 4: Promocje (ok. 2 min)',
    driveURL:
      'https://drive.google.com/open?id=1-3hdVT99mgGfk-A-u7KnLYQu7GzpeOYA',
    src: part4,
  },
];

const reservationUsualVideos = [
  {
    title: '1. System OtoStolik - główne informacje',
    src: bookUsualPart1,
  },
  {
    title: '2. System OtoStolik - rezerwacje automatyczne',
    src: bookUsualPart2,
  },
];

const reservationTableManegerVideos = [
  {
    title: '1. Tutorial OtoStolik - podstawowe informacje',
    src: bookTableMangerPart1,
  },
  {
    title: '2. Tutorial OtoStolik - rezerwacje automatyczne',
    src: bookTableMangerPart2,
  },
];

const TutorialVideos: React.FC<{ reservationConfig: ReservationConfig }> = (
  props
) => {
  const { reservationConfig } = props;

  const reservationCardTitle = hasFlexibleTables(reservationConfig)
    ? i18next.t('contact-page.tutorials.reservation-management-flexible')
    : i18next.t('reservation-management-simple');
  const reservationMovies = hasFlexibleTables(reservationConfig)
    ? reservationTableManegerVideos
    : reservationUsualVideos;
  return (
    <>
      <CollapsibleCard
        title={i18next.t('contact-page.tutorials.order-management')}
      >
        {videos.map((video) => (
          <FormGroup key={video.title} className="bb-1">
            <a href={video.driveURL} target="_blank" rel="noopener noreferrer">
              {video.title}
            </a>
            <video height="320" controls className="d-block my-2">
              <source src={video.src} type="video/mp4" />
            </video>
          </FormGroup>
        ))}
      </CollapsibleCard>
      <CollapsibleCard title={reservationCardTitle}>
        {reservationMovies.map((video) => (
          <FormGroup key={video.title} className="bb-1">
            <span>{video.title}</span>
            <video height="320" controls className="d-block my-2">
              <source src={video.src} type="video/mp4" />
            </video>
          </FormGroup>
        ))}
      </CollapsibleCard>
    </>
  );
};

TutorialVideos.propTypes = {
  reservationConfig: Shapes.reservationConfigShape.isRequired,
};

export default TutorialVideos;
