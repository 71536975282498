import PropTypes from 'prop-types';
import userShape from './user';

const robotShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
  status: PropTypes.oneOf([
    'building',
    'functional',
    'repair_needed',
    'no_more_used',
  ]).isRequired,
  location: PropTypes.exact({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  user: userShape,
});

export default robotShape;
