import { OTO_PRODUCTS, OTO_PRODUCTS_GROUPS } from 'enums';
import {
  AnyRestaruant,
  NormalizedRestaurant,
  PlainRestaurant,
} from 'types/restaurants';

export function hasAnyOrders(restaurant: AnyRestaruant) {
  return (
    hasAnyPlatformOrders(restaurant) ||
    hasProduct(restaurant, OTO_PRODUCTS.iframe) ||
    hasProduct(restaurant, OTO_PRODUCTS.website)
  );
}

export function hasAnyPlatformOrders(restaurant: AnyRestaruant) {
  return (
    hasProduct(restaurant, OTO_PRODUCTS.lubje_orders) ||
    hasProduct(restaurant, OTO_PRODUCTS.lesznoje_orders) ||
    hasProduct(restaurant, OTO_PRODUCTS.ostrowje_orders) 
  );
}

export function hasOwnDeliveries(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.restaurant_deliveries);
}

export function hasExchangeDeliveries(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.exchange_deliveries);
}

export function hasRobotDeliveries(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.robot_deliveries);
}

export function hasAnyDeliveries(restaurant: PlainRestaurant) {
  return OTO_PRODUCTS_GROUPS.anyDeliveriesProduct.some((product) =>
    hasProduct(restaurant, product)
  );
}

export function hasOrders(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.orders);
}

export function hasLesznojeOrdersList(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.lesznoje_orders);
}

export function hasOstrowjeOrdersList(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.ostrowje_orders);
}

export function hasLubjeOrdersList(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.lubje_orders);
}

export function hasReservations(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.reservations);
}

export function hasProduct(restaurant: AnyRestaruant, product: string) {
  return (restaurant.products || []).includes(product);
}

export function hasReservationsPrint(restaurant: PlainRestaurant) {
  return restaurant?.id === 89;
}

export function hasMarketing(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.marketing);
}

export function hasPrinter(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.printer);
}

export function canOrderDelivery(restaurant: PlainRestaurant) {
  return OTO_PRODUCTS_GROUPS.anyDeliveriesProduct.some((product) =>
    hasProduct(restaurant, product)
  );
}

export function canOrderFoodeliDelivery(restaurant: PlainRestaurant) {
  return hasProduct(restaurant, OTO_PRODUCTS.foodeli_deliveries);
}

export function isNew(restaurant: PlainRestaurant) {
  const { products = [] } = restaurant;
  return !products || !products.length || products.includes('isNew');
}

export const BOMBARDINOS = [
  {
    id: 9,
    name: 'Bombardino Centrum',
  },
  {
    id: 10,
    name: 'Bombardino Botanik',
  },
  {
    id: 11,
    name: 'Bombardino Gęsia',
  },
];

export function isBombardino(restaurant: PlainRestaurant) {
  return BOMBARDINOS.map((item) => item.id).includes(restaurant.id);
}

const wantsToCollectMarketingAgreements = (restaurant: AnyRestaruant) => {
  return [64, 141, 452, 468, 471].includes(restaurant.id);
};

const wantsToCountNonVaccinated = (
  restaurant: NormalizedRestaurant
): boolean => {
  const { config } = restaurant;
  return config.ask_about_non_vaccinated_guest_amount || false;
};

const Restaurant = {
  // other
  isBombardino,
  hasProduct,
  isNew,

  // deliveries
  canOrderDelivery,
  canOrderFoodeliDelivery,
  hasOwnDeliveries,
  hasAnyDeliveries,
  hasExchangeDeliveries,
  hasRobotDeliveries,

  // orders
  hasAnyOrders,
  hasOrders,
  hasLesznojeOrdersList,
  hasOstrowjeOrdersList,
  hasLubjeOrdersList,

  // reservations
  hasReservations,
  wantsToCollectMarketingAgreements,
  wantsToCountNonVaccinated,
};

export default Restaurant;
