import React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import i18next from 'i18next';
import { Alert, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';

import OtoSpinner from 'app/components/common/OtoSpinner';
import CustomersFilter from 'app/components/customers/CustomersFilter';
import CustomersListComponent from 'app/components/customers/CustomersList';
import { loadCustomers, selectCustomers, selectCustomersError, selectCustomersLoading } from 'store/customers';
import type { TCustomerWithStats } from 'store/customers';
import { sendSms } from 'store/sms';
import APIService from 'services/api';
import { LOG_BAG } from 'utils/log';
import { ModalTypes } from 'config';
import { hasMarketing } from 'utils/restaurant';
import { selectIsRootAdmin } from 'store/app';
import UrlFetcher from '../containers/UrlFetcher';
import MarketingCampaingList, { MarketingCampaign } from 'app/components/marketing/MarketingCampaingList';
import CreateMarketingCampaignForm from 'app/components/marketing/CreateMarketingCampaignForm';
import withSetModal, { TSetModalFunction } from '../containers/WithSetModal';
import { NormalizedRestaurant } from 'types';
import { selectRestaurant } from 'store/restaurant';

const COMPONENT_NAME = 'MarketingPage';

type MarketingPageProps = PropsFromRedux & {
  setModal: TSetModalFunction;
}

type MarketingPageState = {
  activeFilters: {
    handler: Function,
  }[],
  customerEmailsList: string,
  selectedCustomers: Set<number>,
}

class MarketingPage extends React.PureComponent<MarketingPageProps, MarketingPageState> {
  state: MarketingPageState = {
    activeFilters: [],
    customerEmailsList: '',
    selectedCustomers: new Set<number>(),
  };

  static getDerivedStateFromError =
  LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  componentDidMount() {
    if (this.canSeeCustomers()) {
      this.loadCustomers();
    }
  }

  componentDidCatch(error, info) {
    LOG_BAG.logComponentDidCatch(COMPONENT_NAME, error, info);
  }

  canSeeCustomers = () =>
    hasMarketing(this.props.restaurant) || this.props.isAdmin;

  loadCustomers = () => {
    const url = `/restaurants/${this.props.restaurant.id}/customers`;
    this.props.loadCustomers(url);
  };

  getCustomers = (): TCustomerWithStats[] => {
    const unfilteredCustomers: TCustomerWithStats[] = this.canSeeCustomers()
      ? this.props.customers
      // @TODO fix this test customers fixture
      : getTestCustomers(this.props.restaurant) as unknown as TCustomerWithStats[];
    const { activeFilters } = this.state;
    if (activeFilters.length) {
      return unfilteredCustomers.filter((customer) => {
        const areAllFiltersValid =
          activeFilters.filter((filter) => filter.handler(customer)).length ===
          activeFilters.length;
        return areAllFiltersValid;
      });
    }
    return unfilteredCustomers;
  };

  toggleAllCustomers = () => {
    this.setState({
      selectedCustomers:
        this.state.selectedCustomers.size < this.getCustomers().length
          ? new Set([...this.getCustomers().map((c) => c.id)])
          : new Set([]),
    });
  };

  toggleCustomer = (e) => {
    const { checked, value } = e.target;
    const newSet = new Set<number>([...this.state.selectedCustomers]);
    if (checked) {
      newSet.add(parseInt(value, 10));
    } else {
      newSet.delete(parseInt(value, 10));
    }
    this.setState({ selectedCustomers: newSet });
  };

  sendSms = (message, closeModal) =>
    this.props.sendSms({
      message,
      restaurant: this.props.restaurant,
      customers: Array.from(this.state.selectedCustomers).map(c => c.toString()),
      onSuccess: closeModal,
      onError: () => toast.error('Błąd wysyłania SMSów'),
    });

  showSendSmsModal = () =>
    this.props.setModal(
      {
        confirm: this.sendSms,
        modalSpecificProps: {
          customers: this.state.selectedCustomers,
        },
      },
      ModalTypes.SEND_SMS_MODAL
    );

  render() {
    const { error, loading } = this.props;
    if (!this.canSeeCustomers()) {
      return (
        <Card>
          <CardHeader>
            {i18next.t('marketing-page.customers-list-title')}
          </CardHeader>
          <CardBody>
            <p>
              Moduł marketingowy <strong>chroni Cię</strong> przed utratą
              klientów oraz pomaga <strong>zwiększyć obroty</strong>, dzięki
              zachęceniu ich do częstszego zamawiania
            </p>
            <p>
              Żeby korzystać z modułu marketingowego, wystarczy podpisać umowę o
              powierzeniu danych osobowych.
            </p>
            <p>
              W międzyczasie możesz zobaczyć, jakie możliwości oferuje ta strona
              na danych testowych.
            </p>
            {this.renderCustomersList()}
          </CardBody>
        </Card>
      );
    }
    return (
      <>
        {this.props.isAdmin && (
          <Card className="mb-3">
            <CardHeader>
              {i18next.t('marketing-page.marketing-campaigns-list-title')}
            </CardHeader>
            <CardBody>
              <UrlFetcher<MarketingCampaign[]> url="/marketing/campaigns">
                {([campaigns, urlFetcherProps]) => (
                  <MarketingCampaingList campaigns={campaigns} />
                )}
              </UrlFetcher>
              {/* @ts-expect-error was like this before TS migration */}
              <CreateMarketingCampaignForm onSubmit={this.createCampaign} />
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>
            {i18next.t('marketing-page.customers-list-title')}
          </CardHeader>
          <CardBody>
            {error && (
              <div>
                <Alert color="danger">
                  {i18next.t('marketing-page.customers-list-fetch-error')}
                </Alert>
                <Button color="primary" onClick={this.loadCustomers}>
                  {i18next.t('Refetch')}
                </Button>
              </div>
            )}
            {loading && <OtoSpinner center className="mb-2" />}
            {this.renderCustomersList()}
          </CardBody>
        </Card>
      </>
    );
  }

  setSelectedCustomers = (selectedCustomers) =>
    this.setState({ selectedCustomers });

  handleFiltersChange = (activeFilters) =>
    this.setState({
      activeFilters,
      selectedCustomers: new Set(),
    });

  createCampaign = (campaign) =>
    APIService.post('/marketing/campaigns', campaign)
      .then((c) => toast.success('Dodano '))
      .catch((e) => toast.error('Błąd tworzenia kampanii'));

  updateCustomerEmailsList = () => {
    const customers = this.getCustomers() || [];
    const { selectedCustomers } = this.state;
    this.setState({
      customerEmailsList: customers
        .filter((c) => selectedCustomers.has(c.id))
        .map((c) => c.email)
        .join(', '),
    });
  };

  renderCustomersList() {
    const customers = this.getCustomers();
    const { activeFilters, selectedCustomers } = this.state;
    if (!customers || !customers.length) {
      return null;
    }
    return (
      <>
        <CustomersFilter
          activeFilters={activeFilters}
          setActiveFilters={this.handleFiltersChange}
        />
        <CustomersListComponent
          allCustomers={customers}
          blurPersonalData={false}
          customerEmailsList={this.state.customerEmailsList}
          headerAddon={
            <Button
              color="primary"
              className="ml-3"
              disabled={selectedCustomers.size === 0}
              onClick={this.showSendSmsModal}
            >
              {i18next.t('marketing-page.type-sms-text')}
            </Button>
          }
          onCustomersSelect={this.setSelectedCustomers}
          selectedCustomers={selectedCustomers}
          updateCustomerEmailsList={this.updateCustomerEmailsList}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAdmin: selectIsRootAdmin(state),
  error: selectCustomersError(state),
  loading: selectCustomersLoading(state),
  customers: selectCustomers(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
});

const connector = connect(mapStateToProps, { loadCustomers, sendSms });

type PropsFromRedux = ConnectedProps<typeof connector>;

const enhance = compose(
  withSetModal,
  connector
);
const EnhancedMarketingPage = enhance(MarketingPage);

// @ts-expect-error fix global types
EnhancedMarketingPage.url = '/customers';
// @ts-expect-error fix global types
EnhancedMarketingPage.navName = 'Lista klientów';

export default EnhancedMarketingPage;

function getTestCustomers(restaurant) {
  return [
    {
      created_at: '2020-04-03',
      email: 'jankowalski@test.pl',
      email_marketing: 1,
      id: 1,
      last_order: {
        created_at: '2020-05-24',
        customer_id: 1,
        restaurant_id: restaurant.id,
      },
      name: 'Jan',
      phone: '48123123123',
      restaurant_id: restaurant.id,
      sms_marketing: 1,
      source: 'order',
      surname: 'Kowalski',
      updated_at: '2020-09-29',
    },
    {
      created_at: '2020-04-03',
      email: 'anna.nowak@test.pl',
      email_marketing: 0,
      id: 3,
      last_order: {
        created_at: '2020-09-24',
        customer_id: 3,
        restaurant_id: restaurant.id,
      },
      name: 'Anna',
      phone: '48123123123',
      restaurant_id: restaurant.id,
      sms_marketing: 1,
      source: 'order',
      surname: 'Nowak',
      updated_at: '2020-09-29',
    },
    {
      created_at: '2020-04-10',
      email: 'grzegorz.b@test.pl',
      email_marketing: 1,
      id: 2,
      last_order: {
        created_at: '2020-10-12',
        customer_id: 2,
        restaurant_id: restaurant.id,
      },
      name: 'Grzegorz',
      phone: '48456456456',
      restaurant_id: restaurant.id,
      sms_marketing: 0,
      source: 'order',
      surname: 'Brzęczyszczykiewicz',
      updated_at: '2020-09-29',
    },
  ];
}
