import React, { Component } from 'react';
import type { FormikProps } from 'formik';
import i18next from 'i18next';
import { Alert, Button, FormGroup, Input, Label } from 'reactstrap';
import { Truck } from 'react-feather';
import L from 'leaflet';
import { MapContainer, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl, EditControlProps } from 'react-leaflet-draw';
import circleToPolygon from 'circle-to-polygon';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import CommonSection from '../common/CommonSection';
import InputWithAddon from '../common/InputWithAddon';
import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import { currency } from 'globals/currency';
import { isEmptyAndNotZero } from 'utils/validators';
import OtoButtons from '../common/OtoButtons';
import { LOG_BAG } from 'utils/log';
import { OrdersConfigFormValues } from 'app/pages/OrderSettingsPage';
import { NormalizedRestaurant } from 'types';

const COMPONENT_NAME = 'DeliveryMapForm';

type DeliveryMapFormProps = {
  isAdmin: boolean;
  loading: boolean;
  handleChange: FormikProps<OrdersConfigFormValues>['handleChange'];
  restaurant: NormalizedRestaurant;
  setFieldValue: FormikProps<OrdersConfigFormValues>['setFieldValue'];
  values: OrdersConfigFormValues;
};

type DeliveryMapFormState = {
  drawOptions: EditControlProps['draw'];
};

class DeliveryMapForm extends Component<
  DeliveryMapFormProps,
  DeliveryMapFormState
> {
  static displayName = COMPONENT_NAME;

  mapCenter = [this.props.restaurant.lat, this.props.restaurant.lng];
  mapContainerStyle: React.CSSProperties = {
    height: '95vh',
    margin: '20px 0',
    zIndex: 10,
  };

  _editableFG: any;
  editRef: EditControl | null = null;

  state = {
    drawOptions: {
      rectangle: false,
      circle: {
        shapeOptions: {
          color: '#0000FF',
        },
      },
      marker: false,
      circlemarker: false,
      polyline: false,
      polygon: {
        shapeOptions: {
          color: '#0000FF',
        },
      },
    },
  };

  edgesAmount = 64;
  layersAmount = 0;
  layerColors = [
    'rgb(51, 136, 255)',
    'yellow',
    'red',
    'green',
    'purple',
    'cyan',
  ];
  warningBorder = { border: '1px solid #e74c4b' };

  editOptions = { polygon: true };

  mapInitiated = false;

  initiateMap = () => {
    if (this.mapInitiated) {
      return;
    }

    const area = this.props.values.delivery.area;
    const isProperArea = area && Object.keys(area).length > 0;
    const leafletGeoJSON = new L.GeoJSON(isProperArea ? area : null);

    // populate the leaflet FeatureGroup with the geoJson layers
    leafletGeoJSON.eachLayer((layer) => {
      layer.options.color = this.getNextLayerColor();
      this._editableFG.addLayer(layer);
    });
    this.drawWithNextColor();
    this.mapInitiated = true;
  };

  getNextLayerColor = (increaseLayersAmount = true) => {
    const value = this.getColorForIndex(this.layersAmount);
    increaseLayersAmount && this.layersAmount++;
    return value;
  };

  getColorForIndex = (index): string =>
    this.layerColors[index % this.layerColors.length] as string;

  drawWithNextColor = () => {
    console.warn('drawWithNextColor changes color only once!'); // TODO fix this
    // eslint-disable-next-line
    this.state.drawOptions.polygon.shapeOptions.color =
      this.getNextLayerColor(false);
    // eslint-disable-next-line
    this.state.drawOptions.circle.shapeOptions.color =
      this.getNextLayerColor(false);

    // setState below causes error in react-leaflet-draw
    // this.setState({
    //   drawOptions: {
    //     ...this.state.drawOptions,
    //     polygon: {
    //       shapeOptions: {
    //         color: this.getNextLayerColor(false),
    //       }
    //     }
    //   }
    // })
  };

  _onFeatureGroupReady = (reactFGref) => {
    // store the ref for future access to content
    this._editableFG = reactFGref;
    this.initiateMap();
  };

  _onCreated = (e) => {
    LOG_BAG.addLogAction(`area _onCreated from ${COMPONENT_NAME}`);
    // TODO addAreaOptions on areaCreated
    e.layer.options.color = this.getNextLayerColor();

    this.drawWithNextColor();
    this._onChange();
    this.addNewAreaOption();
  };

  _onEdited = (e) => {
    LOG_BAG.addLogAction(`area _onEdited from ${COMPONENT_NAME}`);
    const editedItemKey = Object.keys(e.layers._layers)[0];

    // check if you click edit button and don't change any shape you skip this if
    if (editedItemKey) {
      const editedItem = e.layers._layers[editedItemKey];
      const allLayers = this._editableFG._layers;

      // issue with editing saved shapes
      // only saved shapes have editedItem.feature
      // shapes created a moment ago is editable without problems
      if (editedItem.feature) {
        const newCoordinates = editedItem._latlngs[0].map((item) => [
          item['lng'],
          item['lat'],
        ]);

        editedItem.feature.geometry.coordinates[0] = newCoordinates;
        delete allLayers[editedItemKey];
        allLayers[editedItemKey] = editedItem;
      }
    }

    this._onChange();
  };

  _onDeleted = (e) => {
    LOG_BAG.addLogAction(`area _onDeleted from ${COMPONENT_NAME}`);
    const itemKey = Object.keys(e.layers._layers)[0];

    if (itemKey) {
      this.layersAmount--;
    }

    this._onChange();
  };

  _onChange = () => {
    if (!this._editableFG) {
      return;
    }

    const manualFeaturesObj = this.createFeaturesObj(this._editableFG._layers);

    this.props.setFieldValue('delivery.area', manualFeaturesObj);
  };

  addNewAreaOption = () => {
    LOG_BAG.addLogAction(`area addNewAreaOption from ${COMPONENT_NAME}`);
    const { areaOptions = [] } = this.props.values.delivery;
    return this.props.setFieldValue('delivery.areaOptions', [
      ...areaOptions,
      {
        name: `Strefa ${areaOptions.length + 1}`,
        price: '',
        minCartAmount: '',
        freeDeliveryFrom: '',
      },
    ]);
  };

  removeAreaOption = (index) => {
    LOG_BAG.addLogAction(`area removeAreaOption from ${COMPONENT_NAME}`);
    const { areaOptions = [] } = this.props.values.delivery;
    return this.props.setFieldValue('delivery.areaOptions', [
      ...areaOptions.slice(0, index),
      ...areaOptions.slice(index + 1),
    ]);
  };

  createFeaturesObj(layers) {
    const layersLen = Object.keys(layers).length;
    const arrayOfResultObj: any[] = [];

    for (let i = 0; i < layersLen; i++) {
      const layer = this.getLayerWithIndex(layers, i);
      arrayOfResultObj.push(this.getLayerFeature(layer));
    }

    return {
      type: 'FeatureCollection',
      features: arrayOfResultObj,
    };
  }

  getLayerWithIndex(layers, index) {
    return layers[Object.keys(layers)[index]];
  }

  getLayerFeature(layer) {
    if (layer.feature) {
      return layer.feature;
    }
    if (layer._mRadius) {
      return this.getCircleLikeManualPolygon(layer);
    }
    return this.getManualPolygon(layer);
  }

  getManualPolygon(layer) {
    const {
      editing: { latlngs },
    } = layer;

    const coordinates = latlngs[0][0].map((item) => [item['lng'], item['lat']]);

    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    };
  }

  getCircleLikeManualPolygon(layer) {
    // GeoJSON doesn't support circle so
    // We create circle and next convert to polygon with x edges
    const {
      _mRadius: radius,
      _latlng: { lng, lat },
    } = layer;

    const polygon = circleToPolygon([lng, lat], radius, this.edgesAmount);

    return {
      type: 'Feature',
      properties: {
        radius,
      },
      geometry: {
        type: 'Polygon',
        coordinates: polygon.coordinates,
      },
    };
  }

  render() {
    const { loading, restaurant, values } = this.props;
    const { lat, lng } = restaurant;
    // console.error('ONLY LUBLIN COORDS WORKING!');
    return (
      <CommonSection
        header={
          <>
            <Truck /> {i18next.t('delivery-settings-form.title')}
          </>
        }
      >
        {lat === 0 && lng === 0 ? (
          <Alert className="mt-2">
            {i18next.t(
              'delivery-map-form.set-restaurant-coordinates-to-continue'
            )}
          </Alert>
        ) : (
          <>
            {this.renderMap()}
            {this.renderAreaOptions()}
            <SubmitButtonWithLoader className="mt-3" loading={loading} />
          </>
        )}
      </CommonSection>
    );
  }

  renderMap() {
    return (
      <MapContainer
        // @FIXME was working before TS migration, probably wrong types
        // @ts-expect-error
        center={this.mapCenter}
        zoom={13}
        zoomControl
        style={this.mapContainerStyle}
      >
        <TileLayer
          // @FIXME was working before TS migration, probably wrong types
          // @ts-expect-error
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <FeatureGroup
          ref={(reactFGref) => {
            this._onFeatureGroupReady(reactFGref);
          }}
        >
          <EditControl
            ref={(ref) => {
              this.editRef = ref;
            }}
            position="topleft"
            onCreated={this._onCreated}
            onDeleted={this._onDeleted}
            onEdited={this._onEdited}
            draw={this.state.drawOptions}
            edit={this.editOptions}
          />
        </FeatureGroup>
      </MapContainer>
    );
  }

  renderAreaOptions() {
    const { handleChange, values } = this.props;
    const { area, areaOptions = [] } = values.delivery;
    const hasTooFewAreaOptions =
      areaOptions.length < (area?.features || []).length;
    const hasTooManyAreaOptions =
      areaOptions.length > (area?.features || []).length;
    return (
      <>
        {hasTooFewAreaOptions && (
          <>
            <Alert color="danger">
              Masz mniej ustawień dla stref dostaw, niż narysowanych stref.
              Najprawodpodobniej doszło do błędu podczas tworzenia strefy dostaw
              na mapie.
            </Alert>
            <Button
              color="primary"
              type="button"
              className="mt-2 mb-2"
              onClick={this.addNewAreaOption}
            >
              Dodaj ustawienia dla strefy dostaw
            </Button>
          </>
        )}
        {hasTooManyAreaOptions && (
          <Alert color="danger">
            Jest więcej ustawień dla stref dostaw, niż narysowanych stref.
            Najprawodpodobniej doszło do błędu podczas usuwania strefy dostaw na
            mapie. Usuń zbędne ustawienia dla stref dostaw i zapisz ustawienia.
          </Alert>
        )}
        {areaOptions.map((areaOption, index) => (
          <FormGroup key={index}>
            <Label>
              <span>{i18next.t('delivery-map-form.zone-name-label')}</span>
              <Input
                name={`delivery.areaOptions[${index}].name`}
                value={areaOption?.name || `Strefa ${index + 1}`}
                onChange={handleChange}
              />
              <hr
                style={{
                  borderBottom: `10px solid ${this.getColorForIndex(index)}`,
                  margin: '5px 0 0',
                }}
              />
            </Label>
            <Label>
              <span
                className={
                  isEmptyAndNotZero(areaOption?.minCartAmount)
                    ? 'text-danger'
                    : ''
                }
              >
                {i18next.t('delivery-map-form.min-cart-amount-label')}
              </span>
              <InputWithAddon
                addonText={currency}
                id={`delivery.areaOptions[${index}].minCartAmount`}
                name={`delivery.areaOptions[${index}].minCartAmount`}
                value={areaOption?.minCartAmount}
                onChange={handleChange}
                type="number"
                step="1"
                min="0"
                style={
                  isEmptyAndNotZero(areaOption?.minCartAmount)
                    ? this.warningBorder
                    : undefined
                }
              />
            </Label>
            <Label>
              <span
                className={
                  isEmptyAndNotZero(areaOption?.price) ? 'text-danger' : ''
                }
              >
                {i18next.t('delivery-map-form.delivery-cost-label')}
              </span>
              <InputWithAddon
                addonText={currency}
                id={`delivery.areaOptions[${index}].price`}
                name={`delivery.areaOptions[${index}].price`}
                value={areaOption?.price}
                onChange={handleChange}
                type="number"
                step="0.5"
                min="0"
                className={'text-facebook'}
                style={
                  isEmptyAndNotZero(areaOption?.price)
                    ? this.warningBorder
                    : undefined
                }
              />
            </Label>
            <Label>
              <span
                className={
                  isEmptyAndNotZero(areaOption?.freeDeliveryFrom)
                    ? 'text-danger'
                    : ''
                }
              >
                {i18next.t('delivery-map-form.free-delivery-from-label')}
              </span>
              <InputWithAddon
                addonText={currency}
                id={`delivery.areaOptions[${index}].freeDeliveryFrom`}
                name={`delivery.areaOptions[${index}].freeDeliveryFrom`}
                value={areaOption?.freeDeliveryFrom}
                onChange={handleChange}
                type="number"
                step="0.5"
                min="0"
                style={
                  isEmptyAndNotZero(areaOption?.freeDeliveryFrom)
                    ? this.warningBorder
                    : undefined
                }
              />
            </Label>
            {hasTooManyAreaOptions && (
              <Label>
                <OtoButtons.DeleteButton
                  onClick={() => this.removeAreaOption(index)}
                />
              </Label>
            )}
          </FormGroup>
        ))}
      </>
    );
  }
}

export default DeliveryMapForm;
