import React from 'react';

import CenterCard from './CenterCard';
import OtoSpinner from './OtoSpinner';

export const CenterCardSpinner = ({ text = '', fullHeight = true }) => (
  <CenterCard fullHeight={fullHeight}>
    <OtoSpinner center />
    {text && <span>{text}</span>}
  </CenterCard>
);

export default CenterCardSpinner;
