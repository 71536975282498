import React from 'react';
import Restaurant from 'utils/restaurant';

import CollapsibleCard from '../common/CollapsibleCard';
import { TutorialLinkGroup } from './helpers';
import type { NormalizedRestaurant } from 'types';

const fbTutorialsLink = `https://drive.google.com/file/d/1jXWcabaskEqFUuoptMXIgc14Ks9vUZyJ/view`;

type ReservationLinksProps = {
  restaurant: NormalizedRestaurant;
}

const ReservationLinks: React.FC<ReservationLinksProps> = ({ restaurant }) => {

  if (!Restaurant.hasReservations(restaurant)) {
    return null;
  }

  const { slug } = restaurant;

  const fbBookNowLink = `https://otostolik.pl/rezerwuj/${slug}?utm_source=facebook&utm_medium=book_now_button`;
  const messengerBookNowLink = `https://otostolik.pl/rezerwuj/${slug}?utm_source=facebook&utm_medium=messenger`;
  const fbStoryBookLink = `https://otostolik.pl/rezerwuj/${slug}?utm_source=facebook&utm_medium=fb_story`;
  const googleMapsBookNowLink = `https://otostolik.pl/rezerwuj/${slug}?utm_source=google_maps`;

  return (
    <CollapsibleCard
      title="Linki do ustawienia (automatyczne rezerwacje):"
      className="mt-2"
    >
      <TutorialLinkGroup
        label={`Przycisk "Zarezerwuj teraz" na Facebook`}
        tutorialLink={fbTutorialsLink}
        valueLink={fbBookNowLink}
      />
      <TutorialLinkGroup
        label="Automatyczna odpowiedź na Messenger"
        tutorialLink={fbTutorialsLink}
        valueLink={messengerBookNowLink}
      />
      <TutorialLinkGroup
        label="Facebook Story"
        tutorialLink={fbTutorialsLink}
        valueLink={fbStoryBookLink}
      />
      <TutorialLinkGroup
        label="Rezerwacja z Google Maps"
        tutorialLink={googleMapsBookNowLink}
        valueLink={googleMapsBookNowLink}
      />
    </CollapsibleCard>
  );
};

export default ReservationLinks;
