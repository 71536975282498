import React from 'react';
import { useFilters, useSortBy, useTable, usePagination } from 'react-table';
import { Button, Input } from 'reactstrap';

import type { IColumn } from './simpleTableHelpers';
import { DefaultColumnFilter } from './SimpleTableFitlers';

const availablePageSizes = [5, 10, 25, 50, 100] as const;

interface SimpleTableWithSortingProps {
  addWrapper?: boolean;
  className?: string;
  columns: IColumn[];
  data: any[];
  minPagesAmountToShowInput?: number;
  hasFilters?: boolean;
  pageSize?: (typeof availablePageSizes)[number];
  showFooter?: boolean;
}

const adminStatsTableStyle = {
  thStyle: {
    borderBottom: 'solid 3px red',
    background: 'aliceblue',
    color: 'black',
    cursor: 'pointer',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    textTransform: 'none',
  } as React.CSSProperties,
  tdStyle: {
    padding: '10px',
    border: 'solid 1px gray',
  } as React.CSSProperties,
};

export const SimpleTableWithSorting = (props: SimpleTableWithSortingProps) => {
  const { minPagesAmountToShowInput = 4 } = props;
  const data = React.useMemo(() => props.data, [props.data]);
  const columns = React.useMemo(() => props.columns, [props.columns]);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const options = React.useMemo(() => {
    const pageSizeState = props.pageSize ? { pageSize: props.pageSize } : {};
    const filterProps = props.hasFilters
      ? {
          defaultColumn,
          filterTypes,
        }
      : {};
    return {
      columns,
      data,
      ...filterProps,
      initialState: { ...pageSizeState },
    };
  }, [
    data,
    columns,
    defaultColumn,
    filterTypes,
    props.pageSize,
    props.hasFilters,
  ]);

  const tableAddons = props.hasFilters
    ? [useFilters, useSortBy, usePagination]
    : [useSortBy, usePagination];

  const tableInstance = useTable(options, ...tableAddons);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // pagination options start
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    // pagination options end
  } = tableInstance;

  const RootTableElement = props.addWrapper ? 'div' : React.Fragment;
  const rootTableElementProps = props.addWrapper
    ? { className: 'table-rwd-wrapper ' }
    : {};

  const { showFooter = true } = props;

  return (
    <RootTableElement {...rootTableElementProps}>
      <table {...getTableProps()} className={`table ${props.className}`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={adminStatsTableStyle.thStyle}
                >
                  <div>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={adminStatsTableStyle.tdStyle}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showFooter && (
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <Button
              type="button"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              &lt; Poprzednia
            </Button>
            <div className="mx-2">
              Strona{' '}
              <em>
                {pageIndex + 1} z {pageOptions.length}
              </em>
            </div>
            <Button
              type="button"
              className="mr-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Następna &gt;
            </Button>
          </div>
          {!minPagesAmountToShowInput ||
          pageOptions.length >= minPagesAmountToShowInput ? (
            <div className="d-flex align-items-center">
              <div>Idź do strony:</div>
              <Input
                type="number"
                className="mw-150 ml-2 mr-3"
                defaultValue={pageIndex + 1 || 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </div>
          ) : null}
          <Input
            type="select"
            className="mw-200"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {availablePageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Rozmiar strony: {pageSize}
              </option>
            ))}
          </Input>
        </div>
      )}
    </RootTableElement>
  );
};

SimpleTableWithSorting.defaultProps = {
  addWrapper: true,
  className: '',
};

export default SimpleTableWithSorting;
