import { useEffect, useState } from 'react';
import APIService from 'services/api';

// import { ERROR_MESSAGES } from 'helpers/errors';
// import { errorNotification } from 'helpers/notification';
// import http from 'helpers/http';
import useIsMounted from './useIsMounted';

export interface IUseDataOptions {
  method?: 'get' | 'post' | 'put' | 'delete';
  url: string;
  deps?: any[];
  callback?: Function;
  options?: {};
}

export interface IData<T> {
  [key: string]: any;
  data: T;
}

// Hook for everything network request especially on component level
const useData = <T>(args: IUseDataOptions) => {
  const { method = 'get', url, deps = [], callback, options = {} } = args;
  const [data, setData] = useState<IData<T>>();
  const [error, setError] = useState(null);
  const isMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      const makeRequest = async () => {
        try {
          const data: IData<T> = await APIService[method](url, options);
          isMounted() && setData(data);
          if (typeof callback === 'function') callback(data);
        } catch (error) {
          isMounted() && setError(error as any);
          // errorNotification(ERROR_MESSAGES.RULE_SNAPSHOT);
        }
      };

      await makeRequest();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, url, isMounted, ...deps]);

  return {
    data,
    error,
    isLoading: !error && !data,
  };
};

export default useData;
