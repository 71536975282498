import PropTypes from 'prop-types';

import restaurantPlaceShape from './restaurant-place';

const plainRestaurantTableFields = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  max_people: PropTypes.number.isRequired,
  min_people: PropTypes.number.isRequired,
  linked_to: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  place_id: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
  is_bookable: PropTypes.bool.isRequired,
  restaurant_id: PropTypes.number.isRequired,
};

export const restaurantTableShape = PropTypes.shape({
  ...plainRestaurantTableFields,
  place: restaurantPlaceShape,
});

export const restaurantTableWithPlaceNameShape = PropTypes.shape({
  ...plainRestaurantTableFields,
  placeName: PropTypes.string.isRequired,
});

export const restaurantTableWithPlaceShape = PropTypes.shape({
  ...plainRestaurantTableFields,
  place: restaurantPlaceShape.isRequired,
});

export default restaurantTableShape;
