import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import i18n from 'i18next';
import { TabContent, TabPane } from 'reactstrap';

import Restaurant from 'utils/restaurant';
import CallForCourrierForm from 'app/components/deliveries/CallForCourrierForm';
import RestaurateurDeliveriesList from 'app/components/deliveries/RestaurateurDeliveriesList';
import DeliveriesMap from 'app/components/deliveries/DeliveriesMap';
import DeliveriesFetcher from '../containers/DeliveriesFetcher';
import {
  addDelivery,
  updateDelivery,
  deleteDelivery,
  selectDeliveries,
  selectDeliveriesLoading,
} from 'store/deliveries';
import { selectRestaurant } from 'store/restaurant';
import UrlFetcher from '../containers/UrlFetcher';
import { isDelivered } from 'utils/deliveries';
import { differenceInMinutes } from 'date-fns/esm';
import { LOG_BAG } from 'utils/log';
import OtoTabs from 'app/components/common/OtoTabs';
import { selectCanEditDeliveries, selectHasMultipleRestaurants, selectIsRootAdmin } from 'store/app';
import { NormalizedRestaurant, User } from 'types';

type DeliveriesPageProps = PropsFromRedux;

type DeliveriesPageState = {
  activeTab: string;
};

const COMPONENT_NAME = 'DeliveriesPage';


class DeliveriesPage extends React.Component<
  DeliveriesPageProps,
  DeliveriesPageState
> {
  tabs = {
    deliveriesList: i18n.t('deliveries-page.deliveries-list-tab'),
    deliveriesMap: i18n.t('deliveries-page.deliveries-map-tab'),
  };

  static displayName = COMPONENT_NAME;
  static getDerivedStateFromError = LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  state: DeliveriesPageState = { activeTab: this.tabs.deliveriesList };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  handleDeliveryUpdate = (delivery, payload) =>
    this.props.updateDelivery(this.props.deliveries, delivery, payload);

  handleDeliverDelete = (delivery) =>
    this.props.deleteDelivery(this.props.deliveries, delivery);

  render() {
    const {
      addDelivery,
      canEditDeliveries,
      deliveries,
      deliveriesLoading,
      isAdmin,
      restaurant,
    } = this.props;

    if (!Restaurant.canOrderDelivery(restaurant)) {
      return null;
    }

    const filteredDeliveries = deliveries.filter(
      (item) =>
        !isDelivered(item) ||
        (isDelivered(item) &&
          differenceInMinutes(new Date(), new Date(item.updated_at)) < 30)
    );

    const { activeTab } = this.state;
    return (
      <>
        <DeliveriesFetcher showLastFetchTime />
        <CallForCourrierForm
          addDelivery={addDelivery}
          isAdmin={isAdmin}
          restaurant={restaurant}
        />
        <OtoTabs
          activeTab={activeTab}
          changeTab={this.toggle}
          tabs={this.tabs}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId={this.tabs.deliveriesList}>
            <UrlFetcher<User[]> url={`/restaurants/${restaurant.id}/drivers`}>
              {([drivers]) => (
                <RestaurateurDeliveriesList
                  canEditDeliveries={canEditDeliveries}
                  deliveries={deliveries}
                  drivers={drivers}
                  loading={deliveriesLoading}
                  hasMultipleRestaurants={this.props.hasMultipleRestaurants}
                  handleDeliveryUpdate={this.handleDeliveryUpdate}
                  handleDeliveryDelete={this.handleDeliverDelete}
                />
              )}
            </UrlFetcher>
          </TabPane>
          <TabPane tabId={this.tabs.deliveriesMap}>
            {activeTab === this.tabs.deliveriesMap && (
              <DeliveriesMap
                deliveries={filteredDeliveries}
                restaurant={restaurant}
              />
            )}
          </TabPane>
        </TabContent>
      </>
    );
  }
}

// @ts-expect-error @FIXME route class component type
DeliveriesPage.url = '/deliveries';

// @ts-expect-error @FIXME route class component type
DeliveriesPage.navName = 'Lista dostaw';

const mapStateToProps = (state) => ({
  isAdmin: selectIsRootAdmin(state),
  canEditDeliveries: selectCanEditDeliveries(state),
  deliveries: selectDeliveries(state),
  deliveriesLoading: selectDeliveriesLoading(state),
  hasMultipleRestaurants: selectHasMultipleRestaurants(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
});
const mapDispatchToProps = {
  addDelivery,
  updateDelivery,
  deleteDelivery,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeliveriesPage);
