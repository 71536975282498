import React from 'react';
import { Input } from 'reactstrap';

export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: {
  column: {
    filterValue: string | undefined;
    preFilteredRows: unknown[];
    setFilter: (value: string | undefined) => void;
  };
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      value={filterValue || ''}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export type TSimpleTableColumnFilterProps = {
  column: {
    filterValue: string | undefined;
    preFilteredRows: any[];
    setFilter: (value: string | undefined) => void;
    id: any;
  };
};

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: TSimpleTableColumnFilterProps) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set<string | number | undefined>();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Input
      type="select"
      value={filterValue}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Wszystkie</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Input>
  );
}
