import React from 'react';
import { Card, CardHeader } from 'reactstrap';

import { ModalTypes } from 'config';
import APIService from 'services/api';
import Logger from 'utils/log';
import { toast } from 'react-toastify';
import { getArrayWithUpdatedObject, getArrayWithoutObject } from 'utils/array';
import PagePropertiesEdit from './PagePropertiesEdit';

import { TSetModalFunction } from 'app/containers/WithSetModal';
import { AnyRestaruant, EIntegrationType, IIntegration } from 'types';
import IntegrationTile from './IntegrationTile';

type ViewRestaurantIntegrationsProps = {
  integrations: IIntegration[];
  urlFetcherProps: {
    setData: (integrations: IIntegration[]) => void;
  };
  restaurant?: AnyRestaruant;
  setModal: TSetModalFunction;
};

type ViewRestaurantIntegrationsState = {
  updateLoading: boolean;
};

const modalTitlesByIntegrationType: Record<EIntegrationType, string> = {
  [EIntegrationType.FACEBOOK_PIXEL]: 'Wprowadź Facebook Pixel ID: ',
  [EIntegrationType.GOOGLE_ANALYTICS]: 'Wprowadź Google Analytics UA: ',
  [EIntegrationType.IFRAME]: 'Wprowadź główny kolor strony: ',
  [EIntegrationType.FOODELI]: 'Wprowadź API key Foodeli',
  [EIntegrationType.SZAMAEXPRESS]: 'Wprowadź API key SzamaExpress',
  [EIntegrationType.DELIVERY_COUPLE]:
    'Wprowadź ID restauracji w systemie DeliveryCouple',
};

const getIntegrationPayload = ({
  type,
  value,
  restaurantId,
}: {
  type: EIntegrationType;
  value: string;
  restaurantId: number;
}): Partial<IIntegration> => {
  if (type === EIntegrationType.SZAMAEXPRESS) {
    return {
      restaurant_id: restaurantId,
      type,
      data: {
        api_key: value,
      },
    };
  }
  if (type === EIntegrationType.DELIVERY_COUPLE) {
    return {
      restaurant_id: restaurantId,
      type,
      data: {
        external_restaurant_id: parseInt(value),
      },
    };
  }
  if (type === EIntegrationType.FOODELI) {
    return {
      restaurant_id: restaurantId,
      type,
      data: {
        email: value,
      },
    };
  }
  if (type === EIntegrationType.IFRAME) {
    return {
      restaurant_id: restaurantId,
      type,
      data: {
        css: {
          primaryColor: value,
        },
      },
    };
  }
  return {
    restaurant_id: restaurantId,
    type,
    data: {
      value,
    },
  };
};

const getIntegrationValue = (integration: IIntegration | null): string => {
  if (!integration) {
    return '';
  }
  if (integration?.type === EIntegrationType.SZAMAEXPRESS) {
    return integration.data.api_key as string;
  }
  if (integration?.type === EIntegrationType.DELIVERY_COUPLE) {
    return integration.data.external_restaurant_id.toString();
  }
  if (integration?.type === EIntegrationType.FOODELI) {
    return integration.data.email;
  }
  if (integration?.type === EIntegrationType.IFRAME) {
    return integration.data.css?.primaryColor as string;
  }
  return integration.data.value as string;
};

export default class ViewRestaurantIntegrations extends React.Component<
  ViewRestaurantIntegrationsProps,
  ViewRestaurantIntegrationsState
> {
  state = { updateLoading: false };

  handleEnableIntegrationClick = (
    type: EIntegrationType,
    existingIntegration: IIntegration | null
  ) => {
    this.props.setModal(
      {
        title: modalTitlesByIntegrationType[type],
        confirm: (value: string) => {
          if (!this.props.restaurant) {
            toast.error(
              'Wystąpił błąd uruchamiania integracji - nie podano restauracji'
            );
            return;
          }
          const payload: Partial<IIntegration> = getIntegrationPayload({
            type,
            value,
            restaurantId: this.props.restaurant.id,
          });
          this.saveIntegration(payload, existingIntegration);
        },
        confirmColor: 'success',
        confirmText: 'Zapisz',
        cancelText: 'Anuluj',
        initialValue: getIntegrationValue(existingIntegration),
      },
      ModalTypes.INPUT
    );
  };

  deleteIntegration = (integration: IIntegration) => {
    if (!this.props.restaurant) {
      toast.error('Wystąpił błąd usuwania integracji - nie podano restauracji');
      return;
    }

    this.setState({ updateLoading: true });
    const url = `/restaurants/${this.props.restaurant.id}/integrations/${integration.id}`;
    return APIService.del(url)
      .then(() => {
        toast.success('Integracja została usunięta');
        this.props.urlFetcherProps.setData(
          getArrayWithoutObject(this.props.integrations, integration)
        );
      })
      .catch((e) => {
        toast.error('Wystąpił błąd usuwania integracji');
        Logger.fetchError({ e, url });
      })
      .finally(() => {
        this.setState({ updateLoading: false });
      });
  };

  handleDeleteIntegrationClick = (integration: IIntegration) => () => {
    this.props.setModal(
      {
        title: `Czy na pewno chcesz usunąć integrację ${integration.type}?`,
        confirm: () => this.deleteIntegration(integration),
        confirmColor: 'danger',
        confirmText: 'Usuń',
        cancelText: 'Anuluj',
      },
      ModalTypes.CONFIRM
    );
  };

  saveIntegration = (
    payload: Partial<IIntegration>,
    existingIntegration: IIntegration | null
  ) => {
    if (!this.props.restaurant) {
      toast.error('Wystąpił błąd zapisu integracji - nie podano restauracji');
      return;
    }

    const isPut = !!existingIntegration;
    const putUrlPart = existingIntegration ? `/${existingIntegration.id}` : '';
    const url = `/restaurants/${this.props.restaurant.id}/integrations${putUrlPart}`;
    const apiAction = isPut ? APIService.put : APIService.post;
    this.setState({ updateLoading: true });
    return apiAction(url, payload)
      .then((newIntegration) => {
        isPut
          ? this.props.urlFetcherProps.setData(
              getArrayWithUpdatedObject(this.props.integrations, newIntegration)
            )
          : this.props.urlFetcherProps.setData([
              ...this.props.integrations,
              newIntegration,
            ]);
        toast.success(
          `Integracja z została ${isPut ? 'zaktualizowana' : 'zapisana'}`
        );
      })
      .catch((e) => {
        Logger.fetchError({ e, url });
        toast.error(
          `Wystąpił błąd ${isPut ? 'aktualizacji' : 'zapisu'} nowej integracji`
        );
      })
      .finally(() => {
        this.setState({ updateLoading: false });
      });
  };

  render() {
    const { integrations } = this.props;
    const iframeExistingIntegration = integrations.find(
      (i) => i.type === EIntegrationType.IFRAME
    );
    return (
      <div className="row">
        {[
          EIntegrationType.FACEBOOK_PIXEL,
          EIntegrationType.GOOGLE_ANALYTICS,
          EIntegrationType.SZAMAEXPRESS,
          EIntegrationType.DELIVERY_COUPLE,
          EIntegrationType.FOODELI,
        ].map((integrationType: EIntegrationType) => {
          const existingIntegration =
            integrations.find((i) => i.type === integrationType) || null;
          return (
            <IntegrationTile
              key={integrationType}
              className="mr-2 min-w-300"
              type={integrationType}
              onEnableIntegrationClick={() =>
                this.handleEnableIntegrationClick(
                  integrationType,
                  existingIntegration
                )
              }
              onDeleteIntegrationClick={
                existingIntegration
                  ? this.handleDeleteIntegrationClick(existingIntegration)
                  : undefined
              }
              value={getIntegrationValue(existingIntegration)}
            />
          );
        })}
        <Card className="mr-2 min-w-300">
          <CardHeader>Kolory strony</CardHeader>
          <PagePropertiesEdit
            onSave={(themeValues) =>
              this.saveIntegration(
                {
                  restaurant_id: this.props.restaurant?.id,
                  type: EIntegrationType.IFRAME,
                  data: {
                    css: themeValues,
                  },
                },
                iframeExistingIntegration || null
              )
            }
            loading={this.state.updateLoading}
            value={
              // @ts-expect-error type mismatch, but it's ok
              iframeExistingIntegration?.data.css as { primaryColor: string }
            }
          />
        </Card>
      </div>
    );
  }
}
