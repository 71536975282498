import { getWeekDayIndex, isToday } from './date-time';
import type { OldFormatDeliveryHour, OrdersConfig } from 'types';

export function areOrdersEnabled(ordersConfig: OrdersConfig) {
  return !(ordersConfig.allowOrders && ordersConfig.allowOrders !== true);
}

export function isDeliveryClosed(ordersConfig) {
  const weekdayIndex = getWeekDayIndex(new Date());
  const todayDeliveryHours = ordersConfig.deliveryHours[weekdayIndex];
  return _isDeliveryClosed(todayDeliveryHours);
}

// copied from landing - make sure both are consistent
function _isDeliveryClosed(
  deliveryHours: OldFormatDeliveryHour,
  date = new Date()
) {
  const { open, close, isClosed } = deliveryHours;
  if (!open || !close) {
    return false;
  }
  if (isClosed) {
    return true;
  }
  const currentHour = date.getHours();
  const currentMinutes = currentHour * 60 + date.getMinutes();
  const openMinutes = open.hour * 60 + (open.min || 0);
  const closeMinutes = close.hour * 60 + (close.min || 0);
  const closesAfterMidnight = close.hour < open.hour;
  if (closesAfterMidnight) {
    if (
      currentHour >= open.hour ||
      currentHour < close.hour ||
      (currentHour === close.hour && currentMinutes <= close.min)
    ) {
      return false;
    }
    if (currentHour < open.hour || currentHour > close.hour) {
      return true;
    }
    if (isToday(date)) {
      return false;
    }
  }
  if (currentMinutes < openMinutes) {
    return true;
  }
  if (currentMinutes > closeMinutes) {
    return true;
  }
  return false;
}
