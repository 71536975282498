import React from 'react';
import { Button, Card, Container, CardTitle, CardText } from 'reactstrap';
import i18next from 'i18next';

import CompanyForm from 'app/components/sign-up/CompanyForm';
import RestaurantForm from 'app/components/sign-up/RestaurantForm';
import SuccessForm from 'app/components/sign-up/SuccessForm';
import ViewLanguagePicker from 'app/views/ViewLanguagePicker';
import HelmetMetaProvider from 'app/components/HelmetMetaProvider';
import { LOG_BAG } from 'utils/log';
import { cities } from 'config';
import { getSafeLsJSON } from 'utils/parsers';
import SmartLogo from 'app/components/SmartLogo';
import Footer from '../theme-components/Footer/Footer';
import DeveloperPanel from 'app/components/debug/DeveloperPanel';

export default class SignUpPage extends React.Component {
  state = {
    company: getSafeLsJSON('signup-company', null),
    representer: getSafeLsJSON('signup-company-representer', null),
    restaurants: getSafeLsJSON('signup-restaurants', null),
    showSuccessForm: false,
  };

  handleRestaurantDataSubmit = (onBoardData) => {
    const newRestaurants = [
      ...(this.state.restaurants || []),
      {
        ...onBoardData.restaurant,
        city_id: parseInt(onBoardData.restaurant.city_id, 10),
      },
    ];
    localStorage.setItem('signup-restaurants', JSON.stringify(newRestaurants));
    this.setState({
      restaurants: newRestaurants,
      onBoardData,
      showSuccessForm: true,
    });
  };

  handleCompanyDataSubmit = (createdCompany, representer) => {
    localStorage.setItem('signup-company', JSON.stringify(createdCompany));
    localStorage.setItem(
      'signup-company-representer',
      JSON.stringify(representer)
    );
    this.setState({
      company: createdCompany,
      representer,
    });
  };

  resetCompany = () => {
    localStorage.removeItem('signup-company');
    this.setState({ company: null });
  };

  render() {
    if (this.state.showSuccessForm) {
      return <SuccessForm onBoardData={this.state.onBoardData} />;
    }
    const { company } = this.state;
    return (
      <>
        <Container className="mt-4 mx-auto px-2">
          <HelmetMetaProvider pageTitle="Rejestracja" />
          <div className="mx-auto col-xl-9">
            <div className="d-flex mb-3 justify-content-between mx-auto">
              <SmartLogo />
              <ViewLanguagePicker />
            </div>
            <Card body inverse color="primary">
              <CardTitle tag="h2">{i18next.t('sign-up.title')}</CardTitle>
              <CardText>{i18next.t('sign-up.text')}</CardText>
            </Card>
            <Card body>
              {!this.state.company ? (
                <CompanyForm onSubmit={this.handleCompanyDataSubmit} />
              ) : (
                <>
                  <div className="mb-3">
                    <h4>
                      {i18next.t('sign-up.current-company-text')}:{' '}
                      {company.name}
                    </h4>
                    <small>NIP: {company.nip}</small>
                    <div>
                      <Button color="primary" onClick={this.resetCompany}>
                        {i18next.t('sign-up.change-company')}
                      </Button>{' '}
                    </div>
                  </div>
                  <RestaurantForm
                    cities={cities}
                    company={this.state.company}
                    representer={this.state.representer}
                    onSubmit={this.handleRestaurantDataSubmit}
                  />
                </>
              )}
            </Card>
            <div className="mt-2 mb-4 d-flex justify-content-center">
              <span className="mr-2">
                {i18next.t('sign-up.has-account-text')}
              </span>
              <a href="/login">{i18next.t('sign-up.login-btn')}</a>
            </div>
          </div>
        </Container>
        <Footer>
          <DeveloperPanel />
        </Footer>
      </>
    );
  }
}

const COMPONENT_NAME = 'SignUpPage';
SignUpPage.displayName = COMPONENT_NAME;
SignUpPage.getDerivedStateFromError =
  LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);
