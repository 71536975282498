import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import { Tenants } from 'store/tenants';
import OrdersPerRestaurantStats from '../admin-statistics/OrdersPerRestaurantStats';

interface IProps {
  className?: string;
  tenant: Tenants;
}

const TenantDashboard: React.FC<IProps> = (props) => {
  const { className, tenant } = props;
  return (
    <section className={className}>
      <h1 className="h3">{tenant.toLocaleUpperCase()} dashboard</h1>
      <h2 className="h4">Porównanie okresów</h2>
      <p>Wybierz okresy w obu kalendarzach, aby porównać</p>
      <Row>
        <Col lg={6}>
          <OrdersPerRestaurantStats defaultRange="lastWeek" tenant={tenant} />
        </Col>
        <Col lg={6}>
          <OrdersPerRestaurantStats defaultRange="week" tenant={tenant} />
        </Col>
      </Row>
    </section>
  );
};

TenantDashboard.propTypes = {
  tenant: PropTypes.oneOf(Object.values(Tenants)).isRequired,
};

export default TenantDashboard;
