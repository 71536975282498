import React, { useMemo } from 'react';

import SimpleTableWithSorting, {
  generateColumn,
  IColumn,
} from 'app/components/common/SimpleTableWithSorting';
import { roleNamesById } from 'enums';
import { TUser, TUserRestaurant } from 'types';

const userRestaurantsColumns: IColumn[] = [
  generateColumn('id'),
  generateColumn('userName', {
    customHeader: 'Użytkownik (imie, ID)',
  }),
  generateColumn('userEmail', {
    customHeader: 'Email',
  }),
  {
    accessor: 'roleAsString',
    Header: 'Role',
  },
  {
    accessor: 'restaurant',
    Header: 'Restauracja (nazwa, ID)',
  },
];

type TMinimalRestaurant = {
  id: number;
  name: string;
};

interface UsersRestaurantsTableProps {
  users: TUser[];
  userRestaurants: TUserRestaurant[];
  restaurants: TMinimalRestaurant[];
}

type TUserRestaurantWithUserFields = TUserRestaurant & {
  userName: string;
  userEmail: string;
  roleAsString: string;
  restaurant: string;
};

export const UsersRestaurantsTable: React.FC<UsersRestaurantsTableProps> = ({
  restaurants,
  users,
  userRestaurants,
}) => {
  const formattedUserRestaurants: TUserRestaurantWithUserFields[] =
    useMemo(() => {
      const usersById: Record<number, TUser> = users.reduce((acc: Record<number, TUser>, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      const restaurantsById: Record<number, TMinimalRestaurant> =
        restaurants.reduce(
          (acc: Record<number, TMinimalRestaurant>, restaurant) => {
            acc[restaurant.id] = restaurant;
            return acc;
          },
          {}
        );

      return userRestaurants.map(({ user_id, role_id, ...rest }) => {
        const user: TUser | undefined = usersById[user_id];
        const restaurant: TMinimalRestaurant | undefined =
          restaurantsById[rest.restaurant_id];
        return {
          ...rest,
          user_id,
          role_id,
          userName: `${user?.name} (${user?.id})`,
          userEmail: user?.email || '-',
          roleAsString: roleNamesById[role_id]
            ? `${roleNamesById[role_id]} (${role_id})`
            : '-',
          restaurant: restaurant
            ? `${restaurant?.name} (${restaurant.id})`
            : 'Brak restauracji',
        };
      });
    }, [restaurants, users, userRestaurants]);
  return (
    <SimpleTableWithSorting
      columns={userRestaurantsColumns}
      data={formattedUserRestaurants}
      hasFilters
      pageSize={50}
    />
  );
};

export default UsersRestaurantsTable;
