import React from 'react';
import i18next from 'i18next';
import { Label, FormGroup } from 'reactstrap';

import { toDateInputValue } from 'utils/date-time';
import WithDateRangeDataFetched from '../../containers/WithDateRangeDataFetched';
import ReservationStatistics from 'app/components/reservations/ReservationStatistics';
import OtoToggle from 'app/components/common/OtoToggle';
import Shapes from 'shapes/main';
import {
  ISavedReservation,
  ITable,
  NormalizedRestaurant,
  ReservationConfig,
} from 'types';
import ErrorBoundary from '../../containers/ErrorBoundary';

interface IProps {
  bookingConfig: ReservationConfig;
  restaurant: NormalizedRestaurant;
  tablesConfig: ITable[];
}

interface IState {
  countDeclinedReservations: boolean;
}

export default class ReservationStatsPage extends React.PureComponent<
  IProps,
  IState
> {
  state = {
    countDeclinedReservations: true,
  };

  static propTypes = {
    restaurant: Shapes.restaurantShape.isRequired,
  };

  getUrl = (startDate, endDate) => {
    const { restaurant } = this.props;
    const after = toDateInputValue(startDate);
    const before = toDateInputValue(endDate);
    return `/restaurants/${restaurant.id}/reservations?filter[created_after]=${after}&filter[created_before]=${before}&include=tables`;
  };

  toggleShowDeclinedReservations = () =>
    this.setState({
      countDeclinedReservations: !this.state.countDeclinedReservations,
    });

  render() {
    return (
      <WithDateRangeDataFetched<ISavedReservation[]> getUrl={this.getUrl}>
        {(fetchedData, { ranges }) => {
          const { endDate, startDate } = ranges[0];
          return (
            <>
              <h2 className="col-12 p-0 mb-2">
                {i18next.t('reservation-statistics.header_text')}
              </h2>
              <h4 className="col-12 p-0 mb-2">
                {toDateInputValue(startDate)} - {toDateInputValue(endDate)}
              </h4>
              <FormGroup check inline>
                <OtoToggle
                  checked={this.state.countDeclinedReservations}
                  className="m-2"
                  onChange={this.toggleShowDeclinedReservations}
                />
                <Label check>
                  {i18next.t('reservation-statistics.toggle_label')}
                </Label>
              </FormGroup>
              <ErrorBoundary componentName="ReservationStatistics">
                <ReservationStatistics
                  bookingConfig={this.props.bookingConfig}
                  includeDeclineReservations={
                    this.state.countDeclinedReservations
                  }
                  reservations={fetchedData}
                  tables={this.props.tablesConfig}
                />
              </ErrorBoundary>
            </>
          );
        }}
      </WithDateRangeDataFetched>
    );
  }
}
