import React from 'react';
import { Label, FormGroup, Input } from 'reactstrap';

import OtoChip from '../../common/OtoChip';
import InputWithOptions from '../../common/InputWithOptions';
import { cities } from 'config';
import { OTO_PRODUCTS, OTO_PRODUCTS_GROUPS } from 'enums';

export const getUniqueValues = (array: string[]) => Array.from(new Set(array));

interface IProductGroup {
  label: string;
  products: string[];
}

const productGroups: IProductGroup[] = [
  {
    label: 'Zamówienia',
    products: [
      OTO_PRODUCTS.orders,
      OTO_PRODUCTS.orders_list,
      OTO_PRODUCTS.lubje_orders,
      OTO_PRODUCTS.lesznoje_orders,
      OTO_PRODUCTS.ostrowje_orders,
    ],
  },
  {
    label: 'Dostawy',
    products: OTO_PRODUCTS_GROUPS.anyDeliveriesProduct,
  },
  {
    label: 'Rezerwacje',
    products: [
      OTO_PRODUCTS.reservations,
      OTO_PRODUCTS.reservations_list,
      OTO_PRODUCTS.iframe,
    ],
  },
  {
    label: 'Strona / iframe / appka',
    products: [
      OTO_PRODUCTS.website,
      OTO_PRODUCTS.iframe,
      OTO_PRODUCTS.mobile_app,
    ],
  },
];

export const productsInGroups = getUniqueValues(
  productGroups.map((group) => group.products).flat()
);

interface IProps {
  activeProducts: string[];
  amountToShow: number;
  handleCityFilterChange: (e: React.ChangeEvent<any>) => void;
  handleNameFilterChange: (e: React.ChangeEvent<any>) => void;
  productsToShow: string[];
  restaurantCityFilter: number;
  restaurantNameFilter: string;
  setAmountToShow: (e?: React.ChangeEvent<any>) => any;
  showCity: boolean;
  toggleProductSelected: (product: string) => void;
}

const AdminRestaurantFilters: React.FC<IProps> = (props) => {
  const {
    activeProducts,
    amountToShow,
    handleCityFilterChange,
    handleNameFilterChange,
    productsToShow,
    restaurantCityFilter,
    restaurantNameFilter,
    setAmountToShow,
    showCity,
    toggleProductSelected,
  } = props;

  const otherProducts = productsToShow.filter(
    (product) => !productsInGroups.includes(product)
  );

  const renderProductGroup = (productGroup: IProductGroup) => (
    <FormGroup>
      <Label className="d-block mt-2">{productGroup.label}</Label>
      <div>
        {productGroup.products.map((product) => (
          <OtoChip
            key={product}
            label={product}
            isSelected={activeProducts.includes(product)}
            onClick={() => toggleProductSelected(product)}
          />
        ))}
      </div>
    </FormGroup>
  );

  return (
    <>
      <>
        <FormGroup>
          <Label>Nazwa restauracji</Label>
          <Input
            className="mw-300"
            placeholder="Wyszukaj restaurację"
            type="text"
            value={restaurantNameFilter}
            onChange={handleNameFilterChange}
          />
        </FormGroup>
        {showCity && (
          <FormGroup>
            <Label>Miasto</Label>
            <Input
              className="mw-300"
              type="select"
              value={restaurantCityFilter}
              onChange={handleCityFilterChange}
            >
              <option value={0}>Wszystkie</option>
              {cities.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
      </>
      <FormGroup>
        <Label className="d-block mt-2">Dostępne produkty</Label>
        {productGroups.map(renderProductGroup)}
        {renderProductGroup({
          label: 'Pozostałe',
          products: otherProducts,
        })}
      </FormGroup>
      <div className="align-vertical mt-3 justify-content-start">
        <span>Pokaż</span>
        <InputWithOptions
          id="restaurant-amount"
          name="restaurant-amount"
          wrapperClassName="mw-125 mx-2 w-auto"
          value={amountToShow}
          onChange={setAmountToShow}
          options={[5, 10, 25, 50, 100]}
        />
        <span>restauracji</span>
      </div>
    </>
  );
};

export default AdminRestaurantFilters;
