import React from 'react';
import PropTypes from 'prop-types';

import Shapes from 'shapes/main';
import { IDelivery } from 'types/delivery';
import { Clock } from 'react-feather';
import Delivery from 'utils/deliveries';

export type DriverArrivesInProps = { delivery: IDelivery; isDriver: boolean };
const DriverArrivesIn: React.FC<DriverArrivesInProps> = ({
  delivery,
  isDriver,
}) => {
  const arriveIn = Delivery.getDriverTimeToArrive(delivery);
  if (!arriveIn) {
    return null;
  }

  const arrivingText = isDriver
    ? 'Restauracja oczekuje Ciebie za:'
    : 'Kierowca będzie za:';

  return (
    <div>
      <Clock className="mr-1" />
      {arrivingText} <b>{arriveIn}</b> min.
    </div>
  );
};

DriverArrivesIn.propTypes = {
  delivery: Shapes.deliveryShape.isRequired,
  isDriver: PropTypes.bool.isRequired,
};

export default DriverArrivesIn;
