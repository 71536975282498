import React from 'react';
import { ModalTypes } from 'config';

import ModalContext from './ModalContext';
import { IDelivery, IOrder, IPromoCode, OldFormatDeliveryHour } from 'types';

export type IPageModalProps =
  | TCommonModalPropsForGenericModals
  | {
      modalSpecificProps?:
        | TAcceptDeliveryModalProps
        | TDisableOrdersModalProps
        | TSendSmsModalProps
        | TOrderFeedbackModalProps
        | TAddCompanyContactModalProps;
    };

type TCommonModalPropsForGenericModals = {
  confirm: (params: any, customParamsForSelectOnly?: any) => void;
  cancel?: () => void;
  confirmColor: 'success' | 'info' | 'danger';
  confirmText?: string;
  cancelText?: string;

  title: React.ReactNode;
  text?: React.ReactNode;

  // TODO split these by types
  options?: string[] | { value: string | number; label: string }[];
  other?: string;
  otherPlaceholder?: string;
  initialValue?: string;
};

export type TCommonPropsForSpecificModals<T> = {
  closeModal: () => void;
  onSave: (message: T) => Promise<any>;
}

export type TAcceptDeliveryModalProps = {
  delivery: IDelivery;
  order?: IOrder;
};

export type TDisableOrdersModalProps = {
  onValuesSelected: (params: {
    reasonToDisable: string;
    disableUntil?: string;
  }) => Promise<any>;
  todayDeliveryHours: OldFormatDeliveryHour;
};

export type TSendSmsModalProps = {
  customers: Set<any>;
  smsText?: string;
};

type TOrderFeedbackModalProps = {
  promoCode: IPromoCode;
};

export type TAddCompanyContactModalProps = {
  companyId: number;
};

export type TSetModalFunction = (
  modalProps: IPageModalProps,
  type: ModalTypes
) => void;

export interface WithSetModalProps {
  setModal: TSetModalFunction;
}

export default function withSetModal<P extends WithSetModalProps>(
  WrappedComponent: React.ComponentType<P>,
  staticComponentProps?: Record<string, any>
) {
  class withSetModal extends React.Component<Omit<P, 'setModal'>, {}> {
    static displayName = `WithSetModal(${getDisplayName(WrappedComponent)})`;
    static navName?: string;
    static url?: string;

    render() {
      return (
        <ModalContext.Consumer>
          {(modalBag) => (
            <WrappedComponent
              // @ts-ignore setModal is not actually being passed so no double passing here, just types
              setModal={modalBag.setModal}
              {...(this.props as P)}
            />
          )}
        </ModalContext.Consumer>
      );
    }
  }
  !!staticComponentProps &&
    Object.entries(staticComponentProps).forEach(([key, value]) => {
      withSetModal[key] = value;
    });
  return withSetModal;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
