import React from 'react';
import SimpleTableWithSorting, {
  generateColumn,
} from '../common/SimpleTableWithSorting';
import type { TRestaurantFeedback } from 'types';

const columns = [
  generateColumn('rating'),
  generateColumn('worked_well'),
  generateColumn('went_wrong'),
  generateColumn('restaurant_id'),
  generateColumn('created_at'),
];


type RestaurantsFeedbacksProps = {
  data: TRestaurantFeedback[];
};

const RestaurantsFeedbacks: React.FC<RestaurantsFeedbacksProps> = (props) => {
  const data = React.useMemo(() => props.data, [props.data]);
  return (
    <>
      <h4>TODO add date based filtering here (e.g.) ?from=xyz</h4>
      <SimpleTableWithSorting columns={columns} data={data} />
    </>
  );
};

export default RestaurantsFeedbacks;
