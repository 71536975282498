import React, { useCallback } from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import 'array-flat-polyfill';
import fromEntries from 'object.fromentries';

import OtoStolikRestaurantApp from 'app/containers/OtoStolikRestaurantApp';
import TranslationsProvider from 'app/containers/TranslationsProvider';
import CaptureClickToPlaySound from 'app/containers/CaptureClickToPlaySound';
import CenterCardSpinner from 'app/components/common/CenterCardSpinner';
import ContextProviders from 'app/theme-components/utilities/ContextProviders';
import LoginPage from 'app/pages/LoginPage';
import SignUpPage from 'app/pages/SignUpPage';
import { isElectron } from 'utils/general';

import 'react-toastify/dist/ReactToastify.css';
import 'scss/styles.scss';

if (!Object.fromEntries) {
  fromEntries.shim();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js', { type: 'module' })
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

const App: React.FC<{}> = () => {
  const [languageLoaded, setLanguageLoaded] = React.useState(false);

  const setTranslationsLoaded = useCallback(() => {
    setLanguageLoaded(true);
  }, []);

  if (!languageLoaded) {
    return (
      <>
        <CenterCardSpinner />
        <TranslationsProvider onLoaded={setTranslationsLoaded} />
      </>
    );
  }
  const SelectedRouter: any = isElectron() ? HashRouter : BrowserRouter;
  return (
    <ContextProviders>
      <SelectedRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="*" element={<OtoStolikRestaurantApp />} />
        </Routes>
      </SelectedRouter>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={3000} />
      <CaptureClickToPlaySound />
    </ContextProviders>
  );
};

export default App;
