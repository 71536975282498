import React, { useMemo, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import i18next from 'i18next';

import TableEditModal from './TableEditModal';
import { ITable, ITableWithPlace } from 'types/restaurant-table';
import CollapsibleCard from '../common/CollapsibleCard';
import TablesList from './TablesList';
import { IconWithTooltip } from '../common';

interface TablesSettingsProps {
  onTableUpdate: (payload: ITable) => Promise<ITable>;
  tables: ITableWithPlace[];
  tablesByIds: Record<number, string>;
}

export const TablesSettings: React.FC<TablesSettingsProps> = (props) => {
  const { onTableUpdate, tables, tablesByIds } = props;

  const [selectedTable, setSelectedTable] = useState<ITable | null>(null);
  const [loadingTables, setLoadingTables] = useState<number[]>([]);

  const handleTableSave = (updatedTable: ITable) => {
    setLoadingTables([...loadingTables, updatedTable.id]);
    return onTableUpdate(updatedTable)
      .then(() => setSelectedTable(null))
      .finally(() =>
        setLoadingTables(
          loadingTables.filter((tableId) => tableId !== updatedTable.id)
        )
      );
  };
  const toggleSelectedTableBookable = (table: ITable) => {
    return handleTableSave({
      ...table,
      is_bookable: !!table.is_bookable ? false : true,
    });
  };

  const tablesGroupedByPlaces = useMemo<
    Record<string, ITableWithPlace[]>
  >(() => {
    const groupedTables: Record<string, ITableWithPlace[]> = tables.reduce(
      (acc, table) => {
        if (acc[table.place.name]) {
          acc[table.place.name] = [...acc[table.place.name], table];
        } else {
          acc[table.place.name] = [table];
        }
        return acc;
      },
      {}
    );

    Object.entries(groupedTables).forEach(([placeName, placeTables]) => {
      groupedTables[placeName] = placeTables.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );
    });

    return groupedTables;
  }, [tables]);

  return (
    <>
      <hr />
      <Form className="table-settings__form">
        <FormGroup className="table-settings__row table-settings__row--heading">
          <div className="table-name-cell table-settings__row-cell">
            {i18next.t('table-settings.column-header.name')}
          </div>
          <div className="table-size-cell table-settings__row-cell">
            {i18next.t('table-settings.column-header.size')}
          </div>
          <div className="table-settings__row-cell">
            {i18next.t('table-settings.column-header.place')}
          </div>
          <div className="table-settings__row-cell">
            <span>{i18next.t('table-settings.add-table.priority')}</span>
            <IconWithTooltip
              id="add-table-priority-tooltip"
              className="ml-2"
              text={i18next.t('table-settings.add-table.priority-tooltip')}
            />
          </div>
          <div className="table-settings__row-cell">
            {i18next.t('table-settings.column-header.available-connections')}
          </div>
          <div className="table-settings__row-cell">
            {i18next.t('table-settings.column-header.is-bookable-online')}
          </div>
        </FormGroup>
        {Object.entries(tablesGroupedByPlaces).map(([placeName, tables]) => {
          const onlineBookableTables = tables.filter(
            (table) => table.is_bookable
          );

          const bookableCount = onlineBookableTables.length;
          const nonBookableCount = tables.length - onlineBookableTables.length;
          return (
            <CollapsibleCard
              key={placeName}
              buttonClassName="align-vertical p-2"
              title={
                <div className="text-left ml-2">
                  <h5 className="mb-0">{placeName}</h5>
                  <div className="small">
                    {i18next.t('table-settings.place-summary', {
                      count: tables.length,
                      bookableCount,
                      nonBookableCount,
                    })}
                  </div>
                </div>
              }
            >
              <TablesList
                isLoading={(table) => loadingTables.includes(table.id)}
                onTableEditClick={(table) => setSelectedTable({ ...table })}
                onTableIsBookableToggle={toggleSelectedTableBookable}
                tables={tables}
                tablesByIds={tablesByIds}
              />
            </CollapsibleCard>
          );
        })}
      </Form>
      <TableEditModal
        onSave={handleTableSave}
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        tables={tables}
        loading={!!selectedTable && loadingTables.includes(selectedTable.id)}
      />
    </>
  );
};

export default TablesSettings;
