import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import i18next from 'i18next';
import { Multiselect } from 'multiselect-react-dropdown';

import CommonSection from '../common/CommonSection';
import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import { activePaymentOptionKeys, paymentOptions } from 'utils/payments';
import { EPaymentType } from 'enums';
import { FormikProps } from 'formik';
import type { OrdersConfigFormValues } from 'app/pages/OrderSettingsPage';

export const activePaymentOptions = paymentOptions.filter((item) =>
  activePaymentOptionKeys.includes(item.name)
);

type PaymentOptionssProps = {
  availableOptions: {
    label: string;
    name: EPaymentType;
    isOnline?: boolean;
  }[];
  field: string;
  label: string;
  setFieldValue: FormikProps<OrdersConfigFormValues>['setFieldValue'];
  values: OrdersConfigFormValues;
};

const PaymentOptionsSet: React.FC<PaymentOptionssProps> = (props) => {
  const { availableOptions, field, label, setFieldValue, values } = props;
  const updatePaymentOptions = (newSelected) => {
    setFieldValue(
      field,
      newSelected.map((option) => option.name)
    );
  };
  return (
    <>
      <Label>{label}</Label>
      <Multiselect
        options={availableOptions}
        selectedValues={availableOptions.filter((opt) =>
          values[field].includes(opt.name)
        )}
        onSelect={updatePaymentOptions}
        onRemove={updatePaymentOptions}
        displayValue="label"
        placeholder={i18next.t(
          'payment-settings-form.select-available-payment-types'
        )}
      />
    </>
  );
};

type PaymentOptionsFormProps = {
  loading: boolean;
  setFieldValue: FormikProps<OrdersConfigFormValues>['setFieldValue'];
  values: OrdersConfigFormValues;
};

const PaymentOptionsForm: React.FC<PaymentOptionsFormProps> = (props) => {
  const { setFieldValue, values } = props;
  const activePaymentOptionsWithLabel = activePaymentOptions.map((item) => ({
    ...item,
    label: i18next.t(`payment-types.${item.name}`),
  }));
  return (
    <CommonSection header={i18next.t('payment-settings-form.header')}>
      <div className="mb-3">
        <PaymentOptionsSet
          availableOptions={activePaymentOptionsWithLabel}
          field="allowedTakeoutPaymentOptionKeys"
          label={i18next.t(
            'payment-settings-form.allowed-takeout-payment-types'
          )}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
      <div className="mb-3">
        <PaymentOptionsSet
          availableOptions={activePaymentOptionsWithLabel}
          field="allowedDeliveryPaymentOptionKeys"
          label={i18next.t(
            'payment-settings-form.allowed-delivery-payment-types'
          )}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
      <SubmitButtonWithLoader className="mt-3" loading={props.loading} />
    </CommonSection>
  );
};

PaymentOptionsSet.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

PaymentOptionsForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default PaymentOptionsForm;
