export const setFocus = (selector) =>
  setTimeout(() => {
    const elem = document.querySelector(selector);
    elem && elem.focus();
  }, 0);

  export function isUntilSm() {
    return window.innerWidth <= 768;
  }
  
  export function isUntilMd() {
    return window.innerWidth <= 992;
  }
  
  const OTO_TABLET_MAX_WIDTH = 1280;
  
  export function isDesktop() {
    return window.innerWidth > OTO_TABLET_MAX_WIDTH;
  }
  
  export const RwdService = {
    getIsUntilSm: isUntilSm,
    getIsUntilMd: isUntilMd,
    getIsDesktop: isDesktop,
  };
  