import React from 'react';

import type { ITableWithPlace } from 'types';

interface IProps {
  showPlaceName?: boolean;
  tables: ITableWithPlace[];
}

const TablesFormatted: React.FC<IProps> = ({
  showPlaceName = true,
  tables,
}) => {
  const uniqueTablePlaceNames: string[] = Array.from(
    new Set(tables.map((table) => table.place.name)).values()
  );

  return (
    <>
      {tables.map((table) => table.name).join(', ')}
      {showPlaceName && ` (${uniqueTablePlaceNames.join(', ')})`}
    </>
  );
};

export default TablesFormatted;
