import React from 'react';
import * as Feather from 'react-feather';
import NavBadge from './NavBadge';
import type { LinkComponentType } from 'app/theme-components/createLinkComposed';
import createLinkComposed from 'app/theme-components/createLinkComposed';

type NavSingleItemProps = {
  item: {
    icon?: string;
    external?: boolean;
    target?: string;
    url: string;
    name: string;
    badge?: {
      variant: string;
      text: string;
    };
  };
  LinkComponent: LinkComponentType;
}

const NavSingleItem: React.FC<NavSingleItemProps> = ({
  item,
  LinkComponent,
}) => {
  const Icon = item.icon && Feather[item.icon] ? Feather[item.icon] : null;
  if (item.external) {
    const rel =
      item.target && item.target === '_blank'
        ? 'noopener noreferrer'
        : undefined;
    return (
      <li className="nav-item">
        <a href={item.url} target={item.target} rel={rel}>
          {item.icon && Icon && <Icon className="side-nav-icon" />}
          <span className="nav-item-label">{item.name}</span>
          {item.badge && (
            <NavBadge color={item.badge.variant} text={item.badge.text} />
          )}
        </a>
      </li>
    );
  }

  const LinkComposed = createLinkComposed(LinkComponent);
  return (
    <li className="nav-item">
      <LinkComposed
        to={item.url}
        // @ts-expect-error type issue, NavLink actually supports callback for className
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        {item.icon && Icon && <Icon className="side-nav-icon" />}
        <span className="nav-item-label">{item.name}</span>
        {item.badge && (
          <NavBadge color={item.badge.variant} text={item.badge.text} />
        )}
      </LinkComposed>
    </li>
  );
};
export default NavSingleItem;
