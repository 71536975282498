import PropTypes, { Requireable } from 'prop-types';

import orderCustomerShape from './order-customer';
import { EDeliveryType, EPaymentStatus, EPaymentType, Tenants } from 'enums';
import { IOrder, IPayment } from 'types';

const sourceRelShape = PropTypes.shape({
  app_name: PropTypes.oneOf([
    'bombardino_mobile_app',
    'czarna_owca_mobile_app',
    'lubje_mobile_app',
    'otostolik_mobile_app',
    'web_app',
    'web_iframe',
  ]).isRequired,
  app_version: PropTypes.string,
  id: PropTypes.number.isRequired,
  order_id: PropTypes.number.isRequired,
  platform: PropTypes.oneOf(['mobile_app', 'web_desktop', 'web_mobile'])
    .isRequired,
  tenant: PropTypes.oneOf(Object.values(Tenants)).isRequired, // TODO Tenants enum
});

const priceOptionShape = PropTypes.exact({
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  oldPrice: PropTypes.number,
});

const commonProductFields = {
  id: PropTypes.number, // TODO make required later
  additionalFields: PropTypes.array,
  desc: PropTypes.string,
  image: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  notes: PropTypes.string,
  oldPrice: PropTypes.number,
  // option: PropTypes.string.isRequired,
  labels: PropTypes.array,
  price: PropTypes.number.isRequired,
  priceOptions: PropTypes.arrayOf(priceOptionShape),
  selectedAdditionalFields: PropTypes.array, // TODO shape
};

const orderedProductShape = PropTypes.exact({
  ...commonProductFields,
  amount: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  displayPrice: PropTypes.number, // deprecated
  hasDiscount: PropTypes.bool,
  option: PropTypes.string,
  voucher_id: PropTypes.number,
});

export const paymentShape: Requireable<IPayment> = PropTypes.shape({
  id: PropTypes.number.isRequired,
  driver: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(EPaymentType)).isRequired,
  status: PropTypes.oneOf(Object.values(EPaymentStatus)).isRequired,
  external_id: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  redirect: PropTypes.string.isRequired,
  paid_at: PropTypes.string,
});

export const orderShape: Requireable<IOrder> = PropTypes.shape({
  confirmation_code: PropTypes.string.isRequired,
  confirmed_at: PropTypes.string,
  commission: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  customer: orderCustomerShape.isRequired,
  customer_id: PropTypes.number.isRequired,
  delivery_cost: PropTypes.number.isRequired,
  delivery_type: PropTypes.oneOf(Object.values(EDeliveryType)).isRequired,
  id: PropTypes.number.isRequired,
  payment: paymentShape,
  payment_id: PropTypes.number.isRequired,
  payment_status: PropTypes.string.isRequired,
  payment_type: PropTypes.oneOf(Object.values(EPaymentType)).isRequired,
  prepare_time: PropTypes.number,
  food_prepare_time: PropTypes.number,
  price: PropTypes.number.isRequired,
  products: PropTypes.arrayOf(orderedProductShape).isRequired,
  promo_code: PropTypes.object, // todo shape
  promo_code_id: PropTypes.number,
  restaurant_id: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
  source_rel: sourceRelShape,
  status: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
});

export default orderShape;
