import React from 'react';
import { Alert } from 'reactstrap';

const ReservationConfigNotFinishedAlert: React.FC<{}> = () => {
  return (
    <Alert color="info">
      <div>Ustawienia rezerwacji nie są skonfigirowane.</div>
      <div>
        Skonfiguruj ustawienia rezerwacji{' '}
        <a href="/settings/reservations">tutaj</a>, lub skontaktuj się z nami{' '}
        <a href="/help">(dane kontaktowe)</a>
      </div>
    </Alert>
  );
};

export default ReservationConfigNotFinishedAlert;
