import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { logActivity, logComponentDidCatch } from 'utils/log';
import Order from 'utils/orders';
import { ModalTypes, ORDER_STATUS } from 'config';
import { BOMBARDINOS, hasPrinter } from 'utils/restaurant';
import Shapes from 'shapes/main';

import { checkIfRestaurantPrepareTimeCorrect } from 'utils/validators';
import AcceptOrderModalBody, {
  getAcceptViewOrderModalTitle,
} from '../components/orders/order-parts/accept-order-modal/AcceptOrderModal';
import type { IOrder, NormalizedRestaurant } from 'types';
import PrintService from 'services/print';
import type { TSetModalFunction } from '../containers/WithSetModal';
import OrderModal from '../components/orders/OrderModal';

export type ViewOrderModalProps = {
  className?: string | null;
  order: IOrder;
  changeOrderRestaurant?: (order: IOrder, selectedRestaurantId: number) => void;
  onClose: (order: IOrder) => void;
  // @FIXME this should not be optional, hotfix for OrderFeedbackPage
  onUpdateOrderStatus?: (
    order: IOrder,
    status: ORDER_STATUS,
    extra: any
  ) => void;
  renderOrderSource: (order: IOrder) => React.ReactNode;
  restaurant: NormalizedRestaurant;
  setModal: TSetModalFunction;
  updatingOrders: number[];
};

export class ViewOrderModal extends React.PureComponent<
  ViewOrderModalProps,
  {}
> {
  static propTypes = {
    className: PropTypes.string,
    order: Shapes.orderShape.isRequired,
    onClose: PropTypes.func.isRequired,
    changeOrderRestaurant: PropTypes.func,
    restaurant: Shapes.restaurantShape.isRequired,
    setModal: PropTypes.func.isRequired,
    onUpdateOrderStatus: PropTypes.func.isRequired,
  };

  componentDidCatch(error, info) {
    logComponentDidCatch('ViewOrderModal', error, info);
  }

  isLoading = () => this.props.updatingOrders.includes(this.props.order.id);

  onUpdateOrderStatus = (status, extra?) => {
    // @FIXME this should not be optional, hotfix for OrderFeedbackPage
    this.props.onUpdateOrderStatus?.(this.props.order, status, extra);
  };

  acceptOrder = () => {
    const { order } = this.props;
    const hasCustomTime = Order.hasCustomRealizationTime(order);

    const modalType = hasCustomTime ? ModalTypes.CONFIRM : ModalTypes.SELECT;

    const prepareTimeOptions =
      Order.isSelfCollect(order) || Order.hasRobotDelivery(order)
        ? Order.TAKEOUT_PREPARE_TIMES
        : Order.DELIVERY_PREPARE_TIMES;

    this.props.setModal(
      {
        title: getAcceptViewOrderModalTitle(order, hasCustomTime),
        text: (
          <AcceptOrderModalBody hasCustomTime={hasCustomTime} order={order} />
        ),
        options: prepareTimeOptions.map((mins) => `${mins} min.`),
        other: i18next.t('order-modal.accept-order.option-other.label'),
        otherPlaceholder: i18next.t('order-modal.accept-order.option-other.placeholder'),
        confirm: (minsAmount: string, customMinsAmount?: string) => {
          const minsValue = parseInt(customMinsAmount || minsAmount, 10);
          // TODO order extra here
          const confirmOrderExtra = Order.hasRobotDelivery(order)
            ? { food_prepare_time: minsValue }
            : { prepare_time: minsValue };

          this.onUpdateOrderStatus(ORDER_STATUS.CONFIRMED, confirmOrderExtra);
          checkIfRestaurantPrepareTimeCorrect(
            this.props.restaurant,
            order,
            minsValue
          );
          if (hasPrinter(this.props.restaurant)) {
            setTimeout(this.printOrder, 2000);
          }
        },
        confirmColor: 'success',
        confirmText: i18next.t('order.mark-as-confirmed'),
        cancelText: i18next.t('Back'),
      },
      modalType
    );
  };

  declineOrder = () => {
    const ORDER_DECLINE_OPTIONS = [
      {
        label: i18next.t('order.decline-modal.reasons.dish-not-available'),
        value: 'dish_not_available',
      },
      {
        label: i18next.t('order.decline-modal.reasons.too-far-distance'),
        value: 'too_far_distance',
      },
      {
        label: i18next.t('order.decline-modal.reasons.restaurant-closed'),
        value: 'restaurant_closed',
      },
      { 
        label: i18next.t('order.decline-modal.reasons.too-many-orders'),
        value: 'too_many_orders'
      },
    ];
    this.props.setModal(
      {
        title: i18next.t('order.decline-modal.title'),
        options: ORDER_DECLINE_OPTIONS,
        other: i18next.t('order.decline-modal.option-other.label'),
        otherPlaceholder: i18next.t('order.decline-modal.option-other.placeholder'),
        confirm: (selectedReason: string, customReason?: string) => {
          const declineOrderExtra = {
            reason: customReason || selectedReason,
          };
          this.onUpdateOrderStatus(ORDER_STATUS.DECLINED, declineOrderExtra);
        },
        confirmColor: 'danger',
        confirmText: i18next.t('order.mark-as-declined'),
        cancelText: i18next.t('Close'),
      },
      ModalTypes.SELECT
    );
  };

  changeRestaurant = () => {
    const { order, changeOrderRestaurant, restaurant } = this.props;
    if (!changeOrderRestaurant) {
      return;
    }
    this.props.setModal(
      {
        title: 'Wybierz restaurację, która ma zrealizować zamówienie',
        text: 'Użyj tej opcji, kiedy inny lokal z sieci znajduje się bliżej zamawiającego i może zrealizować wybrane zamówienie.',
        options: BOMBARDINOS.map((item) => ({
          value: item.id,
          label: item.name,
        })),
        confirm: (selectedRestaurantIdStr: string) => {
          const selectedRestaurantId = parseInt(selectedRestaurantIdStr, 10);
          logActivity(`Restauracja oddaje zamówienie #${order.id} innej.
          Obecna: ${restaurant.name}, id: ${restaurant.id}
          Nowa: ${
            BOMBARDINOS.find((item) => item.id === selectedRestaurantId)?.name
          }, id: ${selectedRestaurantId}`);
          changeOrderRestaurant(this.props.order, selectedRestaurantId);
        },
        confirmColor: 'success',
        confirmText: 'Zmień restaurację',
        cancelText: 'Zamknij',
      },
      ModalTypes.SELECT
    );
  };

  sendToDelivery = () => this.onUpdateOrderStatus(ORDER_STATUS.SENT);
  markAsDelivered = () => this.onUpdateOrderStatus(ORDER_STATUS.DELIVERED);

  printOrder = () => {
    const { order, restaurant } = this.props;
    PrintService.printOrder({ restaurant, order });
  };

  render() {
    const {
      changeOrderRestaurant,
      order,
      onUpdateOrderStatus,
      updatingOrders,
      setModal,
      ...restProps
    } = this.props;
    return (
      <OrderModal
        onAcceptOrderClick={this.acceptOrder}
        onDeclineOrderClick={this.declineOrder}
        onChangeRestaurantClick={this.changeRestaurant}
        onPrintOrderClick={this.printOrder}
        order={order}
        isLoading={updatingOrders.includes(order.id)}
        onUpdateOrderStatus={this.onUpdateOrderStatus}
        {...restProps}
      />
    );
  }
}

export default ViewOrderModal;
