import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { openOrderModal, selectAllOrders, selectOrdersLoadedAtLeastOnce } from 'store/orders';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

const OpenOrderByIdInParams: React.FC = () => {
    const allOrders = useAppSelector(selectAllOrders)
    const wereOrdersLoaded = useAppSelector(selectOrdersLoadedAtLeastOnce)
    const dispatch = useAppDispatch();

    const { orderId } = useParams<{
        orderId?: string
    }>()

    const [shouldOpenModalByUrlId, setShouldOpenModalByUrlId] = useState(!!orderId);

    useEffect(() => {
        if(!shouldOpenModalByUrlId) {
            return;
        }
        if (!orderId) {
            return;
        }

        if (!wereOrdersLoaded) {
            return;
        }
        const matchingOrder = allOrders.find(
            (o) =>
              o.id === parseInt(orderId, 10)
          );
          matchingOrder && dispatch(openOrderModal(matchingOrder))
        setShouldOpenModalByUrlId(false);
    }, [allOrders, shouldOpenModalByUrlId, dispatch, orderId, wereOrdersLoaded])
    return (
        <div>
            
        </div>
    );
};

export default OpenOrderByIdInParams;