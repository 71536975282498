import React, { useState } from 'react';
import { subDays } from 'date-fns';

import AdminPayouts, {
  GroupedPayouts,
} from 'app/components/admin/payouts/AdminPayouts';
import OtoFormGroup from 'app/components/common/OtoFormGroup';
import UrlFetcher from '../../containers/UrlFetcher';
import { PageComponent } from '../../types-global';
import { useAppSelector } from 'hooks/useRedux';
import { selectAdminTenant } from 'store/admin';
import { AdminTenantSelector } from 'app/components/admin-statistics';
import { toDateInputValue } from 'utils/date-time';

const initialMinDate = subDays(new Date(), 30);

const AdminPayoutsPage: PageComponent<{}> = () => {
  const [minCreatedAt, setMinCreatedAt] = useState<Date | null>(initialMinDate);
  const activeTenant = useAppSelector(selectAdminTenant);

  const url = minCreatedAt
    ? `/payouts?min_created_at=${toDateInputValue(minCreatedAt)}`
    : '/payouts';

  return (
    <>
      <div className="container align-vertical mb-3">
        <AdminTenantSelector />
        <OtoFormGroup
          className="mw-300 ml-2"
          label="Jak dalekie okresy wliczać (ważne dla potrącenia zaległości)"
          type="date"
          name="min_created_at"
          onChange={(e) =>
            setMinCreatedAt(e.target.value ? new Date(e.target.value) : null)
          }
          value={minCreatedAt ? toDateInputValue(minCreatedAt) : ''}
        />
      </div>
      <UrlFetcher<GroupedPayouts> url={url}>
        {([groupedPayouts, urlFetcherProps]) => (
          <AdminPayouts
            activeTenant={activeTenant}
            groupedPayouts={groupedPayouts}
          />
        )}
      </UrlFetcher>
    </>
  );
};
AdminPayoutsPage.navName = 'admin.payouts';
AdminPayoutsPage.url = '/admin/payouts';

export default AdminPayoutsPage;
