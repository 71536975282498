import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row, Card, CardBody } from 'reactstrap';

import { checkToken, checkAppVersion } from 'services/auth';
import type {
  NormalizedRestaurant,
} from 'types';
import AddReservationForm from './AddReservationFormikWrapper';
import ErrorBoundary from 'app/containers/ErrorBoundary';
import './AddReservationPage.scss';
import { useAppSelector } from 'hooks/useRedux';
import { selectBookingConfig, selectRestaurant, selectRestaurantTables } from 'store/restaurant';

type RouteParams = {
  date?: string;
  tableId?: string;
};

const AddReservationPage: React.FC<{}> = () => {

  useEffect(() => {
    checkToken();
    checkAppVersion();
  }, [])

  
  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant
  const bookingConfig = useAppSelector(selectBookingConfig)
  const tablesConfig = useAppSelector(selectRestaurantTables)

  const params = useParams<RouteParams>();
  const navigate = useNavigate();

    return (
      <ErrorBoundary componentName='AddReservationPage'>
      <Row>
        <Col lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <AddReservationForm
                allTables={tablesConfig}
                config={bookingConfig}
                hasCalendar={false}
                restaurant={restaurant}
                onReservationCreate={() => navigate('/calendar')}
                selectedDate={params.date}
                selectedTableId={params.tableId}
                type="full"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      </ErrorBoundary>
    );
}

export default AddReservationPage;
