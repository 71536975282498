import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Tenants } from 'store/tenants';
import {
  IPayout,
  PayoutWithRestaurantAndCompany,
} from 'types';
import CollapsibleCard from '../../common/CollapsibleCard';
import { currency } from 'globals/currency';
import SimpleTableWithSorting, {
  generateColumn,
  getRTOriginal,
  ReactTableCellProps,
} from '../../common/SimpleTableWithSorting';
import { formatDate, toDateInputValue } from 'utils/date-time';
import MarkPayoutAsPaid from './MarkPayoutAsPaid';
import ClickToCopy from '../../common/ClickToCopy';
import { toast } from 'react-toastify';
import { fixDecimal } from 'utils/general';
import { OtoToggle } from '../../common';

export type GroupedPayouts = Record<Tenants, TenantPayouts>;

type TenantPayouts = Record<number | string, RestaurantPayouts>;

type RestaurantPayouts = {
  payouts: PayoutWithRestaurantAndCompany[];
  sum_to_payout: number;
};

const columns = [
  generateColumn('id', {
    customHeader: '# wypłaty',
    customCellFormatter: (
      props: ReactTableCellProps<PayoutWithRestaurantAndCompany>
    ) => <MarkPayoutAsPaid payout={getRTOriginal(props)} />,
  }),
  generateColumn('restaurant.company.name', {
    customHeader: 'Firma',
  }),
  generateColumn('restaurant.name', {
    customHeader: 'Restauracja',
  }),
  generateColumn('period_from', {
    customHeader: 'Okres od',
    customCellFormatter: ({ value }) => toDateInputValue(new Date(value)),
  }),
  generateColumn('period_to', {
    customHeader: 'Okres do',
    customCellFormatter: ({ value }) => toDateInputValue(new Date(value)),
  }),
  generateColumn('sum_to_payout', { customHeader: 'Kwota do wypłaty' }),
  generateColumn('paid_at', { customHeader: 'Data wypłacenia' }),
];

const getFlattenedTenantPayouts = (
  tenantPayouts: RestaurantPayouts[]
): IPayout[] => {
  return tenantPayouts.map((restaurantData) => restaurantData.payouts).flat();
};

interface IProps {
  activeTenant: string;
  groupedPayouts: GroupedPayouts;
}

const AdminPayouts: React.FC<IProps> = (props) => {
  const { activeTenant, groupedPayouts } = props;

  const [showZeroPayouts, setShowZeroPayouts] = React.useState(false);
  const [showPaidPayouts, setShowPaidPayouts] = React.useState(false);

  return (
    <Container className="d-flex flex-column">
      <Row className="mx-0">
        <OtoToggle
          id="show-zero-payouts"
          label="Pokaż zerowe wypłaty"
          checked={showZeroPayouts}
          onChange={() => setShowZeroPayouts(!showZeroPayouts)}
        />
        <OtoToggle
          id="show-paid-payouts"
          label="Pokażuj już opłacone/potrącone"
          checked={showPaidPayouts}
          onChange={() => setShowPaidPayouts(!showPaidPayouts)}
        />
      </Row>
      {Object.entries(groupedPayouts as GroupedPayouts).map(
        ([tenant, tenantData]) => {
          let tenantPayouts: RestaurantPayouts[] = Object.values(
            tenantData as TenantPayouts
          );

          if (!showZeroPayouts) {
            tenantPayouts = tenantPayouts
              .filter((item) => item.sum_to_payout !== 0)
              .map((item) => ({
                ...item,
                payouts: item.payouts.filter(
                  (payout) => payout.sum_to_payout !== 0
                ),
              }));
          }

          if (!showPaidPayouts) {
            tenantPayouts = tenantPayouts.filter(
              (item) => !item.payouts.some((payout) => payout.paid_at)
            );
          }

          tenantPayouts = tenantPayouts.sort(
            (a, b) => b.sum_to_payout - a.sum_to_payout
          );

          const flattenedTenantPayouts =
            getFlattenedTenantPayouts(tenantPayouts);

          if (!activeTenant || tenant !== activeTenant) {
            return null;
          }

          const possiblePayouts = tenantPayouts.filter(
            (payout) => payout.sum_to_payout > 0
          );

          const sumToPayoutAll = tenantPayouts.reduce(
            (acc, item) => acc + item.sum_to_payout,
            0
          );
          const sumToPayoutPossible = possiblePayouts.reduce(
            (acc, item) => acc + item.sum_to_payout,
            0
          );

          return (
            <>
              <div className="h2">Wypłaty dla {tenant}</div>
              <h4 className="font-weight-bold">
                Możliwe do opłacenia (dodatnie): {possiblePayouts.length} sztuk
                ({fixDecimal(sumToPayoutPossible)} {currency})
              </h4>
              <h5>
                Wszystkie: {tenantPayouts.length} sztuk (
                {fixDecimal(sumToPayoutAll)} {currency})
              </h5>

              {tenantPayouts?.length > 0 ? (
                <Row className="font-weight-bold bb-1 mb-2">
                  <Col>Firma / restauracja</Col>
                  <Col className="mw-150">Do wypłaty</Col>
                  <Col>Tytuł przelewu</Col>
                </Row>
              ) : (
                <div className="bb-1 mb-2 font-weight-bold">
                  Brak wypłat dla {tenant}
                </div>
              )}
              {tenantPayouts.map((restaurantData, index) => {
                const restaurant = restaurantData.payouts[0].restaurant;
                const companyName = restaurant?.company?.name || 'Restauracja bez firmy';
                const restaurantName = restaurant?.name || 'Brak restauracji';
                const isLastRow = index === tenantPayouts.length - 1;
                const transactionTitle = formatTransactionTitle(restaurantData);

                return (
                  <>
                    <Row
                      className={`bb-1 pt-2 ${
                        restaurantData.sum_to_payout < 0 ? 'bg-dimmed' : ''
                      }`}
                    >
                      <Col>
                        <div>{companyName}</div>
                        <div>{restaurantName}</div>
                      </Col>
                      <Col className="mw-150">
                        <ClickToCopy
                          onSuccess={() =>
                            toast.info(
                              `Skopiowano kwotę "${restaurantData.sum_to_payout}" do schowka`
                            )
                          }
                          textToCopy={restaurantData.sum_to_payout.toString()}
                        >
                          {restaurantData.sum_to_payout}
                        </ClickToCopy>
                        <div>
                          ({restaurantData.payouts.length}{' '}
                          {restaurantData.payouts.length > 1
                            ? 'okresy'
                            : 'okres'}
                          )
                        </div>
                      </Col>
                      <Col>
                        <ClickToCopy
                          onSuccess={() =>
                            toast.info(
                              `Skopiowano tekst "${transactionTitle}" do schowka`
                            )
                          }
                          textToCopy={transactionTitle}
                        >
                          {transactionTitle}
                        </ClickToCopy>
                      </Col>
                    </Row>
                    {isLastRow && (
                      <Row className="bb-1">
                        <Col className="px-0 mt-3">
                          <CollapsibleCard
                            buttonClassName="p-1 mb-0"
                            title={
                              'Szczegóły (tabela wypłat bez grupowania po restaruacji)'
                            }
                          >
                            <SimpleTableWithSorting
                              columns={columns}
                              data={flattenedTenantPayouts}
                              hasFilters
                              pageSize={50}
                              showFooter={false}
                            />
                          </CollapsibleCard>
                        </Col>
                      </Row>
                    )}
                  </>
                );
              })}
            </>
          );
        }
      )}
    </Container>
  );
};

const getPeriodString = (
  payout: PayoutWithRestaurantAndCompany,
  options: { includeSum: boolean; includeSpace?: boolean }
) => {
  const { includeSum, includeSpace = true } = options;

  const from = new Date(payout.period_from);
  const to = new Date(payout.period_to);

  const fromLabel = formatDate(from, 'dd.MM');
  const toLabel = formatDate(to, 'dd.MM');

  const datePart = includeSpace
    ? `${fromLabel} - ${toLabel}`
    : `${fromLabel}-${toLabel}`;

  return `${datePart}${
    includeSum ? ` (${payout.sum_to_payout} ${currency})` : ''
  }`;
};

const formatPeriods = (payouts: PayoutWithRestaurantAndCompany[]): string => {
  if (payouts.length === 1) {
    return getPeriodString(payouts[0], { includeSum: false });
  }
  if (payouts.length === 2) {
    return payouts
      .map((payout) =>
        getPeriodString(payout, {
          includeSum: payouts.length > 1,
        })
      )
      .join(', ');
  }
  const grouped = payouts.reduce(
    (acc, payout, index) => {
      const sep = index === 0 ? '' : ', ';
      return {
        periodsPart: `${acc.periodsPart}${sep}${getPeriodString(payout, {
          includeSum: false,
          includeSpace: false,
        })}`,
        payoutSumPart: `${acc.payoutSumPart}${sep}${payout.sum_to_payout}`,
      };
    },
    {
      periodsPart: '',
      payoutSumPart: '',
    }
  );
  return `${grouped.periodsPart} (${grouped.payoutSumPart} PLN)`;
};

export const formatTransactionTitle = (
  restaurantData: RestaurantPayouts
): string => {
  const isSinglePeriod = restaurantData.payouts.length === 1;

  const periodsPlural = isSinglePeriod ? 'okres' : 'okresy';

  const prefix = `Zwrot kosztów zamówień za ${periodsPlural}`;
  const suffix = 'po potrąceniu prowizji';

  const periodsString = formatPeriods(restaurantData.payouts);

  return `${prefix} ${periodsString} ${suffix}`;
};

export default AdminPayouts;
