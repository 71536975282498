import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import Shapes from 'shapes/main';
import APIService from 'services/api';
import { LOG_BAG } from 'utils/log';
import { OriginalRestaurant } from 'types/restaurants';
import OtoSpinner from '../../common/OtoSpinner';

interface IProps {
  restaurant: OriginalRestaurant;
  updateRestaurant: Function;
}

const EditArea: React.FC<any> = (props: IProps) => {
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const { restaurant, updateRestaurant } = props;

  const showTextArea = () => {
    try {
      const areaConfig = restaurant.config.orders.delivery.area;
      setValue(JSON.stringify(areaConfig));
    } catch (e) {
      toast.error(
        'Nie udało się wstawić aktualnej strefy - jest ona pusta lub niepoprawna.'
      );
      LOG_BAG.logError('Admin EditArea area failed to parse', e as any);
    }
    setShowInput(true);
  };
  const handleChange = (e) => setValue(e.target.value);
  const save = () => {
    const newDelivery = {
      ...restaurant.config.orders.delivery,
      area: JSON.parse(value),
    };
    const payload = {
      orders: {
        delivery: newDelivery,
      },
    };
    setLoading(true);
    APIService.put(`/restaurants/${restaurant.id}/config`, payload)
      .then((response) => {
        toast.success(
          `Strefy restauracji ${restaurant.name} zostały zaktualizowane`
        );
        alert(
          'Strefy zaktualizowane, lecz pamiętaj o wpisaniu kosztów dostawy, min. koszyka i darmowej dostawy, logując się na konto restauracji.'
        );
        setShowInput(false);
        updateRestaurant({
          ...restaurant,
          config: response.config,
        });
      })
      .catch((e) => {
        toast.error(
          'Nie udało się zapisać nowej strefy - jest ona niepoprawna, lub wystąpił błąd serwera.'
        );
        LOG_BAG.logError('Admin EditArea area failed to PUT', e as any);
      })
      .finally(() => setLoading(false));
  };

  if (!showInput) {
    return (
      <Button onClick={showTextArea}>
        Edytuj strefy dla {restaurant.name} ({restaurant.id})
      </Button>
    );
  }
  return (
    <>
      {loading && <OtoSpinner />}
      <Input
        type="textarea"
        disabled={loading}
        rows="5"
        value={value}
        onChange={handleChange}
      />
      <Button
        color="primary"
        disabled={loading}
        onClick={save}
        className="mt-2"
      >
        Zapisz
      </Button>
    </>
  );
};

EditArea.propTypes = {
  restaurant: Shapes.restaurantShape.isRequired,
  updateRestaurant: PropTypes.func.isRequired,
};

export default EditArea;
