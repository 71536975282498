import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import memoizeOne from 'memoize-one';

import { someColors } from './helpers';
import { fixDecimal } from 'utils/general';

const generateChartData = memoizeOne(
  (allData, amountToShow, dataField, labelField) => {
    const data = allData.slice(0, amountToShow);
    return {
      labels: data.map((p) => p[labelField]),
      datasets: [
        {
          data: data.map((p) => fixDecimal(p[dataField])),
          backgroundColor: data.map(
            (p, i) => someColors[i % someColors.length]
          ),
        },
      ],
    };
  }
);

type PieChartWithAmountProps = {
  data: any[];
  dataField: string;
  labelField: string;
  showSelect?: boolean;
  showText?: boolean;
  textFormatter?: (item: any, dataField: string, labelField: string) => string;
  title?: string;
};

const defaultTextFormatter = (item, dataField, labelField) =>
  `${item[labelField]}: ${item[dataField]}`;

const PieChartWithAmount: React.FC<PieChartWithAmountProps> = (props) => {
  const {
    data,
    dataField,
    labelField,
    showSelect = true,
    showText = false,
    textFormatter = defaultTextFormatter,
    title = '',
  } = props;
  const [amountToShow, setAmountToShow] = useState(5);
  const handleSelectChange = (e) =>
    setAmountToShow(parseInt(e.target.value, 10));
  return (
    <>
      {title && <h4>{title}</h4>}
      {showSelect && (
        <select
          className="form-control mw-200 mb-2"
          onChange={handleSelectChange}
          value={amountToShow}
        >
          {[3, 5, 10, 20].map((amount) => (
            <option value={amount}>Pokaż {amount}</option>
          ))}
        </select>
      )}
      <Doughnut
        data={generateChartData(data, amountToShow, dataField, labelField)}
        height={120}
        // @FIXME to be checked - was like this before TS migration
        // @ts-expect-error
        legend={{ display: false }}
      />
      {showText &&
        data.map((item) => (
          <div key={item[labelField]} className="small mt-2 text-center">
            {textFormatter(item, dataField, labelField)}
          </div>
        ))}
    </>
  );
};

PieChartWithAmount.propTypes = {
  data: PropTypes.array.isRequired,
  dataField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  showSelect: PropTypes.bool,
  showText: PropTypes.bool,
  title: PropTypes.string,
};

export default PieChartWithAmount;
