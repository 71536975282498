import { format } from 'date-fns';

import type { ISavedReservation } from 'types';

export const downloadReservationsCSV = (
  reservations: ISavedReservation[],
  fileName: string,
): void => {
  const csvContent = generateReservationsCSV(reservations);
  downloadFile({
    content: csvContent,
    fileName,
    type: 'text/csv;charset=utf-8;',
  });
};

function generateReservationsCSV(reservations: ISavedReservation[]): string {
  const headers = [
    'Imię Nazwisko',
    'Nr telefonu',
    'Email',
    'Data i godzina rezerwacji',
    'Liczba Gości',
    'Zgoda marketingowa na SMS',
    'Zgoda marketingowa na email',
  ];

  const rows = reservations.map((reservation) => {
    return [
      reservation.customer_name,
      reservation.phone_number,
      reservation.email,
      new Date(reservation.reserved_on),
      reservation.guests_number,
      !!reservation.sms_marketing,
      !!reservation.email_marketing,
    ];
  });

  return convertToCSV({
    headers,
    rows,
    dateFormat: 'yyyy-MM-dd',
  });
}

export function convertToCSV({
  headers,
  rows,
  dateFormat,
}: {
  headers: string[];
  rows: unknown[][];
  dateFormat: string;
}): string {
  const processRow = (row: unknown[]): string => {
    return row.reduce((acc: string, cell, index) => {
      if (index > 0) {
        acc += ',';
      }
      if (cell instanceof Date) {
        acc += format(cell, dateFormat);
      } else if (typeof cell === 'object') {
        acc += JSON.stringify(cell);
      } else if (typeof cell === 'boolean') {
        acc += cell ? 'Tak' : 'Nie';
      } else {
        const innerValue =
          cell === null || cell === undefined
            ? ''
            : (cell as string | number).toString();
        let result: string = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = '"' + result + '"';
        }
        acc += result;
      }
      return acc;
    }, '');
  };

  const csvFile = [
    processRow(headers),
    ...rows.map((row) => processRow(row)),
  ].join('\n');

  return csvFile;
}

export function downloadFile({
  content,
  fileName,
  type,
}: {
  content: string;
  type: string;
  fileName: string;
}) {
  const blob = new Blob([content], { type });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
