import React from 'react';
import { Badge, BadgeProps } from 'reactstrap';

type NavBadgeProps = BadgeProps & {
  className?: string;
  text: string;
};

export const NavBadge: React.FC<NavBadgeProps> = ({ className = '', text, ...restProps }) => (
  <Badge className={className} {...restProps}>
    {text}
  </Badge>
);

export default NavBadge;
