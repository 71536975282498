import { deliveryOptions } from './deliveries';
import { ORDER_STATUS, EDeliveryType } from 'enums';
import { paymentOptions } from './payments';
import type { IOrder } from 'types';

export const isNew = (order: IOrder) => order.status === ORDER_STATUS.NEW;
export const isNotNew = (order: IOrder) => order.status !== ORDER_STATUS.NEW;
export const isPending = (order: IOrder) =>
  order.status === ORDER_STATUS.PENDING;
export const isNotConfirmedYet = (order: IOrder) =>
  isNew(order) || isPending(order);
export const isConfirmed = (order: IOrder) =>
  order.status === ORDER_STATUS.CONFIRMED;
export const isDeclined = (order: IOrder) =>
  order.status === ORDER_STATUS.DECLINED;
export const isSent = (order: IOrder) => order.status === ORDER_STATUS.SENT;
export const isDelivered = (order: IOrder) =>
  order.status === ORDER_STATUS.DELIVERED;

export const isContactless = (order: IOrder) =>
  order.delivery_type === EDeliveryType.CONTACTLESS;

export const isTakeout = (order: IOrder) =>
  order.delivery_type === EDeliveryType.TAKEOUT;
export const isSelfCollect = (order: IOrder) =>
  [EDeliveryType.TAKEOUT, EDeliveryType.DRIVE_THROUGH].includes(
    order.delivery_type
  );
const hasRobotDelivery = (order: IOrder) =>
  order.delivery_type === EDeliveryType.ROBOT;

export const hasOnlinePayment = (order: IOrder) => {
  const foundPayment = paymentOptions.find(
    (opt) => opt.name === order.payment_type
  );
  return foundPayment && foundPayment.isOnline;
};

export const getAddress = (order: IOrder) => {
  const { city, street, house, flat } = order.customer;
  const flatPart = flat ? `/${flat}` : '';
  return `${!!city ? `${city}, ` : ''}${street} ${house}${flatPart}`;
};

const getPrepareTime = (order: IOrder) => {
  return order.prepare_time || order.customer?.prepare_time;
};

export function getPaymentMethodLabel(paymentType, groupPaidOnline = true) {
  const foundPayment = paymentOptions.find((opt) => opt.name === paymentType);
  if (groupPaidOnline && foundPayment && foundPayment.isOnline) {
    return 'general_paid_online';
  }
  return paymentType;
}

export function getDeliveryMethodLabel(
  EDeliveryType: IOrder['delivery_type']
): string {
  return (
    deliveryOptions.find((opt) => opt.name === EDeliveryType)?.label ||
    EDeliveryType
  );
}

export function isOrdersConfigFinished(ordersConfig, restaurant) {
  if (
    !ordersConfig ||
    !ordersConfig.delivery ||
    !restaurant.delivery_opening_hours ||
    !ordersConfig.delivery.area ||
    !ordersConfig.delivery.areaOptions
  ) {
    return false;
  }
  return true;
}

export const hasCustomRealizationTime = (order: IOrder) =>
  !!order.customer.deliveryAt;

const calculateItemsCost = (products) =>
  products.reduce((acc, item) => acc + item.amount * item.price, 0);

const memoizedOrderCost = {};
export const getOrderCost = (order: IOrder) => {
  if (!memoizedOrderCost[order.id]) {
    const itemsCost = order.price || calculateItemsCost(order.products);
    const deliveryCost = order.delivery_cost;
    memoizedOrderCost[order.id] = {
      deliveryCost,
      itemsCost,
      totalCost: itemsCost + deliveryCost,
    };
  }
  return memoizedOrderCost[order.id];
};

const CONFIRMED_ORDER_DECLINE_REASONS = [
  {
    label: 'brak możliwości dostawy - dostawa spoza strefy',
    value: 'delivery_issue_out_of_zone',
  },
  {
    label: 'brak możliwości dostawy - brak kierowcy',
    value: 'delivery_issue_no_driver',
  },
  {
    label: 'klient prosi o anulowanie z powodu długiego czasu oczekiwania',
    value: 'customer_decline_too_long_time',
  },
  {
    label: 'klient prosi o anulowanie z innego powodu',
    value: 'customer_decline_other_reason',
  },
];

const Order = {
  CONFIRMED_ORDER_DECLINE_REASONS,
  DELIVERY_PREPARE_TIMES: [30, 45, 60, 75, 90, 120],
  TAKEOUT_PREPARE_TIMES: [10, 15, 20, 30, 45],
  getAddress,
  getPrepareTime,
  getDeliveryMethodLabel,
  getPaymentMethodLabel,
  isConfirmed,
  isContactless,
  isDeclined,
  isDelivered,
  isPending,
  isSent,
  isSelfCollect,
  isTakeout,
  hasCustomRealizationTime,
  hasOnlinePayment,
  hasRobotDelivery,
};

export default Order;
