import React from 'react';
import PropTypes from 'prop-types';
import { fixDecimal } from 'utils/general';
import OtoStackedBarChart from '../common/OtoStackedBarChart';
import memoizeOne from 'memoize-one';

interface IItem {
  yearmonth: string;
  reservation_amount: number;
  guests_amount: number;
}

interface IRowWithSource {
  reservation_amount: number;
  source: string;
  yearmonth: string;
}

const prepareChartDataFromArray = memoizeOne(
  (rowsWithSource: IRowWithSource[]) => {
    const validRows = rowsWithSource.filter(row => !!row.yearmonth);
    const uniqueMonths = Array.from(
      new Set(
        validRows.map((row) => row.yearmonth)
      ).values()
    );
    const uniqueSources = Array.from(
      new Set(
        validRows.map((row) => row.source || 'empty-source')
      ).values()
    );
    // TODO this shit is slow, probably transform data to object and access keys instead of .find() every time if better performance needed
    const datasets = uniqueSources.map((source, index) => ({
      label: source,
      data: uniqueMonths.map(
        (month) =>
        validRows.find(
            (item) => item.yearmonth === month && item.source === source
          )?.reservation_amount || 0
      ),
    }));
    return {
      labels: uniqueMonths,
      datasets,
    };
  }
);

const AdminReservationStatistics = (props) => {
  const { confirmed, declined } = props.data.by_month;
  return (
    <div>
      <h2>Amount & declined % by month</h2>
      <div className="d-flex flex-row">
        <div className="br-1 px-1">
          <div>Year / Month</div>
          <div className="text-success">Confirmed</div>
          <div className="text-danger">Declined</div>
          <div>Declined %</div>
        </div>
        {confirmed.map((item: IItem) => {
          const declinedAmount =
            declined.find(
              (declinedItem) => declinedItem.yearmonth === item.yearmonth
            )?.reservation_amount || 0;
          return (
            <div key={item.yearmonth} className="br-1 px-1 text-center">
              <div>{item.yearmonth || 'NULL'}</div>
              <div className="text-success">{item.reservation_amount}</div>
              <div className="text-danger">{declinedAmount}</div>
              <div>
                {fixDecimal(
                  (declinedAmount * 100) /
                    (declinedAmount + item.reservation_amount)
                )}
                %
              </div>
            </div>
          );
        })}
      </div>
      <h2>By source</h2>
      <OtoStackedBarChart
        {...prepareChartDataFromArray(props.data.by_source)}
      />
    </div>
  );
};

AdminReservationStatistics.propTypes = {
  data: PropTypes.shape({
    by_month: PropTypes.shape({
      confirmed: PropTypes.array.isRequired,
      declined: PropTypes.array.isRequired,
    }),
    by_source: PropTypes.array,
  }),
};

export default AdminReservationStatistics;
