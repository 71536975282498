import React, { useEffect, useState } from 'react';
import Shapes from 'shapes/main';
import { formatDate } from 'utils/date-time';
import {
  hasLesznojeOrdersList,
  hasLubjeOrdersList,
  hasOstrowjeOrdersList,
  hasProduct,
} from 'utils/restaurant';
import { currency } from 'globals/currency';
import { OTO_PRODUCTS } from 'enums';
import TenantSelector from '../admin-statistics/TenantSelector';
import CollapsibleCard from '../common/CollapsibleCard';
import PayoutItem, { getPayoutPaidAtLabel } from './PayoutItem';
import type { IPayout, NormalizedRestaurant } from 'types';

interface IRestaurantPayoutsProps {
  payouts: IPayout[];
  onPayoutDetailsOpen?: (payout: IPayout) => void;
  restaurant: NormalizedRestaurant;
}

export const RestaurantPayouts: React.FC<IRestaurantPayoutsProps> = ({
  payouts,
  onPayoutDetailsOpen,
  restaurant,
}) => {
  const [hasMultipleTenants, setHasMultipleTenants] = useState(false);
  const [activeTenant, setActiveTenant] = useState<string>('');

  useEffect(() => {
    const hasOtoStolik =
      hasProduct(restaurant, OTO_PRODUCTS.website) ||
      hasProduct(restaurant, OTO_PRODUCTS.iframe);
    const hasLubje = hasLubjeOrdersList(restaurant);
    const hasLeszno = hasLesznojeOrdersList(restaurant);
    const hasOstrow = hasOstrowjeOrdersList(restaurant);

    const howManyTenantsHas = [
      hasOtoStolik,
      hasLubje,
      hasLeszno,
      hasOstrow,
    ].filter(Boolean).length;
    if (howManyTenantsHas > 1) {
      setHasMultipleTenants(true);
    }

    if (hasLeszno) {
      setActiveTenant('lesznoje');
    } else if (hasOstrow) {
      setActiveTenant('ostrowje');
    } else if (hasLubje) {
      setActiveTenant('lubje');
    }
  }, [restaurant]);

  const isValidPayoutForCurrentTenant = (payout: IPayout) => {
    if (!activeTenant) {
      return true;
    }
    return payout.tenant === activeTenant;
  };

  const showTenantInPayoutTitle = !activeTenant && hasMultipleTenants;

  return (
    <>
      {hasMultipleTenants && (
        <>
          <TenantSelector
            onChange={setActiveTenant}
            showLesznoJe={hasLesznojeOrdersList(restaurant)}
            showOstrowJe={hasOstrowjeOrdersList(restaurant)}
            value={activeTenant}
          />
        </>
      )}
      {payouts
        .reverse()
        .filter(isValidPayoutForCurrentTenant)
        .map((payout: IPayout) => {
          const from = formatDate(new Date(payout.period_from));
          const to = formatDate(new Date(payout.period_to));
          const titleParts = [
            `Okres: ${from} - ${to}`,
            `do wypłaty: ${payout.sum_to_payout} ${currency}`,
            `data rozliczenia: ${getPayoutPaidAtLabel(payout)}`,
            showTenantInPayoutTitle ? `(${payout.tenant})` : '',
          ];
          const title = titleParts.filter(Boolean).join(' | ');

          return (
            <CollapsibleCard
              key={payout.id}
              onOpen={() => onPayoutDetailsOpen?.(payout)}
              title={title}
            >
              <PayoutItem payout={payout} />
            </CollapsibleCard>
          );
        })}
    </>
  );
};

RestaurantPayouts.propTypes = {
  restaurant: Shapes.restaurantShape.isRequired,
};

export default RestaurantPayouts;
