import React from 'react';

import SimpleTableWithSorting, {
  generateColumn,
  IColumn,
} from 'app/components/common/SimpleTableWithSorting';
import { formatDate } from 'utils/date-time';
import { TUser } from 'types';
import ChangePasswordCell from '../admin/users/ChangePasswordCell';

const usersColumns: IColumn[] = [
  generateColumn('id', {
    customCellFormatter: (props) => <ChangePasswordCell data={props} />,
  }),
  generateColumn('name'),
  generateColumn('email'),
  {
    accessor: 'last_login_at',
    Header: 'Last login',
    Cell: (props) => (
      <div>
        {!!props.value && !props.value.includes('0000')
          ? formatDate(new Date(props.value), 'dd.MM.yy')
          : '-'}
      </div>
    ),
  },
  {
    accessor: 'last_activity_at',
    Header: 'Last activity',
    Cell: (props) => <div>{formatDate(new Date(props.value), 'dd.MM.yy')}</div>,
  },
];

interface UsersTableProps {
  users: TUser[];
}

export const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  return (
    <SimpleTableWithSorting
      columns={usersColumns}
      data={users}
      hasFilters
      pageSize={50}
    />
  );
};

export default UsersTable;
