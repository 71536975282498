import React from 'react';
import { Link } from 'react-router-dom';

import './ReportBug.scss';

export const ReportBug = () => {
  return (
    <Link className="no-print report-bug" to="/help">
      Coś nie działa? Zgłoś błąd
    </Link>
  );
};

export default ReportBug;
