import type { NormalizedRestaurant, ITable, User } from 'types';
import { getArrayWithUpdatedObject } from 'utils/array';
import { getSafeLsJSON } from 'utils/parsers';

export const getRestaurant = (): NormalizedRestaurant | null => getSafeLsJSON('restaurant', null);
export const setRestaurant = (restaurant: NormalizedRestaurant) =>
  localStorage.setItem('restaurant', JSON.stringify(restaurant));
export const updateLsRestaurant = (parts: Partial<NormalizedRestaurant>) => {
  const currentRestaurant = getRestaurant();
  if (!currentRestaurant) {
    return;
  }
  setRestaurant({
    ...currentRestaurant,
    ...parts,
  });
};

export const addLsRestaurantTable = (table: ITable) => {
  const currentRestaurant = getRestaurant();
  if (!currentRestaurant) {
    return;
  }
  const tablesConfig = currentRestaurant.tablesConfig || [];
  setRestaurant({
    ...currentRestaurant,
    tablesConfig: [...tablesConfig, table],
  });
};

export const updateLsRestaurantTable = (newTable: ITable) => {
  const currentRestaurant = getRestaurant();
  if (!currentRestaurant) {
    return;
  }
  const tablesConfig = currentRestaurant.tablesConfig || [];
  setRestaurant({
    ...currentRestaurant,
    tablesConfig: getArrayWithUpdatedObject(tablesConfig, newTable),
  });
};
export const clearLsRestaurant = () => localStorage.removeItem('restaurant');

export const getLsRole = () =>
  parseInt(localStorage.getItem('role') || '1', 10);
export const setLsRole = (role: string) => localStorage.setItem('role', role);
export const clearLsRole = () => localStorage.removeItem('role');

export const getLsLang = () => localStorage.getItem('lang');
export const setLsLang = (lang: string) => localStorage.setItem('lang', lang);

export const getLsUserRestaurants = () =>
  getSafeLsJSON('user-restaurants', null);

export const setLsUserRestaurants = (restaurants) =>
  localStorage.setItem('user-restaurants', JSON.stringify(restaurants));
export const clearLsUserRestaurants = () =>
  localStorage.removeItem('user-restaurants');

const getFirstLoginDate = () => {
  const d = localStorage.getItem('first-login-date');
  if (!d) {
    return null;
  }
  try {
    return new Date(d);
  } catch (e) {
    return null;
  }
};
const setFirstLoginDate = (d) =>
  localStorage.setItem('first-login-date', d.toString());

export const PERSISTENT_LS = {
  getOrdersView: () => localStorage.getItem('orders-view') || 'GRID',
  setOrdersView: (key: string) => localStorage.setItem('orders-view', key),

  getDeliveriesView: () => localStorage.getItem('deliveries-view') || 'GRID',
  setDeliveriesView: (key: string) =>
    localStorage.setItem('deliveries-view', key),

  getUser: () => getSafeLsJSON('user', null),
  setUser: (user: User) => localStorage.setItem('user', JSON.stringify(user)),
  clearUser: () => localStorage.removeItem('user'),

  getLsLang,
  setLsLang,

  getLsRole,
  setLsRole,
  clearLsRole,

  getRestaurant,
  setRestaurant,
  updateLsRestaurant,
  addLsRestaurantTable,
  updateLsRestaurantTable,
  clearLsRestaurant,

  getLsUserRestaurants,
  setLsUserRestaurants,
  clearLsUserRestaurants,

  getFirstLoginDate,
  setFirstLoginDate,
};
