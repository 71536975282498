import React from 'react';
import PropTypes from 'prop-types';

import ContactCard from 'app/components/contact/ContactCard';
import FixErrorSteps from 'app/components/contact/FixErrorSteps';

interface IErrorScreenProps {
  title?: string;
  onLogout: () => void;
}

const ErrorScreen: React.FC<IErrorScreenProps> = (props) => {
  const { onLogout, title } = props;
  return (
    <div className="centrify flex-column justify-content-center">
      {title  && <div className="alert alert-info">{title}</div>}
      <FixErrorSteps onLogout={onLogout} />
      <ContactCard />
    </div>
  );
};

ErrorScreen.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default ErrorScreen;
