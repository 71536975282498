import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import DeliveryHistory from './DeliveryHistory';
import { currency } from 'globals/currency';
import { EPaymentType } from 'enums/payments';
import InputWithAddon from '../../common/InputWithAddon';
import { IDelivery } from 'types/delivery';
import { DriverType } from 'enums/delivery';
import Shapes from 'shapes/main';
import { User } from 'types';

interface IProps {
  canEditDeliveries: boolean;
  drivers: User[];
  handleDeliveryUpdate: (delivery: IDelivery, meta: any) => any;
  onClose: () => void;
  delivery: IDelivery;
  isEditingMode: boolean;
}

interface IState {
  driverId: number | string;
  isEditingMode: boolean;
  payment?: string;
  price: number;
}

export default class DeliveryEditModal extends React.PureComponent<
  IProps,
  IState
> {
  state: IState = {
    driverId: this.props.delivery.assigned_to || '',
    isEditingMode: this.props.isEditingMode,
    payment: this.props.delivery.payment_status,
    price: this.props.delivery.price || 0,
  };

  static propTypes = {
    canEditDeliveries: PropTypes.bool.isRequired,
    drivers: PropTypes.array.isRequired,
    handleDeliveryUpdate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    delivery: Shapes.deliveryShape.isRequired,
    isEditingMode: PropTypes.bool.isRequired,
  };

  handleInputChange = (stateKeyName: 'price' | 'payment' | 'driverId', event) =>
    this.setState({ [stateKeyName]: event.target.value } as IState);

  onSave = () => {
    this.props.handleDeliveryUpdate(this.props.delivery, {
      assigned_to: this.state.driverId,
      price: this.state.price,
      payment_status: this.state.payment,
    });
    this.props.onClose();
  };

  render() {
    const { onClose, delivery } = this.props;
    return (
      <Modal isOpen={true} toggle={onClose} key="modal">
        <ModalHeader toggle={onClose} className="pt-1">
          {i18next.t('delivery.title')} #{delivery.id}
        </ModalHeader>
        <ModalBody>
          {this.state.isEditingMode ? (
            this.renderInputs()
          ) : (
            <DeliveryHistory delivery={delivery} />
          )}
        </ModalBody>
        <ModalFooter>{this.renderFooter()}</ModalFooter>
      </Modal>
    );
  }

  renderAdminOnlyInputs() {
    if (!this.props.canEditDeliveries) {
      return null;
    }

    return (
      <>
        <FormGroup>
          <Label>{i18next.t('delivery.payment-type')}</Label>
          <Input
            type="select"
            value={this.state.payment}
            onChange={(e) => this.handleInputChange('payment', e)}
          >
            <option value={EPaymentType.CASH}>Gotówką przy odbiorze</option>
            <option value={EPaymentType.CARD_ON_PLACE}>
              Kartą przy odbiorze
            </option>
            <option value={EPaymentType.PAID}>Opłacone</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>{i18next.t('delivery.price')}</Label>
          <InputWithAddon
            addonText={currency}
            id="price"
            name="price"
            type="number"
            value={this.state.price}
            onChange={(e) => this.handleInputChange('price', e)}
          />
        </FormGroup>
      </>
    );
  }

  renderInputs() {
    const isRobotDelivery =
      this.props.delivery.driver_type === DriverType.Robot;
    return (
      <>
        <FormGroup key="driver">
          <Label for="driver">{i18next.t('delivery.driver')}</Label>
          <Input
            type="select"
            name="driverSelect"
            disabled={isRobotDelivery}
            value={this.state.driverId}
            onChange={(e) => this.handleInputChange('driverId', e)}
          >
            <option value={''}>Nie Wybrany</option>
            {this.props.drivers.map((driver) => (
              <option key={driver.id} value={driver.id}>
                {driver.name}
              </option>
            ))}
          </Input>
          {isRobotDelivery && (
            <span className="text-danger">
              Ta dostawa jest przypisana do robota, nie możesz przydzielić jej
              innemu kierowcy.
            </span>
          )}
        </FormGroup>
        {this.renderAdminOnlyInputs()}
      </>
    );
  }

  renderFooter() {
    if (this.props.isEditingMode) {
      return (
        <>
          <Button color="success" onClick={this.onSave}>
            {i18next.t('Save')}
          </Button>{' '}
          <Button color="danger" onClick={this.props.onClose}>
            {i18next.t('book-modal.cancel_edit')}
          </Button>{' '}
        </>
      );
    }

    return null;
  }
}

export { DeliveryEditModal }