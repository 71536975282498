import React, { useCallback } from 'react';
import { Button } from 'reactstrap';

import DeveloperVersion from './DeveloperVersion';
import {
  selectLanguage,
  selectMessagingToken,
  setIsDebug,
} from 'store/app';
import DebugModeOnly from './DebugModeOnly';
import SendTestPushNotification from '../../containers/notifications/SendTestPushNotification';
import CollapsibleCard from '../common/CollapsibleCard';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

const DeveloperPanel: React.FC<{}> = () => {
  const lang = useAppSelector(selectLanguage);
  const messagingToken = useAppSelector(selectMessagingToken);
  const dispatch = useAppDispatch();
  const enableDebug = useCallback(() => dispatch(setIsDebug(true)), [dispatch]);
  const disableDebug = useCallback(
    () => dispatch(setIsDebug(false)),
    [dispatch]
  );

  return (
    <>
      <DebugModeOnly>
        <div className="w-100 text-left">
          <div className="mt-2">
            <Button color="primary" type="button" onClick={disableDebug}>
              Wyłącz tryb deweloperski
            </Button>
          </div>
          <div className="mw-400 bt-1 mt-2 pt-2">
            <CollapsibleCard
              title={<h5 className="m-0">Wyślij testową wiadomość push</h5>}
            >
              <SendTestPushNotification />
            </CollapsibleCard>
          </div>
          <div>FCM token: {messagingToken || 'None'}</div>
        </div>
      </DebugModeOnly>
      <div className="d-flex justify-content-end">
        <DeveloperVersion onDebugEnable={enableDebug} lang={lang} />
      </div>
    </>
  );
};

export default DeveloperPanel;
