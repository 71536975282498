import type { ISavedFile } from './attachment';
import type { ITableWithPlace } from './restaurant-table';

export enum ReservationStatus {
  confirmed = 'confirmed',
  declined = 'declined',
}

export enum ReservationSource {
  landing = 'landing',
  iframe = 'iframe',
  dashboard = 'dashboard',
  integration = 'integration',
  google_maps = 'google_maps',
  fb_book_now_button = 'fb_book_now_button',
  fb_messenger = 'fb_messenger',
  otostolik_mobile_app = 'otostolik_mobile_app',

}

export interface ISavedReservation {
  id: number;
  code: string;
  customer_name: string;
  phone_number: string;
  email?: string;
  guests_number: number;

  sms_marketing?: boolean;
  email_marketing?: boolean;

  declined_reason_id?: number | null;
  duration?: number | null;
  extras?: IReservationExtras | null;
  is_entire_place: boolean;
  notes_from_guest?: string | null;
  notes_from_restaurant?: string | null;
  reserved_on: string; // TODO date
  restaurant_id: number;
  source: ReservationSource;
  status: ReservationStatus;
  tables?: ITableWithPlace[] | null;
  attachments?: ISavedFile[] | null;
  created_at: string;
  updated_at: string;
}

export interface IReservationExtras {
  assignee?: string | null;
  kids_number?: number | null;
  non_vaccinated_amount?: number | string | null;
  present?: boolean | null;
}

export type TDraftReservation = Omit<
  ISavedReservation,
  // TODO not sure if we should allow to pass source
  | 'id'
  | 'code'
  | 'created_at'
  | 'updated_at'
  | 'reserved_on'
  | 'status'
  | 'duration'
> & {
  duration: number; // allow empty duration on saved reservations but force on new ones
  visitDate: string;
  visitTime: string;
};

export type TNormalizedReservation<T> = Omit<T, 'duration' | 'reserved_on'> & {
  duration: number;
  visitDate: string;
  visitTime: string;
}

export type CreateReservationDTO = Omit<
  ISavedReservation,
  'id' | 'code' | 'created_at' | 'updated_at' | 'status' | 'tables'
> & {
  tables?: number[];
};

export type ParamsReservationEdit = Omit<ISavedReservation, 'tables'> & {
  tables?: number[];
};

export type AddReservationFormValues = Omit<TDraftReservation, 'tables'> & {
  assignee?: string;
  kids_number?: number;
};
