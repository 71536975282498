import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { AnyRestaruant } from 'types';

interface IProps {
  restaurant: AnyRestaruant;
  type: 'reservations' | 'orders';
}

const RestaurantIsNotLiveAlert: React.FC<IProps> = ({ restaurant, type }) => {
  if (restaurant.status === 'live') {
    return null;
  }
  return (
    <Alert color="primary">
      <p>Restauracja znajduje się w trybie testowym.</p>
      {type === 'reservations' && (
        <div>
          <p>
            Możesz się rozejrzeć, zmienić ustawienia rezerwacji, dodać sale i
            stoliki oraz przenieść rezerwacje z dotychczasowego źródła, ale
            wiadomości SMS nie będą wysyłane po dodaniu rezerwacji.
          </p>
          <p>
            Skonfigurować ustawienia rezerwacji możesz{' '}
            <Link to="/settings/reservations">tutaj</Link>
          </p>
          <p>
            Jeżeli chcesz aktywować restaurację tak, aby wiadomości SMS były
            wysyłane, lub potrzebujesz pomocy - skontaktuj się z nami.
            <div>
              <Link to="/help">Dane kontaktowe</Link>
            </div>
          </p>
        </div>
      )}
      {type === 'orders' && (
        <div>
          <p>
            Możesz się rozejrzeć, zmienić ustawienia zamówień, strefy dostaw,
            godziny, zaktualizować menu oraz podejrzeć profil restauracji, ale
            składanie zamówień jeszcze nie będzie możliwe.
          </p>
          <p>
            Skonfigurować ustawienia zamówień możesz{' '}
            <Link to="/settings/orders">tutaj</Link>
          </p>
          <p>
            Jeżeli chcesz aktywować restaurację, lub potrzebujesz pomocy -
            skontaktuj się z nami.
            <div>
              <Link to="/help">Dane kontaktowe</Link>
            </div>
          </p>
        </div>
      )}
    </Alert>
  );
};

export default RestaurantIsNotLiveAlert;
