import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import i18next from 'i18next';
import { NormalizedRestaurant, OriginalRestaurant } from 'types/restaurants';
import { User } from 'types/user';
import { ROLES } from 'enums';
import Shapes from 'shapes/main';

interface IProps {
  onChangeRestaurantClick: (restaurant: OriginalRestaurant) => void;
  restaurant: NormalizedRestaurant;
  role: number;
  user: User;
  userRestaurants?: OriginalRestaurant[];
}

const LoggedAs = (props: IProps) => {
  const { onChangeRestaurantClick, restaurant, role, user, userRestaurants } = props;
  if (!restaurant || !restaurant.name) {
    return null;
  }
  const hasMultipleRestaurants = userRestaurants && userRestaurants.length > 1;
  const label = getLoggedInLabel(restaurant, user, role);
  return (
    <div
      className={`small mb-3 mb-md-0 mr-3 d-flex ${
        hasMultipleRestaurants ? 'flex-row align-items-center' : 'flex-column'
      }`}
    >
      <div className="mr-2">{i18next.t('header__logged-as')}:</div>
      {hasMultipleRestaurants && userRestaurants ? (
        <UncontrolledDropdown>
          <DropdownToggle
            caret
            className="h5 mb-0 d-flex flex-row flex-nowrap align-items-center"
          >
            <div>{label}</div>
          </DropdownToggle>
          <DropdownMenu>
            {userRestaurants.map((r) => (
              <DropdownItem
                key={r.name}
                className="small"
                onClick={() => onChangeRestaurantClick(r)}
              >
                {r.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <div>{label}</div>
      )}
    </div>
  );
};

function getLoggedInLabel(
  restaurant: NormalizedRestaurant,
  user: User,
  userRole: number
): string {
  if (userRole === ROLES.ADMIN) {
    return `${user.name} (Admin - ${restaurant.name})`;
  }
  if (userRole === ROLES.RESTAURANT_DRIVER) {
    return `${user.name} (Kierowca - ${restaurant.name})`;
  }
  if (userRole === ROLES.EXCHANGE_DRIVER) {
    return `${user.name} (Kierowca - Giełda)`;
  }
  if (userRole === ROLES.DELIVERY_COORDINATOR) {
    return `${user.name} (Koordynator dostaw)`;
  }
  return restaurant.name;
}

LoggedAs.propTypes = {
  onChangeRestaurantClick: PropTypes.func.isRequired,
  restaurant: PropTypes.object,
  role: PropTypes.number.isRequired,
  user: Shapes.userShape.isRequired,
  userRestaurants: PropTypes.array,
};

export default LoggedAs;
