import React from 'react';

import RestaurantPayouts from 'app/components/payouts/RestaurantPayouts';
import ErrorBoundary from '../containers/ErrorBoundary';
import { PageComponent } from '../types-global';
import { useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';
import UrlFetcher from 'app/containers/UrlFetcher';
import type { IPayout, NormalizedRestaurant } from 'types';

const RestaurantPayoutsPage: PageComponent<{}> = () => {
  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant;
  return (
    <ErrorBoundary componentName="RestaurantPayouts">
      <UrlFetcher<IPayout[]> url={`/restaurants/${restaurant.id}/payouts`}>
        {([payouts]) => (
          <RestaurantPayouts payouts={payouts} restaurant={restaurant} />
        )}
      </UrlFetcher>
    </ErrorBoundary>
  );
};

RestaurantPayoutsPage.navName = 'Payouts';
RestaurantPayoutsPage.url = '/payouts';

export default RestaurantPayoutsPage;
