import React from 'react';
import { Badge } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import i18next from 'i18next';

import { G_MAPS_API_KEY } from 'config';
import DeliveriesMapMarker from './DeliveriesMapMarker';
import { EDeliveryStatus } from 'enums/delivery';
import type { IDelivery } from 'types';

export interface IDeliveriesMapProps {
  center?: object;
  zoom?: number;
  deliveries: IDelivery[];
  restaurant: any;
}

class DeliveriesMap extends React.Component<IDeliveriesMapProps, {}> {
  static defaultProps = {
    center: {
      lat: 51.247119,
      lng: 22.563311,
    },
    zoom: 13,
  };

  mapContainerStyle = { marginTop: '10px', height: '100vh', width: '100%' };

  deliveryColorsByStatuses = {
    [EDeliveryStatus.NEW]: { color: 'red', btnClass: 'danger' },
    [EDeliveryStatus.ASSIGNED]: { color: '#007bff', btnClass: 'primary' },
    [EDeliveryStatus.ARRIVING]: { color: 'white', btnClass: 'light' },
    [EDeliveryStatus.ARRIVED]: { color: '#17a2b8', btnClass: 'info' },
    [EDeliveryStatus.ON_THE_WAY]: { color: '#ffc107', btnClass: 'warning' },
    [EDeliveryStatus.DELIVERED]: { color: '#28a745', btnClass: 'success' },
    [EDeliveryStatus.DECLINED]: { color: '#343a40', btnClass: 'dark' },
  };

  render() {
    const { zoom, deliveries, restaurant } = this.props;
    if (!restaurant.orders || !restaurant.orders.delivery) {
      return null;
    }

    const center = {
      lng: parseFloat(restaurant.orders.delivery.long),
      lat: parseFloat(restaurant.orders.delivery.lat),
    };

    const places = deliveries.map((delivery) => {
      return (
        <DeliveriesMapMarker
          key={delivery.id}
          lat={delivery.lat}
          lng={delivery.lng}
          color={this.deliveryColorsByStatuses[delivery.status]?.color}
          content={delivery.address}
        />
      );
    });

    return (
      <>
        {Object.keys(this.deliveryColorsByStatuses).map((item) => (
          <span key={item}>
            <Badge
              href="#"
              color={this.deliveryColorsByStatuses[item].btnClass}
              className="mr-1"
            >
              {i18next.t(`delivery.restaurant-view.statuses.${item}`)}
            </Badge>
          </span>
        ))}
        <div style={this.mapContainerStyle}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: G_MAPS_API_KEY }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            {places}
          </GoogleMapReact>
        </div>
      </>
    );
  }
}

export default DeliveriesMap;
