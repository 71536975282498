import React from 'react';
import PropTypes from 'prop-types';
import { BookOpen } from 'react-feather';
import i18 from 'i18next';

import { AnyRestaruant, PlainRestaurant } from 'types/restaurants';
import { plainRestaurantShape } from 'shapes/restaurant';
import {
  hasLesznojeOrdersList,
  hasLubjeOrdersList,
  hasOstrowjeOrdersList,
} from 'utils/restaurant';
import { APITenantConfig, selectTenantConfigs, Tenants } from 'store/tenants';
import { useAppSelector } from 'hooks/useRedux';

export enum RestaurantPreviewType {
  Menu = 'menu',
  MenuOnPlace = 'menu_on_place',
  Resevations = 'reservations',
}

const urlPartByType = {
  menu: 'zamawiaj',
  menu_on_place: 'menu',
  reservations: 'rezerwuj',
};

interface IProps {
  color?: string;
  className?: string;
  type?: RestaurantPreviewType;
  restaurant: PlainRestaurant;
}

const PreviewLink: React.FC<IProps> = (props) => {
  const { color, className, restaurant } = props;
  // @ts-ignore this is fine because of defaultProps
  const type: PreviewType = props.type;

  const tenantConfigs = useAppSelector(selectTenantConfigs);
  const host = getRestaurantPreviewHost(restaurant, type, tenantConfigs);

  const PreviewLink = `${host}${urlPartByType[type]}/${restaurant.slug}`;
  const text = i18.t('Restaurant preview');

  return (
    <a
      href={PreviewLink}
      className={`btn btn-${color} no-print ${className}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <BookOpen /> <span>{text}</span>
    </a>
  );
};

function getRestaurantPreviewHost(
  restaurant: AnyRestaruant,
  type: RestaurantPreviewType,
  tenantConfigs: Record<Tenants, APITenantConfig>
): string {
  let domain = tenantConfigs[Tenants.OtoStolik].domain;
  if (type === 'menu' && hasLubjeOrdersList(restaurant)) {
    domain = tenantConfigs[Tenants.LUBJE].domain;
  }
  if (type === 'menu' && hasLesznojeOrdersList(restaurant)) {
    domain = tenantConfigs[Tenants.LesznoJe].domain;
  }
  if (type === 'menu' && hasOstrowjeOrdersList(restaurant)) {
    domain = tenantConfigs[Tenants.OstrowJe].domain;
  }
  const host = domain.includes('local') ? 'http://' : 'https://';
  return `${host}${domain}/`;
}

PreviewLink.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RestaurantPreviewType)).isRequired,
  restaurant: plainRestaurantShape.isRequired,
};

PreviewLink.defaultProps = {
  color: 'link',
  className: '',
  type: RestaurantPreviewType.Menu,
};

export default PreviewLink;
