import React from 'react';
import PropTypes from 'prop-types';
import { geocodeByAddress } from 'react-places-autocomplete';

import { debounce } from 'utils/general';
import { logError } from 'utils/log';
import APIService from 'services/api';
import { currency } from 'globals/currency';
import StreetAutocomplete from './StreetAutocomplete';
import type { NormalizedRestaurant } from 'types';

import './location-search-input.scss';

declare global {
  interface Window {
    google: any;
  }
}

interface LocationSearchInputProps {
  address: {
    city?: string;
    street: string;
    homeNumber: string;
    flatNumber?: string | null;
    lat?: number | string;
    lng?: number | string;
  };
  onAddressUpdate: (address: any) => void;
  restaurant: NormalizedRestaurant;
}

interface LocationSearchInputState {
  deliveryPriceMessage?: string;
  deliveryPriceLoading?: boolean;
  error: string | null;
}

class LocationSearchInput extends React.Component<
  LocationSearchInputProps,
  LocationSearchInputState
> {
  searchOptions: {
    location: any;
    radius: number;
    types?: string[];
  };
  debouncedGeocode: () => void;
  homeNumberRef?: HTMLInputElement;

  static propTypes = {
    address: PropTypes.object.isRequired,
    onAddressUpdate: PropTypes.func.isRequired,
    restaurant: PropTypes.object.isRequired,
  };

  constructor(props: LocationSearchInputProps) {
    super(props);
    this.state = {
      error: null,
    };
    try {
      const { restaurant } = props;
      this.searchOptions = {
        location: new window.google.maps.LatLng(restaurant.lat, restaurant.lng),
        radius: 20000,
        types: ['address'],
      };
    } catch (e) {
      logError(
        'Restaurant can order delivery, but has no long/lat set!',
        e as Error
      );
      this.searchOptions = {
        location: new window.google.maps.LatLng(51.247119, 22.563311),
        radius: 1000,
      };
    }
    this.debouncedGeocode = debounce(this.geocode, 1000);
  }

  geocode = () => {
    this.setState({ deliveryPriceLoading: true });
    const { city, street, homeNumber } = this.props.address || {};
    const address = `${city}, ${street} ${homeNumber}`;
    geocodeByAddress(address)
      .then((results) => {
        const selectedAddress = results[0];
        const { location } = selectedAddress.geometry;
        const url = `/restaurants/${
          this.props.restaurant.id
        }/distance?lng=${location.lng()}&lat=${location.lat()}`;
        this.props.onAddressUpdate({
          ...this.props.address,
          lng: location.lng(),
          lat: location.lat(),
        });
        APIService.get(url)
          .then((deliveryData) => {
            const { distance, price: deliveryPrice } = deliveryData;
            this.setState({
              deliveryPriceMessage: `Wyliczona odległość do adresu ${address} - ${distance}m, cena dostawy dla klienta: ${deliveryPrice}${currency}`,
              deliveryPriceLoading: false,
              error: null,
            });
          })
          .catch((e) => {
            this.setState({
              error: 'Wystąpił błąd podczas wyliczenia odległości.',
            });
            logError(`Distance measuring error`, e);
          });
      })
      .catch((error) => {
        this.setState({
          error: 'Wystąpił błąd podczas próby znalezienia ulicy.',
        });
        logError('Geocode error', error);
      });
  };

  handleStreetChange = (street) =>
    this.props.onAddressUpdate({
      ...this.props.address,
      street,
    });

  handleSelect = (suggestionStr: string) => {
    const addressParts = suggestionStr.split(',');
    const [street, city] = addressParts;
    this.props.onAddressUpdate({
      ...this.props.address,
      street,
      city: city.trim(),
    });
    this.homeNumberRef?.focus();
  };

  handleHomeNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const homeNumber = e.target.value;
    this.props.onAddressUpdate({
      ...this.props.address,
      homeNumber,
    });
    this.debouncedGeocode();
  };

  handleFlatNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const flatNumber = e.target.value;
    this.props.onAddressUpdate({
      ...this.props.address,
      flatNumber,
    });
  };

  setHomeNumberRef = (node: HTMLInputElement) => (this.homeNumberRef = node);

  render() {
    const address = this.props.address || {};
    const { flatNumber = '', homeNumber = '', street = '' } = address;
    return (
      <div className="row mb-3">
        <StreetAutocomplete
          street={street}
          searchOptions={this.searchOptions}
          handleStreetChange={this.handleStreetChange}
          handleSelect={this.handleSelect}
          wrapperClassName="col-12 p-0"
        />
        <div className="col-12 col-sm-6 p-0">
          <input
            type="string"
            value={homeNumber}
            ref={this.setHomeNumberRef}
            onChange={this.handleHomeNumberChange}
            className="form-control mt-2"
            placeholder="Nr. budynku"
          />
        </div>
        <div className="col-12 col-sm-6 p-0 pl-sm-2">
          <input
            type="number"
            value={flatNumber || ''}
            onChange={this.handleFlatNumberChange}
            className="form-control mt-2"
            placeholder="Nr. mieszkania"
          />
        </div>
        {this.renderFooter()}
      </div>
    );
  }

  renderFooter() {
    const { deliveryPriceMessage, deliveryPriceLoading, error } = this.state;
    if (deliveryPriceLoading) {
      return (
        <div className="text-success mt-2">
          Wyliczam odległość i cenę dostawy...
        </div>
      );
    }
    if (error) {
      return <div className="text-danger mt-2">{error}</div>;
    }
    return (
      deliveryPriceMessage && (
        <div className="text-success mt-2">{deliveryPriceMessage}</div>
      )
    );
  }
}

export default LocationSearchInput;
