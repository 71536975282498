import React from 'react';

import type { IOrder } from 'types';

type OrderPaymentDetailsProps = {
  order: IOrder;
};

export const OrderPaymentDetails: React.FC<OrderPaymentDetailsProps> = ({
  order,
}) => {
  const payment = order.payment;
  if (!payment) {
    return (
      <>
        Brak płatności - najprawdopodobniej wystąpił błąd podczas tworzenia
        zamówienia lub jego opłaty.
        <br />
        Typ płatności: {order.payment_type}
      </>
    );
  }
  return payment.driver !== 'offline' ? (
    <>
      <div>Status: {payment.status}</div>
      <div>
        ID: {payment.id} / {payment.external_id}
      </div>
    </>
  ) : (
    <div>Płatność offline ({payment.type})</div>
  );
};

export default OrderPaymentDetails;
