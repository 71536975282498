import React, { useState } from 'react';
import {
  Card,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap';
import i18next from 'i18next';

import type { AddReservationFormValues, ReservationConfig } from 'types';
import TimePicker from 'app/components/common/TimePicker';
import { checkBoxStyle } from '../styles';
import {
  canSelectDuration,
  getDurationOptions,
  hasFlexibleTables,
  hasKids,
} from 'utils/reservation';
import { isIOS, isSafari } from 'utils/device';
import { OtoButtons } from 'app/components/common';

interface IProps {
  contentAttachments?: React.ReactNode;
  contentAfterCoreInputs: (disabled?: boolean) => React.ReactNode;
  config: ReservationConfig;
  disabled?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  values: AddReservationFormValues;
  updateVisitTime: (time: string) => void;
}

const AddReservationFormStep1: React.FC<IProps> = (props) => {
  const {
    contentAfterCoreInputs,
    config,
    disabled = false,
    handleChange,
    handleSubmit,
    values,
    updateVisitTime,
  } = props;

  const [showStep2, setShowStep2] = useState(false);

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <CardTitle>
            <h3>
              Krok 1: Podaj datę i godzinę rezerwacji, aby zobaczyć wolne
              stoliki stoliki
            </h3>
          </CardTitle>
          <Row className="align-items-end px-3 gap-10">
            <div>
              <Label for="visitDate">
                {i18next.t('reservation.visit_date')}
              </Label>
              <Input
                type="date"
                name="visitDate"
                id="visitDate"
                disabled={disabled}
                placeholder="Data rezerwacji"
                onChange={handleChange}
                value={values.visitDate}
                className="mw-200"
              />
            </div>
            <div>
              <Label for="visitTime">
                {i18next.t('reservation.visit_time')}
              </Label>
              {isIOS() || isSafari() ? (
                <Input
                  type="time"
                  name="visitTime"
                  id="visitTime"
                  disabled={disabled}
                  onChange={handleChange}
                  value={values.visitTime}
                  className="mw-125"
                />
              ) : (
                <TimePicker
                  className="d-flex flex-row align-items-center"
                  defaultHour={values.visitTime.slice(0, 2)}
                  defaultMin={values.visitTime.slice(3)}
                  disabled={disabled}
                  onChange={updateVisitTime}
                />
              )}
            </div>
            <OtoButtons.AddButton
              color="primary"
              onClick={() => setShowStep2(true)}
              outline={true}
            >
              Dalej: podaj liczbę gości i wybierz stoliki
            </OtoButtons.AddButton>
          </Row>
        </CardBody>
      </Card>

      {showStep2 && (
        <Card>
          <CardBody>
            <CardTitle>
              <h3>
                Krok 2: Podaj liczbę gości oraz zaznacz stoliki na mapie, lub
                wybierz z listy
              </h3>
            </CardTitle>

            <Row>
              <FormGroup className="col-auto">
                <Label for="guests_number">
                  {i18next.t('reservation.guests_number')}
                </Label>
                <Input
                  type="number"
                  name="guests_number"
                  id="guests_number"
                  min="1"
                  disabled={disabled}
                  onChange={handleChange}
                  value={values.guests_number}
                  className="mw-125"
                />
              </FormGroup>
              {hasKids(config) ? (
                <FormGroup className="col-auto">
                  <Label for="kids_number">
                    {i18next.t('reservation.includes_children')}
                  </Label>
                  <Input
                    type="number"
                    name="kids_number"
                    id="kids_number"
                    disabled={disabled}
                    onChange={handleChange}
                    value={values.kids_number}
                  />
                </FormGroup>
              ) : null}
              {canSelectDuration(config) && (
                <FormGroup className="col-auto">
                  <Label for="duration">
                    {i18next.t('reservation.duration')}
                  </Label>
                  <Input
                    type="select"
                    className="mw-200"
                    contentEditable
                    suppressContentEditableWarning
                    name="duration"
                    id="duration"
                    disabled={disabled}
                    onChange={handleChange}
                    value={values.duration}
                  >
                    {getDurationOptions(config).map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
            </Row>
            <div className="d-flex flex-wrap align-items-center">
              {hasFlexibleTables(config) && contentAfterCoreInputs(disabled)}
              <FormGroup check>
                <Label for="is_entire_place" check className="align-vertical">
                  <Input
                    type="checkbox"
                    name="is_entire_place"
                    id="is_entire_place"
                    disabled={disabled}
                    style={checkBoxStyle}
                    onChange={handleChange}
                    checked={values.is_entire_place}
                  />{' '}
                  <span className="ml-2">
                    {i18next.t('add-reservation-page.reservation-exclusive')}
                  </span>
                </Label>
              </FormGroup>
            </div>
            <OtoButtons.GoBackButton
              color="secondary"
              onClick={() => setShowStep2(false)}
            >
              Wróc
            </OtoButtons.GoBackButton>
          </CardBody>
        </Card>
      )}
    </Form>
  );
};

export default AddReservationFormStep1;
