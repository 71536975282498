import React from 'react';
import i18next from 'i18next';

import { toDateInputValue } from 'utils/date-time';
import CommonSection from 'app/components/common/CommonSection';
import DeliveriesSummary from 'app/components/deliveries/DeliveriesSummary';
import WithDateRangeDataFetched from '../../containers/WithDateRangeDataFetched';
import DeliveriesHistory from 'app/components/deliveries/DeliveriesHistory';
import { API_URL } from 'config';
import type { IDelivery } from 'types';

type DeliveriesStatsPageProps = {
  restaurant: {
    id: number;
  };
};

export default class DeliveriesStatsPage extends React.PureComponent<
  DeliveriesStatsPageProps,
  {}
> {
  getUrl = (startDate: Date, endDate: Date) => {
    const { afterFilter, beforeFilter } = this.getFilterRangeFilters(
      startDate,
      endDate
    );
    const includeUser = 'include=statusLogs,user&fields[user]=id,name,phone';
    return `/deliveries?${afterFilter}&${beforeFilter}&${includeUser}`;
  };

  getFilterRangeFilters(startDate, endDate) {
    const afterFilter = `filter[created_after]=${toDateInputValue(startDate)}`;
    const beforeFilter = `filter[created_before]=${toDateInputValue(endDate)}`;
    return { beforeFilter, afterFilter };
  }

  getDeliveriesExportUrl(startDate, endDate) {
    const { afterFilter, beforeFilter } = this.getFilterRangeFilters(
      startDate,
      endDate
    );
    return `${API_URL}/restaurants/${this.props.restaurant.id}/deliveries/export?${afterFilter}&${beforeFilter}`;
  }

  render() {
    return (
      <WithDateRangeDataFetched<IDelivery[]>
        getUrl={this.getUrl}
        defaultRange="today"
      >
        {(fetchedDeliveries, { ranges }) => (
          <>
            <CommonSection
              header={i18next.t('delivery.day-summary.title')}
              colProps={{ md: { size: 12 } }}
            >
              <h2 className="col-12 p-0 mb-2">
                Statystyki dostaw za okres{' '}
                {toDateInputValue(ranges[0].startDate)} -{' '}
                {toDateInputValue(ranges[0].endDate)}
              </h2>
              <DeliveriesSummary
                deliveries={fetchedDeliveries}
                exportButton={
                  <a
                    className="btn btn-primary"
                    href={this.getDeliveriesExportUrl(
                      ranges[0].startDate,
                      ranges[0].endDate
                    )}
                    download
                  >
                    {i18next.t('Export')}
                  </a>
                }
              />
              <h2 className="col-12 p-0 mt-4 mb-2">
                Historia dostaw za okres {toDateInputValue(ranges[0].startDate)}{' '}
                - {toDateInputValue(ranges[0].endDate)}
              </h2>
              <DeliveriesHistory deliveries={fetchedDeliveries} />
            </CommonSection>
          </>
        )}
      </WithDateRangeDataFetched>
    );
  }
}
