import { IPromoCode } from 'types';
import { LOGOUT } from './app';

export interface IPromoCodesReducerState {
  promoCodes: IPromoCode[] | null;
  promoCodesLoading: boolean;
  promoCodesError: any;
}

export const INITIAL_STATE: IPromoCodesReducerState = {
  promoCodes: null,
  promoCodesLoading: false,
  promoCodesError: null,
};

export const SET_PROMO_CODES_LOADING = 'SET_PROMO_CODES_LOADING';
export const SET_PROMO_CODES_SUCCESS = 'SET_PROMO_CODES_SUCCESS';
export const SET_PROMO_CODES_ERROR = 'SET_PROMO_CODES_ERROR';

interface IPromoCodesAction {
  type: string;
  promoCodes: any;
  error: any;
}

function promoCodesReducer(state = INITIAL_STATE, action: IPromoCodesAction) {
  switch (action.type) {
    case SET_PROMO_CODES_SUCCESS:
      return {
        ...state,
        promoCodes: action.promoCodes,
        promoCodesLoading: false,
        promoCodesError: null,
      };
    case SET_PROMO_CODES_LOADING:
      return {
        ...state,
        promoCodesLoading: true,
        promoCodesError: null,
      };
    case SET_PROMO_CODES_ERROR:
      return {
        ...state,
        promoCodes: action.error,
        promoCodesLoading: false,
        promoCodesError: action.error,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const setPromoCodesSuccess = (promoCodes: IPromoCode[]) => ({
  type: SET_PROMO_CODES_SUCCESS,
  promoCodes,
});

export const setPromoCodesError = (e) => ({
  type: SET_PROMO_CODES_ERROR,
  error: e,
});
export const setPromoCodesLoading = () => ({ type: SET_PROMO_CODES_LOADING });

export default promoCodesReducer;
