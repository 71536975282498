import React, { useState } from 'react';
import i18n from 'i18next';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { toast } from 'react-toastify';

import { logError, logActivity } from 'utils/log';
import APIService from 'services/api';
import OtoSpinner from './common/OtoSpinner';
import { PERSISTENT_LS } from 'services/persistent';
import { useLocalStorage } from 'react-use';

const marks = [1, 2, 3, 4, 5];
const twoWeekTime = 1000 * 60 * 60 * 24 * 7 * 2;

const userRoles = ['personnel', 'manager', 'owner', 'driver'];

const RestaurantFeedback = (props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [lastFeedbackDate, setLastFeedbackDate] =
    useLocalStorage('last-restaurant-feedback-date', new Date('2000-01-01'));
  const [values, setValues] = useState({
    mark: null,
    'user-role': '',
  });
  const setField = (newValue, key) => setValues({ ...values, [key]: newValue });

  const submit = () => {
    setSubmitting(true);
    logActivity(
      `New restaurant feedback ${JSON.stringify({
        ...values,
        restaurant: props.restaurant.name,
        restaurantId: props.restaurant.id,
      })}`
    );
    const payload = {
      rating: values.mark,
      worked_well: values['worked-well'],
      went_wrong: values['went-wrong'],
      user_type: values['user-role'],
    };
    setLastFeedbackDate(new Date());
    APIService.post(`/restaurants/${props.restaurant.id}/feedbacks`, payload)
      .then(() => {
        toast.success('Dzięki!');
      })
      .catch((e) => {
        toast.success('Dzięki!');
        logError('Send restaurant feedback error', JSON.stringify(e));
      })
      .finally(() => setSubmitting(false));
  };
  if (new Date() - new Date(lastFeedbackDate) <= twoWeekTime) {
    return null;
  }

  const isNewlyRegistered =
    new Date() - PERSISTENT_LS.getFirstLoginDate() <= twoWeekTime;
  if (isNewlyRegistered) {
    return null;
  }

  // TODO temp!!
  return null;

  return (
    <Card>
      <CardHeader>{i18n.t('restaurant-feedback.title')}</CardHeader>
      <CardBody>
        <FormGroup>
          <Label>{i18n.t('restaurant-feedback.rating-label')}</Label>
          <ButtonGroup>
            {marks.map((mark) => (
              <Button
                key={mark}
                type="button"
                className="px-4"
                color={values.mark === mark ? 'primary' : 'secondary'}
                onClick={() => setField(mark, 'mark')}
              >
                {mark}
              </Button>
            ))}
          </ButtonGroup>
        </FormGroup>
        <FormGroup className="mw-600">
          <Label>{i18n.t('restaurant-feedback.worked-well-label')}</Label>
          <Input
            type="textarea"
            value={values['worked-well'] || ''}
            onChange={(e) => setField(e.target.value, 'worked-well')}
          />
        </FormGroup>
        <FormGroup className="mw-600">
          <Label>{i18n.t('restaurant-feedback.went-wrong-label')}</Label>
          <Input
            type="textarea"
            value={values['went-wrong'] || ''}
            onChange={(e) => setField(e.target.value, 'went-wrong')}
          />
        </FormGroup>
        <FormGroup className="mw-400">
          <Label>{i18n.t('restaurant-feedback.user-role-label')}</Label>
          <Input
            type="select"
            value={values['user-role'] || ''}
            onChange={(e) => setField(e.target.value, 'user-role')}
          >
            <option key="empty" value=""></option>
            {userRoles.map((role) => (
              <option key={role} value={role}>
                {i18n.t(`user-roles.${role}`)}
              </option>
            ))}
          </Input>
        </FormGroup>
        {isSubmitting ? (
          <OtoSpinner />
        ) : (
          <Button
            type="button"
            color="primary"
            disabled={!values.mark || !values['user-role']}
            onClick={submit}
          >
            {i18n.t('Submit')}
          </Button>
        )}
      </CardBody>
    </Card>
  );
};

export default RestaurantFeedback;
