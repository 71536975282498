import React from 'react';
import i18next from 'i18next';
import { Col, Row } from 'reactstrap';

import ArrayHelpers, { GroupedItem } from 'utils/array';
import { currency } from 'globals/currency';
import SimpleTableWithSorting, { generateColumn } from '../common/SimpleTableWithSorting';
import { IDelivery } from 'types/delivery';

const DeliveriesSummary = props => {
  const normalizedDeliveries = (props.deliveries || []).map((d: IDelivery) => ({
    ...d,
    driverId: d.user ? d.user.id : 0,
    driverName: d.user ? d.user.name : '-',
    payment_status: i18next.t(`payment-types.${d.payment_status}`), // TODO payment_type here
  }));

  const deliveriesColumns = React.useMemo(() => [
    generateColumn('driverName', { customHeader: i18next.t('delivery.driver') }),
    generateColumn('amount', { customHeader: i18next.t('delivery.day-summary.total-amount') }),
    generateColumn('payment_status', { customHeader: i18next.t('delivery.payment-type') }),
    generateColumn('price', { customHeader: i18next.t('delivery.day-summary.total-price'), shouldFixDecimal: true }),
  ], []);

  const statsByPayment = ArrayHelpers.groupArrayByField(normalizedDeliveries, 'payment_status', 'price'); // TODO payment_type use instead of payment_status
  const statsByDriver = ArrayHelpers.groupArrayByField(normalizedDeliveries, 'driverName', 'price'); // TODO payment_type use instead of payment_status

  const statsByBoth = ArrayHelpers.groupArrayByFields(normalizedDeliveries, ['payment_status', 'driverName'], 'price');

  const renderStatItem = (statItem: GroupedItem) => <div key={statItem.label}>
    <strong>{statItem.label}</strong>: {statItem.amount} ({statItem.sum} {currency})
  </div>;

  return <>
    <div className="row mx-0 mt-2 mb-3">
      <button className="btn btn-primary" type="button" onClick={props.reload}>{i18next.t('Refetch')}</button>
      {props.exportButton}
    </div>
    <Row>
      <Col xs={12}>
        <SimpleTableWithSorting data={statsByBoth} columns={deliveriesColumns} />
      </Col>
    </Row>
    <Row>
      <Col>
        <div className="h4">{i18next.t('delivery.day-summary.title-by-payment')}
          {statsByPayment.map(i => renderStatItem(i))}
        </div>
      </Col>
      <Col>
        <div className="h4">{i18next.t('delivery.day-summary.title-by-driver')}
          {statsByDriver.map(i => renderStatItem(i))}
        </div>
      </Col>
    </Row>
  </>
};

export default DeliveriesSummary;
