export enum EPaymentType {
  CASH = 'cash',
  CARD_ON_PLACE = 'card_on_place',
  CARD_ONLINE = 'card_online',
  BLIK = 'blik',
  TRANSFER = 'transfer',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAID = 'paid',
}

export enum EPaymentStatus {
  NEW = 'new',
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  PENDING_REFUND = 'pending_refund',
  REFUNDED = 'refunded',
}
