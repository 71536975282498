import React from 'react';
import PropTypes from 'prop-types';
import { HelpCircle } from 'react-feather';
import { addMinutes, formatDistanceStrict } from 'date-fns';

import Order from 'utils/orders';
import { YesNoIcons } from '../icons/IconSets';

const OrderWasOnTimeInfo = (props: { feedback: any }) => {
  const { feedback } = props;
  const { order } = feedback;

  if (feedback.has_arrived_on_time) {
    return <div>{YesNoIcons.YES}</div>;
  }

  if (feedback.has_arrived_on_time !== false) {
    return (
      <div>
        <HelpCircle />
      </div>
    );
  }

  const hasDataToCalculateHowLongPastTime =
    !!feedback.delivered_at_by_customer && !!Order.getPrepareTime(order);
  return (
    <div className="align-vertical">
      <span className="mr-2">{YesNoIcons.NO}</span>
      {hasDataToCalculateHowLongPastTime
        ? formatDistanceStrict(
            new Date(feedback.delivered_at_by_customer),
            new Date(
              addMinutes(
                new Date(order.confirmed_at),
                Order.getPrepareTime(order) as number
              )
            )
          )
        : ''}
    </div>
  );
};

OrderWasOnTimeInfo.propTypes = {
  feedback: PropTypes.object.isRequired,
};

export default OrderWasOnTimeInfo;
