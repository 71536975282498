import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col,} from 'reactstrap';

import NumberWidget from '../widgets/NumberWidget';

const getProgress = num => ({
  value: num || 0,
  label: 'Growth',
});

const AdminStatisticsOverviewComparison = props => {
  const { statistics } = props;
  return (
    <div>
      <Row>
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="New Customers"
            subtitle=""
            color="primary"
            number={statistics.customers.filter_new_count || 0}
            progress={getProgress(statistics.customers.new_increase)}
          />
        </Col>

        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Returning Customers"
            subtitle=""
            color="primary"
            number={statistics.customers.returning_count || 0}
            progress={getProgress(statistics.customers.returning_increase)}
          />
        </Col>

        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Orders"
            subtitle=""
            color="primary"
            number={statistics.orders.filter_count || 0}
            progress={getProgress(statistics.orders.increase)}
          />
        </Col>

        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Deliveries"
            subtitle=""
            color="primary"
            number={statistics.deliveries.filter_count || 0}
            progress={getProgress(statistics.deliveries.increase)}
          />
        </Col>

      </Row>
      <Row>
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Reservations Count"
            subtitle=""
            color="primary"
            number={statistics.reservations.filter_count || 0}
            progress={getProgress(statistics.reservations.increase)}
          />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Deliveries Profit"
            subtitle=""
            color="primary"
            number={statistics.deliveries.profit + ' zł' || 0}
            progress={getProgress(statistics.deliveries.profit_increase)}
          />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Reservations Profit"
            subtitle=""
            color="primary"
            number={statistics.reservations.revenue + ' zł' || 0}
            progress={getProgress(statistics.reservations.revenue_increase)}
          />
        </Col>
        <Col lg={3} md={6} sm={6} xs={12}>
          <NumberWidget
            title="Billable Reservations Count"
            subtitle=""
            color="primary"
            number={statistics.reservations.billable_count || 0}
            progress={getProgress(0)}
          />
        </Col>
      </Row>
    </div>
  );
};

AdminStatisticsOverviewComparison.propTypes = {
  statistics: PropTypes.exact({
    deliveries: PropTypes.object.isRequired,
    orders: PropTypes.object.isRequired,
    reservations: PropTypes.object.isRequired,
  }).isRequired,
};

export default AdminStatisticsOverviewComparison;