import React from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { addDays } from 'date-fns';
import type { ConnectedProps } from 'react-redux';

import APIService from 'services/api';
import { toDateInputValue } from 'utils/date-time';
import { getArrayWithUpdatedObject, getArrayWithoutObject } from 'utils/array';
import { logError, LOG_BAG } from 'utils/log';
import {
  setPromoCodesSuccess,
  setPromoCodesError,
  setPromoCodesLoading,
} from 'store/promoCodes';
import { ModalTypes } from 'config';
import OtoSpinner from 'app/components/common/OtoSpinner';
import PromoCodesList from 'app/components/promocodes/PromoCodesList';
import withSetModal, { TSetModalFunction } from '../containers/WithSetModal';
import {
  promoCodeValueOptions,
  promoCodeMinCartOptions,
} from 'app/components/promocodes/PromoCodeEditModal';
import {  UsageType } from 'types/promo-code';
import type { IPromoCode } from 'types/promo-code';
import type { IAppState } from 'store/main';

export const DEFAULT_PROMO_CODE = {
  code: '',
  discount_type: 'value',
  discount: promoCodeValueOptions[0],
  usage_type: UsageType.Multiple,
  description: '',
  activated_at: toDateInputValue(new Date()),
  deactivated_at: toDateInputValue(addDays(new Date(), 30)),
  min_cart_amount: promoCodeMinCartOptions[2],
};

type PromoCodesPageProps = PropsFromRedux & {
  setModal: TSetModalFunction;
};

type PromoCodesPageState = {
  editingPromoCode: IPromoCode | null;
};

const COMPONENT_NAME = 'PromoCodesPage';

class PromoCodesPage extends React.PureComponent<
  PromoCodesPageProps,
  PromoCodesPageState
> {
  static displayName = COMPONENT_NAME;
  staticgetDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  state = {
    editingPromoCode: null,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.getRestaurantPromoCodes();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getRestaurantPromoCodes = () => {
    this.props.setPromoCodesLoading();
    APIService.get(`/restaurants/promo-codes`)
      .then((promoCodes) => {
        this._isMounted && this.props.setPromoCodesSuccess(promoCodes);
      })
      .catch((e) => {
        toast.error('Błąd pobierania promo kodów');
        this.props.setPromoCodesError(e);
        logError('Error getting promo codes list', e);
      });
  };

  createPromoCode = () =>
    this.props.setModal(
      {
        confirm: this.handlePromoCodeSave,
        confirmColor: 'success',
        confirmText: 'Zapisz',
        cancelText: 'Anuluj',
        modalSpecificProps: {
          // @TODO fixme it was like this before TS migration
          promoCode: DEFAULT_PROMO_CODE as unknown as IPromoCode,
        },
      },
      ModalTypes.PROMO_CODE_MODAL
    );

  editPromoCode = (promoCode) =>
    this.props.setModal(
      {
        confirm: this.handlePromoCodeSave,
        confirmColor: 'success',
        confirmText: 'Zapisz',
        cancelText: 'Anuluj',
        modalSpecificProps: { promoCode },
      },
      ModalTypes.PROMO_CODE_MODAL
    );

  removePromoCode = (promoCode) => {
    const { code, id } = promoCode;
    const url = `/restaurants/promo-codes/${id}`;

    APIService.del(url)
      .then(() => {
        this.props.setPromoCodesSuccess(
          getArrayWithoutObject(this.props.promoCodes || [], promoCode)
        );
        toast.success(`Kod ${code} został usunięty`);
      })
      .catch((e) => {
        toast.error(`Wystąpił błąd podczasu usuwania kodu rabatowego ${code}`);
        this.props.setPromoCodesError(e);
        logError('Promo code delete error', e);
      });
  };

  handlePromoCodeSave = (updatedPromoCode, closeModal) => {
    const newPromoCodes = getArrayWithUpdatedObject(
      this.props.promoCodes || [],
      updatedPromoCode
    );
    this.props.setPromoCodesSuccess(newPromoCodes);
    this.setState({
      editingPromoCode: null,
    });
    closeModal();
  };

  cancelEditing = () => {
    this.setState({
      editingPromoCode: null,
    });
  };

  render() {
    const { promoCodesLoading, promoCodes } = this.props;
    return (
      <>
        <nav className="products-menu-navbar mb-3">
          <Button type="button" color="success" onClick={this.createPromoCode}>
            Stwórz nowy kod
          </Button>
        </nav>
        {promoCodesLoading ? (
          <OtoSpinner center />
        ) : (
          <PromoCodesList
            promoCodes={promoCodes}
            onCreate={this.createPromoCode}
            onEdit={this.editPromoCode}
            onDelete={this.removePromoCode}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => ({
  promoCodes: state.promoCodes.promoCodes,
  promoCodesLoading: state.promoCodes.promoCodesLoading,
  promoCodesError: state.promoCodes.promoCodesError,
});
const mapDispatchToProps = {
  setPromoCodesSuccess,
  setPromoCodesError,
  setPromoCodesLoading,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const ConnectedPromoCodesPage = compose(connector, withSetModal)(
  PromoCodesPage
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default ConnectedPromoCodesPage;
