import React from 'react';
import memoizeOne from 'memoize-one';
import 'chart.js/auto'; // TODO make this tree-shakeable https://react-chartjs-2.netlify.app/docs/migration-to-v4/#tree-shaking
import { Line } from 'react-chartjs-2';

const dataTemplate = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    },
  ],
};

const generateChartData = memoizeOne(
  (chartLabel: string, labels: Array<string>, data: Array<any>) => {
    const chartData = {
      ...dataTemplate,
      label: chartLabel,
      labels,
      datasets: [
        {
          ...dataTemplate.datasets[0],
          data,
        },
      ],
    };
    return chartData;
  }
);

interface IProps {
  className?: string;
  header: string;
  labels: Array<string>;
  data: any[];
}

export const OtoLineChart: React.FC<IProps> = (props) => {
  const { className = '', header, data, labels } = props;
  const chartData = generateChartData(header, labels, data);
  return (
    <div className={className}>
      {!!header && (
        <div className="header">
          <h1 className="title h3">{header}</h1>
        </div>
      )}
      <Line
        data={chartData}
        options={{
          scales: {
            y: {
              beginAtZero: true,
              // precision: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default OtoLineChart;
