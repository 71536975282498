import PropTypes from 'prop-types';

export const discountCategoryShape = PropTypes.exact({
  type: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
});

export const discountProductShape = PropTypes.exact({
  type: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
});
