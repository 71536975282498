import React from 'react';
import { Alert } from 'reactstrap';

import { NormalizedRestaurant } from 'types';
import Shapes from 'shapes/main';
import { hasAnyOrders } from 'utils/restaurant';

const PushNotificationErrorAlert = (props: {
  restaurant: NormalizedRestaurant;
}) => {
  if (!hasAnyOrders(props.restaurant)) {
    return null;
  }
  return (
    <Alert color="danger">
      <p>
        Wystąpił błąd podczas próby ustawień notyfikacji. Najprawdopodobniej
        powodem jest przeglądarka, albo ustawienia urządzenia.
      </p>
      <p>
        Możesz używać aplikacji, edytować menu, lub dokonywać innych zmian, ale
        dla sprawnego otzymywania sygnału dźwiękowego podczas nowego zamówienia
        aplikacja powinna być cały czas aktywna.
      </p>
    </Alert>
  );
};

PushNotificationErrorAlert.propTypes = {
  restaurant: Shapes.restaurantShape.isRequired,
};

export default PushNotificationErrorAlert;
