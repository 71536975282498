import React from 'react';
import { Alert } from 'reactstrap';
import type { PageAlertContextType } from './PageAlertContext';

export type PageAlertProps = {
  alert: PageAlertContextType['alert'];
  onClose: PageAlertContextType['closeAlert'];
};

const PageAlert: React.FC<PageAlertProps> = ({ alert, onClose }) => {
  if (!alert) {
    return null;
  }
  return (
    <Alert
      color={alert.type}
      className="page-level-alert"
      fade={false}
      isOpen={alert !== null}
      toggle={onClose}
    >
      {alert.message}
    </Alert>
  );
};

export default PageAlert;
