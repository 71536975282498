import React from 'react';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { getPaymentMethodLabel } from 'utils/orders';
import { PaymentOptionIcons } from '../../icons/IconSets';
import type { IOrder } from 'types';

type OrderPaymentTypeProps = {
  order: IOrder;
};

export const OrderPaymentType: React.FC<OrderPaymentTypeProps> = ({
  order,
}) => {
  return (
    <div>
      {PaymentOptionIcons[order.payment_type]}{' '}
      {i18next.t(`payment-types.${getPaymentMethodLabel(order.payment_type)}`)}
    </div>
  );
};

OrderPaymentType.propTypes = {
  order: Shapes.orderShape.isRequired,
};

OrderPaymentType.displayName = 'OrderPaymentType';

export default OrderPaymentType;
