export enum OTO_PRODUCTS {
  foodeli_deliveries = 'foodeli_deliveries',
  restaurant_deliveries = 'deliveries', // @TODO potentially change this key in database as well
  exchange_deliveries = 'exchange_deliveries',
  robot_deliveries = 'robot_deliveries',
  szamaexpress_deliveries = 'szamaexpress_deliveries',

  orders = 'orders',
  orders_list = 'orders_list',
  lesznoje_orders = 'lesznoje_orders_list',
  ostrowje_orders = 'ostrowje_orders_list',
  lubje_orders = 'lubje_orders_list',

  reservations = 'reservations',
  reservations_list = 'reservations_list',

  website = 'website',
  iframe = 'iframe',
  mobile_app = 'mobile_app',

  marketing = 'marketing',
  printer = 'printer',
  pepsi_lunch = 'pepsi_lunch',
}

export const OTO_PRODUCTS_GROUPS = {
  anyDeliveriesProduct: [
    OTO_PRODUCTS.foodeli_deliveries,
    OTO_PRODUCTS.restaurant_deliveries,
    OTO_PRODUCTS.exchange_deliveries,
    OTO_PRODUCTS.robot_deliveries,
    OTO_PRODUCTS.szamaexpress_deliveries,
  ],
};
