import React from 'react';
import PropTypes from 'prop-types';

export type IOptionType = string | number | IOption;

interface IOption {
  label: string;
  value: any;
}

export const OtoSelectOption: React.FC<{ opt: IOptionType }> = ({ opt }) => {
  if (typeof opt === 'object') {
    return (
      <option key={opt.value} value={opt.value}>
        {opt.label}
      </option>
    );
  }
  return (
    <option key={opt} value={opt}>
      {opt}
    </option>
  );
};

export const otoSelectOptionShape = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
  }).isRequired,
]);

OtoSelectOption.propTypes = {
  opt: otoSelectOptionShape.isRequired,
};

export default OtoSelectOption;
