import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import { Edit, ArrowRightCircle, ArrowLeftCircle } from 'react-feather';
import i18 from 'i18next';

import {
  hasProductZeroPrice,
  isChoiceFieldWithoutValue,
} from 'utils/menu-edit';
import Shapes from 'shapes/main';
import OtoToggle from '../common/OtoToggle';
import OtoButtons from '../common/OtoButtons';
import { MenuItem } from 'types';

import './ProductCard.scss';

interface MenuProductCardProps {
  allowMoveNext: boolean;
  allowMovePrev: boolean;
  onEdit: null | (() => void);
  onDelete: null | (() => void);
  onDisabledToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onMoveBackwardClick: null | (() => void);
  onMoveForwardClick: null | (() => void);
  product: MenuItem;
}

export const MenuProductCard: React.FC<MenuProductCardProps> = (props) => {
  const {
    allowMoveNext,
    allowMovePrev,
    onDisabledToggle,
    onMoveBackwardClick,
    onMoveForwardClick,
    product,
    onEdit,
    onDelete,
  } = props;
  const hasZeroPrice = hasProductZeroPrice(product);
  const hasIncorrectChoiceFields =
    (product.additionalFields || []).filter(isChoiceFieldWithoutValue).length >
    0;
  const className = `product-card ${
    hasZeroPrice || hasIncorrectChoiceFields ? 'product-card--invalid' : ''
  }`;
  return (
    <Card className={className}>
      <CardHeader className="pt-2 px-2">
        {allowMovePrev && onMoveBackwardClick && (
          <ArrowLeftCircle
            className="cursor-pointer mr-2"
            // @ts-ignore
            title="przenieś produkt wstecz"
            onClick={onMoveBackwardClick}
          />
        )}
        {product.name}
        {allowMoveNext && onMoveForwardClick && (
          <ArrowRightCircle
            className="cursor-pointer ml-2"
            // @ts-ignore
            title="przenieś produkt dalej"
            onClick={onMoveForwardClick}
          />
        )}
      </CardHeader>
      <CardBody className="p-2">{props.children}</CardBody>
      <CardFooter className="d-flex align-items-center flex-wrap p-2">
        <OtoToggle
          checked={!product.isDisabled}
          name={product.name}
          value="true"
          onChange={onDisabledToggle}
        />
        {onEdit && (
          <Button
            color="link"
            className="d-flex align-items-center"
            onClick={onEdit}
          >
            <Edit size="16" />
            {i18.t('Edit')}
          </Button>
        )}
        {onDelete && (
          <OtoButtons.DeleteButton onClick={onDelete}>
            {i18.t('Remove')}
          </OtoButtons.DeleteButton>
        )}
        {hasZeroPrice && (
          <div className="w-100 font-weight-bold text-danger mt-2">
            {i18.t('menu-edit.product-errors.zero-price')}
          </div>
        )}
        {hasIncorrectChoiceFields && (
          <div className="w-100 font-weight-bold text-danger mt-2">
            {i18.t('menu-edit.product-errors.incorrect-additional-fields')}
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

MenuProductCard.propTypes = {
  allowMoveNext: PropTypes.bool.isRequired,
  allowMovePrev: PropTypes.bool.isRequired,
  onDisabledToggle: PropTypes.func.isRequired,
  onMoveBackwardClick: PropTypes.func.isRequired,
  onMoveForwardClick: PropTypes.func.isRequired,
  product: Shapes.menuItemShape.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default MenuProductCard;
