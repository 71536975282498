import React from 'react';
import { Image, MapPin, Phone } from 'react-feather';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import CuisineListSelect from 'app/components/settings/CuisineListSelect';
import CommonSection from 'app/components/common/CommonSection';
import PreviewLink from 'app/views/ViewPreviewLink';
import Shapes from 'shapes/main';
import OpeningHoursForm, {
  OpeningHoursType,
} from 'app/components/settings/OpeningHoursForm';
import { IRestaurantCompany, NormalizedRestaurant } from 'types';
import UrlFetcher from '../containers/UrlFetcher';
import CompanyDetails from 'app/components/companies/CompanyDetails';
import { selectResourcesPath } from 'store/tenants';
import { selectRestaurant, updateRestaurant } from 'store/restaurant';
import type { PageComponent } from '../types-global';
import { selectIsAnyAdmin } from 'store/app';
import GeneralSettingsForm from 'app/components/settings/GeneralSettingsForm';

const RestaurantSettingsPage: PageComponent<{}> = () => {
  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant;
  const images = restaurant.images || [];
  const resourcesPath = useAppSelector(selectResourcesPath);
  const isAnyAdmin = useAppSelector(selectIsAnyAdmin);
  const dispatch = useAppDispatch();

  return (
    <div>
      <PreviewLink className="offset-md-1 mb-3" restaurant={restaurant} />
      <CuisineListSelect
        handleChange={(newCuisineLabel) => console.warn(newCuisineLabel)}
        values={restaurant.cuisine_label}
      />
      <OpeningHoursForm type={OpeningHoursType.RESTAURANT} />
      <OpeningHoursForm type={OpeningHoursType.DELIVERY} />
      <CommonSection header="Dane kontaktowe">
        <div className="my-2">
          <Phone /> {restaurant.phone}
        </div>
        <div>
          <MapPin /> {restaurant.location}
        </div>
      </CommonSection>
      <CommonSection
        header={
          <span>
            <Image /> Obrazki
          </span>
        }
      >
        <strong className="d-block">Na liście restauracji</strong>
        {images.map((img, index) => (
          <img
            key={img.src}
            className="mw-200 m-2 ml-0"
            src={`${resourcesPath}${img.src}`}
            alt={`Zdjęcie ${index + 1}`}
          />
        ))}
        {!images.length && <p>Brak zdjęć</p>}

        <hr />

        <strong className="d-block">
          Zdjęcia dań/wnętrza na podstronie menu
        </strong>
        {(restaurant.hero_images || []).map((src) => (
          <img
            key={src}
            className="mw-200 m-2 ml-0"
            src={`${resourcesPath}${src}`}
            alt=""
          />
        ))}
        {!restaurant.hero_images?.length && <p>Brak zdjęć</p>}

        <hr />

        <GeneralSettingsForm
          canEditLogo={isAnyAdmin}
          restaurant={restaurant}
          resourcesPath={resourcesPath}
          onSubmit={(payload) =>
            dispatch(
              updateRestaurant({
                id: restaurant.id,
                payload,
              })
            ) as any
          }
        />

        <hr />

        <UrlFetcher<IRestaurantCompany>
          onError="show-component"
          url={`/restaurants/${restaurant.id}/company`}
        >
          {([company]) => (
            <>
              <strong className="d-block">Dane firmy:</strong>
              {company ? <CompanyDetails company={company} /> : 'Brak'}
            </>
          )}
        </UrlFetcher>
      </CommonSection>
    </div>
  );
};

export default RestaurantSettingsPage;

RestaurantSettingsPage.propTypes = {
  restaurant: Shapes.restaurantShape.isRequired,
};

RestaurantSettingsPage.url = '/settings/general';
RestaurantSettingsPage.navName = 'Dane restauracji';
