import React from 'react';
import { Button } from 'reactstrap';

import { IRobot } from 'types';
import UrlFetcher from '../../containers/UrlFetcher';
import RobotsList from 'app/components/robots/RobotsList';
import RobotsMap from 'app/components/robots/RobotsMap';

const FleetManagementPage = () => {
  return (
    <>
      <UrlFetcher<IRobot[]>
        url="/robots?include=location,user"
        showDataWhileLoading
      >
        {([robots, urlFetcherProps]) => (
          <>
            <RobotsList
              header="Wszystkie roboty"
              robots={robots}
              showUserColumn
            />
            <Button
              type="button"
              color="primary"
              onClick={urlFetcherProps.reloadData}
            >
              Refresh locations
            </Button>
            <RobotsMap className="mt-2" robots={robots} />
          </>
        )}
      </UrlFetcher>
    </>
  );
};

FleetManagementPage.url = '/admin/fleet-management';
FleetManagementPage.navName = 'admin.fleet-management';

export default FleetManagementPage;
