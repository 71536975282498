import React from 'react';
import PropTypes from 'prop-types';
import { connect, ConnectedProps } from 'react-redux';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form } from 'reactstrap';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { checkAppVersion } from 'services/auth';
import { whatHasChanged } from 'utils/general';
import { logActivity, formatChangedKeys, LOG_BAG } from 'utils/log';
import { selectIsAnyAdmin } from 'store/app';
import {
  checkIfOrdersConfigUpToDate,
  updateOrdersConfigHash,
} from 'store/hash-updates';
import {
  loadRestaurant,
  selectOrdersConfig,
  selectRestaurant,
  updateOrdersConfig,
  updateRestaurant,
} from 'store/restaurant';
import OtoSpinner from 'app/components/common/OtoSpinner';
import PreviewLink from 'app/views/ViewPreviewLink';
import DeliverySettingsForm from 'app/components/settings/DeliverySettingsForm';
import ShoppingCartSettingsForm from 'app/components/settings/ShoppingCartSettingsForm';
import PaymentOptionsForm from 'app/components/settings/PaymentOptions';
import DeliveryMapForm from 'app/components/settings/DeliveryMapForm';
import UpdatesProvider from 'app/containers/UpdatesProvider';
import ErrorBoundary from 'app/containers/ErrorBoundary';
import { pick } from 'utils/object';
import { IAppState } from 'store/main';
import { DeliveryConfig, NormalizedRestaurant, OrdersConfig } from 'types';
import { EDeliveryType, UpdateTarget } from 'enums';
import { activePaymentOptionKeys } from 'utils/payments';

import 'app/components/settings-form.scss';
import Shapes from 'shapes/main';
import { hasRobotDeliveries, isBombardino } from 'utils/restaurant';
import ViewRestaurantDeliveriesPricings from 'app/views/ViewRestaurantDeliveriesPricings';
import OtoAlert from 'app/components/common/OtoAlert';

const COMPONENT_NAME = 'OrderSettingsForm';

export type OrdersConfigFormValues = OrdersConfig & {
  lat: number;
  lng: number;
};

type TPropsWithoutFormik = PropsFromRedux;

type TProps = FormikProps<OrdersConfigFormValues> & TPropsWithoutFormik;

interface IState {
  loading: boolean;
  initFinished: boolean;
}

class OrderSettingsForm extends React.PureComponent<TProps, IState> {
  state = {
    loading: true,
    initFinished: false,
  };

  _isMounted = false;

  static propTypes = {
    ordersConfig: Shapes.ordersConfigShape.isRequired,
    history: PropTypes.object.isRequired,
  };

  static getDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  componentDidMount() {
    this._isMounted = true;
    checkAppVersion();
  }

  init = () => {
    this.mapConfigToFields();
    this._isMounted &&
      this.setState({
        loading: false,
        initFinished: true,
      });
  };

  mapConfigToFields = () => {
    const ordersConfig = this.props.ordersConfig || { delivery: {} };
    this.props.setValues({
      ...this.props.values,
      ...ordersConfig,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.ordersConfig !== this.props.ordersConfig) {
      this.mapConfigToFields();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <OtoSpinner center />
          {!this.state.initFinished && (
            <UpdatesProvider
              restaurant={this.props.restaurant}
              target={UpdateTarget.OrdersConfig}
              onInit={this.init}
            />
          )}
        </>
      );
    }
    const {
      isAdmin,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleSubmit,
      restaurant,
      values,
    } = this.props;

    return (
      <>
        <PreviewLink className="offset-md-1 mb-3" restaurant={restaurant} />
        {isAdmin && (
          <ViewRestaurantDeliveriesPricings restaurant={restaurant} />
        )}
        {(!restaurant.lat || !restaurant.lng) && (
          <OtoAlert color={'danger'} className={'col-md-10 offset-md-1'}>
            {i18next.t(
              'order-settings-form.alert-restaurant-has-missing-coordinates'
            )}
          </OtoAlert>
        )}
        <Form onSubmit={handleSubmit} className="order-settings-form">
          <ShoppingCartSettingsForm
            isAdmin={isAdmin}
            loading={isSubmitting}
            handleChange={handleChange}
            values={values}
          />
          <DeliverySettingsForm
            setFieldValue={setFieldValue}
            showDriveThrough={isAdmin || isBombardino(restaurant)}
            showRobot={isAdmin || hasRobotDeliveries(restaurant)}
            loading={isSubmitting}
            isAdmin={isAdmin}
            handleChange={handleChange}
            values={values}
          />
          <ErrorBoundary componentName={'DeliveryMapForm'}>
            <DeliveryMapForm
              handleChange={handleChange}
              loading={isSubmitting}
              setFieldValue={setFieldValue}
              values={values}
              isAdmin={isAdmin}
              restaurant={restaurant}
            />
          </ErrorBoundary>
          <PaymentOptionsForm
            setFieldValue={setFieldValue}
            loading={isSubmitting}
            values={values}
          />
        </Form>
      </>
    );
  }
}

const convertFalsyInputValueToZero = (areaOptions) => {
  return areaOptions.map((item) => {
    if (!item?.price) item.price = 0;
    if (!item?.minCartAmount) item.minCartAmount = 0;
    if (!item?.freeDeliveryFrom) item.freeDeliveryFrom = 0;
    return item;
  });
};

const checkNumberOfInvalid = (areaOptions, property) => {
  return areaOptions.filter((item) => !item[property] && item[property] !== 0)
    .length;
};

const checkValidAreaOptions = (areaOptions) => {
  return [
    checkNumberOfInvalid(areaOptions, 'price'),
    checkNumberOfInvalid(areaOptions, 'minCartAmount'),
    checkNumberOfInvalid(areaOptions, 'freeDeliveryFrom'),
  ].every((el) => el === 0);
};

const EnhancedOrderSettingsForm = withFormik<TProps, OrdersConfigFormValues>({
  mapPropsToValues: ({ restaurant }): OrdersConfigFormValues =>
    getDefaultOrdersConfig(restaurant),

  handleSubmit: (
    valuesWithCoords: OrdersConfigFormValues,
    {
      setSubmitting,
      props,
    }: FormikBag<TPropsWithoutFormik, OrdersConfigFormValues>
  ) => {
    LOG_BAG.addLogAction(`handleSubmit (save clicked) from ${COMPONENT_NAME}`);
    let isConvertedToZero = false;

    const { ordersConfig, restaurant, updateRestaurant } = props;
    const { lat, lng, ...values } = valuesWithCoords;

    const haveCoordsChanged = lat !== restaurant.lat || lng !== restaurant.lng;

    if (haveCoordsChanged) {
      updateRestaurant({
        payload: {
          lat,
          lng,
        },
        id: restaurant.id,
      });
    }

    if (!checkValidAreaOptions(values.delivery.areaOptions || [])) {
      convertFalsyInputValueToZero(values.delivery.areaOptions);
      isConvertedToZero = true;
    }

    if (isConvertedToZero) {
      alert(
        'Jedna lub więcej stref dostaw nie ma podanej ceny dostawy, lub minimalnej wartości koszyka. Domyślne wartości zostanią ustawione na 0 zł w danej strefie.'
      );
    }

    const keysThatChanged = whatHasChanged<OrdersConfig>(ordersConfig, values, [
      'lat',
      'lng',
      'deliveryHours',
    ]);

    if (keysThatChanged.length === 0) {
      if (!haveCoordsChanged) {
        toast.error(
          'Ustawienia nie zostały zaktualizowane - nie dokonano żadnych zmian'
        );
      }
      setSubmitting(false);
      return;
    }

    const currentDelivery = ordersConfig?.delivery;

    if (keysThatChanged.includes('delivery') && !!currentDelivery) {
      const newDelivery = values.delivery;
      if (
        (currentDelivery.area && !newDelivery.area) ||
        (currentDelivery.areaOptions && !newDelivery.areaOptions) ||
        (currentDelivery.areaOptions?.length && !newDelivery.areaOptions.length)
      ) {
        LOG_BAG.logImportantActivity(
          `area could be overwritten for ${restaurant?.name})(${restaurant?.id})`
        );
        toast.error(
          'Brak możliwości zapisu - wystąpił błąd, powodujący możliwe nadpisanie stref dostaw. Skontaktuj się z zespołem technicznym w celu naprawienia usterki. Kontakt znajdziesz w zakładce Pomoc.',
          { autoClose: false }
        );
        return false;
      }
    }
    const ordersConfigThatChanged = pick(values, ...keysThatChanged);

    const onSuccess = () => {
      logActivity(
        `Ustawienia zostały zaktualizowane. ${formatChangedKeys(
          keysThatChanged,
          ordersConfig,
          ordersConfigThatChanged
        )}`
      );
      toast.success('Dane ustawień zostały pomyślnie zapisane');
    };
    const payload = values;

    return props
      .updateOrdersConfig(payload, props.restaurant, onSuccess)
      .finally(() => setSubmitting(false));
  },
  displayName: COMPONENT_NAME,
})(OrderSettingsForm);

const mapStateToProps = (state: IAppState) => ({
  isAdmin: selectIsAnyAdmin(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
  ordersConfig: selectOrdersConfig(state) as OrdersConfig,
});

const mapDispatchToProps = {
  checkIfOrdersConfigUpToDate,
  loadRestaurant,
  updateOrdersConfigHash,
  updateOrdersConfig,
  updateRestaurant,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EnhancedOrderSettingsForm);

const defaultDeliveryHourPair = {
  open: { hour: 12, min: 0 },
  close: { hour: 20, min: 0 },
  isClosed: false,
};

export function getDefaultOrdersConfig(
  restaurant: NormalizedRestaurant
): OrdersConfigFormValues {
  const deliveryConfig: DeliveryConfig = {
    area: null,
    areaOptions: [],
    dynamicDeliveryPricing: false,
    maxKmDistance: 0,
    // @ts-ignore deprecated property
    minCartAmount: '',
    // @ts-ignore deprecated property
    price: '',
    // @ts-ignore deprecated property
    freeDeliveryFrom: '',
  };

  return {
    alertText: '',
    allowOrders: true,
    allowOrdersChangedAt: null,
    allowOrdersDisabledUntil: null,
    delivery: deliveryConfig,
    askForCutlery: false,
    deliveryTime: 90,
    takeoutTime: 30,
    dynamicDeliveryText: '',
    deliveryHours: Array.from(Array(7)).map(() => defaultDeliveryHourPair),
    lat: restaurant.lat,
    lng: restaurant.lng,
    discountPercent: 0,
    discountPercentIfTakeout: 0,
    roundDiscount: false,
    discountText: '',
    discountRules: [], // todo only discount rules should remain, no categories nor products
    allowedPaymentOptionKeys: activePaymentOptionKeys, // @TODO check if this is still used
    allowedDeliveryPaymentOptionKeys: activePaymentOptionKeys,
    allowedTakeoutPaymentOptionKeys: activePaymentOptionKeys,
    allowedDeliveryOptionKeys: [
      EDeliveryType.USUAL,
      EDeliveryType.CONTACTLESS,
      EDeliveryType.TAKEOUT,
    ],
  };
}
