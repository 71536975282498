import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import ModalContext from './ModalContext';
import ViewOrderModal from 'app/views/ViewOrderModal';
import {
  changeOrderRestaurant,
  selectAllOrders,
  selectOrdersLoading,
  selectUpdatingOrders,
  updateOrderStatus,
} from 'store/orders';
import { selectRestaurant } from 'store/restaurant';
import { isPending } from 'utils/orders';
import { OrderSource } from 'app/components/orders/order-parts/OrderSource';
import { IAppState } from 'store/main';
import { IOrder, NormalizedRestaurant } from 'types';
import OpenOrderByIdInParams from './OpenOrderByIdInParams';

type PendingOrdersContainerProps = PropsFromRedux;
type PendingOrdersContainerState = {
  closedPendingOrders: number[];
};

class PendingOrdersContainer extends React.Component<
  PendingOrdersContainerProps,
  PendingOrdersContainerState
> {
  state: PendingOrdersContainerState = { closedPendingOrders: [] };

  closeModal = (order) =>
    this.setState({
      closedPendingOrders: [...this.state.closedPendingOrders, order.id],
    });

  changeOrderRestaurant = (order, newRestaurantId) => {
    const onSuccess = () => this.closeModal(order);
    return this.props.changeOrderRestaurant(
      order,
      this.props.restaurant.id,
      newRestaurantId,
      { onSuccess }
    );
  };

  updateOrderStatus = (order, status, extra) => {
    const onSuccess = () => this.closeModal(order);
    this.props.updateOrderStatus(order, status, this.props.restaurant, {
      extra,
      onSuccess,
    });
  };

  render() {
    const { closedPendingOrders } = this.state;
    const notAlreadyClosed = (order: IOrder) =>
      !closedPendingOrders.includes(order.id);
    const pendingOrders = this.props.orders
      .filter(isPending)
      .filter(notAlreadyClosed);
    return (
      <>
        <OpenOrderByIdInParams />
        {pendingOrders.length ? (
          <ModalContext.Consumer>
            {(modalBag) => (
              <ViewOrderModal
                order={pendingOrders[pendingOrders.length - 1] as IOrder}
                changeOrderRestaurant={this.changeOrderRestaurant}
                onUpdateOrderStatus={this.updateOrderStatus}
                onClose={this.closeModal}
                setModal={modalBag.setModal}
                renderOrderSource={this.renderOrderSource}
                restaurant={this.props.restaurant}
                updatingOrders={this.props.updatingOrders}
              />
            )}
          </ModalContext.Consumer>
        ) : null}
      </>
    );
  }

  renderOrderSource = (order: IOrder) => <OrderSource order={order} />;
}

const mapStateToProps = (state: IAppState) => ({
  orders: selectAllOrders(state),
  ordersLoading: selectOrdersLoading(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
  updatingOrders: selectUpdatingOrders(state),
});
const mapDispatchToProps = { changeOrderRestaurant, updateOrderStatus };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PendingOrdersContainer);
