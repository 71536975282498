import React from 'react';
import memoizeOne from 'memoize-one';
import { addDays } from 'date-fns';

import { fixDecimal } from 'utils/general';
import { toDateInputValue } from 'utils/date-time';
import OtoLineChart from '../common/OtoLineChart';

const generateChartData = memoizeOne(
  (ordersData: OrdersLineChartProps['ordersData'], startDate, endDate) => {
    const dates = getDates(startDate, endDate).map((d) => toDateInputValue(d));
    return {
      labels: dates,
      data: dates.map((day) =>
        fixDecimal(ordersData[day] ? ordersData[day].orders_sum : 0)
      ),
    };
  }
);

type OrdersLineChartProps = {
  className?: string;
  ordersData: Record<string, { orders_sum: number }>;
  startDate: Date;
  endDate: Date;
};

const OrdersLineChart: React.FC<OrdersLineChartProps> = (props) => {
  const { className = '', ordersData, startDate, endDate } = props;
  const { labels, data } = generateChartData(ordersData, startDate, endDate);
  return (
    <OtoLineChart
      className={className}
      header="Obroty z zamówień"
      data={data}
      labels={labels}
    />
  );
};

function getDates(startDate, stopDate) {
  let dateArray: Date[] = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export default OrdersLineChart;
