import React from 'react';

import ViewRestaurantIntegrations from 'app/components/integrations/ViewRestaurantIntegrations';
import UrlFetcher from '../containers/UrlFetcher';
import withSetModal from '../containers/WithSetModal';
import { LOG_BAG } from 'utils/log';
import { useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';
import { IIntegration } from 'types'

const RestaurantIntegrationsPage = ({ setModal }) => {
  const restaurant = useAppSelector(selectRestaurant)

  if (!restaurant) {
    return null;
  }

  return (
    <>
      <div className="container flex-column">
        <h1>Integracje</h1>
        <UrlFetcher<IIntegration[]>
          url={`/restaurants/${restaurant.id}/integrations`}
        >
          {([integrations, urlFetcherProps]) => (
            <ViewRestaurantIntegrations
              integrations={integrations}
              urlFetcherProps={urlFetcherProps}
              restaurant={restaurant}
              setModal={setModal}
            />
          )}
        </UrlFetcher>
      </div>
    </>
  );
};

const COMPONENT_NAME = 'RestaurantIntegrationsPage';
RestaurantIntegrationsPage.displayName = COMPONENT_NAME;
RestaurantIntegrationsPage.getDerivedStateFromError =
  LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

const EnhancedRestaurantIntegrationsPage = withSetModal(RestaurantIntegrationsPage);

EnhancedRestaurantIntegrationsPage.navName = 'Integracje';
EnhancedRestaurantIntegrationsPage.url = '/settings/integrations';

export default EnhancedRestaurantIntegrationsPage;
