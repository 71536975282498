import React from 'react';
import { Button } from 'reactstrap';
import FA from 'react-fontawesome';

type SidebarToggleButtonProps = {
  isSidebarCollapsed: boolean;
  onToggleSidebarClick: () => void;
};

const SidebarToggleButton: React.FC<SidebarToggleButtonProps> = ({
  isSidebarCollapsed,
  onToggleSidebarClick,
}) => {
  const chevronClassName = isSidebarCollapsed
    ? 'is-collapsed'
    : 'is-not-collapsed';
  const screenReaderLabel = isSidebarCollapsed
    ? 'Expand Sidebar Navigation'
    : 'Collapse Sidebar Navigation';
  return (
    <Button
      onClick={onToggleSidebarClick}
      className={`m-r sidebar-toggle ${chevronClassName}`}
      aria-label={screenReaderLabel}
    >
      <FA name={'chevron-left'} />
    </Button>
  );
};

export default SidebarToggleButton;
