import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';

import {
  checkIfCustomRulesUpToDate,
  checkIfMenuUpToDate,
  checkIfOrdersConfigUpToDate,
  checkIfReservationConfigUpToDate,
  labelsByUpdateTarget,
  updateCustomRulesHash,
  updateMenuHash,
  updateOrdersConfigHash,
  updateReservationConfigHash,
} from 'store/hash-updates';
import { loadRestaurant } from 'store/restaurant';
import { NormalizedRestaurant } from 'types/restaurants';
import { UpdateTarget } from 'enums';
import { CenterCardSpinner } from 'app/components/common';

interface UpdatesProviderOwnProps {
  restaurant: NormalizedRestaurant;
  target: UpdateTarget;
  onInit?: ({ wasUpToDate }: { wasUpToDate: boolean }) => void;
}

type UpdatesProviderProps = UpdatesProviderOwnProps & PropsFromRedux;

const UpdatesProvider: React.FC<UpdatesProviderProps> = (props) => {
  const { children, restaurant, target, onInit } = props;
  const label = labelsByUpdateTarget[target];
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (target === UpdateTarget.CustomRules) {
      setIsLoading(true);
      props
        .checkIfCustomRulesUpToDate(restaurant)
        .then(({ isUpToDate }) => {
          if (!isUpToDate) {
            toast.info(`Znaleziono nowszą wersję ${label}, pobieram zmiany`);
            return props
              .loadRestaurant(restaurant.id, {
                allowCache: false,
                setLoading: false,
              })
              .then(() => props.updateCustomRulesHash(restaurant))
              .then(() => onInit && onInit({ wasUpToDate: false }));
          } else {
            onInit && onInit({ wasUpToDate: true });
          }
        })
        .finally(() => setIsLoading(false));
    }
    if (target === UpdateTarget.OrdersConfig) {
      setIsLoading(true);
      props
        .checkIfOrdersConfigUpToDate(restaurant)
        .then(({ isUpToDate }) => {
          if (!isUpToDate) {
            toast.info(`Znaleziono nowszą wersję ${label}, pobieram zmiany`);
            return props
              .loadRestaurant(restaurant.id, {
                allowCache: false,
                setLoading: false,
              })
              .then(() => props.updateOrdersConfigHash(restaurant))
              .then(() => onInit && onInit({ wasUpToDate: false }));
          } else {
            onInit && onInit({ wasUpToDate: true });
          }
        })
        .finally(() => setIsLoading(false));
    }
    if (target === UpdateTarget.ReservationConfig) {
      setIsLoading(true);
      props
        .checkIfReservationConfigUpToDate(restaurant)
        .then(({ isUpToDate }) => {
          if (!isUpToDate) {
            toast.info(`Znaleziono nowszą wersję ${label}, pobieram zmiany`);
            return props
              .loadRestaurant(restaurant.id, {
                allowCache: false,
                setLoading: false,
              })
              .then(() => props.updateReservationConfigHash(restaurant))
              .then(() => onInit && onInit({ wasUpToDate: false }));
          } else {
            onInit && onInit({ wasUpToDate: true });
          }
        })
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (!children) {
    return null;
  }
  if (isLoading) {
    return <CenterCardSpinner text={`Sprawdzam aktualność ${label}`} />;
  }
  return <>{children}</>;
};

const mapDispatchToProps = {
  checkIfCustomRulesUpToDate,
  checkIfMenuUpToDate,
  checkIfOrdersConfigUpToDate,
  checkIfReservationConfigUpToDate,
  loadRestaurant,
  updateCustomRulesHash,
  updateMenuHash,
  updateOrdersConfigHash,
  updateReservationConfigHash,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UpdatesProvider);
