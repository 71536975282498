import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup } from 'reactstrap';

import InputWithAddon from './InputWithAddon';
import OtoSelectOption, {
  otoSelectOptionShape,
  IOptionType,
} from './OtoSelectOption';
import { InputProps } from 'reactstrap/lib';

const MANUAL_TYPE = 'other-type-manually';

type InputWithOptionsProps = InputProps & {
  allowEmptyOnChange?: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => any;
  options: IOptionType[];
  suffix?: string;
  value: string | number;
  wrapperClassName?: string;
};

export const InputWithOptions: React.FC<InputWithOptionsProps> = (props) => {
  const [showSelect, setShowSelect] = useState(
    props.options
      .map((opt) => (typeof opt !== 'object' ? opt : opt.value))
      .includes(props.value)
  );
  const {
    allowEmptyOnChange = true,
    options,
    suffix,
    type,
    wrapperClassName = '',
    ...otherProps
  } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === MANUAL_TYPE) {
      setShowSelect(false);
      allowEmptyOnChange && props.onChange();
    } else {
      props.onChange(e);
    }
  };
  if (showSelect) {
    return (
      <InputGroup className={wrapperClassName}>
        <Input {...otherProps} type="select" onChange={handleChange}>
          {options.map((opt) => (
            <OtoSelectOption
              opt={opt}
              key={typeof opt !== 'object' ? opt : opt.value}
            />
          ))}
          <option key={MANUAL_TYPE} value={MANUAL_TYPE}>
            wpisz ręcznie
          </option>
        </Input>
      </InputGroup>
    );
  }
  return <InputWithAddon addonText={suffix} type={type} {...otherProps} />;
};

InputWithOptions.propTypes = {
  // @ts-ignore not sure how to type this properly, there's InputType, which cannot be used as a value
  type: PropTypes.oneOf(['text', 'number', 'email', 'password']).isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(otoSelectOptionShape.isRequired).isRequired,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wrapperClassName: PropTypes.string,
};

export default InputWithOptions;
