import React from 'react';
import { Card } from 'reactstrap';

type CenterCardProps = {
  fullHeight?: boolean;
}

export const CenterCard: React.FC<CenterCardProps> = ({ children, fullHeight }) => (
  <div className={`${fullHeight ? 'centrify' : ''} justify-content-center`}>
    <Card className="d-flex flex-fill mw-250 py-3 text-center">
      {children}
    </Card>
  </div>
);

export default CenterCard;
