import React, { useMemo } from 'react';

import SimpleTableWithSorting, {
  ReactTableCellProps,
  TableDateFormatHelper,
  generateColumn,
  getRTOriginal,
} from 'app/components/common/SimpleTableWithSorting';
import type { TSetModalFunction } from 'app/containers/WithSetModal';

import OrderDelivery from 'app/components/orders/order-parts/OrderDelivery';
import OrderPaymentType from 'app/components/orders/order-parts/OrderPaymentType';
import OrderPaymentDetails from 'app/components/orders/order-parts/OrderPaymentDetails';
import OrderTotal from 'app/components/orders/order-parts/OrderTotal';
import OrderStatus from 'app/components/orders/order-parts/OrderStatus';
import DeclineOrderCell from 'app/components/admin/orders/DeclineOrderCell';
import OrderCustomerCell from 'app/components/admin/orders/OrderCustomerCell';

import type { AnyRestaruant, IOrder, IOrderWithDeclination } from 'types';

export interface OrdersTableProps {
  orders: (IOrder | IOrderWithDeclination)[];
  onOrderDecline?: (
    order: IOrder,
    status: string,
    restaurant: AnyRestaruant,
    extra: any
  ) => void;
  setModal: TSetModalFunction;
  showCustomer?: boolean;
  showDeclinationIfPresent?: boolean;
  showPaymentDetails?: boolean;
  showRestaurant?: boolean;
  showTenant?: boolean;
}

export const OrdersTable: React.FC<OrdersTableProps> = ({
  orders,
  onOrderDecline,
  setModal,
  showCustomer,
  showDeclinationIfPresent,
  showPaymentDetails,
  showRestaurant,
  showTenant,
}) => {
  const columns = useMemo(() => {
    return [
      generateColumn('id', {
        customHeader: 'ID Zamówienia',
        customCellFormatter: (props: ReactTableCellProps<IOrder>) => {
          const order = getRTOriginal<IOrder>(props);
          return (
            <>
              {onOrderDecline ? (
                <DeclineOrderCell
                  order={order}
                  setModal={setModal}
                  onOrderDecline={onOrderDecline}
                />
              ) : (
                <div>#{order.id}</div>
              )}
            </>
          );
        },
      }),
      generateColumn('created_at', {
        customHeader: 'Data utworzenia',
        customCellFormatter: TableDateFormatHelper<IOrder>(
          'created_at',
          'HH:mm dd.MM.yyyy'
        ),
      }),
      ...(showRestaurant
        ? [
            {
              accessor: 'restaurant',
              Header: 'Restauracja',
              Cell: (props: ReactTableCellProps<IOrder>) => {
                const order = getRTOriginal<IOrder>(props);
                if (!order.restaurant) {
                  return (
                    <div>
                      Brak danych o nazwie restauracji (#{order.restaurant_id})
                    </div>
                  );
                }
                return (
                  <div>
                    {order.restaurant.name} ({order.restaurant.id})
                  </div>
                );
              },
            },
          ]
        : []),
      generateColumn('status', {
        customHeader: 'Status',
        customCellFormatter: (
          props: ReactTableCellProps<OrdersTableProps['orders'][0]>
        ) => {
          const order = getRTOriginal<OrdersTableProps['orders'][0]>(props);
          return (
            <>
              <OrderStatus order={order} />
              {showDeclinationIfPresent &&
                'declination' in order &&
                order.declination && (
                  <>
                    <div>
                      {order.declination.declined_after_mins} minut po
                      utworzeniu
                    </div>
                    <div>typ odrzucenia: {order.declination.type}</div>
                    <div>powód: {order.declination.reason}</div>
                  </>
                )}
            </>
          );
        },
        filterType: 'select',
      }),
      generateColumn('payment_type', {
        customHeader: 'Płatność',
        customCellFormatter: (props: ReactTableCellProps<IOrder>) => {
          const order = getRTOriginal<IOrder>(props);
          return (
            <>
              <OrderPaymentType order={order} />
              {showPaymentDetails && <OrderPaymentDetails order={order} />}
            </>
          );
        },
        filterType: 'select',
      }),
      generateColumn('delivery_type', {
        customHeader: 'Sposób dostawy',
        customCellFormatter: (props: ReactTableCellProps<IOrder>) => (
          <OrderDelivery order={getRTOriginal<IOrder>(props)} />
        ),
        filterType: 'select',
      }),
      generateColumn('price', {
        customHeader: 'Wartość zamówienia',
        customCellFormatter: (props: ReactTableCellProps<IOrder>) => (
          <OrderTotal order={getRTOriginal<IOrder>(props)} showPrefix={false} />
        ),
      }),
      ...(showTenant
        ? [
            generateColumn('source_rel.tenant', {
              customHeader: 'Firma / Portal',
            }),
          ]
        : []),
      ...(showCustomer
        ? [
            generateColumn('customer', {
              customHeader: 'Klient',
              customCellFormatter: (props: ReactTableCellProps<IOrder>) => {
                const order = getRTOriginal<IOrder>(props);
                return <OrderCustomerCell customer={order.customer} />;
              },
            }),
          ]
        : []),
    ];
  }, [
    onOrderDecline,
    setModal,
    showCustomer,
    showDeclinationIfPresent,
    showPaymentDetails,
    showRestaurant,
    showTenant,
  ]);

  return (
    <SimpleTableWithSorting data={orders} columns={columns} pageSize={25} />
  );
};

export default OrdersTable;
