import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button, Input } from 'reactstrap';
import { PlusCircle } from 'react-feather';

import './ProductCard.scss';

interface IProps {
  buttonClassName?: string;
  className?: string;
  onAdd: (value: string) => void;
  placeholder?: string;
  prefix?: React.ReactNode;
  resetOnClick?: boolean;
}

const CardWithInput: React.FC<IProps> = (props) => {
  const {
    buttonClassName = '',
    className = '',
    placeholder = '',
    prefix = '',
    onAdd,
    resetOnClick = true,
  } = props;

  const [value, setValue] = React.useState('');

  return (
    <Card className={`d-inline-flex mt-2 product-card ${className}`}>
      <CardBody>
        <Input
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          type="button"
          color="link"
          className={buttonClassName}
          onClick={() => {
            onAdd(value);
            resetOnClick && setValue('');
          }}
          disabled={!value}
        >
          <PlusCircle size="30" />
          <span className="ml-2 text-left">
            {prefix} {value}
          </span>
        </Button>
      </CardBody>
    </Card>
  );
};

CardWithInput.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  resetOnClick: PropTypes.bool,
};

export default CardWithInput;
