import React from 'react';
import PropTypes from 'prop-types';
import Toggle, { ToggleProps } from 'react-toggle';

import 'react-toggle/style.css';
import './OtoToggle.scss';

interface IProps {
  label?: React.ReactNode;
  labelClassName?: string;
  labelWrapperClassName?: string;
}

export const OtoToggle: React.FC<IProps & ToggleProps> = (props) => {
  const {
    label,
    labelClassName = 'ml-2',
    labelWrapperClassName = 'd-inline-flex align-vertical',
    ...originalProps
  } = props;

  if (label) {
    return (
      <label className={labelWrapperClassName}>
        <Toggle {...originalProps} />
        <span className={labelClassName}>{label}</span>
      </label>
    );
  }
  return <Toggle {...originalProps} />;
};

const originalTogglePropTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  'aria-label': PropTypes.string,
  icons: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      checked: PropTypes.node,
      unchecked: PropTypes.node,
    }),
  ]),
};

OtoToggle.propTypes = {
  ...originalTogglePropTypes,
  labelClassName: PropTypes.string,
  labelWrapperClassName: PropTypes.string,
};

export default OtoToggle;
