import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { ModalTypes } from 'config';
import { EDeliveryStatus } from 'enums/delivery';
import Shapes from 'shapes/main';
import OtoSpinner from '../common/OtoSpinner';
import DeliveryTile from './DeliveryItem/DeliveryTile';
import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import GroupedFlow from '../common/GroupedFlow';
import { createDeliveriesFlow } from './RestaurateurDeliveriesList';
import DriverDeliveryButtons from './DriverDeliveryButtons';
import Delivery from 'utils/deliveries';
import type { IDelivery, IOrder } from 'types';

import './DriverDeliveriesList.scss';

type DriverDeliveriesListProps = {
  deliveries: IDelivery[];
  loading: boolean;
  onOrderOrderPrepareTimeSet: (
    delivery: IDelivery,
    prepareTime: number
  ) => void;
  onDeliveryUpdate: (
    delivery: IDelivery,
    payload: Partial<IDelivery>
  ) => Promise<void>;
  setModal: TSetModalFunction;
};

const DriverDeliveriesList: React.FC<DriverDeliveriesListProps> = (props) => {
  const {
    deliveries,
    loading,
    onOrderOrderPrepareTimeSet,
    onDeliveryUpdate,
    setModal,
  } = props;
  const [loadingDeliveries, setLoadingDeliveries] = useState({});

  const updateDelivery = (delivery, payload) => {
    setLoadingDeliveries({ ...loadingDeliveries, [delivery.id]: true });
    onDeliveryUpdate(delivery, payload).finally(() =>
      setLoadingDeliveries({ ...loadingDeliveries, [delivery.id]: false })
    );
  };

  const changeStatus = (delivery: IDelivery, status: EDeliveryStatus) =>
    updateDelivery(delivery, { status });

  const handlePhoneClick = (delivery: IDelivery) =>
    changeStatus(delivery, EDeliveryStatus.ARRIVING);

  const proposeOtherTime = (delivery: IDelivery & { order?: IOrder }) => {
    setModal(
      {
        confirm: ({ prepare_time, arriving_in }, closeModal) => {
          closeModal();
          if (!!delivery.order_id && !!prepare_time) {
            onOrderOrderPrepareTimeSet(delivery, prepare_time);
          }
          return updateDelivery(delivery, {
            status: EDeliveryStatus.ASSIGNED,
            arriving_in,
          });
        },
        modalSpecificProps: {
          delivery,
          order: delivery?.order,
        },
      },
      ModalTypes.ACCEPT_DELIVERY
    );
  };

  const requestDelay = (delivery) => {
    setModal(
      {
        title: 'Ile czasu dodać?',
        text: (
          <>
            <div>
              Wybrałeś przyjazd za {delivery.arriving_in}min. od momentu
              przyjęcia dostawy
            </div>
            <div>
              Z nich zostało: {Delivery.getDriverTimeToArrive(delivery)}min.
            </div>
          </>
        ),
        options: [
          ...[5, 10, 15, 20, 25, 30].map((num) => ({
            value: num,
            label: `${num} min`,
          })),
        ],
        confirm: (selectedTime, customTime) => {
          return updateDelivery(delivery, {
            arriving_in:
              delivery.arriving_in + parseInt(customTime || selectedTime, 10),
          });
        },
        confirmColor: 'info',
        confirmText: 'Dodaj czas',
      },
      ModalTypes.SELECT
    );
  };

  const deliveriesFlow = createDeliveriesFlow('driver-view');

  const renderDeliveryItem = (delivery: IDelivery) => (
    <DeliveryTile
      key={delivery.id}
      delivery={delivery}
      isDriver={true}
      onPhoneClick={handlePhoneClick}
    >
      {!!loadingDeliveries[delivery.id] ? (
        <OtoSpinner />
      ) : (
        <DriverDeliveryButtons
          changeStatus={changeStatus}
          delivery={delivery}
          proposeOtherTime={proposeOtherTime}
          requestDelay={requestDelay}
        />
      )}
    </DeliveryTile>
  );

  return (
    <>
      <div className="row">
        {!!loading && <OtoSpinner center />}
        {deliveries.length ? (
          <GroupedFlow
            fieldKey="status"
            data={deliveries}
            flow={deliveriesFlow}
            emptyText="Brak dostaw w tej kategorii"
            renderItem={renderDeliveryItem}
            rowClassName="d-flex flex-row flex-wrap"
          />
        ) : (
          i18n.t('delivery.driver-view.no-deliveries')
        )}
      </div>
    </>
  );
};

DriverDeliveriesList.propTypes = {
  deliveries: PropTypes.arrayOf(Shapes.deliveryShape.isRequired).isRequired,
  loading: PropTypes.bool.isRequired,
  onDeliveryUpdate: PropTypes.func.isRequired,
  onOrderOrderPrepareTimeSet: PropTypes.func.isRequired,
};

export default withSetModal(DriverDeliveriesList);
