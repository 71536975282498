import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import i18next from 'i18next';

import { FREQUENCY } from 'config';
import {
  loadDeliveries,
  selectDeliveriesLastUpdateTime,
  selectDeliveriesLoading,
} from 'store/deliveries';
import { selectRestaurant } from 'store/restaurant';
import { canOrderDelivery } from 'utils/restaurant';
import { displayTime, toDateInputValue } from 'utils/date-time';
import { NormalizedRestaurant } from 'types';
import { selectIsAnyDriver } from 'store/app';
import { OtoButtons } from 'app/components/common';

type DeliveriesFetcherOwnProps = {
  frequency: number;
  showLastFetchTime?: boolean;
};

type DeliveriesFetcherProps = DeliveriesFetcherOwnProps & PropsFromRedux;

class DeliveriesFetcher extends React.Component<DeliveriesFetcherProps> {
  deliveriesFetchInterval: NodeJS.Timeout | null = null;

  static defaultProps = {
    frequency: FREQUENCY.REFRESH,
    isDriver: false,
  };

  componentDidMount() {
    this.fetchDeliveries();
    this.deliveriesFetchInterval = setInterval(
      this.fetchDeliveries,
      this.props.frequency
    );
  }

  componentWillUnmount() {
    this.deliveriesFetchInterval && clearInterval(this.deliveriesFetchInterval);
  }

  fetchDeliveries = () => {
    const { restaurant, isDriver } = this.props;
    if (!canOrderDelivery(restaurant)) {
      return;
    }
    const afterFilter = `filter[created_after]=${toDateInputValue(
      new Date()
    )} 05:00`;
    const beforeFilter = `filter[created_before]=${toDateInputValue(
      new Date()
    )} 23:59`;
    // TODO remove include user if foodeli deliveries only
    const includes =
      'include=user,statusLogs,restaurant&fields[user]=id,name,phone&fields[restaurant]=id,name,location,phone';
    const url = isDriver
      ? `/deliveries/mine?${includes}`
      : `/restaurants/${restaurant.id}/deliveries?${afterFilter}&${beforeFilter}&${includes}`;
    this.props.loadDeliveries(url);
  };

  render() {
    if (!this.props.showLastFetchTime) {
      return null;
    }
    return (
      <div className="align-vertical">
        {i18next.t('deliveries-fetcher.last-fetch-date')}:{' '}
        <span className="ml-1">
          {this.props.lastUpdateTime
            ? displayTime(this.props.lastUpdateTime)
            : i18next.t('deliveries-fetcher.not-fetched')}
        </span>
        <OtoButtons.ReloadButton
          className="ml-2 mb-2"
          disabled={this.props.deliveriesLoading}
          onClick={this.fetchDeliveries}
        >
          {i18next.t('deliveries-fetcher.refetch')}
        </OtoButtons.ReloadButton>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isDriver: selectIsAnyDriver(state),
  deliveriesLoading: selectDeliveriesLoading(state),
  lastUpdateTime: selectDeliveriesLastUpdateTime(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
});
const mapDispatchToProps = {
  loadDeliveries,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeliveriesFetcher);
