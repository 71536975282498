import React from 'react';
import { greatPlaceStyleHover } from './place-styles';

import './DeliveriesMapMarker.scss';
interface IDeliveriesMapMarker {
  content: React.ReactNode;
  color: string;
  lng: any;
  lat: any;
}

export default class DeliveriesMapMarker extends React.Component<IDeliveriesMapMarker> {
  render() {
    const style = {
      ...greatPlaceStyleHover,
      borderColor: this.props.color,
    };

    return (
      <div className="hint hint--html hint--info hint--top" style={style}>
        <div style={{ width: 80 }} className="hint__content">
          {this.props.content}
        </div>
      </div>
    );
  }
}
