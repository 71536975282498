import React from 'react';
import PropTypes from 'prop-types';

type OtoChipProps = {
  isSelected: boolean;
  label: string;
  onClick: () => void;
}

export const OtoChip: React.FC<OtoChipProps> = props => {
  const { isSelected, label, onClick } = props;
  return <div className={`tag ${isSelected ? 'tag--active' : ''}`} onClick={onClick}>
    <span>{label}</span>
    {isSelected && <button type="button" className="tag__remove">X</button>}
  </div>
};

OtoChip.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default OtoChip;
