import { INavItem } from './types';
import RestaurantSettingsPage from '../app/pages/RestaurantSettingsPage';
import DiscountsPage from '../app/pages/DiscountsPage';
import { OTO_PRODUCTS, ROLE_GROUPS } from 'enums';
import EnhancedIntegrationsPage from 'app/pages/RestaurantIntegrationsPage';

export const allOrdersProducts = [
  OTO_PRODUCTS.orders,
  OTO_PRODUCTS.orders_list,
  OTO_PRODUCTS.lubje_orders,
  OTO_PRODUCTS.website,
  OTO_PRODUCTS.iframe,
];

export const settingsNavItem: INavItem = {
  name: 'Ustawienia',
  icon: 'Settings',
  children: [
    {
      name: RestaurantSettingsPage.navName,
      icon: 'Home',
      url: RestaurantSettingsPage.url,
    },
    {
      name: 'Zamówienia',
      icon: 'Clipboard',
      url: '/settings/orders',
      availableWithProducts: allOrdersProducts,
    },
    {
      name: 'Edytuj menu',
      icon: 'Edit',
      url: '/menu',
      availableWithProducts: allOrdersProducts,
    },
    {
      name: 'Promocje',
      icon: 'Tag',
      url: (DiscountsPage as any).url,
      availableWithProducts: allOrdersProducts,
    },
    {
      name: 'Rezerwacje',
      icon: 'Calendar',
      url: '/settings/reservations',
      availableWithProducts: [OTO_PRODUCTS.reservations],
    },
    {
      name: 'Dni wyjątkowe',
      icon: 'Gift',
      url: '/settings/special-days',
    },
    {
      name: EnhancedIntegrationsPage.navName as string,
      icon: 'Settings',
      url: EnhancedIntegrationsPage.url as string,
      availableForRoles: ROLE_GROUPS.allAdminRoles,
    },
  ],
};

export default settingsNavItem;
