import React from 'react';
import PropTypes from 'prop-types';
import { Form, withFormik } from 'formik';
import i18next from 'i18next';
import { Button, Input, Label } from 'reactstrap';


import OtoSpinner from '../common/OtoSpinner';

const CreateMarketingCampaignForm = props => {
  const { errors, isSubmitting, handleChange, values } = props;

  const renderError = key => errors[key] && <div className="alert alert-danger">{errors[key]}</div>

  const renderTextField = (key, options = {}) => <div className={`form-group col-md-6 ${options.className}`}>
    <Label>{i18next.t(`create-campaign.${key}-label`)}</Label>
    <Input
      name={key}
      disabled={options.disabled || false}
      placeholder={options.placeholder || i18next.t(`create-campaign.${key}-hint`)}
      onChange={handleChange}
      type={options.type || 'text'}
      value={values[key]} />
    {renderError(key)}
  </div>;

  return <Form>
    {renderTextField('name')}
    {renderTextField('description', { type: 'textarea' })}
    {isSubmitting ? <OtoSpinner /> : <Button color="primary" type="submit">{i18next.t('Create')}</Button>}
  </Form>;
};

CreateMarketingCampaignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
    type: 'promo_code', //promo | promo_code
    template_id: 1, // required
    promo_code_id: 1, // needed if type === promo_code
    customers: [ //Array of customers
      {
        'email': 'hello@otostolik.pl',
        'name': 'John Doe'
      }
    ],
    parameters: [],
  }),
  handleSubmit: (values, { props, setSubmitting }) => props.onSubmit(values).finally(() => setSubmitting(false))
})(CreateMarketingCampaignForm);
