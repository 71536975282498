import React from 'react';
import PropTypes from 'prop-types';

import { ITableWithPlace } from 'types';
import TablesFormatted from './TablesFormatted';
import TablesCapacity from './TablesCapacity';
import Shapes from 'shapes/main';

interface IProps {
  tables: ITableWithPlace[];
}

const SelectedTables: React.FC<IProps> = (props) => {
  const { tables } = props;
  return (
    <>
      Wybrane stoliki:{' '}
      <TablesFormatted showPlaceName tables={tables as ITableWithPlace[]} />
      <TablesCapacity tables={tables} />
    </>
  );
};

SelectedTables.propTypes = {
  tables: PropTypes.arrayOf(Shapes.restaurantTableWithPlaceShape.isRequired).isRequired,
};

export default SelectedTables;
