import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import i18 from 'i18next';

import Shapes from 'shapes/main';
import { logActivity } from 'utils/log';
import {
  hasProductZeroPrice,
  isCheckboxFieldWithPriceError,
  isChoiceFieldWithoutValue,
  isProductWithEmptyName,
} from 'utils/menu-edit';
import CollapsibleCard from '../common/CollapsibleCard';
import { toast } from 'react-toastify';
import {
  Category,
  MenuAddonFieldsSet,
  MenuItem,
  MenuItemAdditionalField,
  NormalizedRestaurant,
  OriginalRestaurant,
} from 'types';

interface IProps {
  addonFieldsSets: MenuAddonFieldsSet[];
  categories: Category[];
  restaurant: NormalizedRestaurant;
}

class MenuErrors extends React.PureComponent<IProps, {}> {
  static propTypes = {
    addonFieldsSets: PropTypes.array.isRequired,
    categories: PropTypes.arrayOf(Shapes.menuCategoryShape.isRequired),
    restaurant: Shapes.restaurantShape.isRequired,
  };

  static defaultProps = {
    categories: [],
  };

  renderInvalidProductsAlert() {
    const { categories } = this.props;
    if (!categories || !categories.flat) {
      logActivity('no categories');
      return null;
    }
    const productsWithEmptyPrices = getProductsWithEmptyPrices(categories);
    if (!productsWithEmptyPrices.length) {
      return null;
    }

    return (
      <Alert color="danger" className="mt-2">
        {i18.t('menu-errors.invalid-price')}{' '}
        {productsWithEmptyPrices.map((product) => product.name).join(', ')}
      </Alert>
    );
  }

  renderMissingAddonNameInSetsAlert() {
    const addonSetsWithInvalidAddons = getSetsWithAddonsWithMissingName(
      this.props.addonFieldsSets
    );
    if (Object.keys(addonSetsWithInvalidAddons).length > 0) {
      return (
        <Alert color="danger" className="mt-2">
          {i18.t('menu-errors.addon-missing-name-in-set')}{' '}
          {Object.entries(addonSetsWithInvalidAddons)
            .map(([setName, addons]) => {
              const productsJoined = addons
                .map((addon) => addon.name)
                .join(', ');
              return `${productsJoined} (${setName})`;
            })
            .join(', ')}
        </Alert>
      );
    }
  }

  renderInvalidCheckboxAddonsInSetsAlert() {
    const addonSetsWithInvalidAddons = getSetsWithAddonsWithPriceError(
      this.props.addonFieldsSets
    );
    if (Object.keys(addonSetsWithInvalidAddons).length > 0) {
      return (
        <Alert color="danger" className="mt-2">
          {i18.t('menu-errors.addon-price-error-in-set')}{' '}
          {Object.entries(addonSetsWithInvalidAddons)
            .map(([setName, addons]) => {
              const productsJoined = addons
                .map((addon) => addon.name)
                .join(', ');
              return `${productsJoined} (${setName})`;
            })
            .join(', ')}
        </Alert>
      );
    }
  }

  renderProductsGeneralAlert(
    handlerFn: (categories: Category[]) => MenuItem[],
    textKey: string
  ) {
    const invalidProducts = handlerFn(this.props.categories);
    return (
      invalidProducts.length > 0 && (
        <Alert color="danger" className="mt-2">
          {i18.t(textKey)}{' '}
          {invalidProducts.map((product) => product.name).join(', ')}
        </Alert>
      )
    );
  }

  renderDuplicateCategoriesAlert() {
    if (!hasDuplicateCategoryNames(this.props.categories)) {
      return null;
    }
    toast.error(i18.t('menu-errors.duplicate-categories-error'), {
      autoClose: false,
    });
    return (
      <Alert color="danger" className="mt-2">
        {i18.t('menu-errors.duplicate-categories-error')}
      </Alert>
    );
  }

  renderDisabledProductsAlert() {
    const disabledProducts = getDisabledProducts(this.props.categories);
    return (
      !!disabledProducts.length && (
        <CollapsibleCard
          title={`${i18.t('menu-edit.disabled-products')} (${i18.t(
            'menu-edit.items'
          )}${disabledProducts.length})`}
          className="mt-2"
        >
          <Alert color="info">
            {i18.t('menu-edit.disabled-products')}{' '}
            {disabledProducts.map((product) => product.name).join(', ')}
          </Alert>
        </CollapsibleCard>
      )
    );
  }

  render() {
    return (
      <>
        {this.renderInvalidProductsAlert()}
        {this.renderProductsGeneralAlert(
          getProductsWithInvalidChoices,
          'menu-errors.choose-option-error'
        )}
        {this.renderProductsGeneralAlert(
          getProductsWithAdditionalFieldsWithoutNames,
          'menu-errors.addon-missing-name'
        )}
        {this.renderProductsGeneralAlert(
          getProductsWithInvalidAddons,
          'menu-errors.addon-price-error'
        )}
        {this.renderMissingAddonNameInSetsAlert()}
        {this.renderInvalidCheckboxAddonsInSetsAlert()}
        {this.renderDuplicateCategoriesAlert()}
        {this.renderProductsGeneralAlert(
          getProductsWithDuplicatedAddonNames,
          'menu-errors.addon-duplicated-name-error'
        )}
        {this.renderDisabledProductsAlert()}
      </>
    );
  }
}

export default MenuErrors;

export const MenuValidators = {
  getDisabledProducts,
  getProductsWithDuplicatedAddonNames,
  getProductsWithEmptyPrices,
  getProductsWithInvalidChoices,
  getProductsWithAdditionalFieldsWithoutNames,
  getProductsWithInvalidAddons,
  getProductsWithInlineAddonsAmount,
  getSetsWithAddonsWithPriceError,
  getProductsWithNoName,
  getUnknownDisabledProducts,
  hasDuplicateCategoryNames,
};

const getDishes = (restaurant: NormalizedRestaurant | OriginalRestaurant) =>
  ((restaurant.menu || {}).categories || []).map((c) => c.items).flat();

function getProductsWithNoName(categories: Category[]) {
  const nestedProducts = categories.map((c) => c.items || []);
  return nestedProducts.flat().filter(isProductWithEmptyName);
}

function getProductsWithEmptyPrices(categories) {
  const nestedProducts = categories.map((c) => c.items || []);
  return nestedProducts.flat().filter(hasProductZeroPrice);
}

function hasDuplicateCategoryNames(categories: Category[]) {
  const uniqueCategoriesNames = new Set(
    categories.map((c) => c.name.toLowerCase())
  );
  return uniqueCategoriesNames.size < categories.length;
}

function getDisabledProducts(categories: Category[]) {
  const nestedProducts = categories.map((c) => c.items || []);
  return nestedProducts.flat().filter((product) => product.isDisabled);
}

function getProductsWithInvalidChoices(categories: Category[]) {
  const nestedProducts = categories.map((c) => c.items || []);
  const productsWithInvalidChoiceAddons = nestedProducts
    .flat()
    .filter((product) => {
      if (!product.additionalFields || !product.additionalFields.map) {
        return false;
      }
      return (
        product.additionalFields.filter(isChoiceFieldWithoutValue).length > 0
      );
    });
  return productsWithInvalidChoiceAddons;
}

const hasAdditionalFieldWithoutName = (product: MenuItem): boolean => {
  if (!product.additionalFields || !product.additionalFields.map) {
    return false;
  }
  return product.additionalFields.filter((field) => !field.name).length > 0;
};

function getProductsWithAdditionalFieldsWithoutNames(categories: Category[]) {
  const nestedProducts = categories.map((c) => c.items || []);
  return nestedProducts.flat().filter(hasAdditionalFieldWithoutName);
}

const hasCheckboxFieldWithPriceError = (product: MenuItem): boolean => {
  if (!product.additionalFields || !product.additionalFields.map) {
    return false;
  }
  return (
    product.additionalFields.filter(isCheckboxFieldWithPriceError).length > 0
  );
};

function getProductsWithInvalidAddons(categories: Category[]) {
  const nestedProducts = categories.map((c) => c.items || []);
  const productsWithInvalidChoiceAddons = nestedProducts
    .flat()
    .filter(hasCheckboxFieldWithPriceError);
  return productsWithInvalidChoiceAddons;
}

function getProductsWithInlineAddonsAmount(
  categories: Category[],
  amount: number
) {
  const nestedProducts = categories.map((c) => c.items || []);
  return nestedProducts
    .flat()
    .filter((product) => (product.additionalFields?.length || 0) >= amount);
}

function getSetsWithAddonsWithMissingName(
  sets: MenuAddonFieldsSet[]
): Record<string, MenuItemAdditionalField[]> {
  return (sets || []).reduce(
    (acc: Record<string, MenuItemAdditionalField[]>, set) => {
      const invalidAddons = set.items.filter((field) => !field.name);
      if (invalidAddons.length > 0) {
        acc[set.name as string] = invalidAddons;
      }
      return acc;
    },
    {}
  );
}

function getSetsWithAddonsWithPriceError(
  sets: MenuAddonFieldsSet[]
): Record<string, MenuItemAdditionalField[]> {
  return (sets || []).reduce(
    (acc: Record<string, MenuItemAdditionalField[]>, set) => {
      const invalidAddons = set.items.filter(isCheckboxFieldWithPriceError);
      if (invalidAddons.length > 0) {
        acc[set.name as string] = invalidAddons;
      }
      return acc;
    },
    {}
  );
}

function getUnknownDisabledProducts(
  restaurant: NormalizedRestaurant | OriginalRestaurant
) {
  const products = getDishes(restaurant);
  return products.filter(
    (item) => item.isDisabled !== true && item.isDisabled !== false
  );
}

function getProductsWithDuplicatedAddonNames(categories: Category[]) {
  const nestedProducts = categories.map((c) => c.items || []);
  const productsWithDuplicatedAddonNames = nestedProducts
    .flat()
    .filter((product) => {
      if (!product.additionalFields || !product.additionalFields.map) {
        return false;
      }
      const uniqueFieldNames = new Set(
        product.additionalFields.map((field) => field.name)
      );
      return uniqueFieldNames.size !== product.additionalFields.length;
    });
  return productsWithDuplicatedAddonNames;
}
