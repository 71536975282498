import { IOrder } from 'types';
import React from 'react';

type OrderCustomerCellProps = {
  customer: Pick<
    IOrder['customer'],
    'customer_firstname' | 'customer_lastname' | 'phone'
  >;
  showPhoneAsLink?: boolean;
};

export const OrderCustomerCell: React.FC<OrderCustomerCellProps> = ({
  customer,
  showPhoneAsLink = false,
}) => {
  return (
    <>
      <div>
        {customer.customer_firstname} {customer.customer_lastname.slice(0, 1)}.
      </div>
      {showPhoneAsLink ? (
        <a href={`tel:${customer.phone}`}>{customer.phone}</a>
      ) : (
        <div>{customer.phone}</div>
      )}
    </>
  );
};

export default OrderCustomerCell;
