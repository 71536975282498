import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18 from 'i18next';
import { Col, Input, Label, Row } from 'reactstrap';

import { AdditionalFieldTypes } from 'enums';
import OtoButtons from '../common/OtoButtons';

const additionalFieldTypesDict: Record<AdditionalFieldTypes, string> = {
  [AdditionalFieldTypes.MULTIPLE_CHOICE]:
    'product-edit-modal.add-addon.type-multiple-choice',
  [AdditionalFieldTypes.SINGLE_CHOICE]:
    'product-edit-modal.add-addon.type-single-choice',
  [AdditionalFieldTypes.CHECKBOX]: 'product-edit-modal.add-addon.type-upgrade',
  [AdditionalFieldTypes.SET]: 'product-edit-modal.add-addon.type-set',
};

interface IProps {
  allowSets: boolean;
  onAdd: (addonType: AdditionalFieldTypes) => void;
}

export const ProductAdditionalFieldAdd: React.FC<IProps> = (props) => {
  const { allowSets, onAdd } = props;
  const [type, setType] = useState<AdditionalFieldTypes>(
    AdditionalFieldTypes.MULTIPLE_CHOICE
  );
  return (
    <>
      <Row className="align-items-center mb-2">
        <OtoButtons.AddButton onClick={() => onAdd(type)} className="col pl-0">
          {i18.t('product-edit-modal.add-choice-field')}
        </OtoButtons.AddButton>
        <Col>
          <Label>{i18.t('product-edit-modal.add-addon.title')}</Label>
          <Input
            type="select"
            name="additionalFieldType"
            value={type}
            onChange={(e) => setType(e.target.value as AdditionalFieldTypes)}
          >
            {Object.values(AdditionalFieldTypes)
              .filter((type) => allowSets || type !== AdditionalFieldTypes.SET)
              .map((type) => (
                <option key={type} value={type}>
                  {i18.t(additionalFieldTypesDict[type])}
                </option>
              ))}
          </Input>
        </Col>
      </Row>
    </>
  );
};

ProductAdditionalFieldAdd.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

ProductAdditionalFieldAdd.displayName = 'ProductAdditionalFieldAdd';

export default ProductAdditionalFieldAdd;
