import React from 'react';
import { Alert, Button } from 'reactstrap';

import { ModalTypes } from 'config';
import Order from 'utils/orders';
import { getSafeLsJSON } from 'utils/parsers';
import type { NormalizedRestaurant, OrdersConfig } from 'types';
import type { TSetModalFunction } from 'app/containers/WithSetModal';

type OrdersDeliveryTimeAlertProps = {
  ordersConfig: OrdersConfig
  restaurant: NormalizedRestaurant;
  setModal: TSetModalFunction;
  updateOrdersConfig: (payload: Partial<OrdersConfig>, restaurant: NormalizedRestaurant) => void;
}

type OrdersDeliveryTimeAlertState = {
  alertActionDate: Date;
  visible: boolean;
  useLastRemindAlert?: Date;
}

class OrdersDeliveryTimeAlert extends React.Component<OrdersDeliveryTimeAlertProps, OrdersDeliveryTimeAlertState> {
  state: OrdersDeliveryTimeAlertState = {
    alertActionDate: new Date(),
    visible: true,
  };

  onDismiss = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  setTimeForAlertToLocalStorage = () => {
    const d = new Date();
    this.setState({
      useLastRemindAlert: d,
    });
    localStorage.setItem(
      'hidePrepareOrderAlertUntil',
      this.state.alertActionDate.toISOString()
    );
  };

  changeDeliveryTime = () => {
    this.props.setModal(
      {
        title: 'Podaj deklarowany czas na dostawę',
        text: '',
        options: Order.DELIVERY_PREPARE_TIMES.map((time) => time.toString()),
        confirm: (selected) => {
          const payload: Partial<OrdersConfig> = {
              deliveryTime: parseInt(selected, 10),
          };
          this.props.updateOrdersConfig(payload, this.props.restaurant);
        },
        confirmColor: 'success',
        confirmText: 'Potwierdź',
        cancelText: 'Anuluj',
      },
      ModalTypes.SELECT
    );
  };

  hasWeekPassedSinceDismiss = () => {
    const oneWeekTime = 1000 * 60 * 60 * 24 * 7;
    const getTimeDoNotDisplayAlert = localStorage.getItem(
      'hidePrepareOrderAlertUntil'
    );
    if (!getTimeDoNotDisplayAlert) {
      return true;
    }
    if (
      ((new Date()).getTime() - Date.parse(getTimeDoNotDisplayAlert)) >=
      oneWeekTime
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { deliveryTime } = this.props.ordersConfig;
    const lastOrderPrepareTimes = getSafeLsJSON(
      `${this.props.restaurant.id}-delivery-times`,
      []
    );
    if (!lastOrderPrepareTimes || lastOrderPrepareTimes.length < 5) {
      return null;
    }
    const sumValues = (accumulator, curr) => accumulator + curr;
    const last5OrderAvgTime =
      lastOrderPrepareTimes.reduce(sumValues) / lastOrderPrepareTimes.length;
    const shouldDisplayAlert =
    deliveryTime && 
      Math.abs(last5OrderAvgTime - deliveryTime) >= 30 &&
      this.hasWeekPassedSinceDismiss();

    return (
      shouldDisplayAlert && (
        <Alert
          color="info"
          isOpen={this.state.visible}
          toggle={this.onDismiss}
          fade={false}
        >
          <h4>
            Deklarowany czas na dostawę rózni się od średniej z ostatnich 5
            zamówień, zmień deklarowany czas na dostawę!
          </h4>
          <hr />
          <h5>{`Deklarowany czas na dostawę: ${deliveryTime}min`}</h5>
          <h5>{`Średni podany czas z ostatnich 5 zamówień: ${last5OrderAvgTime}min`}</h5>
          <Button
            className="mb-2"
            color="success"
            onClick={this.changeDeliveryTime}
          >
            Zmień czas dostawy
          </Button>{' '}
          <Button
            className="mb-2"
            color="secondary"
            onClick={this.setTimeForAlertToLocalStorage}
          >
            Zamknij i nie przypominaj przez tydzień
          </Button>
        </Alert>
      )
    );
  }
}

export default OrdersDeliveryTimeAlert;
