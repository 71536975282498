import { toast } from 'react-toastify';
import type { Dispatch } from 'redux';

import APIService from 'services/api';
import { getVisitorId } from 'services/auth';
import { LANGS } from 'config';
import { User } from 'types/user';
import { PERSISTENT_LS } from 'services/persistent';
import { updateCalendarPreferences } from './reservation-calendar';
import { loadRestaurant } from './restaurant';
import { ROLES, ROLE_GROUPS } from 'enums';
import { OriginalRestaurant } from 'types';
import { loadAdminRestaurants, loadAdminUsers, loadAdminUsersRestaurants } from './admin';

type Langs = 'pl' | 'en';
export interface IAppReducerState {
  email: string | null;
  isDebug: boolean;
  lang: Langs;
  messagingToken: string | null;
  role: number | null;
  restaurants?: OriginalRestaurant[];
  user?: User;
  userLoading: boolean;
  userRestaurants: {
    id: number;
    name: string;
  }[];
}

interface IAction {
  type?: string;
  role?: number;
  lang?: string;
  payload?: any;
}

export const INITIAL_STATE: IAppReducerState = {
  email: null,
  isDebug: false,
  messagingToken: null,
  role: null,
  lang: 'pl',
  user: undefined,
  userLoading: false,
  userRestaurants: [],
  restaurants: [],
};

export const INIT_USER_STATE = 'APP/INIT_USER_STATE';
export const SET_IS_DEBUG = 'APP/SET_IS_DEBUG';
const SET_MESSAGING_TOKEN = 'APP/SET_MESSAGING_TOKEN';
export const SET_USER_LOADING = 'APP/SET_USER_LOADING';
export const SET_ROLE = 'APP/SET_ROLE';
export const SET_LANG = 'APP/SET_LANG';
export const LOGOUT = 'APP/LOGOUT';
// @FIXME Synthetic action for clearring
export const CHANGE_RESTAURANT = 'APP/CHANGE_RESTAURANT';

export default function appReducer(
  state = INITIAL_STATE,
  action: IAction = {}
): IAppReducerState {
  switch (action.type) {
    case SET_ROLE:
      return {
        ...state,
        role: action.role || null,
      };
    case SET_LANG:
      return {
        ...state,
        lang: action.lang as Langs,
      };
    case INIT_USER_STATE:
      return {
        ...state,
        ...action.payload,
        userLoading: false,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        userLoading: true,
      };
    case SET_IS_DEBUG:
      return {
        ...state,
        isDebug: action.payload,
      };
    case SET_MESSAGING_TOKEN: {
      return {
        ...state,
        messagingToken: action.payload,
      };
    }
    default:
      return state;
  }
}

export const setIsDebug = (isDebug: boolean) => ({
  type: SET_IS_DEBUG,
  payload: isDebug,
});
export const setMessagingToken = (token: string | null) => ({
  type: SET_MESSAGING_TOKEN,
  payload: token,
});

export const setLang = (lang: string) => ({ type: SET_LANG, lang });
export const setRole = (role: number) => ({ type: SET_ROLE, role });

export const selectIsDebug = (state: StateType) => state.app.isDebug;
export const selectMessagingToken = (state: StateType) =>
  state.app.messagingToken;
export const selectLanguage = (state: StateType) => state.app.lang;
export const selectUserRole = (state: StateType) => state.app.role;

export const selectUser = (state: StateType) => state.app.user;
export const selectUserLoading = (state: StateType) => state.app.userLoading;

export const updateUser =
  (user: User, payload: Partial<User>) => (dispatch: Dispatch) => {
    dispatch({ type: SET_USER_LOADING });
    return APIService.put(`/users/${user.id}`, payload)
      .then((updatedUser: User) => {
        PERSISTENT_LS.setUser(updatedUser);
        dispatch({
          type: INIT_USER_STATE,
          payload: { user: updatedUser },
        });
        return updatedUser;
      })
      .catch((error) => {
        throw error;
      });
  };

export const selectUserRestaurants = (state: StateType) =>
  state.app.restaurants;
export const selectHasMultipleRestaurants = (state: StateType): boolean =>
  (selectUserRestaurants(state) || []).length > 1;

export const initUserState = (payload) => (dispatch) => {
  const validPayload = Object.fromEntries(
    Object.entries(payload || {}).filter(
      ([key, value]) =>
        ['email', 'lang', 'role', 'restaurants', 'user'].includes(key) &&
        !!value
    )
  );

  if (ROLE_GROUPS.allAdminRoles.includes(payload.role_id)) {
    dispatch(loadAdminRestaurants());
    dispatch(loadAdminUsers());
    dispatch(loadAdminUsersRestaurants());
  }

  return dispatch({
    type: INIT_USER_STATE,
    payload: validPayload,
  });
};

export const retrieveSavedUserState = () => (dispatch) => {
  const role: number = PERSISTENT_LS.getLsRole();
  const initialState = {
    lang: PERSISTENT_LS.getLsLang() || LANGS.default,
    role,
    restaurants: PERSISTENT_LS.getLsUserRestaurants(),
    user: PERSISTENT_LS.getUser() || null,
  };

  if (ROLE_GROUPS.allAdminRoles.includes(role)) {
    dispatch(loadAdminRestaurants());
    dispatch(loadAdminUsers());
    dispatch(loadAdminUsersRestaurants());
  }

  return dispatch({
    type: INIT_USER_STATE,
    payload: initialState,
  });
};

export const changeRestaurant = (restaurant: { id: number }) => (dispatch) => {
  dispatch({ type: CHANGE_RESTAURANT });
  dispatch(loadRestaurant(restaurant.id));
};

export const logout =
  ({
    location,
    navigate,
  }: {
    location: {
      pathname: string;
    };
    navigate: (path: string) => void;
  }) =>
  async (dispatch) => {
    setApiToken('');
    PERSISTENT_LS.clearLsRestaurant();
    PERSISTENT_LS.clearLsRole();
    PERSISTENT_LS.clearLsUserRestaurants();

    dispatch(
      updateCalendarPreferences({
        activePlace: null,
      })
    );
    const visitorId = await getVisitorId();
    return (
      APIService.post('/auth/logout', {
        device_id: visitorId,
      })
        .then(() => toast.error('Wylogowano pomyślnie'))
        // .catch(() => toast.error('Wystąpił problem podczas wylogowania'))
        .finally(() => {
          dispatch({ type: LOGOUT });
          const loginPath = '/login';
          const currentPath = !!location
            ? location.pathname
            : window.location.pathname;
          if (!currentPath.includes(loginPath)) {
            if (!!navigate) {
              navigate(loginPath);
            } else {
              window.location.pathname = loginPath;
            }
          }
        })
    );
  };

type StateType = { app: IAppReducerState };

export const getApiToken = () => localStorage.getItem('token');
export const setApiToken = (token: string) =>
  localStorage.setItem('token', token);

export const selectIsRootAdmin = (state: StateType) =>
  state.app.role === ROLES.ADMIN;
export const selectIsSalesRep = (state: StateType) =>
  state.app.role === ROLES.SALES_REP;
export const selectIsLubjeAdmin = (state: StateType) =>
  state.app.role === ROLES.LUBJE_STAKEHOLDER;
export const selectIsLesznojeAdmin = (state: StateType) =>
  state.app.role === ROLES.LESZNOJE_ADMIN;
export const selectIsAnyAdmin = (state: StateType) =>
  !!state.app.role && ROLE_GROUPS.allAdminRoles.includes(state.app.role);
export const selectIsAnyDriver = (state: StateType) =>
  !!state.app.role && ROLE_GROUPS.allDriverRoles.includes(state.app.role);

export const selectCanEditDeliveries = (state: StateType) =>
  !!state.app.role &&
  [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN, ROLES.DELIVERY_COORDINATOR].includes(
    state.app.role
  );

export const selectCanEditRestaurantMenu = (state: StateType) =>
  !!state.app.role &&
  [...ROLE_GROUPS.allAdminRoles, ROLES.RESTAURANT_ADMIN].includes(state.app.role);
