import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import { CollapsibleCard, IconWithTooltip, InputWithAddon } from '../common';
import i18next from 'i18next';

interface IProps {
  disabled: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  restaurant: {
    phone: string;
  };
  values: {
    autoReservationMaxGuests: number;
    autoReservationMaxGuestsLabel: string;
  };
}

const ReservationSettingsMaxPeople: React.FC<IProps> = (props) => {
  const { disabled, handleChange, restaurant, values } = props;

  return (
    <CollapsibleCard
      bodyClassName={'align-vertical flex-wrap flex-column align-items-start'}
      title={<div>{i18next.t('reservation-settings.auto-res-max-guests.label')}</div>}
    >
      <FormGroup>
        <Label for="autoReservationMaxGuests">
          {i18next.t('reservation-settings.auto-res-max-guests.label')}
          <IconWithTooltip
            id="auto-res-max-guests"
            className="ml-2"
            text={i18next.t('reservation-settings.auto-res-max-guests.tooltip')}
          />
        </Label>
        <InputWithAddon
          addonText={'osób'}
          disabled={disabled}
          type="number"
          step="1"
          min="5"
          className="mw-70"
          name="autoReservationMaxGuests"
          id="autoReservationMaxGuests"
          onChange={handleChange}
          value={values.autoReservationMaxGuests}
        />
      </FormGroup>
      <FormGroup>
        <Label className="w-100 text-left">
          <span>
            {i18next.t('reservation-settings.auto-res-max-guests-warning.label')}
          </span>
          <Input
            disabled={disabled}
            placeholder={i18next.t('reservation-settings.auto-res-max-guests-warning.placeholder', { phone: restaurant.phone })}
            rows="3"
            name="autoReservationMaxGuestsLabel"
            onChange={handleChange}
            value={values.autoReservationMaxGuestsLabel}
            type="textarea"
          />
        </Label>
      </FormGroup>
    </CollapsibleCard>
  );
};

ReservationSettingsMaxPeople.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    phone: PropTypes.string.isRequired,
  }).isRequired,
  values: PropTypes.shape({
    autoReservationMaxGuests: PropTypes.number.isRequired,
    autoReservationMaxGuestsLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default ReservationSettingsMaxPeople;
