
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';

type CardWithIconProps = {
  bgColor?: string;
  className?: string;
  icon?: React.ReactNode;
  iconProps?: object;
  inverse?: boolean;
  title?: string;
  subtitle?: string;
};

export const CardWithIcon: React.FC<CardWithIconProps> = (props) => {
  const {
    bgColor,
    icon: Icon,
    iconProps,
    title,
    subtitle,
    className,
    inverse,
    ...restProps
  } = props;
  return (
    <Card inverse={inverse} className={`cr-widget ${className} bg-${bgColor}`} {...restProps}>
      <CardBody className="cr-widget__icon">
        {/* @ts-expect-error was working before TS refactor */}
        <Icon size={50} {...iconProps} />
      </CardBody>
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
      </CardBody>
    </Card>
  );
};

CardWithIcon.propTypes = {
  bgColor: PropTypes.string,
  icon: PropTypes.element,
  iconProps: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CardWithIcon.defaultProps = {
  bgColor: 'primary',
  icon: 'span',
  iconProps: { size: 50 },
};

export default CardWithIcon;