import PropTypes from 'prop-types';

import { EDeliveryType } from 'enums/delivery';

const customerPersonalPart = {
  customer_firstname: PropTypes.string.isRequired,
  customer_lastname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

const customerAddressPart = {
  address: PropTypes.string,
  city: PropTypes.string,
  street: requiredIfSelfCollectionOrder,
  flat: PropTypes.string,
  house: requiredIfSelfCollectionOrder,
  lat: PropTypes.number,
  lng: PropTypes.number, // lng from web, long from mobile app
  long: PropTypes.number, // lng from web, long from mobile app
};

const customerMarketingPart = {
  allowEmail: PropTypes.bool.isRequired,
  allowMarketing: PropTypes.bool,
  allowSms: PropTypes.bool.isRequired,
};

const orderCustomerShape = PropTypes.shape({
  ...customerPersonalPart,
  ...customerMarketingPart,
  cutleryPreferences: PropTypes.oneOf(['yes', 'no']),
  courrierCalled: PropTypes.string, // deprecated TODO remove and display time called from related delivery
  delivery: PropTypes.string.isRequired,
  deliveryAt: PropTypes.string,
  ...customerAddressPart,
  notes: PropTypes.string,
  // TODO split these into separate order fields
  prepare_time: PropTypes.number, // deprecated to be removed, after double check in DB
  customDeliveryPrice: PropTypes.number,
});

function requiredIfSelfCollectionOrder(
  props: any,
  propName: string,
  componentName: string
) {
  const { delivery } = props;
  if (
    !!delivery &&
    ![EDeliveryType.TAKEOUT, EDeliveryType.DRIVE_THROUGH].includes(delivery) &&
    !props[propName]
  ) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. \`${propName}\` is required when order is not takeout nor drive through.`
    );
  }
  return null;
}

export default orderCustomerShape;
