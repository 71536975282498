import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import memoizeOne from 'memoize-one';

import { OtoDateRangePicker, OtoSpinner } from 'app/components/common';
import { defineds } from 'app/components/common/defaultRangesPl';
import { get } from 'services/api';
import { debounce } from 'utils/general';
import { logError } from 'utils/log';

const RANGES_KEY = 'selection';

const startDatesByRange = {
  today: defineds.startOfToday,
  week: defineds.startOfWeek,
  month: defineds.startOfMonth,
  lastWeek: defineds.startOfLastWeek,
  lastMonth: defineds.startOfLastMonth,
};

const endDatesByRange = {
  today: defineds.endOfToday,
  week: defineds.endOfWeek,
  month: defineds.endOfMonth,
  lastWeek: defineds.endOfLastWeek,
  lastMonth: defineds.endOfLastMonth,
};

const getMemoizedOptionsBag = memoizeOne((ranges, editRanges, setData) => ({
  editRanges,
  setData,
  ranges,
}));

type TProps<T> = {
  allowRefetch?: boolean;
  children: (data: T, dateRangeBag: any) => React.ReactNode;
  defaultRange?: 'today' | 'week' | 'month' | 'lastWeek' | 'lastMonth';
  fetchOnMount?: boolean;
  getUrl: (startDate: Date, endDate: Date) => string;
  hideCalendarAfterFetch?: boolean;
  onDataFetch?: (data: T) => void;
};

type TRange = {
  startDate: Date;
  endDate: Date;
  key: string;
};

type TState<T> = {
  fetchedData: T | null;
  loading: boolean;
  error: boolean;
  ranges: [TRange];
  showCalendar: boolean;
};

export default class WithDateRangeDataFetched<T> extends React.PureComponent<
  TProps<T>,
  TState<T>
> {
  _isMounted: boolean;

  static displayName = 'WithDateRangeDataFetched';

  static propTypes = {
    allowRefetch: PropTypes.bool,
    children: PropTypes.func,
    defaultRange: PropTypes.oneOf([
      'today',
      'week',
      'month',
      'lastWeek',
      'lastMonth',
    ]),
    fetchOnMount: PropTypes.bool,
    getUrl: PropTypes.func.isRequired,
    hideCalendarAfterFetch: PropTypes.bool,
    onDataFetch: PropTypes.func,
  };

  static defaultProps = {
    allowRefetch: false,
    defaultRange: 'week',
    hideCalendarAfterFetch: false,
    fetchOnMount: true,
  };

  constructor(props) {
    super(props);
    this._isMounted = false;
    const startDate = startDatesByRange[props.defaultRange || 'week'];
    const endDate = endDatesByRange[props.defaultRange || 'week'];
    this.state = {
      fetchedData: null,
      loading: props.fetchOnMount,
      error: false,
      ranges: [
        {
          startDate,
          endDate,
          key: RANGES_KEY,
        },
      ],
      showCalendar: true,
    };
  }

  abortController?: AbortController;

  componentDidMount() {
    this._isMounted = true;
    this.props.fetchOnMount && this.fetchStats(this.state.ranges);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.abortController?.abort();
  }

  fetchStats = (ranges: [TRange]) => {
    this._isMounted && this.setState({ loading: true, error: false });
    this.debouncedFetchStats(ranges);
  };

  editRanges = () => this.setState({ showCalendar: true });

  debouncedFetchStats: (ranges: [TRange]) => void = debounce(
    (ranges: [TRange]): void => {
      const { startDate, endDate } = ranges[0];
      const url = this.props.getUrl(startDate, endDate);
      this.abortController?.abort();
      this.abortController = new AbortController();
      get(url, {
        signal: this.abortController?.signal,
      })
        .then((data) => {
          const showCalendar = this.props.hideCalendarAfterFetch
            ? false
            : this.state.showCalendar;
          this.props.onDataFetch && this.props.onDataFetch(data);
          this._isMounted &&
            this.setState({
              fetchedData: data,
              loading: false,
              ranges,
              showCalendar,
            });
        })
        .catch((error) => {
          logError(`Statistics fetch error for url: ${url}`, error);
          this._isMounted &&
            this.setState({ loading: false, error: true, ranges });
        });
    },
    3000
  );

  setData = (newData: T) => {
    this.abortController?.abort();
    this.setState({
      fetchedData: newData,
      loading: false,
    });
  };

  updateRanges = (item: [TRange]) => {
    const newRanges: [TRange] = [item[RANGES_KEY]];
    this.fetchStats(newRanges);
  };

  render() {
    const { allowRefetch } = this.props;
    const { error, fetchedData, loading, ranges, showCalendar } = this.state;
    const datepicker = showCalendar && (
      <OtoDateRangePicker onChange={this.updateRanges} initialRanges={ranges} />
    );
    const reloadBtn = allowRefetch && (
      <button
        type="button"
        className="btn btn-primary mt-250 my-2"
        onClick={() => this.fetchStats(ranges)}
        disabled={loading}
      >
        {i18next.t('Refetch')}
      </button>
    );
    const optionsBag = getMemoizedOptionsBag(ranges, this.editRanges, this.setData);
    if (error) {
      return (
        <>
          {datepicker}
          {reloadBtn}
          <div>
            Wystąpił błąd pobierania danych. Spróbuj odświeżyć stronę, lub
            skontaktuj się z zespołem OtoStolik (dane w zakładce Pomoc)
          </div>
        </>
      );
    }
    if (loading) {
      return (
        <>
          {datepicker}
          <OtoSpinner center />
        </>
      );
    }
    return (
      <>
        {datepicker}
        {reloadBtn}
        {fetchedData && this.props.children(fetchedData, optionsBag)}
      </>
    );
  }
}
