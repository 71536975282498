import React, { useMemo, useState } from 'react';
import i18 from 'i18next';
import { Image } from 'react-feather';
import { Label } from 'reactstrap';
import { toast } from 'react-toastify';

import OtoSpinner from '../common/OtoSpinner';
import ImageUploader from '../common/ImageUploader';
import { OtoButtons } from '../common';
import type { MenuItem } from 'types';

const imageMaxSize = 1048576;
const imageExtensions = ['.jpg', '.jpeg', '.png'];

type ProductEditModalUploadImageProps = {
  isLoading: boolean;
  product: MenuItem;
  onImageUpload: (payload: FormData) => void;
};

const ProductEditModalUploadImage: React.FC<
  ProductEditModalUploadImageProps
> = ({ isLoading, product, onImageUpload }) => {
  const defaultImages = useMemo<string[]>(
    () => (product.image ? [product.image] : []),
    [product.image]
  );

  const [showImageUploader, setShowImageUploader] = useState(false);

  const onDrop = (pictureFiles: File[], pictureDataURLs: string[]) => {
    const existingImage = product.image;
    const formData = new FormData();
    const newImage = pictureFiles[0];
    const imageUrl = pictureDataURLs[0];

    if (newImage) {
      if (newImage.size >= imageMaxSize) {
        toast.error('Rozmiar pliku jest za duży');
        return;
      }
      formData.append('image', newImage, imageUrl);
    }

    if (existingImage) {
      formData.append('image_old', existingImage);
    }

    if (formData.has('image') || formData.has('image_old')) {
      onImageUpload(formData);
    }
  };

  if (isLoading) {
    return <OtoSpinner center />;
  }
  if (!showImageUploader && !defaultImages.length) {
    return (
      <div className="align-vertical">
        <Label className="pr-0 mb-0 mr-2">
          <Image className="mr-1" /> {i18.t('product-edit-modal.no-image')}
        </Label>
        <OtoButtons.AddButton onClick={() => setShowImageUploader(true)}>
          {i18.t('Add')}
        </OtoButtons.AddButton>
      </div>
    );
  }
  return (
    <ImageUploader
      defaultImages={defaultImages}
      withIcon={false}
      buttonText={i18.t('product-edit-modal.choose-image')}
      fileSizeError={i18.t('product-edit-modal.file-too-large')}
      fileTypeError={i18.t('product-edit-modal.extension-error')}
      label={i18.t('product-edit-modal.max-file-size')}
      onChange={onDrop}
      imgExtension={imageExtensions}
      maxFileSize={imageMaxSize}
      singleImage={true}
      withPreview={true}
    />
  );
};

export default ProductEditModalUploadImage;
