import React from 'react';
import PropTypes from 'prop-types';

import { ITable } from 'types/restaurant-table';
import Shapes from 'shapes/main';

interface IProps {
  tables: ITable[];
}

export const getTablesMinCapacity = (tables: ITable[]) => tables.reduce((acc, table) => acc + table.min_people, 0);
export const getTablesMaxCapacity = (tables: ITable[]) => tables.reduce((acc, table) => acc + table.max_people, 0);

const TablesCapacity: React.FC<IProps> = ({ tables }) => {
  if (tables?.length < 2) {
    return null;
  }
  const min = getTablesMinCapacity(tables)
  const max = getTablesMaxCapacity(tables)
  return (
    <div>
      Łączna pojemność stolików:{' '}
      <strong>
        {min} - {max}os.
      </strong>
    </div>
  );
};

TablesCapacity.propTypes = {
  tables: PropTypes.arrayOf(Shapes.restaurantTableShape.isRequired).isRequired,
};

export default TablesCapacity;
