import PropTypes from 'prop-types';

const restaurantPlaceShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
  is_outside: PropTypes.bool.isRequired,
  is_active: PropTypes.bool.isRequired,
});

export default restaurantPlaceShape;
