import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

import ArrayHelpers from 'utils/array';
import { ORDER_STATUS } from 'config';
import SimpleTableWithSorting, {
  generateColumn,
} from '../common/SimpleTableWithSorting';
import { fixDecimal } from 'utils/general';

const getGroupedData = memoizeOne((rawOrdersData) => {
  const normalizedOrdersData = rawOrdersData.map((item) => {
    const { restaurant } = item;
    return {
      ...item,
      restaurantId: restaurant?.id,
      restaurantName: restaurant?.name,
    };
  });
  const totalGroupedByRestaurant = ArrayHelpers.groupArrayByFields(
    normalizedOrdersData,
    ['restaurantId'],
    'price'
  );
  const declinedGroupedByRestaurant = ArrayHelpers.groupArrayByFields(
    normalizedOrdersData.filter(
      (order) => order.status === ORDER_STATUS.DECLINED
    ),
    ['restaurantId'],
    'price'
  );
  const totalDict = totalGroupedByRestaurant.reduce((dict, item) => {
    dict[item.restaurantId] = item;
    return dict;
  }, {});

  const declinedDict = declinedGroupedByRestaurant.reduce((dict, item) => {
    dict[item.restaurantId] = item;
    return dict;
  }, {});

  return Object.keys(totalDict)
    .map((restaurantId: string) => ({
      restaurantId,
      restaurantName: totalDict[restaurantId].restaurantName,
      totalOrdersAmount: totalDict[restaurantId].amount,
      declinedOrdersAmount: declinedDict[restaurantId]?.amount || 0,
    }))
    .map((item) => ({
      ...item,
      declinedOrdersPercent:
        fixDecimal(item.declinedOrdersAmount / item.totalOrdersAmount) * 100,
    }));
});

const cols = [
  generateColumn('restaurantName'),
  generateColumn('totalOrdersAmount'),
  generateColumn('declinedOrdersAmount'),
  generateColumn('declinedOrdersPercent'),
];

const AdminDeclinedOrdersStats = (props) => {
  const { data } = props;
  const groupedData = getGroupedData(data);
  return <SimpleTableWithSorting columns={cols} data={groupedData} />;
};

AdminDeclinedOrdersStats.propTypes = {
  data: PropTypes.array.isRequired,
};

export default React.memo(AdminDeclinedOrdersStats);
