import React from 'react';
import { Button, Card, CardBody, CardHeader, Input } from 'reactstrap';
import i18next from 'i18next';

import { LOG_BAG } from 'utils/log';
import { NormalizedRestaurant } from 'types';

interface IProps {
  restaurant: NormalizedRestaurant;
}

const ReportProblemForm: React.FC<IProps> = ({ restaurant }) => {
  const [value, setValue] = React.useState('');
  const [formSent, setFormSent] = React.useState(false);

  const submit = () => {
    LOG_BAG.logImportantActivity(
      `Restauracja ${restaurant.name} zgłosiła błąd w admin panelu: ${value}`
    );
    setValue('');
    setFormSent(true);
  };

  return (
    <Card>
      <CardHeader>{i18next.t('contact-page.report-problem.title')}</CardHeader>
      <CardBody>
        {formSent ? (
          <p>{i18next.t('contact-page.report-problem.thanks')}</p>
        ) : (
          <>
            <p>{i18next.t('contact-page.report-problem.desc')}</p>
            <Input
              type="textarea"
              placeholder={i18next.t('contact-page.report-problem.placeholder')}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="mb-3"
            />
            <Button
              type="button"
              color="primary"
              onClick={submit}
              disabled={!value}
            >
              Wyślij
            </Button>
          </>
        )}
      </CardBody>
    </Card>
  );
};

ReportProblemForm.propTypes = {};

export default ReportProblemForm;
