import React, { PureComponent } from 'react';
import { NavLink, matchPath } from 'react-router-dom';
import { Alert } from 'reactstrap';

import {
  Header,
  SidebarNav,
  PageContent,
  PageAlert,
  Page,
  Footer,
  PageLoader,
} from 'app/theme';
import PageModal from 'app/containers/PageModal';
import ReservationAlertsContainer from 'app/containers/ReservationAlertsContainer';
import {
  CenterCardSpinner,
  OtoButtons,
  ReportBug,
} from 'app/components/common';
import LoggedAs from 'app/components/LoggedAs';
import ViewLanguagePicker from 'app/views/ViewLanguagePicker';
import PushNotificationErrorAlert from 'app/components/notifications/PushNotificationErrorAlert';
import { isUntilMd } from 'utils/ui';
import { LANGS } from 'config';
import type {
  NormalizedRestaurant,
  OriginalRestaurant,
  User,
} from 'types';
import type { IRoute } from 'routes';
import PageAlertContext from 'app/theme-components/PageAlert/PageAlertContext';

interface DashboardLayoutProps {
  footer?: React.ReactNode;
  messagingError: any;
  lang: string;
  location: any;
  onChangeRestaurantClick: (restaurant: OriginalRestaurant) => void;
  onPageNameChange: (pageName: string) => void;
  onLogoutClick: () => void;
  nav: {
    bottom: any[];
    top: any[];
  };
  renderLogo: (params: {
    isShort: boolean;
    className?: string;
  }) => React.ReactNode;
  restaurant: NormalizedRestaurant | null;
  restaurantLoading: boolean;
  role: number;
  routes: IRoute[];
  user: User;
  userRestaurants?: OriginalRestaurant[];
}

interface DashboardLayoutState {
  sidebarCollapsed: boolean;
  isMobile: boolean;
}

export default class DashboardLayout extends PureComponent<
  DashboardLayoutProps,
  DashboardLayoutState
> {
  constructor(props: DashboardLayoutProps) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: isUntilMd(),
    };
  }

  static displayName = 'DashboardLayout';

  handleResize = () => {
    if (isUntilMd()) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prev: DashboardLayoutProps) {
    const locationChanged =
      this.state.isMobile &&
      prev.location.pathname !== this.props.location.pathname;
    const sidebarOpened = this.state.sidebarCollapsed;
    if (locationChanged && sidebarOpened) {
      this.toggleSideCollapse();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    this.setState((prevState) => ({
      sidebarCollapsed: !prevState.sidebarCollapsed,
    }));
  };

  render() {
    const {
      footer,
      onChangeRestaurantClick,
      onPageNameChange,
      location,
      restaurant,
      restaurantLoading,
      role,
      renderLogo,
      routes,
      user,
      userRestaurants,
    } = this.props;

    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    const isActiveRoute = (route: { path: string }) =>
      !!matchPath(
        {
          path: route.path,
          end: true,
          caseSensitive: true,
        },
        location.pathname
      ) || false;

    return (
      <>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlertContext.Consumer>
            {(context) => (
              <PageAlert
                alert={context.alert}
                onClose={context.closeAlert}
              />
            )}
          </PageAlertContext.Consumer>
          <PageModal />
          {restaurantLoading || !restaurant ? (
            <CenterCardSpinner text="Pobieram dane restauracji" />
          ) : (
            <div className="app-body">
              <PageAlertContext.Consumer>
                {(consumer) => (
                  <SidebarNav
                    isSidebarCollapsed={sidebarCollapsed}
                    logo={renderLogo({ isShort: sidebarCollapsed })}
                    LinkComponent={NavLink}
                    hasAlert={!!consumer.alert}
                    nav={this.props.nav}
                    toggleSidebar={this.toggleSideCollapse}
                  />
                )}
              </PageAlertContext.Consumer>
              <Page>
                <Header
                  isActiveRoute={isActiveRoute}
                  isSidebarCollapsed={sidebarCollapsed}
                  onPageNameChange={onPageNameChange}
                  onToggleSidebarClick={this.toggleSideCollapse}
                  routes={routes}
                  pageLoader={<PageLoader />}
                >
                  <LoggedAs
                    onChangeRestaurantClick={onChangeRestaurantClick}
                    role={role}
                    restaurant={restaurant}
                    user={user}
                    userRestaurants={userRestaurants}
                  />
                  <div className="d-flex flex-row justify-content-between">
                    <ViewLanguagePicker />
                    <OtoButtons.LogoutButton
                      onClick={this.props.onLogoutClick}
                    />
                  </div>
                </Header>
                <PageContent>
                  {renderLogo({
                    isShort: false,
                    className: 'print-only',
                  })}
                  {this.props.lang !== LANGS.default && (
                    <Alert color="secondary">
                      <div>
                        EN translations are in progress and are not 100% ready.{' '}
                      </div>
                      <div>PL will be used as a fallback language.</div>
                    </Alert>
                  )}
                  <ReportBug />
                  <ReservationAlertsContainer />
                  {this.props.messagingError && (
                    <PushNotificationErrorAlert restaurant={restaurant} />
                  )}
                  {this.props.children}
                </PageContent>
              </Page>
            </div>
          )}
        </div>
        {footer && <Footer>{footer}</Footer>}
      </>
    );
  }
}
