import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import { IOrder } from 'types/order';
import Shapes from 'shapes/main';
import OrderDelivery from '../OrderDelivery';
import OrderRealizationTime from '../OrderRealizationTime';
import Order from 'utils/orders';

const AcceptViewOrderModalBody: React.FC<{
  hasCustomTime: boolean;
  order: IOrder;
}> = ({ hasCustomTime, order }) => {
  const deliveryAtPart = hasCustomTime && (
    <>
      <OrderRealizationTime className="mt-2" order={order} />
      <div className="mt-1">
        {i18next.t('order-modal.contact-customer-if-not-enough-time')}
      </div>
    </>
  );

  return (
    <>
      <OrderDelivery order={order} />
      {getAcceptViewOrderModalText(order, hasCustomTime)}
      {deliveryAtPart}
    </>
  );
};

AcceptViewOrderModalBody.propTypes = {
  hasCustomTime: PropTypes.bool.isRequired,
  order: Shapes.orderShape.isRequired,
};

export function getAcceptViewOrderModalTitle(order: IOrder, hasCustomTime: boolean) {
  if(Order.hasRobotDelivery(order)) {
    return i18next.t('order-modal.accept-order.title-robot-delivery-set-food-prepare-time')
  }
  if(hasCustomTime) {
    return i18next.t('order-modal.accept-order.title-new-order-with-strict-time')
  }
  return i18next.t('order-modal.accept-order.title-default-set-prepare-time')
}

export function getAcceptViewOrderModalText(order: IOrder, hasCustomTime: boolean) {
  if(hasCustomTime || Order.isSelfCollect(order)) {
    return null
  }
  if(Order.hasRobotDelivery(order)) {
    return i18next.t('order-modal.accept-order.content-robot-delivery-set-only-food-prepare-time')
  }
  return i18next.t('order-modal.accept-order.content-default-take-courrier-time-into-account')
}

export default AcceptViewOrderModalBody;
