import React from 'react';
import { PatternFormat } from 'react-number-format';
import { Phone } from 'react-feather';

import InputWithAddon from './InputWithAddon';

interface IOtoPhoneInputProps {
  className?: string;
  id: string;
  name: string;
  placeholder?: string;
  value: string;
  disabled?: boolean;
  required?: boolean;
  setFieldValue: (name: string, value: string) => void;
}

const FormattedPhoneInput = (props: IOtoPhoneInputProps) => {
  // note setFieldValue no onChange usage. this is beecause it passed formatted value by default
  // to obtain plain value we have to use onValueChange callback prop
  // type=tel is used for number as well to show numbers only keyboard on mobile devices
  return (
    <PatternFormat
      format="+## ### ### ###"
      mask="_"
      customInput={InputWithAddon}
      addonText={<Phone />}
      addonType="prepend"
      onValueChange={(valuesObject) =>
        props.setFieldValue(props.name, valuesObject.value)
      }
      isNumericString
      type="tel"
      className={props.className || ''}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder || ''}
      value={props.value}
      disabled={props.disabled || false}
      required={props.required || false}
    />
  );
};

export const OtoInputs = {
  Phone: FormattedPhoneInput,
};

export default OtoInputs;
