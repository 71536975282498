import MarketingPage from '../app/pages/MarketingPage';

export const marketingNavItem = {
  name: 'Marketing',
  icon: 'DollarSign',
  children: [
    {
      // @ts-ignore todo fix props
      name: MarketingPage.navName,
      icon: 'Users',
      // @ts-ignore todo fix props
      url: MarketingPage.url,
    },
    {
      name: 'Kody promocyjne',
      icon: 'Gift',
      url: '/settings/promo-codes',
    },
  ],
};

export default marketingNavItem;
