import React from 'react';
import { toDateInputValue } from 'utils/date-time';
import WithDateRangeDataFetched from '../../containers/WithDateRangeDataFetched';

interface ITwilioStats {
  by_type?: number;
  by_restaurant?: number;
}

const getUrl = (start: Date, end: Date) =>
  `/statistics/twilio-calls?from=${toDateInputValue(
    start
  )}&to=${toDateInputValue(end)}`;

const TwilioCallsStatistics = () => {
  return (
    <WithDateRangeDataFetched<ITwilioStats> allowRefetch getUrl={getUrl}>
      {(data) => {
        return (
          <div>
            <h3>Twilio calls data goes here</h3>
            <div>{JSON.stringify(data)}</div>
          </div>
        );
      }}
    </WithDateRangeDataFetched>
  );
};

TwilioCallsStatistics.displayName = 'TwilioCallsStatistics';

export default TwilioCallsStatistics;
