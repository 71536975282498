import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Container,
} from 'reactstrap';
import {
  ArrowUpCircle,
  ArrowDownCircle,
  PlusCircle,
  AlertCircle,
} from 'react-feather';
import i18 from 'i18next';

import OtoSpinner from '../common/OtoSpinner';
import IconWithTooltip from '../common/IconWithTooltip';
import OtoToggle from '../common/OtoToggle';
import OtoButtons from '../common/OtoButtons';

import PreviewLink, { RestaurantPreviewType } from '../../views/ViewPreviewLink';

import { RwdService } from 'utils/ui';
import Shapes from 'shapes/main';
import MenuErrors from './MenuErrors';
import MenuProductCard from './MenuProductCard';
import MenuAddonFieldSets from './MenuAddonFieldSets';
import CardWithInput from './CardWithInput';
import type {
  Category,
  MenuAddonFieldsSet,
  MenuItem,
  NormalizedRestaurant,
} from 'types';
import CollapsibleCard from '../common/CollapsibleCard';
import MenuProductCardDetails from './MenuProductCardDetails';

import './menu-edit.scss';
import './ProductCard.scss';

interface IProps {
  allowPlainText: boolean;
  onCategoryOrderChange: null | ((catIndex: number, direction: number) => void);
  onProductOrderChange:
    | null
    | ((catIndex: number, prodIndex: number, direction: number) => void);
  editableProduct: MenuItem | null;

  onCategoryAdd: null | ((name: string) => void);
  onCategoryNameChange: null | ((category: Category) => void);
  onCategoryTextChange: null | ((category: Category) => void);
  onCategoryDelete: null | ((category: Category) => void);
  onProductAdd: null | ((catIndex: number) => void);
  onProductEdit: null | ((catIndex: number, prodIndex: number) => void);
  onProductDelete: null | ((catIndex: number, prodIndex: number) => void);

  getMenuItemsWhichUseAddonSet: (set: MenuAddonFieldsSet) => MenuItem[];
  menuLoading: boolean;
  menu: NormalizedRestaurant['menu'];
  menuKey: RestaurantPreviewType;
  handleMenuKeyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onProductDisabledToggle: (
    e: React.ChangeEvent<HTMLInputElement>,
    catIndex: number,
    prodIndex: number
  ) => void;
  onImageError: (product: MenuItem, e: any) => void;
  restaurant: NormalizedRestaurant;
  showAddonSets: boolean;
  updateMenu: (newMenu: NormalizedRestaurant['menu']) => void;
  saveAddonSets: (newSets: MenuAddonFieldsSet[]) => void;
  wasEdited: boolean;
}

type TState = {
  plainText: boolean;
  showDetails: boolean;
};

export default class MenuEditComponent extends React.PureComponent<
  IProps,
  TState
> {
  state: TState = {
    plainText: false,
    showDetails: false,
  };

  static propTypes = {
    allowPlainText: PropTypes.bool,
    onCategoryOrderChange: PropTypes.func.isRequired,
    onProductOrderChange: PropTypes.func.isRequired,
    menuLoading: PropTypes.bool.isRequired,
    menuKey: PropTypes.string.isRequired,
    handleMenuKeyChange: PropTypes.func.isRequired,

    onCategoryAdd: PropTypes.func,
    onCategoryDelete: PropTypes.func,
    onCategoryNameChange: PropTypes.func,
    onCategoryTextChange: PropTypes.func,
    onProductAdd: PropTypes.func,
    onProductEdit: PropTypes.func,
    onProductDelete: PropTypes.func,

    onProductDisabledToggle: PropTypes.func.isRequired,
    restaurant: Shapes.restaurantShape,
    showAddonSets: PropTypes.bool,
    updateMenu: PropTypes.func.isRequired,
    saveAddonSets: PropTypes.func.isRequired,
    wasEdited: PropTypes.bool.isRequired,
  };

  static displayName = 'MenuEditComponent';

  toggleShowDetails = (e) => this.setState({ showDetails: e.target.checked });

  render() {
    if (this.props.menuLoading) {
      return <OtoSpinner center />;
    }
    const { menu, menuKey, onCategoryOrderChange, restaurant } = this.props;
    const categories = menu?.categories || [];
    const addonFieldsSets = menu?.addonFieldsSets || [];
    return (
      <>
        {this.renderNav()}
        <PreviewLink className="mt-2" type={menuKey} restaurant={restaurant} />
        {this.props.allowPlainText && (
          <>
            {this.state.plainText ? (
              <Button
                type="button"
                color="info"
                className="mt-2 ml-2"
                onClick={() => this.setState({ plainText: false })}
              >
                Edytuj kafelki
              </Button>
            ) : (
              <Button
                type="button"
                color="info"
                className="mt-2 ml-2"
                onClick={() => this.setState({ plainText: true })}
              >
                Edytuj JSON
              </Button>
            )}
          </>
        )}
        {this.state.plainText && (
          <Input
            className="mt-2"
            type="textarea"
            rows="10"
            value={JSON.stringify(menu)}
            onChange={(e) => {
              try {
                const newMenu = JSON.parse(e.target.value);
                this.props.updateMenu(newMenu);
              } catch (e) {
                alert(
                  'Wklejony JSON jest niepoprawny! Upewnij się, że wklejasz cały JSON, a nie kasujesz poszczególne znaki.'
                );
                console.error(e);
              }
            }}
          />
        )}
        <MenuErrors
          addonFieldsSets={addonFieldsSets}
          categories={categories}
          restaurant={restaurant}
        />
        <section>
          {categories.length > 0 ? (
            categories.map((category, catIndex) => (
              <div key={category.name}>
                <Row className="mt-2 mx-0 align-items-center">
                  <h2 className="mb-0">{category.name}</h2>
                  {this.props.onCategoryNameChange && (
                    <Button
                      type="button"
                      color="link"
                      className="py-0"
                      onClick={() => this.props.onCategoryNameChange!(category)}
                    >
                      ({i18.t('menu-edit.rename')})
                    </Button>
                  )}
                  {catIndex > 0 && onCategoryOrderChange && (
                    <ArrowUpCircle
                      className="cursor-pointer mr-2"
                      aria-label="przenieś kategorię wyżej"
                      onClick={() => onCategoryOrderChange(catIndex, -1)}
                    />
                  )}
                  {catIndex + 1 < categories.length &&
                    onCategoryOrderChange && (
                      <ArrowDownCircle
                        className="cursor-pointer"
                        aria-label="przenieś kategorię niżej"
                        onClick={() => onCategoryOrderChange(catIndex, 1)}
                      />
                    )}
                  {this.props.onCategoryDelete && (
                    <OtoButtons.DeleteButton
                      onClick={() => this.props.onCategoryDelete!(category)}
                      className="ml-2"
                    />
                  )}
                </Row>
                <Row className="mx-0 mb-2">
                  <div>
                    {i18.t('menu-edit.category-text')}:{' '}
                    {category.text || `${i18.t('menu-edit.none')}`}{' '}
                    <IconWithTooltip
                      id={`cat-text-${catIndex}`}
                      text={i18.t('menu-edit.tooltip-text')}
                    />{' '}
                    {this.props.onCategoryTextChange && (
                      <Button
                        type="button"
                        color="link"
                        className="p-0"
                        onClick={() =>
                          this.props.onCategoryTextChange!(category)
                        }
                      >
                        ({i18.t('Change')})
                      </Button>
                    )}
                  </div>
                </Row>
                <CollapsibleCard
                  bodyClassName="row mx-0"
                  title={`Produkty kategorii ${category.name} (${category.items.length} szt.)`}
                >
                  {category.items.map((product, prodIndex) =>
                    this.renderProduct(product, prodIndex, catIndex)
                  )}
                  {this.props.onProductAdd && (
                    <Card
                      key="add-new"
                      className="product-card"
                      onClick={() => this.props.onProductAdd!(catIndex)}
                    >
                      <CardBody className="align-vertical">
                        <Button
                          type="button"
                          color="link"
                          className="align-vertical"
                        >
                          <PlusCircle size="30" />
                          <span className="add-new-product">
                            {i18.t('menu-edit.add-new-item')} {category.name}
                          </span>
                        </Button>
                      </CardBody>
                    </Card>
                  )}
                </CollapsibleCard>
              </div>
            ))
          ) : (
            <div className="mt-1">{i18.t('menu-edit.no-categories')}</div>
          )}
          {this.props.onCategoryAdd && (
            <CardWithInput
              buttonClassName={`align-vertical pl-0`}
              onAdd={this.props.onCategoryAdd}
              placeholder={i18.t('menu-edit.new-category-name')}
              prefix={i18.t('menu-edit.add-category')}
            />
          )}
        </section>
        {this.props.showAddonSets && (
          <>
            <hr />
            <Container fluid className="flex-column">
              <MenuAddonFieldSets
                getMenuItemsWhichUseAddonSet={
                  this.props.getMenuItemsWhichUseAddonSet
                }
                onSetsSave={this.props.saveAddonSets}
                sets={addonFieldsSets}
              />
            </Container>
          </>
        )}
      </>
    );
  }

  renderNav() {
    const { menuKey, handleMenuKeyChange, restaurant } = this.props;
    const { showDetails } = this.state;
    const hasOnPlaceMenu = !!restaurant.menu_on_place;
    const isPhoneOrTablet = RwdService.getIsUntilMd();

    const restContent = (
      <>
        <OtoToggle
          className="mr-2 d-flex flex-row"
          checked={showDetails}
          id="showDetails"
          name="showDetails"
          value="true"
          onChange={this.toggleShowDetails}
        />
        <Label className="d-flex  my-2 my-mb-0">
          <span>{i18.t('menu-edit.show-details')}</span>
        </Label>
        {hasOnPlaceMenu && (
          <Input
            type="select"
            value={menuKey}
            onChange={handleMenuKeyChange}
            className="mw-200"
          >
            <option value="menu">{i18.t('menu-edit.menu-for-delivery')}</option>
            <option value="menu_on_place">
              {i18.t('menu-edit.premises-menu')}
            </option>
          </Input>
        )}
      </>
    );
    return (
      <nav className="products-menu-navbar">
        {isPhoneOrTablet ? (
          <Card className="m-0 p-2 text-left w-100" titleWithButtons>
            {restContent}
          </Card>
        ) : (
          <>{restContent}</>
        )}
      </nav>
    );
  }

  renderUnsavedChanges(className = '') {
    return (
      this.props.wasEdited && (
        <div
          className={`d-inline-flex text-danger align-items-center mx-md-2 ${className}`}
        >
          <AlertCircle />
          <span className="mx-2">{i18.t('menu-edit.unsaved-changes')}</span>
          <IconWithTooltip
            id="unsaved-changes"
            text="Kliknij zapisz przed porzuceniem strony, albo dokonane zmiany nie będą zapisane"
          />
        </div>
      )
    );
  }

  renderProduct = (product: MenuItem, prodIndex: number, catIndex: number) => {
    const { menu, onProductOrderChange } = this.props;
    const { showDetails } = this.state;
    const categories = menu.categories || [];
    return (
      <MenuProductCard
        key={`${catIndex}${prodIndex}`}
        allowMovePrev={prodIndex > 0}
        allowMoveNext={prodIndex + 1 < categories[catIndex].items.length}
        onMoveBackwardClick={
          onProductOrderChange
            ? () => onProductOrderChange(catIndex, prodIndex, -1)
            : null
        }
        onMoveForwardClick={
          onProductOrderChange
            ? () => onProductOrderChange(catIndex, prodIndex, 1)
            : null
        }
        onDisabledToggle={(e) =>
          this.props.onProductDisabledToggle(e, catIndex, prodIndex)
        }
        onEdit={
          this.props.onProductEdit
            ? () => this.props.onProductEdit!(catIndex, prodIndex)
            : null
        }
        onDelete={
          this.props.onProductDelete
            ? () => this.props.onProductDelete!(catIndex, prodIndex)
            : null
        }
        product={product}
      >
        <MenuProductCardDetails
          onImageError={this.props.onImageError}
          product={product}
          showDetails={showDetails}
        />
      </MenuProductCard>
    );
  };
}
