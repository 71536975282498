import PropTypes from 'prop-types';

import ordersConfigShape from './orders-config';

const originalConfigShape = PropTypes.exact({
  available_seats: PropTypes.number.isRequired,
  available_tables: PropTypes.number.isRequired,
  auto_confirm_before: PropTypes.number.isRequired,
  reservation_duration: PropTypes.number.isRequired,
  has_area_preference_possibility: PropTypes.bool.isRequired,
  orders: ordersConfigShape.isRequired,
});

export default originalConfigShape;
