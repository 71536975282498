import React from 'react';

import { Card, CardText, CardTitle, Progress } from 'reactstrap';

type NumberWidgetProps = {
  title: string;
  subtitle: string;
  number: string | number;
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
  progress: {
    value: number;
    label: string;
  };
};

const NumberWidget: React.FC<NumberWidgetProps> = ({
  title,
  subtitle,
  number,
  color,
  progress: { value, label },
  ...restProps
}) => {
  return (
    <Card body {...restProps}>
      <div className="d-flex justify-content-between">
        <CardText tag="div">
          <div className="mb-0">
            <strong>{title}</strong>
          </div>
          <div className="mb-0 text-muted small">{subtitle}</div>
        </CardText>
        <CardTitle className={`text-${color}`}>{number}</CardTitle>
      </div>
      <Progress
        value={value}
        color={color || (value > 0 ? 'success' : 'danger')}
        style={{
          // justifyContent: value > 0 ? 'flex-start' : 'flex-end',
          height: '8px',
        }}
      />
      <CardText tag="div" className="d-flex justify-content-between">
        <span className="text-left text-muted small">{label}</span>
        <span className="text-right text-muted small">{value}%</span>
      </CardText>
    </Card>
  );
};

NumberWidget.defaultProps = {
  title: '',
  subtitle: '',
  number: 0,
  color: 'primary',
  progress: {
    value: 0,
    label: '',
  },
};

export default NumberWidget;
