import filterXss from 'xss';
import { toast } from 'react-toastify';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { isProd, APP_REFRESH_TIMEOUT } from 'config';

const FORCE_INTERACTION = 'forceInteraction';

const fpPromise = FingerprintJS.load();

export const getVisitorId = async () => {
  const fp = await fpPromise;
  const result = await fp.get();
  console.log('Your unique visitor id: ', result.visitorId);
  return result.visitorId;
};

export const getLsFbToken = () => {
  const data = filterXss(localStorage.getItem('firebaseToken') || '');
  return data ? JSON.parse(data) : {};
};
export const setLsFbToken = (token) =>
  localStorage.setItem(
    'firebaseToken',
    JSON.stringify({
      token,
      updatedAt: new Date(),
    })
  );

export const getForceInteraction = () =>
  localStorage.getItem(FORCE_INTERACTION);
export const setForceInteraction = (token) =>
  localStorage.setItem(FORCE_INTERACTION, token);

export const checkToken = () => {
  if (localStorage.getItem('token')) {
    return true;
  }
  window.location.pathname = '/login';
  toast.info('Zaloguj się, by móc kontynuować');
};

export const checkAppVersion = (
  keyName = 'lastRefreshDate',
  timeout = APP_REFRESH_TIMEOUT
) => {
  if (!isProd) {
    return;
  }
  detectCodeChanged().then((hasCodeChanged) => {
    if (hasCodeChanged) {
      hardRefreshApp(keyName);
    }
  });
};

function hardRefreshApp(keyName) {
  localStorage.setItem(keyName, new Date().toString().slice(0, 33));
  setForceInteraction(true);
  window.location.reload();
}

function detectCodeChanged() {
  const STORAGE_KEY = 'index.html';
  return fetch('/index.html')
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('offline');
      }
      return response.text();
    })
    .then((html) => {
      let previousHtml = filterXss(localStorage.getItem(STORAGE_KEY) || '');
      if (previousHtml !== filterXss(html)) {
        localStorage.setItem(STORAGE_KEY, filterXss(html));
        return true;
      }
      return false;
    })
    .catch(() => {
      /* do nothing */
      return false;
    });
}
