import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import i18next from 'i18next';
import * as Feather from 'react-feather';

import { getAdminNavPart } from 'nav/admin';
import TenantDashboard from 'app/components/admin/TenantDashboard';
import { Tenants } from 'store/tenants';
import EnhancedOrdersListPage from '../OrdersListPage';

const DashboardPageForRootAdmin = () => {
  const className =
    'd-flex flex-row min-w-150 mw-250 p-3 mr-3 align-vertical justify-content-center';

  const pages = getAdminNavPart().children;

  return (
    <>
      <div className="d-flex flex-row flex-wrap">
        <Card className={className} tag={Link} to={EnhancedOrdersListPage.url}>
          <Feather.Clipboard className="mr-2" />
          <span>{i18next.t(`nav.${EnhancedOrdersListPage.navName}`)}</span>
        </Card>
        {pages.map((page) => {
          const FeatherIcon = page.icon ? Feather[page.icon] : null;
          if (!page.url) {
            console.warn(`Page ${page.name} has no url`);
            return null;
          }
          return (
            <Card className={className} tag={Link} key={page.url} to={page.url}>
              <FeatherIcon className="mr-2" />
              <span>{i18next.t(`nav.${page.name}`)}</span>
            </Card>
          );
        })}
      </div>
      <TenantDashboard tenant={Tenants.LUBJE} />
    </>
  );
};

DashboardPageForRootAdmin.url = '/admin/dashboard';
DashboardPageForRootAdmin.navName = 'admin.dashboard';

export default DashboardPageForRootAdmin;
