import DateTimeHelper from './date-time';

export default DateTimeHelper.toDateInputValue;

export const isElectron = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') !== -1;
};

export function debounce(func: Function, wait: number, immediate?: boolean) {
  let timeout;
  return function () {
    // @ts-ignore allow this context change as it's a copied function
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function throttle(func, limit) {
  let lastFunc;
  let lastRan;
  return function () {
    // @ts-ignore allow this context change as it's a copied function
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

export function fixDecimal(num) {
  return Math.round(num * 100) / 100;
}

export function formatThousands(x) {
  return fixDecimal(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function whatHasChanged<T>(propsA: Record<string, any>, propsB: Record<string, any>, fieldsToExclude: string[] = []): (keyof T)[] {
  const keysThatChanged = Object.keys(propsB)
    .filter((key) => !fieldsToExclude.includes(key))
    .filter((key) => propsA[key] !== propsB[key]);
  return keysThatChanged as unknown as (keyof T)[];
}

export function truncate(source: string, size: number) {
  return source.length > size ? source.slice(0, size - 1) + '…' : source;
}

export const loadCssFile = (name: string) => {
  let file = `/${name}`;
  let link = document.createElement('link');
  link.href = file.substr(0, file.lastIndexOf('.')) + '.css';
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.media = 'screen,print';

  document.getElementsByTagName('head')[0]?.appendChild(link);
};
