import PropTypes, { Requireable } from 'prop-types';

import ordersConfigShape from './orders-config';
import originalConfigShape from './original-config';
import reservationConfigShape from './reservation-config';
import menuShape from './restaurant-menu';
import tableShape from './restaurant-table';
import { NormalizedRestaurant, OriginalRestaurant } from 'types';

export const hourShape = PropTypes.exact({
  close_at: PropTypes.string.isRequired,
  close_day: PropTypes.number.isRequired,
  is_closed: PropTypes.bool.isRequired,
  open_at: PropTypes.string.isRequired,
  open_day: PropTypes.number.isRequired,
});

export const customRuleShape = PropTypes.exact({
  key: PropTypes.string.isRequired,
  text: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  disableBook: PropTypes.bool.isRequired,
  disableOrder: PropTypes.bool,
});

const plainRestaurantFields = {
  city_id: PropTypes.number.isRequired,
  created_at: PropTypes.string.isRequired,
  cuisine_label: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  hero_images: PropTypes.arrayOf(PropTypes.string.isRequired),
  logo: PropTypes.string,
  
  location: PropTypes.string.isRequired,
  lat: PropTypes.number,
  lng: PropTypes.number,

  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  slug: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  cuisine_type: PropTypes.string.isRequired, // TODO remove
};

const commonComplexFields = {
  custom_rules: PropTypes.arrayOf(customRuleShape.isRequired).isRequired,
  menu: menuShape.isRequired,
  menu_on_place: menuShape,
  opening_hours: PropTypes.array.isRequired,
  restaurant_opening_hours: PropTypes.arrayOf(hourShape.isRequired).isRequired,
  delivery_opening_hours: PropTypes.arrayOf(hourShape.isRequired).isRequired,
  opening_hours_relation: PropTypes.arrayOf(hourShape),
};

export const plainRestaurantShape = PropTypes.shape(plainRestaurantFields);

export const originalRestaurantShape = PropTypes.shape({
  ...plainRestaurantFields,
  ...commonComplexFields,
  config: originalConfigShape.isRequired,
}) as unknown as Requireable<OriginalRestaurant>;

const restaurantShape = PropTypes.shape({
  ...plainRestaurantFields,
  ...commonComplexFields,
  config: reservationConfigShape.isRequired,
  // generated after normalization on FE
  orders: ordersConfigShape.isRequired,
  tablesConfig: PropTypes.arrayOf(tableShape.isRequired).isRequired,
}) as unknown as Requireable<NormalizedRestaurant>;

export default restaurantShape;
