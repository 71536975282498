import React from 'react';
import * as Sentry from '@sentry/react';

import { useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';
import { selectUser } from 'store/app';

const SentryInitTags = () => {
  const restaurant = useAppSelector(selectRestaurant);
  const user = useAppSelector(selectUser);
  const restaurantId = restaurant?.id;

  React.useEffect(() => {
    restaurantId && Sentry.setTag('restaurantId', restaurantId);
  }, [restaurantId]);

  React.useEffect(() => {
    user?.email && Sentry.setTag('userEmail', user?.email);
  }, [user?.email]);

  return null;
};

export default SentryInitTags;
