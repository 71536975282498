import { EventSourceInput } from '@fullcalendar/core';

import type {
  AddReservationFormValues,
  ISavedReservation,
  ITable,
  ReservationConfig,
  ReservationProposalParams,
  TTableSuggestion,
  TTableSuggestionWithPeopleAmount,
} from 'types';
import {
  addHours,
  countReservationPlacesByDay,
  COUNT_STATUS,
  generateReservedOn,
  isNote,
  getTablesDict,
} from 'utils/reservation';
import { formatReservationTitle } from '../lang';
import { TReservationCalendarView } from './ReservationsCalendar';
import { ReservationCalendarPreferences } from 'store/reservation-calendar';

export interface IReservationCalendarEvent {
  title: string;
  start: string;
  end?: string;
  allDay?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  editable: boolean;
  textColor?: string;
  extendedProps:
    | {
        type: 'reservation';
        reservation: ISavedReservation;
      }
    | { type: 'count-status' }
    | {
        type: 'reservation-proposal';
        tableSuggestion:
          | TTableSuggestionWithPeopleAmount
          | ReservationProposalParams;
      };
  resourceIds?: string[];
}

export function generateReservationEvents(params: {
  config: ReservationConfig;
  defaultDuration: number;
  flexibleTables: boolean;
  reservations: ISavedReservation[];
  preferences: ReservationCalendarPreferences;
  view: TReservationCalendarView;
}): IReservationCalendarEvent[] {
  const {
    config,
    defaultDuration,
    flexibleTables,
    reservations,
    preferences,
    view,
  } = params;

  const { activePlace } = preferences;

  const filteredReservationsByPlace = activePlace
    ? reservations.filter((reservation) => {
        if (!reservation.tables?.length) {
          return true;
        }
        return (
          reservation.tables.filter(
            (table) => table.place_id === activePlace.id
          ).length > 0
        );
      })
    : reservations;

  const events = filteredReservationsByPlace.map((item) => {
    const title: string = formatEventTitle({
      config,
      reservation: item,
      showReservationNotes: preferences.showReservationNotes,
      view,
    });

    const baseProps: IReservationCalendarEvent = {
      title,
      start: item.reserved_on,
      editable: true,
      extendedProps: {
        type: 'reservation',
        reservation: item,
      },
      allDay: item.is_entire_place,
    };

    if (!flexibleTables) {
      return baseProps;
    }
    const tableResourceProps: Partial<IReservationCalendarEvent> =
      item.tables && item.tables.length > 0
        ? {
            resourceIds: item.tables.map((table) => table.id.toString()),
          }
        : {};

    const durationInHours = !!item.duration
      ? item.duration / 60
      : defaultDuration;

    const event: IReservationCalendarEvent = {
      ...baseProps,
      ...tableResourceProps,
      end: addHours(item.reserved_on, durationInHours),
    };
    return event;
  });

  return events;
}

function formatEventTitle({
  config,
  reservation,
  showReservationNotes,
  view,
}: {
  config: ReservationConfig;
  reservation: ISavedReservation;
  showReservationNotes: boolean;
  view: TReservationCalendarView;
}): string {
  if (isNote(reservation)) {
    return reservation.customer_name;
  }
  return formatReservationTitle({
    config,
    reservation,
    showNotes: showReservationNotes,
    view,
  });
}

export function generateNoteEvents(
  reservations: ISavedReservation[],
  options: { addNonVaccinated?: boolean }
): IReservationCalendarEvent[] {
  const reservationsCountByDay = countReservationPlacesByDay(
    reservations,
    options
  );
  return Object.entries(reservationsCountByDay).map((item) =>
    generateNoteEvent(item, options)
  );
}

const generateNoteEvent = (
  [date, stats],
  { addNonVaccinated = false } = {}
): IReservationCalendarEvent => ({
  title: `Zarezerwowano miejsc: ${stats.guestsCount} ${
    addNonVaccinated
      ? `(w tym ${stats.nonVaccinatedCount} os. bez szczepienia)`
      : ''
  }`,
  start: `${date} 00:00`,
  editable: false,
  extendedProps: {
    type: COUNT_STATUS,
  },
});

const shadesOfYellow = [
  'rgba(255, 255, 0, 0.2)',
  'rgba(255, 255, 0, 0.4)',
  'rgba(255, 255, 0, 0.6)',
  'rgba(255, 255, 0, 0.8)',
];

export function generateReservationSuggestionEvents(params: {
  preferences: ReservationCalendarPreferences;
  reservationFormValues: AddReservationFormValues | null;
  tables: ITable[];
  tableSuggestions: TTableSuggestion[] | null;
}) {
  const { preferences, reservationFormValues, tables, tableSuggestions } =
    params;
  const { activePlace } = preferences;

  if (!tableSuggestions || !reservationFormValues) {
    return null;
  }

  const getAllTableIdsFromSuggestion = (
    suggestion: TTableSuggestion
  ): number[] => [
    suggestion.id,
    ...(suggestion.connect ? suggestion.linked_to : []),
  ];

  const reducer = (
    acc: Record<string, TTableSuggestion>,
    suggestion: TTableSuggestion
  ): Record<string, TTableSuggestion> => {
    const keys = getAllTableIdsFromSuggestion(suggestion).sort().join('-');
    if (!acc[keys]) {
      acc[keys] = suggestion;
    }
    return acc;
  };

  const tablesDict = getTablesDict(tables);

  const isSamePlaceAsActive = (suggestion: TTableSuggestion) =>
    !activePlace || suggestion.place_id === activePlace.id;

  const uniqueTableSuggestions: TTableSuggestion[] = Object.values(
    tableSuggestions.filter(isSamePlaceAsActive).reduce(reducer, {})
  );

  const suggestionEvents: EventSourceInput | null = uniqueTableSuggestions.map(
    (suggestion, index) => {
      const reservedOn = generateReservedOn({
        visitDate: reservationFormValues.visitDate,
        visitTime: reservationFormValues.visitTime,
      });

      const tableIds = getAllTableIdsFromSuggestion(suggestion);

      return {
        title: `#${index + 1} - ${
          tableIds.length > 1 ? 'stoliki' : 'stolik'
        } ${tableIds.map((id) => tablesDict[id]?.name).join(', ')}`,
        backgroundColor: shadesOfYellow[index % shadesOfYellow.length],
        textColor: 'black',
        start: reservedOn,
        end: addHours(reservedOn, reservationFormValues.duration / 60),
        extendedProps: {
          type: 'reservation-proposal',
          tableSuggestion: suggestion,
        },
        resourceIds: tableIds.map((id) => id.toString()),
      };
    }
  );
  return suggestionEvents;
}
