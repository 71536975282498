import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { VIEWS } from 'config';

interface ViewTogglerProps {
  activeView: {
    text: string;
    icon: React.ReactNode;
  };
  className?: string;
  onViewChange: (view: { text: string; icon: React.ReactNode }) => void;
}

export const ViewToggler: React.FC<ViewTogglerProps> = ({
  activeView,
  className = '',
  onViewChange,
}) => (
  <div className={className}>
    {Object.values(VIEWS).map((view) => (
      <Button
        key={view.text}
        id={view.text}
        type="button"
        className={`btn position-relative ${
          activeView.text === view.text ? 'btn-primary' : 'btn-secondary'
        }`}
        onClick={() => onViewChange(view)}
      >
        <view.icon
          color={
            activeView.text === view.text
              ? 'var(--primary)'
              : 'var(--secondary)'
          }
        />
      </Button>
    ))}
  </div>
);

ViewToggler.propTypes = {
  activeView: PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
  className: PropTypes.string,
  onViewChange: PropTypes.func.isRequired,
};

export default ViewToggler;
