import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import DeliveryTile from './DeliveryItem/DeliveryTile';
import DeliveryHistory from './DeliveryItem/DeliveryHistory';
import { VIEWS } from 'config';
import CollapsibleCard from '../common/CollapsibleCard';
import { IDelivery } from 'types';

type DeliveriesHistoryProps = {
  deliveries: IDelivery[];
};

const DeliveriesHistory: React.FC<DeliveriesHistoryProps> = ({
  deliveries,
}) => {
  return (
    <>
      {deliveries.map((delivery) => (
        <DeliveryTile
          key={delivery.id}
          delivery={delivery}
          showStatus={false}
          isDriver={false}
          view={VIEWS.LIST.text as 'grid' | 'list'}
        >
          <CollapsibleCard
            className="mt-2"
            title={i18next.t('delivery.show-status-logs')}
          >
            <DeliveryHistory delivery={delivery} />
          </CollapsibleCard>
        </DeliveryTile>
      ))}
    </>
  );
};

DeliveriesHistory.propTypes = {
  deliveries: PropTypes.arrayOf(Shapes.deliveryShape.isRequired).isRequired,
};

export default DeliveriesHistory;
