import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';

import {
  convertSavedReservationToNormalized,
  isDeclined,
} from 'utils/reservation';
import {
  ISavedReservation,
  ITable,
  ReservationConfig,
} from 'types';
import Shapes from 'shapes/main';
import CollapsibleCard from '../common/CollapsibleCard';

interface IProps {
  bookingConfig: ReservationConfig;
  includeDeclineReservations: boolean;
  reservations: ISavedReservation[];
  tables: ITable[];
}

type TReservationsStats = {
  amount: number;
  guests_number: number;
};

const ReservationStatistics: React.FC<IProps> = (props) => {
  const { bookingConfig, reservations, tables } = props;
  if (!reservations || (!reservations.length && reservations.length !== 0)) {
    return (
      <Alert color="info">{i18next.t('reservation-statistics.alert')}</Alert>
    );
  }
  const reducer = (
    dict: Record<string, TReservationsStats>,
    reservation: ISavedReservation
  ) => {
    if (!dict[reservation.source]) {
      dict[reservation.source] = {
        amount: 0,
        guests_number: 0,
      };
    }
    dict[reservation.source].amount++;
    dict[reservation.source].guests_number += reservation.guests_number;
    return dict;
  };

  const filteredReservations = props.includeDeclineReservations
    ? reservations
    : reservations.filter((r) => !isDeclined(r));

  const reservationsBySource = filteredReservations
    .filter((reservation) => {
      if (isDeclined(reservation)) {
        return props.includeDeclineReservations;
      }
      return true;
    })
    .reduce(reducer, {});

  const totalGuestsNumber = filteredReservations.reduce(
    (acc, item) => acc + item.guests_number,
    0
  );

  const reservationsByStatus: Record<string, TReservationsStats> =
    filteredReservations.reduce((dict, reservation) => {
      if (!dict[reservation.status]) {
        dict[reservation.status] = {
          amount: 0,
          guests_number: 0,
        };
      }
      dict[reservation.status].amount++;
      dict[reservation.status].guests_number += reservation.guests_number;
      return dict;
    }, {});

    const reservationsByPeopleAmout: Record<string, TReservationsStats> =
    filteredReservations.reduce((dict, reservation) => {
      if (!dict[reservation.guests_number]) {
        dict[reservation.guests_number] = {
          amount: 0,
          guests_number: 0,
        };
      }
      dict[reservation.guests_number].amount++;
      dict[reservation.guests_number].guests_number += reservation.guests_number;
      return dict;
    }, {});

  const mostPopularTables: Record<number, TReservationsStats> =
    filteredReservations.reduce((dict, reservation) => {
      (reservation.tables || []).forEach((table) => {
        if (!dict[table.id]) {
          dict[table.id] = {
            amount: 0,
            guests_number: 0,
          };
        }
        dict[table.id].amount++;
        dict[table.id].guests_number += reservation.guests_number;
      });
      return dict;
    }, {});

  const mostPopularHours: Record<string, TReservationsStats> =
    filteredReservations.reduce((dict, reservation) => {
      const { visitTime } = convertSavedReservationToNormalized(
        reservation,
        bookingConfig
      );
      if (!dict[visitTime]) {
        dict[visitTime] = {
          amount: 0,
          guests_number: 0,
        };
      }
      dict[visitTime].amount++;
      dict[visitTime].guests_number += reservation.guests_number;
      return dict;
    }, {});

  return (
    <Card>
      <CardBody>
        <Row className="m-b-md align-items-end">
          <Col xs={6}>
            <h4>
              {i18next.t('reservation-statistics.reservation_amount_total')}
            </h4>
            <div className="h3">{filteredReservations.length}</div>
          </Col>
          <Col xs={6}>
            <h4>{i18next.t('reservation-statistics.guest_number_total')}</h4>
            <div className="h3">{totalGuestsNumber}</div>
          </Col>
        </Row>
        <hr />
        <Row className="m-b-md align-items-end">
          {Object.entries(reservationsBySource).map(([source, data]) => (
            <Col key={source} xs={4} className="mb-3">
              <h5 className="strong">
                {i18next.t(`reservation-statistics.sources.${source}`)}
              </h5>
              <div className="h5">
                {i18next.t('reservation-statistics.reservation_amount')}:{' '}
                {data.amount}
              </div>
              <div className="h5">
                {i18next.t('reservation-statistics.guests_number')}:{' '}
                {data.guests_number}
              </div>
            </Col>
          ))}
        </Row>
        <CollapsibleCard title={'Liczba rezerwacji wg. statusu'}>
          <Row className="m-b-md align-items-end">
            {Object.entries(reservationsByStatus).map(([status, data]) => (
              <Col key={status} xs={4} className="mb-3">
                <h5 className="strong">
                  {i18next.t(`reservation.statuses.${status}`)}
                </h5>
                <div className="h5">
                  {i18next.t('reservation-statistics.reservation_amount')}:{' '}
                  {data.amount}
                </div>
                <div className="h5">
                  {i18next.t('reservation-statistics.guests_number')}:{' '}
                  {data.guests_number}
                </div>
              </Col>
            ))}
          </Row>
        </CollapsibleCard>
        <CollapsibleCard title={'Najpopularniejsze stoliki'}>
          <Row className="m-b-md align-items-end">
            {Object.entries(mostPopularTables)
              .sort((a, b) => b[1].amount - a[1].amount)
              .map(([tableId, data]) => {
                const table = (tables || []).find(
                  (t) => t.id === parseInt(tableId, 10)
                );
                return (
                  <Col key={tableId} xs={4} className="mb-3">
                    <h5 className="strong">
                      {table
                        ? `${table.name} (${table.min_people} - ${table.max_people} os.)`
                        : `Stolik bez nazwy #${tableId}`}
                    </h5>
                    <div className="h5">
                      {i18next.t('reservation-statistics.reservation_amount')}:{' '}
                      {data.amount}
                    </div>
                    <div className="h5">
                      {i18next.t('reservation-statistics.guests_number')}:{' '}
                      {data.guests_number}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </CollapsibleCard>
        <CollapsibleCard title={'Najpopularniejsze godziny'}>
          <Row className="m-b-md align-items-end">
            {Object.entries(mostPopularHours)
              .sort((a, b) => b[1].amount - a[1].amount)
              .map(([hour, data]) => (
                <Col key={hour} xs={4} className="mb-3">
                  <h5 className="strong">{hour}</h5>
                  <div className="h5">
                    {i18next.t('reservation-statistics.reservation_amount')}:{' '}
                    {data.amount}
                  </div>
                  <div className="h5">
                    {i18next.t('reservation-statistics.guests_number')}:{' '}
                    {data.guests_number}
                  </div>
                </Col>
              ))}
          </Row>
        </CollapsibleCard>
        <CollapsibleCard title={'Najpopularniejsze rozmiary grupy'}>
          <Row className="m-b-md align-items-end">
            {Object.entries(reservationsByPeopleAmout)
              .sort((a, b) => b[1].amount - a[1].amount)
              .map(([peopleAmout, data]) => (
                <Col key={peopleAmout} xs={4} className="mb-3">
                  <h5 className="strong">{peopleAmout} os.</h5>
                  <div className="h5">
                    {i18next.t('reservation-statistics.reservation_amount')}:{' '}
                    {data.amount}
                  </div>
                  <div className="h5">
                    {i18next.t('reservation-statistics.guests_number')}:{' '}
                    {data.guests_number}
                  </div>
                </Col>
              ))}
          </Row>
        </CollapsibleCard>
      </CardBody>
    </Card>
  );
};

ReservationStatistics.propTypes = {
  includeDeclineReservations: PropTypes.bool.isRequired,
  // @ts-ignore TODO: fix types, probably introduce savedReservationShapeWithTables
  reservations: PropTypes.arrayOf(Shapes.savedReservationShape.isRequired)
    .isRequired,
};

export default ReservationStatistics;
