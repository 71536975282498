import PropTypes, { Requireable } from 'prop-types';
import { ISavedReservation, ReservationSource, ReservationStatus } from 'types/reservation';
import { restaurantTableWithPlaceShape } from './restaurant-table';

const commonReservationProps = {
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  customer_name: PropTypes.string.isRequired,
  declined_reason_id: PropTypes.number,
  duration: PropTypes.number,
  extras: PropTypes.shape({
    assignee: PropTypes.string,
    kids_number: PropTypes.number,
    non_vaccinated_amount: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    present: PropTypes.bool,
  }),
  guests_number: PropTypes.number.isRequired,
  is_entire_place: PropTypes.bool.isRequired,
  notes: PropTypes.string,
  phone_number: PropTypes.string.isRequired,
  restaurant_id: PropTypes.number.isRequired,
  source: PropTypes.oneOf(Object.values(ReservationSource)).isRequired,
  status: PropTypes.oneOf(Object.values(ReservationStatus)).isRequired,
  tables: PropTypes.arrayOf(restaurantTableWithPlaceShape.isRequired),
  attachments: PropTypes.array,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
};

export const savedReservationShape: Requireable<ISavedReservation> = PropTypes.exact({
  ...commonReservationProps,
  reserved_on: PropTypes.string.isRequired, // TODO date
});

export const draftReservationShape = PropTypes.exact({
  ...commonReservationProps,
  visitDate: PropTypes.string.isRequired, // TODO date
  visitTime: PropTypes.string.isRequired,
});

export default savedReservationShape;
