import { IOrder, NormalizedRestaurant } from 'types';
import { logActivity } from './log';
import Order from './orders';
import { getSafeLsJSON } from './parsers';

export function checkIfRestaurantPrepareTimeCorrect(
  restaurant: NormalizedRestaurant,
  order: IOrder,
  mins: number
) {
  if (Order.isSelfCollect(order) || Order.hasCustomRealizationTime(order)) {
    return;
  }
  const lsKey = `${restaurant.id}-delivery-times`;
  const lastOrderDeliveryTimes = getSafeLsJSON(lsKey, []);
  const newDeliveryTimes = [...lastOrderDeliveryTimes, mins].slice(
    Math.max(lastOrderDeliveryTimes.length - 4, 0)
  );
  localStorage.setItem(lsKey, JSON.stringify(newDeliveryTimes));
  const declaredTime = restaurant.orders?.deliveryTime;

  if (newDeliveryTimes.length >= 3 && !!declaredTime) {
    const avgSavedTime = newDeliveryTimes.reduce((acc, item, index, array) => {
      if (index + 1 === array.length) {
        return (acc + item) / array.length;
      }
      return acc + item;
    }, 0);
    if (avgSavedTime - 20 > declaredTime || avgSavedTime + 20 < declaredTime) {
      logActivity(
        `Restaurant ${
          restaurant.name
        } has declared delivery time of ${declaredTime}, but last confirmations are: ${JSON.stringify(
          newDeliveryTimes
        )} with average: ${avgSavedTime}`
      );
    }
  }
}

export const isEmptyAndNotZero = (num) => !num && num !== 0;
