import type { NormalizedRestaurant, IOrder } from 'types';
import { isElectron } from 'utils/general';
import { API_URL } from 'config';

const PrintService = {
  printOrder,
};

export default PrintService;

interface IPrintParams {
  restaurant: NormalizedRestaurant;
  order: IOrder;
}

function printOrder({ order, restaurant }: IPrintParams) {
  if(isElectron()) {
    window.electronApi.printOrder && window.electronApi.printOrder(order);
    return;
  }
  const printerUrl = `${API_URL}/restaurants/${restaurant.id}/orders/${order.id}/print`;
  window.location.href = `my.bluetoothprint.scheme://${printerUrl}`;
}
