import React from 'react';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { IOrder } from 'types';
import Order from 'utils/orders';

type OrderStatusProps = {
  order: IOrder;
  showPlainLabel?: boolean;
};

export const OrderStatus: React.FC<OrderStatusProps> = ({
  order,
  showPlainLabel,
}) => {
  return (
    <div className="order__status">
      {getStatusLabel(order)}
      {showPlainLabel ? ` (${order.status})` : ''}
    </div>
  );
};

function getStatusLabel(order: IOrder) {
  if (Order.isPending(order)) {
    return i18next.t('order.status.pending');
  }
  if (Order.isConfirmed(order)) {
    return i18next.t('order.status.confirmed');
  }
  if (Order.isSent(order)) {
    return i18next.t(
      `order.status.${Order.isSelfCollect(order) ? 'ready-to-collect' : 'sent'}`
    );
  }
  if (Order.isDelivered(order)) {
    return i18next.t(
      `order.status.${Order.isSelfCollect(order) ? 'collected' : 'delivered'}`
    );
  }
  return i18next.t('order.status.declined');
}

OrderStatus.propTypes = {
  order: Shapes.orderShape.isRequired,
};

OrderStatus.displayName = 'OrderStatus';

export default OrderStatus;
