// just a helper file to make it easier to mock these data in Electron app

interface IDynamicEnv {
  NODE_ENV: 'production' | 'development' | 'test';
  REACT_APP_API_URL?: string;
  REACT_APP_LANDING_URL?: string;
  REACT_APP_REFRESH_FREQUENCY?: string;
  REACT_APP_REFRESH_SECONDARY_FREQUENCY?: string;
}

const Env: IDynamicEnv = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_LANDING_URL: process.env.REACT_APP_LANDING_URL,
  REACT_APP_REFRESH_FREQUENCY: process.env.REACT_APP_REFRESH_FREQUENCY,
  REACT_APP_REFRESH_SECONDARY_FREQUENCY: process.env.REACT_APP_REFRESH_SECONDARY_FREQUENCY,
}

// const ElectronExampleEnv: IDynamicEnv = {
//   NODE_ENV: 'production',
//   REACT_APP_API_URL: 'https://stage.lubje.pl/api',
//   REACT_APP_LANDING_URL: 'https://stage.lubje.pl',
//   REACT_APP_REFRESH_FREQUENCY: '',
//   REACT_APP_REFRESH_SECONDARY_FREQUENCY: '',
// }

export default Env;
