import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isSameDay } from 'date-fns';

import { IPlace, ISavedReservation, ITableWithPlace } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import {
  selectCalendarPreferences,
  selectReservationFormValues,
  selectSelectedTables,
  selectTableSuggestions,
} from 'store/reservation-calendar';
import { selectIsRootAdmin } from 'store/app';
import {
  selectPlaces,
  selectRestaurant,
  selectRestaurantTables,
  updateRestaurantPlace,
} from 'store/restaurant';
import ReservationsPlace from 'app/components/reservations/ReservationsPlace/ReservationsPlace';
import { selectReservations } from 'store/reservations';

interface IProps {
  onReservationClick: (reservation: ISavedReservation) => void;
  onTableSelect?: (table: ITableWithPlace) => void;
  showProposedReservations?: boolean;
}

export const ViewReservationsPlace: React.FC<IProps> = (props) => {
  const {
    onReservationClick,
    onTableSelect,
    showProposedReservations = true,
  } = props;

  const [reservationsByTables, setReservationsByTables] = useState<
    Record<number, ISavedReservation[]>
  >({});

  const restaurant = useAppSelector(selectRestaurant);
  const reservations = useAppSelector(selectReservations);
  const restaurantPlaces = useAppSelector(selectPlaces);
  const restaurantTables = useAppSelector(selectRestaurantTables);
  const selectedTables = useAppSelector(selectSelectedTables);
  const tableSuggestions = useAppSelector(selectTableSuggestions);
  const calendarPrefenrences = useAppSelector(selectCalendarPreferences);
  const isRootAdmin = useAppSelector(selectIsRootAdmin);
  const reservationFormValues = useAppSelector(selectReservationFormValues);

  const bookingConfig = restaurant?.config;

  const { activePlace } = calendarPrefenrences;

  const dispatch = useAppDispatch();
  const handlePlaceUpdate = (place: IPlace) =>
    restaurant && dispatch(updateRestaurantPlace(restaurant, place));

  useEffect(() => {
    if (!reservationFormValues) {
      return;
    }
    const { visitDate } = reservationFormValues;
    const selectedDate = new Date(visitDate);

    const filteredReservations = reservations.filter((reservation) =>
      isSameDay(new Date(reservation.reserved_on), selectedDate)
    );
    const newReservationByTables: Record<number, ISavedReservation[]> =
      filteredReservations.reduce((acc, reservation) => {
        reservation.tables?.forEach((table) => {
          if (!acc[table.id]) {
            acc[table.id] = [];
          }
          acc[table.id] = [...acc[table.id], reservation];
        });
        return acc;
      }, {});
    setReservationsByTables(newReservationByTables);
  }, [reservations, restaurantTables, reservationFormValues]);

  if (!activePlace) {
    return (
      <div className="restaurant-map">Wybierz salę, aby wyświetlić mapę</div>
    );
  }

  if (!restaurant || !bookingConfig) {
    return null;
  }

  return (
    <ReservationsPlace
      activePlace={activePlace}
      bookingConfig={bookingConfig}
      calendarPreferences={calendarPrefenrences}
      canEdit={isRootAdmin}
      onReservationClick={onReservationClick}
      onPlaceUpdate={handlePlaceUpdate}
      selectedTables={selectedTables}
      restaurant={restaurant}
      restaurantPlaces={restaurantPlaces}
      reservationsByTables={reservationsByTables}
      reservationFormValues={reservationFormValues}
      tables={restaurantTables as ITableWithPlace[]}
      tableSuggestions={showProposedReservations ? tableSuggestions : []}
      onTableSelect={onTableSelect}
    />
  );
};

ViewReservationsPlace.propTypes = {
  onTableSelect: PropTypes.func.isRequired,
  onReservationClick: PropTypes.func.isRequired,
};

export default ViewReservationsPlace;
