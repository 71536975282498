import React from 'react';
import { useFormik, FormikProps } from 'formik';
import OtoFormGroup from '../common/OtoFormGroup';
import { Col, Label, Row } from 'reactstrap';

import {
  CompanyContactPosition,
  ICompanyContact,
} from 'types/company';
import APIService from 'services/api';
import { toast } from 'react-toastify';
import InputWithOptions from '../common/InputWithOptions';
import { LOG_BAG } from 'utils/log';

export interface AddContactValues {
  company_id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  position: CompanyContactPosition;
}

interface IProps {
  companyId: number;
  handleSubmit: (contact: ICompanyContact) => void;
  children: (params: {
    form: React.ReactNode;
    triggerSubmit: () => void;
    isLoading: boolean;
    isDisabled: boolean;
  }) => JSX.Element;
}

const positionOptions = [
  {
    label: 'Właściciel',
    value: 'owner',
  },
  {
    label: 'Manager',
    value: 'manager',
  },
];

export const AddCompanyContactForm = (props: IProps) => {
  const { companyId, children, handleSubmit } = props;

  const formik: FormikProps<AddContactValues> = useFormik<AddContactValues>({
    initialValues: {
      company_id: companyId,
      first_name: '',
      last_name: '',
      email: '',
      phone: '+48',
      position: 'owner',
    },
    onSubmit: (values) => {
      const contact: ICompanyContact = {
        ...values,
      };
      const url = `/companies/${values.company_id}/contacts`;
      return APIService.post(url, contact)
        .then((savedContact) => {
          toast.success('Kontakt został zapisany');
          return handleSubmit(savedContact as ICompanyContact);
        })
        .catch((error) => {
          toast.error('Wystąpił problem podczas zapisu kontaktu');
          LOG_BAG.logError('Add company contact error', error);
        });
    },
  });

  const form = (
    <form>
      <Row>
        <Col xs="12" md="auto">
          <OtoFormGroup
            label="Imię"
            name="first_name"
            onChange={formik.handleChange}
            value={formik.values.first_name}
          />
        </Col>
        <Col xs="12" md="auto">
          <OtoFormGroup
            label="Nazwisko"
            name="last_name"
            onChange={formik.handleChange}
            value={formik.values.last_name}
          />
        </Col>
        <Col xs="12" md="auto">
          <OtoFormGroup
            label="Nr telefonu"
            name="phone"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />
        </Col>
        <Col xs="12" md="auto">
          <OtoFormGroup
            label="Email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
        </Col>
        <Col xs="12" md="auto">
          <Label for="position">Stanowisko</Label>
          <InputWithOptions
            id="position"
            name="position"
            onChange={formik.handleChange}
            value={formik.values.position}
            options={positionOptions}
          />
        </Col>
      </Row>
    </form>
  );
  return children({
    form,
    triggerSubmit: formik.submitForm,
    isLoading: formik.isSubmitting,
    isDisabled: false,
  });
};
