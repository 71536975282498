import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { DriverType } from 'enums';
import Shapes from 'shapes/main';
import OtoSpinner from '../common/OtoSpinner';
import InputWithOptions from '../common/InputWithOptions';
import type { TAcceptDeliveryModalProps, TCommonPropsForSpecificModals } from 'app/containers/WithSetModal';

type AcceptDeliveryModalProps = TAcceptDeliveryModalProps & TCommonPropsForSpecificModals<{ prepare_time?: number; arriving_in: number }>

const allOptions = [
  ...[15, 20, 25, 30, 35, 40, 45].map((num) => ({
    value: num,
    label: `${num} min`,
  })),
  {
    value: 60,
    label: '60 min (1h)',
  },
  {
    value: 75,
    label: '75 min. (1h 15 min)',
  },
  {
    value: 90,
    label: '90 min. (1.5h)',
  },
  {
    value: 105,
    label: '105 min. (1h 45 min)',
  },
  {
    value: 120,
    label: '120 min. (2h)',
  },
];

const shortOptions = allOptions.filter((o) => o.value <= 60);
const longOptions = allOptions.filter((o) => o.value >= 45);

const AcceptDeliveryModal: React.FC<AcceptDeliveryModalProps> = (props) => {
  const { delivery, closeModal, onSave } = props;

  const loading = false;

  const isRobotDelivery = delivery.driver_type === DriverType.Robot;
  const firstSelectOptions = isRobotDelivery ? shortOptions : allOptions;
  const firstSelectDefaultValue =
    !!delivery.inform_before &&
    firstSelectOptions.map((opt) => opt.value).includes(delivery.inform_before)
      ? delivery.inform_before
      : firstSelectOptions[0].value;

  const [timeToArrive, setTTA] = useState<number>(firstSelectDefaultValue);
  const [timeToArriveToCustomer, setTTATC] = useState<number>(
    longOptions[0].value
  );

  const handleTimeToArriveChange = useCallback((e) => {
    setTTA(e?.target?.value && parseInt(e.target.value, 10));
  }, []);

  const handleTimeToCustomerChange = useCallback((e) => {
    setTTATC(e?.target?.value && parseInt(e.target.value, 10));
  }, []);

  const title = isRobotDelivery
    ? 'Podaj czas na przyjazd do restauracji oraz dostarczenie posiłku do klienta'
    : 'Jak szybko zdążysz przyjechać?';

  const handleConfirmClick = useCallback(() => {
    const prepareTimePart = isRobotDelivery
      ? {
          prepare_time: timeToArriveToCustomer,
        }
      : {};
    return onSave({
      arriving_in: timeToArrive,
      ...prepareTimePart,
    });
  }, [onSave, timeToArrive, timeToArriveToCustomer, isRobotDelivery]);

  const isAnySelectMissing =
    !timeToArrive || (isRobotDelivery && !timeToArriveToCustomer);

  return (
    <>
      <Modal isOpen>
        <ModalHeader toggle={closeModal}>{title}</ModalHeader>
        <ModalBody>
          {!!delivery.inform_before && (
            <div className="mb-2">
              Restauracja potrzebuje{' '}
              <span className="bold strong text-bold text-strong">
                {delivery.inform_before} min.
              </span>{' '}
              na przygotowanie zamówienia.
            </div>
          )}
          <FormGroup>
            {isRobotDelivery && <Label>Czas na przyjazd do restauracji:</Label>}
            <InputWithOptions
              id="ttaRestaurant"
              name="ttaRestaurant"
              options={firstSelectOptions}
              onChange={handleTimeToArriveChange}
              value={timeToArrive}
            />
          </FormGroup>
          {isRobotDelivery && (
            <FormGroup>
              <Label>Czas na przyjazd do klienta:</Label>
              <InputWithOptions
                id="ttaCustomer"
                name="ttaCustomer"
                options={longOptions}
                onChange={handleTimeToCustomerChange}
                value={timeToArriveToCustomer}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          {loading ? (
            <OtoSpinner />
          ) : (
            <>
              <Button type="button" color="secondary" onClick={closeModal}>
                Zamknij
              </Button>
              <Button
                type="button"
                color="success"
                onClick={handleConfirmClick}
                disabled={isAnySelectMissing}
              >
                Przyjmij dostawę
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

AcceptDeliveryModal.propTypes = {
  delivery: Shapes.deliveryShape.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AcceptDeliveryModal;
