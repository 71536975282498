import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'reactstrap';

import { EIntegrationType } from 'types';

import fbPixelImg from '../../../assets/images/brands/fb-pixel.png';
import gAnalyticsImg from '../../../assets/images/brands/google-analytics.png';
import foodeliImg from '../../../assets/images/brands/foodeli.svg';
import { OtoButtons } from '../common';

type IntegrationTileProps = {
  className?: string;
  // name: string;
  // img?: string;
  // note?: string;
  onEnableIntegrationClick?: () => void;
  onDeleteIntegrationClick?: () => void;
  type: EIntegrationType;
  value?: string;
};

export const integrationMeta: Record<
  EIntegrationType,
  {
    name: string;
    img?: string;
    note?: string;
  }
> = {
  [EIntegrationType.FACEBOOK_PIXEL]: {
    name: 'Facebook Pixel',
    img: fbPixelImg,
    note: '(dla restauracji, korzystających z OtoStolik na swojej stronie)',
  },
  [EIntegrationType.GOOGLE_ANALYTICS]: {
    name: 'Google Analytics',
    img: gAnalyticsImg,
    note: '(dla restauracji, korzystających z OtoStolik na swojej stronie)',
  },
  [EIntegrationType.SZAMAEXPRESS]: {
    name: 'SzamaExpress',
    img: 'https://szamaexpress.pl/img/szamaexpress-logo.png',
    note: 'API key',
  },
  [EIntegrationType.DELIVERY_COUPLE]: {
    name: 'Delivery Couple',
    img: 'https://static.wixstatic.com/media/6c941d_aabd449a43444c339e125ad155349375~mv2.png/v1/fill/w_154,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Logo%20light%20RGB.png',
    note: 'ID restauracji w systemie DeliveryCouple',
  },
  [EIntegrationType.FOODELI]: {
    name: 'Foodeli',
    img: foodeliImg,
    note: 'Email w systemie Foodeli',
  },
  [EIntegrationType.IFRAME]: {
    name: 'Style na stronie WWW',
    note: 'Kolor główny, kolor secondary',
  },
};

export const IntegrationTile: React.FC<IntegrationTileProps> = ({
  className,
  children,
  onEnableIntegrationClick,
  onDeleteIntegrationClick,
  type,
  value,
}) => {
  const isActive = !!value;

  const { name, img, note } = integrationMeta[type];

  return (
    <Card className={className}>
      <CardHeader>
        {img && (
          <img
            src={img}
            className={`mw-150 mh-50 mb-2 ${isActive ? '' : 'grayscale'}`}
            alt={name}
          />
        )}
        <CardTitle>{name}</CardTitle>
      </CardHeader>
      <CardBody>
        {children}
        {note && <div className="small text-muted">{note}</div>}
        {isActive ? value : 'brak'}
      </CardBody>
      {(onEnableIntegrationClick || onDeleteIntegrationClick) && (
        <CardFooter className={'d-flex flex-row align-items-ceter justify-content-between'}>
          {isActive ? (
            <>
              <Button
                type="button"
                color="link"
                onClick={onEnableIntegrationClick}
              >
                Edytuj
              </Button>
            </>
          ) : (
            <Button
              type="button"
              color="link"
              onClick={onEnableIntegrationClick}
            >
              Dodaj
            </Button>
          )}
          {onDeleteIntegrationClick && (
            <OtoButtons.DeleteButton
              color="danger"
              onClick={onDeleteIntegrationClick}
            />
          )}
        </CardFooter>
      )}
    </Card>
  );
};

export default IntegrationTile;
