import React from 'react';
import { CardTitle } from 'reactstrap';
import memoizeOne from 'memoize-one';

import { OriginalRestaurant } from 'types';
import CollapsibleCard from '../../common/CollapsibleCard';
import { countArrayItemsByField } from 'utils/array';

interface IProps {
  amountToShow: number;
  restaurants: OriginalRestaurant[];
  filteredRestaurants: OriginalRestaurant[];
  summary: Record<string, number>;
}

const getCuisineTypeSummary = memoizeOne(
  (restaurants: OriginalRestaurant[], amount: number) =>
    countArrayItemsByField(restaurants.slice(0, amount), 'cuisine_type')
);

const AdminRestaurantsSummary: React.FC<IProps> = (props) => {
  const { amountToShow, restaurants, filteredRestaurants, summary } = props;

  const cuisineTypeSummary = getCuisineTypeSummary(
    filteredRestaurants,
    amountToShow
  );

  return (
    <CollapsibleCard title="Podsumowanie restauracji - ile jakich produktów jest">
      <CardTitle tag="h5">
        <div>Wszystkich restauracji: {restaurants.length}</div>
        <div>Wyfiltrowanych restauracji: {filteredRestaurants.length}</div>
      </CardTitle>
      <div className="tags-cloud">
        {Object.entries(summary).map((entry) => (
          <div key={entry[0]} className="tag tag--disabled">
            {entry[0]}:{entry[1]}
          </div>
        ))}
      </div>
      <h3>Ile restauracji ma jaki cuisine type</h3>
      <div className="tags-cloud">
        {Object.entries(cuisineTypeSummary).map((entry) => (
          <div key={entry[0]} className="tag tag--disabled">
            {entry[0]}:{entry[1]}
          </div>
        ))}
      </div>
    </CollapsibleCard>
  );
};

export default AdminRestaurantsSummary;
