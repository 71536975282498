import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Card, CardBody, CardHeader } from 'reactstrap';
import i18next from 'i18next';

const otostolikSupportPhone = '+48 791 792 809';

const defaultContactData = {
  email: 'hello@otostolik.pl',
  phone: otostolikSupportPhone,
};

type TSimpleContactData = {
  email: string;
  phone: string;
};

interface ContactCardProps {
  contactData?:
    | TSimpleContactData
    | {
        technical: TSimpleContactData;
        operations: TSimpleContactData;
      };
}

export const ContactCard: React.FC<ContactCardProps> = ({
  contactData = defaultContactData,
}) => {
  if ('email' in contactData) {
    const { email, phone } = contactData;
    return (
      <Card>
        <CardHeader>
          {i18next.t('contact-page.contact-form.title-general')}
        </CardHeader>
        <CardBody>
          <FormGroup>
            <p>{i18next.t('contact-page.contact-form.text-general')}</p>
            <p>
              Numer kontaktowy: <a href={`tel:${phone}`}>{phone}</a>
            </p>
            <p>
              Mail: <a href={`mailto:${email}`}>{email}</a>
            </p>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }

  const { operations, technical } = contactData;
  return (
    <Card>
      <CardHeader>
        {i18next.t('contact-page.contact-form.title-general')}
      </CardHeader>
      <CardBody>
        <p>{i18next.t('contact-page.contact-form.text-general')}</p>
        <hr />
        <FormGroup>
          <div className="h4">
            {i18next.t('contact-page.contact-form.title-operations')}
          </div>
          {operations.phone && (
            <p>
              Numer kontaktowy:{' '}
              <a href={`tel:${operations.phone}`}>{operations.phone}</a>
            </p>
          )}
          {operations.email && (
            <p>
              Mail:{' '}
              <a href={`mailto:${operations.email}`}>{operations.email}</a>
            </p>
          )}
        </FormGroup>
        <hr className="my-2" />
        <FormGroup>
          <div className="h4">
            {i18next.t('contact-page.contact-form.title-technical')}
          </div>
          {technical.phone && (
            <p>
              Numer kontaktowy:{' '}
              <a href={`tel:${technical.phone}`}>{technical.phone}</a>
            </p>
          )}
          {technical.email && (
            <p>
              Mail: <a href={`mailto:${technical.email}`}>{technical.email}</a>
            </p>
          )}
        </FormGroup>
      </CardBody>
    </Card>
  );
};

ContactCard.propTypes = {
  contactData: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
};

ContactCard.defaultProps = {
  contactData: defaultContactData,
};

export default ContactCard;
