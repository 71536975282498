import React from 'react';

import { IRestaurantCompany } from 'types';

const CompanyDetails: React.FC<{ company: IRestaurantCompany }> = ({
  company,
}) => {
  return (
    <>
      <div>Nazwa: {company.name}</div>
      <div>NIP: {company.nip}</div>
      <div>Adres: {company.address}</div>
      <div>
        Numer konta (do roliczeń z zamówień online): {company.bank_account || 'brak'}
      </div>
      {!!company.representer && (
        <div>
          <strong>Osoba kontaktowa:</strong>
          <div>{company.representer.name}</div>
          <div>{company.representer.phone}</div>
        </div>
      )}
    </>
  );
};

export default CompanyDetails;
