import { IRoute } from './types';
import OrderSettingsPage from '../app/pages/OrderSettingsPage';
import OrdersListPage from '../app/pages/OrdersListPage';

export const ordersListRoute: IRoute = {
  name: OrdersListPage.navName as string,
  path: OrdersListPage.url as string,
  component: OrdersListPage,
};

export const ordersListWithModalRoute: IRoute = {
  name: OrdersListPage.navName,
  path: `${OrdersListPage.url}/:orderId`,
  component: OrdersListPage,
};

export const ordersSettingsRoute: IRoute = {
  name: 'Ustawienia zamówień',
  path: '/settings/orders',
  component: OrderSettingsPage as React.ComponentType<any>,
};
