import React, { useState, useEffect } from 'react';
import { Alert, AlertProps } from 'reactstrap';

interface UpdateBannerProps extends AlertProps {
  canBeClosed: boolean;
  children: React.ReactNode;
  dateFrom?: string;
  dateTo?: string;
  id: string;
}

export const UpdateBanner: React.FC<UpdateBannerProps> = ({
  canBeClosed,
  children,
  dateFrom,
  dateTo,
  id,
  ...alertProps
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const updateBannerClosedItems = JSON.parse(
    localStorage.getItem('update-banner-closed-items') || '{}'
  );

  const isBannerClosed = !!updateBannerClosedItems[id];

  useEffect(() => {
    if (isBannerClosed) {
      setIsOpen(false);
    }
  }, [id, isBannerClosed]);

  const closeBanner = () => {
    setIsOpen(false);

    updateBannerClosedItems[id] = new Date();
    localStorage.setItem(
      'update-banner-closed-items',
      JSON.stringify(updateBannerClosedItems)
    );
  };

  if (dateFrom && new Date(dateFrom) > new Date()) {
    return null;
  }
  if (dateTo && new Date(dateTo) < new Date()) {
    return null;
  }

  return (
    <Alert
      {...alertProps}
      className="no-print"
      isOpen={isOpen}
      toggle={canBeClosed ? closeBanner : undefined}
    >
      {children}
    </Alert>
  );
};

export default UpdateBanner;
