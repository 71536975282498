import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

import OtoToggle from '../../common/OtoToggle';

const visibilityToggles: {
  key: keyof IAdminVisibilityFilters;
  label: string;
  desc?: string;
}[] = [
  { key: 'showAllProducts', label: 'Pokaż wszystkie produkty' },
  { key: 'showContactDetails', label: 'Pokaż telefon i adres' },
  {
    key: 'showCuisineLabels',
    label: 'Pokaż cuisine labels',
    desc: 'Typ kuchni, może ich być kilka, zwykle do pięciu. Używane na www, docelowo też w aplikacji mobilnej, jako #tagi',
  },
  {
    key: 'showCuisineType',
    label: 'Pokaż cuisine type',
    desc: 'Jeden, główny typ kuchni, docelowo rezygnujemy z niego, używany w aplikacji mobilnej',
  },
  { key: 'showEditArea', label: 'Edytuj strefy dostaw' },
  { key: 'showImages', label: 'Pokaż nazwy ścieżek do zdjęć' },
  { key: 'showFinancialSettings', label: 'Pokaż ustawienia finansowe' },
];

export interface IAdminVisibilityFilters {
  showAllProducts: boolean;
  showCuisineLabels: boolean;
  showCuisineType: boolean;
  showContactDetails: boolean;
  showFinancialSettings: boolean;
  showEditArea: boolean;
  showImages: boolean;
}

interface VisibilityTogglesProps {
  onUpdate: (filters: IAdminVisibilityFilters) => void;
  visibility: IAdminVisibilityFilters;
}

const VisibilityToggles = (props: VisibilityTogglesProps) => {
  const { onUpdate, visibility } = props;
  return (
    <div className="row mx-0 align-vertical align-items-stretch">
      {visibilityToggles.map((toggleItem) => (
        <div
          className="card p-2 row d-inline-flex mw-400 ml-0 mr-3 mb-2"
          key={toggleItem.key}
        >
          <Label key={toggleItem.key} className="d-flex flex-row">
            <OtoToggle
              id={toggleItem.key}
              name={toggleItem.key}
              checked={visibility[toggleItem.key]}
              onChange={() => {
                const newVisibility = {
                  ...visibility,
                  [toggleItem.key]: !visibility[toggleItem.key],
                };
                onUpdate(newVisibility);
              }}
            />
            <span className="ml-2">{toggleItem.label}</span>
          </Label>
          {toggleItem.desc && <div className="small">{toggleItem.desc}</div>}
        </div>
      ))}
    </div>
  );
};

VisibilityToggles.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  visibility: PropTypes.object.isRequired,
};

export default VisibilityToggles;
