import type { IOrder } from 'types';

declare global {
  interface Window {
    electronApi: {
      printOrder?: (order: IOrder) => void;
      receive: (channel: string, callback: (data: any) => void) => void;
      send: (channel: string, payload?: any) => void;
      setupPushReceiver: (onMessage: Function) => void;
      twoWayCommunicationRequest: (testParam: any) => void;
      twoWayCommunicationReceive: (
        callback: (event: any, value: number) => void
      ) => void;
    };
  }
}

const ElectronService = {
  enableElectronNotifications,
};

export default ElectronService;

function enableElectronNotifications(
  onToken: (token: string) => void,
  onMessage: (message: object) => void
) {
  console.log('[app] enableElectronNotifications called, onToken is', onToken);

  window?.electronApi.receive('fromMain', (data) => {
    console.log('[app] Received data from main process:', data);
  });

  window?.electronApi.receive('electronUserToken', (data) => {
    const payload = JSON.parse(data);
    console.log('[app] Received electronUserToken from main process:', payload);
    onToken(payload.token);
  });

  window?.electronApi.receive('electronFcmToken', (data) => {
    const payload = JSON.parse(data);
    console.log('[app] Received electronFcmToken from main process:', payload);
    onToken(payload.token);
  });

  window?.electronApi.receive('electronUserPush', (data) => {
    const payload = JSON.parse(data);
    console.log('[app] Received electronUserPush from main process:', payload);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const myNotification = new Notification(payload.title, {
      body: payload.text,
    });
    console.log(
      '[app] client asking server to print, sending: clientPrintRequest'
    );
    window?.electronApi.send(
      'clientPrintRequest',
      JSON.stringify({
        order: {
          id: 1050,
          customer: 'Stas Test',
        },
      })
    );
  });

  console.log('[app] sending test data toMain');
  const token = window?.electronApi.send('tokenRequest');

  // Yay, this works!
  console.log('[app] token answer', token);
  onToken(token as unknown as string);
  window.electronApi?.setupPushReceiver(onMessage);

  // TODO test this for sending notification data
  // ipcRenderer.sendToHost(channel, ...args)
}
