import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputProps, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Eye, EyeOff } from 'react-feather';

type InputWithEyeProps = Omit<InputProps, 'name' | 'onChange'> & {
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputWithEye: React.FC<InputWithEyeProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  return <InputGroup>
    <Input type={showPassword ? 'text' : 'password'} {...props} />
    <InputGroupAddon addonType="append" className="clickable">
      <InputGroupText onClick={() => setShowPassword(!showPassword)}>{showPassword ? <EyeOff /> : <Eye />}</InputGroupText>
    </InputGroupAddon>
  </InputGroup>;
};

InputWithEye.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputWithEye;
