import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { Check, Edit, X } from 'react-feather';

type IProps = {
  className?: string;
  onConfirm: (value: string) => Promise<void> | void;
  disabled?: boolean;
  initialValue?: string | null;
  label: string;
  showConfirmIcon?: boolean;
  styleStaticDiv?: React.CSSProperties;
  styleInput?: React.CSSProperties;
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'url';
};

export const OtoInlineInput: React.FC<IProps> = ({
  className,
  disabled,
  onConfirm,
  initialValue,
  label,
  showConfirmIcon,
  styleStaticDiv,
  styleInput,
  type,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    setValue(initialValue || '');
  }, [initialValue]);

  if (!isEditing) {
    return (
      <div
        className={'align-vertical'}
        style={{
          minWidth: 200,
          ...styleStaticDiv,
        }}
      >
        <span>{label}</span>
        <Edit
          aria-label={'edit'}
          className={'ml-2'}
          style={{
            cursor: 'pointer',
          }}
          onClick={
            disabled
              ? undefined
              : () => {
                  setIsEditing(true);
                }
          }
        />
      </div>
    );
  }

  const onCancel = () => {
    setIsEditing(false);
    setValue(initialValue || '');
  };

  const submitCurrentValue = () => {
    setIsEditing(false);
    void onConfirm(value);
    setValue('');
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitCurrentValue();
  };

  return (
    <form onSubmit={handleSubmit} className={'align-vertical'}>
      <Input
        className={className}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={'Enter to confirm'}
        style={{
          minWidth: 200,
          ...styleInput,
        }}
        type={type}
      />
      <X
        aria-label={'cancel'}
        className={'ml-2'}
        onClick={onCancel}
        style={{
          cursor: 'pointer',
        }}
      />
      {showConfirmIcon && (
        <Check
          aria-label={'confirm'}
          className={'ml-2'}
          onClick={submitCurrentValue}
          style={{
            cursor: 'pointer',
          }}
        />
      )}
    </form>
  );
};

OtoInlineInput.displayName = 'OtoInlineInput';

export default OtoInlineInput;
