import React, { useState } from 'react';
import WithDateRangeDataFetched from 'app/containers/WithDateRangeDataFetched';
import { toDateInputValue } from 'utils/date-time';
import { PageComponent } from 'app/types-global';
import { IDelivery } from 'types';
import DeliveriesTable from 'app/components/deliveries/DeliveriesTable';
import APIService from 'services/api';
import { getArrayWithUpdatedObject } from 'utils/array';
import { toast } from 'react-toastify';

export const AdminDeliveriesListPage: PageComponent<{}> = () => {
  const [loadingDeliveries, setLoadingDeliveries] = useState<number[]>([]);
  return (
    <>
      <WithDateRangeDataFetched<IDelivery[]>
        getUrl={(startDate, endDate) => {
          const after = toDateInputValue(startDate);
          const before = toDateInputValue(endDate);
          return `/admin/deliveries?filter[created_after]=${after}&filter[created_before]=${before}`;
        }}
      >
        {(deliveries, { setData }) => {
          const handleUnassignDelivery = async (delivery: IDelivery) => {
            setLoadingDeliveries((prev) => [...prev, delivery.id]);
            return APIService.put(
              `/restaurants/${delivery.restaurant_id}/deliveries/${delivery.id}`,
              {
                assigned_to: null,
              }
            )
              .then((updatedDelivery) => {
                setLoadingDeliveries((prev) =>
                  prev.filter((id) => id !== updatedDelivery.id)
                );
                setData(getArrayWithUpdatedObject(deliveries, updatedDelivery));
                toast.success('Kierowca odpięty od dostawy');
              })
              .catch(() => {
                setLoadingDeliveries((prev) =>
                  prev.filter((id) => id !== delivery.id)
                );
                toast.error('Wystąpił problem z odpięciem kierowcy');
              });
          };

          return (
            <>
              <DeliveriesTable
                deliveries={deliveries}
                isLoading={(delivery) =>
                  loadingDeliveries.includes(delivery.id)
                }
                onUnassignDriver={handleUnassignDelivery}
              />
            </>
          );
        }}
      </WithDateRangeDataFetched>
    </>
  );
};

AdminDeliveriesListPage.url = '/admin/deliveries';
AdminDeliveriesListPage.navName = 'admin.deliveries';

export default AdminDeliveriesListPage;
