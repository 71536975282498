import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { withFormik, Form } from 'formik';
import type { FormikBag, FormikProps } from 'formik';
import {
  Modal,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  ModalHeader,
  Row,
  Label,
  Col,
  FormGroup,
} from 'reactstrap';
import { toast } from 'react-toastify';

import { toDateInputValue } from 'utils/date-time';
import { setFocus } from 'utils/ui';
import { PromoCodeIssuers, PromoCodeTypes } from 'enums';
import { currency } from 'globals/currency';
import APIService from 'services/api';
import OtoSpinner from '../common/OtoSpinner';
import InputWithOptions from '../common/InputWithOptions';
import { selectIsAnyAdmin } from 'store/app';
import { selectRestaurant } from 'store/restaurant';
import UsageTypeSelect from './UsageTypeSelect';
import IconWithTooltip from '../common/IconWithTooltip';
import AdminRestaurantSelector from '../admin/AdminRestaurantSelector';
import { IPromoCode } from 'types';
import { IAppState } from 'store/main';
import { promoCodelabelsByType } from './getPromoCodeLabel';

export const promoCodeValueOptions = [5, 10, 15, 20, 25];

export const promoCodeMinCartOptions = [0, 30, 40, 50, 60, 75, 100];

const issuerLabels: Record<PromoCodeIssuers, string> = {
  [PromoCodeIssuers.lubje]: 'LUB*JE',
  [PromoCodeIssuers.lesznoje]: 'Leszno Je!',
  [PromoCodeIssuers.ostrowje]: 'Ostrów Je!',
  [PromoCodeIssuers.miedzioweje]: 'Miedziowe Je!',
  [PromoCodeIssuers.otostolik]: 'OtoStolik',
  [PromoCodeIssuers.restaurant]: 'Restauracja',
};

type PromoCodeEditFormValues = Omit<
  IPromoCode,
  'issuer' | 'min_cart_amount' | 'activated_at' | 'deactivated_at'
> & {
  issuer: PromoCodeIssuers;
  min_cart_amount: number;
  activated_at: string;
  deactivated_at: string;
};

type PromoCodeEditModalOwnProps = {
  closeModal: () => void;
  promoCode: IPromoCode;
  onSave: (promoCode: IPromoCode) => void;
};

type PromoCodeEditModalProps = FormikProps<PromoCodeEditFormValues> &
  PromoCodeEditModalOwnProps &
  PropsFromRedux;

class PromoCodeEditModal extends React.PureComponent<
  PromoCodeEditModalProps,
  {}
> {
  static displayName = 'PromoCodeEditModal';

  componentDidMount() {
    setFocus('input[name=code]');
  }

  renderDicountTypesOptions(discountType: PromoCodeTypes, label: string) {
    return (
      <option key={discountType} value={discountType}>
        {label}
      </option>
    );
  }

  render() {
    const { closeModal, handleChange, values, isAnyAdmin, isSubmitting } =
      this.props;
    const isFreeDelivery = values.discount_type === 'freeDelivery';
    return (
      <Modal isOpen className="product-edit-modal">
        <Form>
          <ModalHeader toggle={closeModal} className="px-0 pt-0">
            {values.id
              ? 'Edytujesz promokod: #' + values.id
              : 'Tworzenie nowego kodu'}
          </ModalHeader>
          <ModalBody className="px-0">
            <FormGroup>
              <Label for="code">Kod promocyjny*</Label>
              <Input
                type="text"
                name="code"
                className="mw-300"
                placeholder="Wpisz kod promocyjny"
                disabled={values.id ? true : false}
                value={values.code}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <UsageTypeSelect
                value={values.usage_type}
                onChange={handleChange}
              />
            </FormGroup>
            <Row form>
              <Col xs={12} md="auto">
                <FormGroup>
                  <Label for="discount_type">Typ promocji</Label>
                  <Input
                    type="select"
                    className="min-w-250 mw-250"
                    name="discount_type"
                    value={values.discount_type}
                    onChange={handleChange}
                  >
                    {Object.entries(promoCodelabelsByType).map(
                      ([type, label]) =>
                        this.renderDicountTypesOptions(
                          type as PromoCodeTypes,
                          label
                        )
                    )}
                  </Input>
                </FormGroup>
              </Col>
              {!isFreeDelivery && (
                <Col xs={12} md="auto">
                  <FormGroup>
                    <Label for="discount">Wartość promocji</Label>
                    <InputWithOptions
                      allowEmptyOnChange={false}
                      options={promoCodeValueOptions}
                      suffix={
                        values.discount_type === 'percent'
                          ? '%'
                          : ` ${currency}`
                      }
                      type="number"
                      step="0.1"
                      id="discount"
                      name="discount"
                      className="min-w-200 mw-250"
                      placeholder="Wpisz wartość"
                      // @FIXME
                      value={values.discount || ''}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col xs={12} md="auto">
                <FormGroup>
                  <Label for="discount">
                    Min. koszyk{' '}
                    <IconWithTooltip
                      id="promo-code-min-cart"
                      text="0 oznacza brak wymaganego minimalnego koszyka"
                    />
                  </Label>
                  <InputWithOptions
                    allowEmptyOnChange={false}
                    options={promoCodeMinCartOptions}
                    suffix={currency}
                    type="number"
                    step="0.1"
                    id="min_cart_amount"
                    name="min_cart_amount"
                    className="min-w-200 mw-250"
                    placeholder="Wpisz wartość"
                    value={values.min_cart_amount}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col xs={12} md="auto">
                <FormGroup>
                  <Label for="activated_at">Wazny od</Label>
                  <Input
                    value={values.activated_at}
                    type="date"
                    className="min-w-250 mw-250"
                    name="activated_at"
                    placeholder="0000-00-00"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md="auto">
                <FormGroup>
                  <Label for="deactivated_at">Wazny do</Label>
                  <Input
                    value={values.deactivated_at}
                    type="date"
                    className="min-w-250 mw-250"
                    name="deactivated_at"
                    placeholder="0000-00-00"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="code">Opis promo kodu*</Label>
              <Input
                value={values.description || ''}
                type="textarea"
                rows={4}
                name="description"
                placeholder="Dodaj opis"
                onChange={handleChange}
              />
            </FormGroup>
            {isAnyAdmin && (
              <>
                <Row form>
                  <Col className="min-w-200">
                    <FormGroup>
                      <Label for="activated_at">Id Restauracji</Label>
                      <AdminRestaurantSelector
                        loadIfMissing={true}
                        onSelect={(restaurant) =>
                          this.props.setFieldValue(
                            'restaurant_id',
                            restaurant.id
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className="min-w-200">
                    <FormGroup>
                      <Label for="issuer">Kto płaci</Label>
                      <Input
                        className="mw-300"
                        type="select"
                        name="issuer"
                        value={values.issuer}
                        onChange={handleChange}
                      >
                        {Object.values(PromoCodeIssuers).map((issuer) => (
                          <option key={issuer} value={issuer}>
                            {issuerLabels[issuer]}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col className="min-w-200">
                    <FormGroup>
                      <Label for="refund_amount">
                        Kwota pokrywana przez nas (refund_amount)
                        <div className="text-danger">
                          WIP - zostanie nadpisane przez API
                        </div>
                      </Label>
                      <InputWithOptions
                        allowEmptyOnChange={false}
                        options={promoCodeValueOptions}
                        suffix={
                          values.refund_type === 'percentage'
                            ? '%'
                            : ` ${currency}`
                        }
                        type="number"
                        step="0.1"
                        id="refund_amount"
                        name="refund_amount"
                        className="min-w-150 mw-200"
                        placeholder="Wpisz wartość"
                        // @FIXME
                        value={values.refund_amount || ''}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="min-w-250">
                    <FormGroup>
                      <Label for="refund_type">
                        Jak obliczany jest refund_amount (refund_type)
                      </Label>
                      <Input
                        type="select"
                        className="min-w-250 mw-400"
                        name="refund_type"
                        // @FIXME
                        value={values.refund_type || ''}
                        onChange={handleChange}
                      >
                        <option value="value">value (stała kwota)</option>
                        <option value="percentage">
                          percentage (procent od wielkości rabatu)
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {isSubmitting ? (
              <OtoSpinner center />
            ) : (
              <>
                <Button type="submit" color="success">
                  {values.id ? 'Zapisz' : 'Stwórz'}
                </Button>
                <Button type="button" color="danger" onClick={closeModal}>
                  Anuluj
                </Button>
              </>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

const PromoCodeModalWithFormik = withFormik({
  mapPropsToValues: (
    props: PromoCodeEditModalOwnProps & PropsFromRedux
  ): PromoCodeEditFormValues => {
    const values: PromoCodeEditFormValues = {
      // @FIXME it was like that before TS refactor
      // @ts-expect-error
      issuer: props.isAnyAdmin
        ? PromoCodeIssuers.otostolik
        : PromoCodeIssuers.restaurant,
      // @FIXME it was like that before TS refactor
      // @ts-expect-error
      restaurant_id: props.isAnyAdmin ? null : props.restaurant.id,
      ...props.promoCode,
      min_cart_amount:
        props.promoCode.min_cart_amount || promoCodeMinCartOptions[0],
      activated_at: toDateInputValue(
        new Date(props.promoCode.activated_at || Date.now())
      ),
      deactivated_at: toDateInputValue(
        new Date(props.promoCode.deactivated_at || Date.now())
      ),
    };

    if (props.isAnyAdmin) {
      values.refund_amount = null;
      values.refund_type = 'value';
    }

    return values;
  },
  handleSubmit: (
    values: PromoCodeEditFormValues,
    {
      props,
      setSubmitting,
    }: FormikBag<PromoCodeEditModalOwnProps, PromoCodeEditFormValues>
  ) => {
    const apiMethod = values.id ? APIService.put : APIService.post;
    const url = values.id
      ? `/restaurants/promo-codes/${values.id}`
      : '/restaurants/promo-codes';
    apiMethod(url, values)
      .then((updatedPromoCode) => {
        props.onSave(updatedPromoCode);
        setSubmitting(false);
        toast.success('Kod został pomyślnie zapisany');
      })
      .catch((err) => {
        setSubmitting(false);
        const action = values.id ? 'aktualizacji' : 'zapisu';
        const errorMessage = `Wystąpił błąd ${action} kodu rabatowego`;
        toast.error(errorMessage);
      });
  },
  displayName: 'FormikPromoCodeEditModal',
})(PromoCodeEditModal);

const mapStateToProps = (state: IAppState) => ({
  isAnyAdmin: selectIsAnyAdmin(state),
  restaurant: selectRestaurant(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PromoCodeModalWithFormik);
