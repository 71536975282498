import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

import CollapsibleCard from '../../common/CollapsibleCard';
import OtoToggle from '../../common/OtoToggle';
import { IPlace } from 'types';
import { ReservationCalendarPreferences } from 'store/reservation-calendar';
import './ReservationsViewOptions.scss';
import ReservationsPlaceSelect from '../ReservationsPlaceSelect';

const calendarGridOpenHours = [
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
].map((hourStr) => `${hourStr}:00`);

const calendarGridCloseHours = [
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
].map((hourStr) => `${hourStr}:00`);

interface IProps {
  children?: React.ReactNode;
  openByDefault?: boolean;
  places: IPlace[];
  preferences: ReservationCalendarPreferences;
  showCalendarViewOptions?: boolean;
  showMapViewOptions?: boolean;
  showPlaceSelect: boolean;
  showHours: boolean;
  updatePreferences: (
    preferences: Partial<ReservationCalendarPreferences>
  ) => void;
}

export const ReservationsViewOptions: React.FC<IProps> = (props) => {
  const {
    children,
    openByDefault,
    places,
    preferences,
    showCalendarViewOptions = true,
    showMapViewOptions = false,
    showHours,
    showPlaceSelect,
    updatePreferences,
  } = props;

  const onActivePlaceChange = (place: IPlace | null) =>
    updatePreferences({ activePlace: place });

  const onMaxTimeChange = (e) => updatePreferences({ timeTo: e.target.value });
  const onMinTimeChange = (e) =>
    updatePreferences({ timeFrom: e.target.value });

  const onHideDisabledPlacesChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    updatePreferences({
      hideDisabledPlaces: e.target.checked,
    });

  const {
    activePlace,
    hideDisabledPlaces,
    showDeclinedReservations,
    showPeopleAmountOnMap,
    showReservationNotes,
    showReservationIfPresentOnMap,
    timeFrom,
    timeTo,
  } = preferences;

  return (
    <CollapsibleCard
      className="no-print"
      openByDefault={openByDefault}
      title={
        <div className="calendar-preferences">
          <div className="mr-2">Opcje widoku</div>
          {showHours && (
            <>
              <span className="calendar-preferences__item">
                {timeFrom} - {timeTo}
              </span>
            </>
          )}
          <span className="calendar-preferences__item">
            {activePlace ? activePlace.name : 'Wszystkie sale'}
          </span>
          {showCalendarViewOptions && (
            <>
              <span className="calendar-preferences__item">
                Uwagi widoczne: {showReservationNotes ? 'Tak' : 'Nie'}
              </span>
              <span className="calendar-preferences__item">
                Anulowane rezerwacje widoczne:{' '}
                {showDeclinedReservations ? 'Tak' : 'Nie'}
              </span>
            </>
          )}
          {showMapViewOptions && (
            <>
              <span className="calendar-preferences__item">
                Rozmiary stolików: {showPeopleAmountOnMap ? 'Tak' : 'Nie'}
              </span>
              <span className="calendar-preferences__item">
                Rezerwacje na stolikach:{' '}
                {showReservationIfPresentOnMap ? 'Tak' : 'Nie'}
              </span>
            </>
          )}
        </div>
      }
    >
      <>
        {showHours && (
          <FormGroup className="bb-1 bc-grey pb-3 mb-2">
            <div>
              <Label className="d-block font-weight-bold">
                Zakres godzin w kalendarzu
                <div className="small">
                  (dotyczy widoków Dzień (stoliki) oraz Dzień (1 sala))
                </div>
              </Label>
            </div>
            <div className="d-flex align-vertical mt-2" style={{ gap: 10 }}>
              <Label className="m-0 p-0" htmlFor="calendarMinHour">
                od
              </Label>
              <Input
                type="select"
                className="mw-150"
                id="calendarMinHour"
                value={timeFrom}
                onChange={onMinTimeChange}
              >
                {calendarGridOpenHours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Input>
              <Label className="m-0 p-0" htmlFor="calendarMaxHour">
                do
              </Label>
              <Input
                type="select"
                className="mw-150"
                id="calendarMaxHour"
                value={timeTo}
                onChange={onMaxTimeChange}
              >
                {calendarGridCloseHours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
        )}

        {showPlaceSelect && (
          <ReservationsPlaceSelect
            activePlace={activePlace}
            onActivePlaceChange={onActivePlaceChange}
            places={places}
          />
        )}
        {showCalendarViewOptions && (
          <>
            <div>
              <OtoToggle
                className="align-vertical"
                label="Pokazuj dodatkowe uwagi na widoku dziennym"
                labelWrapperClassName="d-inline-flex align-vertical font-weight-bold mt-2"
                checked={showReservationNotes}
                onChange={(e) =>
                  updatePreferences({
                    showReservationNotes: e.target.checked,
                  })
                }
              />
            </div>
            <div>
              <OtoToggle
                className="align-vertical"
                label="Pokazuj anulowane rezerwacje"
                labelWrapperClassName="d-inline-flex align-vertical font-weight-bold mt-2"
                checked={showDeclinedReservations}
                onChange={(e) =>
                  updatePreferences({
                    showDeclinedReservations: e.target.checked,
                  })
                }
              />
            </div>
            <div>
              <OtoToggle
                className="align-vertical"
                label="Ukryj wyłączone sale"
                labelWrapperClassName="d-inline-flex align-vertical font-weight-bold mt-2"
                checked={hideDisabledPlaces}
                onChange={onHideDisabledPlacesChange}
              />
            </div>
            <hr />
          </>
        )}
        <h3>Ustawienia mapy</h3>
        <OtoToggle
          className="align-vertical"
          label="Pokaż rozmiary stolików"
          labelWrapperClassName="d-inline-flex align-vertical font-weight-bold mt-2"
          checked={showPeopleAmountOnMap}
          onChange={(e) =>
            updatePreferences({ showPeopleAmountOnMap: e.target.checked })
          }
        />
        <OtoToggle
          className="align-vertical"
          label="Pokaż rezerwacje na zajętych stolikach"
          labelWrapperClassName="d-inline-flex align-vertical font-weight-bold mt-2"
          checked={showReservationIfPresentOnMap}
          onChange={(e) =>
            updatePreferences({
              showReservationIfPresentOnMap: e.target.checked,
            })
          }
        />
        <hr />
        {children}
      </>
    </CollapsibleCard>
  );
};

ReservationsViewOptions.propTypes = {
  children: PropTypes.node,
  showHours: PropTypes.bool.isRequired,
};

export default ReservationsViewOptions;
