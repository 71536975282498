import React, { Component } from 'react';
import * as Feather from 'react-feather';
import NavBadge from './NavBadge';

import type { LinkComponentType } from 'app/theme-components/createLinkComposed';
import NavSingleItem from './NavSingleItem';

type NavDropdownItemProps = {
  item: {
    icon?: string;
    name: string;
    badge?: {
      variant: string;
      text: string;
    };
    children: any[];
  };
  isSidebarCollapsed: boolean;
  LinkComponent: LinkComponentType;
};

type NavDropdownItemState = {
  open: boolean;
};

export default class NavDropdownItem extends Component<NavDropdownItemProps, NavDropdownItemState> {
  constructor(props: NavDropdownItemProps) {
    super(props);
    this.state = {
      open: false
    };
  }
  toggle = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => ({ open: !prevState.open }));
  };
  render() {
    const { item } = this.props;
    const isExpanded = this.state.open ? 'open' : '';
    const Icon = item.icon ? Feather[item.icon] : null;
    const ExpandIcon = this.state.open
      ? Feather.ChevronDown
      : Feather.ChevronRight;
    return (
      <li className={`nav-item has-submenu ${isExpanded}`}>
        <a href="#!" role="button" onClick={this.toggle}>
          {item.icon && Icon && <Icon className="side-nav-icon" />}
          <span className="nav-item-label">{item.name}</span>{' '}
          {item.badge && (
            <NavBadge color={item.badge.variant} text={item.badge.text} />
          )}
          <ExpandIcon className="menu-expand-icon" />
        </a>
        {(this.state.open || this.props.isSidebarCollapsed) && (
          <ul className="nav-submenu">
            {item.children.map((item, index) => (
              <NavSingleItem item={item} key={index} LinkComponent={this.props.LinkComponent} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}
