import React from 'react';
import PropTypes from 'prop-types';

import OtoStolikLogo from '../../assets/images/otostolik-logo.svg';
import OtoStolikShortLogo from '../../assets/images/otostolik-logo-short.png';

import LubjeLogo from '../../assets/images/logo-lubje-color.svg';
import LubjeLogoShort from '../../assets/images/logo-lubje-192.png';
import { Tenants } from 'store/tenants';
import { useAppSelector } from 'hooks/useRedux';
import { IAppState } from 'store/main';

interface ISmartLogoProps {
  className?: string;
  isShort?: boolean;
  style?: object;
}

const SmartLogo: React.FC<ISmartLogoProps> = ({
  className = '',
  isShort = false,
  style = {},
}) => {

  const tenant = useAppSelector((state: IAppState) => state.tenants.activeTenant);
  if (tenant === Tenants.LUBJE) {
    return (
      <img
        className={className}
        src={isShort ? LubjeLogoShort : LubjeLogo}
        alt="Logo Lubje"
        style={style}
      />
    );
  }
  const logo = isShort ? OtoStolikShortLogo : OtoStolikLogo;
  if (!logo) {
    return null;
  }
  return (
    <img className={className} src={logo} alt="Logo OtoStolik" style={style} />
  );
};

SmartLogo.propTypes = {
  className: PropTypes.string,
  isShort: PropTypes.bool,
};

export default React.memo(SmartLogo);
