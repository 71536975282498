import type { TRestaurantPlacesAreaConfig } from 'types';

import wloskaSztukaMainHall from './wloska-sztuka-sala-mapa.png';
import wloskaSztukaTerraceHall from './wloska-sztuka-ogrodek-mapa.png';

export const wloskaSztukaAreasConfig: TRestaurantPlacesAreaConfig = {
  127: {
    image: {
      src: wloskaSztukaMainHall,
      height: 1080,
      width: 1920,
    },
    tablesConfig: {},
  },
  128: {
    image: {
      src: wloskaSztukaTerraceHall,
      height: 1080,
      width: 1920,
    },
    tablesConfig: {},
  },
};
