import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import generalRoutes, {
  deliveryCoordinatorRoutes,
  exchangeDriverRoutes,
  IRoute,
  restaurantDriverRoutes,
} from 'routes';
import generalNav, {
  coordinatorNav,
  exchangeDriverNav,
  INavItem,
  restaurantDriverNav,
} from 'nav';
import { PERSISTENT_LS } from 'services/persistent';
import { logIfMemoryLeak } from 'utils/log';
import DashboardLayout from 'layouts/DashboardLayout';
import {
  selectLanguage,
  selectUserRole,
  selectUserRestaurants,
  selectUser,
  logout,
  retrieveSavedUserState,
  changeRestaurant,
} from 'store/app';
import {
  loadRestaurant,
  selectRestaurant,
  selectRestaurantLoading,
} from 'store/restaurant';
import { ROLES } from 'enums';
import RestaurantFeedback from 'app/components/RestaurantFeedback';
import type { OriginalRestaurant } from 'types';

import DeveloperPanel from 'app/components/debug/DeveloperPanel';
import UpdateAppBanner from './UpdateAppBanner';
import { loadTenantsConfigs } from 'store/tenants';
import CenterCardSpinner from 'app/components/common/CenterCardSpinner';
import SentryInitTags from './SentryInitTags';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import FcmMessageHandler from './notifications/FcmMessageHandler';
import { isProd } from 'config';
import SmartLogo from 'app/components/SmartLogo';
import HelmetMetaProvider from 'app/components/HelmetMetaProvider';

window.onerror = logIfMemoryLeak;

const OtoStolikRestaurantApp: React.FC<{}> = () => {
  const [messagingError, setFcmError] = useState<any>(null);
  const [pageName, setPageName] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();

  const lang = useAppSelector(selectLanguage);
  const restaurant = useAppSelector(selectRestaurant);
  const restaurantLoading = useAppSelector(selectRestaurantLoading);
  const role = useAppSelector(selectUserRole);
  const user = useAppSelector(selectUser);
  const userRestaurants = useAppSelector(selectUserRestaurants);
  const tenantsConfigs = useAppSelector(
    (state) => state.tenants.tenantsConfigs
  );

  const dispatch = useAppDispatch();

  const handleLogout = useCallback(
    () =>
      dispatch(
        logout({
          location,
          navigate,
        })
      ),
    [dispatch, location, navigate]
  );

  const trackPageView = useCallback((pageName: string) => {
    if (!isProd) {
      console.info('track', { page: pageName });
    }
    mixpanel.track_pageview({ page: pageName });
  }, []);

  useEffect(() => {
    pageName && trackPageView(pageName);
  }, [pageName, trackPageView]);

  const initApp = () => {
    const restaurant = PERSISTENT_LS.getRestaurant();
    if (!restaurant || !restaurant.id) {
      return handleLogout();
    }
    dispatch(loadTenantsConfigs());
    dispatch(loadRestaurant(restaurant.id));
    dispatch(retrieveSavedUserState());
  };

  useEffect(() => {
    initApp();
  }, []);

  const handleChangeRestaurantClick = useCallback(
    (restaurant: OriginalRestaurant) => {
      dispatch(changeRestaurant(restaurant));
    },
    [dispatch]
  );

  const products: string[] = useMemo(
    () => restaurant?.products || [],
    [restaurant]
  );

  const nav = useMemo(() => {
    return getPermissionBasedNav({
      role: role as number,
      restaurantId: restaurant?.id as number,
      restaurantProducts: products,
    });
  }, [role, restaurant, products]);

  const allowedRoutes = useMemo(() => {
    return getPermissionBasedRoutes({
      role: role as number,
      restaurantId: restaurant?.id as number,
      restaurantProducts: products,
    });
  }, [role, restaurant, products]);

  const renderPage = (page) => <page.component />;

  const renderLogo = ({
    className = '',
    isShort,
  }: {
    isShort: boolean;
    className?: string;
  }) => <SmartLogo className={className} isShort={isShort} />;

  if (!tenantsConfigs || !role || !user) {
    return <CenterCardSpinner />;
  }

  return (
    <DashboardLayout
      footer={<DeveloperPanel />}
      messagingError={messagingError}
      lang={lang}
      location={location}
      onChangeRestaurantClick={handleChangeRestaurantClick}
      onPageNameChange={setPageName}
      onLogoutClick={handleLogout}
      nav={nav}
      renderLogo={renderLogo}
      restaurant={restaurant}
      restaurantLoading={restaurantLoading}
      role={role}
      routes={allowedRoutes}
      user={user}
      userRestaurants={userRestaurants}
    >
      {pageName && <HelmetMetaProvider pageTitle={pageName} />}
      <FcmMessageHandler
        messagingError={messagingError}
        restaurant={restaurant}
        setFcmError={setFcmError}
      />
      <UpdateAppBanner />
      <SentryInitTags />
      <RestaurantFeedback restaurant={restaurant} />
      <Routes>
        {allowedRoutes.map((page) => (
          <Route
            key={page.path}
            index={page.index || false}
            path={page.path}
            element={renderPage(page)}
          />
        ))}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </DashboardLayout>
  );
};

export default OtoStolikRestaurantApp;

const isAvailableForRole = (role: number) => (item: IRoute | INavItem) =>
  !item.availableForRoles || item.availableForRoles.includes(role);

const isAvailableWithProducts =
  (restaurantProducts: string[]) => (item: IRoute | INavItem) =>
    !item.availableWithProducts ||
    item.availableWithProducts.some((neededProduct) =>
      restaurantProducts.includes(neededProduct)
    );

const isAvailableForRestaurantId =
  (restaurantId: number) =>
  (item: IRoute | INavItem): boolean =>
    !item.availableForRestaurantIds ||
    item.availableForRestaurantIds.includes(restaurantId);

function getPermissionBasedNav({
  role,
  restaurantId,
  restaurantProducts = [],
}: {
  role: number;
  restaurantId: number;
  restaurantProducts?: string[];
}) {
  const nav = getUnfilteredRoleBasedNav(role);
  const roleFilter = isAvailableForRole(role);
  const productFilter = isAvailableWithProducts(restaurantProducts);
  const restaurantIdFilter = isAvailableForRestaurantId(restaurantId);
  const filterChildren = (item: INavItem) =>
    'children' in item
      ? {
          ...item,
          children: item.children
            .filter(roleFilter)
            .filter(productFilter)
            .filter(restaurantIdFilter),
        }
      : item;

  return {
    ...nav,
    top: nav.top
      .map(filterChildren)
      .filter(roleFilter)
      .filter(productFilter)
      .filter(restaurantIdFilter),
    bottom: (nav.bottom || [])
      .filter(roleFilter)
      .filter(productFilter)
      .filter(restaurantIdFilter),
  };
}

function getUnfilteredRoleBasedNav(role: number) {
  if (role === ROLES.DELIVERY_COORDINATOR) {
    return coordinatorNav;
  }
  if (role === ROLES.RESTAURANT_DRIVER) {
    return restaurantDriverNav;
  }
  if (role === ROLES.EXCHANGE_DRIVER) {
    return exchangeDriverNav;
  }
  return generalNav;
}

function getPermissionBasedRoutes({
  role,
  restaurantId,
  restaurantProducts = [],
}: {
  role: number;
  restaurantId: number;
  restaurantProducts?: string[];
}) {
  const routes = getUnfilteredRoleBasedRoutes(role);
  return routes
    .filter(isAvailableForRole(role))
    .filter(isAvailableWithProducts(restaurantProducts))
    .filter(isAvailableForRestaurantId(restaurantId));
}

function getUnfilteredRoleBasedRoutes(role: number) {
  if (role === ROLES.DELIVERY_COORDINATOR) {
    return deliveryCoordinatorRoutes;
  }
  if (role === ROLES.RESTAURANT_DRIVER) {
    return restaurantDriverRoutes;
  }
  if (role === ROLES.EXCHANGE_DRIVER) {
    return exchangeDriverRoutes;
  }
  return generalRoutes;
}
