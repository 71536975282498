import React from 'react';
import { FormGroup } from 'reactstrap';
import i18next from 'i18next';

import { IconWithTooltip, OtoSpinner, OtoToggle, OtoButtons } from '../common';
import { ITableWithPlace } from 'types';

import './tables.scss';

interface TablesListProps {
  isLoading: (table: ITableWithPlace) => boolean;
  onTableEditClick: (table: ITableWithPlace) => void;
  onTableIsBookableToggle: (table: ITableWithPlace) => void;
  tables: ITableWithPlace[];
  tablesByIds: Record<number, string>;
}

export const TablesList: React.FC<TablesListProps> = ({
  isLoading,
  onTableEditClick,
  onTableIsBookableToggle,
  tables,
  tablesByIds,
}) => {
  return (
    <>
      {tables.map((table) => {
        const { id, linked_to } = table;
        return (
          <FormGroup key={table.id} className="table-settings__row">
            <div className="table-name-cell table-settings__row-cell">
              {table.name}
              <OtoButtons.EditButton
                onClick={() => onTableEditClick(table)}
                size={16}
              >
                {i18next.t('Edit')}
              </OtoButtons.EditButton>
            </div>
            <div className="table-size-cell table-settings__row-cell">
              {table.min_people} - {table.max_people} os.
            </div>
            <div className="table-settings__row-cell">
              {table.place && table.place.name}{' '}
              {table.place.is_outside ? (
                <IconWithTooltip
                  id={id}
                  faText="fas fa-sun-o"
                  text={i18next.t('table-settings.tooltip-table-outside')}
                />
              ) : (
                <IconWithTooltip
                  id={id}
                  faText="fas fa-home"
                  text={i18next.t('table-settings.tooltip-table-inside')}
                />
              )}
            </div>
            <div className="table-settings__row-cell">{table.priority}</div>
            <div className="table-settings__row-cell">
              {linked_to && linked_to.length
                ? linked_to.map((id) => tablesByIds[id]).join(', ')
                : '-'}
            </div>
            <div className="table-settings__row-cell">
              {isLoading(table) ? (
                <OtoSpinner />
              ) : (
                <OtoToggle
                  className="mr-2"
                  checked={!!table.is_bookable}
                  value="true"
                  onChange={() => onTableIsBookableToggle(table)}
                />
              )}
            </div>
          </FormGroup>
        );
      })}
    </>
  );
};

export default TablesList;
