export enum ModalTypes {
  //generic
  CONFIRM = 'CONFIRM',
  INPUT = 'INPUT',
  SELECT = 'SELECT',
  //custom
  PROMO_CODE_MODAL = 'PROMO_CODE',
  SEND_SMS_MODAL = 'SEND_SMS',
  ACCEPT_DELIVERY = 'ACCEPT_DELIVERY',
  ADD_COMPANY_CONTACT = 'ADD_COMPANY_CONTACT',
  DISABLE_ORDERS = 'DISABLE_ORDERS',
}
