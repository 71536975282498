import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import {
  Row,
  Label,
  Col,
  FormGroup,
  Input,
  FormText,
  Button,
  Spinner,
  FormFeedback,
} from 'reactstrap';
import { Form, FormikProps, withFormik } from 'formik';
import { Persist } from 'formik-persist';
import { toast } from 'react-toastify';

import APIService from 'services/api';
import { LOG_BAG, userFriednlyLog } from 'utils/log';

const products = ['reservations', 'online-orders', 'lubje', 'lesznoje', 'ostrowje'];

type TOwnProps = {
  cities: {
    id: number;
    name: string;
  }[];
  company: {
    id: number;
    nip: string;
    name: string;
    address: string;
    bank_account: string;
    signed_at: string;
  }
  representer: {
    name: string;
    email: string;
  }
  onSubmit: (onBoardData: Record<string, unknown>) => void;
};

type AddRestaurantFormValues = {
  restaurant: {
    name: string;
    city_id: number;
    location: string;
    phone: string;
    cuisine_label: string;
  };
  user: {
    name: string;
    email: string;
    phone: string;
  };
  product_of_interest: string;
  marketing_source: string;
}

type TProps = FormikProps<AddRestaurantFormValues> & TOwnProps;

const RestaurantForm: React.FC<TProps> = (props) => {
  const { cities, handleChange, values, isSubmitting, errors } = props;
  const { user, restaurant } = values;
  return (
    <Form>
      {!isSubmitting && (
        <Persist name="add-restaurant-restaurant-form" debounce={500} />
      )}
      <legend>{i18next.t('sign-up.restaurant-legend')}</legend>
      <Row form>
        <Col md={8}>
          <FormGroup>
            <Label for="restaurantName">
              {i18next.t('sign-up.restaurant-name-label')}
            </Label>
            <Input
              type="text"
              name="restaurant[name]"
              id="restaurantName"
              placeholder={i18next.t('sign-up.restaurant-name-ph')}
              value={restaurant.name}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Col md={3}>
          <FormGroup>
            <Label for="restaurantCity">
              {i18next.t('sign-up.restaurant-city-label')}
            </Label>
            <Input
              type="select"
              name="restaurant[city_id]"
              id="restaurantCity"
              placeholder={i18next.t('sign-up.restaurant-city-ph')}
              value={restaurant.city_id}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            >
              {cities.map((c) => (
                <option value={c.id} key={c.id}>
                  {c.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <Label for="restaurantLocation">
              {i18next.t('sign-up.restaurant-adress-label')}
            </Label>
            <Input
              type="text"
              name="restaurant[location]"
              id="restaurantLocation"
              placeholder={i18next.t('sign-up.restaurant-adress-ph')}
              value={restaurant.location}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="restaurantPhone">
              {i18next.t('sign-up.restaurant-phone-label')}
            </Label>
            <Input
              type="text"
              name="restaurant[phone]"
              id="restaurantPhone"
              placeholder={i18next.t('sign-up.restaurant-phone-ph')}
              value={restaurant.phone}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="restaurantCuisineLabel">
          {i18next.t('sign-up.restaurant-cuisine-label')}
        </Label>
        <Input
          type="text"
          name="restaurant[cuisine_label]"
          id="restaurantCuisineLabel"
          placeholder={i18next.t('sign-up.restaurant-cuisine-ph')}
          value={restaurant.cuisine_label}
          onChange={handleChange}
          required
          disabled={isSubmitting}
        />
        <FormText>{i18next.t('sign-up.restaurant-cuisine-info')}</FormText>
      </FormGroup>

      <legend>{i18next.t('sign-up.user-legend')}</legend>
      <FormGroup>
        <Label for="userName">{i18next.t('sign-up.user-name-label')}</Label>
        <Input
          type="text"
          name="user[name]"
          id="userName"
          placeholder={i18next.t('sign-up.user-name-ph')}
          value={user.name}
          onChange={handleChange}
          required
          disabled={isSubmitting}
        />
      </FormGroup>

      <Row form>
        <Col md={6}>
          <FormGroup>
            <Label for="userEmail">
              {i18next.t('sign-up.user-email-label')}
            </Label>
            <Input
              type="email"
              name="user[email]"
              id="userEmail"
              placeholder={i18next.t('sign-up.user-email-ph')}
              value={user.email}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
            {errors && errors.user?.email && (
              <FormFeedback>{errors.user?.email}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="userPhone">
              {i18next.t('sign-up.user-phone-label')}
            </Label>
            <Input
              type="text"
              name="user[phone]"
              id="userPhone"
              placeholder={i18next.t('sign-up.user-phone-ph')}
              value={user.phone}
              onChange={handleChange}
              required
              disabled={isSubmitting}
            />
            {errors && errors.user?.phone && (
              <FormFeedback>{errors.user?.phone}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <Label for="product_of_interest">
              {i18next.t('sign-up.product-of-interest.title')}
            </Label>
            <Input
              type="select"
              name="product_of_interest"
              id="product_of_interest"
              value={values.product_of_interest}
              onChange={handleChange}
              disabled={isSubmitting}
              required
            >
              <option value=""></option>
              {products.map((productKey) => (
                <option key={productKey} value={productKey}>
                  {i18next.t(`sign-up.product-of-interest.${productKey}`)}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <Label for="marketing_source">
              {i18next.t('sign-up.marketing-source-label')}
            </Label>
            <Input
              type="textarea"
              name="marketing_source"
              id="marketing_source"
              placeholder={i18next.t('sign-up.marketing-source-ph')}
              value={values.marketing_source}
              onChange={handleChange}
              disabled={isSubmitting}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button
        color="primary"
        block
        className="d-flex justify-content-center align-items-center"
        disabled={isSubmitting}
      >
        <span>{i18next.t('sign-up.submit-btn')}</span>
        {isSubmitting && <Spinner size="sm" color="light" className="ml-2" />}
      </Button>
    </Form>
  );
};

RestaurantForm.propTypes = {
  cities: PropTypes.array.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nip: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    bank_account: PropTypes.string.isRequired,
    signed_at: PropTypes.string.isRequired,
  }).isRequired,
  representer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withFormik<TOwnProps, AddRestaurantFormValues>({
  mapPropsToValues: (): AddRestaurantFormValues => ({
    restaurant: {
      name: '',
      city_id: 1,
      location: '',
      phone: '+48',
      cuisine_label: '',
    },
    user: {
      name: '',
      email: '',
      phone: '+48',
    },
    product_of_interest: '',
    marketing_source: '',
  }),
  handleSubmit: (values, { props, setSubmitting, setErrors }) => {
    const { company, representer, onSubmit } = props;
    setSubmitting(true);
    const mainLogPart = `
      Dane firmy: ${userFriednlyLog(company)}
      
      Dane restauracji: ${userFriednlyLog(values.restaurant)}
      Dane użytkownika: ${userFriednlyLog(values.user)}

      product_of_interest: ${values.product_of_interest}
      
      marketing_source: ${values.marketing_source}`;
    LOG_BAG.logSalesActivity(`Restaurator założył nowe konto z poziomu panelu administratora.
    Skontaktujmy się z nim!${mainLogPart}`);
    const url = '/onboard';
    const payload = {
      ...values,
      companyId: company.id,
      representer,
    };
    APIService.post(url, payload)
      .then(onSubmit)
      .catch((err) => {
        toast.error(
          'Wystąpił błąd zapisu danych restauracji. Popraw błędy i spróbuj ponownie, lub skontaktuj się z nami mailowo: hello@otostolik.pl.',
          { autoClose: false }
        );
        LOG_BAG.logSalesActivity(
          `Wystąpił błąd dodawania restauracji i użytkownika do firmy (rejestracja restauracji - krok 2)!
           Błąd: ${JSON.stringify(err)}
           ${mainLogPart}
        `
        );
        setErrors(err.errors);
      })
      .finally(() => setSubmitting(false));
  },
})(RestaurantForm);
