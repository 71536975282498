import React, { useMemo, useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';

import withSetModal from 'app/containers/WithSetModal';
import UsersTable from 'app/components/users/UsersTable';
import { CollapsibleCard, OtoSpinner, OtoTabs } from 'app/components/common';
import UsersRestaurantsTable from 'app/components/users/UsersRestaurantsTable';
import {
  createUser,
  createUserRestaurant,
  selectAdminRestaurants,
  selectAdminRestaurantsLoading,
  selectAdminUsers,
  selectAdminUsersLoading,
  selectAdminUsersRestaurants,
  selectAdminUsersRestaurantsLoading,
} from 'store/admin';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import AddUserForm from 'app/components/users/AddUserForm';
import { citiesById } from 'config';
import OtoAutocomplete from 'app/components/common/OtoAutocomplete';
import AddUserRestaurantForm from 'app/components/users/AddUserRestaurantForm';

const tabs = {
  Users: 'Użytkownicy',
  UsersRestaurants: 'Przypisania użytkowników do restauracji',
};

const AdminUsersPageWithFetchedData = () => {
  const [tab, setTab] = useState(tabs.Users);

  const adminRestaurants = useAppSelector(selectAdminRestaurants);
  const users = useAppSelector(selectAdminUsers);
  const usersLoading = useAppSelector(selectAdminUsersLoading);
  const usersRestaurants = useAppSelector(selectAdminUsersRestaurants);
  const usersRestaurantsLoading = useAppSelector(
    selectAdminUsersRestaurantsLoading
  );
  const restaurants = useAppSelector(selectAdminRestaurants);
  const restaurantsLoading = useAppSelector(selectAdminRestaurantsLoading);

  const isAnyLoading =
    usersLoading || usersRestaurantsLoading || restaurantsLoading;

  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const restaurantOptions = useMemo(
    () =>
      restaurants.map((restaurant) => ({
        id: restaurant.id,
        name: `${restaurant.name} (${restaurant.id}) ${
          citiesById[restaurant.city_id]?.name || 'Inne miasto'
        }`,
      })),
    [restaurants]
  );

  const dispatch = useAppDispatch();

  if (isAnyLoading) {
    return (
      <>
        <OtoSpinner />
        <div>
          {usersLoading ? 'Pobieranie użytkowników' : 'Użytkownicy pobrani'}
        </div>
        <div>
          {usersRestaurantsLoading
            ? 'Pobieranie przypisań użytkowników do restauracji'
            : 'Przypisania użytkowników do restauracji pobrane'}
        </div>
        <div>
          {restaurantsLoading
            ? 'Pobieranie restauracji'
            : 'Restauracje pobrane'}
        </div>
      </>
    );
  }

  return (
    <>
      <div>Pobrano {users?.length} użytkowników</div>
      <div>
        Pobrano {usersRestaurants?.length} przypisań użytkowników do restauracji
        (users_restaurants)
      </div>
      <OtoTabs activeTab={tab} tabs={tabs} changeTab={setTab} />
      <TabContent activeTab={tab}>
        <TabPane tabId={tabs.Users}>
          {tab === tabs.Users && (
            <>
              <CollapsibleCard title={'Dodaj użytkownika'}>
                <OtoAutocomplete
                  options={restaurantOptions}
                  isSingle={true}
                  isObject={true}
                  value={selectedRestaurant}
                  onChange={(value) =>
                    setSelectedRestaurant(
                      value as {
                        id: number;
                        name: string;
                      } | null
                    )
                  }
                  fieldToDisplay={'name'}
                  placeholder={'Wybierz restauracje'}
                  style={{
                    searchBox: {
                      background: 'white',
                      marginBottom: '10px',
                    },
                  }}
                  emptyRecordMsg={'Nie wybrano restauracji'}
                />
                {selectedRestaurant && (
                  <AddUserForm
                    isDriver={false}
                    restaurantId={selectedRestaurant.id}
                    onAddUser={(values) => dispatch(createUser(values))}
                  />
                )}
              </CollapsibleCard>
              <UsersTable users={users} />
            </>
          )}
        </TabPane>
        <TabPane tabId={tabs.UsersRestaurants}>
          {tab === tabs.UsersRestaurants && (
            <>
              <AddUserRestaurantForm
                restaurants={restaurants}
                users={users}
                onAddUserRestaurant={(values) =>
                  dispatch(createUserRestaurant(values))
                }
              />
              <UsersRestaurantsTable
                restaurants={adminRestaurants}
                users={users}
                userRestaurants={usersRestaurants}
              />
            </>
          )}
        </TabPane>
      </TabContent>
    </>
  );
};

const EnhancedAdminUsersPage = withSetModal(AdminUsersPageWithFetchedData, {
  navName: 'admin.users-list',
  url: '/admin/users-list',
});

export default EnhancedAdminUsersPage;
