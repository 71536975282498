// temp inactive
// const indian = 'Indyjska';
// const exlusive = 'Autorska';

const indian = 'Indyjska';
const pub = 'Pub';
const coffee = 'Kawiarnia';

const burgers = 'Burgery';
const pizza = 'Pizza';
const sushi = 'Sushi';
const pasta = 'Makaron';
const american = 'Amerykańska';
const italian = 'Włoska';
const japanese = 'Japońska';
const east = 'Wschodnia';
const kebab = 'Kebab';
const polishandeuropean = 'Polska';
const vege = 'Wege';
const vegan = 'Vegan';
const mexican = 'Meksykańska';
const asian = 'Azjatycka';

const breakfast = 'Śniadanie';
const salad = 'Sałatki';

// todo align this with web. kebab, breakfast, salad are potentially inconsistent
export const SIDEBAR_ORDER_CUISINE_LIST = [
  burgers,
  pizza,
  sushi,
  pasta,
  american,
  italian,
  japanese,
  polishandeuropean,
  east,
  asian,
  kebab,
  vege,
  vegan,
  mexican,
  breakfast,
  salad,
  pub,
  coffee,
  'Inne'
];

export const SIDEBAR_BOOK_CUISINE_LIST = [
  burgers,
  pizza,
  sushi,
  pasta,
  american,
  italian,
  japanese,
  polishandeuropean,
  indian,
  east,
  asian,
  vege,
  vegan,
  breakfast,
  salad,
  pub,
  coffee,
];
