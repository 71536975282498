import React from 'react';
import PropTypes from 'prop-types';
import type { FormikProps } from 'formik';
import i18 from 'i18next';
import {
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import { ShoppingCart } from 'react-feather';

import IconWithTooltip from '../common/IconWithTooltip';
import InputWithAddon from '../common/InputWithAddon';
import { col10Style } from '../../styles';
import { currency } from 'globals/currency';
import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import type { OrdersConfigFormValues } from 'app/pages/OrderSettingsPage';

type ShoppingCartSettingsFormProps = {
  loading: boolean,
  isAdmin: boolean,
  handleChange: FormikProps<OrdersConfigFormValues>['handleChange']
  values: OrdersConfigFormValues
}

const ShoppingCartSettingsForm: React.FC<ShoppingCartSettingsFormProps> = (props) => {
  const { isAdmin, loading, handleChange, values } = props;
  const showDeprecated = isAdmin;

  return (
    <Row>
      <Col md={col10Style}>
        <Card>
          <CardHeader className="font-weight-bold text-black">
            <ShoppingCart />
            {i18.t('order-settings-form.basket-settings')}
          </CardHeader>
          <CardBody className="pt-0">
            {
              <>
                <hr />
                <Row>
                  <FormGroup className="col-12 col-md-6">
                    <Label for="deliveryTime">
                      <span className="mr-2">
                        {i18.t('order-settings-form.delivery-time')}
                      </span>
                      <IconWithTooltip
                        id="deliveryTime"
                        text={i18.t(
                          'order-settings-form.delivery-time-tooltip'
                        )}
                      />
                    </Label>
                    <InputWithAddon
                      addonText="min"
                      type="number"
                      name="deliveryTime"
                      id="deliveryTime"
                      onChange={handleChange}
                      value={values.deliveryTime || ''}
                      placeholder={i18.t('order-settings-form.eg-60')}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6">
                    <Label for="takeoutTime">
                      <span className="mr-2">
                        {i18.t('order-settings-form.pickup-time')}
                      </span>
                      <IconWithTooltip
                        id="takeoutTime"
                        text={i18.t(
                          'order-settings-form.delivery-time-tooltip'
                        )}
                      />
                    </Label>
                    <InputWithAddon
                      addonText="min"
                      type="number"
                      name="takeoutTime"
                      id="takeoutTime"
                      onChange={handleChange}
                      value={values.takeoutTime || ''}
                      placeholder={i18.t('order-settings-form.eg-30')}
                    />
                  </FormGroup>
                </Row>
                <hr />
                {showDeprecated && (
                  <>
                    <div className="text-danger">Deprecated</div>
                    <Row className="b-danger">
                      <FormGroup className="col-12 col-md-6">
                        <Label for="minCartAmount">
                          {i18.t('order-settings-form.min-basket-value')}
                        </Label>
                        <InputWithAddon
                          addonText={currency}
                          type="number"
                          name="delivery.minCartAmount"
                          id="minCartAmount"
                          placeholder={i18.t('order-settings-form.eg-30')}
                          onChange={handleChange}
                          value={values.delivery.minCartAmount || ''}
                        />
                      </FormGroup>
                    </Row>
                    <hr />
                  </>
                )}
                <FormGroup check>
                  <Label>
                    <Input
                      type="checkbox"
                      name="askForCutlery"
                      checked={values.askForCutlery || false}
                      onChange={handleChange}
                    />
                    <span className="mr-2">
                      {i18.t('order-settings-form.ask-for-cutlery')}
                    </span>
                  </Label>
                </FormGroup>
                <hr />
                <FormGroup>
                  <Label for="alertText">
                    <span className="mr-2">
                      {i18.t('order-settings-form.alert-message')}
                    </span>
                    <IconWithTooltip
                      id="alert-text"
                      text={i18.t('order-settings-form.alert-tooltip')}
                    />
                  </Label>
                  <Input
                    type="textarea"
                    name="alertText"
                    id="alertText"
                    onChange={handleChange}
                    placeholder={i18.t('order-settings-form.alert-placeholder')}
                    value={values.alertText || ''}
                  />
                </FormGroup>
                <SubmitButtonWithLoader
                  className="mt-3"
                  loading={loading}
                />
              </>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

ShoppingCartSettingsForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ShoppingCartSettingsForm;
