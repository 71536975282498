import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import FA from 'react-fontawesome';

interface IconWithTooltipProps {
  className?: string;
  id: string | number;
  faText?: string;
  style?: React.CSSProperties;
  text: React.ReactNode;
}

export const IconWithTooltip: React.FC<IconWithTooltipProps> = (props) => {
  const { className = '', id, faText, style, text } = props;
  const fullId = `icon-with-tooltip-${id}`;
  return (
    <>
      <span
        className={className}
        style={{ marginLeft: 'auto', ...style }}
        id={fullId}
      >
        <FA name={faText || 'question-circle-o'} />
      </span>
      <UncontrolledTooltip placement="top" target={fullId}>
        {text}
      </UncontrolledTooltip>
    </>
  );
};

IconWithTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  faText: PropTypes.string,
  text: PropTypes.node.isRequired,
};

export default IconWithTooltip;
