import React from 'react';

import { NormalizedRestaurant } from 'types';
import { hasReservations } from 'utils/restaurant';
import UpdateBanner from '../common/UpdateBanner';

interface IProps {
  restaurant: NormalizedRestaurant;
}

const ReservationReleaseNotes: React.FC<IProps> = ({ restaurant }) => {
  if (!hasReservations(restaurant)) {
    return null;
  }
  return (
    <>
      <UpdateBanner
        canBeClosed={false}
        id="reservation-place-visualization-22-may-2023"
        dateTo="2023-05-24"
      >
        <div className="h5">Cześć, mam dla Ciebie dobrą nowość :) </div>
        <p>
          Wprowadzamy nową usługę - możliwość zdefiniowania mapki stolików
          (niewidocznej dla gości, ale pomagającej Ci i Twojemu personelowi
          podczas wprowadzania rezerwacji).
        </p>
        <p>
          <a
            href={'https://www.loom.com/share/6ed47d6fe5754d91b08bae127eb4b043'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Zobacz jak to działa (3min)
          </a>
        </p>
        <div>Ten baner zniknie automatycznie 24 maja.</div>
      </UpdateBanner>
      <UpdateBanner
        canBeClosed={false}
        id="reservation-updated-3-april-2023"
        dateTo={'2023-04-06'}
      >
        <div className="h5">
          <p>Cześć, mamy dla Ciebie kilka aktualizacji!</p>
          <p>
            <div>
              Zapoznaj się z filmikami poniżej, aby dowiedzieć się więcej.
            </div>
            <div>Ten baner zniknie automatycznie 6 kwietnia.</div>
          </p>

          <p>
            <a
              href={
                'https://www.loom.com/share/08b7a94ff6f04bce9ac913f0ffe3e569'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Filmik 1 (3min)
            </a>
            <span className="ml-2">
              (Legenda rezerwacji, nowy widok, filtr "aktywna sala" + lepsza
              edycja rezerwacji!)
            </span>
          </p>
          <p>
            <a
              href={
                'https://www.loom.com/share/721e1c1442d84738a9ba764698610a7c'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Filmik 2 (4min)
            </a>
            <span className="ml-2">
              (Szybka edycja rezerwacji, godziny dla gości, więcej statystyk!)
            </span>
          </p>
        </div>
      </UpdateBanner>
    </>
  );
};

export default ReservationReleaseNotes;
