import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from 'react-places-autocomplete';

const activeSuggestionStyle = { backgroundColor: '#fafafa', cursor: 'pointer' };
const suggestionStyle = { backgroundColor: '#ffffff', cursor: 'pointer' };

const renderSuggestions = (suggestions, getSuggestionItemProps) => {
  return suggestions.map(suggestion => {
    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
    const style = suggestion.active ? activeSuggestionStyle : suggestionStyle;
    return (
      <div
        {...getSuggestionItemProps(suggestion, {
          className,
          style,
        })}
      >
        <span>{suggestion.description}</span>
      </div>
    );
  });
};

const StreetAutocomplete = ({ searchOptions, street, handleStreetChange, handleSelect, wrapperClassName }) => {
  return <PlacesAutocomplete
    value={street}
    onChange={handleStreetChange}
    onSelect={handleSelect}
    shouldFetchSuggestions={street.length > 2}
    searchOptions={searchOptions}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div className={wrapperClassName}>
        <input
          {...getInputProps({
            placeholder: 'Wyszukaj ulicę ...',
            className: 'form-control',
            id: 'testing'
          })}
        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {renderSuggestions(suggestions, getSuggestionItemProps)}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
};

StreetAutocomplete.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  handleStreetChange: PropTypes.func.isRequired,
  searchOptions: PropTypes.object.isRequired,
  street: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};

export default StreetAutocomplete;
