import React, { useState } from 'react';

import ReservationsFetcher, {
  ReservationsFetcherProps,
} from '../containers/ReservationsFetcher';
import ReservationsDaysLimitFilter, {
  daysLimitFilterOptions,
} from 'app/components/reservations/ReservationsDaysLimitFilter';
import { useAppSelector } from 'hooks/useRedux';
import {
  selectReservations,
  selectReservationsLoading,
} from 'store/reservations';
import type { NormalizedRestaurant } from 'types';
import { OtoSpinner } from 'app/components/common';

type TDefaultRange = '2-weeks' | '1-month' | '3-months' | '1-year' | 'all';
interface ViewReservationsFetcherWithRangeSelectProps
  extends ReservationsFetcherProps {
  defaultRange: TDefaultRange;
  restaurant: NormalizedRestaurant;
}
const ViewReservationsFetcherWithRangeSelect: React.FC<
  ViewReservationsFetcherWithRangeSelectProps
> = (props) => {
  const reservations = useAppSelector(selectReservations);
  const reservationsLoading = useAppSelector(selectReservationsLoading);

  const [daysLimitFilter, setDaysLimitFilter] = useState<string>(
    getDefaultValue(props.defaultRange)
  );
  const handleChangeDaysLimitFilter = (e) => setDaysLimitFilter(e.target.value);

  return (
    <>
      <ReservationsFetcher daysLimitFilter={daysLimitFilter} {...props} />
      <ReservationsDaysLimitFilter
        disabled={reservationsLoading}
        onChange={handleChangeDaysLimitFilter}
        value={daysLimitFilter}
      />
      {reservationsLoading ? (
        <div className="align-vertical gap-10">
          <OtoSpinner />
          <span>Pobieram listę rezerwacji</span>
        </div>
      ) : (
        <div className={'my-2'}>
          Łączna liczba rezerwacji: {reservations.length}
        </div>
      )}
    </>
  );
};

export default ViewReservationsFetcherWithRangeSelect;

function getDefaultValue(defaultRange: TDefaultRange): string {
  if (defaultRange === '2-weeks') {
    return daysLimitFilterOptions.find(
      (option) => option.name === 'reservations.filter-last-two-weeks'
    )!.value;
  }
  if (defaultRange === '1-month') {
    return daysLimitFilterOptions.find(
      (option) => option.name === 'reservations.filter-last-month'
    )!.value;
  }
  if (defaultRange === '3-months') {
    return daysLimitFilterOptions.find(
      (option) => option.name === 'reservations.filter-last-three-month'
    )!.value;
  }
  if (defaultRange === '1-year') {
    return daysLimitFilterOptions.find(
      (option) => option.name === 'reservations.filter-last-year'
    )!.value;
  }
  if (defaultRange === 'all') {
    return daysLimitFilterOptions.find(
      (option) => option.name === 'reservations.filter-all'
    )!.value;
  }
  return daysLimitFilterOptions[0].value;
}
