import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import SimpleTableWithSorting, {
  generateColumn,
} from '../common/SimpleTableWithSorting';
import CollapsibleCard from '../common/CollapsibleCard';
import AdminPromoCodesStatistics from './AdminPromoCodesStatistics';
import StatisticsColumn from '../statistics/StatisticsColumn';
import { fixDecimal } from 'utils/general';
import AdminActiveRestaurantsStats from './AdminActiveRestaurantsStats';

const ordersColumnns = [
  generateColumn('year'),
  generateColumn('month'),
  generateColumn('monthname'),
  generateColumn('Orders New Amount'),
  generateColumn('Orders Total Amount'),
  generateColumn('Orders New Gross', { shouldFixDecimal: true }),
  generateColumn('Orders Total Gross', { shouldFixDecimal: true }),
];

const partnerRestaurantsColumns = [
  generateColumn('year'),
  generateColumn('month'),
  generateColumn('New partner restaurants'),
  generateColumn('Total partner restaurants'),
];

const reservationColumns = [
  generateColumn('month'),
  generateColumn('new'),
  generateColumn('total'),
];

export interface IActiveRestaurantStatsItem {
  month: string;
  orders_amount: number;
  orders_sum: number;
  restaurant_id: number;
  restaurant_name: string;
  year: string;
}

interface IAdminSalesProps {
  data: {
    active_restaurants: Array<IActiveRestaurantStatsItem>;
    orders: Array<object>;
    reservations: Array<object>;
    partner_restaurants: Array<object>;
    promo_codes: {
      cost_for_us: number;
      most_popular: Array<object>;
    };
  };
}

const AdminSalesStatistics = ({ data }: IAdminSalesProps) => {
  const { orders, reservations, partner_restaurants } = data;
  const normalizedReservations = React.useMemo(
    () =>
      Object.entries(reservations).map(([month, data]) => ({
        month,
        ...data,
      })),
    [reservations]
  );

  const activeRestaurantsAmountByMonth = data.active_restaurants.reduce(
    (acc: object, restaurantItem: IActiveRestaurantStatsItem) => {
      if (!acc[restaurantItem.year]) {
        acc[restaurantItem.year] = {};
      }
      if (!acc[restaurantItem.year][restaurantItem.month]) {
        acc[restaurantItem.year][restaurantItem.month] = {
          restaurants: [],
          restaurants_orders_amount: 0,
          restaurants_orders_sum: 0,
        };
      }
      let monthItem = acc[restaurantItem.year][restaurantItem.month];
      acc[restaurantItem.year][restaurantItem.month] = {
        restaurants: [...monthItem.restaurants, restaurantItem],
        restaurants_orders_amount:
          monthItem.restaurants_orders_amount + restaurantItem.orders_amount,
        restaurants_orders_sum:
          monthItem.restaurants_orders_sum + restaurantItem.orders_sum,
      };
      return acc;
    },
    {}
  );

  const cellClassName = 'bb-1 pb-0 px-1';

  const ActiveRestaurantsStats = (props) => (
    <>
      <div className="h3">{props.title}</div>
      <Row className="align-items-center">
        <div>
          <div className={cellClassName}>Month</div>
          <div className={cellClassName}>Value</div>
        </div>
        {Object.entries(activeRestaurantsAmountByMonth).map(
          ([year, monthWithData]: any) =>
            Object.entries(monthWithData)
              .sort(([a], [b]) => parseInt(a, 10) - parseInt(b, 10))
              .map(([month, stats]: any) => (
                <Col key={month} className="text-center px-0 py-2">
                  <div className={cellClassName}>
                    {month}/{year}
                  </div>
                  <div className={cellClassName}>{props.children(stats)}</div>
                </Col>
              ))
        )}
      </Row>
    </>
  );

  return (
    <>
      <ActiveRestaurantsStats title="Ile restauracji miało zamówienia">
        {(stats) => stats.restaurants.length}
      </ActiveRestaurantsStats>
      <ActiveRestaurantsStats title="Średni obrót per restauracja per miesiąc">
        {(stats) =>
          fixDecimal(stats.restaurants_orders_sum / stats.restaurants.length)
        }
      </ActiveRestaurantsStats>
      <Row>
        <Col xs="12" className="mt-2">
          <CollapsibleCard
            title={<h4 className="mb-0">Active restaurants stats</h4>}
          >
            <AdminActiveRestaurantsStats data={data.active_restaurants} />
            {/* <SimpleTableWithSorting data={orders} columns={ordersColumnns} /> */}
          </CollapsibleCard>
        </Col>
        <Col xs="12" className="mt-2">
          <CollapsibleCard
            title={<h4 className="mb-0">Orders general stats</h4>}
          >
            <SimpleTableWithSorting data={orders} columns={ordersColumnns} />
          </CollapsibleCard>
        </Col>
        <Col xs="12" className="mt-2">
          <CollapsibleCard
            title={<h4 className="mb-0">Partner restaurants general stats</h4>}
          >
            <SimpleTableWithSorting
              data={partner_restaurants}
              columns={partnerRestaurantsColumns}
            />
          </CollapsibleCard>
        </Col>
        <Col xs="12" className="mt-2">
          <CollapsibleCard
            title={<h4 className="mb-0">Reservations general stats</h4>}
          >
            <SimpleTableWithSorting
              data={normalizedReservations}
              columns={reservationColumns}
            />
          </CollapsibleCard>
        </Col>
        {!!data.promo_codes && (
          <>
            <StatisticsColumn
              title="general promo codes cost for us"
              value={data.promo_codes.cost_for_us}
            />
            <Col xs="12" className="mt-2">
              <CollapsibleCard
                title={<h4 className="mb-0">Promo codes general stats</h4>}
              >
                <AdminPromoCodesStatistics
                  data={data.promo_codes.most_popular as any}
                />
              </CollapsibleCard>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

AdminSalesStatistics.propTypes = {
  data: PropTypes.exact({
    active_restaurants: PropTypes.array.isRequired,
    orders: PropTypes.array.isRequired,
    reservations: PropTypes.object.isRequired,
    partner_restaurants: PropTypes.array.isRequired,
    promo_codes: PropTypes.object.isRequired,
  }).isRequired,
};

export default AdminSalesStatistics;
