import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

import App from './App';
import { isProd } from 'config';
import { store } from 'store/index';
import { configurationContainer } from 'configurationContainer';

const tracesSampleRate = isProd ? 0.05 : 1.0;

configurationContainer.SENTRY_DSN &&
  Sentry.init({
    dsn: configurationContainer.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api\.otostolik\.pl\/api/,
          /^https:\/\/stage\.otostolik\.pl\/api/,
          /^http:\/\/otostolik\.local\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    beforeSend: (event, hint) => {
      const error = hint.originalException as Error;
      if (
        error?.message?.includes(
          'Sesja wygasła, zaloguj się by móc kontynuować'
        )
      ) {
        return null;
      }
      return event;
    },
    // Performance Monitoring
    tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: 0.005,
    replaysOnErrorSampleRate: 0.1,
  });

if (configurationContainer.MIXPANEL_TOKEN) {
  mixpanel.init(configurationContainer.MIXPANEL_TOKEN, {
    debug: false,
    track_pageview: true,
  });
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
