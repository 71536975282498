import React from 'react'
import type { NavLinkProps } from 'reactstrap'

export type LinkComponentType = 'a' | ((props: { to: string }) => React.ReactElement | null)

const createLinkComposed = (LinkComponent: LinkComponentType) => {
  const LinkComposed = (props: NavLinkProps & { to: string }) => {
    const { to, style, ...otherProps } = props
    const toProp = (LinkComponent === 'a' || LinkComponent === undefined) ? { href: to } : { to }

    return (
      // @ts-ignore
      <LinkComponent
        style={style}
        {...toProp}
        {...otherProps}
      />
    )
  }

  return LinkComposed
}

export default createLinkComposed
