import React, { useState } from 'react';

import { releaseVersion } from 'globals/version';
import { LANGS } from 'config';

interface IProps {
  onDebugEnable: () => void;
  lang: string;
}

const DeveloperVersion = ({ onDebugEnable, lang }: IProps) => {
  const [timesClicked, setTimesClicked] = useState(0);

  const handleClick = () => {
    setTimesClicked(timesClicked + 1);

    if (timesClicked === 4) {
      onDebugEnable();
      setTimesClicked(0);
    }
  };

  return (
    <span onClick={handleClick}>
      {lang === LANGS.default ? 'Wersja: ' : 'Version'} {releaseVersion}
    </span>
  );
};

export default DeveloperVersion;
