import { toast } from 'react-toastify';

import APIService from 'services/api';
import { ORDER_STATUS } from 'config';
import {
  getArrayWithoutObject,
  getArrayWithUpdatedObject,
} from 'utils/array';
import { logError } from 'utils/log';
import { LOGOUT } from './app';
import type { IAppState } from './main';
import { EAsyncStatus } from 'enums';
import type { AnyRestaruant, IOrder } from 'types';

export const INITIAL_STATE: IOrdersReducerState = {
  allOrders: [],
  openedOrder: null,
  lastUpdateTime: null,
  status: EAsyncStatus.IDLE,
  updatingOrders: [],
};

export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING';
export const SET_ORDERS_SUCCESS = 'SET_ORDERS_SUCCESS';
export const SET_ORDERS_ERROR = 'SET_ORDERS_ERROR';
const OPEN_ORDER_MODAL = 'OPEN_ORDER_MODAL';
const CLOSE_ORDER_MODAL = 'CLOSE_ORDER_MODAL';

export const UPDATE_ORDER_LOADING = 'UPDATE_ORDER_LOADING';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';

export interface IOrdersReducerState {
  allOrders: IOrder[];
  lastUpdateTime: Date | null;
  openedOrder: IOrder | null;
  updatingOrders: number[];
  status: EAsyncStatus;
}

interface IOrdersAction {
  type: string;
  payload: any;
  orders: IOrder[]; // TODO optional
  order: IOrder; // TODO optional
  orderId: number; // TODO optional
}

function ordersReducer(
  state = INITIAL_STATE,
  action: IOrdersAction
): IOrdersReducerState {
  switch (action.type) {
    case SET_ORDERS_LOADING:
      return {
        ...state,
        status: EAsyncStatus.PENDING,
      };
    case SET_ORDERS_SUCCESS:
      return {
        allOrders: action.orders,
        lastUpdateTime: new Date(),
        openedOrder: state.openedOrder,
        updatingOrders: state.updatingOrders,
        status: EAsyncStatus.RESOLVED,
      };
    case SET_ORDERS_ERROR:
      return {
        allOrders: [],
        updatingOrders: state.updatingOrders,
        lastUpdateTime: null,
        openedOrder: state.openedOrder,
        status: EAsyncStatus.REJECTED,
      };
    case UPDATE_ORDER_LOADING:
      return {
        ...state,
        updatingOrders: [...state.updatingOrders, action.orderId],
      };
    case UPDATE_ORDER_ERROR:
      return {
        ...state,
        updatingOrders: state.updatingOrders.filter(
          (o) => o !== action.orderId
        ),
      };
    case UPDATE_ORDER_SUCCESS:
      const orderBeforeUpdate = state.allOrders.find(
        (order) => order.id === action.order.id
      );
      const isRestaurantChange =
        orderBeforeUpdate?.restaurant_id !== action.order.restaurant_id;
      return {
        ...state,
        allOrders: isRestaurantChange
          ? getArrayWithoutObject(state.allOrders, action.order)
          : getArrayWithUpdatedObject(state.allOrders, action.order),
        updatingOrders: state.updatingOrders.filter(
          (o) => o !== action.order.id
        ),
      };
    case OPEN_ORDER_MODAL:
      return {
        ...state,
        openedOrder: action.order,
      };
    case CLOSE_ORDER_MODAL:
      return {
        ...state,
        openedOrder: null,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const setOrdersLoading = () => ({ type: SET_ORDERS_LOADING });
export const setOrdersSuccess = (orders) => ({
  type: SET_ORDERS_SUCCESS,
  orders,
});
export const setOrdersError = () => ({ type: SET_ORDERS_ERROR });

interface IAsyncOptions {
  onError?: (e: any, url?: string) => void;
  onSuccess?: (payload?: unknown) => void;
}

export const loadOrders =
  (url: string, options: IAsyncOptions = {}) =>
  (dispatch) => {
    const { onSuccess, onError } = options;
    dispatch(setOrdersLoading());
    return APIService.get(url)
      .then((orders) => {
        dispatch(setOrdersSuccess(orders));
        onSuccess && onSuccess(orders);
      })
      .catch((e) => {
        onError && onError(e, url);
        dispatch(setOrdersError());
      });
  };

// eslint-disable-next-line
export const updateOrder =
  (
    payload: Partial<IOrder>,
    orderId: number,
    restaurantId: number,
    { onSuccess, onError }: IAsyncOptions = {}
  ) =>
  (dispatch) => {
    dispatch({ type: UPDATE_ORDER_LOADING, orderId });
    const url = `/restaurants/${restaurantId}/orders/${orderId}?with=restaurant,sourceRel`; // TODO fix on BE probably needed
    return APIService.put(url, payload)
      .then((updatedOrder) => {
        dispatch({
          type: UPDATE_ORDER_SUCCESS,
          order: updatedOrder,
        });
        onSuccess && onSuccess(updatedOrder);
        return updatedOrder;
      })
      .catch((e) => {
        dispatch({ type: UPDATE_ORDER_ERROR, orderId });
        onError && onError(e);
        logError(`PUT ${url} fail`, e);
        return e;
      });
  };

export const updateOrderStatus = (
  order: IOrder,
  status: IOrder['status'],
  restaurant: AnyRestaruant,
  { extra, onSuccess }
) => {
  let payload = { status };
  if (status === ORDER_STATUS.CONFIRMED) {
    const extraPart =
      typeof extra === 'object' ? extra : { prepare_time: extra };
    payload = {
      status,
      ...extraPart,
      customer: { ...order.customer },
    };
  }
  if (status === ORDER_STATUS.DECLINED) {
    const extraPart = typeof extra === 'object' ? extra : { reason: extra };
    payload = { status, ...extraPart };
  }

  const wrapperOnSuccess = () => {
    toast.success('Zamówienie zostało zaktualizowane');
    onSuccess && onSuccess();
  };
  const onError = () =>
    toast.error('Wystąpił błąd podczas aktualizacji zamówienia');
  return updateOrder(payload, order.id, restaurant.id, {
    onSuccess: wrapperOnSuccess,
    onError,
  });
};

export const changeOrderRestaurant = (
  order: IOrder,
  currentRestaurantId: number,
  newRestaurantId: number,
  { onSuccess }: IAsyncOptions = {}
) => {
  const payload = { restaurant_id: newRestaurantId };
  const wrapperOnSuccess = () => {
    toast.success('Zamówienie zostało przeniesione');
    onSuccess && onSuccess();
  };
  const onError = () =>
    toast.error('Wystąpił błąd podczas przenoszenia zamówienia');
  return updateOrder(payload, order.id, currentRestaurantId, {
    onSuccess: wrapperOnSuccess,
    onError,
  });
};

export const openOrderModal = (order: IOrder) => ({
  type: OPEN_ORDER_MODAL,
  order,
});

export const closeOrderModal = () => ({ type: CLOSE_ORDER_MODAL });

export default ordersReducer;

// Selectors
export const selectAllOrders = (state: IAppState) => state.orders.allOrders;
export const selectOrdersLoading = (state: IAppState) => state.orders.status === EAsyncStatus.PENDING;
export const selectOrdersLastUpdateTime = (state: IAppState) => state.orders.lastUpdateTime;
export const selectOrdersLoadedAtLeastOnce = (state: IAppState) => state.orders.status === EAsyncStatus.RESOLVED || state.orders.status === EAsyncStatus.REJECTED;
export const selectOpenedOrder = (state: IAppState) => state.orders.openedOrder;
export const selectUpdatingOrders = (state: IAppState) => state.orders.updatingOrders;