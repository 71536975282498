import PropTypes from 'prop-types';

import { Tenants } from 'enums';

const payoutShape = PropTypes.exact({
  commission_brut: PropTypes.number.isRequired,
  commission_net: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  orders_sum_all: PropTypes.number.isRequired,
  orders_sum_offline: PropTypes.number.isRequired,
  orders_sum_online: PropTypes.number.isRequired,
  period_from: PropTypes.string.isRequired,
  period_to: PropTypes.string.isRequired,
  promo_codes_cost: PropTypes.number.isRequired,
  restaurant_id: PropTypes.number.isRequired,
  sum_to_payout: PropTypes.number.isRequired,
  tenant: PropTypes.oneOf(Object.values(Tenants)).isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  paid_at: PropTypes.string,
});

export default payoutShape;
