import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';

import APIService from 'services/api';
import { PERSISTENT_LS } from 'services/persistent';
import { initUserState, setApiToken } from 'store/app';
import {
  DEFAULT_URL,
  DELIVERIES_URL,
  ORDERS_URL,
  RESERVATIONS_URL,
} from 'config';
import { loadRestaurant } from 'store/restaurant';
import { ROLES } from 'enums';

import Welcome from './Welcome';
import DeliveriesPage from './DeliveriesPage';
import DriverDeliveriesPage from './DriverDeliveriesPage';
import DashboardPageForTenantAdmin from './admin/DashboardPageForTenantAdmin';
import DashboardPageForRootAdmin from './admin/DashboardPageForRootAdmin';
import HelmetMetaProvider from 'app/components/HelmetMetaProvider';
import ViewLanguagePicker from 'app/views/ViewLanguagePicker';

import Restaurant from 'utils/restaurant';
import { User } from 'types/user';
import LoginForm from 'app/components/login/LoginForm';
import { NormalizedRestaurant, OriginalRestaurant } from 'types/restaurants';
import SmartLogo from 'app/components/SmartLogo';
import DeveloperPanel from 'app/components/debug/DeveloperPanel';
import Footer from '../theme-components/Footer/Footer';
import { useAppDispatch } from 'hooks/useRedux';

export interface ILoginResponse {
  access_token: string;
  user: User;
  role_id: number;
  restaurant: OriginalRestaurant;
  restaurants?: OriginalRestaurant[];
}

const getRedirectLink = (restaurant: NormalizedRestaurant) => {
  const userRole = PERSISTENT_LS.getLsRole();
  if (Restaurant.isNew(restaurant)) {
    return Welcome.url;
  }
  if (userRole === ROLES.DELIVERY_COORDINATOR) {
    // @fixme class component page type
    // @ts-expect-error
    return DeliveriesPage.url;
  }
  if (
    userRole === ROLES.LUBJE_STAKEHOLDER ||
    userRole === ROLES.LESZNOJE_ADMIN
  ) {
    return DashboardPageForTenantAdmin.url;
  }
  if (
    userRole === ROLES.RESTAURANT_DRIVER ||
    userRole === ROLES.EXCHANGE_DRIVER
  ) {
    // @fixme class component page type
    // @ts-expect-error
    return DriverDeliveriesPage.url;
  }
  if (userRole === ROLES.ADMIN) {
    return DashboardPageForRootAdmin.url;
  }
  if (Restaurant.hasOrders(restaurant)) {
    return ORDERS_URL;
  }
  if (
    Restaurant.hasOwnDeliveries(restaurant) ||
    Restaurant.hasExchangeDeliveries(restaurant) ||
    Restaurant.hasRobotDeliveries(restaurant)
  ) {
    return DELIVERIES_URL;
  }
  if (Restaurant.hasReservations(restaurant)) {
    return RESERVATIONS_URL;
  }
  return DEFAULT_URL;
};
const wrapperColStyle = {
  margin: '0 auto 1rem',
  maxWidth: 600,
  padding: '0 10px',
};

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values, actions) => {
    const { email, password } = values;
    const payload = {
      email,
      password,
    };
    APIService.post('/auth/login', payload)
      .then((response: ILoginResponse) => {
        const { access_token, role_id, restaurant, restaurants, user } =
          response;
        setApiToken(access_token);
        PERSISTENT_LS.setLsRole(role_id.toString());
        PERSISTENT_LS.setUser(user);
        if (restaurants && restaurants.length) {
          PERSISTENT_LS.setLsUserRestaurants(restaurants);
        }
        dispatch(
          initUserState({
            email,
            role: role_id,
            restaurants,
            user,
          })
        );
        return dispatch(loadRestaurant(restaurant.id));
      })
      .then((reduxAction) => {
        const { restaurant } = reduxAction as unknown as {
          restaurant: NormalizedRestaurant;
        };
        if (!PERSISTENT_LS.getFirstLoginDate()) {
          PERSISTENT_LS.setFirstLoginDate(new Date());
        }
        navigate(getRedirectLink(restaurant));
      })
      .catch((error) => {
        actions.setSubmitting(false);
        const toastText =
          typeof error === 'string'
            ? error
            : 'Wystąpił błąd podczas próby logowania';
        toast.error(toastText);
      });
  };

  return (
    <div className="login-page-wrapper">
      <Row>
        <HelmetMetaProvider pageTitle="Login" />
        <Col md={{ size: 8, offset: 2 }}>
          <div style={wrapperColStyle}>
            <div className="d-flex flex-row flex-wrap justify-content-between">
              <h1 className="h4 d-flex align-items-center justify-content-center">
                <SmartLogo style={{ width: 120 }} />
                <span className="mt-2 ml-2 nowrap">
                  - {i18next.t('login-page.title')}
                </span>
              </h1>
              <ViewLanguagePicker className="mb-2 mb-md-0" />
            </div>
            <Card>
              <CardBody>
                <LoginForm handleSubmit={handleSubmit} />
              </CardBody>
            </Card>
            <p className="mt-2">
              {i18next.t('login-page.no-account-yet')}{' '}
              <a href="/signup">{i18next.t('login-page.create-account')}</a>
            </p>
          </div>
        </Col>
      </Row>
      <Footer>
        <DeveloperPanel />
      </Footer>
    </div>
  );
};

export default LoginPage;
