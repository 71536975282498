import React from 'react';

import type { OriginalRestaurant, TRestaurantFinancialConfig } from 'types';

type RestaurantFinancialSettingsProps = {
  restaurant: OriginalRestaurant & {
    finance_settings?: TRestaurantFinancialConfig;
  };
};

const RestaurantFinancialSettings: React.FC<
  RestaurantFinancialSettingsProps
> = ({ restaurant }) => {
  if (!restaurant.finance_settings) {
    return <div>Brak ustawień finansowych</div>;
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <div>Prowizje od zamówień:</div>
      {!!restaurant.finance_settings.orders?.commission ? (
        Object.entries(restaurant.finance_settings.orders.commission).map(
          ([tenantName, tenantCommission]) => (
            <div key={tenantName}>
              {tenantName}: {tenantCommission.orders}% +{' '}
              {tenantCommission.online_payments}% za płatności online
            </div>
          )
        )
      ) : (
        <div>Brak ustawień prowizji</div>
      )}
    </div>
  );
};

export default RestaurantFinancialSettings;
