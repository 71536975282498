import { from } from 'env-var'

type TEnvironment = 'development' | 'production' | 'test' | 'staging' | 'local' | 'unknown'

export type Configuration = {
  ENVIRONMENT: TEnvironment,
  API_URL: string | null,
  MIXPANEL_TOKEN: string | null,
  SENTRY_DSN: string | null,

  FEATURE_FLAGS: {
    BLUR_CUSTOMERS_PERSONAL_DATA: boolean,
  },
}

const createConfigurationContainer = (
  source: NodeJS.ProcessEnv
): Configuration => {
  try {
    const env = from(source)

    // @HACK CRA sets the node environment to 'development' by default, but we want to distinguish between local and development servers
    const NODE_ENV = env.get('NODE_ENV').asString() || 'unknown'
    const ENVIRONMENT = (NODE_ENV === 'development' && window.location.hostname === 'localhost' ? 'local' : NODE_ENV) as TEnvironment

    const config: Configuration = {
      ENVIRONMENT,
      API_URL: null, // TODO migrate getApiUrl() here as phase 1 and migrate it to env var as phase 2
      SENTRY_DSN: env.get('REACT_APP_SENTRY_DSN').asString() || null,
      MIXPANEL_TOKEN: env.get('REACT_APP_MIXPANEL_TOKEN').asString() || null,
      FEATURE_FLAGS: {
        BLUR_CUSTOMERS_PERSONAL_DATA: env.get('REACT_APP_BLUR_CUSTOMERS_PERSONAL_DATA').asBoolStrict() || false,
      },
    }

    const overwrites: Partial<Configuration['FEATURE_FLAGS']> = {}

    return {
      ...config,
      FEATURE_FLAGS: {
        ...config.FEATURE_FLAGS,
        ...overwrites,
      },
    }
  } catch (error) {
    alert('Configuration error: ' + (error as Error).message)
    throw error
  }
}

export const configurationContainer = createConfigurationContainer(process.env)
