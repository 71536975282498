import React, { useState } from 'react';
import i18next from 'i18next';
import { Button, Row } from 'reactstrap';

import UrlFetcher from '../../containers/UrlFetcher';
import StatisticsColumn from '../statistics/StatisticsColumn';
import { Tenants } from 'enums';
import { addDays } from 'date-fns';
import {
  OtoFormGroup,
  ReactTableCellProps,
  SimpleTableWithSorting,
  generateColumn,
  getRTOriginal,
} from '../common';
import { fixDecimal } from 'utils/general';
import { toDateInputValue } from 'utils/date-time';

interface IProps {
  tenant: Tenants;
}

type TCustomerStat = {
  customer_id: number;
  name: string;
  surname: string;
  phone: string;
  orders_count: number;
  orders_sum_price: number;
  orders_max_created_at: string;
  orders_min_created_at: string;
};

const cols = [
  generateColumn('name'),
  generateColumn('surname'),
  generateColumn('phone'),
  generateColumn('email'),
  generateColumn('orders_count', { customHeader: 'Liczba zamówień' }),
  generateColumn('orders_sum_price', {
    customHeader: 'Suma zamówień',
    customCellFormatter: (props: ReactTableCellProps<TCustomerStat>) =>
      fixDecimal(getRTOriginal<TCustomerStat>(props).orders_sum_price),
  }),
  generateColumn('orders_min_created_at', { customHeader: 'First order' }),
  generateColumn('orders_max_created_at', { customHeader: 'Last order' }),
];

const AdminCustomerStats: React.FC<IProps> = ({ tenant }) => {
  const [minFirstOrder, setMinFirstOrder] = useState<Date | null>(null);
  const [maxFirstOrder, setMaxFirstOrder] = useState<Date | null>(null);
  const [minLastOrder, setMinLastOrder] = useState<Date | null>(null);
  const [maxLastOrder, setMaxLastOrder] = useState<Date | null>(null);

  return (
    <>
      <Row className="mx-0">
        <OtoFormGroup
          className="mw-300 ml-2"
          label="Min firstOrder date"
          type="date"
          name="minFirstOrder"
          onChange={(e) =>
            setMinFirstOrder(e.target.value ? new Date(e.target.value) : null)
          }
          value={minFirstOrder ? toDateInputValue(minFirstOrder) : ''}
        />
        <OtoFormGroup
          className="mw-300 ml-2"
          label="Max firstOrder date"
          type="date"
          name="maxFirstOrder"
          onChange={(e) =>
            setMaxFirstOrder(e.target.value ? new Date(e.target.value) : null)
          }
          value={maxFirstOrder ? toDateInputValue(maxFirstOrder) : ''}
        />
        <OtoFormGroup
          className="mw-300 ml-2"
          label="Min lastOrder date"
          type="date"
          name="minLastOrder"
          onChange={(e) =>
            setMinLastOrder(e.target.value ? new Date(e.target.value) : null)
          }
          value={minLastOrder ? toDateInputValue(minLastOrder) : ''}
        />
        <OtoFormGroup
          className="mw-300 ml-2"
          label="Max lastOrder date"
          type="date"
          name="maxLastOrder"
          onChange={(e) =>
            setMaxLastOrder(e.target.value ? new Date(e.target.value) : null)
          }
          value={maxLastOrder ? toDateInputValue(maxLastOrder) : ''}
        />
      </Row>
      <UrlFetcher url={`/statistics/customers?tenant=${tenant}`}>
        {([customersStatsRaw, urlFetcherProps]: [TCustomerStat[], any]) => {
          const customerStats = customersStatsRaw
            .map((item) => ({
              ...item,
              firstOrder: new Date(item.orders_min_created_at),
              lastOrder: new Date(item.orders_max_created_at),
            }))
            .filter((item) => {
              if (minFirstOrder && item.firstOrder < minFirstOrder) {
                return false;
              }

              if (maxFirstOrder && item.firstOrder > maxFirstOrder) {
                return false;
              }

              if (minLastOrder && item.lastOrder < minLastOrder) {
                return false;
              }

              if (maxLastOrder && item.lastOrder > maxLastOrder) {
                return false;
              }

              return true;
            });

          const orderedOnceAmount = customerStats.filter(
            (item) => item.orders_count === 1
          ).length;
          const orderedTwiceAmount = customerStats.filter(
            (item) => item.orders_count === 2
          ).length;
          const orderedMoreThanTwiceAmount = customerStats.filter(
            (item) => item.orders_count > 2
          ).length;

          const _7daysAgo = addDays(new Date(), -7);
          const _30daysAgo = addDays(new Date(), -30);
          const _60daysAgo = addDays(new Date(), -60);

          const orderedWithin7DaysAmount = customerStats.filter(
            (item) => item.lastOrder > _7daysAgo
          ).length;
          const orderedWithin30DaysAmount = customerStats.filter(
            (item) => item.lastOrder > _30daysAgo
          ).length;
          const orderedWithin60DaysAmount = customerStats.filter(
            (item) => item.lastOrder > _60daysAgo
          ).length;

          return (
            <>
              <Button type="button" onClick={urlFetcherProps.reloadData}>
                {i18next.t('Reload')}
              </Button>
              <h2>
                Liczba klientów w zależności od daty ostatniego zamówienia
                (aktywność)
              </h2>
              <Row className="m-b-md align-items-end">
                <StatisticsColumn
                  title="Łączna liczba klientów"
                  value={customerStats.length}
                />
                <StatisticsColumn
                  title="Zamówili w ciągu ostatnich 7 dni (aktywni)"
                  value={orderedWithin7DaysAmount}
                />
                <StatisticsColumn
                  title="Zamówili w ciągu ostatnich 30 dni (aktywni)"
                  value={orderedWithin30DaysAmount}
                />
                <StatisticsColumn
                  title="Zamówili w ciągu ostatnich 60 dni (uśpieni)"
                  value={orderedWithin60DaysAmount}
                />
                <StatisticsColumn
                  title="NIE Zamówili w ciągu ostatnich 60 dni (straceni)"
                  value={customerStats.length - orderedWithin60DaysAmount}
                />
              </Row>
              <h2>
                Liczba klientów w zależności od liczby zamówień (czy wracają)
              </h2>
              <Row className="m-b-md align-items-end">
                <StatisticsColumn
                  title="Zamówili tylko 1 raz"
                  value={orderedOnceAmount}
                />
                <StatisticsColumn
                  title="Zamówili tylko 2 razy"
                  value={orderedTwiceAmount}
                />
                <StatisticsColumn
                  title="Zamówili 2+ razy"
                  value={orderedMoreThanTwiceAmount}
                />
              </Row>
              <SimpleTableWithSorting
                hasFilters
                data={customerStats}
                columns={cols}
              />
            </>
          );
        }}
      </UrlFetcher>
    </>
  );
};

export default AdminCustomerStats;
