export enum EIntegrationType {
  GOOGLE_ANALYTICS = 'google-analytics',
  FACEBOOK_PIXEL = 'facebook-pixel',
  IFRAME = 'iframe',
  FOODELI = 'foodeli-api',
  SZAMAEXPRESS = 'szamaexpress',
  DELIVERY_COUPLE = 'delivery-couple',
}

type TCommonIntegrationFields = {
  id: number;
  restaurant_id: number;
  created_at: string;
  updated_at: string;
};

type TIntegrationFieldsByType =
  | {
      data: {
        external_restaurant_id: number;
      };
      type: EIntegrationType.DELIVERY_COUPLE;
    }
  | {
      data: {
        api_key: string;
      };
      type: EIntegrationType.SZAMAEXPRESS;
    }
  | {
      data: {
        email: string;
      };
      type: EIntegrationType.FOODELI;
    }
  | {
      data: {
        value: string;
      };
      type: EIntegrationType.GOOGLE_ANALYTICS | EIntegrationType.FACEBOOK_PIXEL;
    }
  | {
      data: {
        css: Record<string, string>;
      };
      type: EIntegrationType.IFRAME;
    };

export type IIntegration = TCommonIntegrationFields & TIntegrationFieldsByType;

export type TAvailableIntegration = {
  name: string;
  key: EIntegrationType;
  img?: string;
  modalTitle: string;
  note?: string;
};
