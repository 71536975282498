import type { MenuItem, MenuItemAdditionalField, MenuItemPriceOption } from 'types';
import { AdditionalFieldTypes } from 'enums';

const hasNoPrice = (priceOption: MenuItem | MenuItemPriceOption) => !priceOption.price;

export const isProductWithEmptyName = (product: MenuItem) => !product.name;

export const hasProductZeroPrice = (product: MenuItem) => {
  if (product.priceOptions) {
    return product.priceOptions.filter(hasNoPrice).length > 0;
  }
  return hasNoPrice(product);
};

export const isChoiceFieldWithoutValue = (field: MenuItemAdditionalField) => {
  if (field.type === AdditionalFieldTypes.SINGLE_CHOICE
      || field.type === AdditionalFieldTypes.MULTIPLE_CHOICE) {
    return !field.value;
  }
  return false;
};

export const isCheckboxFieldWithPriceError = (field: MenuItemAdditionalField) => {
  if (field.type !== AdditionalFieldTypes.CHECKBOX) {
    return false;
  }
  if (field.addonPrice < 0) {
    return true;
  }
  return !field.addonPrice && field.addonPrice !== 0;
};
