import React from 'react';
import PropTypes from 'prop-types';

import OtoSpinner from '../../common/OtoSpinner';
import { OriginalRestaurant } from 'types/restaurants';
import { YesNoIcons } from '../../icons/IconSets';
import Shapes from 'shapes/main';
import { hasProduct } from 'utils/restaurant';

interface IProps {
  addProduct: (restaurant: OriginalRestaurant, product: string) => void;
  isLoading: boolean;
  restaurant: OriginalRestaurant;
  productsToShow: string[];
  removeProduct: (restaurant: OriginalRestaurant, product: string) => void;
}

const AdminRestaurantListItemProducts: React.FC<IProps> = (props) => {
  const { addProduct, isLoading, productsToShow, restaurant, removeProduct } =
    props;
  const productClassName = 'align-vertical clickable mr-3 mb-2';

  return (
    <div className="align-vertical flex-wrap">
      {isLoading ? (
        <OtoSpinner center />
      ) : (
        productsToShow.map((product) =>
          hasProduct(restaurant, product) ? (
            <div
              key={product}
              className={productClassName}
              onClick={() => removeProduct(restaurant, product)}
            >
              {YesNoIcons.YES} {product}
            </div>
          ) : (
            <div
              key={product}
              className={productClassName}
              onClick={() => addProduct(restaurant, product)}
            >
              {YesNoIcons.NO} {product}
            </div>
          )
        )
      )}
    </div>
  );
};

AdminRestaurantListItemProducts.propTypes = {
  addProduct: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  restaurant: Shapes.originalRestaurantShape.isRequired,
  productsToShow: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  removeProduct: PropTypes.func.isRequired,
};

export default AdminRestaurantListItemProducts;
