import React from 'react';
import OtoButtons from '../common/OtoButtons';

const errorSolutionWrapperStyle = {
  borderBottom: '1px solid #868686',
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  padding: '5px 0',
  maxWidth: 400,
};

interface IFixErrorStepsProps {
  onLogout: () => void;
}

const FixErrorSteps: React.FC<IFixErrorStepsProps> = (props) => {
  const { onLogout } = props
  return (
    <>
      <p>Zwykle jedno z poniższych rozwiązań powinno naprawić usterkę:</p>
      <div style={errorSolutionWrapperStyle}>
        Rozwiązanie 1:{' '}
        <OtoButtons.ReloadButton onClick={() => window.location.reload()}>
          Wczytaj stronę ponownie
        </OtoButtons.ReloadButton>
      </div>
      <div style={errorSolutionWrapperStyle}>
        Rozwiązanie 2: <OtoButtons.LogoutButton onClick={onLogout} />
      </div>
      <div style={errorSolutionWrapperStyle}>
        Rozwiązanie 3:{' '}
        <OtoButtons.DeleteButton
          color="link"
          onClick={() => {
            sessionStorage.clear();
            localStorage.clear();
            onLogout();
          }}
        >
          Usuń ciasteczka na stronie
        </OtoButtons.DeleteButton>
      </div>
      <p className="mt-2">
        Jeżeli to nie pomoże - skontaktuj się z zespołem OtoStolik.
      </p>
    </>
  );
};

export default FixErrorSteps;
