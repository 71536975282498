import { toast } from 'react-toastify';

import { NormalizedRestaurant } from 'types/restaurants';
import APIService from 'services/api';
import { getSafeLsJSON } from 'utils/parsers';
import { UpdateTarget } from 'enums';

const HASH_LS_KEY = 'updates-hashes';

const getLocalHashByTarget = (target: UpdateTarget) => {
  const hashes = getSafeLsJSON(HASH_LS_KEY, {});
  return hashes && hashes[target];
};

export const saveLocalHashByTarget = (
  target: UpdateTarget,
  newHash: string
) => {
  const currentHashes = getSafeLsJSON(HASH_LS_KEY, {});
  localStorage.setItem(
    HASH_LS_KEY,
    JSON.stringify({
      ...currentHashes,
      [target]: newHash,
    })
  );
};

const getLatestHashByTarget = (restaurant, target: UpdateTarget) =>
  APIService.get(`/restaurants/${restaurant.id}/updates?target=${target}`);

export const labelsByUpdateTarget: Record<UpdateTarget, string> = {
  [UpdateTarget.CustomRules]: 'dni wyjątkowych',
  [UpdateTarget.Menu]: 'menu',
  [UpdateTarget.OrdersConfig]: 'ustawień zamówień',
  [UpdateTarget.ReservationConfig]: 'ustawień rezerwacji',
};

export const checkIfTargetHashUpToDate =
  (
    restaurant: NormalizedRestaurant,
    options: {
      updateHashAfterCheck: boolean;
      target: UpdateTarget;
    }
  ) =>
  (dispatch) => {
    const { updateHashAfterCheck, target } = options;
    return getLatestHashByTarget(restaurant, target)
      .then((latestMenuHash) => {
        const existingMenuHash = getLocalHashByTarget(target);
        updateHashAfterCheck && saveLocalHashByTarget(target, latestMenuHash);
        const local = existingMenuHash?.version;
        const actual = latestMenuHash?.version;
        return {
          actual,
          local,
          isUpToDate: local === actual,
        };
      })
      .catch((e) => {
        toast.error(
          `Nie udało się sprawdzić aktualności ${labelsByUpdateTarget[target]}, pobieram na nowo`
        );
        return { isUpToDate: false };
      });
  };

export const checkIfCustomRulesUpToDate = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: false,
    target: UpdateTarget.CustomRules,
  });

export const updateCustomRulesHash = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: true,
    target: UpdateTarget.CustomRules,
  });

export const checkIfMenuUpToDate = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: false,
    target: UpdateTarget.Menu,
  });

export const updateMenuHash = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: true,
    target: UpdateTarget.Menu,
  });

export const checkIfOrdersConfigUpToDate = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: false,
    target: UpdateTarget.OrdersConfig,
  });

export const updateOrdersConfigHash = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: true,
    target: UpdateTarget.OrdersConfig,
  });

export const checkIfReservationConfigUpToDate = (
  restaurant: NormalizedRestaurant
) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: false,
    target: UpdateTarget.ReservationConfig,
  });

export const updateReservationConfigHash = (restaurant: NormalizedRestaurant) =>
  checkIfTargetHashUpToDate(restaurant, {
    updateHashAfterCheck: true,
    target: UpdateTarget.ReservationConfig,
  });
