import React from 'react';

import { ModalTypes } from 'config';
import { TUser } from 'types';
import Order from 'utils/orders';
import {
  getRTOriginal,
  ReactTableCellProps,
} from '../../common/SimpleTableWithSorting';
import type { TSetModalFunction } from '../../../containers/WithSetModal';
import withSetModal from '../../../containers/WithSetModal';
import { OtoButtons, OtoSpinner } from 'app/components/common';
import APIService from 'services/api';
import { toast } from 'react-toastify';
import { LOG_BAG } from 'utils/log';

type ChangePasswordCellProps = {
  setModal: TSetModalFunction;
  data: ReactTableCellProps<TUser>;
};

const ChangePasswordCell: React.FC<ChangePasswordCellProps> = (props) => {
  const { setModal, data } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const user = getRTOriginal<TUser>(data);

  const changePassword = (newPassword: string) => {
    setIsLoading(true);
    return APIService.put(`/users/${user.id}/change-password`, {
      user_id: user.id,
      new_password: newPassword,
    })
      .then(() => toast.success('Hasło zmienione'))
      .catch((error) => {
        LOG_BAG.logError('Change password error', error);
        toast.error('Wystąpił błąd podczas zmiany hasła.');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={'align-vertical gap-5'}>
      <div>#{user.id}</div>
      {isLoading && <OtoSpinner />}
      <OtoButtons.EditButton
        type="button"
        color="link"
        disabled={isLoading}
        onClick={() => {
          setModal(
            {
              title: `Zmieniasz hasło dla użytkownika ${user.name}`,
              text: (
                <>
                  <div>ID użytkownika: {user.id}</div>
                  <div>Email użytkownika: {user.email}</div>
                  <div>Wprowadź nowe hasło poniżej</div>
                </>
              ),
              options: Order.CONFIRMED_ORDER_DECLINE_REASONS,
              other: 'inny',
              confirm: (newPassword: string) => {
                return changePassword(newPassword);
              },
              confirmColor: 'danger',
              confirmText: 'Zmień hasło',
              cancelText: 'Zamknij',
            },
            ModalTypes.INPUT
          );
        }}
      >
        Zmień hasło
      </OtoButtons.EditButton>
    </div>
  );
};

export default withSetModal(ChangePasswordCell);
