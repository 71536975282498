import { toast } from 'react-toastify';

import APIService from 'services/api';
import { logError, logImportantActivity } from 'utils/log';
import { NormalizedRestaurant } from 'types/restaurants';

interface ISendSMSParams {
  customers: string[],
  message: string,
  restaurant: NormalizedRestaurant,
  onError: () => void,
  onSuccess: () => void,
}

export const sendSms = ({ customers, message, restaurant, onError, onSuccess, }: ISendSMSParams): Promise<void> => {
  const url = `/restaurants/${restaurant.id}/sms`;
  const payload = {
    customer_ids: Array.from(customers),
    message,
  };
  return APIService.post(url, payload)
    .then(() => {
      toast.success('Wiadomość SMS została wysłana');
      logImportantActivity(`Restaurant ${restaurant.name} sent SMS to ${payload.customer_ids.length} customers.`);
      onSuccess && onSuccess();
    })
    .catch(e => {
      logError(` sendSMS error; URL: ${url}`, e);
      toast.error('Wystąpił błąd pobczas wysyłania wiadomości SMS');
      onError && onError();
    });
};
