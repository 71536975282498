import React, { useEffect, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

import type { OriginalRestaurant } from 'types';
import OtoSpinner from '../common/OtoSpinner';
import {
  loadAdminRestaurants,
  selectAdminRestaurants,
  selectAdminRestaurantsLoading,
} from 'store/admin';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';

interface AdminRestaurantSelectorProps {
  loadIfMissing?: boolean;
  onSelect: (restaurant: OriginalRestaurant) => void;
}

type RestaurantWithIdInName = OriginalRestaurant & {
  nameWithId: string;
};

const AdminRestaurantSelector: React.FC<AdminRestaurantSelectorProps> = ({
  loadIfMissing,
  onSelect,
}) => {
  const restaurants = useAppSelector(selectAdminRestaurants);
  const restaurantsLoading = useAppSelector(selectAdminRestaurantsLoading);

  const [restaurantsWithIds, setRestaurantsWithIds] = useState<
    RestaurantWithIdInName[]
  >([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadIfMissing && !restaurantsLoading && !restaurants.length) {
      dispatch(loadAdminRestaurants());
    }
  }, [loadIfMissing, restaurants, restaurantsLoading, dispatch]);

  useEffect(() => {
    setRestaurantsWithIds(
      restaurants.map((r) => ({ ...r, nameWithId: `${r.name} (${r.id})` }))
    );
  }, [restaurants]);

  if (restaurantsLoading) {
    return <OtoSpinner />;
  }

  return (
    <Multiselect
      options={restaurantsWithIds}
      selectedValues={null}
      onSelect={(selectedRestaurants: RestaurantWithIdInName[]) =>
        onSelect(selectedRestaurants[0])
      }
      displayValue="nameWithId"
      placeholder="Wybierz restaurację"
    />
  );
};

export default AdminRestaurantSelector;
