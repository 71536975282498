import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form } from 'reactstrap';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { toast } from 'react-toastify';
import { connect, ConnectedProps } from 'react-redux';

import { checkToken, checkAppVersion } from 'services/auth';
import { DISCOUNT_TYPES } from 'config';
import { selectIsRootAdmin } from 'store/app';
import { selectOrdersConfig, selectRestaurant, updateOrdersConfig } from 'store/restaurant';
import { whatHasChanged } from 'utils/general';
import { logActivity, formatChangedKeys, LOG_BAG } from 'utils/log';
import OtoSpinner from 'app/components/common/OtoSpinner';
import PreviewLink from 'app/views/ViewPreviewLink';
import DiscountsForm, {
  DiscountFormValues,
} from 'app/components/discounts/DiscountsForm';
import { getDefaultOrdersConfig } from './OrderSettingsPage';
import {
  DiscountCategory,
  DiscountProduct,
  NormalizedRestaurant,
  OrdersConfig,
} from 'types';

import 'app/components/settings-form.scss';
import Shapes from 'shapes/main';

const createDiscountCategoryRule = (
  categoryName: string,
  percent = 50
): DiscountCategory => ({
  type: DISCOUNT_TYPES.SAME_CATEGORY,
  categoryName,
  percent,
});

const createDiscountProductRule = (
  productName: string,
  percent = 50
): DiscountProduct => ({
  type: DISCOUNT_TYPES.SAME_PRODUCT,
  productName,
  percent,
});

type TPropsWithoutFormik = PropsFromRedux;

type TProps = FormikProps<DiscountFormValues> & TPropsWithoutFormik;

interface IState {
  loading: boolean;
  discountCategories: DiscountCategory[];
  discountProducts: DiscountProduct[];
}

const COMPONENT_NAME = 'DiscountSettingsForm';

class DiscountSettingsForm extends React.PureComponent<TProps, IState> {
  static url = '/discounts';
  static navName = 'Promocje';

  static getDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  static propTypes = {
    ordersConfig: Shapes.ordersConfigShape.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
    discountCategories: [],
    discountProducts: [],
  } as IState;

  _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
    checkAppVersion();
    if (checkToken()) {
      this.mapConfigToFields();
      this._isMounted && this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  mapConfigToFields = () => {
    const ordersConfig = this.props.ordersConfig || { delivery: {} };
    const rules = ordersConfig.discountRules || [];
    this.props.setValues({
      ...this.props.values,
      ...ordersConfig,
    });
    this._isMounted &&
      this.setState({
        discountCategories: rules.filter(
          (rule) => rule.type === DISCOUNT_TYPES.SAME_CATEGORY
        ) as DiscountCategory[],
        discountProducts: rules.filter(
          (rule) => rule.type === DISCOUNT_TYPES.SAME_PRODUCT
        ) as DiscountProduct[],
      });
  };

  addCategoryToDiscount = (newCatName: string, percent: number) => {
    if (this.state.discountProducts.length) {
      toast.error(
        'Brak możliwości ustawienia rabatu -50% na kategorię przy obecności rabatu na produkty. Usuń rabat na produkty, żeby móc ustawić rabat na całe katerogie'
      );
      return;
    }
    const { discountCategories } = this.state;
    if (!discountCategories.find((cat) => cat.categoryName === newCatName)) {
      const newDiscountCategories = [
        ...discountCategories,
        createDiscountCategoryRule(newCatName, percent),
      ];
      this._isMounted &&
        this.setState({
          discountCategories: newDiscountCategories,
        });
      this.props.setFieldValue('discountCategories', newDiscountCategories);
    }
  };

  removeCategoryFromDiscount = (index: number) => {
    const { discountCategories } = this.state;
    const newDiscountCategories = [
      ...discountCategories.slice(0, index),
      ...discountCategories.slice(index + 1),
    ];
    this._isMounted &&
      this.setState({
        discountCategories: newDiscountCategories,
      });
    this.props.setFieldValue('discountCategories', newDiscountCategories);
  };

  addProductToDiscount = (newProdName: string, percent: number) => {
    if (this.state.discountCategories.length) {
      toast.error(
        'Brak możliwości ustawienia rabatu -50% na wybrany produkt przy obecności rabatu na kategorie. Usuń rabat na kategorie, żeby móc ustawić rabat na pojedyńcze produkty'
      );
      return;
    }
    const { discountProducts } = this.state;
    if (!discountProducts.find((prod) => prod.productName === newProdName)) {
      const newDiscountProducts = [
        ...discountProducts,
        createDiscountProductRule(newProdName, percent),
      ];
      this._isMounted &&
        this.setState({
          discountProducts: newDiscountProducts,
        });
      this.props.setFieldValue('discountProducts', newDiscountProducts);
    }
  };
  removeProductFromDiscount = (index: number) => {
    const { discountProducts } = this.state;
    const newDiscountProducts = [
      ...discountProducts.slice(0, index),
      ...discountProducts.slice(index + 1),
    ];
    this._isMounted &&
      this.setState({
        discountProducts: newDiscountProducts,
      });
    this.props.setFieldValue('discountProducts', newDiscountProducts);
  };

  render() {
    if (this.state.loading) {
      return <OtoSpinner center />;
    }
    const { handleSubmit, isSubmitting, restaurant } = this.props;
    return (
      <Form onSubmit={handleSubmit} className="order-settings-form">
        <Container className="flex-column">
          <PreviewLink
            className="align-self-start mb-3"
            restaurant={restaurant}
          />
          <DiscountsForm
            addCategoryToDiscount={this.addCategoryToDiscount}
            addProductToDiscount={this.addProductToDiscount}
            discountCategories={this.state.discountCategories}
            discountProducts={this.state.discountProducts}
            isAdmin={this.props.isAdmin}
            isSubmitting={isSubmitting}
            handleChange={this.props.handleChange}
            menu={this.props.restaurant.menu}
            removeCategoryFromDiscount={this.removeCategoryFromDiscount}
            removeProductFromDiscount={this.removeProductFromDiscount}
            values={this.props.values}
          />
        </Container>
      </Form>
    );
  }
}

const mapPropsToValues = ({ restaurant }): DiscountFormValues => ({
  ...getDefaultOrdersConfig(restaurant),
  discountCategories: [],
  discountProducts: [],
});

const EnhancedDiscountSettingsForm = withFormik({
  mapPropsToValues,
  handleSubmit: (
    values: DiscountFormValues,
    { setSubmitting, props }: FormikBag<TPropsWithoutFormik, DiscountFormValues>
  ) => {
    const { discountCategories, discountProducts, ...rest } = values;
    const newOrdersConfig = {
      ...rest,
      discountRules: [...discountCategories, ...discountProducts],
    };
    const onSuccess = () => {
      const keysThatChanged = whatHasChanged(
        props.ordersConfig,
        newOrdersConfig,
        ['deliveryHours', 'discountRules']
      );
      logActivity(`Ustawienia - zmiana promocji:
        ${formatChangedKeys(
          keysThatChanged,
          props.ordersConfig,
          newOrdersConfig
        )}
      `);
      toast.success('Promocje zostały pomyślnie zapisane');
      setSubmitting(false);
    };
    const payload: OrdersConfig = newOrdersConfig;
    return props.updateOrdersConfig(payload, props.restaurant, onSuccess);
  },
  displayName: COMPONENT_NAME,
})(DiscountSettingsForm);

const mapStateToProps = (state) => ({
  isAdmin: selectIsRootAdmin(state),
  ordersConfig: selectOrdersConfig(state) as OrdersConfig,
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
});

const mapDispatchToProps = { updateOrdersConfig };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EnhancedDiscountSettingsForm);
