import React from 'react';

import { fixDecimal } from 'utils/general';
import { currency } from 'globals/currency';
import SimpleTableWithSorting from '../common/SimpleTableWithSorting';

const CurrencyFormatterCell = (props) => (
  <div>
    {fixDecimal(props.value)} {currency}
  </div>
);

interface IProps {
  data: {
    name: string;
    orders_count: number;
    orders_price: number;
    orders_avg: number;
    orders_commission_sum: number;
    restaurant_commission: number;
  }[]
}
const RestaurantOrderValuesTable: React.FC<IProps> = ({ data }) => {
  const tableData = React.useMemo(() => data, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nazwa restauracji',
        accessor: 'name',
      },
      {
        Header: 'Liczba zamówień',
        accessor: 'orders_count',
      },
      {
        Header: 'Obrót zamówień',
        accessor: 'orders_price',
        Cell: CurrencyFormatterCell,
      },
      {
        Header: 'Średnia wartość zamówienia',
        accessor: 'orders_avg',
        Cell: CurrencyFormatterCell,
      },
      {
        Header: 'Zapisana prowizja (API)',
        accessor: 'orders_commission_sum',
        Cell: CurrencyFormatterCell,
      },
      {
        Header: 'Szacowana prowizja (FE)',
        accessor: 'restaurant_commission',
        Cell: CurrencyFormatterCell,
      },
    ],
    []
  );

  return <SimpleTableWithSorting data={tableData} columns={columns} />;
};

export default RestaurantOrderValuesTable;
