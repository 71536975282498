import DeliveriesPage from '../app/pages/DeliveriesPage';
import DriverDeliveriesPage from '../app/pages/DriverDeliveriesPage';
import RestaurantDriversPage from '../app/pages/RestaurantDriversPage';
import { OTO_PRODUCTS, OTO_PRODUCTS_GROUPS, ROLES } from 'enums';
import type { INavItem } from './types';

const deliveriesPageWithoutRestrictions: INavItem = {
  // @ts-expect-error
  name: DeliveriesPage.navName,
  icon: 'Truck',
  // @ts-expect-error
  url: DeliveriesPage.url,
};

export const restaurantDeliveriesListNavItem: INavItem = {
  ...deliveriesPageWithoutRestrictions,
  availableWithProducts: OTO_PRODUCTS_GROUPS.anyDeliveriesProduct,
};

export const deliveriesCoordinatorDeliveriesListNavItem: INavItem =
  deliveriesPageWithoutRestrictions;

export const driverDeliveriesListNavItem: INavItem = {
  // @ts-expect-error
  name: DriverDeliveriesPage.navName,
  icon: 'Truck',
  // @ts-expect-error
  url: DriverDeliveriesPage.url,
};

export const restaurantDriversListNavItem: INavItem = {
  name: RestaurantDriversPage.navName,
  icon: 'User',
  url: RestaurantDriversPage.url,
  availableForRoles: [ROLES.ADMIN, ROLES.RESTAURANT_ADMIN],
  availableWithProducts: [OTO_PRODUCTS.restaurant_deliveries],
};
