import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

interface IProps {
  activeTab: string;
  changeTab: (tab: string) => void;
  tabs: Record<string, string>;
}

export const OtoTabs = (props: IProps) => {
  const { activeTab, changeTab, tabs } = props;
  return (
    <Nav tabs>
      {Object.values(tabs).map((tab) => (
        <NavItem className="flex-fill" key={tab}>
          <NavLink
            className={activeTab === tab ? 'active' : ''}
            onClick={() => changeTab(tab)}
          >
            {tab}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

OtoTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  tabs: PropTypes.object.isRequired,
};

export default OtoTabs;
