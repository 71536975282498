import React, { useState } from 'react';
import { Form, Field, withFormik, FormikBag } from 'formik';
import type { FormikErrors, FormikProps } from 'formik';
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardHeader,
  Row,
} from 'reactstrap';

import SubmitButtonWithLoader from '../common/SubmitButtonWithLoader';
import {
  IconWithTooltip,
  InputWithAddon,
  OtoButtons,
  OtoSpinner,
} from '../common';
import APIService from 'services/api';
import { toast } from 'react-toastify';

type GoogleMapsFindByNameResponse = {
  name: string;
  phone: string;
  location: string;
  rating: number;
  g_maps_id?: number;
  opening_hours: string[];
  cuisine_type: string;
  cuisine_label: string;
};

export type AddRestaurantFormValues = {
  name: string;
  phone: string;
  location: string;
  rating: number;
  g_maps_id?: number;
  opening_hours: string[];
  cuisine_type: string;
  cuisine_label: string;
  city_id: number;
  images: string[];
};

export interface AddRestaurantFormOwnProps {
  availableCities: { id: number | string; name: string }[];
  onAddRestaurant: (values: AddRestaurantFormValues) => Promise<{
    isSuccess: boolean;
    errors?: Record<string, string[]>;
  }>;
}

type AddRestaurantFormProps = AddRestaurantFormOwnProps &
  FormikProps<AddRestaurantFormValues>;

const AddRestaurantFormWithoutFormik: React.FC<AddRestaurantFormProps> = (
  props
) => {
  const {
    availableCities,
    errors,
    isSubmitting,
    handleChange,
    setValues,
    values,
  } = props;
  const [suggestionLoading, setSuggestionLoading] = useState(false);

  const renderError = (key: string) => {
    return errors[key] ? (
      <div className="text-danger">{errors[key]}</div>
    ) : null;
  };

  const fetchRestaurantDataFromGoogleMaps = async () => {
    if (!values.name || values.name.length < 3) {
      toast.error('Podaj nazwę restauracji');
      return;
    }
    try {
      setSuggestionLoading(true);
      const response: GoogleMapsFindByNameResponse = await APIService.get(
        `/restaurants/g-maps-data?name=${values.name}`
      );
      setValues({
        ...values,
        ...response,
      });
    } catch (error) {
      toast.error('Nie udało się pobrac danych restauracji z Google Maps');
    } finally {
      setSuggestionLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader>{'Dodaj restauracje'}</CardHeader>
      <CardBody>
        <Form>
          <Row className="mx-0 d-flex flex-wrap">
            <FormGroup className="col col-5 min-w-400">
              <Label>Nazwa</Label>
              <InputWithAddon
                name="name"
                wrapperClassName="d-flex align-items-stretch flex-nowrap"
                placeholder="np. Koku Sushi"
                onChange={handleChange}
                addonText={
                  <OtoButtons.ReloadButton
                    className="align-vertical flex-nowrap"
                    disabled={suggestionLoading}
                    onClick={fetchRestaurantDataFromGoogleMaps}
                    style={{
                      height: 25,
                    }}
                  >
                    {suggestionLoading && (
                      <OtoSpinner className="mr-2" size={'sm'} />
                    )}
                    Pobierz z Google
                  </OtoButtons.ReloadButton>
                }
              />
              {renderError('name')}
            </FormGroup>
            <FormGroup className="col min-w-400">
              <Label>Adres (bez kodu pocztowego i miasta)</Label>
              <Field as={Input} name="location" />
              {renderError('location')}
            </FormGroup>
            <FormGroup className="col min-w-250">
              <Label>Nr. telefonu</Label>
              <Field as={Input} name="phone" />
              {renderError('phone')}
            </FormGroup>
            <FormGroup className="col min-w-300">
              <Label>
                Typy kuchni
                <IconWithTooltip
                  id="add-restaurant-cuisine-label"
                  className="ml-2"
                  text="Wporadź, oddzielając przecinkiem"
                />
              </Label>
              <Field as={Input} name="cuisine_label" />
              {renderError('cuisine_label')}
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Nr. telefonu</Label>
              <Field as={Input} name="phone" />
              {renderError('phone')}
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>ID Google Maps (read only)</Label>
              <Field as={Input} disabled name="g_maps_id" />
              {renderError('g_maps_id')}
            </FormGroup>
            <FormGroup className="col min-w-250">
              <Label>Miasto</Label>
              <Field as={Input} type="select" name="city_id">
                <option value={0}>Nie wybrano</option>
                {availableCities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </Field>
              {renderError('city_id')}
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Godziny otwarcia</Label>
              <div>
                {values.opening_hours?.length > 0
                  ? values.opening_hours.map((hour, index) => (
                      <div key={index}>{hour}</div>
                    ))
                  : 'Brak danych - uzupełnij je po stworzeniu restauracji'}
              </div>
              {renderError('opening_hours')}
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Zdjęcia</Label>
              <div>
                {values.images?.length > 0
                  ? values.images.map((hour, index) => (
                      <div key={index}>{hour}</div>
                    ))
                  : 'Brak danych - uzupełnij je po stworzeniu restauracji'}
              </div>
              {renderError('images')}
            </FormGroup>
          </Row>
          <FormGroup className="col-12">
            <SubmitButtonWithLoader
              label="Dodaj restauracje"
              loading={isSubmitting}
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

const mapPropsToValues = (
  props: AddRestaurantFormOwnProps
): AddRestaurantFormValues => ({
  name: '',
  location: '',
  phone: '',
  rating: 0,
  g_maps_id: 0,
  opening_hours: [],
  cuisine_type: '',
  cuisine_label: '',
  city_id: 1,
  images: [],
});

const handleSubmit = async (
  values: AddRestaurantFormValues,
  {
    props,
    setErrors,
    setSubmitting,
    resetForm,
  }: FormikBag<AddRestaurantFormOwnProps, AddRestaurantFormValues>
) => {
  const response = await props.onAddRestaurant(values);
  setSubmitting(false);
  if (response.errors) {
    setErrors(response.errors as FormikErrors<AddRestaurantFormValues>);
  }
  if (response.isSuccess) {
    resetForm();
  }
};

const AddRestaurantForm = withFormik<
  AddRestaurantFormOwnProps,
  AddRestaurantFormValues
>({
  mapPropsToValues,
  handleSubmit,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
})(AddRestaurantFormWithoutFormik);

export default AddRestaurantForm;
