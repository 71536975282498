import React from 'react';
import i18next from 'i18next';

import type { IOrder } from 'types';
import { displayTime, newSafeDate } from 'utils/date-time';
import Order from 'utils/orders';

const OrderConfirmedAtTime = ({ order }: { order: IOrder }) => {
  if (Order.isDeclined(order)) {
    return null;
  }
  return (
    <div>
      <strong>{i18next.t('order-modal.confirmed-time.label')}: </strong>
      <span>
        {order.confirmed_at
          ? displayTime(newSafeDate(order.confirmed_at))
          : i18next.t('order-modal.confirmed-time.value-empty')}
      </span>
    </div>
  );
};

export default OrderConfirmedAtTime;
