import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { IActiveRestaurantStatsItem } from './AdminSalesStatistics';

const cellStyle = {
  border: '1px solid black',
  borderBottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const emptyCellStyle = {
  ...cellStyle,
  background: '#de6262',
};

const restaurantNameCellStyle = {
  ...cellStyle,
  width: 100,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const getCellStyle = (val: number) => {
  return !val ? emptyCellStyle : cellStyle;
};

const AdminActiveRestaurantsStats = (props: { data: IActiveRestaurantStatsItem[] }) => {

  const activeRestaurantsStats = props.data;

  const getDateKey = (item: IActiveRestaurantStatsItem) =>
    `${item.month}/${item.year.slice(2)}`;

  const getKey = (item: IActiveRestaurantStatsItem) =>
    `${getDateKey(item)}-${item.restaurant_name}`;

  const valuesDict: object = activeRestaurantsStats.reduce((dict, item) => {
    dict[getKey(item)] = item.orders_amount;
    return dict;
  }, {});

  const uniqueMonths: Array<string> = [
    ...new Set(activeRestaurantsStats.map(getDateKey)),
  ];

  const uniqueRestaurants: Array<string> = [
    ...new Set(activeRestaurantsStats.map((item) => item.restaurant_name)),
  ];
  return <>
    <Row>
      <Col>Restaurant Name</Col>
      {uniqueMonths.map((um) => (
        <Col key={um} className="p-0 text-center">
          {um}
        </Col>
      ))}
    </Row>
    {uniqueRestaurants.map((restaurantName) => (
      <Row key={restaurantName}>
        <Col style={restaurantNameCellStyle}>{restaurantName}</Col>
        {uniqueMonths.map((um) => {
          const val = valuesDict[`${um}-${restaurantName}`] || 0;
          return (
            <Col
              key={um}
              className="p-0 text-center"
              style={getCellStyle(val)}
            >
              {val}
            </Col>
          );
        })}
      </Row>
    ))}
  </>;
};

AdminActiveRestaurantsStats.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AdminActiveRestaurantsStats;