import React from 'react';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { getPaymentMethodLabel } from 'utils/orders';
import { PaymentOptionIcons } from '../../icons/IconSets';
import { IDelivery } from 'types/delivery';

const DeliveryPaymentType: React.FC<{ delivery: IDelivery }> = ({
  delivery,
}) => {
  // TODO probably should use payment_type
  const payment = i18next.t(
    `payment-types.${getPaymentMethodLabel(delivery.payment_status)}`
  );
  return (
    <div>
      {i18next.t('delivery.payment-type')}:{' '}
      {PaymentOptionIcons[delivery.payment_status]} {payment}
    </div>
  );
};

DeliveryPaymentType.propTypes = {
  delivery: Shapes.deliveryShape.isRequired,
};

export default DeliveryPaymentType;
