import i18next from 'i18next';
import React from 'react';
import { Label, Row } from 'reactstrap';

import { ISavedFile } from 'types/attachment';
import { ModalTypes } from 'config';
import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import CollapsibleCard from '../common/CollapsibleCard';
import OtoButtons from '../common/OtoButtons';

interface IReservationAttachmentsProps {
  attachments: ISavedFile[] | null;
  canEdit?: boolean;
  onAdd: (file: ISavedFile) => void;
  onDelete: (inde: number) => void;
  renderFileUpload: (props: {
    onSuccess: (file: ISavedFile) => void;
  }) => React.ReactNode;
  setModal: TSetModalFunction;
}

const ReservationAttachments: React.FC<IReservationAttachmentsProps> = (
  props
) => {
  const { attachments, canEdit = true, onAdd, onDelete, renderFileUpload, setModal } = props;
  const nonDeletedAttachments =
    attachments?.filter((attachment) => attachment.action !== 'delete') || [];

  const handleFileAdd = (savedFile: ISavedFile) => {
    setModal(
      {
        title: 'Podaj nazwę pliku',
        text: 'Możesz zmienić nazwę pliku przed zapisaniem, jeżeli jest ona nieczytelna. Jeżeli nie chcesz zmieniać nazwy - kliknij Zatwierdź nie zmieniając jej.',
        confirm: (newTitle: string) => {
          const originalTitleExtension = getExtension(savedFile.title);
          const newTitleExtension = getExtension(newTitle);

          const finalFileNameWithExtension = !!newTitleExtension
            ? newTitle
            : `${newTitle}.${originalTitleExtension}`;

          onAdd({
            ...savedFile,
            title: finalFileNameWithExtension,
          });
        },
        confirmText: i18next.t('Confirm'),
        confirmColor: 'success',
        initialValue: savedFile.title,
      },
      ModalTypes.INPUT
    );
  };
  return (
    <>
      {nonDeletedAttachments.length > 0 && (
        <Label className="mt-3">Załączniki:</Label>
      )}
      {nonDeletedAttachments.map((attachment, index) => (
        <Row
          key={attachment.path}
          className="d-flex mx-0 mw-350 justify-content-between"
        >
          <a href={attachment.asset} target="_blank" rel="noopener noreferrer">
            {attachment.title || attachment.name}
          </a>
          {canEdit && (
            <OtoButtons.DeleteButton onClick={() => onDelete(index)} />
          )}
        </Row>
      ))}
      {canEdit && (
        <Row className="mx-0 align-items-end">
          <CollapsibleCard
            className="min-w-300"
            buttonClassName={'p1'}
            title={'Dodaj załącznik'}
          >
            {renderFileUpload({ onSuccess: handleFileAdd })}
          </CollapsibleCard>
        </Row>
      )}
    </>
  );
};

function getExtension(fileName: string): string | null {
  if (fileName.includes('.jpg')) {
    return 'jpg';
  }
  if (fileName.includes('.jpeg')) {
    return 'jpeg';
  }
  if (fileName.includes('.png')) {
    return 'png';
  }
  if (fileName.includes('.')) {
    const parts = fileName.split('.');
    return parts[parts.length - 1];
  }
  return null;
}

export default withSetModal(ReservationAttachments);
