import React from 'react';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { DeliveryOptionIcons } from '../../icons/IconSets';
import Order from 'utils/orders';
import type { IOrder } from 'types';

type OrderDeliveryProps = {
  order: IOrder;
};

export const OrderDelivery: React.FC<OrderDeliveryProps> = ({ order }) => {
  const icon = DeliveryOptionIcons[order.delivery_type];
  return (
    <div
      className={
        Order.isContactless(order) || Order.hasRobotDelivery(order)
          ? 'text-danger'
          : ''
      }
    >
      <div>
        {icon} {i18next.t(`delivery-type.${order.delivery_type}`)}
        {Order.isSelfCollect(order) ? null : `, ${Order.getAddress(order)}`}
      </div>
    </div>
  );
};

OrderDelivery.propTypes = {
  order: Shapes.orderShape.isRequired,
};

OrderDelivery.displayName = 'OrderDelivery';

export default OrderDelivery;
