import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Button, Row } from 'reactstrap';
import i18next from 'i18next';

import Shapes from 'shapes/main';
import { NormalizedRestaurant } from 'types/restaurants';
import { IPlace } from 'types/restaurant-place';
import { TableDTO } from 'types/restaurant-table';
import CollapsibleCard from '../common/CollapsibleCard';
import { IconWithTooltip, OtoToggle } from '../common';

interface AddTableComponentProps {
  onAddTable: (tableData: TableDTO) => void;
  places: IPlace[];
  restaurant: NormalizedRestaurant;
}

export const AddTableComponent: React.FC<AddTableComponentProps> = ({
  onAddTable,
  places,
  restaurant,
}) => {
  const [values, setValues] = useState({
    name: '',
    max_people: 4,
    min_people: 2,
    linked_to: [],
    place_id: -1,
    priority: 1,
    restaurant_id: restaurant.id,
    is_bookable: true,
  });
  const updateField = (name, val) => setValues({ ...values, [name]: val });
  const handleChange = (e) => updateField(e.target.name, e.target.value);
  return (
    <CollapsibleCard title={i18next.t('table-settings.add-table.header')}>
      <Row className="mx-0">
        <FormGroup className="col col-md-4">
          <Label>{i18next.t('table-settings.add-table.name')}</Label>
          <Input
            name="name"
            placeholder="np. 1, S1 lub A"
            value={values.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className="col-xs-12 col-md-auto">
          <Label>{i18next.t('table-settings.add-table.min-people')}</Label>
          <Input
            className="mw-150"
            name="min_people"
            value={values.min_people}
            onChange={handleChange}
            min="1"
            step="1"
            type="number"
          />
        </FormGroup>
        <FormGroup className="col-xs-12 col-md-auto">
          <Label>{i18next.t('table-settings.add-table.max-people')}</Label>
          <Input
            className="mw-150"
            name="max_people"
            value={values.max_people}
            onChange={handleChange}
            min="1"
            step="1"
            type="number"
          />
        </FormGroup>
        <FormGroup className="col-xs-12 col-md-auto">
          <Label>
            <span>{i18next.t('table-settings.add-table.priority')}</span>
            <IconWithTooltip
              id="add-table-priority-tooltip"
              className="ml-2"
              text={i18next.t('table-settings.add-table.priority-tooltip')}
            />
          </Label>
          <Input
            className="mw-150"
            name="priority"
            value={values.priority}
            onChange={handleChange}
            min="1"
            step="1"
            type="number"
          />
        </FormGroup>
      </Row>
      <Row className="mx-0">
        <FormGroup className="col col-md-4">
          <Label>
            {i18next.t('table-settings.column-header.available-connections')}
          </Label>
          <Input
            disabled
            name="linked_to"
            value={values.linked_to}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className="col col-md-4">
          <Label>{i18next.t('table-settings.column-header.place')}</Label>
          <Input
            type="select"
            name="place_id"
            value={values.place_id}
            onChange={handleChange}
          >
            <option
              value="-1"
              label={i18next.t(
                'table-settings.add-table.option-place-not-chosen'
              )}
            ></option>
            {places.map((place) => (
              <option key={place.id} value={place.id}>
                {place.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup className="col col-md-4">
          <Label>
            {i18next.t('table-settings.column-header.is-bookable-online')}
          </Label>
          <div>
            <OtoToggle
              checked={!!values.is_bookable}
              name="is_bookable"
              onChange={() => updateField('is_bookable', !values.is_bookable)}
            />
          </div>
        </FormGroup>
      </Row>
      <FormGroup className="col-12">
        <Button type="button" onClick={() => onAddTable(values as TableDTO)}>
          {i18next.t('Add')}
        </Button>
      </FormGroup>
    </CollapsibleCard>
  );
};

AddTableComponent.propTypes = {
  onAddTable: PropTypes.func.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
  places: PropTypes.arrayOf(Shapes.restaurantPlaceShape.isRequired).isRequired,
};

export default AddTableComponent;
