import React, { useMemo } from 'react';
import {
  OtoButtons,
  ReactTableCellProps,
  SimpleTableWithSorting,
  generateColumn,
  getRTOriginal,
} from 'app/components/common';
import type { IDelivery } from 'types';
import { toDateTimeInputValue } from 'utils/date-time';

export type DeliveriesTableProps = {
  deliveries: IDelivery[];
  isLoading?: (delivery: IDelivery) => boolean;
  onUnassignDriver?: (delivery: IDelivery) => void;
};
const DeliveriesTable: React.FC<DeliveriesTableProps> = ({
  deliveries,
  isLoading,
  onUnassignDriver,
}) => {
  const columns = useMemo(
    () => [
      generateColumn('created_at', {
        customHeader: 'Czas utworzenia',
        customCellFormatter: (props: ReactTableCellProps<IDelivery>) => {
          const delivery = getRTOriginal<IDelivery>(props);
          return toDateTimeInputValue(new Date(delivery.created_at));
        },
      }),
      generateColumn('address', {
        customHeader: 'Adres',
      }),
      generateColumn('restaurant_id', {
        customHeader: 'Restaurant ID',
      }),
      generateColumn('assigned_to', {
        customHeader: 'Kierowca',
      }),
      generateColumn('driver_type', {
        customHeader: 'Driver Type',
        filterType: 'select',
      }),
      generateColumn('status', {
        customHeader: 'Status',
        filterType: 'select',
      }),
      generateColumn('id', {
        customHeader: 'ID',
        customCellFormatter: (props: ReactTableCellProps<IDelivery>) => {
          const delivery = getRTOriginal<IDelivery>(props);
          return (
            <>
              <div>{delivery.id}</div>
              {onUnassignDriver && delivery.assigned_to && (
                <OtoButtons.ReloadButton
                  outline
                  color={'secondary'}
                  loading={isLoading?.(delivery)}
                  onClick={() => onUnassignDriver(delivery)}
                >
                  Odepnij od kierowcy
                </OtoButtons.ReloadButton>
              )}
            </>
          );
        },
      }),
    ],
    [isLoading, onUnassignDriver]
  );
  return (
    <>
      <SimpleTableWithSorting columns={columns} data={deliveries} hasFilters />
    </>
  );
};

export default DeliveriesTable;
