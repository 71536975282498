import React from 'react';
import CollapsibleCard from '../../common/CollapsibleCard';

import './ReservationsCalendarCustomEvents.scss';

/* eslint-disable jsx-a11y/anchor-is-valid */
export const ReservationsLegend = () => {
  return (
    <CollapsibleCard
      buttonClassName={'p-1'}
      className={'no-print'}
      title="Legenda"
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column nowrap',
          gap: 10,
        }}
      >
        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-dot-event fc-event-count-status"
        >
          <div className="fc-event-main-inner">
            <div className="fc-event-title">
              Nota, np. uwaga o liczbie zarezerwowanych miejsc w danym dniu
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-future fc-daygrid-event fc-daygrid-dot-event"
        >
          <div className="fc-event-main-inner align-vertical">
            <div className="fc-event-title">
              Stoliki: S1, S2 (Sala Główna) | Zwykła rezerwacja
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event"
        >
          <div className="fc-event-main-inner align-vertical">
            <span className="event-label event-label--notes-from-customer">!</span>
            <div className="fc-event-title">
              Stoliki: S1, S2 (Sala Główna) | Rezerwacja z uwagą od gości
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event"
        >
          <div className="fc-event-main-inner align-vertical">
            <span className="event-label event-label--notes-from-restaurant">!</span>
            <div className="fc-event-title">
              Stoliki: S1, S2 (Sala Główna) | Rezerwacja z uwagą od restauracji
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-future fc-daygrid-event fc-daygrid-dot-event"
        >
          <div className="fc-event-main-inner align-vertical">
            <span className="event-label event-label--automatic"></span>
            <div className="fc-event-title">
              Stolik: S3 | Rezerwacja automatyczna
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event"
        >
          <div className="fc-event-main-inner align-vertical">
            <span className="event-label event-label--file">Plik</span>
            <div className="fc-event-title">
              Stoliki: S11, S15, S13, S8 (Sala główna) | Rezerwacja z
              załącznikiem
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event fc-event-success"
        >
          <div className="fc-event-main-inner align-vertical">
            <div className="fc-event-title">
              Stolik: S8 | Rezerwacja oznaczona "Na Sali"
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-start fc-event-end fc-event-past fc-daygrid-event fc-daygrid-dot-event fc-event-declined"
        >
          <div className="fc-event-main-inner align-vertical">
            <div className="fc-event-title">
              Stolik: S3 | Anulowana rezerwacja
            </div>
          </div>
        </a>

        <a
          href="#"
          className="fc-event fc-event-draggable fc-event-resizable fc-event-start fc-event-end fc-event-today fc-daygrid-event fc-daygrid-block-event fc-h-event"
        >
          <div className="fc-event-main">
            <div className="fc-event-main-inner align-vertical">
              <div className="fc-event-title">
                Rezerwacja na wyłączność (cała sala, cały dzień)
              </div>
            </div>
          </div>
        </a>
      </div>
    </CollapsibleCard>
  );
};
/* eslint-enable jsx-a11y/anchor-is-valid */

export default ReservationsLegend;
