import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import ContactCard from 'app/components/contact/ContactCard';
import TutorialVideos from 'app/components/contact/TutorialVideos';
import UrlFetcher from '../containers/UrlFetcher';
import ReportProblemForm from 'app/components/contact/ReportProblemForm';
import FixErrorSteps from 'app/components/contact/FixErrorSteps';
import { checkToken, checkAppVersion } from 'services/auth';
import { logout } from 'store/app';
import { ReservationConfig } from 'types/reservation-config';
import { col10Style } from '../styles';
import type { NormalizedRestaurant } from 'types';

import 'app/components/settings-form.scss';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { selectRestaurant } from 'store/restaurant';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectActiveRestaurantTenantConfig } from 'store/tenants';

type TProps = {
  config: ReservationConfig;
  restaurant: NormalizedRestaurant;
};

const ContactPage: React.FC<TProps> = (props) => {
  useEffect(() => {
    checkAppVersion();
    checkToken();
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant;
  const appropriateTenantConfig = useAppSelector(
    selectActiveRestaurantTenantConfig
  );
  const bookingConfig = restaurant.config;

  const dispatch = useAppDispatch();

  return (
    <Row>
      <Col md={col10Style}>
        <ReportProblemForm restaurant={restaurant} />
        <Card>
          <CardHeader>Coś nie działa jak należy?</CardHeader>
          <CardBody>
            <FixErrorSteps
              onLogout={() =>
                dispatch(
                  logout({
                    location,
                    navigate,
                  })
                )
              }
            />
          </CardBody>
        </Card>
        {appropriateTenantConfig.support ? (
          <ContactCard contactData={appropriateTenantConfig.support} />
        ) : (
          <UrlFetcher<{
            email: string;
            phone: string;
          }>
            url="/support/contact-data"
            onError="show-component"
          >
            {([contactData, urlFetcherProps]) => (
              <ContactCard contactData={contactData} />
            )}
          </UrlFetcher>
        )}

        <TutorialVideos reservationConfig={bookingConfig} />
      </Col>
    </Row>
  );
};

export default ContactPage;
