import React from 'react';
import memoizeOne from 'memoize-one';

import UrlFetcher from 'app/containers/UrlFetcher';
import withSetModal, { TSetModalFunction } from 'app/containers/WithSetModal';
import IntegrationTile from 'app/components/integrations/IntegrationTile';
import { useAppSelector } from 'hooks/useRedux';
import { selectIsRootAdmin } from 'store/app';
import { EIntegrationType, IIntegration } from 'types';

type AdminIntegrationsPageProps = {
  setModal: TSetModalFunction;
};

type IntegrationWithRestaurant = IIntegration & {
  restaurant?: {
    id: number;
    name: string;
  };
};

const filterIntegrations = (
  integrations: IntegrationWithRestaurant[],
  isRootAdmin: boolean
): IntegrationWithRestaurant[] => {
  if (isRootAdmin) {
    return integrations;
  }
  return integrations.filter(
    (integration) => integration.type === EIntegrationType.SZAMAEXPRESS
  );
};

const memoizedFilterIntegrations = memoizeOne(filterIntegrations);

const AdminIntegrationsPage: React.FC<AdminIntegrationsPageProps> = ({
  setModal,
}) => {
  const isRootAdmin = useAppSelector(selectIsRootAdmin);

  const url = '/admin/integrations';

  const getIntegrationValue = (
    integration: IIntegration
  ): string | undefined => {
    if (integration.type === EIntegrationType.SZAMAEXPRESS) {
      return integration.data.api_key as string;
    }
    if (integration.type === EIntegrationType.FOODELI) {
      return integration.data.email as string;
    }
    if (integration.type === EIntegrationType.FACEBOOK_PIXEL) {
      return integration.data.value as string;
    }
    if (integration.type === EIntegrationType.IFRAME) {
      return (integration.data?.css as Record<string, unknown>)
        ?.primaryColor as string;
    }
    return undefined;
  };

  return (
    <>
      <div className="container align-vertical mb-3"></div>
      <UrlFetcher<IntegrationWithRestaurant[]> url={url}>
        {([integrations, urlFetcherProps]) => {
          const filteredIntegrations = memoizedFilterIntegrations(
            integrations,
            isRootAdmin
          );
          return (
            <div className="d-flex flex-row flex-wrap">
              {filteredIntegrations.map((integration) => (
                <IntegrationTile
                  key={integration.id}
                  className="mr-2 min-w-300"
                  value={getIntegrationValue(integration)}
                  type={integration.type}
                >
                  {integration.restaurant ? (
                    <div className="mb-2">{`${integration.restaurant.name} (${integration.restaurant.id})`}</div>
                  ) : (
                    <div className="mb-2">
                      Brak restauracji / restauracja usunięta
                    </div>
                  )}
                </IntegrationTile>
              ))}
            </div>
          );
        }}
      </UrlFetcher>
    </>
  );
};

const EnhancedAdminIntegrationsPage = withSetModal(AdminIntegrationsPage);

EnhancedAdminIntegrationsPage.navName = 'admin.integrations';
EnhancedAdminIntegrationsPage.url = '/admin/integrations';

export default EnhancedAdminIntegrationsPage;
