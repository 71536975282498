import React, { useCallback, useState } from 'react';
import i18next from 'i18next';
import { Button, Card, Col, Row } from 'reactstrap';

import { currency } from 'globals/currency';
import { fixDecimal, formatThousands } from 'utils/general';
import { formatDate, toDateInputValue } from 'utils/date-time';
import CollapsibleCard from 'app/components/common/CollapsibleCard';
import WithDateRangeDataFetched from '../../../containers/WithDateRangeDataFetched';
import PieChartWithAmount from 'app/components/statistics/PieChartWithAmount';
import OtoToggle from 'app/components/common/OtoToggle';
import StatisticsRangeHeader from 'app/components/admin-statistics/StatisticsRangeHeader';
import { Tenants } from 'store/tenants';

const appNameDict = {
  web_app: 'Strona internetowa',
  lubje_mobile_app: 'Aplikacja mobilna',
};

const platformDict = {
  mobile_app: 'Aplikacja mobilna',
  web_desktop: 'Strona (PC / duży ekran)',
  web_mobile: 'Strona (smartfon / mały ekran)',
};

interface DayStats {
  date: string;
  orders_amount: number;
  orders_total: number;
}

interface ILubjeStats {
  general?: DayStats[];
  partners?: DayStats[];
  nonPartners?: DayStats[];
  statsByPament: {
    payment_type: string;
    orders_amount: number;
    orders_total: number;
  }[];
  statsByAppName: {
    app_name: string;
    orders_amount: number;
    orders_total: number;
  }[];
  statsByPlatform: {
    platform: string;
    orders_amount: number;
    orders_total: number;
  }[];
}

const emptyArr = [];

interface IProps {
  tenant: Tenants;
}

const OrdersStatisticsPage: React.FC<IProps> = ({ tenant }) => {
  const [ranges, setRanges] = useState<any[]>([1]);
  const [data, setData] = useState<ILubjeStats[]>([]);
  const [showCharts, setShowCharts] = useState(false);

  const setDataItem = (item, index) =>
    setData([...data.slice(0, index), item, ...data.slice(index + 1)]);
  const addRange = useCallback(() => {
    setRanges([...ranges, ranges.length + 1]);
  }, [ranges, setRanges]);

  const formatUrl = (start: Date, end: Date) =>
    `/statistics/orders?from=${toDateInputValue(start)}&to=${toDateInputValue(
      end
    )}&tenant=${tenant}`;

  return (
    <>
      {ranges.map((item, index) => (
        <Card key={item} className="p-2 mb-4">
          <WithDateRangeDataFetched<ILubjeStats>
            hideCalendarAfterFetch
            fetchOnMount={false}
            defaultRange="lastWeek"
            getUrl={formatUrl}
            onDataFetch={(data) => setDataItem(data, index)}
          >
            {(stats: ILubjeStats, { editRanges, ranges }) => (
              <CollapsibleCard
                className="mb-0"
                tag="div"
                title={
                  <StatisticsRangeHeader
                    isEmpty={!stats}
                    onEdit={editRanges}
                    ranges={ranges}
                  >
                    {stats && (
                      <>
                        <span className="ml-2">
                          {' | '}Liczba zamówień:{' '}
                          {stats.general?.reduce(
                            (acc, item) => acc + item.orders_amount,
                            0
                          ) || 0}
                        </span>
                        <span className="ml-2">
                          {' | '}Obrót:{' '}
                          {formatThousands(
                            fixDecimal(
                              stats.general?.reduce(
                                (acc, item) => acc + item.orders_total,
                                0
                              ) || 0
                            )
                          )}{' '}
                          {currency}
                        </span>
                      </>
                    )}
                  </StatisticsRangeHeader>
                }
              >
                {!stats ? (
                  <Row className="mx-0">Brak danych za ten okres</Row>
                ) : (
                  <>
                    <Row>
                      <LubjeDateCol
                        dayStatsForRange={stats.general || emptyArr}
                      />
                      <LubjeStatsCol
                        dayStatsForRange={stats.general || emptyArr}
                        prevRangeDayStats={
                          index === 0
                            ? null
                            : (data[index - 1] as ILubjeStats).general
                        }
                        title="Z restauracji łącznie"
                      />
                    </Row>
                    {showCharts && (
                      <Row className="bt-1 mt-3 pt-3">
                        <Col xs="12" md="4">
                          <PieChartWithAmount
                            data={stats.statsByPament}
                            dataField="orders_amount"
                            labelField="payment_type"
                            textFormatter={(item, dataField, labelField) =>
                              `${i18next.t(
                                `payment-types.${item[labelField]}`
                              )}: ${item[dataField]}`
                            }
                            showText
                            showSelect={false}
                            title="Jak nasi użytkownicy płacą?"
                          />
                        </Col>
                        <Col xs="12" md="4">
                          <PieChartWithAmount
                            data={stats.statsByAppName}
                            dataField="orders_amount"
                            labelField="app_name"
                            showText
                            showSelect={false}
                            textFormatter={(item, dataField) =>
                              `${
                                appNameDict[item.app_name] ||
                                `Inne (${item.app_name})`
                              }: ${item[dataField]}`
                            }
                            title="Skąd przychodzą?"
                          />
                        </Col>
                        <Col xs="12" md="4">
                          <PieChartWithAmount
                            data={stats.statsByPlatform}
                            dataField="orders_amount"
                            labelField="platform"
                            showText
                            showSelect={false}
                            textFormatter={(item, dataField) =>
                              `${
                                platformDict[item.platform] ||
                                `Inne (${item.platform})`
                              }: ${item[dataField]}`
                            }
                            title="Z jakich platform korzystają?"
                          />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </CollapsibleCard>
            )}
          </WithDateRangeDataFetched>
        </Card>
      ))}
      <div className="d-flex align-vertical bt-1 pt-3 mt-3">
        <Button type="button" color="primary" onClick={addRange}>
          Dodaj nowy okres
        </Button>
        <OtoToggle
          checked={showCharts}
          id="showCharts"
          name="showCharts"
          value="true"
          label="Pokaż wykresy (statystyki typów płatności i źródeł zamówień)"
          labelWrapperClassName="d-inline-flex align-vertical ml-2 mb-0"
          onChange={(e) => setShowCharts(e.target.checked)}
        />
      </div>
    </>
  );
};

const styles = {
  subtitle: {
    borderBottom: '1px solid black',
    minHeight: 30,
  },
  row: {
    minHeight: 35,
  },
};

function LubjeDateCol(props: { dayStatsForRange: DayStats[] }) {
  const { dayStatsForRange } = props;
  return (
    <Col>
      <div className="font-weight-bold text-center">Data</div>
      <div style={styles.subtitle}></div>
      {dayStatsForRange.map((dayStatsItem: DayStats) => (
        <Row
          key={dayStatsItem.date}
          className="w-100 text-center"
          style={styles.row}
        >
          {dayStatsItem.date} {formatDate(new Date(dayStatsItem.date), 'EEEE')}
        </Row>
      ))}
      <Row className="w-100 text-centerbt-1" style={styles.row}>
        Razem
      </Row>
    </Col>
  );
}

function LubjeStatsCol(props: {
  dayStatsForRange: DayStats[];
  dayStatsForSummaryPercentage?: DayStats[];
  prevRangeDayStats?: DayStats[] | null;
  title: string;
}) {
  const {
    dayStatsForRange,
    dayStatsForSummaryPercentage,
    prevRangeDayStats,
    title,
  } = props;
  const addAmount = (acc, item) => acc + item.orders_amount;
  const addTotal = (acc, item) => acc + item.orders_total;
  const amount = dayStatsForRange.reduce(addAmount, 0);
  const amountPercentage =
    !!dayStatsForSummaryPercentage &&
    amount / dayStatsForSummaryPercentage.reduce(addAmount, 0);
  const sum = dayStatsForRange.reduce(addTotal, 0);
  const sumPercentage =
    !!dayStatsForSummaryPercentage &&
    sum / dayStatsForSummaryPercentage.reduce(addTotal, 0);
  return (
    <Col>
      <div className="font-weight-bold text-center">{title}</div>
      <Col>
        <Row style={styles.subtitle}>Liczba zamówień | Obrót</Row>
        {dayStatsForRange.map((dayStats: DayStats, index) => {
          const prev = !!prevRangeDayStats
            ? prevRangeDayStats[index]?.orders_amount || 0
            : null;
          return (
            <Row key={dayStats.date} style={styles.row}>
              <Col xs="auto">
                <ComparedNums current={dayStats.orders_amount} prev={prev} />
              </Col>
              <Col>
                {fixDecimal(dayStats.orders_total)} {currency}
              </Col>
            </Row>
          );
        })}
        <OrdersSummaryRow
          ordersAmount={amount}
          ordersAmountPercent={amountPercentage || null}
          ordersTotal={sum}
          ordersTotalPercent={sumPercentage || null}
        />
      </Col>
    </Col>
  );
}

function ComparedNums(props: { current: number; prev?: number | null }) {
  const { current, prev } = props;
  if (!prev) {
    return <span>{current}</span>;
  }
  const isNewBigger = current > prev;
  return (
    <>
      <span className="mr-1">{current}</span>
      <span className={isNewBigger ? 'text-success' : 'text-danger'}>
        {isNewBigger ? '+' : ''}
        {fixDecimal(current - prev)}
      </span>
    </>
  );
}

function OrdersSummaryRow(props) {
  const { ordersAmount, ordersAmountPercent, ordersTotal, ordersTotalPercent } =
    props;
  return (
    <Row className="bt-1" style={styles.row}>
      <Col xs="auto">
        {ordersAmount}{' '}
        {!!ordersAmountPercent && `(${fixDecimal(ordersAmountPercent * 100)})`}
      </Col>
      <Col>
        {formatThousands(ordersTotal)} {currency}{' '}
        {!!ordersTotalPercent && `(${fixDecimal(ordersTotalPercent * 100)})%`}
      </Col>
    </Row>
  );
}

export default OrdersStatisticsPage;
