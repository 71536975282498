import filterXss from 'xss';
import { Grid, List } from 'react-feather';
import values from 'object.values';

import Env from './config-env';
import { ModalTypes, ORDER_STATUS } from 'enums';

if (!Object.values) {
  values.shim();
}

export { ModalTypes, ORDER_STATUS };

export const isProd = Env.NODE_ENV === 'production';
export const isDev = Env.NODE_ENV === 'development';
export const isTestEnv = Env.NODE_ENV === 'test';

export const API_URL = getApiUrl();

function getApiUrl() {
  const host = filterXss(window.location.hostname);
  if (host === 'stage.admin.otostolik.pl') {
    return 'https://stage.otostolik.pl/api';
  }
  if (host === 'admin.otostolik.pl') {
    return 'https://api.otostolik.pl/api';
  }
  return Env.REACT_APP_API_URL || 'http://otostolik.local/api';
}

export const isStageHost = () => {
  const host = filterXss(window.location.hostname);
  return host.includes('stage');
};

export const isLocalHost = () => {
  const host = filterXss(window.location.hostname);
  return host.includes('local');
};

export const CHECK_VERSION_FREQUENCY = 60 * 60 * 1000;

export const REFRESH_FREQUENCY: number =
  (Env.REACT_APP_REFRESH_FREQUENCY &&
    parseInt(Env.REACT_APP_REFRESH_FREQUENCY, 10)) ||
  60 * 1000;

const REFRESH_SECONDARY_FREQUENCY: number =
  (Env.REACT_APP_REFRESH_SECONDARY_FREQUENCY &&
    parseInt(Env.REACT_APP_REFRESH_SECONDARY_FREQUENCY, 10)) ||
  60 * 1000 * 5;

export const DING_FREQUENCY = 10 * 1000;

export const FREQUENCY = {
  CHECK_VERSION: CHECK_VERSION_FREQUENCY,
  DING: DING_FREQUENCY,
  REFRESH: REFRESH_FREQUENCY,
  REFRESH_SECONDARY: REFRESH_SECONDARY_FREQUENCY,
};

export const _1hour = 1000 * 60 * 60;
export const APP_REFRESH_TIMEOUT = _1hour * 16;

export const DELIVERIES_URL = '/deliveries';
export const ORDERS_URL = '/orders';
export const RESERVATIONS_URL = '/calendar';
export const DEFAULT_URL = ORDERS_URL;

export const personalFields = [
  { label: 'Imię', name: 'customer_firstname' },
  { label: 'Nazwisko', name: 'customer_lastname' },
  { label: 'Telefon', name: 'phone', type: 'tel', addon: '+48' },
  { label: 'Email', name: 'email', type: 'email' },
];

export const DISCOUNT_TYPES = {
  SAME_PRODUCT: 'same-product',
  SAME_CATEGORY: 'same-category',
};

export const REASONS_TO_CLOSE = [
  { label: 'reasons-to-close.too-many-orders', value: 'too-many-orders' },
  { label: 'reasons-to-close.closed', value: 'closed' },
  { label: 'reasons-to-close.technical', value: 'technical' },
];

export const LANGS = {
  default: 'pl',
  supported: ['pl', 'en'],
  icons: {
    pl: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg',
    en: 'https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg',
  },
};

export const VIEWS = {
  GRID: {
    text: 'grid',
    icon: Grid,
  },
  LIST: {
    text: 'list',
    icon: List,
  },
};

export const G_MAPS_API_KEY = 'AIzaSyAutjvQ2MHHEMs7Eg0jf6qYBY_hDILgXng';

export const cities = [
  { id: 1, name: 'Lublin' },
  { id: 2, name: 'Rzeszów' },
  { id: 3, name: 'Warszawa' },
  { id: 4, name: 'Wrocław' },
  { id: 5, name: 'Gdansk' },
  { id: 6, name: 'Olsztyn' },
  { id: 7, name: 'Białystok' },
  { id: 8, name: 'Puławy' },
  { id: 9, name: 'Radom' },
  { id: 10, name: 'Łódź' },
  { id: 11, name: 'Kielce' },
  { id: 12, name: 'Gdynia' },
  { id: 13, name: 'Kraków' },
  { id: 14, name: 'Opole' },
  { id: 15, name: 'Świdnik' },
  { id: 16, name: 'Leszno' },
  { id: 17, name: 'Katowice' },
  { id: 18, name: 'Lubin' },
  { id: 19, name: 'Głogów' },
  { id: 20, name: 'Polkowice' },
  { id: 21, name: 'Ostrów Wielkopolski' },
];

type TCity = (typeof cities)[number];
export const citiesById: Record<number, TCity> = cities.reduce(
  (acc: Record<number, TCity>, city) => {
    acc[city.id] = city;
    return acc;
  },
  {}
);

export interface IReservationDurationOption {
  label: string;
  value: number;
}

export const possibleReservationDurations: IReservationDurationOption[] = [
  { label: '1h', value: 60 },
  { label: '1.5h', value: 90 },
  { label: '2h', value: 120 },
  { label: '2.5h', value: 150 },
  { label: '3h', value: 180 },
  { label: '4h', value: 240 },
  { label: '5h', value: 300 },
  { label: 'Do końca dnia', value: 720 },
];
