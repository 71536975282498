import PropTypes, { Requireable } from 'prop-types';
import { UsageType } from 'types';
import type { IPromoCode } from 'types';
import { PromoCodeIssuers } from 'enums';

const promoCodeShape = PropTypes.exact({
  activated_at: PropTypes.string.isRequired,
  available_days: PropTypes.arrayOf(PropTypes.string.isRequired),
  available_sources: PropTypes.arrayOf(PropTypes.string.isRequired),
  code: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  customer_id: PropTypes.number,
  deactivated_at: PropTypes.string.isRequired,
  deleted_at: PropTypes.string,
  description: PropTypes.string,
  discount: PropTypes.number,
  discount_type: PropTypes.oneOf(['value', 'percent', 'freeDelivery'])
    .isRequired,
  id: PropTypes.number.isRequired,
  issuer: PropTypes.oneOf(Object.values(PromoCodeIssuers)).isRequired,
  min_cart_amount: PropTypes.number,
  orders_count: PropTypes.number, // todo remove
  redeemed: PropTypes.bool.isRequired,
  refund_amount: PropTypes.number,
  refund_type: PropTypes.string,
  restaurant_id: PropTypes.number,
  updated_at: PropTypes.string.isRequired,
  usage_type: PropTypes.oneOf(Object.values(UsageType)).isRequired,
}) as Requireable<IPromoCode>;

export default promoCodeShape;
