import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import i18next from 'i18next';
import { Formik } from 'formik';
import * as yup from 'yup';

import OtoSpinner from '../common/OtoSpinner';
import InputWithEye from '../common/InputWithEye';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().required('Pole email jest wymagane'),
  password: yup.string().required('Pole hasło jest wymagane'),
});

const LoginForm: React.FC<{ handleSubmit: (values, actions) => void }> = ({
  handleSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="text"
              onBlur={handleBlur}
              name="email"
              id="email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Hasło</Label>
            <InputWithEye
              name="password"
              id="password"
              onBlur={handleBlur}
              autoComplete="current-password"
              value={values.password}
              onChange={handleChange}
            />
          </FormGroup>
          {isSubmitting ? (
            <OtoSpinner center />
          ) : (
            <>
              <div className="text-danger">
                {touched.email && errors.email && (
                  <div className="mb-2">{errors.email}</div>
                )}
                {touched.password && errors.password && (
                  <div className="mb-2">Pole hasło jest wymagane</div>
                )}
              </div>
              <Button type="submit" onClick={handleSubmit as any}>
                {i18next.t('Zaloguj')}
              </Button>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
