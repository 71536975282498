import React from 'react';
import { Form, FormikProps, Field, withFormik, FormikBag } from 'formik';
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardHeader,
  Row,
} from 'reactstrap';

import SubmitButtonWithLoader from 'app/components/common/SubmitButtonWithLoader';
import type { TDeliveryPricingDTO } from 'types';

export type AddDeliveryPricingFormValues = TDeliveryPricingDTO;

interface AddDeliveryPricingFormOwnProps {
  onAddDeliveryPricing: (
    user: AddDeliveryPricingFormValues
  ) => Promise<unknown>;
}

type AddDeliveryPricingFormProps = AddDeliveryPricingFormOwnProps &
  FormikProps<AddDeliveryPricingFormValues>;

const AddDeliveryPricingFormWithoutFormik: React.FC<
  AddDeliveryPricingFormProps
> = (props) => {
  const { isSubmitting } = props;
  return (
    <Card>
      <CardHeader>{'Dodaj cenę dostawy'}</CardHeader>
      <CardBody>
        <Form>
          <Row className="mx-0 d-flex flex-wrap">
            <FormGroup className="col min-w-200">
              <Label>Odległość od (metry)</Label>
              <Field
                as={Input}
                name="distance_from"
                type="number"
                min="0"
                step="100"
                placeholder="np. 2000"
              />
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Odległość do (metry)</Label>
              <Field
                as={Input}
                name="distance_to"
                type="number"
                min="0"
                step="100"
                placeholder="np. 5000"
              />
            </FormGroup>
            <FormGroup className="col min-w-200">
              <Label>Cena</Label>
              <Field
                as={Input}
                name="price"
                type="number"
                min="1"
                step="0.5"
                placeholder="np. 15.50"
              />
            </FormGroup>
          </Row>
          <FormGroup className="col-12">
            <SubmitButtonWithLoader
              label={'Zapisz cenę dostawy'}
              loading={isSubmitting}
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

const AddDeliveryPricingForm = withFormik<
  AddDeliveryPricingFormOwnProps,
  AddDeliveryPricingFormValues
>({
  mapPropsToValues: (): AddDeliveryPricingFormValues => ({
    distance_from: 1000,
    distance_to: 5000,
    price: 15,
  }),
  handleSubmit: (
    values: AddDeliveryPricingFormValues,
    {
      props,
      setSubmitting,
    }: FormikBag<AddDeliveryPricingFormOwnProps, AddDeliveryPricingFormValues>
  ) => {
    return props
      .onAddDeliveryPricing(values)
      .finally(() => setSubmitting(false));
  },
})(AddDeliveryPricingFormWithoutFormik);

export default AddDeliveryPricingForm;
