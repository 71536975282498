import {
  DECLINE_REASON_NO_SHOW,
  DECLINE_REASON_BY_CLIENT,
} from 'utils/reservation';
import { convertSavedReservationToNormalized } from 'utils/reservation';
import type { ISavedReservation, ReservationConfig } from 'types';
import { TReservationCalendarView } from './ReservationsCalendar/ReservationsCalendar';

export const mapLabelsToDeclineResons: Record<number, string> = {
  [DECLINE_REASON_BY_CLIENT]: 'Anulowana przez klienta',
  [DECLINE_REASON_NO_SHOW]: 'Klient nie przyszedł',
};

export const RES_CANCELLED = 'Rezerwacja została anulowana';
export const RES_CANCELLED_TEXT = (res: ISavedReservation) =>
  `${res.reserved_on} ${res.guests_number} os. ${res.customer_name}`;
export const NEW_RES = 'Otrzymano nową rezerwację';
export const NEW_RES_TEXT = (res: ISavedReservation) =>
  `${res.reserved_on} ${res.guests_number} os. ${res.customer_name}`;

const getTablePart = (item: ISavedReservation) => {
  if (!item.tables?.length) {
    return '';
  }
  const tableNames = item.tables.map((table) => table.name).join(', ');
  return tableNames;
};

const getFirstWord = (str: string) => {
  if (str && str.split) {
    const parts = str.split(' ');
    return parts[0];
  }
  return str;
};

const getNotes = (
  reservation: ISavedReservation,
  options: { showNotes?: boolean }
) => {
  if (!options?.showNotes) {
    return '';
  }

  if (reservation.notes_from_guest && reservation.notes_from_restaurant) {
    return ` | Uwaga (gość): ${reservation.notes_from_guest} | Uwaga (lokal): ${reservation.notes_from_restaurant}`;
  }
  if (reservation.notes_from_restaurant) {
    return ` | Uwaga: ${reservation.notes_from_restaurant}`;
  }
  return reservation.notes_from_guest
    ? ` | Uwaga: ${reservation.notes_from_guest}`
    : '';
};

type TFormatReservationParams = {
  reservation: ISavedReservation;
  config: ReservationConfig;
  showNotes: boolean;
  view: TReservationCalendarView;
};

export const formatReservationTitle = (
  params: TFormatReservationParams
): string => {
  const { reservation, config, showNotes, view } = params;

  const formattedNotes = getNotes(reservation, { showNotes });
  const { visitTime: time } = convertSavedReservationToNormalized(
    reservation,
    config
  );

  const firstWordOfName = getFirstWord(reservation.customer_name);
  const fullName = reservation.customer_name;

  const guests = `${reservation.guests_number} os.`; // formatGuestsWithKids(reservation, config) if kids supported
  const tables = getTablePart(reservation);

  if (view === 'dayGridDay') {
    return `${time} | ${tables} | ${fullName} dla ${guests}${formattedNotes}`;
  }
  if (view === 'resourceTimelineDay' || view === 'resourceTimeGridDay') {
    return `${time} | ${tables} | ${firstWordOfName} - ${guests}${formattedNotes}`;
  }

  if (view === 'dayGridWeek') {
    return `${time} | ${tables} | ${fullName} dla ${guests}`;
  }
  if (view === 'dayGridMonth') {
    return `${time} | ${guests}`;
  }
  throw new Error('Unknown view');
};
