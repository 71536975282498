import React from 'react';
import { Card, CardBody, CardHeader,  Col, ColProps, Row } from 'reactstrap';

import { col10Style } from '../../styles';

type CommonSectionProps = {
  header: React.ReactNode,
  colProps?: ColProps,
}

export const CommonSection: React.FC<CommonSectionProps> = ({ children, colProps, header }) => (
  <Row>
  <Col md={col10Style} {...colProps}>
    <Card>
      <CardHeader className="font-weight-bold text-black">
        {header}
      </CardHeader>
      <CardBody className="pt-0">
        {children}
      </CardBody>
    </Card>
  </Col>
</Row>
);

export default CommonSection;
