import React from 'react';
import { Container } from 'reactstrap';
import { Route, Routes } from 'react-router-dom';

import OrdersFetcher from '../containers/OrdersFetcher';
import PendingOrdersContainer from '../containers/PendingOrdersContainer';
import { FREQUENCY } from 'config';
import OrderStatsPage from './restaurant-statistics/OrderStatsPage';
import ReservationStatsPage from './restaurant-statistics/ReservationStatsPage';
import DeliveriesStatsPage from './restaurant-statistics/DeliveriesStatsPage';
import { useAppSelector } from 'hooks/useRedux';
import { selectBookingConfig, selectOrdersConfig, selectRestaurant, selectRestaurantTables } from 'store/restaurant';
import type { NormalizedRestaurant, OrdersConfig } from 'types';

const StatisticsPage: React.FC<{}> = () => {
  const bookingConfig = useAppSelector(selectBookingConfig)
  const ordersConfig = useAppSelector(selectOrdersConfig) as OrdersConfig
  const restaurant = useAppSelector(selectRestaurant) as NormalizedRestaurant
  const restaurantTables = useAppSelector(selectRestaurantTables)
  return (
    <>
      <OrdersFetcher
        ordersConfig={ordersConfig}
        frequency={FREQUENCY.REFRESH_SECONDARY}
      />
      <PendingOrdersContainer />
      <Container className="flex-column">
        <Routes>
          <Route
            path="/orders"
            element={<OrderStatsPage restaurant={restaurant} />}
          />
          <Route
            path="/reservations"
            element={
              <ReservationStatsPage
                bookingConfig={bookingConfig}
                restaurant={restaurant}
                tablesConfig={restaurantTables}
              />
            }
          />
          <Route
            path="/deliveries"
            element={<DeliveriesStatsPage restaurant={restaurant} />}
          />
        </Routes>
      </Container>
    </>
  );
};
export default StatisticsPage;
