import { INavItem } from "./types";
import { OTO_PRODUCTS, OTO_PRODUCTS_GROUPS, ROLES } from "enums";
import { allOrdersProducts } from "./settings";

const allRolesButRestaurantStaff = Object.values(ROLES).filter(
  (roleId) => roleId !== ROLES.RESTAURANT_STAFF
) as ROLES[];

export const restaurantStatisticsNavItem: INavItem = {
  name: 'Statystyki',
  icon: 'Activity',
  url: '/statistics',
  availableForRoles: allRolesButRestaurantStaff,
  children: [
    {
      name: 'Zamówienia',
      url: '/statistics/orders',
      availableForRoles: allRolesButRestaurantStaff,
      availableWithProducts: allOrdersProducts,
    },
    {
      name: 'Rezerwacje',
      url: '/statistics/reservations',
      availableForRoles: allRolesButRestaurantStaff,
      availableWithProducts: [OTO_PRODUCTS.reservations],
    },
    {
      name: 'Dostawy',
      url: '/statistics/deliveries',
      availableForRoles: allRolesButRestaurantStaff,
      availableWithProducts: OTO_PRODUCTS_GROUPS.anyDeliveriesProduct,
    },
  ],
};

export default restaurantStatisticsNavItem;
