import PropTypes, { Validator } from 'prop-types';

import { EDeliveryStatus } from 'enums/delivery';
import { DriverType } from 'enums/delivery';
import { EPaymentType } from 'enums/payments';
import { originalRestaurantShape } from './restaurant';
import userShape from './user';
import { IDelivery } from 'types';

export const statusLogShape = PropTypes.exact({
  created_at: PropTypes.string.isRequired,
  delivery_id: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(EDeliveryStatus)).isRequired,
  updated_at: PropTypes.string.isRequired,
});

export const DELIVERY_TYPE_EXTERNAL = 'external';
export const DELIVERY_TYPE_INTERNAL = 'internal';

const deliveryShape = PropTypes.exact({
  address: PropTypes.string.isRequired,
  assigned_to: PropTypes.number,
  arriving_in: PropTypes.number,
  created_at: PropTypes.string.isRequired,
  declined_by: PropTypes.array.isRequired,
  delivery_at: PropTypes.string,
  driver_type: PropTypes.oneOf(Object.values(DriverType) as DriverType[])
    .isRequired,
  distance: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  inform_before: PropTypes.number,
  notes: PropTypes.string,
  order_id: PropTypes.number,
  payment_id: PropTypes.number,
  payment_status: PropTypes.oneOf(Object.values(EPaymentType)).isRequired,
  phone: PropTypes.string.isRequired,
  price: PropTypes.number,
  restaurant: originalRestaurantShape,
  restaurant_id: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.values(EDeliveryStatus)).isRequired,
  status_logs: PropTypes.arrayOf(statusLogShape.isRequired),
  type: PropTypes.oneOf([DELIVERY_TYPE_EXTERNAL, DELIVERY_TYPE_INTERNAL])
    .isRequired as Validator<IDelivery['type']>,
  updated_at: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  user: userShape,
});

export default deliveryShape;
